import React, { useEffect } from 'react';
import routeError from 'router/routeError';
import RecuiterDashboard from './recruiter';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { checkRole, getAccessToken } from 'helpers/authHelper';
import AdminDashboard from './admin';
import PanelDashboard from './panel';
import { IRoleSetType } from 'app/redux/slices/userSlice';
import ServerSentEvent from 'helpers/eventSource';
import { NOTIFICATION_COUNT } from 'helpers/apiRoutes';
import { setNotificationCount } from 'app/redux/slices/sharedSlice';
import { PANEL, RECRUITER } from 'app/redux/constant';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) =>
    state.user.userData.role.map((item: IRoleSetType) => item.role)
  );

  const handleGetNotificationCount = (event: MessageEvent) => {
    dispatch(setNotificationCount(event?.data));
  };

  useEffect(() => {
    const token = getAccessToken();
    const encodeToken = encodeURIComponent(token);
    const eventSource = new ServerSentEvent(
      `${process.env.REACT_APP_API_URL}${NOTIFICATION_COUNT}?authToken=${encodeToken}`
    );

    eventSource?.openConnection();
    eventSource.onMessage = handleGetNotificationCount;

    eventSource?.openConnection();
    return () => {
      eventSource?.closeConnection();
    };
  }, []);

  const user = checkRole(role);
  if (user === RECRUITER) return <RecuiterDashboard />;
  if (user === PANEL) return <PanelDashboard />;
  return <AdminDashboard />;
};
export default routeError(Dashboard);
