import React from 'react';
import './index.style.scss';
import { SCHEDULE_EVENTS_TYPE } from 'helpers/types';

type Props = {
  optionsArray?: Array<string>;
};

function ColorIndicator({
  optionsArray = [
    SCHEDULE_EVENTS_TYPE.AVAILABLE,
    SCHEDULE_EVENTS_TYPE.BOOKED,
    SCHEDULE_EVENTS_TYPE.EXISTING,
    SCHEDULE_EVENTS_TYPE.CANCELLED,
    SCHEDULE_EVENTS_TYPE.REQUESTED
  ]
}: Props) {
  const getStatus = (eventType: string) => {
    return (
      <div className="color-indicator">
        <div className={`logo-${eventType.toLowerCase()}`}></div>
        <div className="color-indicator-text">{eventType}</div>
      </div>
    );
  };
  return (
    <div className="color-indicator-wrapper">
      {optionsArray.map((item) => {
        return getStatus(item);
      })}
    </div>
  );
}

export default ColorIndicator;
