import service from './index.service';
export interface ISlotEfficiencyBody {
  month: number;
  year: number;
  techStack: string;
  interviewLevel: string;
}

export interface ISlotRequirementBody {
  month: number;
  year: number;
  techStack: string;
  level: string;
  noOfSlots: number;
}

export async function userDataFromIdService(id: string) {
  try {
    const response = await service.get(`/admin/user?id=${id}`);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function employeeSummaryService(fromDate: string, toDate: string) {
  try {
    const response = await service.get(
      `/admin/dashboard/employee-summary?fromDate=${fromDate}&toDate=${toDate}`
    );
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function postSlotRequirementService(body: ISlotRequirementBody) {
  try {
    const response = await service.post(`/admin/dashboard/slot-efficiency/requirement`, body);
    return response?.data;
  } catch (error: any) {
    return { data: null, error: [error] };
  }
}

export async function slotEfficiencyService(body: ISlotEfficiencyBody) {
  try {
    const response = await service.post(`/user/dashboard/slot-efficiency`, body);
    return response?.data;
  } catch (error: any) {
    return { data: null, error: [error] };
  }
}

export async function fetchUserRoleList(pageNumber: number, pageSize: number) {
  try {
    const response = await service.get(`/admin/dashboard/user/list/${pageNumber}/${pageSize}`);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function searchFilter(searchText: string, abortSignal: any) {
  try {
    const text = encodeURIComponent(searchText);
    const response = await service.get('/admin/dashboard/search', {
      params: { searchText: text },
      signal: abortSignal
    });
    return response?.data;
  } catch (error: any) {
    if (error.code === 'ERR_CANCELED') {
      return { data: null, error: [{ message: error.message, code: error.code }] };
    }
    return error?.response?.data;
  }
}

export async function deleteAdminService(id: number) {
  try {
    const response = await service.delete(`admin/delete?userId=${id}`);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}
export async function deletePanelService(idArray: Array<string>) {
  let response: any;
  try {
    for (let id of idArray) {
      response = await service.delete(`admin/panel/delete?userId=${id}`);
    }
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}
export async function deleteRecruiterService(id: string) {
  try {
    const response = await service.delete(`admin/recruiter/delete?userId=${id.toString()}`);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function getRewardsService(month: number, year: number) {
  try {
    const response = await service.get('/user/dashboard/rewards', {
      params: {
        month: month,
        year: year,
        rewardPeriod: 'MONTHLY'
      }
    });
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function getSlotSummaryService(date: string) {
  try {
    const response = await service.get('/slot/summary', {
      params: {
        date: date
      }
    });
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function getUpcomingInterviewsData(userId: string) {
  try {
    const response = await service.get('/panel/dashboard/upcoming-interview', {
      params: {
        userId: userId
      }
    });
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function fetchPanelUsersList() {
  try {
    const response = await service.get(`/admin/dashboard/manage/panel/list`);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}
export async function fetchOnboardUsersList() {
  try {
    const response = await service.get(`/admin/dashboard/onboard/list`);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}
export async function employeeCount() {
  try {
    const response = await service.get(`/admin/employee-count`);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function getSlotAchievementsDataService() {
  try {
    const response = await service.get(`/panel/dashboard/achievements`);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}
