import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './index.style.scss';

function DrawerAnimationWrapper(Component: any) {
  return (props: any) => {
    return (
      <CSSTransition in={props.isOpen} timeout={300} classNames="drawer" unmountOnExit>
        <Component {...props} />
      </CSSTransition>
    );
  };
}

export default DrawerAnimationWrapper;
