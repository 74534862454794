import { AnyAction, Store } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppDispatch, RootState } from '../store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

// use this for normal dispatch
export const useAppDispatch: () => AppDispatch = useDispatch;
// use this for async thunk call
export const useAppThunkDispatch: () => AppThunkDispatch = useDispatch;
// use this for getting state
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
