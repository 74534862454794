/* eslint-disable react/display-name */
import React from 'react';
import { NavigateFunction, useLocation, useNavigate, Location } from 'react-router-dom';

export interface IWithRouter extends Location {
  navigate: NavigateFunction;
}

function withRouter(WrappedComponent: any) {
  return (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
      <WrappedComponent
        router={{
          navigate,
          ...location
        }}
        {...props}
      />
    );
  };
}

export default withRouter;
