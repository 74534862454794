import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { adminInfoService } from '../../services/auth.service';
import { GET_ADMIN_INFO, USER } from '../constant';

export interface IRoleSetType {
  id: number;
  role: string;
  label: string;
}

export interface ITechStackType {
  label: string;
  id: string;
  name: string;
}

interface IUserStates {
  userData: {
    userId: string;
    firstName: string;
    lastName: string;
    image: string;
    designation: string;
    role: IRoleSetType[];
    email: string;
    mobileNumber: string;
    techStackLevelSet: {
      techStackId: number;
      techStackName: string;
      levelOfPanel: string;
    }[];
    projects: string[];
    isLoading?: boolean;
  };
}

const initialState: IUserStates = {
  userData: {
    userId: '',
    firstName: '',
    lastName: '',
    image: '',
    designation: '',
    role: [],
    email: '',
    mobileNumber: '',
    techStackLevelSet: [],
    projects: [],
    isLoading: false
  }
};

export const getAdminInfo = createAsyncThunk(GET_ADMIN_INFO, async function adminInfo() {
  const userData = await adminInfoService();
  if (!userData.error) {
    return userData;
  }
});

export const userSlice = createSlice({
  name: USER,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdminInfo.fulfilled, (state, { payload }) => {
      if (payload?.data) {
        const {
          userId,
          firstName,
          lastName,
          designation,
          profileImageUrl,
          roleSet,
          emailId,
          mobileNo,
          techStackLevelSet,
          projects
        } = payload?.data;
        state.userData = {
          userId: userId,
          firstName: firstName,
          lastName: lastName,
          designation: designation,
          image: profileImageUrl,
          role: roleSet,
          email: emailId,
          mobileNumber: mobileNo,
          techStackLevelSet: techStackLevelSet,
          projects: projects,
          isLoading: false
        };
      }
    });
    builder.addCase(getAdminInfo.pending, (state, { payload }) => {
      state.userData.isLoading = true;
    });
    builder.addCase(getAdminInfo.rejected, (state, { payload }) => {
      state.userData.isLoading = false;
    });
  }
});

export default userSlice.reducer;
