import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import Button from 'app/core/shared-components/button';
import ButtonGroup, { ISelectedSearchProps } from 'app/core/shared-components/button-group';
import ClickAwayListener from 'app/core/shared-components/click-away';
import CustomSelect, { ISelectOptions } from 'app/core/shared-components/customSelect';
import Drawer from 'app/core/shared-components/drawer';
import ImageComponent from 'app/core/shared-components/image';
import Loader from 'app/core/shared-components/loader';
import Search, { IOptionType } from 'app/core/shared-components/search';
import SlotsSearchResult from 'app/core/shared-components/search-result';
import TabComponent from 'app/core/shared-components/tab';
import ToastUndo from 'app/core/shared-components/toast-undo';
import Typography from 'app/core/shared-components/typography';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import { getSlotInfo } from 'app/redux/slices/scheduleSlice';
import { getLOI, setSelectedNotificationData } from 'app/redux/slices/sharedSlice';
import {
  IRequestedSlotsResponse,
  deleteRequestedSlot,
  fetchAllSlots,
  fetchAvailableSlots,
  fetchBookedSlots,
  fetchCancelledSlots,
  fetchRequestedSlots,
  handleSearch,
  readdSlotRequest,
  removeSlotRequest,
  resetPage,
  resetSearchResult,
  setFilterValue,
  setFilteredDateValue,
  slotCount,
  slotCountForSpecificUser,
  toggleRequestFlag
} from 'app/redux/slices/slotsSlice';
import { ISlotFilterDTOType } from 'app/services/slots.service';
import { ILevelOfInterviewType } from 'app/services/user-response.types';
import Date from 'assets/images/date.svg';
import { ReactComponent as LoaderSpinner } from 'assets/images/loader-spinner.svg';
import NoRequestedSlot from 'assets/images/no-result-found.svg';
import PlusIcon from 'assets/images/plus-primary.svg';
import { theme } from 'assets/styles/theme';
import {
  ADD_TECH_BUTTON_TEXT,
  ALL,
  AVAILABLE,
  BOOKED,
  CANCELLED,
  CANCEL_LABEL,
  REQUESTED_SLOT,
  SEARCH
} from 'helpers/constants';
import {
  ADD_TECH_STACK_TO_VIEW_SLOTS,
  DELETED_SLOT,
  NO_SLOTS_AVAILABLE,
  NO_SLOTS_BOOKED,
  NO_SLOTS_CANCELLED,
  NO_SLOTS_FOUND,
  NO_SLOTS_PROVIDED
} from 'helpers/messages.constants';
import { notify } from 'helpers/toastHelper';
import { ISlotsStatusTypes, slotInfo } from 'helpers/types';
import routeError from 'router/routeError';
import {
  normalStyle,
  techLevelStyles
} from '../Dashboard/components/slot-efficiency-graph/techLevel.style';
import ErrorPage from '../Error';
import Header from './components/header';
import SlotCarousel from './components/slot-carousel';
import SlotPicker from './components/slot-picker';
import SlotRequestCard from './components/slot-request-card';
import SlotRequestForm from './components/slot-request-form';
import './index.style.scss';
import { SETTINGS, SLOTS } from 'helpers/appRoutes';
import { IButtonProps } from 'app/core/shared-components/button';

interface ISlotsState {
  searchOption: IOptionType[];
  selectedSearch: ISelectedSearchProps | null;
  searchNotFound: boolean;
  clearResults: boolean;
  selectedTabIndex: number;
  isDrawerOpen: boolean;
  isSlotRequestModalOpen: boolean;
  showDateFilter: boolean;
  borderColor: string;
  carouselHeight: number;
  techStackFilter: ISelectOptions;
  levelFilter: ILevelOfInterviewType;
  dateFilter: ISlotFilterDTOType[];
  slotCategory: ISlotsStatusTypes;
  noResultMessage: string;
  requestedSlots: IRequestedSlotsResponse[];
  isDeletionOn: boolean;
  deletedSlotId: number;
  pendingDeletionQueue: string[];
  requestId: number;
  buttonGroupId: number;
}

interface ISlotsProps {
  isSlotPage: boolean;
  hideTabs?: boolean;
}

interface ILevelWiseCountList {
  levelId?: string | number;
  levelCount?: number;
}

interface ILevelProps {
  id?: string | number;
  isZohoRecord?: boolean;
  level?: string | number;
  numberOfPanel?: number;
}
const Slots = (props: ISlotsProps) => {
  const [slotPageState, setSlotPageState] = useState<ISlotsState>({
    searchOption: [],
    selectedSearch: null,
    searchNotFound: false,
    clearResults: false,
    selectedTabIndex: 0,
    isDrawerOpen: false,
    isSlotRequestModalOpen: false,
    borderColor: theme.primary,
    slotCategory: AVAILABLE,
    carouselHeight: 0,
    showDateFilter: false,
    dateFilter: [] as ISlotFilterDTOType[],
    techStackFilter: {} as ISelectOptions,
    levelFilter: {} as ILevelOfInterviewType,
    noResultMessage: NO_SLOTS_PROVIDED,
    requestedSlots: [],
    isDeletionOn: true,
    deletedSlotId: 0,
    pendingDeletionQueue: [],
    requestId: -1,
    buttonGroupId: 0
  });

  const signal = useRef<any>(null);
  const timeOut = useRef<any>(null);

  const { pathname } = useLocation();

  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const {
    isToastVisible,
    isError,
    isLoading,
    toastMessage,
    searchOptions,
    currentSlots,
    requestedSlots: requestedSlotsData,
    filterValue,
    filteredDateData,
    isRequestSlotLoading,
    selectedSearch: selectedSearchResult,
    showRequestFlag,
    isSearchError,
    slotCountData
  } = useAppSelector((state) => state.slots);

  const { techStackLevelSet } = useAppSelector((state) => state.user.userData);

  const {
    levelOfInterview,
    isLevelLoading,
    isTechLoading,
    isSharedError,
    selectedNotificationData
  } = useAppSelector((state) => state.shared);

  const { isSlotPage = true, hideTabs = false } = props;

  const {
    searchOption,
    selectedSearch,
    searchNotFound,
    clearResults,
    selectedTabIndex,
    isDrawerOpen,
    isSlotRequestModalOpen,
    borderColor,
    slotCategory,
    carouselHeight,
    levelFilter,
    techStackFilter,
    showDateFilter,
    dateFilter,
    noResultMessage,
    buttonGroupId
  } = slotPageState;

  const { levelWiseCountList } = slotCountData;

  const [firstLevelOfInterview, ...restLOI] = levelOfInterview;
  const techStackList = techStackLevelSet?.map((techStack) => ({
    id: techStack.techStackId,
    label: techStack.techStackName
  }));

  const [firstTechStack, ...rest] = techStackList;
  const btnStyle = { size: 'medium', variant: 'contained' };

  const handleTabSelect = (index: number) => {
    setSlotPageState((prevState) => ({
      ...prevState,
      selectedTabIndex: index,
      levelFilter: levelOfInterview[index],
      isDrawerOpen: false
    }));
  };

  const handleDateFilter = (filterValue: ISlotFilterDTOType[]) => {
    setSlotPageState((prevState) => ({
      ...prevState,
      dateFilter: filterValue
    }));
  };

  const closeDateFilter = () => {
    if (showDateFilter) {
      setSlotPageState((prevState) => ({
        ...prevState,
        showDateFilter: false
      }));
    }
  };

  const toggleDateFilter = () => {
    setSlotPageState((prevState) => ({
      ...prevState,
      showDateFilter: !showDateFilter
    }));
  };

  const handleButtonGroupClick = (position: number) => {
    if (techStackLevelSet.length)
      switch (position) {
        case 0:
          dispatch(
            fetchAvailableSlots({
              levelId: levelFilter?.id.toString(),
              techStackId: techStackFilter?.id.toString(),
              dateTime: dateFilter
            })
          );
          setSlotPageState((prevState) => ({
            ...prevState,
            noResultMessage: NO_SLOTS_PROVIDED
          }));
          break;
        case 1:
          dispatch(
            fetchBookedSlots({
              levelId: levelFilter?.id?.toString(),
              techStackId: techStackFilter?.id?.toString(),
              dateTime: dateFilter
            })
          );
          setSlotPageState((prevState) => ({
            ...prevState,
            noResultMessage: NO_SLOTS_BOOKED
          }));
          break;
        case 2:
          dispatch(
            fetchCancelledSlots({
              levelId: levelFilter?.id?.toString(),
              techStackId: techStackFilter?.id?.toString(),
              dateTime: dateFilter
            })
          );
          setSlotPageState((prevState) => ({
            ...prevState,
            noResultMessage: NO_SLOTS_CANCELLED
          }));
          break;
        case 3:
          dispatch(
            fetchAllSlots({
              levelId: levelFilter?.id?.toString(),
              techStackId: techStackFilter?.id?.toString(),
              dateTime: dateFilter
            })
          );
          setSlotPageState((prevState) => ({
            ...prevState,
            noResultMessage: NO_SLOTS_AVAILABLE
          }));
          break;
      }
    setSlotPageState((prevState) => ({
      ...prevState,
      isDrawerOpen: false,
      borderColor: slotInfo[position].color,
      slotCategory: slotInfo[position].type,
      buttonGroupId: position
    }));
  };

  const handleTechStackChange = (params: ISelectOptions | ISelectOptions[] | null) => {
    if (!Array.isArray(params) && params !== null) {
      setSlotPageState((prevState) => ({
        ...prevState,
        techStackFilter: params
      }));
    }
  };

  const handleSearchAction = async (searchString: string) => {
    setSlotPageState((prevState) => ({
      ...prevState,
      searchOption: [],
      isDrawerOpen: false,
      clearResults: false
    }));
    if (searchNotFound) {
      setSlotPageState((prevState) => ({
        ...prevState,
        searchNotFound: false
      }));
    }
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
    if (searchString?.trim()?.length) {
      timeOut.current = setTimeout(async () => {
        if (signal.current) signal.current.abort();
        signal.current = new AbortController();
        const data = await dispatch(
          handleSearch({
            searchString: searchString.trim().toLocaleLowerCase(),
            abortSignal: signal.current
          })
        );
        if (!data.payload?.data?.length || !searchOptions.length) {
          setSlotPageState((prevState) => ({ ...prevState, searchNotFound: true }));
        }
      }, 1000);
    }
  };
  const clearResult = () => {
    setSlotPageState((prevState) => ({
      ...prevState,
      selectedSearch: null,
      levelFilter: levelFilter,
      techStackFilter: techStackFilter,
      clearResults: true,
      dateFilter: []
    }));
    dispatch(
      setFilteredDateValue({
        mode: 'Custom',
        dates: null,
        slotTime: { startTime: null, endTime: null }
      })
    );
    dispatch(
      setFilterValue({
        ...filterValue,
        dateTime: []
      })
    );
    dispatch(resetSearchResult());
  };

  const handleSearchSelection = (id: string | number) => {
    const searchValue =
      searchOptions.find((option) => option.id.toString() === id.toString()) || null;

    if (searchValue) {
      setSlotPageState((prevState) => ({
        ...prevState,
        searchNotFound: false,
        selectedSearch: searchValue,
        clearResults: false,
        isDrawerOpen: false
      }));
      dispatch(
        fetchAvailableSlots({
          userId: searchValue?.id
        })
      );
    }
  };
  const handleSearchBlur = () =>
    setSlotPageState((prev) => ({
      ...prev,
      clearResults: true,
      searchOption: [],
      isDrawerOpen: false
    }));
  const handleSlotRequest = () => {
    if (!isDrawerOpen) {
      dispatch(fetchRequestedSlots());
    }
    setSlotPageState((prevState) => ({
      ...prevState,
      isDrawerOpen: !isDrawerOpen,
      clearResults: true,
      searchOption: []
    }));
  };

  const handleDeleteUndo = useCallback(
    (slotId: string | number) => async () => {
      dispatch(readdSlotRequest(slotId));
      toast.update(slotId, { onClose: () => {}, hideProgressBar: true });
      toast.dismiss(slotId);
    },
    []
  );

  const handleDeleteClose = useCallback((toastId: number | string) => {
    dispatch(deleteRequestedSlot(toastId)).then(() => {
      dispatch(fetchRequestedSlots());
      setSlotPageState((prevState) => ({ ...prevState, isUndoToastVisible: false }));
    });
  }, []);

  const deleteSlotRequested = (id: string | number) => {
    dispatch(removeSlotRequest(id));
    notify(
      false,
      <ToastUndo toastMessage={DELETED_SLOT} handleUndo={handleDeleteUndo(id)} toastId={id} />,
      id,
      handleDeleteClose
    );
  };

  const handleSlotRequestModal = () => {
    setSlotPageState((prevState) => ({
      ...prevState,
      isSlotRequestModalOpen: !isSlotRequestModalOpen
    }));
  };

  const handleAddTechStack = () => {
    navigate(SETTINGS);
  };

  const getSlotCount = () => {
    const [firstTechStack, ...rest] = techStackLevelSet;
    const slotCategoryValue =
      slotCategory === AVAILABLE || slotCategory === BOOKED
        ? slotCategory.toUpperCase()
        : slotCategory === ALL
        ? ALL.toUpperCase()
        : CANCEL_LABEL;
    if (filteredDateData?.dates && !selectedSearch?.id) {
      dispatch(
        slotCount({
          interviewStatus: slotCategoryValue,
          techStackId: techStackFilter ? techStackFilter?.id : firstTechStack?.techStackId,
          dateTime: dateFilter
        })
      );
    } else if (selectedSearch?.id && !filteredDateData?.dates) {
      dispatch(
        slotCountForSpecificUser({
          userId: selectedSearch.id,
          interviewStatus: slotCategoryValue,
          dateTime: filteredDateData?.dates ? dateFilter : []
        })
      );
    } else {
      if (!techStackLevelSet?.length || !techStackFilter?.id) {
        return;
      } else if (techStackFilter && !filteredDateData?.dates) {
        dispatch(
          slotCount({
            interviewStatus: slotCategoryValue,
            techStackId: techStackFilter ? techStackFilter?.id : firstTechStack?.techStackId,
            dateTime: []
          })
        );
      }
    }
  };

  const levelWiseCount = (levelWiseCountList: ILevelWiseCountList[], levels: ILevelProps) => {
    return (
      <span className="tab-title-count">
        {` (${levelWiseCountList?.find((level) => level?.levelId == levels?.id)?.levelCount})`}
      </span>
    );
  };

  const updateLevelAndTechStackFilter = () => {
    setSlotPageState((prevState) => ({
      ...prevState,
      levelFilter: firstLevelOfInterview,
      techStackFilter: firstTechStack
    }));
  };

  const setLOIAndDateFilter = () => {
    dispatch(getLOI());
    dispatch(
      setFilteredDateValue({
        mode: 'Custom',
        dates: null,
        slotTime: { startTime: null, endTime: null }
      })
    );
    updateLevelAndTechStackFilter();
  };

  const getSelectedSlotNotificationData = async () => {
    if (selectedNotificationData?.id) {
      const { payload } = await dispatch(getSlotInfo(selectedNotificationData?.id));
      const techStatckLOP = payload?.data?.techStackInterviewLevelDto;
      const [techStack] = techStackList.filter(
        (techStack) => techStack.id === techStatckLOP?.techStackId
      );

      let levelIndex = 0;
      const [selectedLevel] = levelOfInterview.filter((LOI, index: number) => {
        if (LOI?.id === techStatckLOP?.levelId) {
          levelIndex = index;
          return LOI;
        }
      });

      document
        .getElementById(selectedLevel?.level)
        ?.scrollIntoView({ behavior: 'auto', block: 'start' });

      handleTechStackChange(techStack);
      handleTabSelect(levelIndex);
      handleButtonGroupClick(2);
    }
  };

  const getSlotsData = () => {
    if (levelFilter?.id && techStackFilter?.id) {
      switch (slotCategory) {
        case AVAILABLE:
          dispatch(
            fetchAvailableSlots({
              levelId: levelFilter.id?.toString(),
              techStackId: techStackFilter.id?.toString(),
              dateTime: dateFilter
            })
          );
          break;
        case BOOKED:
          dispatch(
            fetchBookedSlots({
              levelId: levelFilter.id?.toString(),
              techStackId: techStackFilter.id?.toString(),
              dateTime: dateFilter
            })
          );
          break;
        case CANCELLED:
          dispatch(
            fetchCancelledSlots({
              levelId: levelFilter.id.toString(),
              techStackId: techStackFilter.id.toString(),
              dateTime: dateFilter
            })
          );
          break;
        case ALL:
          dispatch(
            fetchAllSlots({
              levelId: levelFilter?.id?.toString(),
              techStackId: techStackFilter?.id?.toString(),
              dateTime: dateFilter
            })
          );
      }
      dispatch(
        setFilterValue({
          levelId: levelFilter?.id,
          techStackId: techStackFilter?.id.toString(),
          dateTime: dateFilter
        })
      );
    }
  };

  const showCountCondition = !!techStackLevelSet.length && levelWiseCountList;

  useEffect(() => {
    setLOIAndDateFilter();
  }, [techStackLevelSet.length]);

  useEffect(() => {
    if (levelOfInterview.length && techStackLevelSet.length) {
      updateLevelAndTechStackFilter();
    }
  }, [levelOfInterview?.length, techStackLevelSet?.length]);

  useEffect(() => {
    getSlotsData();
  }, [levelFilter?.id, techStackFilter?.id, dateFilter]);

  useEffect(() => {
    if (selectedSearchResult !== null) {
      dispatch(resetSearchResult());
    }
  }, [searchOption]);

  useEffect(() => {
    const options = searchOptions?.map((user) => {
      return {
        id: user.id,
        startIcon: user.profileImageUrl,
        searchKey: `${user.firstName} ${user.lastName}`,
        supportingData: user.designation,
        singleSearchData: user
      };
    });

    setSlotPageState((prevState) => ({ ...prevState, searchOption: [...options] }));
  }, [searchOptions]);

  useEffect(() => {
    setSlotPageState((prevState) => ({
      ...prevState,
      requestedSlots: requestedSlotsData
    }));
  }, [requestedSlotsData]);

  useEffect(() => {
    getSlotCount();
  }, [slotCategory, techStackFilter?.id, currentSlots]);

  useEffect(() => {
    dispatch(toggleRequestFlag(false));
    getSelectedSlotNotificationData();
    return () => {
      dispatch(resetPage());
      if (pathname === SLOTS) dispatch(setSelectedNotificationData({ id: '', slotDate: '' }));
    };
  }, []);

  return (
    <Fragment>
      {!isError && !isSharedError && !isSearchError ? (
        <div className={isSlotPage ? 'slots-page' : 'slots-page slot-widget'}>
          {isSlotPage && (
            <div className="slots-header">
              {selectedSearch ? (
                <Header title={selectedSearch} onClear={clearResult} />
              ) : (
                <div className="header-container">
                  <div className="header-title">Slots</div>
                  {!selectedSearch && techStackLevelSet.length > 0 && (
                    <Button
                      customStyle={isDrawerOpen ? 'slot-btn-active' : 'slot-btn'}
                      variant="outlined"
                      onClick={handleSlotRequest}>
                      Slot Requests
                    </Button>
                  )}
                </div>
              )}
              <div className="search-bar">
                <Search
                  options={searchOption as IOptionType[]}
                  placeholder={SEARCH}
                  customDropdownIconClass="image-circle"
                  onChange={handleSearchAction}
                  clearResult={clearResults}
                  setSelectedId={handleSearchSelection}
                  resultNotFound={searchNotFound}
                />
              </div>
            </div>
          )}
          {selectedSearch?.statusEnum && <SlotsSearchResult selectedSearch={selectedSearch} />}
          {!selectedSearch && (
            <div className="slots-body">
              {!isSlotPage && techStackList.length > 1 && (
                <div className="slot-overview-dropdown">
                  <CustomSelect
                    dropdownOptions={techStackList}
                    selectedOptions={techStackFilter}
                    onChange={handleTechStackChange}
                    customStyles={techLevelStyles}
                    showDropdownIndicator
                  />
                </div>
              )}
              <div className="slots-body-header">
                {!hideTabs && (
                  <div onFocus={handleSearchBlur}>
                    <ButtonGroup
                      orientation="horizontal"
                      customStyle="slots-button-group"
                      activeButtonClick={handleButtonGroupClick}
                      tabValue={buttonGroupId}
                      showSelectedButton
                      options={[
                        {
                          ...btnStyle,
                          label: AVAILABLE,
                          count: slotCountData?.statusCount?.available
                        } as IButtonProps,
                        {
                          ...btnStyle,
                          label: BOOKED,
                          count: slotCountData?.statusCount?.booked
                        } as IButtonProps,
                        {
                          ...btnStyle,
                          label: CANCELLED,
                          count: slotCountData?.statusCount?.cancelled
                        } as IButtonProps,
                        {
                          size: 'small',
                          variant: 'contained',
                          label: ALL,
                          count: slotCountData?.statusCount?.allSlotCount
                        }
                      ]}></ButtonGroup>
                  </div>
                )}
                {isSlotPage && (
                  <div className="slot-filters">
                    <div className="date-filter">
                      <ClickAwayListener handleClose={closeDateFilter}>
                        <Button
                          startIcon={Date}
                          onClick={toggleDateFilter}
                          customStyle={
                            showDateFilter ? 'date-btn date-btn-active' : 'date-btn'
                          }></Button>
                        {showDateFilter && (
                          <div className="slot-date-filter-popover">
                            <SlotPicker
                              onClose={toggleDateFilter}
                              onSubmit={handleDateFilter}
                              slotCategory={slotCategory}
                            />
                          </div>
                        )}
                        {filteredDateData?.dates && <div className="slots-filter-badge"></div>}
                      </ClickAwayListener>
                    </div>

                    {techStackLevelSet?.length > 1 && (
                      <CustomSelect
                        dropdownOptions={techStackList}
                        selectedOptions={techStackFilter}
                        onChange={handleTechStackChange}
                        showDropdownIndicator
                        customStyles={normalStyle}
                        searchOption={isDrawerOpen}
                      />
                    )}
                  </div>
                )}
              </div>

              <div
                className={
                  currentSlots?.data?.interviewSlotDateDTOList === null ||
                  techStackLevelSet?.length < 1
                    ? 'slots-tabs empty'
                    : 'slots-tabs'
                }>
                {slotCategory === AVAILABLE &&
                  currentSlots?.data?.interviewSlotDateDTOList !== null &&
                  !isLoading &&
                  showRequestFlag && (
                    <div className="request-flag">
                      <div className="yellow-circle"></div>
                      <Typography variant="subtitle-16">{REQUESTED_SLOT}</Typography>
                    </div>
                  )}
                <TabComponent
                  tabs={levelOfInterview.map((levels) => {
                    return {
                      title: (
                        <span id={levels?.level}>
                          {levels?.level}

                          {showCountCondition && (
                            <span className="tab-title-count">
                              {levelWiseCount(levelWiseCountList, levels)}
                            </span>
                          )}
                        </span>
                      ),

                      tabData: (
                        <Fragment>
                          {techStackLevelSet?.length < 1 ? (
                            <div className="no-slots-found">
                              <ImageComponent src={NoRequestedSlot} />
                              <Typography variant="headline-24">{NO_SLOTS_FOUND}</Typography>
                              <Typography variant="headline-16" customStyle="no-slot-message">
                                {ADD_TECH_STACK_TO_VIEW_SLOTS}
                              </Typography>
                              <Button
                                customStyle="slot-btn"
                                variant="contained"
                                onClick={handleAddTechStack}>
                                {ADD_TECH_BUTTON_TEXT}
                              </Button>
                            </div>
                          ) : (
                            <div className="slot-details" id="carousel">
                              {isLoading ? (
                                <div
                                  className={
                                    isSlotPage
                                      ? 'slot-loading-wrapper'
                                      : 'slot-loading-wrapper slots-loading-component'
                                  }>
                                  <LoaderSpinner className="loading-spinner" />
                                </div>
                              ) : (
                                <Fragment>
                                  {currentSlots?.data?.interviewSlotDateDTOList?.map(
                                    (slotList, index) => (
                                      <SlotCarousel
                                        key={index}
                                        techStackId={techStackFilter?.id?.toString()}
                                        levelId={levelFilter?.id?.toString()}
                                        noOfSlots={slotList.noOfSlots}
                                        day={slotList?.date}
                                        slots={slotList?.interviewSlotTimeDTOList}
                                        slotType={slotCategory}
                                        borderColor={borderColor}
                                      />
                                    )
                                  )}
                                  {currentSlots?.data?.interviewSlotDateDTOList === null && (
                                    <div className="no-slots-available">
                                      <ImageComponent src={NoRequestedSlot} />
                                      <Typography variant="headline-24">
                                        {NO_SLOTS_FOUND}
                                      </Typography>
                                      <Typography
                                        variant="headline-16"
                                        customStyle="no-slot-message">
                                        {noResultMessage}
                                      </Typography>
                                    </div>
                                  )}
                                </Fragment>
                              )}
                            </div>
                          )}
                        </Fragment>
                      )
                    };
                  })}
                  height={carouselHeight}
                  onSelect={handleTabSelect}
                  selectedIndex={selectedTabIndex}></TabComponent>
              </div>
            </div>
          )}
          <Drawer
            headerProps={
              <Typography variant="headline-24" customStyle="slot-drawer-title">
                Slot Requests
              </Typography>
            }
            bodyStyles="slot-drawer-body"
            onClose={handleSlotRequest}
            isOpen={isDrawerOpen}>
            {isRequestSlotLoading ? (
              <div className="slot-loading-wrapper">
                <LoaderSpinner className="loading-spinner" />
              </div>
            ) : (
              <div className="slot-drawer-body">
                <div className="request-slot">
                  <Typography variant="headline-16" customStyle="response-text">
                    Responses received
                  </Typography>
                  <Button
                    customStyle="new-request-btn"
                    variant="outlined"
                    startIcon={PlusIcon}
                    onClick={handleSlotRequestModal}>
                    New Request
                  </Button>
                </div>
                {requestedSlotsData.length ? (
                  <div className="slots-received">
                    {requestedSlotsData.map((slots, index) => {
                      return (
                        <SlotRequestCard
                          key={index}
                          slotCategory={slotCategory}
                          deleteSlotRequest={deleteSlotRequested}
                          requestId={slots.slotRequestId}
                          showTag
                          tag={`${slots?.techStack} - ${slots?.interviewLevel}`}
                          panelList={slots.slotRequestUserResponseDtoList}
                          fromTime={slots?.fromTime}
                          toTime={slots.toTime}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="no-response">
                    <ImageComponent src={NoRequestedSlot} />
                    <div className="no-result-text">No slots requested</div>
                  </div>
                )}
              </div>
            )}
          </Drawer>
          {isSlotRequestModalOpen && (
            <SlotRequestForm onClose={handleSlotRequestModal} onDrawerClose={handleSlotRequest} />
          )}
        </div>
      ) : (
        <ErrorPage page="apiFailure" />
      )}
      <Loader loading={isLevelLoading || isTechLoading} customClass="slots-loader" />
    </Fragment>
  );
};
export default routeError(Slots);
