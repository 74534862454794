import React, { Fragment } from 'react';
import moment from 'moment';
import ImageComponent from 'app/core/shared-components/image';
import Typography from 'app/core/shared-components/typography';
import DateIcon from 'assets/images/date-black.svg';
import CloseIcon from 'assets/images/modal-close-icon.svg';
import ClockIcon from 'assets/images/clock-icons.svg';
import MultipleSlots from 'app/pages/Slots/components/slot-picker/MultipleSlots';
import { IAddSlotTimings } from 'app/pages/Slots/components/slot-picker';
import { timeStamps } from 'helpers/timeStamp.constants';
import { handleTabEventWithValue } from 'helpers/utils';
import './index.style.scss';

interface ISlotCardProps {
  selectedDateTime: IAddSlotTimings[];
  handleDateDeleteTab: (id: number) => void;
  handleDateDelete: Function | ((index: number) => {});
  handleDisabled: (val: boolean) => void;
  handleDateTimeChange: Function | ((index: number) => {});
}

const SlotCard = (props: ISlotCardProps) => {
  return (
    <Fragment>
      {props.selectedDateTime?.map((selectedTime: IAddSlotTimings, index: number) => {
        return (
          <div className="slot-details-card" key={index}>
            <div className="slot-details-header">
              <div className="slot-details-date">
                <ImageComponent src={DateIcon} />
                <Typography variant="headline-16">
                  {moment(selectedTime?.date).format(timeStamps.DD_MMM_YYYY)}
                </Typography>
              </div>
              <div
                tabIndex={0}
                onKeyDown={handleTabEventWithValue(index, props?.handleDateDeleteTab)}
                className="drawer-delete-slots">
                <ImageComponent
                  src={CloseIcon}
                  customClass="close-icon"
                  onClick={props.handleDateDelete(index)}
                />
              </div>
            </div>
            <div key={index}>
              <div className="add-slots-subText">
                <ImageComponent src={ClockIcon} customClass="image" />
                <span className="slotsText">Available Slots</span>
              </div>
              <MultipleSlots
                onChangeTime={props.handleDateTimeChange(index)}
                date={[selectedTime.date]}
                addSlotTimes={[...selectedTime.times]}
                setDisabled={props.handleDisabled}
              />
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};
export default SlotCard;
