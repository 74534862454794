import React from 'react';
import ImageComponent from 'app/core/shared-components/image';
import { NO_FILTERS_DESC, NO_FILTERS_TITLE } from 'helpers/messages.constants';
import nofilters from 'assets/images/nofilters.svg';
import Typography from 'app/core/shared-components/typography';
import './index.style.scss';

const Nofilters = () => {
  return (
    <div className="no-filters-main-container">
      <ImageComponent src={nofilters} />
      <Typography customStyle="side-modal-label">{NO_FILTERS_TITLE}</Typography>
      <div className="desc-no-filters">{NO_FILTERS_DESC}</div>
    </div>
  );
};
export default Nofilters;
