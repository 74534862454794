export const theme = {
  primary: '#2c74d6',
  activeSelectOption: '#DEEBFF',
  primaryDark: '#2444a4',
  primaryLight: '#eef7fe',
  bgColor: '#f9fafc',
  textColorPrimary: '#101828',
  textColorSecondary: '#344054',
  textColorMedium: '#667085',
  textColorLight: '#98a2b3',
  calendarTimeBoxBorder: 'rgba(152, 162, 179, 0.2)',
  disable: '#c0c4cc',
  error: '#ff5252',
  errorSecondary: '#ffecef',
  warning: '#f7d64d',
  warningSecondary: '#fdf8e1',
  successSecondary: '#dcf4f0',
  calendarBorder: '#d1d6df',
  white: '#ffffff',
  existingEventBg: '#e7e8e9',
  darkBlack: '#000000',
  borderColorWithOp: 'rgba(0, 0, 0, 0.25)',
  calendarWrapperBorder: 'rgba(44, 116, 214, 0.04)',
  secondary: '#eef7fe',
  backgroundColor: '#f9fafc',
  separator: '#eaecf0',
  primaryButtonHover: '#2444a4',
  textColor1: '#101828',
  textColor2: '#667085',
  disabled: '#98a2b3',
  calendarGray: '#d1d6df',
  warningColor1: '#f39b17',
  warningColor2: '#fdf2e1',
  success: '#04bfa4',
  chipColorRed: '#ffecef',
  chipColorYellow: '#fdf8e1',
  chipColorGreen: '#dcf4f0',
  tertiary: '#3f3f3f',
  black: '#000',
  grey: '#f2f2f8',
  frozenWhite: '#fcfcfd',
  gray: '#f2f2f8',
  coolGray: '#f2f2f2',
  lightGrayBg: '#f4f6f9',
  blue: '#2196f3',
  blackRiver: '#344054',
  grayBlue: '#667085',
  gray900: '#101828',
  gray700: '#344054',
  gray500: '#667085',
  gray300: '#d0d5dd',
  gray200: '#eaecf0',
  gray83: '#d4d4d4',
  charcoal: '#344054',
  grayTdBack: '#f9fafc',
  dialogBoxButtonColor: '#848484',
  listHoverColor: '#f9fafc',
  tagsColor: '#eef7fe',
  inputBoxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #eef7fe',
  projectTagTextColor: '#04bfa4',
  projectTagBg: '#dcf4f0',
  paginationNumberBackgroud: '#eef7fe',
  disabledTextColor: '#98a2b3',
  selectHoverBoxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
  selectHoverColor: '#b2b5b8',
  navSelectBackground: '#e3f2fe',
  dividerBackground: '#eaecf0',
  warningBg: '#fdf8e1',
  warningText: '#f39b17',
  countText: '#101828',
  disabledButtonColor: '#98a2b3',
  techItemBorder: '1px solid #eaecf0',
  techBgColor: '#d9d9d9',
  textColor: '#505660',
  imgBgColor: '#eef7fe',
  weight300: '300',
  weight400: '400',
  weight500: '500',
  weight600: '600',
  weight700: '700',
  roboto: "'Roboto', 'sans-serif'",
  montserrat: "'Montserrat', 'sans-serif'",
  fontSize10px: '0.625rem',
  fontSize12px: '0.75rem',
  fontSize14px: '0.875rem',
  fontSize20px: '1.25rem',
  fontSize48px: '3rem',
  fontSize32px: '2rem',
  fontSize24px: '1.5rem',
  fontSize18px: '1.125rem',
  fontSize16px: '1rem',
  headerfontsizeMd: '3.75rem',
  headerfontsize: '4.5rem',
  subheaderfontsizeMd: '2.5rem',
  subheaderfontsize: '3.75rem',
  titlefontsizeMd: '1.5rem',
  tabheadingfontsize: '2rem',
  titlefontsize: '2.25rem',
  bodyfontsizeMd: '1.125rem',
  bodyfontsize: '1.5rem',
  body1fontsizeMd: '1.125rem',
  body1fontsize: '1.125rem',
  textfontsizeMd: '0.875rem',
  textfontsize: '1rem',
  captionfontsize: '0.875rem',
  errorfontsize: '0.875rem',
  settingsAutocompleteBoxshadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #eef7fe',
  cardBoxShadow: '0px 2px 16px rgba(7, 81, 176, 0.04)',
  boxShadowNotification: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  boxShadowSearchbar: '0px 0px 0px 4px #cce1fd',
  tagBoxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  backbuttonBoxShadow: '0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05)',
  refreshbuttonBoxShadow: '0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05)',
  modalBoxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25), 0px 0px 8px rgba(0, 0, 0, 0.25)',
  buttonGroupBoxShadow: '0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05)',
  slotLevelCardBoxShadow: '0px 2px 16px rgba(44, 116, 214, 0.04)',
  chevronBoxShadow: '0px 0px 4px rgba(44, 116, 214, 0.16)',
  addSlotCardBoxShadow: '0px 2px 16px rgba(44, 116, 214, 0.04)',
  slotCardHoverBoxShadow: '0px 3px 6px rgba(16, 24, 40, 0.16)',
  drawerButtonsBoxShadow: '0px -4px 4px rgba(0, 0, 0, 0.08)',
  selectorDropdownBoxShadow:
    '0px 4px 6px rgba(16, 24, 40, 0.25),  0px 12px 16px rgba(16, 24, 40, 0.08)',
  tooltipBoxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
  rewardsFirstBoxShadow: 'linear-gradient(180deg, rgba(238, 247, 254, 0) 0%, #eef7fe 100%)',
  rewardsSecondBoxShadow: 'linear-gradient(180deg, rgba(220, 244, 240, 0) 0%, #dceff4 100%)',
  rewardsThirdBoxShadow: 'linear-gradient(180deg, rgba(253, 248, 225, 0) 0%, #fdf8e1 100%)',
  rewardsProfileBoxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
  rewardsOverlay: 'rgba(52, 64, 84, 0.7)',
  slotCardBoxShadow: '-0.1px 0px 1px rgba(0, 0, 0, 0.25)',
  dateHoverBoxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 4px #eef7fe',
  selectLiBoxShadow: '0px 2px 6px rgba(52, 64, 84, 0.15)',
  tableBoxShadow: '0px 4px 4px gray-300',
  menuBoxShadow: '0 3px 6px rgba(0,0,0,.16)',
  levelTagColor: '#eef7fe',
  toastSuccess: '#d1fadf',
  toastSuccessLight: '#ecfdf3',
  modalBackdropBackground: 'rgba(52, 64, 84, 0.7)',
  logoutButtonBoxShadow: '0px 0px 6px rgba(16, 24, 40, 0.25)',
  borderRadius: '0.5rem',
  borderRadius6: '0.375rem',
  borderRadius16: '1rem',
  border: '1px solid gray-300',
  inputBoxBorder: '1px solid gray-500',
  backbuttonBorder: '0.063rem solid #d0d5dd',
  refreshbuttonBorder: '0.063rem solid #2196f3',
  buttonGroupBorder: '1px solid #eaecf0',
  checkboxBorder: '1px solid #667085',
  menuBorder: '1px solid #d5d5d5',
  panelStatusBorder: '1px solid #f39b17',
  buttonGroupHorizontalFirstChild: '0.5rem 0rem 0rem 0.5rem',
  buttonGroupHorizontalLastChild: '0rem 0.5rem 0.5rem 0rem',
  buttonGroupVerticalFirstChild: '0.5rem 0.5rem 0rem 0rem',
  buttonGroupVerticalLastChild: '0rem 0rem 0.5rem 0.5rem',
  dialogBoxBorder: '1px solid #2c74d6',
  primaryBorder: '1px solid #2c74d6',
  mediaTablet: '46.5rem',
  mediaPhone: '23.438rem',
  shimmerBackground: 'linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%)',
  errorBorder: '1px solid #ff5252'
};
