import * as yup from 'yup';
import _, { uniq } from 'lodash';
import { MIN_ONE_VALUE, TECHSTACK_LOP_DUPLICATE_ERROR } from 'helpers/messages.constants';

const schemaOfFieldRequired = yup
  .object()
  .shape({
    id: yup.string().required(),
    label: yup.string().required()
  })
  .required();

const schemaOfFieldNotRequired = yup
  .object()
  .shape({
    id: yup.string(),
    label: yup.string()
  })
  .nullable()
  .notRequired();

const schemaOfArrayRequired = yup
  .object()
  .shape({
    techStackId: schemaOfFieldRequired,
    interviewLevelId: schemaOfFieldRequired
  })
  .required();

const schemaOfArrayNotRequired = yup
  .object()
  .shape({
    techStackId: schemaOfFieldNotRequired,
    interviewLevelId: schemaOfFieldNotRequired
  })
  .required();

export const yupOnboard = yup.object({
  selectedRole: yup
    .object({
      id: yup.string().required(),
      label: yup.string().required()
    })
    .required(),
  archive: yup.boolean().when(['selectedRole'], {
    is: (value: any) => value?.id === '2',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired()
  }),
  project: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        label: yup.string().required()
      })
    )
    .when(['selectedRole', 'archive'], {
      is: (selectedRoleStatus: any, archiveStatus: boolean) =>
        selectedRoleStatus?.id === '2' && !archiveStatus,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired()
    }),
  techStackDetails: yup
    .array()
    .min(1, MIN_ONE_VALUE)
    .nonNullable()
    .when(['selectedRole', 'archive'], {
      is: (selectedRoleStatus: any, archiveStatus: boolean) =>
        selectedRoleStatus?.id === '2' && !archiveStatus,
      then: (schema) =>
        schema
          .of(schemaOfArrayRequired)
          .required()
          .test('techStackDetails', (values, { createError, path }) => {
            if (values) {
              for (let i = 0; i < values?.length - 1; i++) {
                for (let j = i + 1; j < values?.length; j++) {
                  const stackEqual =
                    values[i]?.techStackId !== null &&
                    values[i]?.techStackId?.id === values[j]?.techStackId?.id;
                  const levelEqual =
                    values[i]?.interviewLevelId !== null &&
                    values[i]?.interviewLevelId?.id === values[j]?.interviewLevelId?.id;
                  if (stackEqual && levelEqual) {
                    return createError({
                      message: 'Error',
                      path: 'techStackDetails'
                    });
                  }
                }
              }
              return true;
            } else
              return createError({
                message: 'Error',
                path: 'techStackDetails'
              });
          }),
      otherwise: (schema) => schema.notRequired()
    })
});
