import React, { CSSProperties, useMemo } from 'react';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { ISelectedSlotState } from 'app/pages/Slots/components/slot-request-card';
import ImageComponent from 'app/core/shared-components/image';
import Typography from 'app/core/shared-components/typography';
import { ISelectOptions } from 'app/core/shared-components/customSelect';
import SplitSlot from 'app/pages/Slots/components/split-slot';
import { SIXTY_MINUTES } from 'helpers/constants';
import './index.style.scss';
import { getExperience } from 'helpers/utils';

export interface IPanelOptions {
  id?: string;
  image?: string | null;
  name?: string;
  designation?: string;
  level?: string[] | string;
  experience?: number;
}

interface ISlotRadioGroupProps {
  options: Array<IPanelOptions>;
  setCurrentValue: (val: number) => void;
  currentValue: number;
  fromTime?: string;
  toTime?: string;
  onSlotChange?: (params: ISelectedSlotState) => void;
  selectedSlot?: ISelectedSlotState;
  selectOptions?: ISelectOptions[];
  showExperience?: boolean;
  displaySplitSlot?: boolean;
}

function SlotRadioGroup({
  options,
  setCurrentValue,
  currentValue,
  fromTime,
  toTime,
  onSlotChange,
  selectedSlot,
  selectOptions,
  showExperience,
  displaySplitSlot = true
}: ISlotRadioGroupProps) {
  const slotTimeDifference = useMemo(
    () => moment(toTime).diff(fromTime, 'minutes'),
    [fromTime, toTime]
  );
  const showSplitSlot =
    slotTimeDifference >= SIXTY_MINUTES && !!selectOptions && !!onSlotChange && !!selectedSlot;

  const maxUserNameLenth = displaySplitSlot ? 28 : 18;

  const optionsCondition = useMemo(() => options.length > 2, [options.length]);

  const containerStyles: CSSProperties = useMemo(
    () =>
      optionsCondition
        ? { height: '16rem', overflowY: 'scroll' }
        : { height: 'auto', overflowY: 'initial' },
    [options.length]
  );

  const menuPlacement = useMemo(
    () =>
      optionsCondition &&
      (currentValue === options.length - 1 || currentValue === options.length - 2)
        ? 'top'
        : 'auto',
    [options.length, currentValue]
  );

  const groupName = useMemo(() => uniqueId(), []);

  function onClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    setCurrentValue(parseInt(event.currentTarget.id));
  }

  const handleInputRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <div className="panel-slots-radio-group" style={containerStyles}>
      {options.map((label: IPanelOptions, index: number) => {
        return (
          <div
            key={index}
            className={`radio-outer-slots`}
            id={index.toString()}
            onClick={onClickHandler}>
            <div className="slot-panel-container">
              {options.length > 1 && (
                <label className="radio-container">
                  <input
                    className="radio"
                    type="radio"
                    name={groupName}
                    onChange={handleInputRadio}
                    checked={index == currentValue}
                  />
                  <div className="radio-custom"></div>
                </label>
              )}
              <div className="slot-radio-label">
                <div className="panel-details">
                  <div>
                    <ImageComponent
                      src={label.image as string}
                      className="panel-image"
                      fallbackText={label.name}
                    />
                  </div>
                  <div>
                    <div className="panel-name">
                      <Typography variant="headline-16" customStyle="truncate-panel-name">
                        {label.name}
                      </Typography>
                      {label?.name && label?.name?.length > maxUserNameLenth && (
                        <div className="panel-name-tooltip">
                          {label.name}
                          <span className="panel-name-tooltip-arrow" />
                        </div>
                      )}
                      <div className="tags">
                        {displaySplitSlot && <div className="single-tag">{label.level}</div>}
                        <div className="single-tag-experience grey-Bg">
                          {label?.experience && getExperience(label?.experience)}
                        </div>
                      </div>
                    </div>
                    <Typography variant="subtitle-16">{label.designation}</Typography>
                  </div>
                </div>
                {currentValue == index && showSplitSlot && displaySplitSlot && (
                  <SplitSlot
                    slotOptions={selectOptions}
                    onSlotChange={onSlotChange}
                    selectedSlot={selectedSlot}
                    menuPlacement={menuPlacement}
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default React.memo(SlotRadioGroup);
