import React, { Fragment, ImgHTMLAttributes, useState } from 'react';
import './index.style.scss';
import Typography from '../typography';
import { ReactComponent as Icon } from '../../../../assets/images/rectangle.svg';

interface IImageProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'children'> {
  src: string | null;
  fallbackText?: string;
  customClass?: string;
  fallbackClass?: string;
}

function ImageComponent(props: IImageProps) {
  const { src, fallbackText, customClass, fallbackClass, ...otherProps } = props;
  const [imagePlaceholder, setImagePlaceholder] = useState<boolean>(false);

  const handleError = (event: any) => {
    event.currentTarget.onerror = null;
    if (!imagePlaceholder) setImagePlaceholder(true);
  };

  if (src && !imagePlaceholder) {
    return (
      <Fragment>
        <img
          src={src}
          className={customClass}
          draggable={false}
          onError={handleError}
          {...otherProps}
        />
      </Fragment>
    );
  } else if (fallbackText) {
    return (
      <div className={`image-placeholder ${fallbackClass}`} onClick={props.onClick}>
        <Typography customStyle="profile-text">{fallbackText.split(' ')[0][0]}</Typography>
      </div>
    );
  }
  return (
    <Fragment>
      <Icon className={customClass} onClick={props.onClick as any} />
    </Fragment>
  );
}
export default ImageComponent;
