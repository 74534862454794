import React, { Component, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { googleLogout } from '@react-oauth/google';
import withRouter, { IWithRouter } from 'router/withRouter';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import ClickAwayListener from 'app/core/shared-components/click-away';
import Typography from 'app/core/shared-components/typography';
import Modal from 'app/core/shared-components/modal';
import Button from 'app/core/shared-components/button';
import NavigationItems from 'app/core/shared-components/navigation-items';
import ImageComponent from 'app/core/shared-components/image';
import { getAdminInfo } from 'app/redux/slices/userSlice';
import { getLOI, getProjects, getRoles, getTechStack } from 'app/redux/slices/sharedSlice';
import {
  CANCEL,
  COPYRIGHT,
  LOGOUT_TEXT,
  NO_LABEL,
  RESIZE,
  WINDOW_WIDTH,
  YES_LABEL
} from 'helpers/constants';
import Images from 'assets/images';
import { logoutService } from 'app/services/auth.service';
import { handleTabEventwithoutValue } from 'helpers/utils';
import { HOME_ROUTE, LOGIN_ROUTE, SETTINGS_ROUTE } from 'helpers/apiRoutes';
import './index.style.scss';
import { NEW_LOGOUT_MESSAGE } from 'helpers/messages.constants';

interface ISingleItem {
  icon: string;
  title: string;
  path: string;
}

interface ISideNavProps {
  items: ISingleItem[];
  router: IWithRouter;
}

interface ISideNavStates {
  customStyles: string;
  showModal: boolean;
  isProfileDropdownOpen: boolean;
}

function SideNav(props: ISideNavProps) {
  const { items } = props;
  const [sideNavStates, setSideNavStates] = useState<ISideNavStates>({
    customStyles: 'link',
    showModal: false,
    isProfileDropdownOpen: false
  });
  const [windowWidth, setWindowWidth] = useState(0);
  const [isOnline, setIsOnline] = useState(true);
  const [toggleSidenav, setTogglSidenav] = useState(false);
  const { customStyles, showModal, isProfileDropdownOpen } = sideNavStates;
  const { firstName, lastName, image, designation, techStackLevelSet } = useAppSelector(
    (state) => state.user.userData
  );
  const { slotifyLogo, logOut, sidenavClose, sidenavOpen, coditasLogo } = Images;
  const navigationRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const dispatch = useAppThunkDispatch();

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  function handleLogoutClick() {
    setSideNavStates({ ...sideNavStates, showModal: true, isProfileDropdownOpen: false });
  }
  async function logoutUser() {
    const { navigate } = props.router;
    const isOnline = navigator.onLine;

    if (isOnline) {
      const data = await logoutService();
      if (!data?.error) {
        sessionStorage.clear();
        googleLogout();
        navigate(LOGIN_ROUTE);
      }
    } else {
      setIsOnline(isOnline);
      if (window.location.pathname === HOME_ROUTE) {
        navigate('/');
      } else {
        navigate(HOME_ROUTE);
      }
      setSideNavStates({ ...sideNavStates, showModal: false });
    }
  }
  function closeModal() {
    setSideNavStates({ ...sideNavStates, showModal: false });
  }

  function toggleProfileDropdown() {
    setSideNavStates((prevState) => ({
      ...prevState,
      isProfileDropdownOpen: !sideNavStates.isProfileDropdownOpen
    }));
  }

  function setSelectedSideNavItem() {
    setSideNavStates((prevState) => ({
      ...prevState,
      customStyles: 'link selected'
    }));
  }

  function handleCloseProfileDropdown() {
    setSideNavStates((prevState) => ({
      ...prevState,
      isProfileDropdownOpen: false
    }));
  }

  const handleSettingsNavigation = useCallback(() => {
    props.router.navigate(SETTINGS_ROUTE);
  }, []);

  const renderTechStack = () => {
    let arr;
    arr = techStackLevelSet
      .slice(2, techStackLevelSet.length)
      ?.map((item, i) => item.techStackName);
    if (techStackLevelSet.length > 2) {
      return (
        <Fragment>
          {techStackLevelSet.slice(0, 2)?.map((tech, index) => {
            return (
              <div key={index} className="techName-container">
                <div className="techName">{tech.techStackName}</div>
                {tech.techStackName.length > 8 && (
                  <div className="tooltip">
                    {tech.techStackName}
                    <span className="tooltip-arrow" />
                  </div>
                )}
              </div>
            );
          })}
          <div className="techNameCount-container">
            <div className="techNameCounts">{`+${techStackLevelSet.length - 2}`}</div>
            <div className="tooltipCount">
              {arr.map((item, i) => (
                <span key={i}>{item}</span>
              ))}
              <span className="tooltip-arrow" />
            </div>
          </div>
        </Fragment>
      );
    }
    return techStackLevelSet?.map((tech, index) => {
      return (
        <div key={index} className="techName-container">
          <div className="techName">{tech.techStackName}</div>
          {tech.techStackName.length > 8 && (
            <div className="tooltip">
              {tech.techStackName}
              <span className="tooltip-arrow" />
            </div>
          )}
        </div>
      );
    });
  };

  const navigateDashboard = () => {
    props.router.navigate(HOME_ROUTE);
  };

  const toggleSidenavBar = () => {
    setTogglSidenav(!toggleSidenav);
  };
  const handleScrollTop = () => {
    if (navigationRef) {
      navigationRef.current.scrollTop = 0;
    }
  };
  const handleWindowResize = () => {
    resizeWindow();
    window.addEventListener(RESIZE, resizeWindow);
    return () => window.removeEventListener(RESIZE, resizeWindow);
  };

  const handleInitialLoad = () => {
    setSelectedSideNavItem();
    dispatch(getTechStack());
    dispatch(getRoles());
    dispatch(getLOI());
    dispatch(getProjects());
    dispatch(getAdminInfo());
  };

  useEffect(() => {
    handleWindowResize();
  }, []);

  useEffect(() => {
    handleInitialLoad();
  }, []);

  useEffect(() => {
    handleScrollTop();
  }, [navigationRef]);

  return (
    <Fragment>
      {isOnline && (
        <div className={toggleSidenav ? 'side-nav closed' : 'side-nav'}>
          <div className={toggleSidenav ? 'content items' : 'content'}>
            <div
              className="sidenav-collapseIcon"
              tabIndex={0}
              onKeyDown={handleTabEventwithoutValue(toggleSidenavBar)}>
              <ImageComponent
                src={toggleSidenav ? sidenavOpen : sidenavClose}
                className="nav-image"
                onClick={toggleSidenavBar}
              />
            </div>
            <div className="header">
              <div className="header--content" onClick={navigateDashboard}>
                <ImageComponent
                  src={toggleSidenav ? coditasLogo : slotifyLogo}
                  className="nav-image"
                />
              </div>

              {windowWidth < WINDOW_WIDTH && (
                <ClickAwayListener handleClose={handleCloseProfileDropdown}>
                  <div className="profile-section">
                    <div className="profile-section-image">
                      <ImageComponent
                        src={image}
                        fallbackText={`${firstName.toUpperCase()}`}
                        fallbackClass="nav-user-image no-image-wrapper"
                        className="nav-user-image"
                        onClick={toggleProfileDropdown}
                      />
                      {isProfileDropdownOpen && (
                        <div className="profile-dropdown">
                          <div className="profile-user-detail">
                            <Typography variant="text" className="name">
                              {`${firstName} ${lastName}`}
                            </Typography>
                            <Typography variant="text" className="caption">
                              {designation}
                            </Typography>
                          </div>
                          <div className="profile-logout" onClick={handleLogoutClick}>
                            <ImageComponent src={logOut} className="nav-image" />
                            <Typography variant="text" customStyle="nav-text">
                              Log out
                            </Typography>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </ClickAwayListener>
              )}
            </div>
            <div
              ref={navigationRef}
              className={toggleSidenav ? 'navigation collapsed' : 'navigation'}>
              <NavigationItems
                items={items}
                customStyles={customStyles}
                toggledState={toggleSidenav}
              />
            </div>

            <div className="divider"></div>
            <div className="nav-user-info" onClick={handleSettingsNavigation}>
              <ImageComponent
                src={image}
                fallbackText={`${firstName.toUpperCase()}`}
                fallbackClass="nav-user-image no-image-wrapper"
                className="nav-user-image"
                onClick={toggleProfileDropdown}
              />
              {!toggleSidenav && (
                <div className="nav-user-detail">
                  <Typography variant="text" className="name">
                    {`${firstName} ${lastName}`}
                  </Typography>
                  <Typography variant="text" className="caption">
                    {designation}
                  </Typography>
                  <div className="teckStackDetails">{renderTechStack()}</div>
                </div>
              )}
            </div>
            <div className="accounts" onClick={handleLogoutClick}>
              <ImageComponent src={logOut} className="nav-image" />
              {!toggleSidenav && (
                <Typography variant="text" customStyle="nav-text">
                  {LOGOUT_TEXT}
                </Typography>
              )}
            </div>
            {!toggleSidenav && (
              <div className="footer">
                <span className="copyrightLogo">{COPYRIGHT}</span>
              </div>
            )}
          </div>
          <Modal onClose={closeModal} customStyle="dialog-box" header="Log Out" isOpen={showModal}>
            <Typography variant="text" customStyle="dialog-box-content">
              {NEW_LOGOUT_MESSAGE}
            </Typography>
            <div className="dialog-box-footer">
              <Button size="medium" customStyle="cancel-button" onClick={closeModal}>
                {CANCEL}
              </Button>
              <Button size="medium" customStyle="confirm-button" onClick={logoutUser}>
                {LOGOUT_TEXT}
              </Button>
            </div>
          </Modal>
        </div>
      )}
    </Fragment>
  );
}

export default withRouter(SideNav);
