import service from './index.service';
import { IBodyPanelEdit, IPanelFilters } from 'helpers/panel';

const localBase = 'http://43.204.203.27:8081/admin';

export async function projectListService() {
  try {
    const response = await service.get(`user/project/list`);
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function techStackListService() {
  try {
    const response = await service.get(`user/tech-stack/list`);
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function interviewLevelListService() {
  try {
    const response = await service.get(`user/interview-levels`);
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function searchPanelService(searchString: string, signal: any) {
  try {
    const response = await service.get(`user/panel/search?searchPanel=${searchString}`, {
      signal
    });
    return response.data;
  } catch (error: any) {
    if (error.code === 'ERR_CANCELED') {
      return { data: null, error: [{ message: error.message, code: error.code }] };
    } else if (error?.response?.data.code === 400) {
      return { data: null, error: [{ message: error.message, code: error?.response?.data.code }] };
    }
    return error.response.data;
  }
}

export async function getInReviewPanelService(pageNo: number, filters: IPanelFilters) {
  try {
    const response = await service.post(`admin/manage/panel/in-review/${pageNo}/10`, filters);
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function getArchivedPanelService(pageNo: number, filters: IPanelFilters) {
  try {
    const response = await service.post(`admin/manage/panel/archived/${pageNo}/10`, filters);
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function getApprovedPanelService(pageNo: number, filters: IPanelFilters) {
  try {
    const response = await service.post(`admin/manage/panel/approved/${pageNo}/10`, filters);
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function markOnBenchService(idArray: Array<string>) {
  try {
    const response = await service.post(`admin/manage/panel/mark-on-bench`, {
      userId: idArray
    });
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function deletePanelService(idArray: Array<string>) {
  try {
    const response = await service.delete(
      `admin/manage/panel/delete?${idArray?.map((n, index) => `userId=${n.toString()}`).join('&')}`
    );

    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function roleListService() {
  try {
    const response = await service.get(`user/role/list`);
    return response.data;
  } catch (e: any) {
    return e.response;
  }
}

export async function editUserInfoService(body: IBodyPanelEdit) {
  try {
    const response = await service.post(`admin/manage/panel/edit`, body);
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function getUserInfoService(id: string) {
  try {
    const response = await service.get(`admin/user?id=${id}`);
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function getRoleList() {
  try {
    const response = await service.post(`/user/role/list`);
    return response.data;
  } catch (e: any) {
    return e.response;
  }
}

export async function editStatusService(idArray: string[]) {
  try {
    const response = await service.post(`admin/manage/panel/approve`, { userId: idArray });
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function archiveUsers(idArray: string[]) {
  try {
    const response = await service.post(`admin/manage/panel/archive`, { userId: idArray });
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}
