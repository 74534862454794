import React, { useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { handleTabEventWithValue } from 'helpers/utils';
import './index.style.scss';

type Props = {
  labelArray: Array<string>;
  setCurrentValue: (val: string) => void;
  currentValue: string;
};

function Radio({ labelArray, setCurrentValue, currentValue }: Props) {
  const groupName = useMemo(() => uuid(), []);

  function onClickHandler(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) {
    const value = event.currentTarget?.getAttribute('data-value')
      ? event.currentTarget?.getAttribute('data-value')
      : event;
    if (value) {
      if (labelArray.includes(value)) {
        setCurrentValue(value);
      }
    }
  }

  function handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {}

  return (
    <>
      {labelArray.map((label, index) => {
        return (
          <div
            tabIndex={0}
            key={index}
            className="radio-outer"
            data-value={label.toString()}
            onClick={onClickHandler}
            onKeyDown={handleTabEventWithValue(label?.toString(), onClickHandler)}>
            <label className="radio-container">
              <input
                className="radio"
                type="radio"
                name={groupName}
                checked={label === currentValue}
                onChange={handleRadioChange}
              />
              <div className="radio-custom"></div>
            </label>
            <div className="radio-label">{label}</div>
          </div>
        );
      })}
    </>
  );
}

export default React.memo(Radio);
