import React, { Fragment, useMemo } from 'react';
import moment from 'moment';
import { ISelectOptions } from 'app/core/shared-components/customSelect';
import { ISelectValue } from 'app/core/shared-components/select';
import { SIXTY_MINUTES } from 'helpers/constants';
import { ISelectedSlotState } from '../slot-request-card';
import SplitSlot from '../split-slot';
import './index.style.scss';

interface ITechLevel {
  techStack: ISelectValue;
  level: ISelectValue;
}

interface ITechLevelRadioGroupProps {
  options: Array<ITechLevel>;
  setCurrentValue: (val: number) => void;
  currentValue: number;
  fromTime?: string;
  toTime?: string;
  onSlotChange?: (params: ISelectedSlotState) => void;
  selectedSlot?: ISelectedSlotState;
  selectOptions?: ISelectOptions[];
}

function TechLevelRadioGroup({
  options,
  setCurrentValue,
  currentValue,
  fromTime,
  toTime,
  onSlotChange,
  selectOptions,
  selectedSlot
}: ITechLevelRadioGroupProps) {
  function onClickHandler(event: React.MouseEvent<HTMLDivElement>) {
    setCurrentValue(parseInt(event.currentTarget.id));
  }

  const handleInputRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const slotTimeDifference = useMemo(
    () => moment(toTime).diff(fromTime, 'minutes'),
    [fromTime, toTime]
  );

  const showSplitSlot =
    !!selectOptions && !!onSlotChange && !!selectedSlot && slotTimeDifference >= SIXTY_MINUTES;

  return (
    <div className="tech-level-container">
      <div className="tech-level-radio-container">
        {options.map((techLevelOption: ITechLevel, index: number) => {
          return (
            <div
              key={index}
              className="radio-outer-slots tech-level"
              id={index.toString()}
              onClick={onClickHandler}>
              {options.length > 1 && (
                <label className="radio-container">
                  <input
                    className="radio"
                    type="radio"
                    name="value"
                    onChange={handleInputRadioChange}
                    checked={index == currentValue}
                  />
                  <div className="radio-custom"></div>
                </label>
              )}
              <div className="radio-label">
                <div className="single-tag">{`${techLevelOption?.techStack?.label} - ${techLevelOption?.level?.label}`}</div>
              </div>
            </div>
          );
        })}
      </div>
      {showSplitSlot && (
        <Fragment>
          <SplitSlot
            onSlotChange={onSlotChange}
            selectedSlot={selectedSlot}
            slotOptions={selectOptions}
          />
        </Fragment>
      )}
    </div>
  );
}

export default TechLevelRadioGroup;
