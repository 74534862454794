import service from './index.service';

interface IFilterProps {
  techStackList: string[];
  sortBy: string;
}
export async function getRecruitersSerivce(pageNo: number, filters: IFilterProps) {
  try {
    const response = await service.post(`/admin/manage/recruiter/list/${pageNo}/10`, filters);
    return response.data;
  } catch (error: any) {
    return { data: null, error: [error] };
  }
}

export async function searchRecruiterService(searchString: string, signal: any) {
  try {
    const response = await service.get(`/admin/manage/recruiter/search?searchTA=${searchString}`, {
      signal
    });
    return response.data;
  } catch (error: any) {
    if (error.code === 'ERR_BAD_REQUEST') {
      return { data: null, error: [{ message: error.message, code: error.code }] };
    } else if (error?.code === 'ERR_NETWORK') {
      return { data: null, error: [{ message: error.message, code: error.code }] };
    }
  }
}

export async function getUserInfoService(id: string) {
  try {
    const response = await service.get(`admin/manage/recruiter?userId=${id}`);
    return response.data;
  } catch (error: any) {
    return { data: null, error: [error] };
  }
}

export async function deleteRecruiterService(id: string) {
  try {
    const response = await service.delete(`/admin/manage/recruiter/delete?userId=${id}`);
    return response.data;
  } catch (error: any) {
    return { data: null, error: [error] };
  }
}

export async function editUserInfoService(body: any) {
  try {
    const response = await service.post(`admin/user/edit`, body);
    return response.data;
  } catch (error: any) {
    return { data: null, error: [error] };
  }
}
