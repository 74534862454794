import React, { Fragment, useCallback } from 'react';
import { Row } from 'react-table';
import CheckBox from '../../checkbox';
import RowCells from './rowCells';

interface IRowsProps {
  rows: Row<object>[];
  prepareRow: (row: Row<object>) => void;
  handleRowSelect: (id: string) => void;
  isCheckEnabled: boolean;
  checkRow: (id: string) => boolean;
  selectedId: string;
  idKey: string;
}

const Rows = (props: IRowsProps) => {
  const { rows, prepareRow, handleRowSelect, isCheckEnabled, checkRow, selectedId, idKey } = props;

  const handleCheckBoxClick = useCallback(
    (id: string) => () => {
      handleRowSelect(id);
    },
    [rows]
  );

  return (
    <Fragment>
      {rows.map((row: any, index) => {
        prepareRow(row);
        const id = row?.original[idKey] ? row?.original[idKey] : '';
        const idMatchCondition = id == selectedId && id && selectedId;
        return (
          <tr
            {...row.getRowProps()}
            className={`${idMatchCondition ? 'selected-tr table-tr' : 'table-body-tr'}`}
            key={index}
            id={idMatchCondition ? selectedId : ''}>
            {isCheckEnabled && (
              <td className="table-row-cell table-checkbox-cell">
                <CheckBox
                  id={row.original.id}
                  handleClick={handleCheckBoxClick(row.original.id)}
                  checked={checkRow(row.original.id)}
                />
              </td>
            )}
            <RowCells cells={row.cells} />
          </tr>
        );
      })}
    </Fragment>
  );
};

export default Rows;
