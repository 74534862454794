import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'app/redux/hooks';
import SlotDetailsCard from 'app/pages/Slots/components/slot-details-card';
import SlotPicker, { IAddSlotTimings } from 'app/pages/Slots/components/slot-picker';
import { ISelectValue } from 'app/core/shared-components/select';
import Drawer from 'app/core/shared-components/drawer';
import Typography from 'app/core/shared-components/typography';
import { ReactComponent as LoaderSpinner } from 'assets/images/loader-spinner.svg';
import Date from 'assets/images/date.svg';
import { IAddSlotBody } from 'app/services/slots.service';
import { handleTabEventwithoutValue } from 'helpers/utils';
import { ADD_SlOTS, SELECT_DATES } from 'helpers/constants';
import './index.style.scss';

interface IAddSlotFormProps {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  userId: number | string;
  dateFilter?: {
    from: string;
    to: string;
  }[];
  slotCategory?: string;
  onAddSlot: (slots: IAddSlotBody, errorMessage: string) => void;
  techStackFilter?: ISelectValue;
}

interface IAddSlotFormStates {
  isAddSlotDropdownOpen: boolean;
  selectedSlots: IAddSlotTimings[];
  showAddSlotCards: boolean;
}

const AddSlotForm = (props: IAddSlotFormProps) => {
  const [addSlotFormStates, setAddSlotFormStates] = useState<IAddSlotFormStates>({
    isAddSlotDropdownOpen: false,
    selectedSlots: [],
    showAddSlotCards: false
  });

  const {
    userId,
    isDrawerOpen,
    toggleDrawer,
    onAddSlot,
    slotCategory,
    dateFilter = [],
    techStackFilter = {} as ISelectValue
  } = props;

  const { isAddSlotDropdownOpen, showAddSlotCards, selectedSlots } = addSlotFormStates;
  const { showLoader } = useAppSelector((state) => state.slots);

  const handleAddSlotDropdown = () => {
    setAddSlotFormStates((prevState: IAddSlotFormStates) => ({
      ...prevState,
      isAddSlotDropdownOpen: !isAddSlotDropdownOpen
    }));
    if (!isAddSlotDropdownOpen) {
      setAddSlotFormStates((prevState: IAddSlotFormStates) => ({
        ...prevState,
        showAddSlotCards: false,
        selectedSlots: []
      }));
    }
  };

  const toggleAddSlotSelect = () => {
    setAddSlotFormStates((prevState: IAddSlotFormStates) => ({
      ...prevState,
      showAddSlotCards: false,
      selectedSlots: []
    }));
  };

  const handleAddSlot = (val: IAddSlotTimings[]) => {
    setAddSlotFormStates((prevState: IAddSlotFormStates) => ({
      ...prevState,
      showAddSlotCards: true,
      selectedSlots: val
    }));
  };
  const handleUpdateSlotStates = () => {
    setAddSlotFormStates((prevState: IAddSlotFormStates) => ({
      ...prevState,
      showAddSlotCards: false,
      selectedSlots: [],
      isAddSlotDropdownOpen: !isDrawerOpen ? false : isAddSlotDropdownOpen
    }));
  };

  useEffect(() => {
    handleUpdateSlotStates();
  }, [isDrawerOpen]);
  return (
    <Drawer
      headerProps={
        <Typography variant="headline-24" customStyle="slot-drawer-title">
          {ADD_SlOTS}
        </Typography>
      }
      bodyStyles="slot-drawer-body"
      onClose={toggleDrawer}
      isOpen={isDrawerOpen}>
      {showLoader ? (
        <div className="slot-loading-wrapper">
          <LoaderSpinner className="loading-spinner" />
        </div>
      ) : (
        <div className="add-slot-drawer">
          <div className="date-filter-div">
            {!showAddSlotCards && (
              <div
                tabIndex={0}
                onKeyDown={handleTabEventwithoutValue(handleAddSlotDropdown)}
                className={isAddSlotDropdownOpen ? 'date-btn-focused' : 'date-btn-drawer'}
                onClick={handleAddSlotDropdown}>
                <span className="placeholder">{SELECT_DATES}</span>
                <img src={Date} className="date-img" />
              </div>
            )}
            {isAddSlotDropdownOpen && (
              <div className="slot-date-filter-drawer">
                <SlotPicker
                  onClose={handleAddSlotDropdown}
                  onSlotAdd={handleAddSlot}
                  showClose={false}
                  addMultipleSlots={true}
                />
              </div>
            )}
            {showAddSlotCards && (
              <SlotDetailsCard
                selectedTimes={selectedSlots}
                userId={userId}
                slotCategory={slotCategory}
                onAddSlot={onAddSlot}
                dateFilter={dateFilter}
                techStackId={parseInt(techStackFilter?.id?.toString())}
                onCancel={toggleDrawer}
                toggleAddSlotCard={toggleAddSlotSelect}
              />
            )}
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default AddSlotForm;
