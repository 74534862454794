import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import CustomSelect, { ISelectOptions } from 'app/core/shared-components/customSelect';
import ImageComponent from 'app/core/shared-components/image';
import Typography from 'app/core/shared-components/typography';
import DeleteEnabled from 'assets/images/delete-enabled.svg';
import Plus from 'assets/images/disabled-plus.svg';
import PlusEnabled from 'assets/images/plus-primary.svg';
import { formatTime, getTime } from 'helpers/dateHelper';

export interface ISlotTime {
  startTime: ISelectOptions | null;
  endTime: ISelectOptions | null;
}
interface IMultipleSlotsProps {
  onChangeTime: Function;
  date: Date | Date[] | null;
  addSlotTimes?: ISlotTime[];
  setDisabled: Function;
}
interface IFormValues {
  date: Date | null;
  slotTime: {
    startTime: string | null | any;
    endTime: string | null | any;
  };
}
const MultipleSlots = (props: IMultipleSlotsProps) => {
  const hookForm = useForm<IFormValues>({
    defaultValues: {
      date: new Date(),
      slotTime: {
        startTime: null,
        endTime: null
      }
    },
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const {
    setValue,
    control,
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors }
  } = hookForm;
  const { onChangeTime, setDisabled, date, addSlotTimes } = props;
  const [selectedTimes, setSelectedTimes] = useState<ISlotTime[]>(
    addSlotTimes
      ? addSlotTimes
      : [
          {
            startTime: null,
            endTime: null
          }
        ]
  );

  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (
      selectedTimes.length &&
      selectedTimes[selectedTimes.length - 1]?.endTime !== null &&
      selectedTimes[selectedTimes.length - 1]?.startTime !== null &&
      date !== null &&
      !error
    ) {
      if (addSlotTimes) {
        setSelectedTimes(addSlotTimes);
      }
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [date]);

  const checkValidTime = () => {
    if (Array.isArray(date)) {
      const sortedDate = date.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      if (sortedDate[0].toDateString() === currentDate?.toDateString() && date.length < 2) {
        if (
          selectedTimes?.length > 1 ||
          (selectedTimes[selectedTimes.length - 1]?.startTime !== null &&
            selectedTimes[selectedTimes.length - 1]?.endTime !== null)
        ) {
          const timeWithoutNull = selectedTimes?.filter((time, index) => time.startTime !== null);
          const validTimes = timeWithoutNull?.filter(
            (time, index) =>
              formatTime(time!.startTime!.label, sortedDate[0]) >
              moment().utcOffset('+05:30').format()
          );
          if (!validTimes.length) {
            setDisabled(true);
            setError('Invalid time selected');
          } else {
            setDisabled(false);
            setError('');
          }
        }
      }
    }
  };

  const handleMultipleSlots = () => {
    setSelectedTimes((prevState) => {
      return [...prevState, { startTime: null, endTime: null }];
    });
    setDisabled(true);
    onChangeTime([...selectedTimes], 'Duplicate Entry');
  };

  const deleteSlots = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    const index = event?.currentTarget?.id;
    const selectedTimesCopy = [...selectedTimes];
    selectedTimesCopy?.splice(parseInt(index), 1);
    setSelectedTimes([...selectedTimesCopy]);
    if (selectedTimesCopy[selectedTimesCopy.length - 1].endTime != null) {
      if (_.uniqWith(selectedTimesCopy, _.isEqual).length !== selectedTimesCopy.length) {
        setDisabled(true);
        onChangeTime([...selectedTimesCopy], 'Duplicate Entry');
        setError('Duplicate Entry');
        return;
      } else {
        setDisabled(false);
        onChangeTime([...selectedTimesCopy], '');
        setError('');
      }
    } else {
      setDisabled(true);
      onChangeTime([...selectedTimes], 'new added');
      return;
    }
    if (
      selectedTimesCopy.length >= 1 &&
      selectedTimesCopy[selectedTimesCopy.length - 1]?.startTime !== null &&
      selectedTimesCopy[selectedTimesCopy.length - 1]?.endTime !== null
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleStartTimeChange = (id: number) => (val: ISelectOptions | ISelectOptions[] | null) => {
    if (!Array.isArray(val) && val !== null) {
      const options = Array.isArray(date)
        ? getTime(date[date.length - 1])
        : getTime(new Date(), true);
      const selectedTimesCopy = [...selectedTimes];
      const startTimeIndex = options.findIndex((item: ISelectOptions) => item.id === val.id);
      selectedTimesCopy.splice(id, 1, {
        startTime: val,
        endTime: options[startTimeIndex + 4]
          ? options[startTimeIndex + 4]
          : options[options.length - 1]
      });
      const timeWithNull = selectedTimesCopy?.filter((time) => time.endTime === null);
      setSelectedTimes((prevState) => [...selectedTimesCopy]);
      if (timeWithNull?.length) {
        setDisabled(true);
        return;
      }

      if (selectedTimesCopy[id].startTime !== null && selectedTimesCopy[id].endTime !== null) {
        if (_.uniqWith(selectedTimesCopy, _.isEqual).length !== selectedTimesCopy.length) {
          setDisabled(true);
          onChangeTime([...selectedTimesCopy], 'Duplicate Entry');
          setError('Duplicate Entry');
          return;
        } else {
          setDisabled(false);
          onChangeTime([...selectedTimesCopy], '');
          setError('');
        }
      }
      onChangeTime([...selectedTimesCopy], '');
      setDisabled(false);
    }
  };

  const handleEndTimeChange = (id: number) => (val: ISelectOptions | ISelectOptions[] | null) => {
    if (!Array.isArray(val)) {
      const selectedTimesCopy = [...selectedTimes];
      selectedTimesCopy.splice(id, 1, { startTime: selectedTimes[id].startTime, endTime: val });
      const timeWithNull = selectedTimesCopy?.filter((time) => time.endTime === null);

      setSelectedTimes((prevState) => [...selectedTimesCopy]);
      if (timeWithNull?.length) {
        setDisabled(true);
        return;
      }

      if (selectedTimesCopy[id].startTime !== null && selectedTimesCopy[id].endTime !== null) {
        if (_.uniqWith(selectedTimesCopy, _.isEqual).length !== selectedTimesCopy.length) {
          setDisabled(true);
          onChangeTime([...selectedTimesCopy], 'Duplicate Entry');
          setError('Duplicate Entry');
          return;
        } else {
          setDisabled(false);
          onChangeTime([...selectedTimesCopy], '');
          setError('');
        }
      }
    }
  };

  return (
    <div className="multi-slot-wrapper">
      {selectedTimes?.map((selectedTime: ISlotTime, index) => {
        const checkDisabledState =
          selectedTime?.startTime !== null && selectedTime?.endTime !== null;

        const startTime = getTime(
          Array.isArray(date)
            ? date?.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0]
            : date
        );

        const startTimeOptions =
          Array.isArray(date) && date.length < 2 ? startTime : getTime(new Date(), true);

        const endTimeOptions =
          selectedTime.startTime !== null
            ? startTimeOptions
                .filter(
                  (time) =>
                    moment(time.label, 'h:mma') > moment(selectedTime.startTime!.label, 'h:mma')
                )
                .slice(1)
            : getTime(Array.isArray(date) ? date[0] : date);

        return (
          <div className="slot-timing_multiple" key={index}>
            <div className="multiple-slot-timings">
              <CustomSelect
                dropdownOptions={startTimeOptions.slice(0, -2)}
                selectedOptions={selectedTime.startTime}
                placeholder="10:00 am"
                onChange={handleStartTimeChange(index)}
                restrictAlphabetOnSearch
                autoScrollToId="16"
                showFullWidth
              />
              <div className="separator"></div>
              <CustomSelect
                dropdownOptions={endTimeOptions}
                selectedOptions={selectedTime.endTime}
                placeholder="11:00 am"
                restrictAlphabetOnSearch
                onChange={handleEndTimeChange(index)}
                showFullWidth
              />
            </div>

            <div className="addMore">
              {selectedTimes.length > 1 && (
                <ImageComponent
                  src={DeleteEnabled}
                  id={index.toString()}
                  onClick={deleteSlots}
                  customClass="time-delete"
                />
              )}
              {selectedTimes.length - 1 === index ? (
                <ImageComponent
                  src={checkDisabledState && !error ? PlusEnabled : Plus}
                  onClick={checkDisabledState && !error ? handleMultipleSlots : () => {}}
                  customClass="time-add"
                />
              ) : (
                <div className="time-add-btn"></div>
              )}
            </div>
          </div>
        );
      })}
      {!!error && (
        <Typography variant="error" customStyle="duplicate-time-error">
          {error}
        </Typography>
      )}
    </div>
  );
};
export default React.memo(MultipleSlots);
