import React, { Fragment, useCallback, useMemo, useState } from 'react';
import './index.style.scss';
import moment from 'moment';
import Typography from 'app/core/shared-components/typography';
import ImageComponent from 'app/core/shared-components/image';
import Images from 'assets/images';
import InputBox from 'app/core/shared-components/input-box';
import CheckBox from 'app/core/shared-components/checkbox';
import Button from 'app/core/shared-components/button';
import Radio from 'app/core/shared-components/radio-group';
import {
  CANCELLATION_OPTION,
  CANCELLATION_OPTION_TYPE,
  CANDIDATE_BUSY,
  OTHER_BUSY,
  PANEL_BUSY,
  SLOT_CANCEL_OPTION
} from 'helpers/constants';
import { timeStamps } from 'helpers/timeStamp.constants';

interface ICancelCardProps {
  start: Date;
  end: Date;
  onCloseClick?: () => void;
  onCancelClick?: (param: ICancel) => void;
  showMakeSlotAvailableCheckbox?: boolean;
  showCancelledBy?: boolean;
}

export interface ICancelCard {
  cancelComments: string;
  makeSlotAvailable: boolean;
}

export interface ICancel extends ICancelCard {
  cancelComments: string;
  makeSlotAvailable: boolean;
  cancelOption: CANCELLATION_OPTION_TYPE;
}

interface ICancelCardStates extends ICancelCard {
  label: string;
  cancelOption: string;
}

function CancelCard(props: ICancelCardProps) {
  const [cancelCardState, setCancelCardState] = useState<ICancelCardStates>({
    label: `${moment(props.start).format(timeStamps.HH_MM_A)} - ${moment(props.end).format(
      timeStamps.HH_MM_A
    )} `,
    cancelComments: '',
    makeSlotAvailable: false,
    cancelOption: PANEL_BUSY
  });

  const disableCancelButton = useMemo(() => {
    const { cancelComments, cancelOption } = cancelCardState;
    if (cancelOption === PANEL_BUSY || cancelOption === CANDIDATE_BUSY) return false;
    else if (cancelOption === OTHER_BUSY && cancelComments.toString().trim().length) return false;
    else return true;
  }, [cancelCardState.cancelComments, cancelCardState.cancelOption]);

  const handleChange = (param: string | number) => {
    const regex = new RegExp(/^[A-Za-z,.!\s]*$/);
    if (regex.test(param.toString()))
      setCancelCardState((prevState) => ({ ...prevState, cancelComments: param.toString() }));
  };

  const handleCheckBoxClick = () => {
    setCancelCardState((prevState) => ({
      ...prevState,
      makeSlotAvailable: !prevState.makeSlotAvailable
    }));
  };

  const handleCancelClick = () => {
    if (moment(props.end).isAfter(moment()) && cancelCardState.cancelOption?.length) {
      let cancelledBy: CANCELLATION_OPTION_TYPE = CANCELLATION_OPTION.panel;
      if (cancelCardState.cancelOption?.toString()?.toUpperCase() === CANDIDATE_BUSY.toUpperCase())
        cancelledBy = CANCELLATION_OPTION.candidate;
      else if (cancelCardState.cancelOption?.toString()?.toUpperCase() === OTHER_BUSY.toUpperCase())
        cancelledBy = CANCELLATION_OPTION.other;
      props.onCancelClick &&
        props.onCancelClick({
          cancelComments: cancelCardState.cancelComments.trim(),
          makeSlotAvailable: cancelCardState.makeSlotAvailable,
          cancelOption: cancelledBy
        });
    }
  };

  const handleRadioChange = useCallback((val: string) => {
    setCancelCardState((prevState) => ({
      ...prevState,
      cancelOption: val
    }));
  }, []);

  return (
    <div className="slot-cancel-card-wrapper">
      <div className="slot-cancel-card-header">
        <Typography customStyle="slot-cancel-title">{cancelCardState.label}</Typography>
        <div className="close-icon-wrapper" onClick={props.onCloseClick}>
          <ImageComponent src={Images.close} alt="Close Icon" />
        </div>
      </div>
      <div className="slot-cancel-card-main">
        <div className="slot-cancel-card-content">
          {props.showCancelledBy && (
            <div className="slot-cancel-mark-wrapper">
              <Typography customStyle="cancel-reason-label">Reason for cancellation</Typography>
              <Radio
                labelArray={SLOT_CANCEL_OPTION}
                setCurrentValue={handleRadioChange}
                currentValue={cancelCardState.cancelOption}
              />
            </div>
          )}
          <Typography customStyle="cancel-reason-label">Remark</Typography>
          <InputBox
            multiline
            placeholder="Please enter a reason for slot cancellation"
            customClass="cancel-input-box"
            value={cancelCardState.cancelComments}
            handleChange={handleChange}
          />
          {props.showMakeSlotAvailableCheckbox && cancelCardState.cancelOption !== PANEL_BUSY && (
            <div className="slot-cancel-checkbox-wrapper">
              <CheckBox
                handleClick={handleCheckBoxClick}
                label="Make the slot available again"
                customClass="slot-available-label"
              />
            </div>
          )}
        </div>
        <Button
          variant="outlined"
          customStyle="slot-cancel-card-action-btn"
          disabled={disableCancelButton || !moment(props.end).isAfter(moment())}
          onClick={handleCancelClick}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default CancelCard;
