import React, { useEffect, useState } from 'react';
import { Moment } from 'moment';
import { v4 as uuid } from 'uuid';
import ImageComponent from 'app/core/shared-components/image';
import InputBox from 'app/core/shared-components/input-box';
import { ISelectValue } from 'app/core/shared-components/select';
import Modal from 'app/core/shared-components/modal';
import ComponentLoader from '../loader-component';
import Button from 'app/core/shared-components/button';
import Typography from 'app/core/shared-components/typography';
import ClippedSelect, { IClippedSelectValue } from 'app/core/shared-components/clipped-select';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import Images from 'assets/images';
import { postSlotRequirement } from 'app/redux/slices/adminDashboardSlice';
import { notify } from 'helpers/toastHelper';
import { handleTabEventwithoutValue } from 'helpers/utils';
import { LOP_LABEL, SLOT_COUNT_LIMIT, TECH_STACK_LABEL } from 'helpers/constants';
import { SLOT_COUNT_LIMIT_DESC, SLOT_LESS_THAN_DESC } from 'helpers/messages.constants';
import './index.style.scss';
interface ISlotRequirementProps {
  onClose: () => void;
  onAdd: () => void;
  techStack: ISelectValue;
  level: ISelectValue;
  monthData: Moment;
}
interface ISlotRequirementStates {
  isError: string | null;
  selectCount: number;
  selected: IClippedSelectValue[];
  techStackOptions: ISelectValue[];
  levelOfInterviewOptions: ISelectValue[];
}

const SlotRequirementForm = (props: ISlotRequirementProps) => {
  const { onClose, techStack, level, monthData } = props;
  const [slotCount, setSlotCount] = useState<string>('');
  const [stackLoi, setStackLoi] = useState<ISlotRequirementStates>({
    isError: null,
    selectCount: 1,
    selected: [],
    techStackOptions: [],
    levelOfInterviewOptions: []
  });
  const { techStackOptions } = stackLoi;

  const { techStackLevelSet, role } = useAppSelector((state) => state.user.userData);
  const { techStack: techStackList } = useAppSelector((state) => state.shared);
  const { levelOfInterview } = useAppSelector((state) => state.shared);

  const dispatch = useAppThunkDispatch();

  const handleSlotCountChange = (value: string | number) => {
    const regex = /^[0-9\b]+$/;
    if (value === '' || regex.test(value.toString())) {
      setSlotCount(value.toString());
    }
  };

  const handleStackLoiChange = (params: IClippedSelectValue) => {
    setStackLoi((prevState) => ({ ...prevState, selected: [params] }));
  };

  const handleAddRequirement = async () => {
    const monthNumber = parseInt(monthData.format('M'));
    const yearNumber = parseInt(monthData.format('YYYY'));
    const techStackId = techStack?.id?.toString();
    const levelId = level?.id?.toString();
    const requirementData = {
      month: monthNumber,
      year: yearNumber,
      techStack: stackLoi?.selected[0]?.firstChipValue?.id?.toString() || techStackId,
      level: stackLoi?.selected[0]?.secondChipValue?.id?.toString() || levelId,
      noOfSlots: parseInt(slotCount)
    };

    await dispatch(postSlotRequirement(requirementData));
    notify(true, `Successfully added ${slotCount} slots to the slot requirement.`, uuid());
    onClose();
    if (
      techStackId === (stackLoi?.selected[0]?.firstChipValue?.id?.toString() || techStackId) &&
      levelId === (stackLoi?.selected[0]?.secondChipValue?.id?.toString() || levelId)
    ) {
      props.onAdd();
    }
  };

  const handleMonthChange = () => {};

  const handleMapData = (data: any, TSList: boolean, TSLevelList: boolean) => {
    return data?.map((item: any) => ({
      id: TSList ? item?.id : TSLevelList ? item?.techStackId : item?.id,
      label: TSList ? item?.stack : TSLevelList ? item?.techStackName : item?.level
    }));
  };
  const handleListValues = () => {
    const isAdminRole = role.length === 1 && !!role.filter((item) => item.id === 1).length;
    let techStackListData: ISelectValue[] = [];
    if (isAdminRole) {
      techStackListData = handleMapData(techStackList, true, false);
    } else {
      techStackListData = handleMapData(techStackLevelSet, false, true);
    }
    const levelOfInterviewList = handleMapData(levelOfInterview, false, false);
    setStackLoi((prevState) => ({
      ...prevState,
      techStackOptions: techStackListData,
      levelOfInterviewOptions: levelOfInterviewList
    }));
  };

  useEffect(() => {
    handleListValues();
  }, []);

  return (
    <Modal
      customStyle="slot-requirement-modal"
      onClose={onClose}
      header="Slot Requirement"
      isOpen={true}>
      {false ? (
        <ComponentLoader customStyle="slot-loading-wrapper" />
      ) : (
        <div>
          <div tabIndex={0} onKeyDown={handleTabEventwithoutValue(onClose)} className="closeIcon">
            <ImageComponent src={Images.close} onClick={onClose} customClass="modal-close-icon" />
          </div>

          <div className="slot-requirement-form">
            <div className="form-field date-input ">
              <Typography variant="input-label">Date*</Typography>
              <InputBox
                value={monthData.format('MMMM YYYY')}
                onChange={handleMonthChange}
                disabled
              />
            </div>

            <div className="form-field">
              <div className="stackLoi-row-container">
                <Typography customStyle="input-label">{TECH_STACK_LABEL}</Typography>
                <Typography customStyle="input-label">{LOP_LABEL}</Typography>
              </div>
              <ClippedSelect
                id={uuid()}
                value={{
                  firstChipValue: stackLoi?.selected[0]?.firstChipValue || techStack,
                  secondChipValue: stackLoi?.selected[0]?.secondChipValue || level
                }}
                firstChipOption={stackLoi?.techStackOptions}
                disableFirstChip={techStackOptions?.length < 2}
                secondChipOption={stackLoi?.levelOfInterviewOptions}
                onChange={handleStackLoiChange}
                tabIndex={true}
              />
            </div>
            <div className="form-field">
              <Typography variant="input-label">Number of Slots Required*</Typography>
              <InputBox value={slotCount} handleChange={handleSlotCountChange} autoFocus />
              {parseInt(slotCount) > SLOT_COUNT_LIMIT && (
                <Typography variant="error">{SLOT_COUNT_LIMIT_DESC}</Typography>
              )}
              {parseInt(slotCount) <= 0 && (
                <Typography variant="error">{SLOT_LESS_THAN_DESC}</Typography>
              )}
            </div>
            <div className="modal-buttons">
              <Button variant="outlined" customStyle="cancel-button" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                customStyle="requirement-button"
                onClick={handleAddRequirement}
                disabled={
                  !slotCount.length ||
                  parseInt(slotCount) > SLOT_COUNT_LIMIT ||
                  parseInt(slotCount) <= 0
                }>
                Add
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
export default SlotRequirementForm;
