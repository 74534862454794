import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ONBOARD } from '../constant';
import { DEFAULTPAGESIZE } from '../../../helpers/constants';
import {
  fetchOnboardList,
  IOnboardUserExperienceFilter,
  fetchUserDetails,
  postOnboardUser,
  IOnboardUserData,
  onboardSearch
} from '../../services/onboardUser.service';
import { IUserDataByIdType } from '../../services/user-response.types';

export interface IUserDetail {
  userId: number;
  firstName: string;
  lastName: string;
  emailId: string;
  designation: string;
  experience: number;
  profileImageUrl: string;
  mobileNo: string;
}

export interface IOnboardPendingList {
  pageCount: number;
  totalCount: number;
  userApprovalDTOList: IUserDetail[];
}

export interface IOnboardUserStates {
  filtersApplied: IOnboardUserExperienceFilter[];
  currentPageNumber: number;
  availablePageCount: number;
  isLoading: boolean;
  isError: boolean;
  showToast: string;
  pendingList: IUserDetail[];
  userDetails: IUserDataByIdType | null | any;
  searchOptions: IUserDetail[];
  navigateTo: string;
  userId: number[] | string[] | null;
  showDrawer: boolean;
  resultNotFound: boolean;
  isOnboardingDrawerLoading?: boolean;
  totalCount: number;
}

const initialState: IOnboardUserStates = {
  filtersApplied: [],
  currentPageNumber: 0,
  availablePageCount: 0,
  isLoading: false,
  isError: false,
  showToast: '',
  pendingList: [],
  userDetails: null,
  searchOptions: [],
  navigateTo: '/',
  userId: null,
  showDrawer: false,
  resultNotFound: false,
  isOnboardingDrawerLoading: false,
  totalCount: 0
};

interface IGetOnboardParams {
  pageNumber?: number;
  pageSize?: number;
  filter?: IOnboardUserExperienceFilter[];
}

export const getSpecificUserDetails = createAsyncThunk(
  `${ONBOARD}/getSpecificUserDetails`,
  async (userId: number | string, thunkAPI) => {
    const response = await fetchUserDetails(userId);
    if (!response.error) {
      return response.data;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const getOnboardUserList = createAsyncThunk(
  `${ONBOARD}/getOnboardUserList`,
  async (params: IGetOnboardParams, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { onboardUser } = state;
    const {
      pageNumber = onboardUser.currentPageNumber,
      pageSize = DEFAULTPAGESIZE,
      filter = onboardUser.filtersApplied
    } = params;
    const response = await fetchOnboardList(pageNumber, pageSize, filter);
    if (!response.error) {
      const update = Object.assign({ pageNumber, filter }, response.data);
      return update;
    }
    return thunkAPI.rejectWithValue(response.error);
  }
);

interface IGetOnboard {
  userId: (number | string)[];
  data: IOnboardUserData;
}

export const onboardUser = createAsyncThunk(
  `${ONBOARD}/onboardUser`,
  async (params: IGetOnboard, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const { onboardUser, shared } = state;
    const response = await postOnboardUser(params.userId, params.data);
    if (!response.error) {
      const toastContent =
        params.userId.length > 1
          ? `Successfully onboarded ${params.userId.length} users.`
          : `Successfully onboarded ${onboardUser.userDetails.firstName} ${onboardUser.userDetails.lastName}`;
      const navigateTo =
        params.data.roleId.toString() === shared.recruiter?.id.toString()
          ? '/talentAcquisition'
          : '/panel';
      return {
        toastContent,
        navigateTo
      };
    }
    return thunkAPI.rejectWithValue(response.error);
  }
);

export const onboardUserSearch = createAsyncThunk(
  `${ONBOARD}/search`,
  async (params: any, thunkAPI) => {
    const { searchString, abortSignal } = params;
    const data = await onboardSearch(searchString, abortSignal.signal);
    if (!data.error) {
      return data.data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const onboardUserSlice = createSlice({
  name: ONBOARD,
  initialState,
  reducers: {
    openDrawer: (state, action) => {
      state.userId = action.payload;
      state.showDrawer = true;
    },
    closeDrawer: (state) => {
      state.userId = null;
      state.showDrawer = false;
      state.userDetails = null;
    },
    resetPage: (state) => {
      state.availablePageCount = 0;
      state.currentPageNumber = 0;
      state.filtersApplied = [];
      state.isLoading = false;
      state.pendingList = [];
      state.searchOptions = [];
      state.isError = false;
      state.userDetails = null;
      state.showToast = '';
      state.navigateTo = '/';
    },
    toggleToast: (state, action) => {
      state.showToast = action.payload;
      state.navigateTo = '/';
    }
  },
  extraReducers(builder) {
    builder.addCase(getOnboardUserList.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(getOnboardUserList.fulfilled, (state, action: any) => {
      state.isLoading = false;
      state.pendingList = action.payload?.userApprovalDTOList;
      state.availablePageCount = Math.ceil(action.payload?.totalCount / DEFAULTPAGESIZE);
      state.currentPageNumber = action.payload?.pageNumber;
      state.filtersApplied = action.payload?.filter;
      state.userId = null;
      state.showDrawer = false;
      state.totalCount = action.payload?.totalCount;
    });
    builder.addCase(getOnboardUserList.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(onboardUser.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isOnboardingDrawerLoading = true;
    });
    builder.addCase(onboardUser.fulfilled, (state, action: any) => {
      state.isLoading = false;
      state.showToast = action.payload.toastContent;
      state.navigateTo = action.payload.navigateTo;
      state.isOnboardingDrawerLoading = false;
    });
    builder.addCase(onboardUser.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isOnboardingDrawerLoading = false;
    });
    builder.addCase(getSpecificUserDetails.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(getSpecificUserDetails.fulfilled, (state, action: any) => {
      state.userDetails = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getSpecificUserDetails.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(onboardUserSearch.fulfilled, (state, action: any) => {
      state.searchOptions = action.payload;
      state.resultNotFound = false;
    });
    builder.addCase(onboardUserSearch.rejected, (state) => {
      state.resultNotFound = true;
    });
  }
});

export const { resetPage, toggleToast, openDrawer, closeDrawer } = onboardUserSlice.actions;
export default onboardUserSlice.reducer;
