import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Typography from 'app/core/shared-components/typography';
import Button from 'app/core/shared-components/button';
import Modal from 'app/core/shared-components/modal';
import ClickAwayListener from 'app/core/shared-components/click-away';
import ImageComponent from 'app/core/shared-components/image';
import UserList from 'app/core/shared-components/users-list';
import Images from 'assets/images';
import './index.style.scss';
import {
  ASCII,
  CANCEL_BUTTON_TEXT,
  DELETE_BUTTON_TEXT,
  EDIT_BUTTON_TEXT,
  NUMBER_OF_PANELS_TEXT,
  PROJECT_LABEL,
  PULSE_ANIMATION_TIMEOUT,
  SAVE_BUTTON_TEXT
} from 'helpers/constants';
import {
  PROJECT_NAME_CONTAINS_SPECIAL_CHAR_ERROR,
  INVALID_PROJECT_NAME_ERROR,
  PROJECT_DELETE_MODAL_TEXT,
  PROJECT_DELETE_CONFIRMATION_TEXT
} from 'helpers/messages.constants';
import { PROJECT_CONTAINS_SPECIAL_CHARACTER } from 'helpers/regex.constants';
import {
  clearSearchBarValue,
  editProjects,
  getUsersListProjects,
  handleResetPage
} from 'app/redux/slices/projectSlice';
import { getProjects, setSelectedNotificationData } from 'app/redux/slices/sharedSlice';
import { useAppThunkDispatch, useAppSelector } from 'app/redux/hooks';
import { handleTabEventwithoutValue } from 'helpers/utils';
import { IProjectType } from 'app/services/user-response.types';

interface IProjectCardProps {
  projectCard: IProjectType;
  onDeleteThroughCard: (param: IProjectType) => void;
}

interface IProjectCardState {
  showEditModal: boolean;
  showDeleteModal: boolean;
  projectValue: string;
  showDropdown: boolean;
  projectNameError: string;
  disabled: boolean;
  errorToast: string;
  isToastVisible: boolean;
}

export function ProjectCard({ projectCard, onDeleteThroughCard }: IProjectCardProps) {
  const { userData, isUserListLoading, count } = useAppSelector((state) => state.project);
  const [projectCardState, setProjectCardState] = useState<IProjectCardState>({
    showEditModal: false,
    showDeleteModal: false,
    projectValue: '',
    showDropdown: false,
    projectNameError: '',
    disabled: true,
    errorToast: '',
    isToastVisible: false
  });
  const dispatch = useAppThunkDispatch();
  const { projects, selectedNotificationData } = useAppSelector((state) => state?.shared);

  const {
    showDropdown,
    showDeleteModal,
    showEditModal,
    projectValue,
    projectNameError,
    disabled,
    errorToast
  } = projectCardState;

  const { ellipsis } = Images;
  const [openUsersList, setOpenUsersList] = useState(false);

  const isProjectIdEqual = selectedNotificationData?.id === projectCard?.id;

  const handleDropdown = () => {
    setProjectCardState({ ...projectCardState, showDropdown: !projectCardState?.showDropdown });
  };

  const closeDropdown = useCallback(() => {
    if (showDropdown) {
      setProjectCardState((prevState) => ({ ...prevState, showDropdown: false }));
    }
  }, [showDropdown, showEditModal]);

  const handleEditModal = () => {
    setProjectCardState({
      ...projectCardState,
      projectValue: projectCard?.name,
      showEditModal: !showEditModal,
      showDropdown: false
    });
  };

  const handleEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = event?.target?.value;
    setProjectCardState({
      ...projectCardState,
      projectValue: currentValue,
      projectNameError: '',
      disabled: currentValue?.trim() && currentValue?.trim() !== projectCard?.name ? false : true
    });
    if (currentValue?.length > 30) {
      setProjectCardState({
        ...projectCardState,
        projectNameError: ''
      });
    } else if (PROJECT_CONTAINS_SPECIAL_CHARACTER.test(currentValue)) {
      setProjectCardState({
        ...projectCardState,
        projectNameError: PROJECT_NAME_CONTAINS_SPECIAL_CHAR_ERROR,
        projectValue: currentValue,
        disabled: true
      });
    }
  };

  const onProjectEdit = async () => {
    const { id } = projectCard;
    const { projectValue, projectNameError } = projectCardState;
    const isProjectExists = projects?.filter(
      (project) =>
        project?.name?.toUpperCase() ===
        projectValue
          ?.replace(/\s{2,}/g, ' ')
          ?.trim()
          ?.toUpperCase()
    );
    if (!isProjectExists?.length) {
      const data = await dispatch(
        editProjects({ id: id, projectName: projectValue?.replace(/\s{2,}/g, ' ')?.trim() })
      ).unwrap();
      if (!data?.error) {
        dispatch(handleResetPage());
        dispatch(clearSearchBarValue());
        dispatch(getProjects());
        handleEditModal();
      } else {
        setProjectCardState({
          ...projectCardState,
          projectNameError: data?.error[0]?.message,
          disabled: true
        });
      }
    } else {
      setProjectCardState({
        ...projectCardState,
        projectNameError: INVALID_PROJECT_NAME_ERROR,
        disabled: true
      });
    }
  };

  const handleDeleteModal = () => {
    setProjectCardState({
      ...projectCardState,
      showDeleteModal: !showDeleteModal,
      showDropdown: false
    });
  };

  const onDeleteItem = async () => {
    onDeleteThroughCard(projectCard);
    setProjectCardState({ ...projectCardState, showDeleteModal: false });
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === ASCII.enter && !disabled) {
      onProjectEdit();
    }
  };

  const getProjectEditModal = () => {
    return (
      <Modal
        header="Edit Project"
        customStyle="edit-modal"
        isOpen={projectCardState?.showEditModal}
        onClose={handleEditModal}>
        <div className="edit-modal-content">
          <div>
            <label htmlFor="projectName" className="delete-modal--project-label">
              {PROJECT_LABEL}
            </label>
            <br />
            <input
              autoFocus
              type="text"
              name="projectName"
              className="project-name"
              value={projectValue}
              onChange={handleEdit}
              autoComplete="off"
              required
              onKeyUp={handleKeyUp}
            />
            <br />
            {projectNameError && (
              <Typography variant="error" customStyle="project-error">
                {projectNameError}
              </Typography>
            )}
          </div>
          <div className="edit-modal-footer">
            <Button customStyle="edit-modal--cancel-btn" onClick={handleEditModal}>
              {CANCEL_BUTTON_TEXT}
            </Button>
            <Button
              customStyle="edit-modal--save-btn"
              id={projectCard.id}
              onClick={onProjectEdit}
              variant="contained"
              disabled={disabled}>
              {SAVE_BUTTON_TEXT}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const getProjectDeleteModal = () => {
    return (
      <Modal
        header="Delete Project"
        customStyle="edit-modal"
        isOpen={projectCardState?.showDeleteModal}
        onClose={handleDeleteModal}>
        <div className="edit-modal-content">
          <Typography customStyle="delete-modal-para">{PROJECT_DELETE_MODAL_TEXT}</Typography>
          <Typography customStyle="delete-modal-confirm-text">
            {PROJECT_DELETE_CONFIRMATION_TEXT}
          </Typography>
          <div className="edit-modal-footer">
            {errorToast && <Typography variant="error">{errorToast}</Typography>}
            <Button customStyle="edit-modal--cancel-btn" onClick={handleDeleteModal}>
              {CANCEL_BUTTON_TEXT}
            </Button>
            <Button
              customStyle="edit-modal--save-btn"
              id={projectCard.name}
              variant="contained"
              onClick={onDeleteItem}>
              {DELETE_BUTTON_TEXT}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const showDropDown = () => {
    return (
      <div className="dropdown">
        <div
          className="dropdown-option"
          onClick={handleEditModal}
          tabIndex={0}
          onKeyDown={handleTabEventwithoutValue(handleEditModal)}>
          <span>{EDIT_BUTTON_TEXT}</span>
        </div>
        <div className="divider"></div>
        <div
          className="dropdown-option"
          onClick={handleDeleteModal}
          tabIndex={0}
          onKeyDown={handleTabEventwithoutValue(handleDeleteModal)}>
          <span>{DELETE_BUTTON_TEXT}</span>
        </div>
      </div>
    );
  };

  const handleUsersList = () => {
    setOpenUsersList(!openUsersList);
  };

  const getList = (id: string) => {
    dispatch(getUsersListProjects(id));
  };

  const getUsersListModal = () => {
    return (
      <Modal customStyle="user-list-modal" onClose={handleUsersList} isOpen={openUsersList}>
        <div className="edit-modal-content">
          <UserList
            title={projectCard?.name?.toString()}
            onClose={handleUsersList}
            totalCount={count}
            listType={'project'}
            id={projectCard?.id}
            isTabView={false}
            handleGetList={getList}
            listData={userData}
            isLoading={isUserListLoading}
          />
        </div>
      </Modal>
    );
  };

  const haveNotificationDataInView = () => {
    if (selectedNotificationData?.id) {
      document
        .getElementById(selectedNotificationData?.id)
        ?.scrollIntoView({ behavior: 'auto', block: 'center' });
      setTimeout(() => {
        dispatch(setSelectedNotificationData({ id: '' }));
      }, PULSE_ANIMATION_TIMEOUT);
    }
  };

  useEffect(() => {
    haveNotificationDataInView();

    return () => {
      dispatch(setSelectedNotificationData({ id: '' }));
    };
  }, []);

  return (
    <Fragment>
      <div
        className={`project-item ${isProjectIdEqual ? 'selected-project' : ''}`}
        id={isProjectIdEqual ? projectCard?.id : ''}>
        <div className="project--detail">
          <Typography variant="text" customStyle="project--name">
            {projectCard?.name}
          </Typography>
          {projectCard?.name.length > 10 && (
            <div className="project-tooltip">
              {projectCard?.name}
              <span className="project-tooltip-arrow" />
            </div>
          )}
          <span onClick={handleUsersList}>
            <Typography variant="text" customStyle="project--sub-detail">
              {`${NUMBER_OF_PANELS_TEXT} ${projectCard?.numberOfPanel}`}
            </Typography>
          </span>
        </div>
        <ClickAwayListener handleClose={closeDropdown} customClass="project--dropdown">
          <div
            className="dots"
            onClick={handleDropdown}
            tabIndex={0}
            onKeyDown={handleTabEventwithoutValue(handleDropdown)}>
            <ImageComponent
              src={ellipsis}
              alt="3-dots"
              className={`ellipsis ${showDropdown && 'active-btn-ellipsis'}`}
            />
          </div>
          {projectCardState.showDropdown && showDropDown()}
        </ClickAwayListener>
      </div>
      {getProjectEditModal()}
      {getProjectDeleteModal()}
      {getUsersListModal()}
    </Fragment>
  );
}

export default ProjectCard;
