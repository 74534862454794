import React from 'react';
import './index.style.scss';
import SlotDetailContainer, { SlotStrip } from '.';
import { Moment } from 'moment';
import { YELLOW } from 'helpers/constants';
import { timeStamps } from 'helpers/timeStamp.constants';

interface ISlotRequestedEvents {
  start: Moment;
  end: Moment;
}

interface ISlotRequested {
  events: ISlotRequestedEvents[];
}

function SlotRequestedContainer(props: ISlotRequested) {
  const { events } = props;
  return (
    <SlotDetailContainer title="Requested Slots">
      {events.map((item, index) => (
        <SlotStrip
          key={index}
          title={`${item.start.format(timeStamps.HH_MM_A)} - ${item.end.format(
            timeStamps.HH_MM_A
          )}`}
          borderColor={YELLOW}
        />
      ))}
    </SlotDetailContainer>
  );
}

export default SlotRequestedContainer;
