import Images from '../../../../../assets/images';
import Button from '../../../../core/shared-components/button';
import HorizontalSlider from '../../../../core/shared-components/horizontal-scroll';
import ImageComponent from '../../../../core/shared-components/image';
import React, { useEffect, useState } from 'react';
import Typography from '../../../../core/shared-components/typography';
import './index.style.scss';
import { useAppSelector, useAppThunkDispatch } from '../../../../redux/hooks';
import {
  getUpcomingInterviews,
  IUpcomingInterviewData,
  toggleCancelModal
} from '../../../../redux/slices/adminDashboardSlice';
import UpcomingInterviewDetails from './UpcomingInterviewDetails';
import ComponentLoader from '../loader-component';
import InterviewCancelCard from '../interview-cancel-card';

const UpcomingInterviews = (props: any) => {
  const {
    upcomingInterviewData,
    isUpcomingInterviewLoading,
    currentSelectedInterview,
    isCancelModalOpen
  } = useAppSelector((state: any) => state.adminDashboard);
  const dispatch = useAppThunkDispatch();
  const { userId } = useAppSelector((state) => state.user.userData);
  const { upcomingEmptyCheck } = Images;

  const [upcomingInterviews, setUpcomingInterview] = useState<IUpcomingInterviewData[]>(
    [] as IUpcomingInterviewData[]
  );

  useEffect(() => {
    dispatch(getUpcomingInterviews(userId.toString()));
  }, []);

  useEffect(() => {
    setUpcomingInterview(upcomingInterviewData);
  }, [upcomingInterviewData]);

  const handleCancelBooking = (id: string) => {
    const currentInterviews = upcomingInterviewData?.filter(
      (data: IUpcomingInterviewData) => data.slotId.toString() !== id.toString()
    );
    setUpcomingInterview(currentInterviews);
  };

  const handleCancelModalClose = () => {
    dispatch(toggleCancelModal([]));
  };

  return (
    <div className="upcoming-interview">
      <div className="header">
        <Typography variant="headline-16" customStyle="upcoming-interview-header">
          Upcoming Interviews
        </Typography>
      </div>
      <div className="interview-details">
        {isUpcomingInterviewLoading ? (
          <ComponentLoader />
        ) : upcomingInterviewData?.length !== 0 ? (
          <HorizontalSlider>
            {upcomingInterviews?.map((item: IUpcomingInterviewData, index: number) => (
              <UpcomingInterviewDetails upcomingInterview={item} key={index} />
            ))}
          </HorizontalSlider>
        ) : (
          <div className="no-upcoming-interviews">
            <ImageComponent src={upcomingEmptyCheck} />
            <div className="details">
              <Typography variant="headline-24">No Upcoming Interviews</Typography>
              {props.userRole !== 'admin' && (
                <Typography variant="headline-16" customStyle="supporting-text">
                  Add more available slots to have more interviews!
                </Typography>
              )}
            </div>
          </div>
        )}
      </div>
      {isCancelModalOpen && (
        <InterviewCancelCard
          slotId={currentSelectedInterview?.slotId}
          onCloseClick={handleCancelModalClose}
          onCancelClick={handleCancelBooking}
        />
      )}
    </div>
  );
};
export default UpcomingInterviews;
