import React from 'react';
import withRouter, { IWithRouter } from '../../../router/withRouter';
import Button from '../../core/shared-components/button';
import Typography from '../../core/shared-components/typography';
import ImageComponent from '../../core/shared-components/image';
import { ErrorPageRoutes } from './errorPageRoutes';
import './index.style.scss';
import { googleLogout } from '@react-oauth/google';
import { getDataFromSession } from '../../../helpers/authHelper';

interface IErrorProps {
  router: IWithRouter;
  page?: string;
}

class ErrorPage extends React.Component<IErrorProps> {
  constructor(props: any) {
    super(props);

    this.backButtonHandler = this.backButtonHandler.bind(this);
    this.refreshButtonHandler = this.refreshButtonHandler.bind(this);
    this.homeButtonHandler = this.homeButtonHandler.bind(this);
    this.loginButtonHandler = this.loginButtonHandler.bind(this);
  }

  backButtonHandler() {
    history.back();
  }

  refreshButtonHandler() {
    window.location.reload();
  }

  homeButtonHandler() {
    const { navigate } = this.props.router;
    if (getDataFromSession('access-token')) {
      navigate('/home', { replace: true });
    } else {
      navigate('/login', { replace: true });
    }
  }

  loginButtonHandler() {
    const { navigate } = this.props.router;
    sessionStorage.clear();
    localStorage.clear();
    localStorage.removeItem('persist:root');
    window.localStorage.clear();
    googleLogout();
    navigate('/login');
    window.location.reload();
  }

  render() {
    const page = this.props.page || 'error';
    const Icon = ErrorPageRoutes[page].pageIcon;
    return (
      <div className="page">
        <div className="page-main">
          <Icon className="page-Icon" />
          <Typography customStyle="page--headingText" variant="title">
            {ErrorPageRoutes[page].pageHeading}
          </Typography>
          <Typography customStyle="page--bodyText" variant="body1">
            {ErrorPageRoutes[page].pageContent}
          </Typography>
          <div className="page-button--base">
            {ErrorPageRoutes[page].backButton && (
              <Button
                size="2xl"
                onClick={this.backButtonHandler}
                customStyle="page--backButton"
                startIcon={ErrorPageRoutes[page].backButtonStartIcon}
                endIcon={ErrorPageRoutes[page].backButtonEndIcon}>
                <Typography variant="text" customStyle="backButton-text--style">
                  {ErrorPageRoutes[page].backButton}
                </Typography>
              </Button>
            )}

            {ErrorPageRoutes[page].refreshButton && (
              <Button
                startIcon={ErrorPageRoutes[page].refreshButtonStartIcon}
                endIcon={ErrorPageRoutes[page].refreshButtonEndIcon}
                onClick={
                  page === 'error'
                    ? this.homeButtonHandler
                    : page === 'authError'
                    ? this.loginButtonHandler
                    : this.refreshButtonHandler
                }
                customStyle="page--refreshButton"
                size="2xl">
                <Typography variant="text" customStyle="refreshButton-text--style">
                  {ErrorPageRoutes[page].refreshButton}
                </Typography>
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ErrorPage);
