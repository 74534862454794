import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  projectEdit,
  addProject,
  deleteProject,
  projectService,
  searchProject,
  userListProject,
  userListSearchProject
} from '../../services/project.service';
import {
  ADD_PROJECT,
  DELETE_PROJECT,
  EDIT_PROJECT,
  PROJECT,
  USERS_LIST_PROJECT,
  USERS_LIST_SEARCH_PROJECT
} from '../constant';
import { SEARCH } from '../../../helpers/constants';

export interface IProjectState {
  projectData: any;
  selectedSearch: any;
  clearResult: boolean;
  isLoading: boolean;
  resetPage: boolean;
  isError: boolean;
  currentDeletion: any;
  isToastVisible: boolean;
  isUserListLoading?: boolean;
  userData?: [];
  count?: number;
}
const initialState: IProjectState = {
  projectData: [],
  selectedSearch: {},
  clearResult: false,
  isLoading: false,
  resetPage: false,
  isError: false,
  currentDeletion: {},
  isToastVisible: false,
  isUserListLoading: false,
  userData: [],
  count: 0
};
interface ProjectUserListType {
  id: string;
  searchText: string;
}

export const getProjectData = createAsyncThunk(
  PROJECT,
  async function projectData(sortingOrder: string, thunkAPI) {
    const resp = await projectService(sortingOrder);
    if (!resp?.error) {
      return resp.data;
    }
    return thunkAPI.rejectWithValue(resp);
  }
);
export const deleteProjects = createAsyncThunk(
  DELETE_PROJECT,
  async function deleteProjects(id: any, thunkAPI) {
    thunkAPI.dispatch(setLoadingState(true));
    const resp = await deleteProject(id);
    if (!resp?.data !== null) {
      thunkAPI.dispatch(setLoadingState(false));
      return resp;
    }
    thunkAPI.dispatch(setLoadingState(false));
    return thunkAPI.rejectWithValue(resp);
  }
);
export const getUsersListProjects = createAsyncThunk(
  USERS_LIST_PROJECT,
  async function getUsersListProjects(id: string, thunkAPI) {
    thunkAPI.dispatch(setUserListLoadingState(true));
    const resp = await userListProject(id);
    if (!resp?.data !== null) {
      thunkAPI.dispatch(setUserListLoadingState(false));
      return resp;
    }
    thunkAPI.dispatch(setUserListLoadingState(false));
    return thunkAPI.rejectWithValue(resp);
  }
);
export const getUsersSearchListProjects = createAsyncThunk(
  USERS_LIST_SEARCH_PROJECT,
  async function getUsersSearchListProjects(projectData: ProjectUserListType, thunkAPI) {
    thunkAPI.dispatch(setUserListLoadingState(true));
    const resp = await userListSearchProject(projectData?.id, projectData?.searchText);
    if (!resp?.data !== null) {
      thunkAPI.dispatch(setUserListLoadingState(false));
      return resp;
    }
    thunkAPI.dispatch(setUserListLoadingState(false));
    return thunkAPI.rejectWithValue(resp);
  }
);
export const addProjects = createAsyncThunk(
  ADD_PROJECT,

  async function addProjects(data: any, thunkAPI) {
    thunkAPI.dispatch(setLoadingState(true));
    const resp = await addProject(data);
    if (!resp?.data !== null) {
      thunkAPI.dispatch(setLoadingState(false));
      return resp.data;
    }
    thunkAPI.dispatch(setLoadingState(false));
    return thunkAPI.rejectWithValue(resp);
  }
);
export const editProjects = createAsyncThunk(
  EDIT_PROJECT,
  async function editProjects(data: any, thunkAPI) {
    thunkAPI.dispatch(setLoadingState(true));
    const resp = await projectEdit(data.id, data.projectName);
    if (!resp?.data !== null) {
      thunkAPI.dispatch(setLoadingState(false));
      return resp.data;
    }
    thunkAPI.dispatch(setLoadingState(false));
    return thunkAPI.rejectWithValue(resp);
  }
);

export const handleProjectSearch = createAsyncThunk(
  SEARCH,
  async function handleSearch(params: any) {
    const { searchString, abortSignal } = params;
    const data = await searchProject(searchString, abortSignal?.signal);
    if (!data.error) {
      return data;
    } else {
      return { data: data };
    }
  }
);
const builderFunc = (
  state: IProjectState,
  loading: boolean,
  userList: [] | undefined,
  count: number | undefined,
  error: boolean
) => {
  state.isUserListLoading = loading;
  state.userData = userList;
  state.count = count;
  state.isError = error;
};

export const projectSlice = createSlice({
  name: PROJECT,
  initialState,
  reducers: {
    searchSelected: (state, action) => {
      state.selectedSearch = action.payload;
    },
    clearSearchBarValue: (state) => {
      state.clearResult = true;
    },
    resetSearchBarValue: (state) => {
      state.clearResult = false;
    },
    handleResetPage: (state) => {
      state.resetPage = true;
      state.isError = false;
    },
    setCurrentDeletion: (state, action) => {
      state.currentDeletion = action.payload;
    },
    setIsToastVisible: (state, action) => {
      state.isToastVisible = action.payload;
    },
    setLoadingState: (state, action) => {
      state.isLoading = action.payload;
    },
    setUserListLoadingState: (state, action) => {
      state.isUserListLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectData.fulfilled, (state, { payload }) => {
      state.projectData = payload.data;
      state.isLoading = false;
    });
    builder.addCase(getProjectData.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(getProjectData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(getUsersListProjects.fulfilled, (state, { payload }) =>
      builderFunc(state, false, payload?.data?.panelList, payload?.data?.count, false)
    );
    builder.addCase(getUsersListProjects.pending, (state, { payload }) =>
      builderFunc(state, true, undefined, undefined, false)
    );
    builder.addCase(getUsersListProjects.rejected, (state, { payload }) =>
      builderFunc(state, false, undefined, undefined, true)
    );

    builder.addCase(getUsersSearchListProjects.fulfilled, (state, { payload }) =>
      builderFunc(state, false, payload?.data?.panelList, payload?.data?.count, false)
    );
    builder.addCase(getUsersSearchListProjects.pending, (state, { payload }) =>
      builderFunc(state, true, undefined, undefined, false)
    );
    builder.addCase(getUsersSearchListProjects.rejected, (state, { payload }) =>
      builderFunc(state, false, undefined, undefined, true)
    );

    builder.addCase(editProjects.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(editProjects.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(editProjects.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addProjects.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(addProjects.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(addProjects.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(deleteProjects.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(deleteProjects.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(deleteProjects.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });
  }
});

export const {
  searchSelected,
  clearSearchBarValue,
  resetSearchBarValue,
  handleResetPage,
  setCurrentDeletion,
  setIsToastVisible,
  setLoadingState,
  setUserListLoadingState
} = projectSlice.actions;

export default projectSlice.reducer;
