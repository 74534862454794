export const SHARED_DATA = 'shared-data';
export const LEVEL_OF_PANNEL = 'level-of-pannel';
export const COUNTER = 'counter';
export const PANEL = 'panel';
export const LOGIN = 'login';
export const USER = 'user';
export const ADMIN_DASHBOARD = 'admin-dashboard';
export const GET_ADMIN_INFO = 'admin-info';
export const APPROVAL = 'approval';
export const ONBOARD = 'onboard';
export const SCHEDULE = 'schedule';
export const SETTINGS = 'settings';
export const EDIT_LEVEL = 'edit-level';
export const DELETE_LEVEL = 'delete-level';
export const ADD_LEVEL = 'add-level';
export const LEVEL_OF_USERS_LIST = 'lop-users-list';
export const USER_SEARCH_LIST = 'lop-users-search-list';
export const TECH_STACK = 'tech-stack';
export const DELETE_TECH_STACK = 'delete-tech-stack';
export const PANEL_LIST_TS = 'tech-panel-list';
export const PANEL_SEARCH_LIST_TS = 'tech-panel-list-search';
export const RECRUITER_LIST_TS = 'tech-recruiter-list';
export const RECRUITER_SEARCH_LIST_TS = 'tech-recruiter-search-list';
export const ADD_TECH_STACK = 'add-tech-stack';
export const EDIT_TECH_STACK = 'edit-tech-stack';
export const PROJECT = 'project';
export const USERS_LIST_PROJECT = 'users-list-project';
export const USERS_LIST_SEARCH_PROJECT = 'users-search-project-list';
export const DELETE_PROJECT = 'delete-project';
export const ADD_PROJECT = 'add-project';
export const EDIT_PROJECT = 'edit-project';
export const GET_USER = 'get-user';
export const GET_USER_LIST = 'get-list';
export const CHANGE_SUMMARY = 'change-summary';
export const GET_SUMMARY = 'get-summary';
export const DELETE_USER = 'delete-user';
export const EDIT_USER = 'edit-user';
export const GET_ROLES = 'getRoles';
export const GET_TECH_STACK = 'getTechStack';

export const PANEL_GET_PROJECTLIST = 'panel/getProjectList';
export const PANEL_GET_INTERVIEWLEVELLIST = 'panel/getInterviewLevelList';
export const PANEL_GET_SELECTEDUSERDATA = 'panel/getSelectedUserData';
export const PANEL_SEARCH = 'panel/search';
export const PANEL_DELETE = 'pane/delete';
export const PANEL_EDITPANEL_INFO = 'panel/editPanelInfo';
export const PANEL_GET_PANEL_ALL = 'panel/getPanelAll';
export const PANEL_GET_PANEL_ONBENCH = 'panel/getPanelOnBench';
export const PANEL_GET_PANEL_ONPROJECT = 'panel/getPanelOnProject';
export const PANEL_STATUS_CHANGE = 'panel/approvePanel';
export const PANEL_ARCHIVE = 'panel/archivePanel';
export const PANEL_GET_TECHSTACKLIST = 'panel/getTechStackList';

export const REQUEST_SLOT = 'request-slot';
export const SLOTS_SEARCH = 'slots-panel-search';

export const REFRESH_APP = 'refresh-app';

export const GET_REWARDS_DATA = 'get-rewards-data';
export const GET_UPCOMING_INTERVIEWS = 'get-upcoming-interviews';
export const GET_SLOT_SUMMARY_DATA = 'get-slot-summary-data';

export const RECRUITER = 'recruiter';
export const ADMIN = 'admin';
