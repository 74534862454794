import React, { Component, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ImageComponent from '../image';
import Typography from '../typography';
import NavPopup from '../bottom-navigation-popup';
import './index.style.scss';
import Images from '../../../../assets/images';
import ClickAwayListener from '../../shared-components/click-away';
interface ISingleItem {
  icon: string;
  title: string;
  path: string;
}

interface IBottomNavProps {
  items: ISingleItem[];
  customStyles?: string;
}

interface IBottomNavStates {
  isPopupOpen: boolean;
  customClass: string;
}

function BottomNavigation(props: IBottomNavProps) {
  const [bottomNavState, setBottomNavState] = useState<IBottomNavStates>({
    isPopupOpen: false,
    customClass: 'bottom-nav-item link'
  });

  function onMoreClick() {
    setBottomNavState({
      ...bottomNavState,
      isPopupOpen: !bottomNavState.isPopupOpen
    });
  }

  function closePopUp() {
    setBottomNavState({
      ...bottomNavState,
      isPopupOpen: false
    });
  }

  function closeMenu() {
    setBottomNavState({
      ...bottomNavState,
      isPopupOpen: false
    });
  }

  const { items, customStyles } = props;
  const { isPopupOpen } = bottomNavState;
  const rootPath = window.location.pathname === '/' ? true : false;
  const customMoreClass =
    !(window.location.pathname === '/home' || window.location.pathname === '/slots') && !rootPath
      ? 'bottom-nav-item link selected'
      : 'bottom-nav-item link';

  const { more } = Images;
  return (
    <ClickAwayListener handleClose={closeMenu} className="pop-up-modal">
      <div className="bottom-navigation">
        {items.slice(0, 2).map((item: ISingleItem, index: number) => (
          <NavLink
            key={index}
            to={item.path}
            id={index.toString()}
            onClick={closePopUp}
            className={({ isActive }) =>
              isActive || (rootPath && item.path === 'home')
                ? 'bottom-nav-item selected'
                : 'bottom-nav-item link'
            }>
            <div className="bottom-navigation-item">
              <ImageComponent src={item.icon} customClass="nav-image" />
              <Typography variant="text" customStyle="nav-text ">
                {item.title}
              </Typography>
            </div>
          </NavLink>
        ))}
        {isPopupOpen && <NavPopup items={items} sendSelectedNavData={closeMenu} />}
        <div className={customMoreClass} onClick={onMoreClick}>
          <div className="bottom-navigation-item">
            <ImageComponent src={more} customClass="nav-image" />
            <Typography variant="text" customStyle="nav-text ">
              More
            </Typography>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default BottomNavigation;
