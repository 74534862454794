import React, { useState } from 'react';
import Drawer from 'app/core/shared-components/drawer';
import ImageComponent from 'app/core/shared-components/image';
import Images from 'assets/images';
import Loader from 'app/core/shared-components/loader';
import Button from 'app/core/shared-components/button';
import Typography from 'app/core/shared-components/typography';
import {
  CANCEL_BUTTON_TEXT,
  SAVE_BUTTON_TEXT,
  PROJECT_DRAWER_LABEL,
  NUMBER_OF_PANELS_TEXT
} from 'helpers/constants';
import Modal from 'app/core/shared-components/modal';
import UserList from 'app/core/shared-components/users-list';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import { getUsersListProjects } from 'app/redux/slices/projectSlice';
import Input from 'app/core/shared-components/input-box/inputComponent/inputComponent';
import { IProjectType } from 'app/services/user-response.types';

interface IProjectDrawerProps {
  isOpen: boolean;
  onDrawerClose: () => void;
  isLoading: boolean;
  projectNameError?: string;
  handleEdit: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  currentEditable: IProjectType;
  currentName: string;
  toggleDeleteModal: () => void;
  handleDrawerEdit: () => void;
}

function ProjectDrawer({
  isOpen,
  isLoading,
  projectNameError,
  currentEditable,
  currentName,
  handleEdit,
  toggleDeleteModal,
  handleDrawerEdit,
  onDrawerClose
}: IProjectDrawerProps) {
  const [openUsersList, setOpenUsersList] = useState(false);

  const dispatch = useAppThunkDispatch();
  const { userData, isUserListLoading, count } = useAppSelector((state) => state.project);

  const submitButtonDisableCondition =
    currentEditable?.name === currentName || !!projectNameError || !!!currentEditable?.name?.length;

  const showErrorMessage = projectNameError && currentEditable?.name !== currentName;

  const { delete: Delete, projectIcon } = Images;

  const getUsersListModal = () => {
    return (
      <Modal customStyle="user-list-modal" onClose={handleUsersList} isOpen={openUsersList}>
        <div className="edit-modal-content">
          <UserList
            title={currentEditable?.name?.toString()}
            onClose={handleUsersList}
            totalCount={count}
            listType={'project'}
            id={currentEditable?.id}
            isTabView={false}
            handleGetList={getList}
            listData={userData}
            isLoading={isUserListLoading}
          />
        </div>
      </Modal>
    );
  };
  const getList = (id: string) => {
    dispatch(getUsersListProjects(id));
  };

  const handleUsersList = () => {
    setOpenUsersList(!openUsersList);
  };

  return (
    <Drawer
      onClose={onDrawerClose}
      isOpen={isOpen}
      bodyStyles="drawer-body-style"
      headerStyles="drawer-header-custom-style"
      headerProps={
        <div className="delete-icon">
          <ImageComponent src={Delete} onClick={toggleDeleteModal} />
        </div>
      }>
      <div className="project-edit-drawer-content">
        <div className="project-input-container">
          <div className="innerWrapper">
            <ImageComponent src={projectIcon} />

            <div onClick={handleUsersList}>
              <Typography variant="text" customStyle="project-drawer-sub-detail">
                {`${NUMBER_OF_PANELS_TEXT} ${currentEditable?.numberOfPanel}`}
              </Typography>
            </div>
          </div>
          <div className="drawer-separator"></div>
          <label htmlFor="projectName" className="delete-modal--project-label">
            {PROJECT_DRAWER_LABEL}
          </label>

          <Input
            autoFocus
            type="text"
            value={currentEditable?.name}
            autoComplete="off"
            onChange={handleEdit}
            name="projectName"
            required
          />

          {showErrorMessage && <div className="error-message">{projectNameError}</div>}
        </div>
        <div className="edit-drawer-footer">
          <div className="edit-cancel-btn">
            <Button customStyle="edit-drawer--cancel-btn" onClick={onDrawerClose} type="button">
              {CANCEL_BUTTON_TEXT}
            </Button>
          </div>

          <div className="edit-save-btn">
            {isLoading ? (
              <Loader loading={isLoading} />
            ) : (
              <Button
                type="submit"
                customStyle="edit-drawer--save-btn"
                variant="contained"
                onClick={handleDrawerEdit}
                disabled={submitButtonDisableCondition}>
                {SAVE_BUTTON_TEXT}
              </Button>
            )}
          </div>
        </div>
      </div>
      {getUsersListModal()}
    </Drawer>
  );
}

export default ProjectDrawer;
