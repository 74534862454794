import React from 'react';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { checkRole, isAdmin } from 'helpers/authHelper';
import { NOTIFICATION_ENTITY_ENUM } from 'helpers/constants';
import ImageComponent from 'app/core/shared-components/image';
import { PANEL, RECRUITER } from 'app/redux/constant';
import { useAppDispatch, useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  INotificationListData,
  setSelectedNotificationData,
  toggleCalendarSync,
  toggleManageAdmins
} from 'app/redux/slices/sharedSlice';
import { fetchSpecificCancelledSlot } from 'app/redux/slices/slotsSlice';
import { NOTIFICATION_ICON_MAP } from '../icon.data';
import './index.style.scss';
import { ITab } from 'helpers/types';
import { readNotification } from 'app/services/common.service';
import { LEVELS, PROJECTS, SCHEDULE, SETTINGS, SLOTS, TECHSTACKS } from 'helpers/appRoutes';

interface INotificationCardProps {
  data: INotificationListData;
  activeTab: ITab;
  updateNotificationData: (notificationType: ITab, notificationId?: string) => void;
}

const NotificationCard = ({ data, activeTab, updateNotificationData }: INotificationCardProps) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();

  const {
    userData: { role: userRoles }
  } = useAppSelector((state) => state.user);

  const { title, description, createdTime, entity, isRead, notificationId } = data;
  const isGeneralTabActive = activeTab === 'general';

  const handleNotificationClick = async () => {
    const userRole = userRoles.map((roles) => roles?.role);
    const isUserAdmin = isAdmin(userRole);
    const role = checkRole(userRole);

    dispatch(
      setSelectedNotificationData({
        id: data?.slotId || data?.id || '',
        slotDate: data?.slotDate || ''
      })
    );

    if (!isRead) {
      const notificationReadResponse = await readNotification(
        notificationId,
        isGeneralTabActive ? 'GENERAL_NOTIFICATION' : 'ACTIVITY_LOG'
      );

      if (!notificationReadResponse.error) {
        updateNotificationData(activeTab, notificationId);
      }
    }

    switch (entity) {
      case NOTIFICATION_ENTITY_ENUM.SLOT_ADDED:
      case NOTIFICATION_ENTITY_ENUM.SLOT_BOOKED:
      case NOTIFICATION_ENTITY_ENUM.SLOT_CANCELLED: {
        if (data?.slotId && data?.slotDate) {
          if (role === PANEL) {
            navigate(SCHEDULE);
            return;
          }
          if (role === RECRUITER) {
            navigate(SLOTS);
            if (entity === NOTIFICATION_ENTITY_ENUM.SLOT_CANCELLED) {
              await thunkDispatch(fetchSpecificCancelledSlot(data?.slotId));
              return;
            }
          }
        }
        break;
      }
      case NOTIFICATION_ENTITY_ENUM.TECH_STACK: {
        navigate(TECHSTACKS);
        break;
      }
      case NOTIFICATION_ENTITY_ENUM.INTERVIEW_LEVEL: {
        navigate(LEVELS);
        break;
      }
      case NOTIFICATION_ENTITY_ENUM.PROJECT: {
        navigate(PROJECTS);
        break;
      }
      case NOTIFICATION_ENTITY_ENUM.ADMIN: {
        if (isUserAdmin) {
          dispatch(toggleManageAdmins(true));
        }
        navigate(SETTINGS);
        break;
      }
      case NOTIFICATION_ENTITY_ENUM.TECH_STACK_ASSIGNED:
      case NOTIFICATION_ENTITY_ENUM.TECH_STACK_UNASSIGNED:
      case NOTIFICATION_ENTITY_ENUM.PROJECT_ASSIGNED:
      case NOTIFICATION_ENTITY_ENUM.PROJECT_UNASSIGNED:
      case NOTIFICATION_ENTITY_ENUM.TECH_STACK_LEVEL_ASSIGNED:
      case NOTIFICATION_ENTITY_ENUM.TECH_STACK_LEVEL_UNASSIGNED: {
        navigate(SETTINGS);
        break;
      }
      case NOTIFICATION_ENTITY_ENUM.CALENDAR_SYNC: {
        if (role === PANEL) {
          dispatch(toggleCalendarSync(true));
          navigate(SETTINGS);
        }
        break;
      }
      default:
        return;
    }
  };

  return (
    <div
      className={`notification-card ${isRead ? 'notification-read' : ''}`}
      onClick={handleNotificationClick}>
      <div className="notification-card-icon">
        <div className="icon">
          <ImageComponent src={NOTIFICATION_ICON_MAP[entity]} />
        </div>
      </div>
      <div className="notification-card-data">
        <div className="notification-card-header">
          <span className="category-heading">{title}</span>
          <span className="notification-time">{moment(createdTime).fromNow()}</span>
        </div>
        <div
          className="notification-card-body"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${description}`) }}></div>
      </div>
    </div>
  );
};

export default NotificationCard;
