import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Accordion from 'app/core/shared-components/accordion';
import Loading from 'app/core/shared-components/loader';
import Typography from 'app/core/shared-components/typography';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import { getAdminList, resetPageState } from 'app/redux/slices/settingSlice';
import { toggleCalendarSync, toggleManageAdmins } from 'app/redux/slices/sharedSlice';
import { isAdmin } from 'helpers/authHelper';
import { CALENDAR_SYNC, INTERVIEW_LIMIT, ISM_SUPPORT_MAIL, ROLE_PANEL } from 'helpers/constants';
import routeError from 'router/routeError';
import ErrorPage from '../Error';
import GoogleSync from './components/google-sync';
import InterviewLimit from './components/linterview-limit';
import UserDetail from './components/user-detail';
import UserTeam from './components/user-team';
import './index.style.scss';

function Settings() {
  const dispatch = useAppThunkDispatch();
  const [role, setRole] = useState<string[]>([]);
  const reduxSettingState = useAppSelector((state) => state.settings);
  const userState = useAppSelector((state) => state.user);

  const { isCalendarSyncOpen, isManageAdminsOpen } = useAppSelector((state) => state.shared);

  const fetchAdminList = async () => {
    const userRole = userState?.userData?.role?.map((item) => item.role);
    setRole(userRole);
    if (isAdmin(userRole)) {
      await dispatch(getAdminList({}));
    }
  };

  useEffect(() => {
    fetchAdminList();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetPageState());
      dispatch(toggleCalendarSync(false));
      dispatch(toggleManageAdmins(false));
      toast.dismiss();
    };
  }, []);

  return (
    <Fragment>
      {!reduxSettingState?.error ? (
        <Fragment>
          <div className="settings-layout">
            <Typography customStyle="settings-text">Settings</Typography>
            <Accordion title="My Details" defaultOpen>
              <UserDetail />
            </Accordion>
            {isAdmin(role) && (
              <Accordion title="Manage Admins" open={isManageAdminsOpen}>
                <UserTeam />
              </Accordion>
            )}
            {role.includes(ROLE_PANEL) && (
              <Accordion title={INTERVIEW_LIMIT}>
                <InterviewLimit />
              </Accordion>
            )}
            {role.includes(ROLE_PANEL) && (
              <Accordion title={CALENDAR_SYNC} open={isCalendarSyncOpen}>
                <GoogleSync />
              </Accordion>
            )}
            <Accordion title="Help" open>
              <div className="help-accordion">
                <Typography customStyle="help-text">
                  For any queries/assistance related to this platform please
                </Typography>
                <a href={ISM_SUPPORT_MAIL} target="_blank" rel="noreferrer" className="help-link">
                  contact us
                </a>
              </div>
            </Accordion>
          </div>
        </Fragment>
      ) : (
        <ErrorPage page="apiFailure" />
      )}
      <Loading loading={reduxSettingState.isLoading} />
    </Fragment>
  );
}

export default routeError(Settings);
