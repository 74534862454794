/* eslint-disable react/display-name */
import React from 'react';
import ErrorPage from '../app/pages/Error';

function routeError(WrappedComponent: any) {
  return (props: any) => {
    const isOnline = navigator.onLine;

    if (isOnline) {
      return <WrappedComponent {...props} />;
    }

    return <ErrorPage page="noInternet" />;
  };
}

export default routeError;
