import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Plus from 'assets/images/plus.svg';
import {
  ADD_BUTTON_TEXT,
  ADD_LEVEL_BUTTON_TEXT,
  ADD_LOI_TITLE,
  CANCEL_BUTTON_TEXT,
  GO_BACK_BUTTON,
  LEVEL,
  LEVEL_NAME,
  LEVEL_NAME_PLACEHOLDER,
  LEVEL_TITLE
} from 'helpers/constants';
import { LevelOfPanelValidation } from 'helpers/levelOfInterview.validation';
import { notify } from 'helpers/toastHelper';
import routeError from 'router/routeError';
import withRouter from 'router/withRouter';
import Button from 'app/core/shared-components/button';
import Input from 'app/core/shared-components/input-box/inputComponent/inputComponent';
import Loader from 'app/core/shared-components/loader';
import Modal from 'app/core/shared-components/modal';
import ToastUndo from 'app/core/shared-components/toast-undo';
import Typography from 'app/core/shared-components/typography';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  addLevel,
  deleteLevel,
  setCurrentDeletion,
  setIsToastVisible
} from 'app/redux/slices/levelOfInterviewSlice';
import { getLOI } from 'app/redux/slices/sharedSlice';
import { ILevelOfInterviewType } from 'app/services/user-response.types';
import ErrorPage from '../Error';
import LevelItem from './components/levels-card';
import './index.style.scss';
import {
  DELETE_EDGE_CASE,
  LEVEL_DELETE_SUCCESSFUL,
  LEVEL_OF_PANEL_EXISTS
} from 'helpers/messages.constants';

export interface ILevelsData {
  id: string;
  level: string;
  numberOfPanel: number;
  isZohoRecord: boolean;
}
interface ILevelOfInterviewState {
  showAddModal: boolean;
  showDropdown: boolean;
  levelsData: ILevelsData[];
  isToastVisible: boolean;
  isDeletionInProcess: boolean;
}

export interface ILevelForm {
  levelValue: string;
}

const LevelOfInterview = () => {
  const [levelState, setLevelOfInterviewState] = useState<ILevelOfInterviewState>({
    showAddModal: false,
    showDropdown: false,
    levelsData: [],
    isToastVisible: false,
    isDeletionInProcess: false
  });

  const [showErrModal, setErrModal] = useState(false);
  const [bookedSlotCount, setBookedSlotCount] = useState(0);
  const [levelData, setLevelData] = useState<ILevelsData>();

  const dispatch = useAppThunkDispatch();

  const { levelOfInterview, isLevelLoading } = useAppSelector((state) => state?.shared);
  const { isLoading, isError } = useAppSelector((state) => state?.levelOfInterview);

  const { showAddModal, levelsData } = levelState;

  const hookForm = useForm<ILevelForm>({
    defaultValues: {
      levelValue: ''
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(LevelOfPanelValidation)
  });

  const {
    setValue,
    control,
    setError,
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, errors }
  } = hookForm;

  const handleAddModal = () => {
    setLevelOfInterviewState({
      ...levelState,
      showAddModal: !levelState.showAddModal,
      showDropdown: false
    });
    reset();
  };
  const handleDeleteModal = () => {
    setErrModal(false);
    setLevelOfInterviewState((prevState) => ({ ...prevState, levelsData: levelOfInterview }));
  };
  const getLevelDeleteModalEdgeCase = () => {
    const panelAssignedError = ` has currently been assigned to ${levelData?.numberOfPanel} Panellists and cannot be
    deleted until it is replaced with another Level.`;
    const bookingError = `has currently
     ${bookedSlotCount} booked slot ${bookedSlotCount > 1 ? 's' : ''} and cannot be
    deleted until the active bookings are cancelled.`;
    return (
      <Modal
        header={DELETE_EDGE_CASE}
        customStyle="edit-modal"
        onClose={handleDeleteModal}
        isOpen={showErrModal}>
        <div className="edit-modal-content">
          <div className="delete-modal-para">
            <span className="modalLevelName">
              <q>{levelData?.level}</q>
            </span>
            {levelData?.numberOfPanel && levelData?.numberOfPanel > 0 ? (
              <span>{panelAssignedError}</span>
            ) : (
              <span>{bookingError}</span>
            )}
          </div>
          <div className="edit-modal-footer">
            <Button customStyle="edit-modal--cancel-btn" onClick={handleDeleteModal}>
              {GO_BACK_BUTTON}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const addLevelOfInterview: SubmitHandler<ILevelForm> = async (values) => {
    const isLevelExist = levelOfInterview?.filter((interviewLevel: ILevelOfInterviewType) => {
      return (
        interviewLevel?.level?.toUpperCase().replace(/\s/g, '')?.trim() ===
        hookForm.watch('levelValue')?.replace(/\s/g, '')?.trim()?.toUpperCase()
      );
    }).length;

    if (isLevelExist) {
      setError('levelValue', {
        type: 'onChange',
        message: LEVEL_OF_PANEL_EXISTS
      });
    } else {
      const data = await dispatch(
        addLevel({
          level: values.levelValue?.replace(/\s{2,}/g, ' ')?.trim()
        })
      ).unwrap();
      if (!data?.error) {
        dispatch(getLOI());
        handleAddModal();
      }
    }
  };
  const handleEnterKeyEvent = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.keyCode === 13) {
      if (isValid) {
        addLevelOfInterview({ levelValue: event?.currentTarget?.value });
      }
    }
  };

  const keyDownFunction = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleEnterKeyEvent(event);
  };

  function localDelete(level: ILevelsData) {
    setLevelData(level);
    let temp = levelState?.levelsData;
    temp = temp.filter((item) => item.id !== level.id);
    setLevelOfInterviewState((prevState) => ({
      ...prevState,
      levelsData: temp,
      isDeletionInProcess: true,
      isDeleteOn: false,
      isSearchedOptionSelected: false
    }));
    notify(
      false,
      <ToastUndo
        toastMessage={LEVEL_DELETE_SUCCESSFUL}
        handleUndo={onUndoDelete}
        toastId={level.id}
      />,
      level.id,
      onCloseDialogForToast(level?.id)
    );
    dispatch(setCurrentDeletion(level));
    dispatch(setIsToastVisible(true));
  }

  function onUndoDelete(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const id = event.currentTarget.id;
    toast.dismiss(id);
    toast.update(id, { onClose: () => {}, hideProgressBar: true });
    dispatch(getLOI());
  }

  const onCloseDialogForToast = (id: string) => () => {
    dispatch(deleteLevel(id)).then((response) => {
      if (!response?.payload?.data) {
        setBookedSlotCount(response?.payload?.error[0]?.data?.bookedSlotCount);
        setErrModal(true);
      } else {
        dispatch(getLOI());
      }
    });
  };

  const levelValueField = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('levelValue', event.currentTarget?.value);
  };

  useEffect(() => {
    dispatch(getLOI());
  }, []);

  useEffect(() => {
    setLevelOfInterviewState((prevState) => ({ ...prevState, levelsData: levelOfInterview }));
  }, [levelOfInterview]);

  return (
    <Fragment>
      {!!navigator.onLine ? (
        !isError ? (
          <div className="level">
            <div className="level-head">
              <Typography variant="text" customStyle="head-style">
                {LEVEL_TITLE}
              </Typography>
              <div className="btns">
                <div className="add-sort">
                  <Button
                    startIcon={Plus}
                    customStyle="add-btn"
                    onClick={handleAddModal}
                    variant="contained">
                    {ADD_LEVEL_BUTTON_TEXT}
                  </Button>
                </div>
              </div>
            </div>
            <div className="level-body">
              {levelsData?.map((item) => (
                <LevelItem
                  key={item?.id}
                  levelItem={item}
                  levelCard={item}
                  onDeleteThroughCard={localDelete}
                />
              ))}
            </div>
            {getLevelDeleteModalEdgeCase()}
            <Modal
              header={ADD_LOI_TITLE}
              customStyle="addModal"
              onClose={handleAddModal}
              isOpen={showAddModal}>
              <form onSubmit={handleSubmit(addLevelOfInterview)}>
                <div className="addModal-content">
                  <div className="levels-form">
                    <div>
                      <label htmlFor={LEVEL_NAME} className="delete-modal--level-label">
                        {LEVEL}
                      </label>
                      <Controller
                        control={control}
                        name="levelValue"
                        render={({}) => (
                          <Input
                            {...register('levelValue', {
                              required: true,
                              onChange: levelValueField
                            })}
                            required
                            autoFocus
                            placeholder={LEVEL_NAME_PLACEHOLDER}
                          />
                        )}
                      />
                      {errors?.levelValue && (
                        <div className="error-message">{errors?.levelValue?.message}</div>
                      )}
                    </div>
                  </div>
                  <div className="addModal-footer">
                    <Button customStyle="addModal-cancelBtn" onClick={handleAddModal} type="button">
                      {CANCEL_BUTTON_TEXT}
                    </Button>
                    <Button
                      type="submit"
                      customStyle="add-modal--save-btn"
                      variant="contained"
                      disabled={!isValid || isSubmitting}>
                      {ADD_BUTTON_TEXT}
                    </Button>
                  </div>
                </div>
              </form>
            </Modal>
          </div>
        ) : (
          <ErrorPage page="apiFailure" />
        )
      ) : (
        <ErrorPage page="noInternet" />
      )}
      <Loader loading={isLevelLoading || isLoading} />
    </Fragment>
  );
};

export default routeError(withRouter(LevelOfInterview));
