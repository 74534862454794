import React, { useCallback, useState, Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router';
import Drawer from 'app/core/shared-components/drawer';
import Form, { ITechStackArray } from 'app/core/shared-components/form';
import ImageComponent from 'app/core/shared-components/image';
import { ISelectValue } from 'app/core/shared-components/select';
import { IClippedSelectValue } from 'app/core/shared-components/clipped-select';
import Button from 'app/core/shared-components/button';
import Typography from 'app/core/shared-components/typography';
import { editUserInfo } from 'app/redux/slices/panelSlice';
import { notify } from 'helpers/toastHelper';
import deleteIcon from 'assets/images/delete.svg';
import {
  STATUSENUM_APPROVED,
  STATUSENUM_ARCHIVE,
  STATUSENUM_IN_REVIEW,
  VIEW_DETAILS
} from 'helpers/constants';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import { getExperience, handleTabEventwithoutValue } from 'helpers/utils';
import { PANEL_ROUTE } from 'helpers/apiRoutes';
import {
  ON_BENCH_LABEL,
  SUCCESSFULLY_APPROVED,
  SUCCESSFULLY_ARCHIEVED,
  SUCCESSFULLY_ONBOARDED
} from 'helpers/messages.constants';
import { ISTATUSFIELD } from 'helpers/types';
import { ISelectedPanel } from '../../admin';

interface ISelectedPanelStates {
  id: string;
  firstName: string;
  role: {
    id: number;
    label: string;
  };
  lastName: string;
  statusEnum: ISTATUSFIELD;
  designation: string;
  email: string;
  mobileNo: number;
  profileImageUrl: string;
  totalExperience: number;
  stackLoi: IClippedSelectValue[];
  projects: ISelectValue[];
}

interface IPanelProps {
  selectedPanel: ISelectedPanel;
  isEditingOn: boolean;
  handlCloseDrawer: () => void;
  handleDelete: () => void;
}

const PanelDrawer = (props: IPanelProps) => {
  const { selectedPanel, isEditingOn, handlCloseDrawer, handleDelete } = props;
  const { panel } = useAppSelector((state) => state.shared);
  const { shared } = useAppSelector((state) => state);
  const dispatch = useAppThunkDispatch();

  const navigate = useNavigate();
  const blankFunc = () => {};
  const handleClick = useCallback(
    (statusTab: number) => () => {
      navigate(PANEL_ROUTE, {
        state: statusTab
      });
    },
    []
  );

  const handleEditPanel = async (
    role: ISelectValue,
    status: string,
    projects?: ISelectValue[],
    stackLop?: ITechStackArray[]
  ) => {
    let userOnBench = false;
    if (selectedPanel?.id && selectedPanel?.role) {
      let data = {
        userId: [selectedPanel?.id],
        roleId: selectedPanel?.role.id,
        status: status
      };
      if (selectedPanel?.role.id.toString() == shared.panel?.id.toString()) {
        const panelData = {
          projectIds: projects?.map((item) => item.id.toString()) || [],
          onBench: !projects?.length,
          techStackDetails: stackLop ? stackLop : []
        };
        if (panelData.onBench) {
          userOnBench = true;
        }
        Object.assign(data, panelData);
      }
      await dispatch(editUserInfo(data));

      const toastMsgApproved = `${SUCCESSFULLY_APPROVED} ${
        selectedPanel?.firstName + ' ' + selectedPanel?.lastName
      }`;
      const toastMsgOnboard = `${SUCCESSFULLY_ONBOARDED} ${
        selectedPanel?.firstName + ' ' + selectedPanel?.lastName
      }`;
      const toastMsgInReview = `${SUCCESSFULLY_ARCHIEVED} ${
        selectedPanel?.firstName + ' ' + selectedPanel?.lastName
      }`;
      const toastMsg =
        status === STATUSENUM_APPROVED
          ? toastMsgApproved
          : status === STATUSENUM_IN_REVIEW
          ? toastMsgOnboard
          : toastMsgInReview;
      const statusTab = status == STATUSENUM_APPROVED ? 1 : status == STATUSENUM_IN_REVIEW ? 0 : 2;
      if (status !== selectedPanel.statusEnum) {
        notify(
          true,
          <div className="toast-container-detail">
            <Typography customStyle="toast-title">{toastMsg}</Typography>
            <Button
              variant="outlined"
              customStyle="toast-detail-button"
              onClick={handleClick(statusTab)}>
              {VIEW_DETAILS}
            </Button>
          </div>,
          uuidv4().toString(),
          blankFunc
        );
      } else if (userOnBench) {
        notify(
          true,
          <div className="toast-container-detail">
            <Typography customStyle="toast-title">
              {`${ON_BENCH_LABEL} ${selectedPanel?.firstName} ${selectedPanel?.lastName} on bench`}
            </Typography>
          </div>,
          uuidv4().toString(),
          blankFunc
        );
      }
      handlCloseDrawer && handlCloseDrawer();
      return;
    }
  };

  return (
    <div>
      <Drawer
        isOpen={isEditingOn}
        onClose={handlCloseDrawer}
        bodyStyles="modal-drawer-body"
        headerProps={
          <div className="delete-icon">
            <ImageComponent
              src={deleteIcon}
              onClick={handleDelete}
              tabIndex={0}
              onKeyDown={handleTabEventwithoutValue(handleDelete)}
            />
          </div>
        }>
        <Form
          showProfileField={true}
          userDetail={{
            name: `${selectedPanel.firstName} ${selectedPanel.lastName}`,
            designation: selectedPanel?.designation,
            email: selectedPanel?.email,
            experience:
              (selectedPanel?.totalExperience &&
                getExperience(Number(selectedPanel?.totalExperience))) ||
              '',
            mobile: `${selectedPanel?.mobileNo}`,
            profileImageUrl: `${selectedPanel?.profileImageUrl}`
          }}
          panelForm={true}
          showStatusField={true}
          onSave={handleEditPanel}
          roleValue={panel as ISelectValue}
          statusFieldValue={selectedPanel?.statusEnum}
          archiveFieldValue={
            selectedPanel?.statusEnum === STATUSENUM_IN_REVIEW
              ? STATUSENUM_IN_REVIEW
              : STATUSENUM_ARCHIVE
          }
          projectValue={selectedPanel?.projects}
          stackLopValue={selectedPanel?.stackLoi}
          onCancel={handlCloseDrawer}
        />
      </Drawer>
    </div>
  );
};
export default PanelDrawer;
