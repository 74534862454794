import React, { HtmlHTMLAttributes, useState } from 'react';
import { useAppSelector } from 'app/redux/hooks';
import { ITechStackInterviewLevelSet } from 'app/redux/slices/slotsSlice';
import { IProjectType } from 'app/services/user-response.types';
import Button, { IButtonProps } from '../button';
import './index.style.scss';
import { IOrientation } from 'helpers/types';

export interface ISelectedSearchProps {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  designation?: string;
  totalExperience?: string | 0;
  techStackInterviewLevelSet?: ITechStackInterviewLevelSet[];
  projects?: IProjectType[] | null;
  mobileNo?: string;
  profileImageUrl?: string | null;
  addedOn?: string;
  statusEnum?: string;
}

export interface IButtonGroupProps extends HtmlHTMLAttributes<HTMLElement> {
  customStyle?: string;
  orientation?: IOrientation;
  options: IButtonProps[];
  activeButtonClick?: (position: number) => void;
  tabValue?: number;
  showSelectedButton?: boolean;
  count?: number;
  selectedSearch?: ISelectedSearchProps;
}

function ButtonGroup(props: IButtonGroupProps) {
  const [activeButtonId, setActiveButtonId] = useState<number>(0);
  const { techStackLevelSet } = useAppSelector((state) => state.user.userData);

  const {
    customStyle,
    orientation,
    options,
    tabValue,
    showSelectedButton,
    selectedSearch,
    activeButtonClick
  } = props;

  function activeButton(id: number) {
    setActiveButtonId(id);
  }
  function setActiveButton(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const id = event.currentTarget.id;
    activeButton(parseInt(id, 10));
    activeButtonClick && activeButtonClick(parseInt(id, 10));
  }

  return (
    <div className={`button-group button-group--${orientation}  `}>
      {options?.map(
        ({ label, size, variant, endIcon, disabled, startIcon, count }, index: number) => {
          const customClass = showSelectedButton
            ? index === tabValue
              ? 'selected'
              : ''
            : index === activeButtonId
            ? 'selected'
            : '';
          const countCustomClass = showSelectedButton
            ? index === tabValue
              ? 'selectedCount'
              : 'label-count'
            : index === activeButtonId
            ? 'selectedCount'
            : 'label-count';
          return (
            <Button
              id={`${index}`}
              key={index}
              size={size}
              variant={variant}
              customStyle={`
                  ${customClass}
                 ${customStyle}`}
              onClick={setActiveButton}
              endIcon={endIcon}
              startIcon={startIcon}
              disabled={disabled}>
              <span className="label-text">
                {label}
                {selectedSearch && selectedSearch?.techStackInterviewLevelSet?.length ? (
                  <span className={countCustomClass}>
                    {count || count === 0 ? `(${count})` : ''}
                  </span>
                ) : (
                  <span className={countCustomClass}>
                    {techStackLevelSet?.length && (count || count === 0) ? `(${count})` : ''}
                  </span>
                )}
              </span>
            </Button>
          );
        }
      )}
    </div>
  );
}

export default ButtonGroup;
