import React, { useEffect, useState } from 'react';
import GoogleSyncImage from '../../../../../assets/images/googleSyncImage.svg';
import { CONNECT, DIS_CONNECT, G_SUITE } from '../../../../../helpers/constants';
import Button from '../../../../core/shared-components/button';
import ImageComponent from '../../../../core/shared-components/image';
import { useAppThunkDispatch } from '../../../../redux/hooks';
import { checkIsGoogleSynced, handleGoogleSync } from '../../../../redux/slices/settingSlice';
import './index.style.scss';

interface IGoogleSyncState {
  isSynced: boolean;
}

const GoogleSync = () => {
  const [googleSyncState, setGoogleSyncState] = useState<IGoogleSyncState>({
    isSynced: false
  });

  const thunkDispatch = useAppThunkDispatch();

  const { isSynced } = googleSyncState;

  const handleConnect = () => {
    if ((window as any).google) {
      const clientToken = (window as any).google?.accounts.oauth2.initCodeClient({
        client_id: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
        ux_mode: 'popup',
        redirected_uri: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}/user/events`,
        scope: `${process.env.REACT_APP_GOOGLE_SYNC_SCOPE1} ${process.env.REACT_APP_GOOGLE_SYNC_SCOPE2} `,
        prompt: 'consent',
        callback: async (tokenResponse: any) => {
          await thunkDispatch(handleGoogleSync({ code: tokenResponse.code, enabled: true }));
          checkUserSync();
        }
      });
      clientToken.requestCode();
    }
  };

  const handleDiconnect = async () => {
    await thunkDispatch(handleGoogleSync({ code: '', enabled: false }));
    checkUserSync();
  };

  const checkUserSync = async () => {
    const data = await thunkDispatch(checkIsGoogleSynced());
    setGoogleSyncState((...prevState) => ({ ...prevState, isSynced: data?.payload?.data }));
  };

  useEffect(() => {
    checkUserSync();
  }, []);

  return (
    <div className="pa-sync-wrapper">
      <div className="pa-sync-note">Connect your google calendar to view existing events.</div>
      <div className="pa-sync-sub-wrapper">
        <ImageComponent
          src={GoogleSyncImage}
          alt="Tech Stack Image"
          className="tech-stack--img"
          fallbackClass="tech-no-image"
          fallbackText="Google Sync"
        />
        <div className="pa-sync-suite">{G_SUITE}</div>
        {!isSynced && (
          <Button
            variant="contained"
            customStyle="pa-sync-button"
            disabled={false}
            onClick={handleConnect}>
            {CONNECT}
          </Button>
        )}
        {isSynced && (
          <Button
            variant="outlined"
            customStyle="pa-diconnect-button"
            disabled={false}
            onClick={handleDiconnect}>
            {DIS_CONNECT}
          </Button>
        )}
      </div>
    </div>
  );
};

export default GoogleSync;
