import { ISelectOptions } from '../../../core/shared-components/customSelect';
import { StylesConfig } from 'react-select';
import Images from '../../../../assets/images';
import { theme } from '../../../../assets/styles/theme';

const { orangeDot } = Images;

export const statusStyles: StylesConfig<ISelectOptions> = {
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: theme.warningColor1,
    fontSize: '0.75rem',
    padding: 0,
    paddingLeft: '1rem',
    border: 'none',
    margin: 0,
    '&:before': {
      content: "''",
      height: 6,
      width: 6,
      background: `url("${orangeDot}") center/cover no-repeat`,
      display: 'inline-block',
      marginRight: '0.5rem'
    }
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: theme.panelStatusBorder,
    borderColor: theme.warningColor1,
    borderRadius: '0.5rem',
    minHeight: 32,
    minWidth: 100,
    maxWidth: 136,
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow: 'none',
    '&:hover': {
      border: theme.panelStatusBorder,
      boxShadow: 'none'
    }
  }),
  indicatorsContainer: (baseStyles, state) => ({
    ...baseStyles,
    cursor: 'pointer',
    paddingRight: '1rem'
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    minWidth: 100,
    maxWidth: 136,
    zIndex: 999,
    border: theme.techItemBorder,
    borderRadius: '0.5rem'
  })
};
