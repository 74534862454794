import React, { useEffect, useLayoutEffect } from 'react';
import './index.style.scss';
import LeftArrow from '../../../../../assets/images/chevron.svg';
import RightArrow from '../../../../../assets/images/group.svg';
import { useRef, useState } from 'react';
import { RESIZE } from '../../../../../helpers/constants';

function CustomNextArrow({ onClick, disabled, height }: any) {
  const isDisabled = disabled;

  return (
    <button
      className={isDisabled ? 'right-arrow arrow-disabled' : 'right-arrow'}
      onClick={onClick}
      style={{ height: height }}>
      <img src={RightArrow} alt="img" />
    </button>
  );
}
function CustomPrevArrow({ onClick, disabled, height }: any) {
  const isDisabled = disabled;
  return (
    <button
      className={isDisabled ? `left-arrow arrow-disabled ` : `left-arrow `}
      onClick={onClick}
      style={{ height: height }}>
      <img src={LeftArrow} alt="img" />
    </button>
  );
}

const CustomScroll = ({ children }: any) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isLeftSlide, setIsLeftSlide] = useState<boolean>(false);
  const [isRightSlide, setIsRightSlide] = useState<boolean>(true);
  const [showArrows, setShowArrows] = useState<boolean>(true);

  const [windowWidth, setWindowWidth] = useState(0);

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener(RESIZE, resizeWindow);
    return () => window.removeEventListener(RESIZE, resizeWindow);
  }, []);

  useLayoutEffect(() => {
    if (scrollRef?.current) {
      if (scrollRef.current.clientWidth < scrollRef.current.scrollWidth) {
        setShowArrows(true);
      } else {
        setShowArrows(false);
      }
    }
  }, [scrollRef, windowWidth]);

  const scrollToLeft = () => {
    if (scrollRef?.current) {
      const width = 150;
      if (scrollRef.current.scrollLeft <= 0) {
        setIsLeftSlide(false);
        setIsRightSlide(true);
      } else {
        setIsLeftSlide(true);
        setIsRightSlide(true);
        scrollRef.current.scrollLeft -= width;
      }
    }
  };
  const scrollToRight = () => {
    if (scrollRef?.current) {
      const offsetWidth = scrollRef.current.scrollWidth;
      const currentWidth = scrollRef.current.scrollLeft + scrollRef.current.clientWidth;
      const width = 150;
      if (currentWidth >= offsetWidth) {
        setIsRightSlide(false);
        setIsLeftSlide(true);
      } else {
        setIsLeftSlide(true);
      }
      scrollRef.current.scrollLeft += width;
    }
  };

  return (
    <div className="horizontal-scroll-div">
      {showArrows && (
        <CustomPrevArrow onClick={scrollToLeft} height="40px" disabled={!isLeftSlide} />
      )}
      <div className="scroll-body" id="scroll-body" ref={scrollRef}>
        <div className="scroll">{children}</div>
      </div>
      {showArrows && (
        <CustomNextArrow onClick={scrollToRight} height="40px" disabled={!isRightSlide} />
      )}
    </div>
  );
};
export default CustomScroll;
