import { InputHTMLAttributes, TextareaHTMLAttributes } from 'react';
import { theme } from '../assets/styles/theme';
import { ALL, AVAILABLE, BOOKED, CANCELLED } from './constants';

export const SCHEDULE_EVENTS_TYPE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  EXISTING: 'EXISTING',
  AVAILABLE: 'AVAILABLE',
  BOOKED: 'BOOKED',
  CANCELLED: 'CANCELLED',
  TEMPORARY: 'TEMPORARY',
  REQUESTED: 'REQUESTED'
};

export type EVENT_TYPES = 'BOOKED' | 'AVAILABLE' | 'EXISTING' | 'ADD' | 'EDIT' | string;

export type ISTATUSTYPES = 'APPROVED' | 'IN_REVIEW' | 'ARCHIVE';
export type IARCHIVETYPES = 'IN_REVIEW' | 'ARCHIVE';
export type ISTATUSFIELD = 'APPROVED' | 'IN_REVIEW';

export type dropDownIndicatorColor = 'orange' | '';
export type menuPosition = 'absolute' | 'fixed';
export type menuPlacement = 'auto' | 'top' | 'bottom';

export type HTMLPropOmit = 'onChange' | 'onFocus' | 'onBlur';
export type HTMLInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, HTMLPropOmit>;
export type HTMLTextareaProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLPropOmit>;
export type HTMLProps = InputHTMLAttributes<HTMLInputElement> &
  TextareaHTMLAttributes<HTMLTextAreaElement>;
export type HTMLInputTextareaProps = Omit<HTMLProps, HTMLPropOmit>;
export interface ISlotInfoType {
  type: 'Available' | 'Booked' | 'Cancelled' | 'All';
  color: string;
}
export const slotInfo: ISlotInfoType[] = [
  { type: AVAILABLE, color: theme.primary },
  { type: BOOKED, color: theme.success },
  { type: CANCELLED, color: theme.error },
  { type: ALL, color: '' }
];
export type ISlotsStatusTypes = 'Available' | 'Booked' | 'Cancelled' | 'All';

export type IOrientation = 'vertical' | 'horizontal';
export type IReadNotificationType = 'GENERAL_NOTIFICATION' | 'ACTIVITY_LOG';
export type ITab = 'general' | 'activity';
