import React, { Component } from 'react';
import { EMAIL, NAME } from '../../../../helpers/constants';
import ImageComponent from '../image';
interface IRowsCreatorProps {
  recruiter: {
    firstName?: any;
    lastName?: string;
    profileImageUrl?: string;
    designation?: string;
    email?: string;
  };
  componentType: string;
}

const RecruiterRowCreator = (props: IRowsCreatorProps) => {
  const { recruiter } = props;
  const [firstLetter, ...rest] = recruiter?.firstName;
  const getRowBody = () => {
    switch (props.componentType) {
      case NAME:
        return (
          <div className="recruiter-name-designation ">
            <div className="recruiter-img-name-container">
              <ImageComponent
                src={recruiter?.profileImageUrl || null}
                className="recruiter-img"
                fallbackClass="recruiter-img-alt"
                fallbackText={firstLetter}
              />
              <div>
                <div className="recruiter-name">{`${recruiter?.firstName} ${recruiter?.lastName}`}</div>
                <div className="recruiter-designation recruiter-gray-text">
                  {recruiter?.designation}
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case EMAIL:
        return <div>{recruiter?.email}</div>;
        break;
    }
  };
  return <div>{getRowBody()}</div>;
};
export default RecruiterRowCreator;
