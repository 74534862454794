import React from 'react';
import { ISelectOptions } from 'app/core/shared-components/customSelect';
import {
  IN_REVIEW,
  IN_REVIEW_TAB,
  NAME_ASC,
  PROJECTSTATUSOPTIONS,
  USER_STATUS
} from 'helpers/constants';

export interface IProjectStatusType {
  id: number | string;
  label: string;
  showDot?: boolean;
}
export interface IProjectDropdownType {
  id: number | string;
  label: string;
}
export const projectStatusOptions = PROJECTSTATUSOPTIONS;
export const statusOptions = USER_STATUS;
export interface ISearchOptions {
  id: string;
  searchKey: string;
  singleSearchData: IPanelData;
  startIcon: string | undefined;
  supportingData: string;
}

export interface IPanelStates {
  selectedRows: string[];
  currentListType: string;
  pageNo: number;
  isFilteringOn: boolean;
  filterCount: number;
  isSortingOn: boolean;
  sortingValue: string;
  isDeleteOn: boolean;
  clearResult: boolean;
  searchedOptions: ISearchOptions[];
  selectedUser: ISelectedPanelType;
  filtersBody: {
    eligiblePanels: boolean;
    experience: Array<{ experienceFrom: number; experienceTo: number }>;
    levelOfPanel: string[] | null;
    panelStatusEnum: string;
    projects: string[] | null;
    sortBy: string;
    techStack: string[] | null;
  };
  isSearchOptionSelected: boolean;
  isEditingOn: boolean;
  isSingleDeleteOn: boolean;
  noResultFound: boolean;
  isSaveOn: boolean;
  isToastVisible: boolean;
  filters: IFilters;
  formData: any;
  currentPg: number;
  isDeletionInProcess?: boolean;
  panelDataRecords?: IPanelData[] | any;
  totalCount?: number;
}
export const IPanelStatesData = {
  selectedRows: [],
  currentListType: IN_REVIEW_TAB,
  pageNo: 1,
  isFilteringOn: false,
  filterCount: 0,
  isSortingOn: false,
  sortingValue: '',
  isDeleteOn: false,
  clearResult: false,
  searchedOptions: [],
  selectedUser: {
    designation: '',
    emailId: '',
    firstName: '',
    lastName: '',
    mobileNo: 0,
    profileImageUrl: '',
    projects: [],
    roles: { id: '', label: '' },
    statusEnum: IN_REVIEW,
    techStackInterviewLevelDtos: [],
    totalExperience: 0,
    experience: 0,
    userId: ''
  },
  filtersBody: {
    experience: [
      {
        experienceFrom: 0,
        experienceTo: 50
      }
    ],

    techStack: [],
    levelOfPanel: [],
    projects: [],
    eligiblePanels: false,
    panelStatusEnum: 'ALL',
    sortBy: NAME_ASC
  },
  isSearchOptionSelected: false,
  isEditingOn: false,
  isSingleDeleteOn: false,
  noResultFound: false,
  isSaveOn: false,
  isToastVisible: false,
  filters: {
    interviewLevelList: [],
    techStacks: [],
    experience: [],
    projects: [],
    count: 0,
    eligiblePanels: false
  },
  formData: {},
  currentPg: 1,
  isDeletionInProcess: false,
  panelDataRecords: []
};
export interface IFilters {
  count: number;
  eligiblePanels: boolean;
  experience: number[];
  interviewLevelList: string[];
  projects: string[];
  techStacks: string[];
}
export interface IPanelType {
  id: string;
  label: string;
}
interface ITechStackDetails {
  techStackId: ISelectOptions | null;
  interviewLevelId: ISelectOptions | null;
}
type IStatusField = 'APPROVED' | 'IN_REVIEW';
export interface ISelectedPanelType {
  designation: string;
  emailId: string;
  firstName: string;
  lastName: string;
  mobileNo: number;
  profileImageUrl: string;
  projects: ISelectOptions[] | null;
  roles: IPanelType;
  statusEnum: IStatusField | any;
  techStackInterviewLevelDtos: ITechStackDetails[] | null;
  totalExperience: number;
  experience: number;
  userId: string;
}
export interface IPanelData {
  addedOn: string;
  designation: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  mobileNo: string;
  profileImageUrl: string;
  projects: null;
  statusEnum: string;
  techStackInterviewLevelSet: [
    {
      techStack: string;
      level: string;
    }
  ];
  totalExperience: number;
}
export interface IPanelFilters {
  eligiblePanels?: boolean;
  experience: Array<{
    experienceFrom: number;
    experienceTo: number;
  }>;
  levelOfPanel: string[] | null;
  panelStatusEnum: string;
  projects: string[] | null;
  sortBy: string;
  techStack: string[] | null;
}
export interface IProjectType {
  id: string;
  name: string;
}
export interface ITechStackType {
  level: string;
  levelId: string;
  roleId: string;
  techStack: string;
  techStackId: string;
}
export interface IOnboardFormData {
  designation: string;
  emailId: string;
  experience: number;
  firstName: string;
  lastName: string;
  mobileNo: string;
  profileImageUrl: string | null;
  userId: string;
}
export interface IBodyPanelEdit {
  onBench: boolean;
  projectIds: string[];
  roleId: string;
  status: string;
  techStackDetails: [
    {
      interviewLevelId: string;
      string: string;
    }
  ];
  userId: string[];
}
