import React, { useEffect, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import moment from 'moment';
import { ISelectedSearchProps } from 'app/core/shared-components/button-group';
import CustomScroll from 'app/pages/Slots/components/custom-horzontal-scroll';
import SlotCancellationModal from 'app/pages/Slots/components/slot-cancellation-modal';
import SlotRequestCard from 'app/pages/Slots/components/slot-request-card';
import { useAppDispatch, useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import { setSelectedNotificationData } from 'app/redux/slices/sharedSlice';
import {
  fetchSpecificAvailableSlots,
  fetchSpecificBookedSlot,
  fetchSpecificCancelledSlot,
  toggleRequestFlag
} from 'app/redux/slices/slotsSlice';
import { theme } from 'assets/styles/theme';
import {
  ALL,
  AVAILABLE,
  BOOKED,
  CANCEL,
  CANCELLED,
  CANCEL_LABEL,
  TODAY,
  TOMORROW
} from 'helpers/constants';
import { headerDetailsForSlots } from 'helpers/utils';
import { timeStamps } from 'helpers/timeStamp.constants';
import { ISlotsStatusTypes } from 'helpers/types';
import './index.style.scss';

export interface ISpecificAvailableSlotResponse {
  slotDate: string;
  fromTime: string;
  toTime: string;
  slotUserDetailList: ISlotUserDetail[];
}

export interface ISpecificBookedSlotResponse {
  date: string;
  fromTime: string;
  toTime: string;
  candidateName: string;
  cancelReason: string;
  cancelledBy: string;
  cancelComments: string;
  slotUserDetail: ISlotUserDetail[];
  techStackInterviewLevelDto: {
    techStackId: string;
    techStack: string;
    levelId: string;
    level: string;
  };
  zohoRecruitUrl: string;
  recruiterName?: string;
}

export interface ISlotUserDetail {
  userId: string;
  imageUrl: string;
  slotId: string;
  firstName: string;
  lastName: string;
  designation: string;
  levelOfInterview: string;
  mobileNo: string;
  emailId: string;
  experience: number;
}
export interface ISlots {
  fromTime: string;
  isRequest: boolean;
  noOfPanels: number;
  panelistName?: string;
  toTime: string;
  candidateName: string;
  slotId: string;
  slotStatus?: string;
}
export interface ISlotsData {
  fromTime: string;
  toTime: string;
}

interface ISlotCarouselProps {
  selectedUser?: ISelectedSearchProps;
  day: string;
  noOfSlots: number;
  slots: ISlots[];
  borderColor: string;
  slotType: ISlotsStatusTypes;
  levelId?: string;
  techStackId: string;
  isSearch?: boolean;
  dateFilter?: {
    from: string;
    to: string;
  }[];
}

const SlotCarousel = (props: ISlotCarouselProps) => {
  const {
    day,
    slots,
    borderColor,
    slotType,
    levelId,
    techStackId,
    noOfSlots,
    isSearch,
    dateFilter,
    selectedUser
  } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const dayToShow = moment(day).format(timeStamps.DD_MMM);
  const isToday = dayToShow === moment().format(timeStamps.DD_MMM);
  const isTomorrow = dayToShow === moment().add(1, 'days').format(timeStamps.DD_MMM);
  const [currentSelectedSlotId, setCurrentSelectedSlotId] = useState<number>(-1);
  const thunkDispatch = useAppThunkDispatch();
  const dispatch = useAppDispatch();
  const { selectedSlot, selectedCancelledSlot, selectedBookedSlot, currentSlots } = useAppSelector(
    (state) => state.slots
  );

  const { selectedNotificationData } = useAppSelector((state) => state.shared);

  const dayOfDate = moment(day).format(timeStamps.DDDD);

  const slotIdArray: string[] = selectedSlot?.slotUserDetailList?.length
    ? selectedSlot?.slotUserDetailList?.map((slot) => slot?.slotId)
    : [''];

  const handleSlotClick = async (index: number) => {
    setCurrentSelectedSlotId(index);

    const slotData = {
      slotDate: moment(day).format(timeStamps.YYYY_MM_DD),
      interviewLevelId: levelId?.toString(),
      techStackId: techStackId?.toString(),
      fromTime: slots[index]?.fromTime,
      toTime: slots[index]?.toTime
    };

    switch (slotType) {
      case AVAILABLE:
        if (!isSearch) {
          await thunkDispatch(fetchSpecificAvailableSlots(slotData));
          setShowModal(!showModal);
        } else {
          setShowModal(!showModal);
        }
        break;
      case BOOKED:
        thunkDispatch(fetchSpecificBookedSlot(slots[index]?.slotId));
        setShowModal(!showModal);

        break;
      case CANCELLED:
        thunkDispatch(fetchSpecificCancelledSlot(slots[index]?.slotId));
        setShowModal(!showModal);
        break;
      case ALL:
        slots[index]?.slotStatus == AVAILABLE.toUpperCase() && !isSearch
          ? thunkDispatch(fetchSpecificAvailableSlots(slotData))
          : slots[index]?.slotStatus == BOOKED.toUpperCase()
          ? thunkDispatch(fetchSpecificBookedSlot(slots[index]?.slotId))
          : thunkDispatch(fetchSpecificCancelledSlot(slots[index]?.slotId));
        setShowModal(!showModal);

        break;
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentSelectedSlotId(-1);
    if (selectedNotificationData?.id) {
      dispatch(setSelectedNotificationData({ id: '', slotDate: '' }));
    }
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const showSlotModal = () => {
    const slotDay = moment(selectedNotificationData?.slotDate).format(timeStamps.DD_MMM);

    if (selectedNotificationData?.id && dayToShow === slotDay) {
      const index = slots.findIndex((slot) => slot?.slotId === selectedNotificationData?.id);

      if (index >= 0) {
        setShowModal(true);

        setCurrentSelectedSlotId(index);
        document
          .getElementById(selectedNotificationData?.id)
          ?.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
      return;
    }
  };

  useEffect(() => {
    slots.map((slot) => {
      if (slot.isRequest) {
        dispatch(toggleRequestFlag(true));
      }
    });
  }, [slots]);

  useEffect(() => {
    showSlotModal();
  }, []);
  return (
    <div className="slot-carousel-card">
      <div className="day-name">
        {isToday ? TODAY : isTomorrow ? TOMORROW : `${dayToShow}, ${dayOfDate}`}
        <span>
          {`(${noOfSlots} Slot${noOfSlots > 1 ? 's' : ''})`}
          <span></span>
        </span>
      </div>
      <div id="confirmation-container"></div>
      <CustomScroll>
        {slots.map((slot, index: number) => {
          const customClass =
            selectedNotificationData?.id == slot?.slotId
              ? slot?.noOfPanels > 1
                ? 'cardActive'
                : ' cardRow cardActive'
              : slot?.noOfPanels > 1
              ? ''
              : 'cardRow';
          return (
            <div key={index}>
              <Popover
                isOpen={showModal && currentSelectedSlotId === index}
                padding={8}
                reposition
                clickOutsideCapture={selectedNotificationData?.id !== ''}
                containerClassName="slot-booking-popover"
                onClickOutside={handleCloseModal}
                align={'center'}
                positions={['right', 'left', 'bottom', 'top']}
                content={
                  slotType === AVAILABLE ||
                  (slotType === ALL && slots[index]?.slotStatus === AVAILABLE.toUpperCase()) ? (
                    <SlotRequestCard
                      hideDelete={false}
                      slotId={isSearch ? slot?.slotId : slotIdArray}
                      isSearch={isSearch}
                      dateFilter={dateFilter}
                      panelList={selectedSlot?.slotUserDetailList}
                      handleClose={handleCloseModal}
                      fromTime={slot?.fromTime}
                      toTime={slot?.toTime}
                      currentSlot={slot}
                      selectedUser={selectedUser}
                      showModal={showModal}
                      handleShowModal={handleShowModal}
                      slotCategory={slotType}
                      isRequest={slot?.isRequest}
                    />
                  ) : (
                    <SlotCancellationModal
                      readOnly={
                        slotType === CANCELLED ||
                        (slotType === ALL && slot?.slotStatus === CANCEL_LABEL)
                      }
                      isSearch={isSearch}
                      dateFilter={dateFilter}
                      handleClose={handleCloseModal}
                      cancelReason={selectedBookedSlot?.cancelReason}
                      candidateName={selectedBookedSlot?.candidateName}
                      cancelledBy={selectedBookedSlot?.cancelledBy}
                      cancelComments={selectedBookedSlot?.cancelComments}
                      recruiterName={selectedBookedSlot?.recruiterName}
                      panelDetails={selectedBookedSlot?.slotUserDetail}
                      zohoRecruitUrl={selectedBookedSlot?.zohoRecruitUrl}
                      techStackInterviewLevel={selectedBookedSlot?.techStackInterviewLevelDto}
                      date={headerDetailsForSlots(slots[index]?.fromTime, slots[index]?.toTime)}
                      slotType={slotType}
                    />
                  )
                }>
                {slotType === ALL ? (
                  <div
                    className={`card ${customClass}`}
                    id={index.toString()}
                    onClick={() => handleSlotClick(index)}
                    style={{
                      borderColor:
                        slot?.isRequest &&
                        slot?.slotStatus !== BOOKED.toUpperCase() &&
                        slot?.slotStatus !== CANCEL.toUpperCase()
                          ? theme.warningColor1
                          : slot?.slotStatus == AVAILABLE.toUpperCase()
                          ? theme.primary
                          : slot?.slotStatus == BOOKED.toUpperCase()
                          ? theme.success
                          : theme.error
                    }}>
                    <div className="time">{`${moment(slot.fromTime).format(
                      timeStamps.HH_MM_a
                    )} - ${moment(slot.toTime).format(timeStamps.HH_MM_a)}`}</div>
                    <div className="panelName">
                      {slot?.slotStatus == AVAILABLE.toUpperCase()
                        ? slot?.panelistName
                        : slot?.candidateName}
                    </div>
                    {slot.noOfPanels > 1 && (
                      <div
                        className="count-tag"
                        style={{
                          borderColor: slot.isRequest ? theme.warningColor1 : theme.primary
                        }}>
                        {slot.noOfPanels}
                      </div>
                    )}
                  </div>
                ) : slotType === AVAILABLE ? (
                  <div
                    className={`card ${customClass}`}
                    id={index.toString()}
                    onClick={() => handleSlotClick(index)}
                    style={{ borderColor: slot.isRequest ? theme.warningColor1 : borderColor }}>
                    <div className="time">{`${moment(slot?.fromTime).format(
                      timeStamps.HH_MM_a
                    )} - ${moment(slot?.toTime).format(timeStamps.HH_MM_a)}`}</div>
                    {slot?.noOfPanels == 1 && <div className="panelName">{slot?.panelistName}</div>}
                    {slot?.noOfPanels > 1 && (
                      <div
                        className="count-tag"
                        style={{
                          borderColor: slot?.isRequest ? theme.warningColor1 : borderColor
                        }}>
                        {slot?.noOfPanels}
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className={
                      currentSelectedSlotId === index
                        ? 'card cardActive card-booked'
                        : 'card card-booked'
                    }
                    id={slot?.slotId}
                    onClick={() => handleSlotClick(index)}
                    style={{ borderColor }}>
                    <div className="time">{`${moment(slot?.fromTime).format(
                      timeStamps.HH_MM_a
                    )} - ${moment(slot?.toTime).format(timeStamps.HH_MM_a)}`}</div>
                    <div className="name">{slot?.candidateName}</div>
                  </div>
                )}
              </Popover>
            </div>
          );
        })}
      </CustomScroll>
    </div>
  );
};
export default SlotCarousel;
