import React from 'react';
import { Navigate } from 'react-router-dom';
import { getDataFromSession } from '../helpers/authHelper';

interface IProtectedRouteProps {
  component: JSX.Element;
}

interface IProtectedRouteStates {
  isLoggedIn: string | null;
}

export class ProtectedRoute extends React.PureComponent<
  IProtectedRouteProps,
  IProtectedRouteStates
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoggedIn: getDataFromSession('access-token')
    };
  }

  render() {
    const { component } = this.props;

    if (this.state.isLoggedIn) {
      return component;
    }
    return <Navigate to="/login" />;
  }
}
