import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  CANCEL_BUTTON_TEXT,
  DESIGNATION,
  EMAIL_ADDRESS,
  ON_BENCH,
  PHONE_NUMBER,
  PROJECTS,
  ROLE_PANEL,
  ROLE_RECRUITER,
  SAVE_BUTTON_TEXT,
  TECH_STACKS,
  TECH_STACK_LOP
} from 'helpers/constants';
import Button from 'app/core/shared-components/button';
import CustomSelect, { ISelectOptions } from 'app/core/shared-components/customSelect';
import ImageComponent from 'app/core/shared-components/image';
import Typography from 'app/core/shared-components/typography';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import { updateAdminStack } from 'app/redux/slices/settingSlice';
import { getAdminInfo } from 'app/redux/slices/userSlice';
import './index.style.scss';

interface IUserDetailStates {
  stack: ISelectOptions[];
  buttonDisabled: boolean;
  sharedStack: ISelectOptions[];
  techStack: ISelectOptions[];
  roles: string[];
}

function UserDetail() {
  const [userStack, setUserStack] = useState<IUserDetailStates>({
    stack: [],
    buttonDisabled: true,
    sharedStack: [],
    techStack: [],
    roles: []
  });

  const { user, shared } = useAppSelector((state) => state);
  const dispatch = useAppThunkDispatch();

  const {
    firstName,
    lastName,
    designation,
    email,
    image,
    mobileNumber,
    techStackLevelSet,
    projects
  } = user?.userData;

  const { techStack: stack } = shared;
  const { techStack, roles } = userStack;

  const toolTipConditionLength = 15;

  const sortById = (param: ISelectOptions[]) => {
    return _.orderBy(param, 'id', 'asc');
  };

  const checkArrayEquals = (param1: ISelectOptions[], param2: ISelectOptions[]) => {
    const firstSortedArray = sortById(param1);
    const secondSortedArray = sortById(param2);
    return _.isEqual(firstSortedArray, secondSortedArray);
  };

  const handleStackChange = (params: ISelectOptions | ISelectOptions[] | null) => {
    if (Array.isArray(params)) {
      setUserStack((prevState) => ({ ...prevState, stack: [...params] }));
    }
  };

  const handleSave = () => {
    const { stack } = userStack;
    dispatch(updateAdminStack(stack));
  };

  const handleReset = () => {
    setUserStack((prevState) => ({ ...prevState, stack: techStack }));
  };

  const generateTechstackDetails = () => {
    const techStackDisplayArray: string[] = [];

    if (techStackLevelSet.length) {
      techStackLevelSet.forEach((item) => {
        return techStackDisplayArray.push(`${item.techStackName}-${item.levelOfPanel}`);
      });
    }
    return techStackDisplayArray;
  };

  const updateTechStackData = () => {
    const techStackData = techStackLevelSet?.map((techStack) => ({
      id: techStack.techStackId,
      label: techStack.techStackName
    }));
    setUserStack((prevState) => ({
      ...prevState,
      stack: [...techStackData],
      techStack: [...techStackData]
    }));
  };

  const updateSharedStack = () => {
    setUserStack((prevState) => ({
      ...prevState,
      sharedStack: stack.map((item) => ({ id: item.id, label: item.stack }))
    }));
  };

  const updateUserRole = () => {
    const userRole = user.userData.role.map((item) => item.role);
    setUserStack((prevState) => ({ ...prevState, roles: [...userRole] }));
  };
  const updateSaveButtonDisableState = () => {
    const isPropsAndStateEqual = checkArrayEquals(techStack, userStack.stack);
    if (!isPropsAndStateEqual)
      setUserStack((prevState) => ({ ...prevState, buttonDisabled: false }));
    else setUserStack((prevState) => ({ ...prevState, buttonDisabled: true }));
  };

  const updateUserInfo = () => {
    dispatch(getAdminInfo());
  };

  useEffect(() => {
    updateTechStackData();
  }, [techStackLevelSet]);

  useEffect(() => {
    updateSharedStack();
  }, [stack]);

  useEffect(() => {
    updateUserRole();
  }, [user?.userData?.role]);

  useEffect(() => {
    updateSaveButtonDisableState();
  }, [techStack, userStack?.stack]);

  useEffect(() => {
    updateUserInfo();
  }, []);

  return (
    <div className="user-detail-column">
      <div className="user-detail-wrapper">
        <div className="user-image-container">
          <ImageComponent
            src={image}
            alt={`${firstName} ${lastName}`}
            customClass="user-image"
            fallbackText={`${firstName} ${lastName}`}
            fallbackClass="user-image"
            loading="eager"
          />
        </div>
        <div className="settings-column">
          <span className="name-row">{`${firstName} ${lastName}`}</span>
          <div className="user-fields">
            <div className="user-common-wrapper">
              <Typography customStyle="label">{DESIGNATION}</Typography>
              <span>{designation}</span>
            </div>
            <div className="user-common-wrapper">
              <Typography customStyle="label">{EMAIL_ADDRESS}</Typography>
              <span>{email}</span>
            </div>
            <div className="user-common-wrapper">
              <Typography customStyle="label">{PHONE_NUMBER}</Typography>
              <span>{mobileNumber}</span>
            </div>

            {roles.includes(ROLE_PANEL) && (
              <Fragment>
                <div className="user-common-wrapper settings">
                  <Typography customStyle="label">{TECH_STACK_LOP}</Typography>
                  <div className="settings-techStack">
                    {generateTechstackDetails().map((item, index: number) => {
                      return (
                        <div key={index}>
                          <div className="techStack-name">{item}</div>

                          {item?.length > toolTipConditionLength && (
                            <div className="tooltip">
                              {item}
                              <span className="tooltip-arrow" />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="user-common-wrapper projects">
                  <Typography customStyle="label">{PROJECTS}</Typography>
                  <div className="settings-projects">
                    {!!projects.length ? (
                      projects?.map((item, index: number) => {
                        return (
                          <div className="project-bg-color techStack-name" key={index}>
                            {item}
                          </div>
                        );
                      })
                    ) : (
                      <div className="on-bench-settings">{ON_BENCH}</div>
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </div>
          <div className="recruiter-container">
            {roles.includes(ROLE_RECRUITER) && (
              <div className="techStack-container">
                <Typography customStyle="label tech-name">{TECH_STACKS}</Typography>
                <div className="techStack-action-container">
                  <div className="techStack-select">
                    <CustomSelect
                      showMasterCheck
                      isMulti
                      selectedOptions={userStack?.stack}
                      dropdownOptions={userStack?.sharedStack}
                      onChange={handleStackChange}
                      showDropdownIndicator
                    />
                  </div>
                  {roles.includes(ROLE_RECRUITER) && !userStack?.buttonDisabled && (
                    <div className="user-detail-action-buttons">
                      <Button
                        variant="outlined"
                        customStyle="action-buttons action-buttons--cancel"
                        disabled={userStack?.buttonDisabled}
                        onClick={handleReset}>
                        {CANCEL_BUTTON_TEXT}
                      </Button>
                      <Button
                        onClick={handleSave}
                        variant="contained"
                        customStyle="action-buttons action-buttons--save"
                        disabled={userStack?.buttonDisabled}>
                        {SAVE_BUTTON_TEXT}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetail;
