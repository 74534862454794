import React, { HTMLAttributes } from 'react';
import './index.style.scss';

interface ITypographyProps extends HTMLAttributes<HTMLParagraphElement> {
  children: any;
  variant?:
    | 'header'
    | 'sub-header'
    | 'title'
    | 'body'
    | 'body1'
    | 'text'
    | 'captions'
    | 'error'
    | 'headline-32'
    | 'headline-24'
    | 'headline-16'
    | 'subtitle-16'
    | 'subtitle-18'
    | 'supporting-text'
    | 'button-text'
    | 'input-label'
    | 'chip-text-semibold'
    | 'chip-text';
  customStyle?: string;
}

class Typography extends React.Component<ITypographyProps> {
  render() {
    const { variant, customStyle, ...otherProps } = this.props;
    const defaultVariant = variant || 'subtitle-16';

    return (
      <div className={`${defaultVariant} ${customStyle}`} {...otherProps}>
        {this.props.children}
      </div>
    );
  }
}

export default Typography;
