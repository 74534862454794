import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { encode, storeDataInSession } from 'helpers/authHelper';
import { authService } from 'app/services/auth.service';
import { LOGIN } from '../constant';

interface IAdminDashboardStates {
  isLoading: boolean;
  role: string[];
  isFirstLogin: boolean;
}

interface ILoginResponse {
  clientId: string;
  client_id: string;
  credential: string;
  select_by: string;
}

const initialState = {
  isLoading: false,
  role: [],
  isFirstLogin: false
};

export const handleLogin = createAsyncThunk(
  LOGIN,
  async function onLogin(response: ILoginResponse) {
    const data = await authService(response.credential);
    if (!data?.error?.length) {
      const accessToken = data.data.accessToken;
      storeDataInSession('access-token', encode(accessToken));
    }
    return data;
  }
);

export const loginSlice = createSlice({
  name: LOGIN,
  initialState,
  reducers: {
    toggleLoader: (state) => {
      state.isLoading = !state.isLoading;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(handleLogin.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        role: payload?.data?.roles,
        isFirstLogin: payload?.data?.isFirstLoggedIn
      };
    });
    builder.addCase(handleLogin.pending, (state) => {
      return {
        ...state,
        isLoading: true
      };
    });
  }
});
export default loginSlice.reducer;
