import React from 'react';
import './index.style.scss';

interface IDasboardProps {
  layout: {
    column: number;
    components: any[];
  }[];
}

const DashboardColumn = ({ components }: any) => (
  <div className="column">
    {React.Children.map(components, (component) => {
      return component;
    })}
  </div>
);

const DashboardLayout = ({ layout }: IDasboardProps) => (
  <div className="dashboard-layout">
    {layout?.map(({ components, column }) => {
      return <DashboardColumn key={column} components={components} />;
    })}
  </div>
);

export default DashboardLayout;
