import React, { Component, useRef, useState } from 'react';
import { NAME, EMAIL, TECH } from 'helpers/constants';
import ImageComponent from 'app/core/shared-components/image';
import { Popover } from 'react-tiny-popover';
import arrowRight from '../../../../assets/images/arrow-right.svg';
import '../index.style.scss';

interface IRecruiter {
  designation: string;
  email: string;
  experience: number;
  firstName: string;
  id: string;
  lastName: string;
  mobileNo: string;
  profileImageUrl: string;
  techStackList: string[];
}
interface IRowsCreatorProps {
  recruiter: IRecruiter;
  componentType: string;
}

const RowCreator = (props: IRowsCreatorProps) => {
  const [showTooltip, setshowTooltip] = useState(false);

  const handleMouseOut = () => {
    setshowTooltip(false);
  };
  const handleMouseIn = (e: React.MouseEvent) => {
    setshowTooltip(true);
  };

  const { recruiter } = props;
  const [firstTS, secondTS, ...rest] = recruiter?.techStackList;
  const firstLetter = recruiter?.firstName.split('').shift();
  switch (props.componentType) {
    case NAME:
      return (
        <div className="recruiter-name-designation ">
          <div className="recruiter-img-name-container">
            <ImageComponent
              src={recruiter?.profileImageUrl || null}
              className="recruiter-img"
              fallbackClass="recruiter-img-alt"
              fallbackText={`${firstLetter}`}
            />
            <div>
              <div className="recruiter-name">{`${recruiter?.firstName} ${recruiter?.lastName}`}</div>
              <div className="recruiter-designation recruiter-gray-text">
                {recruiter?.designation}
              </div>
            </div>
          </div>
        </div>
      );
      break;
    case EMAIL:
      return <div>{recruiter?.email}</div>;
      break;
    case TECH:
      return (
        <div className="recruiter-cell-tech-stack-div">
          {!!recruiter?.techStackList.length && <div className="tech-bg">{firstTS}</div>}
          {recruiter?.techStackList.length > 1 && <div className="tech-bg">{secondTS}</div>}
          {recruiter?.techStackList.length > 2 ? (
            <Popover
              isOpen={showTooltip}
              positions={['top', 'bottom']}
              containerClassName="select-popover"
              reposition
              clickOutsideCapture
              content={
                <div>
                  <div className="tooltipCountTS">
                    {rest.map((item: string, i: number) => (
                      <span key={i}>{item}</span>
                    ))}
                  </div>
                </div>
              }>
              <div className="tooltip-container">
                <div
                  className="tech-plus"
                  onMouseEnter={handleMouseIn}
                  onMouseLeave={handleMouseOut}>
                  +{recruiter?.techStackList.length - 2}
                </div>
              </div>
            </Popover>
          ) : null}
        </div>
      );
      break;
    default:
      return <img className="profile-arrow panel-panel-gray-text" src={arrowRight} />;
  }
};

export default RowCreator;
