import React, { Component } from 'react';
import AppRouter from './router';
import { persistedStore, store } from './app/redux/store';
import { Provider } from 'react-redux';
import './App.style.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PersistGate } from 'redux-persist/integration/react';
import Loader from './app/core/shared-components/loader';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || 'none');

export class AppComponent extends Component {
  constructor(props: any) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.methodDoesNotExist = this.methodDoesNotExist.bind(this);
  }

  componentDidMount() {
    window.addEventListener('blur', this.onBlur);
  }

  componentWillUnmount() {
    window.removeEventListener('blur', this.onBlur);
  }

  onBlur() {
    toast.dismiss();
  }
  methodDoesNotExist(): void {
    throw new Error('Function not implemented.');
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={<Loader loading={true} />} persistor={persistedStore}>
          <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
            <AppRouter />
          </GoogleOAuthProvider>
        </PersistGate>
      </Provider>
    );
  }
}

export default AppComponent;
