import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useAppSelector, useAppThunkDispatch } from '../../../../redux/hooks';
import { ReactComponent as LoaderSpinner } from '../../../../../assets/images/loader-spinner.svg';
import Typography from '../../../../core/shared-components/typography';
import Button from '../../../../core/shared-components/button';
import { fetchRequestedSlots } from '../../../../redux/slices/slotsSlice';
import './index.style.scss';
import ImageComponent from '../../../../core/shared-components/image';
import NoRequestedSlot from '../../../../../assets/images/no-result-found.svg';

const SlotCard = ({ requestedSlots, handleRequestModal }: any) =>
  requestedSlots?.length > 0 ? (
    requestedSlots.map((slot: any, index: React.Key | null | undefined) => (
      <div className="slotrequest-card" key={index}>
        <div className="time-details">
          <Typography className="time-label" variant="headline-16">
            {moment(slot?.date).format('DD MMM, YYYY')}, {moment(slot?.fromTime).format('hh:mm a')}{' '}
            - {moment(slot?.toTime).format('hh:mm a')}
          </Typography>
          <Typography className="techstack-label" variant="captions">
            {slot?.techStack}-{slot?.interviewLevel}
          </Typography>
        </div>
        <Button variant="text" onClick={handleRequestModal}>
          {slot.slotRequestUserResponseDtoList.length} Response
          {slot.slotRequestUserResponseDtoList.length > 1 ? 's' : ''}
        </Button>
      </div>
    ))
  ) : (
    <div className="no-slots-available">
      <ImageComponent src={NoRequestedSlot} />
      <Typography variant="headline-16" customStyle="no-slot-message">
        No slots requested
      </Typography>
    </div>
  );

const SlotRequest = ({ handleSlotRequestModal }: any) => {
  const dispatch = useAppThunkDispatch();
  const { isRequestSlotLoading, requestedSlots: requestedSlotsData } = useAppSelector(
    (state) => state.slots
  );

  useEffect(() => {
    dispatch(fetchRequestedSlots());
  }, []);

  return (
    <div className="slot-request">
      <div className="slot-request-header">
        <Typography customStyle="slot-request-title" variant="headline-16">
          Slot Requests
        </Typography>
        <Button variant="text" onClick={handleSlotRequestModal}>
          View All
        </Button>
      </div>
      <div className="slot-request-container">
        {isRequestSlotLoading ? (
          <LoaderSpinner className="loading-spinner" />
        ) : (
          <SlotCard
            requestedSlots={requestedSlotsData}
            handleRequestModal={handleSlotRequestModal}
          />
        )}
      </div>
    </div>
  );
};

export default SlotRequest;
