import React, { useEffect, useState } from 'react';
import './index.style.scss';
import CustomSelect, { ISelectOptions } from '../../shared-components/customSelect';
import { leftSelectStyles, rightSelectStyles } from './index.style';

export interface IClippedSelectValue {
  firstChipValue: ISelectOptions | null;
  secondChipValue: ISelectOptions | null;
}
interface IClippedSelectProps {
  id?: string;
  firstChipOption: ISelectOptions[];
  secondChipOption: ISelectOptions[];
  value?: IClippedSelectValue | null;
  onChange?: (params: IClippedSelectValue) => void;
  customClass?: string;
  firstChipClass?: string;
  secondChipClass?: string;
  disableFirstChip?: boolean;
  disableSecondChip?: boolean;
  tabIndex?: boolean;
}
function ClippedSelect(props: IClippedSelectProps) {
  const { disableFirstChip = false, disableSecondChip = false, tabIndex = false } = props;
  const [selected, setSelected] = useState<IClippedSelectValue>({
    firstChipValue: null,
    secondChipValue: null
  });
  const firstHandleChange = (params: ISelectOptions | ISelectOptions[] | null) => {
    const { onChange } = props;
    if (!Array.isArray(params)) {
      const currentValue = {
        firstChipValue: params,
        secondChipValue: selected?.secondChipValue || null
      };
      setSelected(currentValue);
      onChange && onChange(currentValue);
    }
  };
  const secondHandleChange = (params: ISelectOptions | ISelectOptions[] | null) => {
    const { onChange } = props;
    if (!Array.isArray(params)) {
      const currentValue = {
        firstChipValue: selected?.firstChipValue || null,
        secondChipValue: params
      };
      setSelected(currentValue);
      onChange && onChange(currentValue);
    }
  };
  useEffect(() => {
    const { value } = props;
    if (value && Object.values(value).length === 2) setSelected(value);
  }, [props.value]);

  return (
    <div className={`clipped-select-wrapper ${props.customClass}`}>
      <CustomSelect
        dropdownOptions={props.firstChipOption}
        onChange={firstHandleChange}
        selectedOptions={selected?.firstChipValue}
        showDropdownIndicator
        showFullWidth
        customStyles={leftSelectStyles}
      />
      <CustomSelect
        dropdownOptions={props.secondChipOption}
        onChange={secondHandleChange}
        selectedOptions={selected?.secondChipValue}
        showDropdownIndicator
        showFullWidth
        customStyles={rightSelectStyles}
      />
    </div>
  );
}
export default ClippedSelect;
