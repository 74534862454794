import React, { useEffect, useState } from 'react';
import Typography from '../../../../core/shared-components/typography';
import './index.style.scss';
import MonthsToggle from '../months-toggle';
import moment, { Moment } from 'moment';
import RewardsData from './RewardsData';
import {
  getRewardsData,
  IRewardsUser,
  IRewardsWidgetData
} from '../../../../redux/slices/adminDashboardSlice';
import { useAppSelector, useAppThunkDispatch } from '../../../../redux/hooks';
import Images from '../../../../../assets/images';
import ImageComponent from '../../../../core/shared-components/image';
import ComponentLoader from '../loader-component';

interface IRewardsWidgetStates {
  month: Moment;
  rewardsData: IRewardsWidgetData;
  firstRankData: IRewardsUser[];
  secondRankData: IRewardsUser[];
  thirdRankData: IRewardsUser[];
}

const rewardsDataJSON = {
  rank: 1,
  userPoints: 2800,
  rewardsRankingDtoList: [
    {
      firstName: 'Ravindra',
      lastName: 'Singh',
      profileImage: null,
      designation: 'DevOps Engineer',
      points: 2800,
      rank: 1
    },
    {
      firstName: 'Nishu',
      lastName: 'Kumari',
      profileImage: null,
      designation: 'Associate software Engineer',
      points: 2800,
      rank: 1
    },
    {
      firstName: 'Jyoti',
      lastName: 'Kamble',
      profileImage: null,
      designation: 'Software Engineer',
      points: 2800,
      rank: 1
    },
    {
      firstName: 'Akshay',
      lastName: 'Khamkar',
      profileImage: null,
      designation: 'Associate software Engineer',
      points: 2800,
      rank: 1
    },
    {
      firstName: 'Ashwini',
      lastName: 'Kote',
      profileImage: null,
      designation: 'Tech Lead',
      points: 2320,
      rank: 2
    },
    {
      firstName: 'Sohail',
      lastName: 'Hamdule',
      profileImage: null,
      designation: 'Tech Lead',
      points: 2320,
      rank: 2
    },
    {
      firstName: 'Shantanu',
      lastName: 'Radke',
      profileImage: null,
      designation: 'Tech Lead',
      points: 2320,
      rank: 2
    },
    {
      firstName: 'Shubham',
      lastName: 'Rajpurohit',
      profileImage: null,
      designation: 'DevOps Lead',
      points: 2080,
      rank: 3
    },
    {
      firstName: 'Varun',
      lastName: 'Elavia',
      profileImage: null,
      designation: 'DevOps Lead',
      points: 2080,
      rank: 3
    }
  ]
};

const RewardsWidget = () => {
  const [rewardsWidgetState, setRewardsWidgetState] = useState<IRewardsWidgetStates>({
    month: moment().subtract(1, 'months'),
    rewardsData: {} as IRewardsWidgetData,
    firstRankData: [] as IRewardsUser[],
    secondRankData: [] as IRewardsUser[],
    thirdRankData: [] as IRewardsUser[]
  });

  const { rewardsWidgetData, isRewardsDataLoading } = useAppSelector(
    (state) => state.adminDashboard
  );
  const dispatch = useAppThunkDispatch();

  const { noRewards } = Images;

  const handleMonthChange = (month: Moment) => {
    setRewardsWidgetState((prevState) => ({ ...prevState, month: month }));
  };

  useEffect(() => {
    const monthNumber = parseInt(month.format('M'));
    const year = parseInt(month.format('YYYY'));
    dispatch(getRewardsData({ month: monthNumber, year: year })).then(({ payload }) => {
      if (!payload.error) {
        const firstRankData = payload?.data?.rewardsRankingDtoList?.filter(
          (user: IRewardsUser) => user.rank === 1
        );
        const secondRankData = payload?.data?.rewardsRankingDtoList?.filter(
          (user: IRewardsUser) => user.rank === 2
        );
        const thirdRankData = payload?.data?.rewardsRankingDtoList?.filter(
          (user: IRewardsUser) => user.rank === 3
        );
        setRewardsWidgetState((prevState) => ({
          ...prevState,
          firstRankData: firstRankData,
          secondRankData: secondRankData,
          thirdRankData: thirdRankData
        }));
      }
    });
  }, [rewardsWidgetState?.month]);

  useEffect(() => {
    setRewardsWidgetState((prevState) => ({ ...prevState, rewardsData: rewardsWidgetData }));
  }, [rewardsWidgetData]);

  const { month, rewardsData, firstRankData, secondRankData, thirdRankData } = rewardsWidgetState;
  const suffix = rewardsData?.userPoints === 1 ? 'st' : rewardsData?.userPoints === 2 ? 'nd' : 'th';

  return (
    <div className="rewards-widget-wrapper">
      <div className="header">
        <Typography variant="headline-16" customStyle="rewards-header">
          Rewards
        </Typography>
      </div>
      <MonthsToggle month={month} onMonthToggle={handleMonthChange} hideCurrentMonth />
      {isRewardsDataLoading ? (
        <ComponentLoader />
      ) : rewardsWidgetData?.rewardsRankingDtoList?.length === undefined ||
        rewardsWidgetData?.rewardsRankingDtoList?.length === 0 ? (
        <div className="no-rewards-data">
          <ImageComponent src={noRewards} />
          <Typography variant="headline-16" customStyle="hint">
            No Data Found
          </Typography>
        </div>
      ) : (
        <>
          {rewardsData?.rank !== 0 ? (
            rewardsData?.rank === 1 ? (
              <div className="message">
                <span>Congratulations!</span> You are on the top of the leaderboard with
                <span>{` ${rewardsData?.userPoints}`}</span> points.
              </div>
            ) : (
              <div className="message">
                You are at
                <span> {` ${rewardsData?.rank}${suffix} `} </span>
                position with
                <span> {` ${rewardsData?.userPoints}`}</span> points.
              </div>
            )
          ) : (
            <div className="message">
              Hurray! You&apos;ve joined the team. Start interviewing to gain points.
            </div>
          )}
          <div className="rewards-data-wrapper">
            <RewardsData userData={secondRankData} position={2} />
            <RewardsData userData={firstRankData} position={1} />
            <RewardsData userData={thirdRankData} position={3} />
          </div>
        </>
      )}
    </div>
  );
};
export default RewardsWidget;
