export const DUPLICATE_VALUE =
  'Please remove the duplicate entries for Tech Stack and Level of Panel in order to save. ';
export const LOGIN_ROLE_ERROR = 'The email you selected does not appear to be registered. Please ';
export const SAVE_MODAL_DESCRIPTION = 'Are you sure you want to save changes of the selected User?';
export const DELETE_MODAL_DESCRIPTION =
  'Are you sure you want to delete the selected Panel? The profile cannot be restored once deleted.';
export const APPROVE_PANEL_DASHBOARD =
  'Are you sure you want to save details of the selected user? The selected user will be moved to Approved';
export const NO_ONBOARD_USERS_TITLE = 'No Users left';
export const NO_PANELLIST_TITLE = 'No Panellists left';
export const NO_USERS_LEFT = 'No users';
export const NO_ONBOARD_USERS_DESC = 'All users have been onboarded';
export const NO_PANELLIST_DESC = 'All panellists have been approved';
export const NO_FILTERS_TITLE = 'Your filters produced no results.';
export const NO_FILTERS_DESC = 'Try adjusting or clearing your filters to display better results.';
export const SAVE_PANEL_DESCRIPTION =
  'Are you sure you want to save changes of the selected Panel?';
export const UNABLE_PANEL_DELETE = 'Unable to delete members';
export const UNABLE_PANEL_DELETE_DESC =
  'Delete operation failed as following members have active bookings. Please try again by excluding them.';
export const NO_PANELLISTS_FOUND = 'No Panelists found';
export const NO_PANELLISTS_FOUND_DESC = 'No panelists are assigned to this';
export const NO_RECRUITERS_FOUND = 'No Recruiters found';
export const NO_RECRUITERS_FOUND_DESC = 'No recruiters are assigned to this';
export const SAVE_TA = 'Save Talent Acquisition';
export const DELETE_TA = 'Delete Talent Acquisition';
export const DELETE_TA_DESCRIPTION =
  'Are you sure you want to delete the selected Talent Acquisition? The profile cannot be restored once deleted.';
export const SAVE_TA_DESCRIPTION =
  'Are you sure you want to save changes of the selected Talent Acquisition?';
export const TA_SEARCH_PLACEHOLDER = 'Search for Talent Acquisition';
export const TA_UNABLE_TO_DELETE =
  '“Floyd Miles” cannot be deleted unless their active requests and bookings are either cancelled or fulfilled.';
export const TA_UNABLE_DELETE_TITLE = 'Unable to delete Talent Aquisition';
export const DELETE_EDGE_CASE = 'Unable to delete Level';
export const DELETE_LOI_DESCRIPTION =
  'Are you sure you want to delete the selected Level of Panel? The level cannot be restored once deleted.';
export const LEVEL_NAME_VALIDATION_ERROR = 'Please enter a valid value for level.';
export const LEVEL_NAME_ALREADY_EXIST_ERROR =
  'This Level of Panel already exists. Try another name.';
export const LEVEL_EXPERIENCE_ERROR = 'Please enter a valid value for experience.';
export const LEVEL_EXPERIENCE_PLACEHOLDER = 'Enter the experience range in years';
export const HOME_SUCCESS_TOAST = 'Successfully Approved 1 person';
export const ATLEAST_ONE_DIGIT_ERROR = 'Level should contain atleast one digit.';
export const DELETE_TECH_MODAL_DESCRIPTION =
  'Are you sure you want to delete the selected Tech Stack? The tech stack cannot be restored once deleted.';
export const NO_UPLOADED_IMAGE_ERROR = 'Please upload image file';
export const NO_TECH_NAME_ERROR = 'Please enter Tech Stack name.';
export const INVALID_TECH_NAME_ERROR = 'Please enter valid tech stack name.';
export const TECH_NAME_ALREADY_EXIST_ERROR = 'This Tech Stack already exists. Try another name.';
export const SHOULD_CONTAIN_ALPHABET_ERROR = 'Tech stack name should contain atleast one alphabet';
export const MAX_TECH_LENGTH = 'Tech stack length should be less than 30 characters.';
export const PROJECT_DELETE_MODAL_TEXT =
  'Are you sure you want to delete the selected Project? The project cannot be restored once deleted.';
export const PROJECT_DELETE_CONFIRMATION_TEXT =
  'Please note : Panelists assigned only to this project will be marked "On Bench".';
export const INVALID_PROJECT_NAME_ERROR = 'This Project name already exists. Try another name';
export const INVALID_LEVEL_NAME_ERROR = 'Please enter valid Level name.';
export const MAX_PROJECT_LENGTH = 'Project name should be less than 30 characters.';
export const PROJECT_NAME_PLACEHOLDER = 'Enter project name';
export const MAX_LEVEL_LENGTH = 'Level name should be less than 30 characters.';
export const MAX_EXPERIENCE_LENGTH = 'Experience should be less than 30 characters.';
export const PROJECT_NAME_CONTAINS_SPECIAL_CHAR_ERROR = 'Please enter valid Project name.';
export const NO_RESULT_FOUND_ERROR =
  'Sorry, we couldn’t find any results.Try again using a different keyword';
export const PROJECT_NAME_ALREADY_EXIST = 'This Project name already exists. Try another name.';
export const PROJECT_UNDO_TAG = 'Project has been successfully deleted';
export const INVALID_FILE_TYPE = 'Please select .jpg , .jpeg or .png ';
export const TECH_DELETE_TOAST_MESSAGE = 'Tech Stack has been successfully deleted';
export const SLOT_CANCEL_TOAST_MESSAGE = 'Slot cancelled successfully';
export const SLOT_REQUEST_ACCEPTED = 'Slot request accepted';
export const SLOT_REQUEST_DECLINED = 'Slot request Declined.';
export const SLOT_DELETED_TOAST_MESSAGE = 'Slot deleted successfully';
export const SLOT_ADDED_TOAST_MESSAGE = 'Slot added successfully';
export const SLOT_EDIT_TOAST_MESSAGE = 'Slot edited successfully';
export const SLOT_OVERLAP_TOAST_MESSAGE = 'Slots are overlapping for the selected time';
export const PANEL_INREVIEW_MESSAGE =
  'Slots can not be added for Panels that are not approved. Please contact their reporting manager.';
export const PANEL_ARCHIVED_MESSAGE =
  'Slots can not be added for Panels that are not approved. Please contact their reporting manager.';
export const MILESTONE_200 = 'Great job! You have reached your milestone of 200 interviews.';
export const MILESTONE_100 = 'Great job! You have reached your milestone of 100 interviews ';
export const MILESTONE_50 = 'Great job! You have reached your milestone of 50 interviews.';
export const MILESTONE_25 = 'Great job! You have reached your milestone of 25 interviews';
export const MILESTONE_UNLOCK = `Great job! Take 25 interviews to unlock a new achievement`;
export const GOLDEN_BADGE = `WOW! You have done it, you have now received the golden batch for taking most number of interviews`;
export const MOST_NUM_OF_SLOTS =
  'Kudos! You have achieved the golden mode on adding the most number of slots';
export const ACHIEVEMENT_UNLOCKED = 'This achievement is now unlocked. ';
export const STREAK_CONGRATS = 'Congratulations!! you have unlocked the golden streak ';
export const THREE_STREAKS = 'Woahh!! Your streak of adding 3 or more interviews is consistent.';
export const SLOTS_MADE_AVAILABLE = 'Slot Made Available';
export const NO_SLOTS_PROVIDED = 'No available slots have been provided';
export const NO_SLOTS_BOOKED = 'No slots have been booked';
export const NO_SLOTS_CANCELLED = 'No slots have been cancelled';
export const NO_SLOTS_AVAILABLE = 'No slots available';
export const SLOTS_OVERAPPING_MESSAGE = 'Slots are overlapping for the selected time';
export const DELETED_SLOT = 'Successfully deleted the slot request';
export const NO_SLOTS_FOUND = 'No slots found';
export const ADD_TECH_STACK_TO_VIEW_SLOTS = 'Add a Tech Stack to view the slots';
export const DUPLICATE_SLOT_TIME = 'Duplicate entry';
export const SLOTS_COINCIDING_ERROR =
  'There seem to be coinciding slots provided for the time requested.';
export const DELETED_SLOT_TOAST_MESSAGE = 'Successfully deleted the slot request';
export const MIN_ONE_VALUE = 'Please select alteast one techStack and level or set to archive';
export const TECHSTACK_LOP_DUPLICATE_ERROR =
  'Please remove the duplicate entries for Tech Stack and Level of Panel in order to save.';
export const INPUT_PLACEHOLDER = 'Please enter text here...';
export const LEVEL_OF_PANEL_EXISTS = 'This Level of Panel already exists. Try another name.';
export const PROJECT_ALREADY_EXISTS = 'This Project Name already exists. Try another name.';
export const APPROVE_USER_MODAL_TITLE = 'Approve User?';
export const NO_EVENTS_FOUND = 'No Events Found';
export const CHECK_FOR_ANOTHER_DATE = 'Check for another date';
export const PAST_START_TIME = 'Start time is already in the past, change start time to add slot';
export const CANNOT_DELETE_ACTIVE_BOOKINGS =
  'Cannot be deleted unless their active requests and bookings are either cancelled or fulfilled';
export const MULTIPLE_SELECT_VALUE = 'Select value must be an array when multiple is true';
export const DELETE_SLOT_RESQUEST_TOAST_MESSAGE =
  'Are you sure you want to delete the selected Slot Request? Doing so will also delete all slots provided by panels for this request.';
export const DELETE_SLOT_REQUEST = 'Delete Slot request?';
export const MARK_AS_BOOKED = 'Mark as booked';
export const SLOT_MARKED_AS_BOOKED = 'Slot marked as booked';
export const NO_SPECIAL_CHARACTERS_ALLOWED = 'No numbers and special characters allowed';
export const MAKE_SLOT_AVAILABLE = 'Make the slot available again';
export const NO_RESPONSES_RECEIVED = 'No responses received';
export const TECH_STACK_EDITED = 'Tech Stack Edited Successfully';
export const SLOT_DELETE_CONFIRMATION =
  'Are you sure you want to delete the selected available slot? The slot cannot be restored once deleted.';
export const PANEL_APPROVAL = 'Slots cannot be added until the Panel is approved';
export const SLOT_DELETED_SUCCESSFULLY = 'Slot deleted successfully';
export const SLOT_REQUESTED_SUCCESSFULLY = 'Slot requested successfully';
export const LOGOUT_MESSAGE = 'Are you sure you want to Log Out from the application?';
export const NEW_LOGOUT_MESSAGE = 'Are you sure you want to Log out from the application?';
export const COULDNT_FIND_RESULTS =
  'Sorry, we couldn’t find any results. Try again using a different keyword';
export const LOADING = 'Loading results....';
export const START_TYPING = 'Start typing the team member’s name you are searching for.';
export const DELETE_SINGLE_USER =
  'Are you sure you want to delete the selected Panel? The profile cannot be restored once deleted';
export const APPROVAL_CONFIRMATION =
  'Are you sure you want to approve the selected users? The selected users will be moved to Approved';
export const DELETE_CONFIRMATION_TEXT =
  'Are you sure you want to delete the selected Panels? The profile cannot be restored once deleted';
export const LOGOUT_CONFIRMATION_TEXT = ' Are you sure you want to Log Out from the application?';
export const TA_DELETE_TEXT =
  'cannot be deleted unless their active requests and bookings are either cancelled or fulfilled.';
export const INTERVIEW_CANCELLED = 'Interview successfully cancelled';
export const INTERVIEW_CANCELLED_ERR_MSG_LIMIT = 'Weekly limit cannot be less than daily limit.';
export const SLOT_COUNT_LIMIT_DESC = ' Number of slot should not be greater than 300';
export const SLOT_LESS_THAN_DESC = 'Number of slot should be greater than 0';
export const INTERVIEW_CANCELLED_ERR_MSG = 'Values cannot be negative';
export const SUCCESSFULLY_APPROVED = 'Successfully approved';
export const SUCCESSFULLY_ONBOARDED = 'Successfully onboarded';
export const SUCCESSFULLY_ARCHIEVED = 'Successfully archived';
export const SUCCESSFULLY_DELETED = 'Successfully deleted';
export const ON_BENCH_LABEL = 'Successfully marked';
export const ON_BENCH_USER_TEXT = 'users on bench';
export const PAST_TIME_MSG = 'The time slot selected is already in past';
export const APPROVAL_MSG_1 = 'Are you sure you want to approve';
export const APPROVAL_MSG_2 = '? The selected user will be moved to Approved';
export const SUCCESSFULLY_APPROVED_USERS = 'Successfully approved users';
export const DELETE_TECHSTACK_MESSAGE =
  'Are you sure you want to delete the selected Tech Stack?  The tech stack cannot be restored once deleted.';
export const UNABLE_TO_DELETE_TECHSTACK = 'Unable to delete Tech Stack';
export const DELETE_TECHSTACK = 'Delete Tech Stack';
export const SLOT_REQUEST_RAISED =
  'slot request has been raised. Please tap on it and accept or decline the same';
export const LEVEL_DELETE_SUCCESSFUL = 'Level has been sucessefully deleted';
export const SLOT_REQUEST_HINT = 'Enter the details for the slot you are looking for.';
export const NO_USERS_FOUND = 'No Users Found';
export const USER_SEARCHED_DOES_NOT_EXIST = 'The user you searched for does not exist';
