import React, { Fragment } from 'react';

interface ITechStackInterviewLevelSet {
  level: string;
  levelId: string;
  roleId: string;
  techStack: string;
  techStackId: string;
}
interface ITeckStackLOIProps {
  techStackInterviewLevelSet: ITechStackInterviewLevelSet[];
}

const TechStackLOI = (props: ITeckStackLOIProps) => {
  const { techStackInterviewLevelSet } = props;
  return (
    <Fragment>
      {!!techStackInterviewLevelSet.length &&
        techStackInterviewLevelSet?.slice(0, 2)?.map((item, index: number) => (
          <div className="single-tag " key={index}>
            {item?.techStack} - {item?.level}
          </div>
        ))}
    </Fragment>
  );
};

export default TechStackLOI;
