import React, { Fragment, useRef, useMemo, useLayoutEffect } from 'react';
import './index.style.scss';
import Typography from '../../../../core/shared-components/typography';
import ImageComponent from '../../../../core/shared-components/image';
import Tag from '../../../../core/shared-components/tag';
import { PANEL_STATUS } from '../../../../../helpers/constants';

interface ICandidateDetailProps {
  userImage: string | null;
  userName: string;
  userEmail: string;
  userDesignation: string;
  userTechStackLevel: { techStack: string; level: string }[] | null;
  userProject: string[] | null;
  weeklyInterviewCount: string | number | null;
  dailyInterviewCount: string | number | null;
  status: 'APPROVED' | 'ARCHIVE' | 'IN_REVIEW';
}

function CandidateDetail(props: ICandidateDetailProps) {
  const {
    userImage,
    userName,
    userEmail,
    userDesignation,
    weeklyInterviewCount,
    dailyInterviewCount
  } = props;

  const divRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (divRef.current) divRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const renderTechStackLevelTags = useMemo(() => {
    const { userTechStackLevel } = props;
    if (Array.isArray(userTechStackLevel)) {
      if (userTechStackLevel.length > 2) {
        return (
          <Fragment>
            {userTechStackLevel.slice(0, 2).map((item: any, index) => (
              <Tag
                key={index}
                label={`${item.techStack} ${item.level}`}
                customClass="techStack-tag"
              />
            ))}
            <Tag
              label={`+ ${userTechStackLevel.length - 2}`}
              customClass="techStack-tag tag-no-ellipsis"
            />
          </Fragment>
        );
      } else {
        return userTechStackLevel.map((item: any, index) => (
          <Tag key={index} label={`${item.techStack} ${item.level}`} customClass="techStack-tag" />
        ));
      }
    }
  }, [props.userTechStackLevel]);

  const renderProjectsTags = useMemo(() => {
    const { userProject } = props;
    if (Array.isArray(userProject)) {
      if (userProject.length > 2) {
        return (
          <Fragment>
            {userProject.slice(0, 2).map((item: any, index) => (
              <Tag key={index} label={item} customClass="project-tag" />
            ))}
            <Tag label={`+ ${userProject.length - 2}`} customClass="project-tag" tag-no-ellipsis />
          </Fragment>
        );
      } else {
        return userProject.map((item: any, index) => (
          <Tag key={index} label={item} customClass="project-tag" />
        ));
      }
    }
    return <Tag label={'On Bench'} customClass="project-tag-warning" />;
  }, [props.userProject]);

  return (
    <div ref={divRef} className="schedule-detail-container">
      <div className="schedule-detail-header">
        <div className="schedule-detail-label-container">
          <Typography customStyle="schedule-detail-header-label">Name</Typography>
        </div>
        <div className="schedule-user-detail-wrapper">
          <ImageComponent
            src={userImage}
            alt={userName}
            fallbackText={userName}
            customClass="user-image"
            fallbackClass="fallback-user-image"
          />
          <div className="user-detail-container">
            <div className="user-detail-status-conatiner">
              <Typography customStyle="schedule-user-name">{userName}</Typography>
              {props.status === PANEL_STATUS.inReview && (
                <div className="status-container status-container-warning">
                  <div className="color-indicator color-indicator-warning"></div>
                  <Typography customStyle="status-text status-text-warning">In Review</Typography>
                </div>
              )}
              {props.status === PANEL_STATUS.archive && (
                <div className="status-container status-container-error">
                  <div className="color-indicator color-indicator-error"></div>
                  <Typography customStyle="status-text status-text-error">Archive</Typography>
                </div>
              )}
            </div>
            <Typography customStyle="schedule-user-detail-label">{userDesignation}</Typography>
            <Typography customStyle="schedule-user-detail-label">{userEmail}</Typography>
          </div>
        </div>
      </div>
      <div className="schedule-detail-header">
        <div className="schedule-detail-label-container">
          <Typography customStyle="schedule-detail-header-label">
            Tech Stack - Level of Interview
          </Typography>
        </div>
        <div className="schedule-detail-tag-container">{renderTechStackLevelTags}</div>
      </div>
      <div className="schedule-detail-header">
        <div className="schedule-detail-label-container">
          <Typography customStyle="schedule-detail-header-label">Project</Typography>
        </div>
        <div className="schedule-detail-tag-container">{renderProjectsTags}</div>
      </div>
      <div className="schedule-detail-header">
        <div className="schedule-detail-label-container">
          <Typography customStyle="schedule-detail-header-label">Max Interviews</Typography>
        </div>
        <div className="schedule-detail-interview-limit-container">
          <div className="schedule-detail-interview-limit-wrapper">
            <Typography customStyle="schedule-limit-label">Weekly:</Typography>
            <Typography customStyle="limit-count-label">
              {weeklyInterviewCount !== null ? weeklyInterviewCount : 50}
            </Typography>
          </div>
          <div className="schedule-detail-interview-limit-wrapper">
            <Typography customStyle="schedule-limit-label">Daily:</Typography>
            <Typography customStyle="limit-count-label">
              {dailyInterviewCount !== null ? dailyInterviewCount : 50}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(CandidateDetail);
