import React, { Component } from 'react';
import service from './index.service';
import axios from 'axios';
import { decode, getDataFromSession } from '../../helpers/authHelper';
import { IMAGE_FILE } from '../../helpers/constants';
import {
  TS_USERS_PANEL_LIST,
  TS_USERS_RECRUITER_LIST,
  TS_USERS_SEARCH_PANEL_LIST,
  TS_USERS_SEARCH_RECRUITER_LIST
} from '../../helpers/apiRoutes';
const baseURL = process.env.REACT_APP_API_URL;
const token = decode(getDataFromSession('access-token') || '');

export async function techStackService(sortingOrder: string) {
  try {
    const response = await service.get(`/user/tech-stack/list?sortBy=${sortingOrder}`);
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}
export async function techStackEdit(
  id: string,
  stackName: string,
  imageFile: any,
  imageUrl: string
) {
  try {
    const imageParam = imageFile ? imageUrl : '';
    const endPointUrl =
      imageUrl === null && imageFile !== null
        ? `/admin/tech-stack/edit?id=${id}&techStackName=${encodeURIComponent(stackName)}`
        : `/admin/tech-stack/edit?id=${id}&techStackName=${encodeURIComponent(
            stackName
          )}&imageUrl=${imageParam}`;
    let response;
    if (imageUrl === imageFile?.get(IMAGE_FILE)) {
      response = await service.put(endPointUrl);
    } else {
      response = await service.put(endPointUrl, imageFile);
    }
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function addTechStack(stackname: string, imageFile: any) {
  try {
    const response = await service.post(
      `/admin/tech-stack/add?techStackName=${encodeURIComponent(stackname)}`,
      imageFile
    );
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}
export async function deleteTechStack(id: string) {
  try {
    const response = await service.delete(`/admin/tech-stack/delete?id=${id}`);
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function searchTechStack(searchText: string, signal: any) {
  try {
    const response = await service.get(`admin/tech-stack/search?text=${searchText}`, {
      signal
    });
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}
export async function userListPanelTechStack(id: string) {
  try {
    const response = await service.get(`${TS_USERS_PANEL_LIST}?techStackId=${id}`);
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function userListSearchPanelTechStack(id: string, searchText: string) {
  try {
    const response = await service.get(
      `${TS_USERS_SEARCH_PANEL_LIST}?searchText=${searchText}&techStackId=${id}`
    );
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}
export async function userListRecruiterTechStack(id: string) {
  try {
    const response = await service.get(`${TS_USERS_RECRUITER_LIST}?techStackId=${id}`);
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function userListSearchRecruiterTechStack(id: string, searchText: string) {
  try {
    const response = await service.get(
      `${TS_USERS_SEARCH_RECRUITER_LIST}?searchText=${searchText}&techStackId=${id}`
    );
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}
