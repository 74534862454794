import React, { useState, useCallback, useEffect, Fragment, useMemo } from 'react';
import './index.style.scss';
import Typography from '../../../../core/shared-components/typography';
import Images from '../../../../../assets/images';
import ImageComponent from '../../../../core/shared-components/image';
import Button from '../../../../core/shared-components/button';
import InputBox from '../../../../core/shared-components/input-box';
import moment, { Moment } from 'moment';
import CheckBox from '../../../../core/shared-components/checkbox';
import Tag from '../../../../core/shared-components/tag';
import Radio from '../../../../core/shared-components/radio-group';
import {
  CANCELLATION_OPTION,
  CANCELLATION_OPTION_TYPE,
  CANDIDATE_BUSY,
  OTHER_BUSY,
  PANEL_BUSY,
  SLOT_CANCEL_OPTION
} from '../../../../../helpers/constants';
import { getSlotInfo } from '../../../../redux/slices/scheduleSlice';
import { useAppSelector, useAppThunkDispatch } from '../../../../redux/hooks';
import Loader from '../../../../core/shared-components/loader';

interface ISlotCancelProps {
  slotId: string | number;
  end: Date | null;
  onCancelHandler?: (param: ISlotCancel) => void;
  onCloseClick?: () => void;
  hideCancelField?: boolean;
  showMakeSlotAvailableCheckbox?: boolean;
  showCancelledBy?: boolean;
}

export interface ISlotCancelPopup {
  cancelComments: string;
  makeSlotAvailable: boolean;
}

export interface ISlotCancel extends ISlotCancelPopup {
  cancelOption: CANCELLATION_OPTION_TYPE;
}

interface ISlotCancelState extends ISlotCancelPopup {
  startDate: Moment;
  endDate: Moment;
  showCancelField: boolean;
  cancelOption: CANCELLATION_OPTION_TYPE | string;
  candidateName: string;
  techStack: string;
  zohoRecruitUrl: string | null;
}

function SlotCancel(props: ISlotCancelProps) {
  const { onCloseClick, showMakeSlotAvailableCheckbox = false, showCancelledBy } = props;
  const [slotCancelState, setSlotCancelState] = useState<ISlotCancelState>({
    cancelComments: '',
    makeSlotAvailable: false,
    startDate: moment(),
    endDate: moment(),
    showCancelField: moment(props.end).isAfter(moment()) ? true || !!props.hideCancelField : false,
    cancelOption: OTHER_BUSY,
    candidateName: '',
    techStack: '',
    zohoRecruitUrl: null
  });
  const reduxScheduleState = useAppSelector((state) => state.schedule);
  const dispatch = useAppThunkDispatch();
  const disableCancelButton = useMemo(() => {
    const { cancelComments, cancelOption } = slotCancelState;
    if (cancelOption === PANEL_BUSY || cancelOption === CANDIDATE_BUSY) return false;
    else if (cancelOption === OTHER_BUSY && cancelComments.toString().trim().length) return false;
    else return true;
  }, [slotCancelState.cancelComments, slotCancelState.cancelOption]);

  const { startDate, endDate, showCancelField, techStack, candidateName, zohoRecruitUrl } =
    slotCancelState;
  const { cancelledPopupLoading } = reduxScheduleState;

  useEffect(() => {
    (async () => {
      const { slotId } = props;
      const response = await dispatch(getSlotInfo(slotId));
      if (response.payload.data) {
        const { data } = response.payload;
        setSlotCancelState((prevState) => ({
          ...prevState,
          cancelOption: PANEL_BUSY,
          startDate: moment(data.fromTime),
          endDate: moment(data.toTime),
          candidateName: data.candidateName,
          techStack: data.techStackInterviewLevelDto.techStack,
          zohoRecruitUrl: data.zohoRecruitUrl,
          showCancelField: moment(data.toTime).isAfter(moment())
            ? true || !!props.hideCancelField
            : false
        }));
      }
    })();
  }, [props.slotId]);

  const handleInputChange = useCallback((param: string | number) => {
    const regex = new RegExp(/^[A-Za-z,.!\s]*$/);
    if (regex.test(param.toString()))
      setSlotCancelState((prevState) => ({ ...prevState, cancelComments: param.toString() }));
  }, []);

  const handleClick = useCallback(() => {
    const { onCancelHandler } = props;
    if (endDate.isAfter(moment())) {
      let cancelledBy: CANCELLATION_OPTION_TYPE = CANCELLATION_OPTION.panel;
      if (slotCancelState.cancelOption?.toString()?.toUpperCase() === CANDIDATE_BUSY.toUpperCase())
        cancelledBy = CANCELLATION_OPTION.candidate;
      else if (slotCancelState.cancelOption?.toString()?.toUpperCase() === OTHER_BUSY.toUpperCase())
        cancelledBy = CANCELLATION_OPTION.other;
      const data = {
        cancelComments: slotCancelState.cancelComments,
        makeSlotAvailable: slotCancelState.makeSlotAvailable,
        cancelOption: cancelledBy
      };
      onCancelHandler && onCancelHandler(data);
    }
  }, [props.onCancelHandler, slotCancelState]);

  const handleCheckBoxClick = useCallback(() => {
    setSlotCancelState((prevState) => ({
      ...prevState,
      makeSlotAvailable: !prevState.makeSlotAvailable
    }));
  }, []);

  const handleRadioChange = useCallback((val: string) => {
    setSlotCancelState((prevState) => ({
      ...prevState,
      cancelOption: val
    }));
  }, []);

  return (
    <div className="slot-cancel-container">
      <div className="slot-cancel-header">
        <div className="slot-cancel-title-wrapper">
          <Typography customStyle="slot-booked-label">Booked Slot</Typography>
          {zohoRecruitUrl && (
            <a
              href={`${zohoRecruitUrl}`}
              className="slot-cancel-icon-wrapper"
              target="_blank"
              rel="noreferrer">
              <ImageComponent src={Images.infoGray} alt="info" />
            </a>
          )}
        </div>
        <div className="slot-cancel-icon-wrapper" onClick={onCloseClick}>
          <ImageComponent src={Images.close} alt="close" />
        </div>
      </div>
      <div className="slot-cancel-main">
        <div className="slot-cancel-main-sub-container">
          <Typography customStyle="slot-timming-label">{`${startDate.format(
            'hh:mm A'
          )} - ${endDate.format('hh:mm A')}`}</Typography>
          <div className="slot-cancel-book-container">
            <Tag label={`${techStack}`} customClass="slot-booked-stack-label" />
          </div>
        </div>
        <Typography customStyle="slot-booked-date-label">{`${startDate.format(
          'dddd, DD MMMM YYYY'
        )}`}</Typography>
      </div>
      <div className="slot-cancelled-name-wrapper">
        <Typography customStyle="slot-candidate-label">Candidate name :</Typography>
        <Typography customStyle="slot-candidate-name-label">{candidateName}</Typography>
      </div>
      {showCancelField && (
        <div className="slot-cancel-footer-wrapper">
          <div className="slot-cancel-reason-wrapper">
            {showCancelledBy && (
              <div className="slot-cancel-mark-wrapper">
                <Typography customStyle="slot-reason-label">Reason for cancellation</Typography>
                <div className="slot-cancel-radio-wrapper">
                  <Radio
                    labelArray={SLOT_CANCEL_OPTION}
                    setCurrentValue={handleRadioChange}
                    currentValue={slotCancelState.cancelOption}
                  />
                </div>
              </div>
            )}
            <Typography customStyle="slot-reason-label">
              Remark{slotCancelState.cancelOption === OTHER_BUSY && `*`}{' '}
            </Typography>
            <InputBox
              customClass="slot-cancel-field"
              multiline
              placeholder="Please enter a reason for slot cancellation"
              handleChange={handleInputChange}
              value={slotCancelState.cancelComments}
            />
            {showMakeSlotAvailableCheckbox && slotCancelState.cancelOption !== PANEL_BUSY && (
              <div className="slot-cancel-checkbox-wrapper">
                <CheckBox
                  handleClick={handleCheckBoxClick}
                  label="Make the slot available again"
                  customClass="slot-cancel-checkbox-label"
                />
              </div>
            )}
          </div>
          <Button
            variant="outlined"
            customStyle="slot-cancel-button"
            disabled={disableCancelButton || !endDate.isAfter(moment())}
            onClick={handleClick}>
            Cancel Booking
          </Button>
        </div>
      )}
      <Loader loading={cancelledPopupLoading} customClass="loading-wrapper" />
    </div>
  );
}

export default SlotCancel;
