import jwt_decode from 'jwt-decode';
import { ROLE_ADMIN, ROLE_PANEL, ROLE_RECRUITER } from './constants';
export function encode(data: string) {
  return window.btoa(data);
}

export function decode(data: string) {
  return window.atob(data);
}

export function storeDataInSession(key: string, value: string) {
  window.sessionStorage.setItem(key, value);
}

export function getDataFromSession(key: string) {
  return window.sessionStorage.getItem(key);
}

export function getAccessToken() {
  return decode(getDataFromSession('access-token') || '');
}

export function checkRole(roles: string[]) {
  if (roles.includes(ROLE_PANEL)) {
    return 'panel';
  } else if (roles.includes(ROLE_RECRUITER)) {
    return 'recruiter';
  }
  return 'admin';
}

export function isAdmin(roles: string[]) {
  if (roles.includes(ROLE_ADMIN)) {
    return true;
  }
  return false;
}
