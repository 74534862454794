import React, {
  BaseSyntheticEvent,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import './index.style.scss';
import ClickAwayListener from 'app/core/shared-components/click-away';
import ImageComponent from 'app/core/shared-components/image';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import { IUserList, search } from 'app/redux/slices/settingSlice';
import Typography from 'app/core/shared-components/typography';
import Tag from 'app/core/shared-components/tag';
import Images from 'assets/images';
import Image from 'app/core/shared-components/image';
import { ISelectValue } from 'app/core/shared-components/select';
import Loader from 'app/core/shared-components/loader';
import { AUTOCOMPLETE_SETTINGS } from 'helpers/regex.constants';
import { NO_RESULT_FOUND_ERROR } from 'helpers/messages.constants';

interface ISettingState {
  inputValue: string;
  showDropdown: boolean;
  dropdownOptions: IUserList[];
  inputSelected: ISelectValue[];
  inputStyle: string;
  optionsLoading: boolean;
  noResultFound: boolean;
}

interface IAutocompleteProps {
  value?: ISelectValue[];
  onChange?: (params: ISelectValue[]) => void;
}

function Autocomplete(props: IAutocompleteProps) {
  const [inputState, setInputState] = useState<ISettingState>({
    inputValue: '',
    showDropdown: false,
    inputSelected: [],
    dropdownOptions: [],
    inputStyle: '',
    optionsLoading: false,
    noResultFound: false
  });
  const [cursor, setCursor] = useState(0);
  const [inputValue, setInputValue] = useState(0);
  const reduxState = useAppSelector((state) => state.settings);
  const dispatch = useAppThunkDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const timeOut = useRef<any>();
  const abortSignal = useRef<any>();
  const refOptn = useRef<HTMLDivElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);

  const handleChange = (event: BaseSyntheticEvent) => {
    const value = event.target.value;
    setInputValue(event.target.value.length);
    const regex = new RegExp(AUTOCOMPLETE_SETTINGS);
    if (regex.test(value)) {
      setInputState((prevState) => ({
        ...prevState,
        inputValue: value,
        showDropdown: true,
        optionsLoading: true,
        noResultFound: false
      }));
      if (timeOut.current) {
        clearTimeout(timeOut.current);
      }
      if (value.trim().length) {
        timeOut.current = setTimeout(async () => {
          if (abortSignal.current) abortSignal.current.abort();
          abortSignal.current = new AbortController();
          const data = await dispatch(
            search({
              searchString: value.toLocaleLowerCase(),
              abortSignal: abortSignal.current
            })
          );
          if (!data.payload.error) {
            setInputState((prevState) => ({ ...prevState, optionsLoading: false }));
          } else {
            setInputState((prevState) => ({
              ...prevState,
              optionsLoading: false,
              noResultFound: true
            }));
          }
        }, 1000);
      } else {
        setInputState((prevState) => ({
          ...prevState,
          optionsLoading: false,
          showDropdown: false
        }));
      }
    }
  };

  const handleSelection = (param: IUserList) => {
    const { inputSelected } = inputState;
    const { onChange } = props;
    const available = inputSelected.findIndex(
      (item) => item.id.toString() === param.userId.toString()
    );
    if (available !== -1) {
      inputSelected.splice(available, 1);
      setInputState((prevState) => ({
        ...prevState,
        dropdownOptions: [],
        inputSelected,
        inputValue: '',
        showDropdown: false
      }));
      inputRef?.current?.focus();
      return onChange && onChange(inputSelected);
    }
    const data = {
      id: param.userId.toString(),
      label: `${param.firstName} ${param.lastName}`
    };
    const state = [...inputSelected, data];
    setInputState((prevState) => ({
      ...prevState,
      dropdownOptions: [],
      inputSelected: state,
      inputValue: '',
      showDropdown: false
    }));
    inputRef?.current?.focus();
    setCursor(0);
    setInputValue(0);
    return onChange && onChange(state);
  };

  const handleRemove = (_label?: string, id?: string | null) => {
    const { inputSelected } = inputState;
    const { onChange } = props;
    if (id) {
      const data = inputSelected.findIndex((item) => item.id.toString() === id.toString());
      if (data !== -1) {
        inputSelected.splice(data, 1);
        setInputState((prevState) => ({
          ...prevState,
          inputSelected,
          inputValue: '',
          showDropdown: false
        }));
        inputRef?.current?.focus();
        return onChange && onChange(inputSelected);
      }
    }
  };

  const handleClose = () => {
    setInputState((prevState) => ({ ...prevState, showDropdown: false }));
  };

  const handleFocus = () => {
    setInputState((prevState) => ({ ...prevState, inputStyle: 'autocomplete-shadow' }));
  };

  const handleBlur = () => {
    setInputState((prevState) => ({ ...prevState, inputStyle: '' }));
  };

  const handleKeyUp = useCallback(
    (option: IUserList) => (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === 13) {
        inputValue !== 0 ? handleSelection(option) : e.preventDefault();
      }
    },
    [inputState.dropdownOptions]
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { inputSelected, dropdownOptions } = inputState;
    if (refOptn?.current && dropRef.current) {
      if (e.keyCode === 40) {
        const maxLength = dropdownOptions?.length < 3 ? dropdownOptions?.length : 3;
        if (cursor + 1 < maxLength) {
          setCursor(cursor + 1);
        } else {
          if (dropdownOptions?.length - 1 > cursor) {
            dropRef.current.scrollTop += refOptn?.current.clientHeight;
            setCursor(cursor + 1);
          }
        }
      } else if (e.keyCode === 38) {
        if (cursor - 1 >= 0) {
          dropRef.current.scrollTop -= refOptn?.current.clientHeight;
          setCursor(cursor - 1);
        }
      } else if (e.keyCode === 13 && dropdownOptions[cursor]) {
        inputValue !== 0 ? handleSelection(dropdownOptions[cursor]) : e.preventDefault();
      } else if (e.keyCode === 8 && inputSelected?.length) {
        const user = inputSelected[inputSelected?.length - 1];
        inputValue == 0 && handleRemove(user?.label, user?.id.toString());
      }
    }
  };

  const renderDropdown = () => {
    const { dropdownOptions, noResultFound, optionsLoading } = inputState;

    if (noResultFound) {
      return (
        <Fragment>
          <ImageComponent src={Images.noResult} className="no-results-image" />
          <Typography variant="text" customStyle="no-result-text">
            {NO_RESULT_FOUND_ERROR}
          </Typography>
        </Fragment>
      );
    } else if (optionsLoading) {
      return (
        <Fragment>
          <Loader loading={optionsLoading} customClass="dropdown-loading" />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {dropdownOptions?.map((option, index) => (
            <div
              ref={refOptn}
              key={option.userId}
              className={
                cursor === index ? 'autocomplete-option selectedOption' : 'autocomplete-option'
              }
              tabIndex={0}
              onClick={() => handleSelection(option)}
              onKeyUp={handleKeyUp(option)}>
              <Image
                src={option?.profileImageUrl}
                alt={option.firstName}
                fallbackText={option?.firstName}
                customClass="user-image"
                fallbackClass="user-image"
              />
              <div className="user-detail">
                <Typography customStyle="user-name">{`${option.firstName} ${option.lastName}`}</Typography>
                <Typography customStyle="user-email">{option.emailId}</Typography>
              </div>
            </div>
          ))}
        </Fragment>
      );
    }
  };

  const renderTag = () => {
    const { inputSelected } = inputState;
    return (
      <Fragment>
        {inputSelected.map((value) => {
          return (
            <Tag
              removable
              key={value.id}
              label={value.label}
              dataValue={value.id.toString()}
              onRemove={handleRemove}
              customClass="tag-style"
            />
          );
        })}
      </Fragment>
    );
  };

  const updateDropDownOptions = () => {
    const { options } = reduxState;
    const { inputSelected } = inputState;
    const data = options.filter((option) => {
      const data = inputSelected.find(
        (selection) =>
          selection.id.toString().toLowerCase() === option.userId.toString().toLocaleLowerCase()
      );
      if (data) return false;
      return true;
    });
    setInputState((prevState) => ({ ...prevState, dropdownOptions: [...data] }));
  };
  useEffect(() => {
    const { value } = props;
    if (value) setInputState((prevState) => ({ ...prevState, inputSelected: [...value] }));
  }, [props.value]);

  useEffect(() => {
    updateDropDownOptions();
  }, [reduxState.options]);

  return (
    <ClickAwayListener
      handleClose={handleClose}
      style={inputState.inputSelected.length ? { paddingBlock: '0.5rem' } : {}}
      className={`autocomplete-wrapper ${inputState.inputStyle}`}>
      <div className="autocomplete-field-wrapper">
        <ImageComponent src={Images.email} customClass="email-icon" />
        <div className="autocomplete-tag-wrapper">
          {renderTag()}
          <input
            ref={inputRef}
            value={inputState.inputValue}
            onChange={handleChange}
            className="autocomplete-input"
            placeholder={inputState.inputSelected.length ? '' : 'example@coditas.com'}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      {inputState.showDropdown && (
        <div className="autocomplete-dropdown-wrapper" ref={dropRef}>
          {renderDropdown()}
        </div>
      )}
    </ClickAwayListener>
  );
}

export default Autocomplete;
