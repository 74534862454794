import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { COUNTER } from '../constant';
import { RootState } from '../store';
import {
  getRecruitersSerivce,
  searchRecruiterService,
  getUserInfoService,
  deleteRecruiterService,
  editUserInfoService
} from '../../services/talent-acquisition.service';

interface ITalentState {
  recruiterData: any;
}
const initialState: ITalentState = {
  recruiterData: {
    recruiterDTOList: [],
    totalCount: 1,
    searchOptions: [],
    isToastVisible: false,
    selectedUserData: {},
    saveModaltitle: '',
    saveModalDescription: '',
    formData: {},
    loading: false,
    isError: false
  }
};

export const getSearchedData = createAsyncThunk('search', async (params: any, thunkAPI) => {
  const { searchString, abortSignal } = params;
  const data = await searchRecruiterService(searchString, abortSignal?.signal);
  if (!data.error) {
    return data;
  } else {
    if (data.error[0].code === 'ERR_BAD_REQUEST') {
      return { data: null, error: [{ message: data.error.message, code: data.error.code }] };
    } else if (data?.error[0]?.code === 'ERR_NETWORK') {
      return thunkAPI.rejectWithValue(data);
    } else {
      return data;
    }
  }
});

export const getSelectedUserData = createAsyncThunk(
  'talent/getSelectedUserData',
  async (id: string, thunkAPI) => {
    const value = await getUserInfoService(id);
    if (!value?.error) {
      return value;
    }
    return thunkAPI.rejectWithValue(value);
  }
);

export const getRecruiters = createAsyncThunk(
  'talent/getRecruiters',
  async (props: any, thunkAPI) => {
    const value = await getRecruitersSerivce(props.pgNo, props.filters);
    if (!value?.error) {
      return value.data;
    }
    return thunkAPI.rejectWithValue(value);
  }
);

export const editUserInfo = createAsyncThunk('pane/editPanelInfo', async (undefined, thunkAPI) => {
  const state: any = await thunkAPI.getState();
  const res = await editUserInfoService(state.talentAcquisition.recruiterData.formData);
  if (!res?.error) {
    return res;
  }
  return thunkAPI.rejectWithValue(res);
});

export const talentSlice = createSlice({
  name: COUNTER,
  initialState,
  reducers: {
    sort: (state, action) => {
      state.recruiterData.recruiterDTOList = action.payload;
    },
    setRecruiterList: (state, action) => {
      state.recruiterData.recruiterDTOList = action.payload;
    },
    setLoading: (state, action) => {
      state.recruiterData.loading = action.payload;
    },
    setToast: (state, action) => {
      state.recruiterData.isToastVisible = action.payload;
    },
    setTotalCount: (state, action) => {
      state.recruiterData.totalCount = action.payload;
    },
    generateFormDetails: (state, action) => {
      const { updatedData, initialRole } = action.payload;

      const techStackId = updatedData?.techStack?.map((techStack: any) => techStack?.id);
      const projectId = updatedData?.panelValues?.projects?.map((project: any) => project?.id);
      const isOnBench = !updatedData?.panelValues?.projects?.length;
      const techStackLevel = updatedData?.panelValues?.stackLoi?.map((stack: any) => ({
        techStackId: [stack?.firstValue?.id],
        interviewLevelId: stack?.secondValue?.id
      }));
      const objectToSend = {
        userId: action.payload.userId,
        roleDetails: [
          {
            roleId: 0,
            projectId: [],
            techStackLevel: [
              {
                techStackId: [],
                interviewLevelId: 0
              }
            ]
          }
        ],
        onBench: false
      };
      const recruiterRoleDetails = [
        {
          roleId: 3,
          techStackLevel: [{ techStackId: techStackId, interviewLevelId: 0 }]
        }
      ];
      const panelRoleDetails = [
        {
          roleId: 2,
          projectId: projectId,
          techStackLevel: techStackLevel
        }
      ];
      let dataToSend = {};
      if (updatedData?.techStack && updatedData?.panelValues) {
        const bothData = {
          ...objectToSend,
          roleDetails: [recruiterRoleDetails[0], panelRoleDetails[0]],
          onBench: isOnBench
        };
        dataToSend = bothData;
      } else if (updatedData?.techStack?.length) {
        const recruiterData = { ...objectToSend, roleDetails: recruiterRoleDetails };
        dataToSend = recruiterData;
      } else {
        const updatedData = { ...objectToSend, roleDetails: panelRoleDetails, onBench: isOnBench };
        dataToSend = updatedData;
      }
      state.recruiterData.formData = dataToSend;
    },
    generateModalDetails: (state, actions) => {
      const { title, description } = actions.payload;
      state.recruiterData.saveModaltitle = title;
      state.recruiterData.saveModalDescription = description;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRecruiters.fulfilled, (state, { payload }) => {
      state.recruiterData.recruiterDTOList = payload.recruiterDTOList;
      state.recruiterData.totalCount = payload.totalCount;
    });
    builder.addCase(getRecruiters.rejected, (state, { payload }) => {
      state.recruiterData.isError = true;
    });
    builder.addCase(getSelectedUserData.pending, (state, { payload }: any) => {
      state.recruiterData.selectedUserData = {};
    });
    builder.addCase(getSelectedUserData.fulfilled, (state, { payload }: any) => {
      state.recruiterData.selectedUserData = payload.data;
    });
    builder.addCase(getSelectedUserData.rejected, (state, { payload }: any) => {
      state.recruiterData.isError = true;
    });
    builder.addCase(getSearchedData.pending, (state, { payload }: any) => {
      state.recruiterData.searchOptions = [];
    });
    builder.addCase(getSearchedData.fulfilled, (state, { payload }: any) => {
      state.recruiterData.searchOptions = payload.data;
    });
    builder.addCase(getSearchedData.rejected, (state, { payload }: any) => {
      state.recruiterData.isError = true;
    });
    builder.addCase(editUserInfo.rejected, (state, { payload }) => {
      state.recruiterData.isError = true;
    });
  }
});

export const {
  sort,
  setRecruiterList,
  setLoading,
  generateModalDetails,
  generateFormDetails,
  setTotalCount,
  setToast
} = talentSlice.actions;
export default talentSlice.reducer;
