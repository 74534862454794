import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import ErrorPage from '../app/pages/Error';
import routeError from './routeError';
import Splash from '../app/pages/Splash';
import { useAppSelector } from '../app/redux/hooks';
import * as Sentry from '@sentry/react';
import routes from './routes';

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

interface IRoutes {
  name: string;
  path: string;
  element: JSX.Element;
  children?: IRoutes[];
}

interface IAppRouterState {
  filteredRoutes: any[];
}

const LoginElement = lazy(
  () => new Promise<any>((resolve) => setTimeout(() => resolve(import('../app/pages/Login')), 1000))
);

const protectedRoutes = (routes: IRoutes[]) => {
  return routes.map(({ name, path, element, children }: IRoutes) => {
    if (children) {
      return (
        <Route key={name} path={path} element={<ProtectedRoute component={element} />}>
          {protectedRoutes(children)}
        </Route>
      );
    }
    return <Route key={name} path={path} element={<ProtectedRoute component={element} />} />;
  });
};

function AppRouter() {
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    Sentry.setContext('User', {
      email: user?.userData?.email,
      userID: user?.userData?.userId,
      role: user?.userData?.role?.map((item: any) => item.role).join(', ')
    });
  }, [user]);
  function generateRoutes() {
    const roles = user.userData.role.map((item: any) => item.role);
    return protectedRoutes(routes(roles));
  }

  return (
    <SentryRoutes>
      <Route
        path="/login"
        element={
          <Suspense fallback={<Splash />}>
            <LoginElement />
          </Suspense>
        }
      />
      {generateRoutes()}
      <Route path="*" element={<ErrorPage />} />
      <Route path="authError" element={<ErrorPage page="authError" />} />
    </SentryRoutes>
  );
}
export default routeError(AppRouter);
