import { PROJECT_USERS_LIST, PROJECT_USERS_SEARCH_LIST } from '../../helpers/apiRoutes';
import service from './index.service';
const baseURL = process.env.REACT_APP_API_URL;

export async function projectService(sortingOrder: string) {
  try {
    const response = await service.get(`/user/project/list?sortBy=${sortingOrder}`);
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}
export async function projectEdit(id: string, projectName: string) {
  try {
    const response = await service.put(
      `/admin/project/edit?id=${id}&name=${encodeURIComponent(projectName)}`
    );
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function addProject(projectName: string) {
  try {
    const response = await service.post(
      `/admin/project/add?name=${encodeURIComponent(projectName)}`
    );
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}
export async function deleteProject(id: number) {
  try {
    const response = await service.delete(`/admin/project/delete?id=${id}`);
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}
export async function searchProject(searchText: string, signal: any) {
  try {
    const response = await service.get(`admin/project/search?searchText=${searchText}`, {
      signal
    });
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}
export async function userListProject(id: string) {
  try {
    const response = await service.get(`${PROJECT_USERS_LIST}?projectId=${id}`);
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}
export async function userListSearchProject(id: string, searchText: string) {
  try {
    const response = await service.get(
      `${PROJECT_USERS_SEARCH_LIST}?searchText=${searchText}&projectId=${id}`
    );
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}
