import React, { BaseSyntheticEvent, Fragment, useCallback, useEffect, useState } from 'react';
import './index.style.scss';
import { useAppThunkDispatch, useAppSelector } from 'app/redux/hooks';
import CheckBox from 'app/core/shared-components/checkbox';
import Button from 'app/core/shared-components/button';
import {
  ADMIN_FILTER_RESET_DISABLED,
  ADMIN_FILTER_RESET_BUTTON,
  ADMIN_FILTER_APPLY_DISABLED,
  ADMIN_FILTER_APPLY_BUTTON,
  RECRUITER_FILTER_LENGTH,
  SEARCH_TECH_STACK,
  TECH_STACK_FILTER,
  FILTER_BY
} from 'helpers/constants';
import { getTechStack } from 'app/redux/slices/sharedSlice';
import { FILTER, APPLY, RESET } from 'helpers/constants';
import _ from 'lodash';
import { handleTabEventWithValue } from 'helpers/utils';
import InputBox from 'app/core/shared-components/input-box';
import Images from 'assets/images';
import { NO_RESULT_FOUND_ERROR } from 'helpers/messages.constants';
import Typography from 'app/core/shared-components/typography';
import ImageComponent from 'app/core/shared-components/image';

interface IFiltersProps {
  getFilters: (param: string[]) => void;
  appliedFilters: string[];
  onClose: () => void;
  getFilterCount: (param: number) => void;
  isOpen?: boolean;
}

interface ITechStackState {
  searchValue: string;
  isSearchedOptionSelected: boolean;
  isSearched: boolean;
  searchData: ITechStackType[];
}
export interface ITechStackType {
  addedOn: string;
  id: string;
  imageUrl: string;
  isZohoRecord: boolean;
  noOfUsers: number;
  stack: string;
}
interface IFiltersStates {
  localFilters: string[];
  singleCheckCount: number;
  filtersApplied: boolean;
  isApplyEnabled: boolean;
}

function Filters({
  getFilters,
  appliedFilters,
  onClose,
  getFilterCount,
  isOpen = false
}: IFiltersProps) {
  const [filtersState, setFiltersState] = useState<IFiltersStates>({
    localFilters: [],
    singleCheckCount: 0,
    filtersApplied: false,
    isApplyEnabled: false
  });
  const [isItemSelected, setIsItemSelected] = useState('');
  const [techStackState, setTechStackState] = useState<ITechStackState>({
    isSearchedOptionSelected: false,
    searchData: [],
    isSearched: false,
    searchValue: ''
  });
  const filtersList = useAppSelector((state) => state.shared.techStack);
  const dispatch = useAppThunkDispatch();
  const filterOptions = [TECH_STACK_FILTER];
  let { searchData, searchValue, isSearched } = techStackState;
  const { search, close, noResult } = Images;

  function handlefilterCheck(param: string) {
    let temp = filtersState.localFilters;
    let foundIndex = temp.findIndex((item) => {
      return item == param;
    });
    if (foundIndex !== -1) {
      temp.splice(foundIndex, 1);
      setFiltersState((prevState) => ({
        ...prevState,
        singleCheckCount: filtersState.singleCheckCount - 1
      }));
    } else {
      temp.push(param?.toString());
      setFiltersState((prevState) => ({
        ...prevState,
        singleCheckCount: filtersState.singleCheckCount + 1
      }));
    }
    setFiltersState((prevState) => ({ ...prevState, localFilters: [...temp] }));
  }

  function apply() {
    getFilters([...filtersState.localFilters]);
    getFilterCount(filtersState.singleCheckCount);
    onClose();
  }

  function reset() {
    setFiltersState((prevState) => ({ ...prevState, localFilters: [], singleCheckCount: 0 }));
  }
  const applyFilter = () => {
    !filtersState.isApplyEnabled ? apply() : () => {};
  };

  const handleDummyFunc = () => {};

  const handleFilter = (item: string) => (event: BaseSyntheticEvent) => {
    event.stopPropagation();
    handlefilterCheck(item);
  };

  const handleFilterByChange = (item: string) => {
    setIsItemSelected(item);
    handleSearchAction('');
  };

  async function getFiltersList() {
    const data = await dispatch(getTechStack()).unwrap();
  }

  const getSearchResults = (searchValue: string) => {
    return filtersList.filter(
      (techItem) => filtersList && techItem.stack.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  const handleSearchAction = useCallback(
    (searchValue: string | number) => {
      const searchString = searchValue.toString();

      if (searchString.trim().length) {
        const data = getSearchResults(searchString);
        setTechStackState((prevState) => ({
          ...prevState,
          isSearched: true,
          searchData: data,
          searchValue: searchString
        }));
      } else {
        setTechStackState((prevState) => ({
          ...prevState,
          isSearched: false,
          searchData: [],
          searchValue: ''
        }));
      }
    },
    [searchData]
  );

  const clearInput = () => {
    handleSearchAction('');
  };

  const filterData = () => {
    const techData: ITechStackType[] = isSearched ? searchData : filtersList;
    return (
      <Fragment>
        {isSearched && !techData.length ? (
          <div className="no-result-tech-stack">
            <ImageComponent src={noResult} className="no-results-image" />
            <Typography variant="text" customStyle="name">
              {NO_RESULT_FOUND_ERROR}
            </Typography>
          </div>
        ) : (
          <div className={'ap-filter-modal-list-grid-container-recruiter'}>
            {techData?.map((item) => {
              return (
                <div
                  onClick={handleFilter(item.id)}
                  className="ta-filter-option"
                  key={item?.id}
                  tabIndex={0}
                  onKeyDown={handleTabEventWithValue(item?.id, handlefilterCheck)}>
                  <CheckBox checked={filtersState.localFilters.includes(item?.id.toString())} />
                  <div className="ta-filter-option-name">{item?.stack}</div>
                  {item?.stack?.length > RECRUITER_FILTER_LENGTH && (
                    <div className="ts-tooltip">
                      {item?.stack}
                      <span className="ts-tooltip-arrow" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </Fragment>
    );
  };

  const updateCheckCount = () => {
    getFiltersList();
    setFiltersState((prevState) => ({
      ...prevState,
      localFilters: [...appliedFilters],
      singleCheckCount: appliedFilters.length
    }));
  };

  useEffect(() => {
    setIsItemSelected(TECH_STACK_FILTER);
  }, []);

  useEffect(() => {
    updateCheckCount();
  }, []);

  useEffect(() => {
    const isEnabled = _.isEqual(appliedFilters, filtersState.localFilters);
    setFiltersState((prevState) => ({ ...prevState, isApplyEnabled: isEnabled }));
  }, [filtersState.localFilters]);

  return (
    <div className="admin-panel-list-filters-modal admin-panel-filter-backdrop">
      <div className="filter-modal-outer">
        <div className="ap-filter-header">
          <div className="admin-modal-filter-header">{FILTER_BY}</div>
          <div onClick={onClose} className="ap-filter-close">
            <img src={close} alt="" />
          </div>
        </div>
        <div className="parent-filter-container">
          <div className="side-bar-title">
            {filterOptions.map((item: string, index: number) => {
              return (
                <div
                  key={index}
                  className={isItemSelected === item ? 'item-name item-name-active' : 'item-name'}
                  onClick={() => handleFilterByChange(item)}>
                  {item}
                </div>
              );
            })}
          </div>
          <div className="right-side">
            <div className="search-bar">
              <InputBox
                startIcon={search}
                endIcon={searchValue ? close : ''}
                placeholder={SEARCH_TECH_STACK}
                value={searchValue}
                handleChange={handleSearchAction}
                onEndIconClick={clearInput}
                customClass="input-bar"
              />
            </div>
            {filterData()}
          </div>
        </div>
        <div className="btnContainer">
          <Button
            onClick={reset}
            variant="outlined"
            size="medium"
            customStyle={
              filtersState.singleCheckCount === 0
                ? ADMIN_FILTER_RESET_DISABLED
                : ADMIN_FILTER_RESET_BUTTON
            }>
            Reset
          </Button>
          <Button
            size="medium"
            customStyle={
              !filtersState.isApplyEnabled ? ADMIN_FILTER_APPLY_BUTTON : ADMIN_FILTER_APPLY_DISABLED
            }
            onClick={applyFilter}>
            {APPLY}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Filters;
