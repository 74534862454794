import React, { Fragment } from 'react';

export interface ITechStackInterviewLevelDtos {
  techStackId: number;
  techStack: string;
  levelId: number;
  level: string;
  roleId: number;
}
interface ITechStackDTO {
  techStackInterviewLevelDtos: ITechStackInterviewLevelDtos[];
}

const TATechStacks = ({ techStackInterviewLevelDtos }: ITechStackDTO) => (
  <Fragment>
    {techStackInterviewLevelDtos?.map((techStackInterviewLevel, index) => {
      return (
        <div className="techStackNames" key={index}>
          {techStackInterviewLevel?.techStack}
        </div>
      );
    })}
  </Fragment>
);
export default TATechStacks;
