import React, { useState, useEffect } from 'react';
import SideNav from '../app/core/shared-components/sidenav';
import { Outlet } from 'react-router-dom';
import withRouter from './withRouter';
import '../App.style.scss';
import BottomNavigation from '../app/core/shared-components/bottom-navigation';
import Images from '../assets/images';
import { useAppSelector } from '../app/redux/hooks';
import { ROLE_ADMIN, ROLE_PANEL, ROLE_RECRUITER } from '../helpers/constants';

interface IsideNavItem {
  icon: string;
  title: string;
  path: string;
  roles: Array<string>;
}

const sideNavItems = [
  {
    icon: `${Images.dashboard}`,
    title: 'Dashboard',
    path: 'home',
    roles: [ROLE_ADMIN, ROLE_PANEL, ROLE_RECRUITER]
  },
  {
    icon: `${Images.slots}`,
    title: 'Slots',
    path: 'slots',
    roles: [ROLE_RECRUITER]
  },
  {
    icon: `${Images.slots}`,
    title: 'Schedule',
    path: 'schedule',
    roles: [ROLE_PANEL]
  },
  {
    icon: `${Images.approvals}`,
    title: 'Onboarding Users',
    path: 'onboard',
    roles: [ROLE_ADMIN]
  },
  {
    icon: `${Images.panel}`,
    title: 'Manage Users',
    path: 'manageUsers',
    roles: [ROLE_ADMIN]
  },
  {
    icon: `${Images.techStack}`,
    title: 'Tech Stacks',
    path: 'techStacks',
    roles: [ROLE_ADMIN]
  },
  {
    icon: `${Images.levels}`,
    title: 'Level of Panel',
    path: 'levels',
    roles: [ROLE_ADMIN]
  },
  {
    icon: `${Images.projects}`,
    title: 'Projects',
    path: 'projects',
    roles: [ROLE_ADMIN]
  },
  {
    icon: `${Images.settings}`,
    title: 'Settings',
    path: 'settings',
    roles: [ROLE_ADMIN, ROLE_PANEL, ROLE_RECRUITER]
  }
];

function LayoutRoute() {
  const [sideNavItemState, setSideNaveItemState] = useState<any>([]);
  const [bottomNavItems, setBottomNavItems] = useState<any>([]);

  const role: any = useAppSelector((state) =>
    state.user.userData.role.map((item: any) => item.role)
  );

  useEffect(() => {
    let temp: any = [];
    sideNavItems.forEach((sideNavItem: IsideNavItem) => {
      role.forEach((roleItem: any) => {
        if (sideNavItem.roles.includes(roleItem) && !temp.includes(sideNavItem)) {
          temp.push(sideNavItem);
        }
      });
    });
    setSideNaveItemState([...temp]);
    sideNavItems.forEach((sideNavItem: IsideNavItem) => {
      role.forEach((roleItem: any) => {
        if (sideNavItem.roles.includes(roleItem) && !temp.includes(sideNavItem)) {
          temp.push(sideNavItem);
        }
      });
    });
    temp = temp.filter((item: IsideNavItem) => item.title !== 'Manage Users');
    if (role.includes(ROLE_ADMIN)) {
      temp.push(
        {
          icon: `${Images.panel}`,
          title: 'Panel',
          path: 'panel',
          roles: [ROLE_ADMIN]
        },
        {
          icon: `${Images.person}`,
          title: 'Talent Acquisition',
          path: 'talentAcquisition',
          roles: [ROLE_ADMIN]
        }
      );
    }
    setBottomNavItems([...temp]);
  }, [role.length]);

  return (
    <div className="layout">
      <div className="side-navigation">
        <SideNav items={sideNavItemState} />
      </div>
      <div className="container">
        <Outlet />
      </div>
      <div className="bottom-navigation-wrapper">
        <BottomNavigation items={bottomNavItems} />
      </div>
    </div>
  );
}

export default withRouter(LayoutRoute);
