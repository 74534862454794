import React, { useEffect, useMemo, useRef } from 'react';
import { components } from 'react-select';
import CheckBox from 'app/core/shared-components/checkbox';

const Option = ({ children, isSelected, value, ...props }: any) => {
  const autoScrollRef = useRef<HTMLDivElement>(null);
  const { autoScrollToId = '', isMulti } = props.selectProps;
  const isAutoScrollToId = useMemo(() => autoScrollToId === value, [autoScrollToId]);

  const handleSelectOption =
    ({ selectOption, data }: any) =>
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      selectOption(data);
    };

  useEffect(() => {
    const elementToScroll = document.getElementById('auto-scroll');

    elementToScroll
      ? elementToScroll.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      : autoScrollRef &&
        autoScrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, []);

  return (
    <components.Option {...props}>
      {isMulti ? (
        <div className="option-container" onClick={handleSelectOption(props)}>
          <CheckBox checked={isSelected} handleClick={handleSelectOption(props)} tabIndex={true} />
          {children}
        </div>
      ) : (
        <div
          tabIndex={0}
          className={`option-container ${isSelected ? 'selected' : null}`}
          id={isSelected ? 'auto-scroll' : ''}
          ref={isAutoScrollToId ? autoScrollRef : null}
          onClick={handleSelectOption(props)}>
          {children}
        </div>
      )}
    </components.Option>
  );
};

export default Option;
