import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { View, Views } from 'react-big-calendar';
import { Event } from 'react-big-calendar';
import moment, { Moment } from 'moment';
import { SCHEDULE } from 'app/redux/constant';
import {
  addSlots,
  deleteSlot,
  editSlot,
  fetchPanelDetail,
  fetchSlotDetails,
  fetchUserSchedule,
  ISlots,
  searchPanel as getPanelList,
  slotCancel,
  slotRequest,
  slotRequestAcceptance,
  slotRequestDecline
} from 'app/services/schedule.service';

import { ISlotDetails } from 'app/pages/Schedule';
import { IProjectType } from 'app/services/user-response.types';
import { ISelectedSearch } from './slotsSlice';
import { EVENT_TYPES, SCHEDULE_EVENTS_TYPE } from 'helpers/types';
import { ISelectorOption, ISelectorValue } from 'app/core/shared-components/selector';
import { CANCELLATION_OPTION_TYPE, MY_SCHEDULE, SCHEDULE_STATUS } from 'helpers/constants';

export type IEventType = 'BOOKED' | 'AVAILABLE' | 'EXISTING' | 'ADD' | 'EDIT' | string;

export interface IEvents extends Omit<Event, 'start' | 'end'> {
  start: Moment;
  end: Moment;
  type: EVENT_TYPES;
  id: string | number;
}

interface ISearchPanel {
  searchText: string;
  abortSignal: AbortSignal;
}

interface ISlotNotificationDto {
  slotRequestId: string;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  date: string;
  fromTime: string;
  toTime: string;
  techStack: string;
  level: string;
}

interface ISlotRequestsData {
  slotRequestNotificationDto: ISlotNotificationDto[];
  slotRequestCount: number;
}

type IStatus = 'APPROVED' | 'IN_REVIEW' | 'ARCHIVE';

export interface ICurrentSelectedUser {
  id: string | number;
  label: string;
  imageSource: string | null;
  email: string;
  designation: string;
  projectNameList: string[] | null;
  slots: ISlots[];
  techStackInterviewLevelDtoList: { techStack: string; level: string }[] | null;
  maxInterviewsDaily: string | number | null;
  maxInterviewsWeekly: string | number | null;
  status: IStatus;
}

interface IScheduleStates {
  searchOptions: ISelectorOption[];
  searchLoading: boolean;
  searchError: boolean;
  currentUser: ISelectorValue | null;
  currentSelectedUser: ICurrentSelectedUser | null;
  calendarEvents: IEvents[];
  pageLoading: boolean;
  eventsLoading: boolean;
  pageError: boolean;
  currentView: View;
  currentDate: Date;
  slotRequests: ISlotRequest[];
  slotRequestLoading: boolean;
  pendingSlotRequestAcceptance: ISlotRequest[];
  pendingSlotRequestDeletion: ISlotRequest[];
  pendingSlotDeletion: IEvents[];
  availableEvents: IEvents[];
  bookedEvents: IEvents[];
  cancelledEvents: IEvents[];
  existingEvents: IEvents[];
  requestedEvents: IEvents[];
  cancelledPopupLoading: boolean;
}

export const searchPanel = createAsyncThunk(
  `${SCHEDULE}/search`,
  async (param: ISearchPanel, thunkAPI) => {
    const { searchText, abortSignal } = param;
    const response = await getPanelList(searchText, abortSignal);
    if (!response.error) {
      return response.data;
    }
    return thunkAPI.rejectWithValue(response.error);
  }
);

export const getPanelSchedule = createAsyncThunk(
  `${SCHEDULE}/get-schedule`,
  async (userId: string | number | undefined, thunkAPI) => {
    const reduxState: any = thunkAPI.getState();
    const { schedule } = reduxState;
    const data = userId ? userId : schedule?.currentSelectedUser?.id;
    const response = await fetchUserSchedule(data);
    if (!response.data) {
      return thunkAPI.rejectWithValue(response);
    }
    return response;
  }
);

export const addPanelSlot = createAsyncThunk(
  `${SCHEDULE}/add-panel-slot`,
  async (param: ISlots[], thunkAPI) => {
    const reduxState: any = thunkAPI.getState();
    const { schedule } = reduxState;
    const response = await addSlots({ userId: schedule.currentSelectedUser.id, slots: param });
    if (response.data) {
      await thunkAPI.dispatch(getPanelSchedule());
      return response;
    } else if (response.error) {
      const { error } = response;
      if (error[0]?.error[0]?.code === 400) return response;
      else thunkAPI.rejectWithValue(response);
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const editPanelSlot = createAsyncThunk(
  `${SCHEDULE}/edit-panel-slot`,
  async (param: ISlots[], thunkAPI) => {
    const reduxState: any = thunkAPI.getState();
    const { schedule } = reduxState;
    const response = await editSlot({ userId: schedule.currentSelectedUser.id, slots: param });
    if (response.data) {
      await thunkAPI.dispatch(getPanelSchedule());
      return response;
    } else if (response.error) {
      const { error } = response;
      if (error[0]?.error[0]?.code === 400) return response;
      else thunkAPI.rejectWithValue(response);
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const getSlotRequest = createAsyncThunk(
  `${SCHEDULE}/fetch-slot-request`,
  async (_undefined, thunkAPI) => {
    const response = await slotRequest();
    if (!response.error) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const slotRequestAccept = createAsyncThunk(
  `${SCHEDULE}/slot-request-accept`,
  async (slotId: string | number, thunkAPI) => {
    const response = await slotRequestAcceptance(slotId);
    if (!response.error) {
      await thunkAPI.dispatch(getSlotRequest());
      response.data = slotId;
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const slotDeclineRequest = createAsyncThunk(
  `${SCHEDULE}/slot-request-decline`,
  async (slotId: string | number, thunkAPI) => {
    const response = await slotRequestDecline(slotId);
    if (!response.error) {
      await thunkAPI.dispatch(getSlotRequest());
      response.data = slotId;
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const slotDelete = createAsyncThunk(
  `${SCHEDULE}/delete-available-slot`,
  async (slotId: string | number, thunkAPI) => {
    const reduxState: any = thunkAPI.getState();
    const { schedule } = reduxState;
    const data = schedule.pendingSlotDeletion.find(
      (slot: any) => slot.id.toString() === slotId.toString()
    );
    if (data) {
      const response = await deleteSlot(data.id);
      response.data = data;
      if (!response.error) {
        await thunkAPI.dispatch(getPanelSchedule());
        return response;
      }
      return thunkAPI.rejectWithValue(response);
    }
  }
);

export const switchUser = createAsyncThunk(
  `${SCHEDULE}/switch-user`,
  async (userId: string | number, thunkAPI) => {
    const response = await fetchPanelDetail(userId);
    if (!response.error) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

interface ICancelSlot {
  slotId: number | string;
  cancelComments: string;
  makeSlotAvailable: boolean;
  cancelOption: CANCELLATION_OPTION_TYPE;
}

export const cancelBookedSlot = createAsyncThunk(
  `${SCHEDULE}/cancel-booked-slot`,
  async (params: ICancelSlot, thunkAPI) => {
    const reduxState: any = thunkAPI.getState();
    const { schedule } = reduxState;
    const { currentSelectedUser } = schedule;
    const data = Object.assign(params, {
      userId: currentSelectedUser.id
    });
    const response = await slotCancel(data);
    if (!response.error) {
      const scheduleResponse = await thunkAPI.dispatch(getPanelSchedule());
      if (!scheduleResponse.payload.data) {
        return thunkAPI.rejectWithValue(response);
      }
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const getSlotInfo = createAsyncThunk(
  `${SCHEDULE}/get-slot-info`,
  async (slotId: string | number, thunkAPI) => {
    const response = await fetchSlotDetails(slotId);
    if (!response.error) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export interface ISlotRequest {
  slotRequestId: number | string;
  firstName: string;
  lastName: string;
  profileImageUrl: string | null;
  date: string;
  fromTime: Date | string;
  toTime: Date | string;
  techStack: string;
  level: string;
}

const initialState: IScheduleStates = {
  searchOptions: [],
  searchLoading: false,
  searchError: false,
  calendarEvents: [],
  currentUser: null,
  currentSelectedUser: null,
  pageLoading: false,
  eventsLoading: false,
  pageError: false,
  currentView: Views.WEEK,
  currentDate: new Date(),
  pendingSlotRequestAcceptance: [],
  pendingSlotRequestDeletion: [],
  slotRequests: [],
  slotRequestLoading: false,
  pendingSlotDeletion: [],
  availableEvents: [],
  bookedEvents: [],
  existingEvents: [],
  cancelledEvents: [],
  requestedEvents: [],
  cancelledPopupLoading: false
};

interface IPendingSlotRequest {
  slotRequestId: string | number;
  type: 'ACCEPT' | 'DECLINE';
}

export const scheduleSlice = createSlice({
  name: SCHEDULE,
  initialState,
  reducers: {
    showErrorScreen: (state) => {
      state.eventsLoading = false;
      state.pageError = true;
    },
    switchToCurrentUser: (state, action: PayloadAction<ICurrentSelectedUser>) => {
      state.currentSelectedUser = action.payload;
    },
    resetPage: (state) => {
      state.availableEvents = [];
      state.bookedEvents = [];
      state.calendarEvents = [];
      state.cancelledEvents = [];
      state.cancelledPopupLoading = false;
      state.currentDate = new Date();
      state.currentSelectedUser = null;
      state.currentUser = null;
      state.currentView = Views.WEEK;
      state.eventsLoading = false;
      state.existingEvents = [];
      state.pageError = false;
      state.searchError = false;
      state.searchLoading = false;
      state.searchOptions = [];
      state.slotRequestLoading = false;
      state.slotRequests = [];
      state.pageLoading = false;
      state.requestedEvents = [];
    },
    setUser: (state, action: PayloadAction<ISelectorValue | null>) => {
      state.currentUser = action.payload;
    },
    resetSearch: (state) => {
      state.searchLoading = false;
      state.searchError = false;
      state.searchOptions = [];
    },
    changeView: (state, action: PayloadAction<View>) => {
      state.currentView = action.payload;
    },
    changeCurrentDate: (state, action: PayloadAction<Date>) => {
      state.currentDate = action.payload;
    },
    removeSlotRequest: (state, action: PayloadAction<IPendingSlotRequest>) => {
      const data = state.slotRequests.findIndex(
        (slot) => slot.slotRequestId.toString() === action.payload.slotRequestId.toString()
      );
      if (data !== -1) {
        const deletedData = state.slotRequests.splice(data, 1);
        if (action.payload.type.toUpperCase() === 'ACCEPT')
          state.pendingSlotRequestAcceptance =
            state.pendingSlotRequestAcceptance.concat(deletedData);
        else if (action.payload.type.toUpperCase() === 'DECLINE')
          state.pendingSlotRequestDeletion = state.pendingSlotRequestDeletion.concat(deletedData);
      }
    },
    addSlotRequest: (state, action: PayloadAction<IPendingSlotRequest>) => {
      if (action.payload.type.toUpperCase() === 'ACCEPT') {
        const data = state.pendingSlotRequestAcceptance.findIndex(
          (slot) => slot.slotRequestId.toString() === action.payload.slotRequestId.toString()
        );
        if (data !== -1) {
          state.slotRequests.push(state.pendingSlotRequestAcceptance[data]);
          state.pendingSlotRequestAcceptance.splice(data, 1);
        }
      } else if (action.payload.type.toUpperCase() === 'DECLINE') {
        const data = state.pendingSlotRequestDeletion.findIndex(
          (slot) => slot.slotRequestId.toString() === action.payload.slotRequestId.toString()
        );
        if (data !== -1) {
          state.slotRequests.push(state.pendingSlotRequestDeletion[data]);
          state.pendingSlotRequestDeletion.splice(data, 1);
        }
      }
    },
    removeEvent: (state, action: PayloadAction<string | number>) => {
      const data = state.availableEvents.findIndex(
        (event) => event.id.toString() === action.payload.toString()
      );
      if (data !== -1) {
        const deletedData = state.availableEvents.splice(data, 1);
        state.pendingSlotDeletion = state.pendingSlotDeletion.concat(deletedData);
        state.calendarEvents = state.availableEvents.concat(
          state.bookedEvents,
          state.cancelledEvents,
          state.existingEvents,
          state.requestedEvents
        );
      }
    },
    reAddEvent: (state, action: PayloadAction<string | number>) => {
      const data = state.pendingSlotDeletion.findIndex(
        (slot) => slot.id.toString() === action.payload.toString()
      );
      if (data !== -1) {
        state.availableEvents.push(state.pendingSlotDeletion[data]);
        state.pendingSlotDeletion.splice(data, 1);
        state.calendarEvents = state.availableEvents.concat(
          state.bookedEvents,
          state.cancelledEvents,
          state.existingEvents,
          state.requestedEvents
        );
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(switchUser.pending, (state) => {
      state.currentSelectedUser = null;
      state.currentDate = new Date();
      state.currentView = Views.WEEK;
      state.existingEvents = [];
      state.availableEvents = [];
      state.bookedEvents = [];
      state.calendarEvents = [];
      state.cancelledEvents = [];
      state.requestedEvents = [];
      state.eventsLoading = true;
      state.pageError = false;
    });
    builder.addCase(switchUser.fulfilled, (state, action) => {
      const label =
        state.currentUser?.id.toString() === action.payload.data.id.toString()
          ? MY_SCHEDULE
          : `${action.payload.data.firstName} ${action.payload.data.lastName}`;
      state.currentSelectedUser = {
        id: action.payload.data.id,
        label: label,
        imageSource: action.payload.data.profileImageUrl,
        ...action.payload.data
      };
      state.eventsLoading = false;
    });
    builder.addCase(switchUser.rejected, (state) => {
      state.eventsLoading = false;
      state.pageError = true;
    });
    builder.addCase(editPanelSlot.pending, (state) => {
      state.eventsLoading = true;
      state.pageError = false;
    });
    builder.addCase(editPanelSlot.fulfilled, (state) => {
      state.eventsLoading = false;
    });
    builder.addCase(editPanelSlot.rejected, (state) => {
      state.eventsLoading = false;
      state.pageError = true;
    });
    builder.addCase(addPanelSlot.pending, (state) => {
      state.eventsLoading = true;
      state.pageError = false;
    });
    builder.addCase(addPanelSlot.fulfilled, (state) => {
      state.eventsLoading = false;
    });
    builder.addCase(addPanelSlot.rejected, (state) => {
      state.eventsLoading = false;
      state.pageError = true;
    });
    builder.addCase(getSlotInfo.pending, (state) => {
      state.cancelledPopupLoading = true;
    });
    builder.addCase(getSlotInfo.fulfilled, (state) => {
      state.cancelledPopupLoading = false;
    });
    builder.addCase(getSlotInfo.rejected, (state) => {
      state.cancelledPopupLoading = false;
      state.pageError = true;
    });
    builder.addCase(cancelBookedSlot.pending, (state) => {
      state.eventsLoading = true;
      state.pageError = false;
    });
    builder.addCase(cancelBookedSlot.fulfilled, (state) => {
      state.eventsLoading = false;
    });
    builder.addCase(cancelBookedSlot.rejected, (state) => {
      state.eventsLoading = false;
      state.pageError = true;
    });
    builder.addCase(searchPanel.pending, (state) => {
      state.searchLoading = true;
      state.searchError = false;
    });
    builder.addCase(searchPanel.fulfilled, (state, action) => {
      const data: ISelectedSearch[] = action.payload.filter(
        (item: ISelectedSearch) => !(state.currentUser?.id.toString() === item.id.toString())
      );
      const searchOptions = data.map((item) => {
        return {
          id: item?.id,
          label: `${item?.firstName} ${item?.lastName}`,
          imageSource: item?.profileImageUrl,
          secondaryLabel: item?.designation
        };
      });
      if (!searchOptions.length) state.searchError = true;
      state.searchOptions = searchOptions;
      state.searchLoading = false;
    });
    builder.addCase(searchPanel.rejected, (state) => {
      state.searchLoading = false;
      state.searchError = true;
    });
    builder.addCase(getPanelSchedule.pending, (state) => {
      state.calendarEvents = [];
      state.availableEvents = [];
      state.bookedEvents = [];
      state.existingEvents = [];
      state.cancelledEvents = [];
      state.requestedEvents = [];
      state.eventsLoading = true;
      state.pageError = false;
    });
    builder.addCase(getPanelSchedule.fulfilled, (state, action) => {
      const { data } = action.payload;
      const events = Object.values(data.dateMap).flatMap((event) => event) as ISlotDetails[];
      const availableEvents: IEvents[] = [];
      const requestedEvents: IEvents[] = [];
      const bookedEvents: IEvents[] = [];
      const cancelledEvents: IEvents[] = [];
      const googleEvents: IEvents[] = [];
      events.forEach((item, index) => {
        if (item.status?.toString() === SCHEDULE_STATUS.AVAILABLE.toString()) {
          if (item.isRequestedSlot) {
            const data = {
              id: item.slotId,
              start: moment(item.from),
              end: moment(item.to),
              title: 'Requested Slot',
              type: SCHEDULE_EVENTS_TYPE.REQUESTED,
              allDay: false
            };
            requestedEvents.push(data);
          } else {
            const inPendingDeletionQueue = state.pendingSlotDeletion.some(
              (arg) => arg.id.toString() === item.slotId.toString()
            );
            const data = {
              id: item.slotId,
              start: moment(item.from),
              end: moment(item.to),
              title: 'Available Slot',
              type: SCHEDULE_EVENTS_TYPE.AVAILABLE,
              allDay: false
            };
            inPendingDeletionQueue ? undefined : availableEvents.push(data);
          }
        } else if (item.status?.toString() === SCHEDULE_STATUS.BOOKED.toString()) {
          const data = {
            start: moment(item.from),
            end: moment(item.to),
            title: item.techStack?.map((tech) => tech.techStackName).join(', '),
            type: SCHEDULE_EVENTS_TYPE.BOOKED,
            allDay: false,
            id: item.slotId
          };
          bookedEvents.push(data);
        } else if (item.status?.toString() === SCHEDULE_STATUS.CANCELLED.toString()) {
          const data = {
            start: moment(item.from),
            end: moment(item.to),
            title: item.techStack?.map((tech) => tech.techStackName).join(', '),
            type: SCHEDULE_EVENTS_TYPE.CANCELLED,
            allDay: false,
            id: item.slotId
          };
          cancelledEvents.push(data);
        } else if (item.isCalendarEvent) {
          const data = {
            id: index,
            start: item.isAllDayEvent
              ? moment(item.from).set({ hour: 0, minute: 0 })
              : moment(item.from),
            end: item.isAllDayEvent
              ? moment(item.to).set({ hour: 23, minute: 59 })
              : moment(item.to),
            title: item.eventSummary,
            type: SCHEDULE_EVENTS_TYPE.EXISTING,
            allDay: !!item.isAllDayEvent
          };
          googleEvents.push(data);
        }
      });

      state.availableEvents = availableEvents;
      state.bookedEvents = bookedEvents;
      state.cancelledEvents = cancelledEvents;
      state.existingEvents = googleEvents;
      state.requestedEvents = requestedEvents;
      state.calendarEvents = availableEvents.concat(
        bookedEvents,
        cancelledEvents,
        googleEvents,
        requestedEvents
      );
      state.eventsLoading = false;
    });
    builder.addCase(getPanelSchedule.rejected, (state) => {
      state.eventsLoading = false;
      state.pageError = true;
    });
    builder.addCase(getSlotRequest.fulfilled, (state, action) => {
      const { payload } = action;
      const filterSlots = payload.data.slotRequestNotificationDto.filter(
        (item: ISlotNotificationDto) => {
          const inPendingAcceptance = state.pendingSlotRequestAcceptance.some(
            (request) => item.slotRequestId.toString() === request.slotRequestId.toString()
          );
          const inPendingDeletionQueue = state.pendingSlotRequestDeletion.some(
            (request) => item.slotRequestId.toString() === request.slotRequestId.toString()
          );
          return !(inPendingAcceptance || inPendingDeletionQueue);
        }
      );
      state.slotRequests = filterSlots;
    });
    builder.addCase(getSlotRequest.rejected, (state) => {
      state.slotRequestLoading = false;
      state.pageError = true;
    });
    builder.addCase(slotRequestAccept.pending, (state) => {
      state.eventsLoading = true;
      state.slotRequestLoading = true;
      state.pageError = false;
    });
    builder.addCase(slotRequestAccept.fulfilled, (state, action) => {
      state.eventsLoading = false;
      state.slotRequestLoading = false;
      state.pendingSlotRequestAcceptance = state.pendingSlotRequestAcceptance.filter(
        (slotRequestAccept) =>
          slotRequestAccept.slotRequestId.toString() !== action.payload.toString()
      );
    });
    builder.addCase(slotRequestAccept.rejected, (state) => {
      state.eventsLoading = false;
      state.slotRequestLoading = false;
      state.pageError = true;
    });
    builder.addCase(slotDeclineRequest.pending, (state) => {
      state.slotRequestLoading = true;
      state.pageError = false;
    });
    builder.addCase(slotDeclineRequest.fulfilled, (state, action) => {
      state.slotRequestLoading = false;
      state.pendingSlotRequestDeletion = state.pendingSlotRequestDeletion.filter(
        (slotRequestAccept) =>
          slotRequestAccept.slotRequestId.toString() !== action.payload.toString()
      );
    });
    builder.addCase(slotDeclineRequest.rejected, (state) => {
      state.slotRequestLoading = false;
      state.pageError = true;
    });
    builder.addCase(slotDelete.fulfilled, (state, action) => {
      state.eventsLoading = false;
      state.pendingSlotDeletion = state.pendingSlotDeletion.filter(
        (slot) => slot.id.toString() !== action.payload.data.id.toString()
      );
    });
    builder.addCase(slotDelete.rejected, (state) => {
      state.eventsLoading = false;
      state.pageError = true;
    });
  }
});

export const {
  resetSearch,
  setUser,
  changeView,
  changeCurrentDate,
  removeSlotRequest,
  addSlotRequest,
  removeEvent,
  reAddEvent,
  resetPage,
  switchToCurrentUser,
  showErrorScreen
} = scheduleSlice.actions;
export default scheduleSlice.reducer;
