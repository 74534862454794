import React, { useEffect, useState } from 'react';
import './index.style.scss';
import Images from '../../../../../assets/images';
import ImageComponent from '../../../../core/shared-components/image';
import Typography from '../../../../core/shared-components/typography';
import moment, { Moment } from 'moment';
import { useAppSelector, useAppThunkDispatch } from '../../../../redux/hooks';
import { getSlotInfo } from '../../../../redux/slices/scheduleSlice';
import Loader from '../../../../core/shared-components/loader';
import Tag from '../../../../core/shared-components/tag';

interface ISlotRequestedPopupProps {
  slotId: string | number;
  onCloseClick?: () => void;
}

interface ISlotRequestedPopupStates {
  startDate: Moment;
  endDate: Moment;
  techStack: string | null;
  levelOfInterview: string | null;
  requestedBy: string | null;
}

function SlotRequestedPopup(props: ISlotRequestedPopupProps) {
  const { onCloseClick } = props;
  const [slotState, setSlotState] = useState<ISlotRequestedPopupStates>({
    startDate: moment(),
    endDate: moment(),
    techStack: null,
    levelOfInterview: null,
    requestedBy: null
  });
  const reduxScheduleState = useAppSelector((state) => state.schedule);
  const dispatch = useAppThunkDispatch();
  const { startDate, endDate, techStack, levelOfInterview, requestedBy } = slotState;
  const { cancelledPopupLoading } = reduxScheduleState;

  useEffect(() => {
    (async () => {
      const { slotId } = props;
      const response = await dispatch(getSlotInfo(slotId));
      if (response.payload.data) {
        const { data } = response.payload;
        setSlotState((prevState) => ({
          ...prevState,
          startDate: moment(data.fromTime),
          endDate: moment(data.toTime),
          techStack: data.techStackInterviewLevelDto.techStack,
          levelOfInterview: data.techStackInterviewLevelDto.level,
          requestedBy: data.requestedBy
        }));
      }
    })();
  }, [props.slotId]);

  return (
    <div className="slot-requested-wrapper">
      <div className="slot-available-header">
        <Typography customStyle="slot-available-title">Requested Slot</Typography>
        <div className="slot-available-icon-wrapper">
          <div className="icon-wrapper" onClick={onCloseClick}>
            <ImageComponent src={Images.close} />
          </div>
        </div>
      </div>
      <div className="slot-available-content">
        <Typography customStyle="slot-timing">{`${startDate.format('hh:mm A')} - ${endDate.format(
          'hh:mm A'
        )}`}</Typography>
        <Typography customStyle="slot-dates">{`${startDate.format(
          'dddd, DD MMMM YYYY'
        )}`}</Typography>
        {requestedBy && (
          <div className="slot-request-card-detail">
            <div className="slot-request-card-raised-wrapper">
              <Typography customStyle="slot-request-card--raisedBy">Raised by</Typography>
            </div>
            <div className="slot-request-card-name-avatar-wrapper">
              <div className="slot-request-card-name-wrapper">
                <Typography customStyle="slot-request-card--name">{`${requestedBy}`}</Typography>
              </div>
            </div>
          </div>
        )}
        {techStack && levelOfInterview && (
          <Tag label={`${techStack} - ${levelOfInterview}`} customClass="techStack-tag" />
        )}
      </div>
      <Loader loading={cancelledPopupLoading} customClass="loading-wrapper" />
    </div>
  );
}

export default SlotRequestedPopup;
