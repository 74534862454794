import React, { useCallback, useMemo } from 'react';
import { Column, useSortBy, useTable } from 'react-table';
import ImageComponent from 'app/core/shared-components/image/index';
import Nofilters from 'app/core/shared-components/no-filters/index';
import Pagination from 'app/core/shared-components/table/pagination';
import HeaderGroups from 'app/core/shared-components/table/headerGroups';
import Rows from 'app/core/shared-components/table/rows';
import { IPanelData } from 'helpers/panel';
import Images from 'assets/images';
import { NO_USERS_LEFT } from 'helpers/messages.constants';
import './index.style.scss';

interface ITableProps {
  columnsDefinition: Column[];
  rowsDefinition: any[] | IPanelData[];
  numberOfPages?: number;
  selectedRows?: string[];
  onSelectedRowChange?: (param: string[]) => void;
  onPageChange?: (param: number) => void;
  isCheckEnabled?: boolean;
  tableOverflow?: boolean;
  currentPg?: number;
  sortColumns?: boolean;
  isFilterScreen?: boolean;
  noHeaderBorder?: boolean;
  customEmptyScreen?: boolean;
  totalCount?: number;
  selectedId?: string;
  idKey?: string;
}

const Table = (props: ITableProps) => {
  const {
    columnsDefinition,
    rowsDefinition,
    sortColumns = false,
    isCheckEnabled = true,
    onSelectedRowChange,
    selectedRows = [],
    tableOverflow = true,
    numberOfPages,
    isFilterScreen,
    onPageChange,
    noHeaderBorder = false,
    currentPg,
    customEmptyScreen = false,
    totalCount = 0,
    selectedId = '',
    idKey = ''
  } = props;

  const pageNumberArray = useMemo(
    () => new Array(numberOfPages).fill('').map((_, index) => index + 1),
    [numberOfPages]
  );

  const columns = useMemo(() => columnsDefinition, [columnsDefinition]);
  const data = useMemo(() => rowsDefinition, [rowsDefinition]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data },
    useSortBy
  );

  const { nothingHere } = Images;

  const handleRowSelect = (row: string) => {
    const filteredRow = selectedRows?.filter((singleRow) => {
      return singleRow !== row;
    });

    if (onSelectedRowChange) {
      if (filteredRow?.length !== selectedRows?.length) {
        onSelectedRowChange([...filteredRow]);
      } else {
        const temp = [...selectedRows];
        temp.push(row);
        onSelectedRowChange([...temp]);
      }
    }
  };
  const handleAllRowSelect = useCallback(() => {
    const filteredArray = selectedRows?.filter(
      (id) => !rowsDefinition?.map((row) => row.id).includes(id)
    );
    if (onSelectedRowChange) {
      selectedRows?.length - filteredArray?.length === rowsDefinition?.length
        ? onSelectedRowChange([...filteredArray])
        : onSelectedRowChange([...filteredArray, ...rowsDefinition.map((row) => row.id)]);
    }
  }, [selectedRows, rowsDefinition]);

  const setPageNoOnClick = (param: number) => {
    if (onPageChange) onPageChange(param);
  };

  const checkIdeal = () => {
    const data = rowsDefinition?.filter((item) =>
      selectedRows?.find((param) => param === item?.id)
    );
    return !checkMaster() &&
      ((data.length && data.length < rowsDefinition?.length) || selectedRows?.length)
      ? true
      : false;
  };

  const checkMaster = () => {
    return (
      selectedRows?.filter((id) => rowsDefinition?.map((row) => row?.id).includes(id)).length ===
      rowsDefinition?.length
    );
  };

  const checkRow = useCallback(
    (id: string) => {
      return selectedRows?.filter((singleRow) => singleRow === id).length > 0;
    },
    [selectedRows]
  );

  const pageShift = (offset: number) => {
    if (currentPg && onPageChange && numberOfPages) {
      onPageChange(currentPg + offset);
    }
  };

  return (
    <div className="table-outer-container">
      <div className={tableOverflow ? 'table-main-container' : 'table-main-overflow'}>
        <table {...getTableProps()} className="table-sub-container" cellSpacing={0}>
          <thead className="table-header">
            <HeaderGroups
              headerGroups={headerGroups}
              checkIdeal={checkIdeal}
              checkMaster={checkMaster}
              handleAllRowSelect={handleAllRowSelect}
              isCheckEnabled={isCheckEnabled}
              noHeaderBorder={noHeaderBorder}
              sortColumns={sortColumns}
            />
          </thead>

          <tbody {...getTableBodyProps()} className="table-body">
            <Rows
              checkRow={checkRow}
              handleRowSelect={handleRowSelect}
              isCheckEnabled={isCheckEnabled}
              prepareRow={prepareRow}
              rows={rows}
              idKey={idKey}
              selectedId={selectedId}
            />
          </tbody>
        </table>
      </div>

      {!rowsDefinition?.length && isFilterScreen && !customEmptyScreen && (
        <div className="no-filters-div">
          <Nofilters />
        </div>
      )}
      {!rowsDefinition?.length && !isFilterScreen && !customEmptyScreen && (
        <div className="no-results-container-panel">
          <div className="nr-main-container">
            <div>
              <ImageComponent src={nothingHere} />
            </div>
            <div className="desc">
              <div className="desc-heading">{NO_USERS_LEFT}</div>
            </div>
          </div>
        </div>
      )}
      <div className="pagination-container">
        {numberOfPages && currentPg && numberOfPages > 1 ? (
          <Pagination
            totalCount={totalCount}
            pageShift={pageShift}
            pageNo={currentPg}
            numberOfPages={numberOfPages}
            pageNoArray={pageNumberArray}
            setPageNo={setPageNoOnClick}
          />
        ) : null}
      </div>
    </div>
  );
};

Table.defaultProps = {
  sortColumns: false,
  isCheckEnabled: true,
  selectedRows: [],
  tableOverflow: true,
  noHeaderBorder: false
};

export default Table;
