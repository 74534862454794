import React, { Fragment } from 'react';

interface IRowCellsProps {
  cells: any[];
}

const RowCells = (props: IRowCellsProps) => {
  const { cells } = props;

  return (
    <Fragment>
      {cells.map((cell: any, index: number) => {
        return (
          <td {...cell.getCellProps()} key={index}>
            {cell.render('Cell')}
          </td>
        );
      })}
    </Fragment>
  );
};

export default RowCells;
