import React from 'react';
import ImageComponent from 'app/core/shared-components/image';
import Modal from 'app/core/shared-components/modal';
import Button from 'app/core/shared-components/button';
import { IOnboardFormData } from 'helpers/panel';
import { UNABLE_PANEL_DELETE, UNABLE_PANEL_DELETE_DESC } from 'helpers/messages.constants';
import { CANCEL_BUTTON_TEXT } from 'helpers/constants';
import { INonDeletedUserArray } from 'app/pages/Dashboard/admin';
interface IModalProps {
  handleCloseModalDelete?: () => void;
  nonDeletedUserArray?: INonDeletedUserArray[];
  isOpen?: boolean;
}

const GetLDeleteModalEdgeCase = (props: IModalProps) => {
  const { handleCloseModalDelete, nonDeletedUserArray = [], isOpen = false } = props;

  const getUserList = () => {
    return nonDeletedUserArray?.map((item, index: number) => {
      return (
        <div key={index} className={index % 2 !== 0 ? 'userRecord backgroudDark' : 'userRecord'}>
          <div className="usersList">
            <ImageComponent
              src={item?.profileImageUrl || null}
              className="profile-img-list"
              fallbackClass="profile no-image"
              fallbackText={`${item?.firstName?.split(' ')[0].charAt(0).toUpperCase()}`}
            />
            <div className="user-data-list">
              <div className="user-name">
                {item?.firstName} {item?.lastName}
              </div>
              <div className="user-designation">{item?.designation}</div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <Modal
      header={UNABLE_PANEL_DELETE}
      customStyle="edit-modal"
      onClose={handleCloseModalDelete}
      isOpen={isOpen}>
      <div className="edit-modal-content">
        <div className="delete-modal-para">
          <span>{UNABLE_PANEL_DELETE_DESC}</span>
          <span className="modalLevelName">
            <div
              className={
                nonDeletedUserArray && nonDeletedUserArray?.length > 5
                  ? 'userList-section fixedHeight'
                  : 'userList-section'
              }>
              {getUserList()}
            </div>
          </span>
        </div>
        <div className="edit-modal-footer delete">
          <Button customStyle="edit-modal--cancel-btn" onClick={handleCloseModalDelete}>
            {CANCEL_BUTTON_TEXT}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default GetLDeleteModalEdgeCase;
