import React, { useEffect, useRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

const NavLinkComponent = ({ children, ...otherProps }: NavLinkProps) => {
  const navRef = useRef() as React.MutableRefObject<HTMLAnchorElement>;

  const handleScroll = () => {
    if (navRef) {
      if (otherProps?.id && parseInt(otherProps?.id, 10) <= 3) {
        navRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      } else if (otherProps?.id && parseInt(otherProps?.id, 10) > 3) {
        navRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }
  };

  return (
    <NavLink {...otherProps} ref={navRef} onClick={handleScroll}>
      {children}
    </NavLink>
  );
};

export default NavLinkComponent;
