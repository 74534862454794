import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import Typography from '../typography';
import './index.style.scss';

interface IModalProps {
  children: any;
  onClose?: React.MouseEventHandler<HTMLElement>;
  customStyle?: string;
  customBackdrop?: string;
  header?: string;
  isOpen?: boolean;
}

function Modal(props: IModalProps) {
  const { children, onClose, customStyle, header, customBackdrop, isOpen = false } = props;
  const backdropStyle = customBackdrop ? customBackdrop : 'modal-backdrop';
  const modelStyle = customStyle ? customStyle : 'modal';

  function handleModalClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation();
  }
  return (
    <CSSTransition timeout={300} in={!!isOpen} unmountOnExit classNames="alert">
      <div className={backdropStyle} onClick={onClose}>
        <div className={modelStyle} onClick={handleModalClick}>
          {header && (
            <Typography variant="body" customStyle="heading-modal">
              {header}
            </Typography>
          )}
          {children}
        </div>
      </div>
    </CSSTransition>
  );
}

export default Modal;
