import React from 'react';
import Images from '../../../assets/images';
import { ReactComponent as FindIcon } from '../../../assets/images/findIcon.svg';
import { ReactComponent as NoInternet } from '../../../assets/images/noInternet.svg';
import { ReactComponent as Danger } from '../../../assets/images/danger.svg';

export const ErrorPageRoutes: any = {
  error: {
    pageIcon: FindIcon,
    pageHeading: 'Page not found',
    pageContent: " The page you are looking for doesn't exist.",
    backButton: 'Go Back',
    backButtonStartIcon: Images.arrowLeft,
    refreshButton: 'Take me home'
  },
  noInternet: {
    pageIcon: NoInternet,
    pageHeading: 'No Connection!',
    pageContent: ' Please check your network settings and try again.',
    refreshButton: 'Try Again'
  },
  apiFailure: {
    pageIcon: Danger,
    pageHeading: 'Something went wrong',
    pageContent:
      ' Sorry, there was a problem at our end and we are trying to fix it. Please try again.',
    refreshButton: 'Try again'
  },
  authError: {
    pageIcon: Danger,
    pageHeading: 'Access Denied',
    pageContent: ' There is a change in your role. Please try again by logging in.',
    refreshButton: 'Login'
  }
};
