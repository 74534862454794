import React from 'react';
import Typography from 'app/core/shared-components/typography';

interface IDrawerHeader {
  title: string;
}

function DrawerHeader(props: IDrawerHeader) {
  return (
    <div className="drawer-header-wrapper">
      <Typography customStyle="drawer-header-title">{props.title}</Typography>
    </div>
  );
}

export default DrawerHeader;
