import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import Button from 'app/core/shared-components/button';
import SlotCard from 'app/pages/Slots/components/slot-details-card/SlotCard';
import { ISlotTime } from 'app/pages/Slots/components/slot-picker/MultipleSlots';
import { IAddSlotTimings } from 'app/pages/Slots/components/slot-picker';
import { ALL, AVAILABLE, CANCEL_BUTTON_TEXT, SAVE_BUTTON_TEXT } from 'helpers/constants';
import { formatTime } from 'helpers/dateHelper';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  addSlot,
  fetchAllSlots,
  fetchAvailableSlots,
  toggleToastVisibility
} from 'app/redux/slices/slotsSlice';
import { IAddSlotBody } from 'app/services/slots.service';
import { notify } from 'helpers/toastHelper';
import { PAST_TIME_MSG } from 'helpers/messages.constants';
import './index.style.scss';
interface ISlotDetailsCardProps {
  selectedTimes: IAddSlotTimings[];
  userId: number | string;
  techStackId: number;
  dateFilter: {
    from: string;
    to: string;
  }[];
  slotCategory?: string;
  onCancel: () => void;
  onAddSlot: (slots: IAddSlotBody, errorMessage: string) => void;
  toggleAddSlotCard: () => void;
}

const SlotDetailsCard = (props: ISlotDetailsCardProps) => {
  const {
    selectedTimes,
    userId,
    onCancel,
    dateFilter,
    onAddSlot,
    slotCategory,
    toggleAddSlotCard
  } = props;
  const [selectedDateTime, setSelectedDateTime] = useState<IAddSlotTimings[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [cardDisabledState, setCardDisabledState] = useState<boolean[]>(
    new Array(selectedDateTime.length).fill(false)
  );

  const dispatch = useAppThunkDispatch();

  const handleCloseToast = () => {
    toast.dismiss();
  };

  const handleDateTimeChange = (id: number) => (val: ISlotTime[], error: string) => {
    const disabled = !!error?.length;
    const cardDisabledStateCopy = [...cardDisabledState];
    cardDisabledStateCopy.splice(id, 1, disabled);
    setCardDisabledState(cardDisabledStateCopy);
    const selectedDateTimeCopy = [...selectedDateTime];
    selectedDateTimeCopy.splice(id, 1, { date: selectedDateTime[id].date, times: val });
    setSelectedDateTime([...selectedDateTimeCopy]);
  };

  const handleDateDelete = (id: number) => () => {
    const selectedTimesCopy = [...selectedDateTime];
    selectedTimesCopy.splice(id, 1);
    setSelectedDateTime([...selectedTimesCopy]);
    if (selectedTimesCopy?.length === 0) {
      toggleAddSlotCard();
    }
  };
  const handleAddSlot = () => {
    let times: { date: string; slot: { from: string; to: string }[] }[];
    times = selectedDateTime.map((value) => {
      return {
        date: moment(value.date).format('YYYY-MM-DD'),
        slot: value.times.map((singleTime) => {
          const { startTime, endTime } = singleTime;
          return {
            from: formatTime(startTime!.label, value.date),
            to: formatTime(endTime!.label, value.date)
          };
        })
      };
    });
    dispatch(addSlot({ userId: userId, slots: times })).then(({ payload }) => {
      if (payload.data !== null) {
        onCancel();
        onAddSlot({ userId: userId, slots: times }, '');
        slotCategory == ALL &&
          dispatch(fetchAllSlots({ userId: userId?.toString(), dateTime: dateFilter }));
        if (slotCategory === AVAILABLE) {
          dispatch(
            fetchAvailableSlots({
              userId: userId?.toString(),
              dateTime: dateFilter
            })
          );
        }
      } else {
        onCancel();
        onAddSlot({ userId: userId, slots: times }, payload);
      }
    });
  };

  const handleDisabled = (value: boolean) => {
    setDisabled(value);
  };
  const handleDeleteDate = (id: number) => {
    const selectedTimesCopy = [...selectedDateTime];
    selectedTimesCopy.splice(id, 1);
    setSelectedDateTime([...selectedTimesCopy]);
    if (selectedTimesCopy?.length < 1) {
      toggleAddSlotCard();
    }
  };
  const handleTimeChange = () => {
    const validTimeSlots = selectedTimes?.map((selectedTime, index) => {
      const currentDate = new Date();
      const validTimes = selectedTime?.times?.filter(
        (time, index) =>
          formatTime(time!.startTime!.label, selectedTime.date) >
          moment(currentDate).utcOffset('+05:30').format()
      );
      return { date: selectedTime.date, times: validTimes };
    });
    const datesWithAtleastOneSlot = validTimeSlots?.filter(
      (validSlots) => validSlots?.times?.length
    );
    setSelectedDateTime(datesWithAtleastOneSlot);
    if (datesWithAtleastOneSlot?.length === 0) {
      onCancel();
      notify(
        true,
        <Fragment>{PAST_TIME_MSG}</Fragment>,
        uuidv4().toString(),
        handleCloseToast,
        'error'
      );
    }
    setCardDisabledState(new Array(datesWithAtleastOneSlot.length).fill(false));
  };
  useEffect(() => {
    handleTimeChange();
  }, [selectedTimes]);

  return (
    <Fragment>
      <div className="slot-details-wrapper">
        <SlotCard
          selectedDateTime={selectedDateTime}
          handleDateDelete={handleDateDelete}
          handleDateTimeChange={handleDateTimeChange}
          handleDisabled={handleDisabled}
          handleDateDeleteTab={handleDeleteDate}
        />
      </div>
      {!!selectedDateTime.length && (
        <div className="add-slot-drawer-footer">
          <Button customStyle="cancel-btn" variant="outlined" onClick={onCancel}>
            {CANCEL_BUTTON_TEXT}
          </Button>

          <Button
            customStyle="save-btn"
            variant="contained"
            onClick={handleAddSlot}
            disabled={disabled || cardDisabledState.indexOf(true) !== -1}>
            {SAVE_BUTTON_TEXT}
          </Button>
        </div>
      )}
    </Fragment>
  );
};
export default SlotDetailsCard;
