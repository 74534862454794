import React, { Fragment, useEffect, useState } from 'react';
import './index.style.scss';
import Typography from '../../../../core/shared-components/typography';
import Button from '../../../../core/shared-components/button';
import { useAppSelector, useAppThunkDispatch } from '../../../../redux/hooks';
import { getAdminList, removeAdmin } from '../../../../redux/slices/settingSlice';
import ConfirmationModal from '../../../../core/shared-components/confirmation-modal';
import { notify } from '../../../../../helpers/toastHelper';
import { v4 as uuidv4 } from 'uuid';

interface ITableEmailProps {
  userId: string | number;
  userEmail: string;
}

function TableEmail(props: ITableEmailProps) {
  const { userEmail } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useAppThunkDispatch();
  const { userId: id } = useAppSelector((state: any) => state.user.userData);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleDelete = async () => {
    const { userId } = props;
    toggleModal();
    await dispatch(removeAdmin(userId));
    notify(true, <>{`Successfully deleted admin`}</>, uuidv4().toString(), handleToastClose);
    await dispatch(getAdminList({}));
  };
  const handleToastClose = () => {};

  return (
    <Fragment>
      <div className="table-email-wrapper">
        <Typography customStyle="table-email-lable">{userEmail}</Typography>
        {id !== props.userId && (
          <Button variant="text" customStyle="table-email-delete" onClick={toggleModal}>
            Delete
          </Button>
        )}
      </div>
      <ConfirmationModal
        isOpen={showModal}
        title="Delete Admin ? "
        description="Are you sure you want to delete the selected Admin ? Their admin rights will be removed upon deletion."
        confirmText="Delete"
        onClose={toggleModal}
        onConfirm={handleDelete}
      />
    </Fragment>
  );
}

export default TableEmail;
