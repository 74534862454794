import React, { useCallback, useEffect, useRef, useState } from 'react';
import { uniqueId } from 'lodash';
import { toast } from 'react-toastify';
import Images from 'assets/images';
import { ReactComponent as LoaderSpinner } from 'assets/images/loader-spinner.svg';
import NoRequestedSlot from 'assets/images/no-result-found.svg';
import PlusIcon from 'assets/images/plus-primary.svg';
import { isAdmin } from 'helpers/authHelper';
import {
  DASHBOARD,
  MAX_NOTIFICATION_COUNT,
  PANEL_LABEL,
  PLUS_NOTIFICATION,
  SEARCH
} from 'helpers/constants';
import { notify } from 'helpers/toastHelper';
import Button from 'app/core/shared-components/button';
import Drawer from 'app/core/shared-components/drawer';
import ImageComponent from 'app/core/shared-components/image';
import Loader from 'app/core/shared-components/loader';
import Search, { IOptionType } from 'app/core/shared-components/search';
import SlotsSearchResult from 'app/core/shared-components/search-result';
import ToastUndo from 'app/core/shared-components/toast-undo';
import Typography from 'app/core/shared-components/typography';
import UsersTab from 'app/core/shared-components/users-section-tab';
import { ADMIN, PANEL, RECRUITER } from 'app/redux/constant';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  clearSearchBarValue,
  getUserData,
  handleSearch,
  resetPage,
  resetSearchBarValue,
  searchSelected,
  toggleDrawerVisibility,
  toggleTechDrawerVisibility
} from 'app/redux/slices/adminDashboardSlice';
import {
  deleteRequestedSlot,
  fetchAvailableSlots,
  fetchRequestedSlots,
  handleSearch as handlePanelSearch,
  IRequestedSlotsResponse,
  readdSlotRequest,
  removeSlotRequest
} from 'app/redux/slices/slotsSlice';
import {
  IDashboardSearchDataType,
  IPanelSearch,
  IProjectType,
  ITechStackInterviewLevelDtos,
  IUserDataByIdType
} from 'app/services/user-response.types';
import ErrorPage from '../../Error';
import Header from '../../Slots/components/header';
import SlotRequestCard from '../../Slots/components/slot-request-card';
import SlotRequestForm from '../../Slots/components/slot-request-form';
import BookedSlots from '../components/booked-slots';
import DashboardLayout from '../components/dashboard-layout';
import HomeDrawer from '../components/home-drawer';
import NotificationDrawer from '../components/notification-drawer';
import SlotEfficiency from '../components/slot-efficiency';
import SlotOverview from '../components/slot-overview';
import SlotRequest from '../components/slot-request';
import TechDrawer from '../components/tech-drawer';
import './index.style.scss';
import { DELETED_SLOT } from 'helpers/messages.constants';
import { getExperience } from 'helpers/utils';

export interface ISelectedPanel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  designation: string;
  totalExperience: string | 0;
  techStackInterviewLevelSet: ITechStackInterviewLevelDtos[];
  projects: IProjectType[];
  mobileNo: string;
  profileImageUrl: string;
  addedOn: string;
  statusEnum: string;
  isDashboard: boolean;
}

interface ISingleSearchData {
  userId: string;
  fullName: string;
  roleId: string;
  totalExperience: number;
  activeFlag: boolean;
  designation: string;
  roles: string[];
  profileImageUrl: string;
  mobileNo: string;
  email: string;
  techStack: string[];
  project: string[];
}

interface IDashboardStates {
  isSlotRequestDrawerOpen: boolean;
  isSlotRequestModalOpen: boolean;
  requestedSlots: IRequestedSlotsResponse[];
  isSearchedOptionSelected: boolean;
  options: IOptionType[];
  resultNotFound: boolean;
  isHomeToastVisible: boolean;
  isUndoToastVisible: boolean;
  isDeletionOn: boolean;
  deletedSlotId: number;
  showPanelSlots: boolean;
  selectedPanel: ISelectedPanel;
  resultCleared: boolean;
  isNotificationOpen: boolean;
}

const WIDGETS: any = (handleSlotRequest: () => {}) => ({
  admin: [
    {
      column: 1,
      components: [<SlotEfficiency key={uniqueId()} />]
    },
    {
      column: 2,
      components: [<SlotOverview title="Slot Overview" key={uniqueId()} />]
    },
    {
      column: 3,
      components: [<UsersTab key={uniqueId()} />]
    }
  ],
  recruiter: [
    {
      column: 1,
      components: [<SlotEfficiency key={uniqueId()} />]
    },
    {
      column: 2,
      components: [<SlotOverview showOnlyAvailableSlots title="Available Slots" key={uniqueId()} />]
    },
    {
      column: 3,
      components: [<BookedSlots key={uniqueId()} />]
    },
    {
      column: 4,
      components: [<SlotRequest key={uniqueId()} handleSlotRequestModal={handleSlotRequest} />]
    }
  ]
});
const RecuiterDashboard = () => {
  const [userRole, setUserRole] = useState(PANEL);
  const [widget, setWidget] = useState<any>([]);

  const [dashBoardStates, setDashboardStates] = useState<IDashboardStates>({
    isSlotRequestDrawerOpen: false,
    isSlotRequestModalOpen: false,
    requestedSlots: [] as IRequestedSlotsResponse[],
    isSearchedOptionSelected: false,
    options: [],
    resultNotFound: false,
    isHomeToastVisible: false,
    isUndoToastVisible: false,
    isDeletionOn: true,
    deletedSlotId: 0,
    showPanelSlots: false,
    selectedPanel: {} as ISelectedPanel,
    resultCleared: false,
    isNotificationOpen: false
  });

  const timeOut = useRef<any>(null);
  const abortSignal = useRef<any>(null);

  const dispatch = useAppThunkDispatch();
  const {
    toastMessage,
    isToastVisible,
    isRequestSlotLoading,
    requestedSlots: requestedSlotsData,
    isSearchError: slotsSearchError
  } = useAppSelector((state) => state?.slots);

  const {
    userData: { firstName, isLoading, techStackLevelSet, role }
  } = useAppSelector((state) => state?.user);

  const {
    isDrawerOpen,
    mountHome,
    clearResult,
    isTechDrawerOpen,
    userDataById,
    isSearchError: adminDashBoardSearchError
  } = useAppSelector((state) => state?.adminDashboard);
  const { techStack, notificationCount } = useAppSelector((state) => state?.shared);
  const { isFirstLogin } = useAppSelector((state) => state.login);
  const { notificationIcon } = Images;

  const {
    isSlotRequestDrawerOpen,
    isSlotRequestModalOpen,
    resultNotFound,
    options,
    showPanelSlots,
    selectedPanel,
    isNotificationOpen
  } = dashBoardStates;

  const handleDeleteUndo = useCallback(
    (slotId: string | number) => async () => {
      dispatch(readdSlotRequest(slotId));
      toast.update(slotId, { onClose: () => {}, hideProgressBar: true });
      toast.dismiss(slotId);
    },
    []
  );

  const handleDeleteClose = useCallback((toastId: number | string) => {
    dispatch(deleteRequestedSlot(toastId)).then(() => {
      dispatch(fetchRequestedSlots());
      setDashboardStates((prevState) => ({ ...prevState, isUndoToastVisible: false }));
    });
  }, []);

  const deleteSlotRequested = async (id: number | string) => {
    dispatch(removeSlotRequest(id));
    notify(
      false,
      <ToastUndo toastMessage={DELETED_SLOT} handleUndo={handleDeleteUndo(id)} toastId={id} />,
      id,
      handleDeleteClose
    );
  };

  const handleSlotRequest = () => {
    if (!isSlotRequestDrawerOpen) {
      dispatch(fetchRequestedSlots());
    }
    setDashboardStates((prevState) => ({
      ...prevState,
      isSlotRequestDrawerOpen: !isSlotRequestDrawerOpen
    }));
  };

  const handleSlotRequestModal = () => {
    setDashboardStates((prevState) => ({
      ...prevState,
      isSlotRequestModalOpen: !isSlotRequestModalOpen
    }));
  };

  const handleRequestClick = () => {
    if (userRole === ADMIN) {
      handleSlotRequest();
      return;
    }
    handleSlotRequestModal();
  };

  const closeSearchResult = () => {
    dispatch(clearSearchBarValue());
    setDashboardStates((prevState) => ({
      ...prevState,
      isSearchedOptionSelected: false,
      showPanelSlots: false,
      resultCleared: true
    }));
  };

  const handlePanelSearchAction = async (searchString: string) => {
    setDashboardStates((prevState) => ({
      ...prevState,
      options: []
    }));
    if (resultNotFound) {
      setDashboardStates((prevState) => ({
        ...prevState,
        resultNotFound: false
      }));
    }
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
    if (!!searchString?.trim()?.length) {
      timeOut.current = setTimeout(async () => {
        if (abortSignal.current) abortSignal.current.abort();
        abortSignal.current = new AbortController();
        const signal = abortSignal.current;
        const data = await dispatch(handlePanelSearch({ searchString, signal }));

        if (!!data?.payload?.data?.length) {
          const options = data.payload?.data?.map((user: IPanelSearch) => {
            return {
              id: user.id,
              startIcon: user.profileImageUrl,
              searchKey: `${user.firstName} ${user.lastName}`,
              supportingData: user.designation,
              singleSearchData: user
            };
          });
          setDashboardStates((prevState) => ({
            ...prevState,
            options: options,
            resultNotFound: false
          }));
        } else {
          setDashboardStates((prevState) => ({
            ...prevState,
            resultNotFound: true
          }));
        }
      }, 1000);
    } else {
      setDashboardStates((prevState) => ({ ...prevState, options: [] }));
    }
  };
  function searchSelection(id: string | number, searchSingleData: any) {
    setDashboardStates((prevState) => ({
      ...prevState,
      isSearchedOptionSelected: true
    }));
    dispatch(resetSearchBarValue());
    dispatch(searchSelected(searchSingleData));
    if (searchSingleData?.stack) {
      dispatch(toggleTechDrawerVisibility());
    } else if (searchSingleData?.roles?.includes(PANEL_LABEL)) {
      dispatch(getUserData(id)).then(({ payload }) => {
        const userDataById = payload?.data as IUserDataByIdType;
        const selectedPanelData: ISelectedPanel = {
          id: userDataById?.userId,
          firstName: userDataById?.firstName,
          lastName: userDataById?.lastName,
          email: userDataById?.emailId,
          designation: userDataById?.designation,
          totalExperience:
            userDataById?.totalExperience && getExperience(userDataById?.totalExperience),
          techStackInterviewLevelSet: userDataById?.techStackInterviewLevelDtos,
          projects: userDataById?.projects,
          mobileNo: userDataById?.mobileNo,
          profileImageUrl: userDataById?.profileImageUrl,
          addedOn: '',
          statusEnum: userDataById?.statusEnum,
          isDashboard: true
        };
        dispatch(
          fetchAvailableSlots({
            userId: searchSingleData?.userId,
            dateTime: []
          })
        );
        setDashboardStates((prevState) => ({
          ...prevState,
          showPanelSlots: true,
          selectedPanel: selectedPanelData
        }));
      });
    } else {
      dispatch(getUserData(id));
      setDashboardStates((prevState) => ({
        ...prevState,
        resultCleared: true
      }));
      dispatch(toggleDrawerVisibility());
    }
  }
  const handlePanelSearchSelection = (id: string | number, searchSingleData: any) => {
    setDashboardStates((prevState) => ({
      ...prevState,
      isSearchedOptionSelected: true
    }));
    dispatch(resetSearchBarValue());
    dispatch(searchSelected(searchSingleData));
    dispatch(
      fetchAvailableSlots({
        userId: searchSingleData?.id,
        dateTime: []
      })
    );
    setDashboardStates((prevState) => ({
      ...prevState,
      showPanelSlots: true,
      selectedPanel: searchSingleData
    }));
  };
  async function handleSearchAction(searchString: string) {
    setDashboardStates((prevState) => ({
      ...prevState,
      options: []
    }));
    if (resultNotFound) {
      setDashboardStates((prevState) => ({
        ...prevState,
        resultNotFound: false
      }));
    }
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
    if (!!searchString?.trim()?.length) {
      timeOut.current = setTimeout(async () => {
        if (abortSignal.current) abortSignal.current.abort();
        abortSignal.current = new AbortController();
        const signal = abortSignal.current;
        const response = await dispatch(handleSearch({ searchString, signal }));
        const data = response.payload.data as IDashboardSearchDataType;
        if (!response?.payload?.error) {
          const options = data?.userSet?.map((user) => {
            return {
              startIcon: user?.profileImageUrl,
              searchKey: user?.fullName,
              tags: user?.roles,
              id: user?.userId,
              singleSearchData: user
            };
          });
          setDashboardStates((prevState) => ({
            ...prevState,
            options: [...options],
            resultNotFound: false
          }));
        } else if (response?.payload?.error && response?.payload?.error[0].code === 1008) {
          setDashboardStates((prevState) => ({
            ...prevState,
            resultNotFound: true
          }));
        }
      }, 1000);
    } else {
      setDashboardStates((prevState) => ({ ...prevState, options: [] }));
    }
  }

  const openNotificationDrawer = () => {
    setDashboardStates((prevState) => ({
      ...prevState,
      isNotificationOpen: true
    }));
  };

  const closeNotificationDrawer = () => {
    setDashboardStates((prevState) => ({
      ...prevState,
      isNotificationOpen: false
    }));
  };

  const updateUserRole = () => {
    const userRole = role?.map((item) => item?.role);
    if (isAdmin(userRole)) {
      setUserRole(ADMIN);
    } else {
      setUserRole(RECRUITER);
    }
  };

  const resetSearch = () => {
    if (mountHome) {
      setDashboardStates((prevState) => ({
        ...prevState,
        isSearchedOptionSelected: false
      }));
      dispatch(resetSearchBarValue());
    }
  };

  useEffect(() => {
    updateUserRole();
  }, [role]);

  useEffect(() => {
    setDashboardStates((prevState) => ({ ...prevState, requestedSlots: requestedSlotsData }));
  }, [requestedSlotsData]);

  useEffect(() => {
    resetSearch();
  }, [techStack]);

  useEffect(() => {
    return () => {
      dispatch(resetPage());
      toast.dismiss();
    };
  }, []);

  useEffect(() => {
    setWidget(WIDGETS(handleSlotRequest)[userRole]);
  }, [userRole]);

  useEffect(() => {
    setDashboardStates((prevState) => ({ ...prevState, requestedSlots: requestedSlotsData }));
  }, [requestedSlotsData]);

  return slotsSearchError || adminDashBoardSearchError ? (
    <ErrorPage page="apiFailure" />
  ) : isLoading ? (
    <Loader loading={isLoading} />
  ) : (
    <div className="dashboard-page">
      <div className="slots-header">
        {showPanelSlots ? (
          <Header
            title={userRole === ADMIN ? userDataById : selectedPanel}
            onClear={closeSearchResult}
          />
        ) : (
          <div className="header-container">
            <div className="header-title">{isFirstLogin ? `Welcome ${firstName}` : DASHBOARD}</div>
          </div>
        )}

        <div className="actions-container">
          <div
            className={`notifications ${isNotificationOpen ? 'notifications-active' : ''}`}
            onClick={openNotificationDrawer}>
            {!!Number(notificationCount) && (
              <div
                className={
                  notificationCount > MAX_NOTIFICATION_COUNT
                    ? 'count-increased notification-count'
                    : 'notification-count'
                }>
                {notificationCount > MAX_NOTIFICATION_COUNT ? PLUS_NOTIFICATION : notificationCount}
              </div>
            )}
            <ImageComponent src={notificationIcon} customClass="notification-icon" />
          </div>
          {!!techStackLevelSet?.length && !showPanelSlots && (
            <Button
              customStyle={`slot-btn ${isSlotRequestDrawerOpen ? 'slot-btn-active' : ''}`}
              variant="outlined"
              onClick={handleRequestClick}>
              {userRole === ADMIN ? <span>Slot Requests</span> : <span>New Slot Requests</span>}
            </Button>
          )}

          <div className="search-bar">
            <Search
              placeholder={SEARCH}
              onChange={userRole === ADMIN ? handleSearchAction : handlePanelSearchAction}
              setSelectedId={userRole === ADMIN ? searchSelection : handlePanelSearchSelection}
              options={options}
              customDropdownIconClass={userRole === ADMIN ? '' : 'search-circle'}
              clearResult={clearResult}
              resultNotFound={resultNotFound}
            />
          </div>
        </div>
      </div>
      {!showPanelSlots ? (
        <DashboardLayout layout={widget} />
      ) : (
        <SlotsSearchResult selectedSearch={selectedPanel} isDashboard={true} />
      )}

      <Drawer
        headerProps={
          <Typography variant="headline-24" customStyle="slot-drawer-title">
            Slot Requests
          </Typography>
        }
        bodyStyles="slot-drawer-body"
        onClose={handleSlotRequest}
        isOpen={isSlotRequestDrawerOpen}>
        {isRequestSlotLoading ? (
          <div className="slot-loading-wrapper">
            <LoaderSpinner className="loading-spinner" />
          </div>
        ) : (
          <div className="slot-drawer-body">
            <div className="request-slot">
              <Typography variant="headline-16" customStyle="response-text">
                Responses received
              </Typography>
              <Button
                customStyle="new-request-btn"
                variant="outlined"
                startIcon={PlusIcon}
                onClick={handleSlotRequestModal}>
                New Request
              </Button>
            </div>
            {requestedSlotsData.length ? (
              <div className="slots-received">
                {requestedSlotsData.map((slots, index) => (
                  <SlotRequestCard
                    key={index}
                    showTag
                    tag={`${slots?.techStack} - ${slots?.interviewLevel}`}
                    requestId={slots.slotRequestId}
                    deleteSlotRequest={deleteSlotRequested}
                    panelList={slots.slotRequestUserResponseDtoList}
                    fromTime={slots?.fromTime}
                    toTime={slots.toTime}
                  />
                ))}
              </div>
            ) : (
              <div className="no-response">
                <ImageComponent src={NoRequestedSlot} />
                <div className="no-result-text">No slots requested</div>
              </div>
            )}
          </div>
        )}
      </Drawer>

      {isSlotRequestModalOpen && (
        <SlotRequestForm onClose={handleSlotRequestModal} onDrawerClose={handleSlotRequest} />
      )}

      <HomeDrawer isDrawerOpen={isDrawerOpen} />
      <div className="notification-drawer-container">
        <Drawer
          isOpen={isNotificationOpen}
          onClose={closeNotificationDrawer}
          headerProps="Notifications"
          headerStyles="notification-header"
          bodyStyles="notification-body"
          hideDivider>
          <NotificationDrawer />
        </Drawer>
      </div>
      <TechDrawer isDrawerOpen={isTechDrawerOpen} />
    </div>
  );
};
export default RecuiterDashboard;
