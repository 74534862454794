import React, { useState, useCallback, useEffect, useRef, Fragment } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Column } from 'react-table';
import './index.style.scss';
import Table from 'app/core/shared-components/table';
import Typography from 'app/core/shared-components/typography';
import Search, { IOptionType } from 'app/core/shared-components/search';
import Button from 'app/core/shared-components/button';
import Images from 'assets/images';
import ImageComponent from 'app/core/shared-components/image';
import ExperienceFilter from './components/filter';
import UserDetail from './components/user-detail';
import ClickAwayListener from 'app/core/shared-components/click-away';
import SideModal from './components/side-modal';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  getOnboardUserList,
  IUserDetail,
  onboardUserSearch,
  openDrawer,
  resetPage,
  toggleToast,
  closeDrawer as closeToggleDrawer
} from 'app/redux/slices/onboardUserSlice';
import Loader from 'app/core/shared-components/loader';
import ErrorPage from 'app/pages/Error';
import routeError from 'router/routeError';
import withRouter from 'router/withRouter';
import debounce from 'lodash/debounce';
import {
  MASS_ONBOARDING,
  ONBOARDING_USERS,
  ONBOARD_USER,
  STATUSENUM_ARCHIVE
} from 'helpers/constants';
import { getExperience } from 'helpers/utils';

interface IOnboardUserList {
  designation?: string;
  emailId?: string;
  experience?: number;
  firstName?: string;
  id?: number;
  lastName?: string;
  mobileNo?: string;
  profileImageUrl?: string | null;
  userId?: number;
}
interface IOnboardStates {
  showFilter: boolean;
  onboardUserList: IOnboardUserList[];
  selectedRows: string[];
  searchOptions: IOptionType[];
  clearSearchResult: boolean;
}

function Onboard() {
  const [statusNav, setStatusNav] = useState<number>();
  const [onBoardState, setOnBoardState] = useState<IOnboardStates>({
    showFilter: false,
    onboardUserList: [],
    selectedRows: [],
    searchOptions: [],
    clearSearchResult: false
  });
  const reduxOnboardState = useAppSelector((state) => state.onboardUser);
  const timeOut = useRef<any>(null);
  const abortSignal = useRef<any>(null);
  const dispatch = useAppThunkDispatch();

  const {
    pendingList,
    availablePageCount,
    filtersApplied,
    searchOptions: onboardSearchOptions,
    isLoading,
    showToast,
    isError,
    navigateTo,
    currentPageNumber,
    showDrawer,
    resultNotFound,
    totalCount
  } = reduxOnboardState;

  const { showFilter, onboardUserList, selectedRows, searchOptions, clearSearchResult } =
    onBoardState;
  const { filter } = Images;

  const toggleFilter = useCallback(() => {
    setOnBoardState((prevState) => ({ ...prevState, showFilter: !prevState.showFilter }));
  }, [showFilter]);

  const toggleDrawer = useCallback(() => {
    const { selectedRows } = onBoardState;
    dispatch(openDrawer(selectedRows));
  }, [selectedRows]);

  const closeFilter = useCallback(() => {
    const { showFilter } = onBoardState;
    if (showFilter) setOnBoardState((prevState) => ({ ...prevState, showFilter: false }));
  }, [showFilter]);

  const changeDrawerState = useCallback(
    (userId: number) => () => {
      dispatch(openDrawer([userId]));
      setOnBoardState((prevState) => ({
        ...prevState,
        selectedRows: []
      }));
    },
    []
  );

  const changePageNumber = (params: number) => {
    closeFilter();
    dispatch(getOnboardUserList({ pageNumber: params - 1 }));
  };

  const changeSelectedRows = (params: string[]) => {
    setOnBoardState((prevState) => ({
      ...prevState,
      selectedRows: [...params]
    }));
  };

  const closeDrawer = () => {
    setOnBoardState((prevState) => ({
      ...prevState,
      selectedRows: [],
      clearSearchResult: !prevState.clearSearchResult
    }));
    dispatch(closeToggleDrawer());
  };

  const handleSearch = (searchString: string) => {
    setOnBoardState((prevState) => ({
      ...prevState,
      searchOptions: []
    }));
    if (timeOut.current) {
      timeOut.current.cancel();
    }
    timeOut.current = debounce(() => {
      if (abortSignal.current) abortSignal.current.abort();
      abortSignal.current = new AbortController();
      const signal = abortSignal.current;
      dispatch(onboardUserSearch({ searchString, abortSignal: signal }));
    }, 2000);
    timeOut.current();
  };

  const handleSearchSelection = (id: string | number) => {
    dispatch(openDrawer([id]));
  };

  const handleToastClose = () => {
    dispatch(toggleToast(''));
  };

  const handleStatusNavigation = (status: string) => {
    setStatusNav(status === STATUSENUM_ARCHIVE ? 2 : 0);
  };

  const handleUserList = () => {
    dispatch(getOnboardUserList({ pageNumber: 0 })).then((response) => {
      const userData = response?.payload?.userApprovalDTOList;
      setOnBoardState((prevState) => ({
        ...prevState,
        onboardUserList: userData.map((user: IOnboardUserList) => ({ ...user, id: user.userId }))
      }));
    });
  };

  const updateSearchOptions = () => {
    const data = onboardSearchOptions.map((options) => ({
      startIcon: options.profileImageUrl,
      searchKey: `${options.firstName} ${options.lastName}`,
      supportingData: options.designation,
      id: `${options.userId}`,
      singleSearchData: options
    }));
    setOnBoardState((prevState) => ({ ...prevState, searchOptions: data }));
  };

  const customClassValue = `filter-click-wrapper ${
    showFilter ? 'filter-click-wrapper--active' : ''
  } ${filtersApplied.length ? 'filter-click-wrapper--applied' : ''}`;

  const updatePendingList = () => {
    setOnBoardState((prevState) => ({
      ...prevState,
      onboardUserList: pendingList.map((user) => ({ ...user, id: user.userId }))
    }));
  };

  const updateOnboardUserList = () => {
    dispatch(getOnboardUserList({}));
    return () => {
      dispatch(resetPage());
    };
  };

  const columns: Column<any>[] = [
    {
      Header: (
        <div key="name" className="table-onboard-header table-th-cell--first">
          Name
        </div>
      ),
      accessor: 'firstName',
      Cell: (pendingList) => {
        return (
          <div
            className="td-decoration"
            key={pendingList?.row?.original?.firstName}
            onClick={changeDrawerState(pendingList?.row?.original?.userId)}>
            <UserDetail
              userName={`${pendingList?.row?.original?.firstName} ${pendingList?.row?.original?.lastName}`}
              userImage={pendingList?.row?.original?.profileImageUrl}
            />
          </div>
        );
      }
    },
    {
      Header: (
        <div key="designation" className="table-onboard-header">
          Designation
        </div>
      ),
      accessor: 'designation',
      Cell: (pendingList) => {
        return (
          <div
            className="td-decoration"
            key={pendingList?.row?.original?.designation}
            onClick={changeDrawerState(pendingList?.row?.original?.userId)}>
            {pendingList?.row?.original?.designation}
          </div>
        );
      }
    },

    {
      Header: (
        <div key="email" className="table-onboard-header">
          Email
        </div>
      ),
      accessor: 'emailId',
      Cell: (pendingList) => {
        return (
          <div
            className="td-decoration"
            key={pendingList?.row?.original?.emailId}
            onClick={changeDrawerState(pendingList?.row?.original?.userId)}>
            {pendingList?.row?.original?.emailId}
          </div>
        );
      }
    },
    {
      Header: (
        <div key="experience" className="table-onboard-header">
          Experience
        </div>
      ),
      accessor: 'experience',
      Cell: (pendingList) => {
        getExperience(pendingList?.row?.original?.experience);
        return (
          <div
            className="td-decoration"
            key={pendingList?.row?.original?.experience}
            onClick={changeDrawerState(pendingList?.row?.original?.userId)}>
            {getExperience(pendingList?.row?.original?.experience)}
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    updateOnboardUserList();
  }, []);

  useEffect(() => {
    updateSearchOptions();
  }, [onboardSearchOptions]);

  useEffect(() => {
    updatePendingList();
  }, [pendingList]);

  return (
    <Fragment>
      {!isError && (
        <div className="onboard-container">
          <header className="onboard-header">
            <Typography customStyle="onboard-title">{ONBOARDING_USERS}</Typography>
            <div className="onboard-toolbar">
              <div className="buttonContainer">
                <Button
                  variant="contained"
                  customStyle="onboard-button"
                  onClick={toggleDrawer}
                  disabled={selectedRows.length === 0}>
                  Onboard
                </Button>
                <ClickAwayListener
                  data-value={filtersApplied.length}
                  handleClose={closeFilter}
                  customClass={customClassValue}>
                  <Button
                    variant="outlined"
                    customStyle={
                      showFilter ? 'filter-button filter-button-active' : 'filter-button'
                    }
                    onClick={toggleFilter}>
                    <ImageComponent src={filter} alt="Filter Icon" />
                  </Button>
                  <CSSTransition timeout={300} unmountOnExit classNames="alert" in={showFilter}>
                    {showFilter ? (
                      <ExperienceFilter onClose={closeFilter} />
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </CSSTransition>
                </ClickAwayListener>
              </div>
              <div>
                <Search
                  options={searchOptions}
                  placeholder="Search"
                  onChange={handleSearch}
                  customDropdownIconClass="search-profile-icon"
                  setSelectedId={handleSearchSelection}
                  clearResult={clearSearchResult}
                  resultNotFound={resultNotFound}
                />
              </div>
            </div>
          </header>
          <main className="table-container">
            <Table
              currentPg={currentPageNumber + 1}
              columnsDefinition={columns}
              rowsDefinition={onboardUserList}
              onSelectedRowChange={changeSelectedRows}
              onPageChange={changePageNumber}
              numberOfPages={availablePageCount}
              selectedRows={selectedRows}
              totalCount={totalCount}
            />
          </main>
          <SideModal
            open={showDrawer}
            onClose={closeDrawer}
            handleStatusNavigation={handleStatusNavigation}
            formHeading={selectedRows.length > 1 ? MASS_ONBOARDING : ONBOARD_USER}
            getUsersList={handleUserList}
          />
        </div>
      )}
      {isError && <ErrorPage page="apiFailure" />}
      <Loader loading={isLoading} />
    </Fragment>
  );
}

export default routeError(withRouter(Onboard));
