import React, { useMemo } from 'react';
import { components } from 'react-select';

const ValueContainer = ({ ...props }: any) => {
  const [values, input] = props.children;
  const { tagLimit = 2 } = props.selectProps;

  const showTags = useMemo(() => Array.isArray(values) && values.length > tagLimit, [values]);

  const count = useMemo(() => (showTags ? values.length - tagLimit : null), [values]);

  return (
    <components.ValueContainer {...props}>
      {showTags ? values.slice(0, tagLimit) : values}
      {count && <div className="multi-value-count">+{count}</div>}
      {input}
    </components.ValueContainer>
  );
};

export default ValueContainer;
