const Images = {
  home: require('./home.svg').default,
  dashboard: require('./dashboard.svg').default,
  approvals: require('./approvals.svg').default,
  person: require('./person.svg').default,
  panel: require('./panel.svg').default,
  techStack: require('./tech-stack.svg').default,
  levels: require('./levels.svg').default,
  projects: require('./projects.svg').default,
  settings: require('./settings.svg').default,
  approvalsLarge: require('./approvals-large.svg').default,
  arrowLeft: require('./arrowIcon.svg').default,
  arrowUp: require('./arrow-up.svg').default,
  arrowDown: require('./arrow-down.svg').default,
  email: require('./email.svg').default,
  close: require('./modal-close-icon.svg').default,
  noResult: require('./no-result.svg').default,
  rightTick: require('./right-tick.svg').default,
  avatar: require('./avatar.svg').default,
  camera: require('./Camera.svg').default,
  cancelIcon: require('./cancel-icon.svg').default,
  coditasIcon: require('./coditas-icon.svg').default,
  coditasLogo: require('./coditas-logo.svg').default,
  warningIcon: require('./warning-icon.svg').default,
  logOut: require('./logout.svg').default,
  search: require('./search.svg').default,
  loaderSpinner: require('./loader-spinner.svg').default,
  user: require('./user.svg').default,
  techStackLarge: require('./tech-stack-large.svg').default,
  delete: require('./delete.svg').default,
  more: require('./more.svg').default,
  ellipsis: require('./dots.svg').default,
  uploadFile: require('./upload-icon.svg').default,
  deleteIcon: require('./delete-icon.svg').default,
  slots: require('./slots.svg').default,
  leftChevron: require('./left-chevron.svg').default,
  rightChevron: require('./right-chevron.svg').default,
  filter: require('./filter.svg').default,
  section: require('./section.jpg'),
  orangeDot: require('./dot_orange.svg').default,
  orangeArrow: require('./orange_arrowDown.svg').default,
  chevronRightActive: require('./chevron-right-blue.svg').default,
  chevronRightDisabled: require('./chevron-right-grey.svg').default,
  chevronLeftActive: require('./chevron-left-blue.svg').default,
  edit: require('./edit.svg').default,
  megaphone: require('./megaphone.svg').default,
  plus: require('./plus.svg').default,
  toastClose: require('./toast-close.svg').default,
  crownIcon: require('./crown-icon.svg').default,
  firstBadge: require('./first-badge.svg').default,
  secondBadge: require('./second-badge.svg').default,
  thirdBadge: require('./third-badge.svg').default,
  searchIcon: require('./search-icon.svg').default,
  warningOrange: require('./warning-orange.svg').default,
  nothingHere: require('./nothing-here.svg').default,
  techStackGray: require('./tech-stack-grey.svg').default,
  avatarGray: require('./user-avatar-grey.svg').default,
  clockGray: require('./clock-grey.svg').default,
  upcomingEmptyCheck: require('./upcoming-empty-state.svg').default,
  noRewards: require('./no-rewards.svg').default,
  streak: require('./streakIcon.svg').default,
  badge: require('./badge.svg').default,
  star: require('./starfull.svg').default,
  lock: require('./lockIcon.svg').default,
  darkLock: require('./darklock.svg').default,
  date: require('./date.svg').default,
  goldenStar: require('./goldenstar.svg').default,
  goldenStreak: require('./goldenStreak.svg').default,
  goldenSlot: require('./goldenSlot.svg').default,
  goldenBadge: require('./goldenBadge.svg').default,
  greenBadge: require('./greenBadge.svg').default,
  zohoIcon: require('./zoho.svg').default,
  zohoInitial: require('./zoho-initial.svg').default,
  infoGray: require('./info-gray.svg').default,
  noData: require('./no-result-found.svg').default,
  chevronLeftDisabled: require('./chevron-left-gray.svg').default,
  coditasCommaWhite: require('./coditas-comma-white.svg').default,
  slotifyLogo: require('./slotify-logo.svg').default,
  sidenavOpen: require('./sidenav-open.svg').default,
  sidenavClose: require('./sidenav-close.svg').default,
  collapsedManagePanel: require('./collapsed-manage-panels.svg').default,
  collapsedManagePanelSelected: require('./mu-selected.svg').default,
  collapsedManagePanelFocus: require('./mu-selected-focus.svg').default,
  sortIcon: require('./sort.svg').default,
  orangeArrowUp: require('./orangeArrowUp.svg').default,
  closeIcon: require('./close-icon.svg').default,
  notificationIcon: require('./notification-icon.svg').default,
  levelBlueIcon: require('./level-blue.svg').default,
  techStackBlueIcon: require('./tech-stack-blue.svg').default,
  tickIcon: require('./tick.svg').default,
  calendarSyncIcon: require('./calendar-sync.svg').default,
  topEarnerIcon: require('./top-earner.svg').default,
  slotAddedIcon: require('./slot-added.svg').default,
  slotCancelledIcon: require('./slot-cancelled.svg').default,
  slotBookedIcon: require('./slot-booked.svg').default,
  projectBlueIcon: require('./projects-search.svg').default,
  adminIcon: require('./admin.svg').default,
  slotRequestAcceptedIcon: require('./slot-request-accepted.svg').default,
  techStackLevelIcon: require('./tech-stack-level.svg').default,
  projectIcon: require('./project.svg').default,
  trash: require('./trash-enabled.svg').default,
  plusPrimary: require('./plus-primary.svg').default,
  sort: require('./sort-icon.svg').default,
  bookedSlot: require('./booked-slot.svg').default,
  cancelledSlot: require('./cancelled-slot.svg').default,
  availableSlot: require('./available-slot.svg').default,
  noNotifications: require('./noNotifications.svg').default
};

export default Images;
