import React, { ChangeEvent, Fragment, useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ILevelForm, ILevelsData } from '../..';
import Images from 'assets/images';
import {
  CANCEL_BUTTON_TEXT,
  DELETE_BUTTON_TEXT,
  DELETE_LOI_TITLE,
  EDIT_BUTTON_TEXT,
  EDIT_LOI_TITLE,
  LEVEL,
  LEVEL_NAME,
  NUMBER_OF_PANEL,
  PULSE_ANIMATION_TIMEOUT,
  SAVE_BUTTON_TEXT
} from 'helpers/constants';
import { LevelOfPanelEditValidation } from 'helpers/levelOfInterview.validation';
import Button from 'app/core/shared-components/button';
import ClickAwayListener from 'app/core/shared-components/click-away';
import ImageComponent from 'app/core/shared-components/image';
import Input from 'app/core/shared-components/input-box/inputComponent/inputComponent';
import Modal from 'app/core/shared-components/modal';
import Typography from 'app/core/shared-components/typography';
import UserList from 'app/core/shared-components/users-list';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import { editLevel, userListLevel } from 'app/redux/slices/levelOfInterviewSlice';
import { getLOI, setSelectedNotificationData } from 'app/redux/slices/sharedSlice';
import './index.style.scss';
import { DELETE_LOI_DESCRIPTION, LEVEL_OF_PANEL_EXISTS } from 'helpers/messages.constants';

interface ILevelItemProps {
  levelItem: ILevelsData;
  levelCard: ILevelsData;
  onDeleteThroughCard: (param: ILevelsData) => void;
}
interface ILevelItemState {
  showEditModal: boolean;
  showDeleteModal: boolean;
  showDropdown: boolean;
}

export function LevelItem({ levelItem, levelCard, onDeleteThroughCard }: ILevelItemProps) {
  const [levelItemState, setLevelItem] = useState<ILevelItemState>({
    showEditModal: false,
    showDeleteModal: false,
    showDropdown: false
  });
  const [errorModal, setErrorModal] = useState(false);
  const [openUsersList, setOpenUsersList] = useState(false);
  const dispatch = useAppThunkDispatch();
  const { levelOfInterview, selectedNotificationData } = useAppSelector((state) => state.shared);
  const { userList, userListLoading, count } = useAppSelector((state) => state.levelOfInterview);

  const dropDownRef = React.createRef<HTMLDivElement>();

  const hookForm = useForm<ILevelForm>({
    defaultValues: {
      levelValue: ''
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(LevelOfPanelEditValidation)
  });

  const {
    setValue,
    control,
    setError,
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, errors }
  } = hookForm;

  const { showDropdown, showEditModal, showDeleteModal } = levelItemState;
  const { ellipsis } = Images;

  const isLevelItemIdEqual = selectedNotificationData?.id === levelItem?.id;

  const submitButtonDisableCondition =
    !isValid || isSubmitting || levelItem.level === hookForm.watch('levelValue');

  const handleDropdown = (event: React.MouseEvent<HTMLImageElement>) => {
    event?.stopPropagation();
    setLevelItem({ ...levelItemState, showDropdown: !levelItemState.showDropdown });
  };

  const handleEditModal = () => {
    setLevelItem({
      ...levelItemState,
      showEditModal: !showEditModal,
      showDropdown: false
    });
  };
  const handleDeleteModal = () => {
    setErrorModal(false);
    levelItem?.numberOfPanel
      ? null
      : setLevelItem({
          ...levelItemState,
          showDeleteModal: !showDeleteModal,
          showDropdown: false
        });
  };

  const editLevelOfInterview: SubmitHandler<ILevelForm> = async (values: any) => {
    let isLevelExist = levelOfInterview?.filter(
      (interviewLevel) =>
        interviewLevel?.level?.toUpperCase()?.trim() ===
        hookForm
          .watch('levelValue')
          ?.replace(/\s{2,}/g, ' ')
          ?.trim()
          ?.toUpperCase()
    ).length;
    if (levelItem.level === hookForm.getValues('levelValue')) {
      isLevelExist = 0;
    }
    if (isLevelExist) {
      setError('levelValue', {
        type: 'onChange',
        message: LEVEL_OF_PANEL_EXISTS
      });
    } else {
      const data = await dispatch(
        editLevel({
          id: levelItem?.id,
          level: values.levelValue?.replace(/\s{2,}/g, ' ')?.trim()
        })
      ).unwrap();
      if (!data?.error) {
        dispatch(getLOI());
        handleEditModal();
      }
    }
  };

  const closeDropdown = useCallback(() => {
    if (showDropdown) {
      setLevelItem((prevState) => ({ ...prevState, showDropdown: false }));
    }
  }, [showDropdown, showEditModal]);

  const handleEnterKeyEvent = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.keyCode === 13) {
      if (isValid) {
        return;
      } else {
        event.preventDefault();
      }
    }
  };

  const onDeleteItem = async () => {
    onDeleteThroughCard(levelCard);
    setLevelItem({ ...levelItemState, showDeleteModal: false });
  };

  const changeFieldValue = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('levelValue', `${event.currentTarget.value}`);
  };

  const getLevelModal = () => {
    return (
      <Modal
        header={EDIT_LOI_TITLE}
        customStyle="edit-modal"
        onClose={handleEditModal}
        isOpen={levelItemState?.showEditModal}>
        <form onSubmit={handleSubmit(editLevelOfInterview)}>
          <div className="edit-modal-content">
            <div className="levels-form">
              <div>
                <label htmlFor={LEVEL_NAME} className="delete-modal--level-label">
                  {LEVEL}
                </label>
                <Controller
                  control={control}
                  name="levelValue"
                  render={({ field: { value } }: any) => (
                    <Input
                      {...register('levelValue', {
                        required: true,
                        onChange: changeFieldValue
                      })}
                      autoFocus
                      type="text"
                      value={value}
                      autoComplete="off"
                      onKeyDown={(e) => handleEnterKeyEvent(e)}
                    />
                  )}
                />
                {errors.levelValue && (
                  <div className="error-message">{errors.levelValue.message}</div>
                )}
              </div>
            </div>
            <div className="edit-modal-footer">
              <Button type="button" customStyle="edit-modal--cancel-btn" onClick={handleEditModal}>
                {CANCEL_BUTTON_TEXT}
              </Button>
              <Button
                type="submit"
                variant="contained"
                customStyle="edit-modal--save-btn"
                id={levelItem.id.toString()}
                disabled={submitButtonDisableCondition}>
                {SAVE_BUTTON_TEXT}
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    );
  };

  const getLevelDeleteModal = () => {
    return (
      <Modal
        header={DELETE_LOI_TITLE}
        customStyle="edit-modal"
        onClose={handleDeleteModal}
        isOpen={levelItemState?.showDeleteModal}>
        <div className="edit-modal-content">
          <Typography variant="text" customStyle="delete-modal-para">
            {DELETE_LOI_DESCRIPTION}
          </Typography>
          <div className="edit-modal-footer">
            <Button customStyle="edit-modal--cancel-btn" onClick={handleDeleteModal}>
              {CANCEL_BUTTON_TEXT}
            </Button>
            <Button
              customStyle="edit-modal--save-btn"
              variant="contained"
              id={levelItem.level}
              onClick={onDeleteItem}>
              {DELETE_BUTTON_TEXT}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const getList = (id: number) => {
    dispatch(userListLevel(id));
  };

  const getUsersListModal = () => {
    return (
      <Modal customStyle="user-list-modal" onClose={handleUsersList} isOpen={openUsersList}>
        <div className="edit-modal-content">
          <UserList
            title={levelItem?.level?.toString()}
            onClose={handleUsersList}
            id={levelItem?.id}
            totalCount={count}
            listData={userList}
            isLoading={userListLoading}
            listType="level"
            handleGetList={getList}
          />
        </div>
      </Modal>
    );
  };

  const showDropDown = () => {
    return (
      <div className="dropdown">
        <div className="dropdown-option" onClick={handleEditModal}>
          <span>{EDIT_BUTTON_TEXT}</span>
        </div>
        <div className="divider"></div>
        <div
          className={levelItem.numberOfPanel > 0 ? 'dropdown-option-disabled' : 'dropdown-option'}
          onClick={handleDeleteModal}>
          <span>{DELETE_BUTTON_TEXT}</span>
        </div>
      </div>
    );
  };

  const handleUsersList = () => {
    setOpenUsersList(!openUsersList);
  };

  const haveNotificationInView = () => {
    if (selectedNotificationData?.id) {
      document
        .getElementById(selectedNotificationData?.id)
        ?.scrollIntoView({ behavior: 'auto', block: 'center' });
      setTimeout(() => {
        dispatch(setSelectedNotificationData({ id: '' }));
      }, PULSE_ANIMATION_TIMEOUT);
    }
  };

  const updateLevelValue = () => {
    if (levelItemState?.showEditModal) {
      setValue('levelValue', `${levelItem.level}`);
    }
  };

  useEffect(() => {
    haveNotificationInView();
    return () => {
      dispatch(setSelectedNotificationData({ id: '' }));
    };
  }, []);

  useEffect(() => {
    updateLevelValue();
  }, [levelItemState.showEditModal]);

  return (
    <Fragment>
      <div
        className={`levels-item ${isLevelItemIdEqual ? 'selected-level' : ''}`}
        id={isLevelItemIdEqual ? levelItem?.id : ''}>
        <div className="left-section">
          <Typography variant="title" className="levels--name">
            {levelItem?.level?.toString()}
          </Typography>
          {levelItem?.level?.length > 10 && (
            <div className="ts-tooltip">
              {levelItem?.level?.toString()}
              <span className="ts-tooltip-arrow" />
            </div>
          )}
        </div>

        <div className="levels--detail">
          <Typography variant="text" customStyle="levels--header">
            {NUMBER_OF_PANEL}
          </Typography>
          <span onClick={handleUsersList}>
            <Typography variant="text" customStyle="levels--sub-detail">
              {levelItem?.numberOfPanel}
            </Typography>
          </span>
        </div>
        <div className="level--dropdown">
          <ClickAwayListener handleClose={closeDropdown}>
            <div className="dots" ref={dropDownRef}>
              {levelItem?.isZohoRecord ? (
                <div className="zoho-label">
                  <span className="zoho-tag">Z</span>
                </div>
              ) : (
                <ImageComponent
                  src={ellipsis}
                  alt="3-dots"
                  onClick={handleDropdown}
                  className={`ellipsis ${showDropdown && 'active-btn-ellipsis'}`}
                />
              )}
            </div>
            {levelItemState?.showDropdown && showDropDown()}
          </ClickAwayListener>
        </div>
      </div>

      {getLevelModal()}
      {getLevelDeleteModal()}
      {getUsersListModal()}
    </Fragment>
  );
}

export default LevelItem;
