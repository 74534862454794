import React from 'react';
import { Column } from 'react-table';
import RowCreator from '../../../../pages/Panel/components/rowCreator';
import RecruiterRowCreator from '../RecruiterRow';
import { EMAIL, LEVELCASE, NAME, TECH } from '../../../../../helpers/constants';

export const PANELTABNAME = 'Panelists';
export const RECRUITERTABNAME = 'Recruiters';
export const PROJECTTYPE = 'Project';
export const LEVELTYPE = 'Level';

export const columns: Column[] = [
  {
    Header: (
      <div key={NAME} className="panel-header-cell ap-name-header-cell">
        Name
      </div>
    ),
    accessor: 'firstName',
    Cell: (item) => {
      return (
        <div key={NAME} className="panel-row-cell">
          <RowCreator component={NAME} panel={item?.row?.original} />
        </div>
      );
    }
  },
  {
    Header: (
      <div key={LEVELCASE} className="panel-header-cell">
        Level of Panel
      </div>
    ),
    accessor: 'techStackInterviewLevelSet',
    Cell: (item) => {
      return (
        <div key={LEVELCASE} className="panel-row-cell panel-cell-tech-stack">
          <RowCreator component={LEVELCASE} panel={item?.row?.original} />
        </div>
      );
    }
  }
];

export const columnsRecruiter: Column[] = [
  {
    Header: (
      <div key={NAME} className="recruiter-header-cell recruiter-first-header">
        Name
      </div>
    ),
    accessor: 'firstName',
    Cell: (item) => {
      return (
        <div key={NAME} className="recruiter-row-cell recruiter-first-row-cell">
          <RecruiterRowCreator componentType={NAME} recruiter={item?.row?.original} />
        </div>
      );
    }
  },
  {
    Header: (
      <div key={EMAIL} className="recruiter-header-cell">
        Email Address
      </div>
    ),
    accessor: 'email',
    Cell: (item) => {
      return (
        <div key={EMAIL} className="recruiter-row-cell recruiter-gray-text">
          <RecruiterRowCreator componentType={EMAIL} recruiter={item?.row?.original} />
        </div>
      );
    }
  }
];
export const projectColumns: Column<any>[] = [
  {
    Header: (
      <div key={NAME} className="panel-header-cell ap-name-header-cell">
        Name
      </div>
    ),
    accessor: 'firstName',
    Cell: (item) => {
      return (
        <div key={NAME} className="panel-row-cell">
          <RowCreator component={NAME} panel={item?.row?.original} />
        </div>
      );
    }
  },
  {
    Header: (
      <div key={LEVELCASE} className="panel-header-cell">
        Level of Panel
      </div>
    ),
    accessor: 'techStackInterviewLevelSet',
    Cell: (item) => {
      return (
        <div key={LEVELCASE} className="panel-row-cell panel-cell-tech-stack">
          <RowCreator component={LEVELCASE} panel={item?.row?.original} />
        </div>
      );
    }
  }
];
export const levelColumns: Column[] = [
  {
    Header: (
      <div key={NAME} className="panel-header-cell ap-name-header-cell">
        Name
      </div>
    ),
    accessor: 'firstName',
    Cell: (item) => {
      return (
        <div key={NAME} className="panel-row-cell">
          <RowCreator component={NAME} panel={item?.row?.original} />
        </div>
      );
    }
  },
  {
    Header: (
      <div key={TECH} className="panel-header-cell">
        Tech Stack
      </div>
    ),
    accessor: 'techStackInterviewLevelSet',
    Cell: (item) => {
      return (
        <div key={TECH} className="panel-row-cell panel-cell-tech-stack">
          <RowCreator component={TECH} panel={item?.row?.original} showLOP={false} />
        </div>
      );
    }
  }
];
