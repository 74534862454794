import React, { useEffect, useState } from 'react';
import './index.style.scss';
import Typography from '../../../../core/shared-components/typography';
import ImageComponent from '../../../../core/shared-components/image';
import Images from '../../../../../assets/images';
import moment, { Moment } from 'moment';
import { useAppSelector, useAppThunkDispatch } from '../../../../redux/hooks';
import { getSlotInfo } from '../../../../redux/slices/scheduleSlice';
import Loader from '../../../../core/shared-components/loader';
import {
  CANCELLATION_OPTION,
  CANDIDATE_BUSY,
  OTHER_BUSY,
  PANEL_BUSY
} from '../../../../../helpers/constants';

interface ISlotCancelledPopupProps {
  slotId: string | number;
  onClose?: () => void;
}

interface ISlotCancelledStates {
  slotStart: Moment;
  slotEnd: Moment;
  candidateName: string;
  cancelledBy: string;
  techStack: string;
  level: string;
  cancellationReason: string;
  cancelComments: string;
}

function SlotCancelledPopup(props: ISlotCancelledPopupProps) {
  const { onClose } = props;
  const [slotCancelState, setSlotCancelState] = useState<ISlotCancelledStates>({
    slotStart: moment(),
    slotEnd: moment(),
    candidateName: '',
    cancelledBy: '',
    techStack: '',
    level: '',
    cancellationReason: '',
    cancelComments: ''
  });
  const reduxScheduleState = useAppSelector((state) => state.schedule);
  const dispatch = useAppThunkDispatch();

  const {
    slotStart,
    slotEnd,
    candidateName,
    techStack,
    level,
    cancellationReason,
    cancelledBy,
    cancelComments
  } = slotCancelState;

  useEffect(() => {
    (async () => {
      const { slotId } = props;
      const response = await dispatch(getSlotInfo(slotId));
      if (response.payload.data) {
        const { data } = response.payload;
        let cancelReason = PANEL_BUSY;
        if (
          data?.cancelReason?.toString()?.toUpperCase() ===
          CANCELLATION_OPTION.candidate.toUpperCase()
        )
          cancelReason = CANDIDATE_BUSY;
        else if (
          data?.cancelReason?.toString()?.toUpperCase() === CANCELLATION_OPTION.other.toUpperCase()
        )
          cancelReason = OTHER_BUSY;
        setSlotCancelState({
          slotStart: moment(data.fromTime),
          slotEnd: moment(data.toTime),
          candidateName: data.candidateName,
          cancelledBy: data.cancelledBy,
          cancellationReason: cancelReason,
          techStack: data.techStackInterviewLevelDto.techStack,
          level: data.techStackInterviewLevelDto.level,
          cancelComments: data.cancelComments
        });
      }
    })();
  }, [props.slotId]);

  return (
    <div className="slot-cancelled-popup">
      <div className="slot-cancelled-header">
        <div className="slot-cancel-time-wrapper">
          <Typography customStyle="slot-cancelled-time-label">{`${slotStart.format(
            'Do MMMM YYYY , hh:mmA'
          )} - ${slotEnd.format('hh:mmA')}`}</Typography>
        </div>
        <div className="slot-icon-wrapper" onClick={onClose}>
          <ImageComponent src={Images.close} alt={'Close'} />
        </div>
      </div>
      <div className="slot-cancelled-main">
        <div className="slot-cancelled-candidate-wrapper">
          <div className="slot-cancelled-name-wrapper">
            <Typography customStyle="slot-candidate-label">Candidate name :</Typography>
            <Typography customStyle="slot-candidate-name-label">{candidateName}</Typography>
          </div>
          <div className="slot-cancelled-tech-stack-tag">
            <Typography customStyle="slot-cancelled-tech-stack-label">{`${techStack} - ${level}`}</Typography>
          </div>
        </div>
        <div className="slot-cancelled-by-wrapper">
          <Typography customStyle="slot-cancelled-label">Marked as Cancelled By</Typography>
          <div className="slot-cancelled-name-wrapper">
            <Typography customStyle="slot-candidate-label">{cancelledBy}</Typography>
          </div>
        </div>
        <div className="slot-cancelled-reason-wrapper">
          <Typography customStyle="slot-cancelled-reason-label">Reason For Cancellation</Typography>
          <Typography customStyle="slot-cancelled-reason">{cancellationReason}</Typography>
        </div>
        <div className="slot-cancelled-reason-wrapper">
          <Typography customStyle="slot-cancelled-reason-label">Remark</Typography>
          <Typography customStyle="slot-cancelled-reason">{cancelComments}</Typography>
        </div>
      </div>
      <Loader loading={reduxScheduleState.cancelledPopupLoading} customClass="loading-wrapper" />
    </div>
  );
}

export default SlotCancelledPopup;
