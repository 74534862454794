import React from 'react';
import './index.style.scss';
import SlotDetailContainer, { SlotStrip } from '.';
import { Moment } from 'moment';
import { timeStamps } from 'helpers/timeStamp.constants';

interface ISlotCancelledEvents {
  start: Moment;
  end: Moment;
}

interface ISlotCancelled {
  events: ISlotCancelledEvents[];
}

function SlotCancelledContainer(props: ISlotCancelled) {
  const { events } = props;
  return (
    <SlotDetailContainer title="Cancelled Slots">
      {events.map((item, index) => (
        <SlotStrip
          key={index}
          title={`${item.start.format(timeStamps.HH_MM_A)} - ${item.end.format(
            timeStamps.HH_MM_A
          )}`}
          borderColor="RED"
        />
      ))}
    </SlotDetailContainer>
  );
}

export default SlotCancelledContainer;
