import React, { useCallback, useLayoutEffect, useState } from 'react';
import './index.style.scss';
import SlotDetailContainer, { SlotStrip } from '.';
import { Moment } from 'moment';
import moment from 'moment';
import _ from 'lodash';
import SlotEdit from './slot-edit';
import { BLUE } from 'helpers/constants';
import { timeStamps } from 'helpers/timeStamp.constants';

export interface IEvents {
  id: string | number;
  start: Moment;
  end: Moment;
  editEnabled?: boolean;
}

interface ISlotAvailableProps {
  events: IEvents[];
  onDeleteClick?: (param: IEvents) => void;
  onSaveClick?: (param: IEvents) => void;
}

function SlotAvailableContainer(props: ISlotAvailableProps) {
  const [slotState, setSlotState] = useState<IEvents[]>([]);

  useLayoutEffect(() => {
    setSlotState([...props.events]);
  }, [props.events]);

  const handleDeleteClick = useCallback(
    (param: IEvents) => () => {
      const { onDeleteClick } = props;
      if (param.end.isAfter(moment())) onDeleteClick && onDeleteClick(param);
    },
    [props.onDeleteClick]
  );

  const handleEditClick = useCallback(
    (param: IEvents) => () => {
      const currentState = _.cloneDeep(slotState);
      const data = currentState.findIndex((item) => item.id.toString() === param.id.toString());
      if (data !== -1) {
        currentState[data].editEnabled = !currentState[data].editEnabled;
        setSlotState(currentState);
      }
    },
    [slotState]
  );

  const handleSaveClick = useCallback(
    (param: IEvents) => (slotStart: Moment, slotEnd: Moment) => {
      const { events, onSaveClick } = props;
      const data = {
        id: param.id,
        start: slotStart,
        end: slotEnd
      };
      setSlotState([...events]);
      onSaveClick && onSaveClick(data);
    },
    [props.onSaveClick, slotState]
  );

  return (
    <SlotDetailContainer title="Available Slots">
      {slotState.map((item) => {
        if (item.editEnabled)
          return (
            <SlotEdit
              key={item.id}
              start={item.start}
              end={item.end}
              onCancelClick={handleEditClick(item)}
              onSaveClick={handleSaveClick(item)}
            />
          );
        return (
          <SlotStrip
            key={item.id}
            title={`${item.start.format(timeStamps.HH_MM_A)} - ${item.end.format(
              timeStamps.HH_MM_A
            )}`}
            showDeleteIcon={item.end.isAfter(moment())}
            onDeleteClick={handleDeleteClick(item)}
            showEditIcon={item.end.isAfter(moment())}
            onEditClick={handleEditClick(item)}
            borderColor={BLUE}
          />
        );
      })}
    </SlotDetailContainer>
  );
}

export default SlotAvailableContainer;
