import axios from 'axios';
import { getDataFromSession, decode } from '../../helpers/authHelper';
const token = decode(sessionStorage.getItem('access-token') || '');

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: `Bearer ${token}` }
});

service.interceptors.request.use((config) => {
  const token = decode(getDataFromSession('access-token') || '');
  config.headers = {
    Authorization: `Bearer ${token}`
  };
  return config;
});

service.interceptors.response.use(
  (value: any) => Promise.resolve(value),
  (error: any) => {
    if (error.code === 'ERR_NETWORK') {
      const data = {
        data: null,
        error: {
          code: 'ERR_NETWORK',
          message: error.message
        }
      };
      error.response.data = data;
      return Promise.reject(error);
    } else if (error.response.data.code === 1002 && error?.response?.status === 401) {
      window.location.href = '/authError';
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default service;
