import React, { ChangeEvent, Component, HTMLAttributes, useEffect, useRef, useState } from 'react';
import ImageComponent from 'app/core/shared-components/image';
import './index.style.scss';

interface IInputProps extends HTMLAttributes<HTMLInputElement> {
  customClass?: string;
  startIcon?: string;
  endIcon?: string;
  value?: string | number;
  disabled?: boolean;
  multiline?: boolean;
  handleChange?: (value: string | number) => void;
  onStartIconClick?: () => void;
  onEndIconClick?: () => void;
  autoFocus?: boolean;
  pattern?: string;
}

function InputBox(props: IInputProps) {
  const {
    customClass,
    startIcon,
    endIcon,
    onStartIconClick,
    onEndIconClick,
    value,
    disabled,
    multiline,
    handleChange,
    placeholder,
    autoFocus,
    pattern,
    ...otherProps
  } = props;
  const [customInputWrapperClass, setCustomInputWrapperClass] = useState<string>(
    props.disabled ? 'input-wrapper disabled' : 'input-wrapper '
  );

  function onFocus() {
    setCustomInputWrapperClass('input-wrapper focus');
  }
  function onBlur() {
    setCustomInputWrapperClass('input-wrapper');
  }

  function handleInputChange(
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const value = event?.target?.value;
    props.handleChange && props.handleChange(value);
  }
  const inputElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) {
      inputElement.current?.focus();
    }
  }, []);

  return (
    <div className={`${customClass} ${customInputWrapperClass}`}>
      {startIcon && (
        <div className="start-icon" onClick={onStartIconClick}>
          <ImageComponent src={startIcon} className="icon" />
        </div>
      )}
      {multiline ? (
        <textarea
          className="input-field"
          disabled={disabled}
          value={value}
          onChange={handleInputChange}
          rows={2}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          autoFocus
        />
      ) : (
        <input
          className="input-field"
          {...otherProps}
          disabled={disabled}
          value={value}
          onChange={handleInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          tabIndex={0}
          autoFocus={autoFocus}
          pattern={pattern}
          ref={inputElement}
          placeholder={placeholder}
        />
      )}
      {endIcon && (
        <div className="end-icon" onClick={onEndIconClick}>
          <ImageComponent src={endIcon} />
        </div>
      )}
    </div>
  );
}

export default InputBox;
