import React, { Fragment, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { uniqueId } from 'lodash';
import Images from 'assets/images';
import { isAdmin } from 'helpers/authHelper';
import {
  BOTH,
  CANCEL,
  DASHBOARD,
  DELETE,
  DELETE_PANEL,
  MAX_NOTIFICATION_COUNT,
  PLUS_NOTIFICATION,
  ROLE_ADMIN,
  SEARCH
} from 'helpers/constants';
import { notify } from 'helpers/toastHelper';
import ConfirmationModal from 'app/core/shared-components/confirmation-modal';
import { ISelectOptions } from 'app/core/shared-components/customSelect';
import Drawer from 'app/core/shared-components/drawer';
import ImageComponent from 'app/core/shared-components/image';
import Loader from 'app/core/shared-components/loader';
import Search, { IOptionType } from 'app/core/shared-components/search';
import ToastUndo from 'app/core/shared-components/toast-undo';
import UsersTab from 'app/core/shared-components/users-section-tab';
import GetLDeleteModalEdgeCase from 'app/core/shared-components/users-section-tab/utils';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  clearSearchBarValue,
  getEmployeeCount,
  getUserData,
  handleSearch,
  resetPage,
  resetSearchBarValue,
  searchSelected,
  toggleDrawerVisibility,
  toggleTechDrawerVisibility
} from 'app/redux/slices/adminDashboardSlice';
import { setLoading } from 'app/redux/slices/panelSlice';
import { fetchAvailableSlots } from 'app/redux/slices/slotsSlice';
import { deletePanelService } from 'app/services/panel.service';
import {
  IDashboardSearchDataType,
  IRoleListType,
  IUserDataByIdType
} from 'app/services/user-response.types';
import ErrorPage from '../../Error';
import DashboardLayout from '../components/dashboard-layout';
import EmployeeSummary from '../components/employee-summary';
import HomeDrawer from '../components/home-drawer';
import { ITechStackInterviewLevelDtos } from '../components/home-drawer/techStacks';
import NotificationDrawer from '../components/notification-drawer';
import PanelDrawer from '../components/panel-drawer';
import RewardsWidget from '../components/rewards-widget';
import SlotEfficiency from '../components/slot-efficiency';
import './index.style.scss';
import { ADMIN, RECRUITER } from 'app/redux/constant';
import TechDrawer from '../components/tech-drawer';
import { DELETE_MODAL_DESCRIPTION } from 'helpers/messages.constants';
import { ISTATUSFIELD } from 'helpers/types';
import { getExperience } from 'helpers/utils';

export interface ISelectedPanel {
  role: ISelectOptions | null;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  designation: string;
  totalExperience: string;
  stackLoi: {
    firstChipValue: ISelectOptions;
    secondChipValue: ISelectOptions;
  }[];
  techStack: ISelectOptions[];
  projects: ISelectOptions[];
  mobileNo: string;
  profileImageUrl: string;
  addedOn: string;
  statusEnum: ISTATUSFIELD | undefined;
  isDashboard: boolean;
}

interface IDashboardStates {
  options: IOptionType[];
  resultNotFound: boolean;
  selectedPanel: ISelectedPanel;
  isNotificationOpen: boolean;
}

export interface INonDeletedUserArray {
  firstName: string;
  lastName: string;
  designation: string;
  profileImageUrl: string;
}

const WIDGETS: any = {
  admin: [
    {
      column: 1,
      components: [<SlotEfficiency key={uniqueId()} />]
    },
    {
      column: 2,
      components: [
        <div key={uniqueId()} className="rewards-interview">
          <RewardsWidget />
          <EmployeeSummary />
        </div>
      ]
    },
    {
      column: 3,
      components: [<UsersTab key={uniqueId()} />]
    }
  ]
};
const AdminDashboard = () => {
  const [widget, setWidget] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [nonDeletedUserModal, setNonDeletedUserModal] = useState<boolean>();
  const [nonDeletedUserArray, setNonDeletedUserArray] = useState<INonDeletedUserArray[]>();
  const [dashBoardStates, setDashboardStates] = useState<IDashboardStates>({
    options: [],
    resultNotFound: false,
    selectedPanel: {} as ISelectedPanel,
    isNotificationOpen: false
  });
  const [userRole, setUserRole] = useState(RECRUITER);
  const [isEditingOn, setIsEditingOn] = useState(false);

  const timeOut = useRef<any>(null);
  const abortSignal = useRef<any>(null);

  const { techStack, recruiter, panel } = useAppSelector((state) => state?.shared);
  const { isDrawerOpen, mountHome, clearResult, isTechDrawerOpen, isSearchError } = useAppSelector(
    (state) => state?.adminDashboard
  );
  const { notificationCount } = useAppSelector((state) => state?.shared);
  const { firstName, isLoading, role } = useAppSelector((state) => state?.user?.userData);
  const { isFirstLogin } = useAppSelector((state) => state.login);

  const dispatch = useAppThunkDispatch();

  const { options, resultNotFound, selectedPanel, isNotificationOpen } = dashBoardStates;

  const { notificationIcon } = Images;

  async function handleSearchAction(searchString: string) {
    setDashboardStates((prevState) => ({
      ...prevState,
      options: []
    }));
    if (resultNotFound) {
      setDashboardStates((prevState) => ({
        ...prevState,
        resultNotFound: false
      }));
    }
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
    if (!!searchString?.trim()?.length) {
      timeOut.current = setTimeout(async () => {
        if (abortSignal.current) abortSignal.current.abort();
        abortSignal.current = new AbortController();
        const signal = abortSignal.current;
        const response = await dispatch(handleSearch({ searchString, signal }));
        const data = response?.payload?.data as IDashboardSearchDataType;
        if (!response?.payload?.error) {
          const options = data?.userSet?.map((user) => {
            return {
              startIcon: user?.profileImageUrl,
              searchKey: user?.fullName,
              supportingData: user?.designation,
              id: user?.userId,
              singleSearchData: user
            };
          });

          setDashboardStates((prevState) => ({
            ...prevState,
            options: [...options],
            resultNotFound: false
          }));
        } else if (response?.payload?.error && response?.payload?.error[0]?.code === 1008) {
          setDashboardStates((prevState) => ({
            ...prevState,
            resultNotFound: true
          }));
        }
      }, 1000);
    } else {
      setDashboardStates((prevState) => ({ ...prevState, options: [] }));
    }
  }

  function generateRole(array: IRoleListType[]) {
    const roles = array?.filter((role) => role?.id == recruiter?.id || role?.id == panel?.id);
    const currentRole = roles[0]?.id === recruiter?.id ? recruiter : panel;
    const role = roles?.length === 2 ? { id: 0, label: BOTH } : currentRole;
    return role;
  }

  function searchSelection(id: string | number, searchSingleData: any) {
    setDashboardStates((prevState) => ({
      ...prevState,
      isSearchedOptionSelected: true
    }));
    dispatch(resetSearchBarValue());
    dispatch(searchSelected(searchSingleData));
    if (searchSingleData?.stack) {
      dispatch(toggleTechDrawerVisibility());
    } else if (searchSingleData?.roles?.includes('Panel')) {
      dispatch(getUserData(id)).then(({ payload }) => {
        const userDataById = payload?.data as IUserDataByIdType;
        const tempRole = generateRole(userDataById.roles || []);
        const tempProject: ISelectOptions[] = userDataById.projects?.map((item) => ({
          id: item?.id,
          label: item?.name
        }));
        const tempStackLoi = userDataById.techStackInterviewLevelDtos
          ?.filter((techLevel: ITechStackInterviewLevelDtos) => techLevel.roleId == 2)
          .map((item: ITechStackInterviewLevelDtos) => ({
            firstChipValue: { id: item?.techStackId, label: item?.techStack },
            secondChipValue: { id: item?.levelId, label: item?.level }
          }));
        const temptechStack: ISelectOptions[] = userDataById.techStackInterviewLevelDtos
          ?.filter((techLevel: ITechStackInterviewLevelDtos) => techLevel.roleId == 3)
          .map((item: ITechStackInterviewLevelDtos) => ({
            id: item?.techStackId,
            label: item?.techStack
          }));
        const selectedPanelData = {
          role: tempRole,
          id: userDataById?.userId,
          firstName: userDataById?.firstName,
          lastName: userDataById?.lastName,
          email: userDataById?.emailId,
          designation: userDataById?.designation,
          totalExperience:
            (userDataById?.totalExperience && getExperience(userDataById?.totalExperience)) || '',
          stackLoi: [...tempStackLoi],
          techStack: [...temptechStack],
          projects: [...tempProject],
          mobileNo: userDataById?.mobileNo,
          profileImageUrl: userDataById?.profileImageUrl,
          addedOn: '',
          statusEnum: userDataById?.statusEnum,
          isDashboard: true
        };
        setIsEditingOn(true);
        dispatch(
          fetchAvailableSlots({
            userId: searchSingleData?.userId,
            dateTime: []
          })
        );
        setDashboardStates((prevState) => ({
          ...prevState,
          showPanelSlots: true,
          selectedPanel: selectedPanelData
        }));
      });
    } else {
      dispatch(getUserData(id));
      setDashboardStates((prevState) => ({
        ...prevState,
        resultCleared: true
      }));
      dispatch(toggleDrawerVisibility());
    }
  }

  const handlCloseDrawer = () => {
    setIsEditingOn(false);
    dispatch(clearSearchBarValue());
  };
  const handleDelete = () => {
    setDeleteModal(true);
  };
  const deletePanelData = async () => {
    dispatch(setLoading(true));
    const deleteSuccessFul = await deletePanelService([selectedPanel?.id]);
    if (deleteSuccessFul.data) {
      dispatch(clearSearchBarValue());
      setIsEditingOn(false);
      const userRole = role.map((item) => item.role).includes(ROLE_ADMIN);
      userRole && dispatch(getEmployeeCount());
    } else {
      setIsEditingOn(false);
      dispatch(clearSearchBarValue());
      setNonDeletedUserModal(true);
      setNonDeletedUserArray(deleteSuccessFul?.error[0]?.data);
    }
    dispatch(setLoading(false));
  };
  const handleCloseSingleDelete = () => {
    setDeleteModal(false);
  };
  const deleteConfirmationClick = () => {
    setDeleteModal(false);
    setIsEditingOn(false);
    dispatch(clearSearchBarValue());
    notify(
      false,
      <ToastUndo
        toastMessage={`Successfully deleted ${selectedPanel?.firstName} ${selectedPanel?.lastName}`}
        handleUndo={onUndoDelete}
        toastId={parseInt(selectedPanel?.id)}
      />,
      parseInt(selectedPanel?.id),
      deletePanelData
    );
  };
  const handleCloseModal = () => {
    setNonDeletedUserModal(false);
  };
  function onUndoDelete(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const id = parseInt(event.currentTarget.id);
    toast.dismiss(id);
    toast.update(id, { onClose: () => {}, hideProgressBar: true });
  }

  const openNotificationDrawer = () => {
    setDashboardStates((prevState) => ({
      ...prevState,
      isNotificationOpen: true
    }));
  };

  const closeNotificationDrawer = () => {
    setDashboardStates((prevState) => ({
      ...prevState,
      isNotificationOpen: false
    }));
  };

  const updateuserRole = () => {
    const userRole = role.map((item) => item.role);
    if (isAdmin(userRole)) {
      setUserRole(ADMIN);
    } else {
      setUserRole(RECRUITER);
    }
  };

  const resetSearch = () => {
    if (mountHome) {
      setDashboardStates((prevState) => ({
        ...prevState,
        isSearchedOptionSelected: false
      }));
      dispatch(resetSearchBarValue());
    }
  };

  useEffect(() => {
    updateuserRole();
  }, [role]);

  useEffect(() => {
    resetSearch();
  }, [techStack]);

  useEffect(() => {
    return () => {
      dispatch(resetPage());
      toast.dismiss();
    };
  }, []);

  useEffect(() => {
    setWidget(WIDGETS[userRole]);
  }, [userRole]);
  return isSearchError ? (
    <ErrorPage page="apiFailure" />
  ) : isLoading ? (
    <Loader loading={isLoading} />
  ) : (
    <div className="dashboard-page">
      <div className="slots-header">
        <div className="header-container">
          <div className="header-title">{isFirstLogin ? `Welcome ${firstName}` : DASHBOARD}</div>
        </div>
        <div className="actions-container">
          <div
            className={`notifications ${isNotificationOpen ? 'notifications-active' : ''}`}
            onClick={openNotificationDrawer}>
            {!!Number(notificationCount) && (
              <div
                className={
                  notificationCount > MAX_NOTIFICATION_COUNT
                    ? 'count-increased notification-count'
                    : 'notification-count'
                }>
                {notificationCount > MAX_NOTIFICATION_COUNT ? PLUS_NOTIFICATION : notificationCount}
              </div>
            )}
            <ImageComponent src={notificationIcon} />
          </div>
          <div className="search-bar">
            <Search
              placeholder={SEARCH}
              onChange={handleSearchAction}
              setSelectedId={searchSelection}
              options={options}
              customDropdownIconClass="search-circle"
              clearResult={clearResult}
              resultNotFound={resultNotFound}
            />
          </div>
        </div>
      </div>
      <Fragment>
        <DashboardLayout layout={widget} />
      </Fragment>
      <PanelDrawer
        selectedPanel={selectedPanel}
        isEditingOn={isEditingOn}
        handlCloseDrawer={handlCloseDrawer}
        handleDelete={handleDelete}
      />
      <HomeDrawer isDrawerOpen={isDrawerOpen} />

      <TechDrawer isDrawerOpen={isTechDrawerOpen} />

      <div className="notification-drawer-container">
        <Drawer
          isOpen={isNotificationOpen}
          onClose={closeNotificationDrawer}
          headerProps="Notifications"
          headerStyles="notification-header"
          bodyStyles="notification-body"
          hideDivider>
          <NotificationDrawer />
        </Drawer>
      </div>

      <GetLDeleteModalEdgeCase
        handleCloseModalDelete={handleCloseModal}
        nonDeletedUserArray={nonDeletedUserArray}
        isOpen={nonDeletedUserModal}
      />
      <ConfirmationModal
        title={DELETE_PANEL}
        description={DELETE_MODAL_DESCRIPTION}
        isOpen={deleteModal}
        onConfirm={deleteConfirmationClick}
        onClose={handleCloseSingleDelete}
        cancelText={CANCEL}
        confirmText={DELETE}
      />
    </div>
  );
};

export default AdminDashboard;
