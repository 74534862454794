import React, { useState, useEffect, SyntheticEvent, forwardRef } from 'react';
import Typography from 'app/core/shared-components/typography';
import { ReactComponent as CheckedIcon } from 'assets/images/checked.svg';
import { ReactComponent as CheckboxIdeal } from 'assets/images/checkboxIdeal.svg';
import './index.style.scss';

interface props {
  checked?: boolean;
  label?: string;
  disabled?: boolean;
  handleClick?: (event: SyntheticEvent, check: boolean) => void;
  customClass?: string;
  id?: string;
  ideal?: boolean;
  tabIndex?: boolean;
}

const CheckBox = forwardRef((props: props) => {
  const {
    checked: check = false,
    label = null,
    disabled = false,
    ideal = false,
    handleClick,
    customClass,
    tabIndex = false,
    ...otherProps
  } = props;
  const [checked, setChecked] = useState(check);

  function handleCheckboxClick(event: SyntheticEvent) {
    const { disabled = false, handleClick } = props;
    if (!disabled) {
      !ideal && setChecked(!checked);
      handleClick && handleClick(event, !checked);
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    const { disabled = false, handleClick } = props;
    if (!disabled && e.keyCode == 13) {
      setChecked(!checked);
      handleClick && handleClick(e, !checked);
    }
  };

  useEffect(() => {
    setChecked(check);
  }, [check]);

  return (
    <div className={customClass}>
      <span
        tabIndex={tabIndex ? 0 : -1}
        className={disabled ? 'checkbox checkbox-disabled' : 'checkbox'}
        onClick={handleCheckboxClick}
        onKeyDown={handleKeyPress}
        {...otherProps}>
        {ideal ? (
          <CheckboxIdeal />
        ) : checked ? (
          <CheckedIcon />
        ) : (
          <div className={`unchecked-box ${disabled ? 'unchecked-box-disabled' : ''}`}></div>
        )}
        {label && (
          <Typography variant="text" customStyle="checkbox-label">
            {label}
          </Typography>
        )}
      </span>
    </div>
  );
});

CheckBox.defaultProps = {
  checked: false,
  disabled: false
};

export default CheckBox;
