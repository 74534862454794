import React, { useState, useEffect } from 'react';
import '../../index.style.scss';
import { HeaderProps } from 'react-big-calendar';
import moment from 'moment';

function CustomMonthHeader(props: HeaderProps) {
  const [monthHeaderState, setMonthState] = useState(moment(props.date));

  useEffect(() => {
    setMonthState(moment(props.date));
  }, [props.date]);

  return (
    <div className="month-header-day-name-container">
      <p className="month-header-day-name">{monthHeaderState.format('ddd').toUpperCase()}</p>
    </div>
  );
}

export default React.memo(CustomMonthHeader);
