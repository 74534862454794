import React, {
  BaseSyntheticEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import moment from 'moment';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { v4 as uuid, v4 as uuidv4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'app/core/shared-components/button';
import ClickAwayListener from 'app/core/shared-components/click-away';
import CustomSelect, { ISelectOptions } from 'app/core/shared-components/customSelect';
import DatePicker from 'app/core/shared-components/date-picker';
import ImageComponent from 'app/core/shared-components/image';
import { projectStyles, techStackStyles } from 'app/core/shared-components/form/index.styles';
import Input from 'app/core/shared-components/input-box/index';
import Modal from 'app/core/shared-components/modal';
import Typography from 'app/core/shared-components/typography';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  fetchAvailableSlots,
  fetchRequestedSlots,
  handleRequestSlot,
  toggleToastVisibility,
  validateRequestedSlot
} from 'app/redux/slices/slotsSlice';
import { IFetchSlotBody } from 'app/services/slots.service';
import Images from 'assets/images';
import DateIcon from 'assets/images/date.svg';
import { ReactComponent as LoaderSpinner } from 'assets/images/loader-spinner.svg';
import CloseIcon from 'assets/images/modal-close-icon.svg';
import WarningIcon from 'assets/images/warning-green.svg';
import {
  DISABLE,
  EIGTH_FIFTEEN,
  EIGTH_THIRTY,
  ENABLE,
  END_TIME,
  FORMAT,
  GA_CATEGORY,
  GA_EVENT_TYPES,
  GA_LABEL,
  LOI_LABEL,
  NEW_REQUEST,
  SLOT_REQUEST_FORM_DATA,
  START_TIME,
  TECH_STACK_LABEL
} from 'helpers/constants';
import { formatTime, getTime } from 'helpers/dateHelper';
import {
  DUPLICATE_SLOT_TIME,
  SLOTS_COINCIDING_ERROR,
  SLOT_REQUESTED_SUCCESSFULLY,
  SLOT_REQUEST_HINT
} from 'helpers/messages.constants';
import { SlotRequestFormValidation } from 'helpers/slotRequestForm.validation';
import { timeStamps } from 'helpers/timeStamp.constants';
import { notify } from 'helpers/toastHelper';
import { handleTabEventwithoutValue, timeFormatter, trackGA } from 'helpers/utils';
import './index.style.scss';

interface ITechstackLOIStates {
  isError: string | null;
  selectCount: number;
  techStackOptions: ISelectOptions[];
  levelOfInterviewOptions: ISelectOptions[];
  currentFilter: IFetchSlotBody;
  isSlotValid: boolean;
}

interface ISlotTime {
  startTime: ISelectOptions;
  endTime: ISelectOptions;
}

interface ISlotFieldArrayProps {
  startTime: string | null;
  endTime: string | null;
}

interface SlotTime {
  startTime: ISelectOptions;
  endTime: ISelectOptions;
}
export interface ISlotTimeData {
  from: string | null;
  to: string | null;
}

interface IFormProps {
  date: any;
  slotTime: SlotTime[] | null;
  selected: {
    firstChipValue: ISelectOptions | null;
    secondChipValue: ISelectOptions | null;
  };
}

interface ISlotRequestFormProps {
  onDrawerClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClose: () => void;
}
const SlotRequestForm = (props: ISlotRequestFormProps) => {
  const [isDatePickerOpen, toggleDatePicker] = useState<boolean>(false);
  const [stackLoi, setStackLoi] = useState<ITechstackLOIStates>({
    isError: null,
    selectCount: 1,
    techStackOptions: [],
    levelOfInterviewOptions: [],
    currentFilter: {} as IFetchSlotBody,
    isSlotValid: true
  });
  const [initialDate, ...restDates] = getTime(new Date());
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const [formData, setFormData] = useState<{
    date: Date | null;
    slotTime: { startTime: ISelectOptions; endTime: ISelectOptions };
  }>({
    date: currentDate,
    slotTime: {
      startTime: initialDate,
      endTime: getTime(new Date())[4] || getTime(new Date())[getTime(new Date()).length - 1]
    }
  });
  const [isTimeInValid, setIsTimeInValid] = useState<boolean>(false);

  const hookForm = useForm<IFormProps>({
    defaultValues: {
      date: new Date(),
      slotTime: [
        {
          startTime: null as unknown as ISelectOptions,
          endTime: null as unknown as ISelectOptions
        }
      ],
      selected: {
        firstChipValue: null,
        secondChipValue: null
      }
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(SlotRequestFormValidation)
  });

  const {
    setValue,
    control,
    setError,
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { isValid, isDirty, errors },
    reset
  } = hookForm;

  const { append, remove, fields, replace } = useFieldArray({
    name: 'slotTime',
    control: control
  });

  const { techStackLevelSet } = useAppSelector((state) => state.user.userData);
  const reduxState = useAppSelector((state) => state.shared);
  const { isError, filterValue, availableSlots, showLoader } = useAppSelector(
    (state) => state.slots
  );

  const dispatch = useAppThunkDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);

  const { trash, plusPrimary } = Images;
  const { onClose, onDrawerClose } = props;

  const [initialSlicedDate, ...restSlicedDates] = getTime(watch('date')).slice(0, -2);
  const { date } = formData;

  const { isSlotValid } = stackLoi;

  const [firstLevelOfInterview, ...rest] = stackLoi.levelOfInterviewOptions;

  const handleDateInputClick = () => {
    toggleDatePicker(!isDatePickerOpen);
    validateSlots();
  };
  const handleDateDropdownClose = useCallback(() => {
    if (isDatePickerOpen) {
      toggleDatePicker(false);
    }
  }, [isDatePickerOpen]);
  const handleDateChange = (date: Date | Date[] | null) => {
    setValue('date', date);
    toggleDatePicker(!isDatePickerOpen);
    setValue('slotTime.0.startTime', initialSlicedDate);
    const [firstValue, ...rest] = getTime(watch('date'))
      ?.filter((time) => {
        return time.id > Number(watch('slotTime.0.startTime')?.id);
      })
      .slice(1);
    setValue('slotTime.0.endTime', firstValue);
    clearErrors('slotTime');
  };

  const handleAddMoreClick = (e: BaseSyntheticEvent) => {
    append({
      startTime: '' as unknown as ISelectOptions,
      endTime: '' as unknown as ISelectOptions
    });
  };
  const checkSlotTime = () => {
    const watchTime = watch('slotTime')?.filter(
      (time: ISlotTime) => typeof time.startTime != 'string' && typeof time.endTime != 'string'
    );
    const slotTimeArray: ISlotTime[] = [];
    watchTime?.forEach((time: any) => {
      slotTimeArray.push({
        startTime: time?.startTime?.id,
        endTime: time?.endTime?.id
      });
    });
    const uniqueArray =
      slotTimeArray &&
      slotTimeArray.reduce<ISlotTime[]>((reducedArray, current) => {
        const stackLOP = reducedArray.find(
          (item: ISlotTime) =>
            item.startTime === current.startTime && item.endTime === current.endTime
        );
        if (!stackLOP) {
          return reducedArray.concat([current]);
        } else {
          return reducedArray;
        }
      }, []);

    const slotTimeArrayLengthCheck = uniqueArray && uniqueArray?.length !== slotTimeArray?.length;

    if (slotTimeArrayLengthCheck) {
      setError('slotTime', { message: DUPLICATE_SLOT_TIME });
    } else clearErrors('slotTime');
  };

  const handleStartTimeChange = (val: ISelectOptions | ISelectOptions[], index: number) => {
    setValue(`slotTime.${index}.startTime`, val as ISelectOptions);
    const endTimeArray = getTime(watch('date')).filter(
      (time) => time.id > Number(watch(`slotTime.${index}.startTime`)?.id)
    );
    if (
      watch(`slotTime.${index}.startTime`)?.label == EIGTH_FIFTEEN ||
      watch(`slotTime.${index}.startTime`)?.label === EIGTH_THIRTY
    ) {
      setValue(`slotTime.${index}.endTime`, endTimeArray[endTimeArray.length - 1]);
    } else setValue(`slotTime.${index}.endTime`, endTimeArray[3]);
    checkSlotTime();
    validateSlots();
  };

  const handleEndTimeChange = (val: ISelectOptions | ISelectOptions[], index: number) => {
    setValue(`slotTime.${index}.endTime`, val as ISelectOptions);
    checkSlotTime();
    validateSlots();
  };

  const handleViewSlot = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    dispatch(fetchAvailableSlots(filterValue));
    onClose();
    onDrawerClose(event);
  };

  const endTimeDropDownOptions = (index: number) => {
    return watch('slotTime')?.filter((slot: any) => typeof slot.startTime === 'string').length
      ? getTime(watch('date'))?.slice(2, -2)
      : getTime(watch('date'))
          .filter((time: ISelectOptions) => {
            return time?.id > watch(`slotTime.${index}.startTime`)?.id;
          })
          .slice(1);
  };

  const handleFormSubmit = () => {
    const dataToTrack = `${watch('selected.firstChipValue.label')} - ${watch(
      'selected.secondChipValue.label'
    )}`;
    trackGA(
      GA_CATEGORY.SLOTS,
      GA_LABEL.SLOTS,
      SLOT_REQUEST_FORM_DATA,
      GA_EVENT_TYPES.FORM_SUBMIT,
      dataToTrack
    );
    const [firstDate, ...rest] = getTime(date);
    if (
      moment(formatTime(firstDate?.label, date || new Date())).isAfter(
        moment(formatTime(getTime(date)[getTime(date).length - 3].label, date || new Date()))
      )
    ) {
      setIsTimeInValid(true);
    }

    if (watch('date') !== null && watch('slotTime')) {
      const slotTimeData: ISlotTimeData[] = [];
      watch('slotTime')?.map((data: ISlotTime, index: number) => {
        const formattedStartTime = formatTime(
          watch(`slotTime.${index}.startTime`)?.label,
          watch('date')
        );

        const formattedEndTime = formatTime(
          watch(`slotTime.${index}.endTime`)?.label,
          watch('date')
        );

        const slotDataToSend = {
          from: formattedStartTime,
          to: formattedEndTime
        };

        slotTimeData.push(slotDataToSend);
      });
      const objectToSend = {
        date: moment(watch('date')).format(timeStamps.YYYY_MM_DD),
        slots: slotTimeData,
        techStackId: watch('selected.firstChipValue.id').toString(),
        interviewLevelId: watch('selected.secondChipValue.id').toString()
      };

      dispatch(handleRequestSlot(objectToSend)).then(() => {
        onClose();
        dispatch(fetchRequestedSlots());
        dispatch(toggleToastVisibility(SLOT_REQUESTED_SUCCESSFULLY));
        notify(true, SLOT_REQUESTED_SUCCESSFULLY, uuidv4().toString(), handleToastClose);
      });
    }
  };
  const handleToastClose = () => {
    dispatch(toggleToastVisibility(''));
  };

  const removeField = (event: SyntheticEvent, index: number) => {
    remove(index);
    checkSlotTime();
  };

  const validateSlots = () => {
    const validCondition =
      watch('selected.firstChipValue')?.id &&
      watch('selected.secondChipValue')?.id &&
      watch('date') !== null &&
      watch('slotTime');
    if (validCondition) {
      const slotTimeData: ISlotTimeData[] = [];
      watch('slotTime')?.map((data: ISlotTime, index: number) => {
        const formattedStartTime = formatTime(
          watch(`slotTime.${index}.startTime`)?.label,
          watch('date')
        );

        const formattedEndTime = formatTime(
          watch(`slotTime.${index}.endTime`)?.label,
          watch('date')
        );

        const slotDataToSend = {
          from: formattedStartTime,
          to: formattedEndTime
        };

        slotTimeData.push(slotDataToSend);
      });
      const objectToSend = {
        date: moment(watch('date')).format(timeStamps.YYYY_MM_DD),
        slots: slotTimeData,
        techStackId: watch('selected.firstChipValue.id')?.toString(),
        interviewLevelId: watch('selected.secondChipValue.id')?.toString()
      };
      setIsTimeInValid(false);
      dispatch(validateRequestedSlot(objectToSend)).then(({ payload }) => {
        if (payload.data) {
          setStackLoi((prevState) => ({ ...prevState, isSlotValid: false }));
        } else {
          setStackLoi((prevState) => ({ ...prevState, isSlotValid: true }));
        }
      });
    }
  };

  const setFormSlotData = () => {
    const data = techStackLevelSet?.map((techStack) => ({
      id: techStack.techStackId,
      label: techStack.techStackName
    }));
    const [firstChipValue, ...rest] = data;
    setValue('selected.firstChipValue', firstChipValue);
    setValue('selected.secondChipValue', firstLevelOfInterview);
    const [firstEndTimeSlice, secondEndTimeSlice, thirdEndTimeSlice, ...restEndTimeSlice] = getTime(
      watch('date')
    )
      .filter((time) => time.id > Number(watch(`slotTime.0.startTime`)?.id))
      .slice(1);
    const slotData = {
      startTime: initialSlicedDate,
      endTime: thirdEndTimeSlice
    };
    replace(slotData);
    setStackLoi((prevState) => ({ ...prevState, techStackOptions: data }));
  };

  const setData = () => {
    const [firstDate, ...rest] = getTime(watch('date'));
    const validCondiiton =
      watch('date') != null &&
      watch('slotTime') &&
      watch('selected.firstChipValue.id') &&
      watch('selected.secondChipValue.id') &&
      moment(formatTime(firstDate?.label, watch('date') || new Date())).isBefore(
        moment(
          formatTime(
            getTime(watch('date'))[getTime(watch('date')).length - 3]?.label,
            watch('date') || new Date()
          )
        )
      );
    if (validCondiiton) {
      setFormData((prevState) => ({ ...prevState, disabled: false }));
    } else {
      setFormData((prevState) => ({ ...prevState, disabled: true }));
    }
  };

  const updateStackLOI = () => {
    const { levelOfInterview } = reduxState;
    const data = levelOfInterview.map((item) => ({ id: item.id, label: item.level }));
    setStackLoi((prevState) => ({ ...prevState, levelOfInterviewOptions: data }));
  };
  const handleTechStackUpdate = (params: ISelectOptions | ISelectOptions[]) => {
    setValue(`selected.firstChipValue`, params as ISelectOptions, {
      shouldValidate: true
    });
    validateSlots();
  };
  const handleLOIUpdate = (params: ISelectOptions | ISelectOptions[]) => {
    setValue(`selected.secondChipValue`, params as ISelectOptions, {
      shouldValidate: true
    });
    validateSlots();
  };
  const scrollToSlot = () => {
    scrollRef.current && scrollRef.current.scrollIntoView();
  };

  const scrollBehaviour = (condition: string) => {
    if (condition === DISABLE) {
      document.getElementById('scrollableContainer')?.classList.add('no-scroll');
    }
    if (condition === ENABLE) {
      document.getElementById('scrollableContainer')?.classList.remove('no-scroll');
    }
  };

  useEffect(() => {
    setFormSlotData();
  }, [techStackLevelSet, watch('date')]);

  useEffect(() => {
    setValue('selected.secondChipValue', firstLevelOfInterview);
  }, [stackLoi.levelOfInterviewOptions]);

  useEffect(() => {
    updateStackLOI();
  }, [reduxState.levelOfInterview]);

  useEffect(() => {
    validateSlots();
  }, [watch('slotTime'), watch('date'), watch('selected')]);

  useEffect(() => {
    setData();
  }, [watch('date'), watch('slotTime'), watch('selected')]);

  useEffect(() => {
    scrollToSlot();
  }, [fields?.length]);

  return (
    <Modal customStyle="slot-request-modal" onClose={onClose} header="" isOpen={true}>
      <div className="slot-header-container">
        <div className="slot-header">{NEW_REQUEST}</div>
        <ImageComponent src={CloseIcon} customClass="slot-delete-btn" onClick={onClose} />
      </div>
      {showLoader ? (
        <div className="slot-loading-wrapper">
          <LoaderSpinner className="loading-spinner" />
        </div>
      ) : (
        <div className="parent-modal-container">
          <form onSubmit={handleSubmit(handleFormSubmit)} id="slot-request-form">
            <div className="scrollable-container" id="scrollableContainer">
              <Typography variant="subtitle-16" customStyle="slot-request-form-hint">
                {SLOT_REQUEST_HINT}
              </Typography>
              <div className="slot-request-form">
                <div className="form-field date-input ">
                  <Typography variant="input-label">Date*</Typography>
                  <ClickAwayListener handleClose={handleDateDropdownClose}>
                    <Input
                      value={
                        watch('date') ? moment(watch('date')).format(timeStamps.DD_MM_YYYY) : ''
                      }
                      endIcon={DateIcon}
                      tabIndex={0}
                      onChange={() => {}}
                      onKeyDown={handleTabEventwithoutValue(handleDateInputClick)}
                      onEndIconClick={handleDateInputClick}
                      onClick={handleDateInputClick}
                      placeholder="Select Date"
                    />

                    {isDatePickerOpen && (
                      <div className="slot-date-picker-wrapper" tabIndex={0}>
                        <Controller
                          control={control}
                          name="date"
                          render={({ ...props }) => (
                            <DatePicker
                              {...register('date', {})}
                              onChange={handleDateChange}
                              disablePast
                              defaultValue={watch('date')}
                            />
                          )}
                        />
                      </div>
                    )}
                  </ClickAwayListener>
                </div>
                <div className="form-field">
                  <div className="stackLoi-row-container">
                    <Typography customStyle="input-label">{TECH_STACK_LABEL}</Typography>
                    <Typography customStyle="input-label">{LOI_LABEL}</Typography>
                  </div>
                  <div className="slot-request-clipped-fields">
                    <Controller
                      control={control}
                      name="selected.firstChipValue"
                      render={({ field: fieldController }: any) => (
                        <CustomSelect
                          {...fieldController}
                          showDropdownIndicator
                          dropdownOptions={stackLoi?.techStackOptions}
                          selectedOptions={fieldController.value}
                          customStyles={techStackStyles}
                          onChange={(event) => handleTechStackUpdate(event)}
                          blurInputOnSelect={true}
                          onMenuOpen={() => scrollBehaviour(DISABLE)}
                          onMenuClose={() => scrollBehaviour(ENABLE)}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="selected.secondChipValue"
                      render={({ field: fieldController }: any) => (
                        <CustomSelect
                          {...fieldController}
                          showDropdownIndicator
                          dropdownOptions={stackLoi?.levelOfInterviewOptions}
                          selectedOptions={fieldController.value}
                          customStyles={projectStyles}
                          onChange={(event) => handleLOIUpdate(event)}
                          blurInputOnSelect={true}
                          onMenuOpen={() => scrollBehaviour(DISABLE)}
                          onMenuClose={() => scrollBehaviour(ENABLE)}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="form-field slot-timing-form-field">
                  <Typography variant="input-label" customStyle="slot-timing-title">
                    Slot Timing*
                  </Typography>
                  {fields.length &&
                    fields.map((field: any, index: number) => {
                      return (
                        <div className="slot-timing" key={field.id} ref={scrollRef}>
                          <div className="slot-request-slots">
                            <div className="start-time">
                              <Controller
                                control={control}
                                name={`slotTime.${index}.startTime`}
                                render={({ field }: any) => (
                                  <CustomSelect
                                    {...field}
                                    dropdownOptions={getTime(watch('date')).slice(0, -2)}
                                    selectedOptions={field.value}
                                    placeholder={timeFormatter(START_TIME, FORMAT)}
                                    onChange={(data) => handleStartTimeChange(data, index)}
                                    showFullWidth
                                    blurInputOnSelect={true}
                                    restrictAlphabetOnSearch
                                    menuPlacement={index > 2 ? 'top' : 'bottom'}
                                    onMenuOpen={() => scrollBehaviour(DISABLE)}
                                    onMenuClose={() => scrollBehaviour(ENABLE)}
                                  />
                                )}
                              />
                            </div>

                            <div className="separator"></div>
                            <div className="end-time">
                              <Controller
                                control={control}
                                name={`slotTime.${index}.endTime`}
                                render={({ field }: any) => {
                                  return (
                                    <CustomSelect
                                      {...field}
                                      dropdownOptions={endTimeDropDownOptions(index)}
                                      onChange={(data) => handleEndTimeChange(data, index)}
                                      selectedOptions={field.value}
                                      placeholder={timeFormatter(END_TIME, FORMAT)}
                                      showFullWidth
                                      blurInputOnSelect={true}
                                      restrictAlphabetOnSearch
                                      menuPlacement={index > 2 ? 'top' : 'bottom'}
                                      onMenuOpen={() => scrollBehaviour(DISABLE)}
                                      onMenuClose={() => scrollBehaviour(ENABLE)}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="slot-request-buttons">
                            <Button
                              type="button"
                              onClick={(event) => {
                                removeField(event, index);
                              }}
                              disabled={watch('slotTime')?.length === 1}
                              className="slot-request-remove-button">
                              <div className="clipped-close-Icon">
                                <ImageComponent
                                  src={trash}
                                  alt={'Close Icon'}
                                  className={watch('slotTime')?.length === 1 ? 'stackLoi-icon' : ''}
                                />
                              </div>
                            </Button>
                            <Button
                              type="button"
                              onClick={(e) => handleAddMoreClick(e)}
                              className="slot-request-add-button"
                              disabled={!isValid}>
                              <ImageComponent src={plusPrimary} alt={'Close Icon'} />
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                  {errors.slotTime && <p className="error-message">{errors?.slotTime?.message}</p>}
                </div>
              </div>
            </div>

            <div className="form-field">
              {!isSlotValid && (
                <div className="warning-message">
                  <ImageComponent src={WarningIcon} customClass="warning-icon" />
                  <Typography variant="subtitle-16" customStyle="warning-text">
                    {SLOTS_COINCIDING_ERROR}
                    <span onClick={handleViewSlot}> View Slots</span>
                  </Typography>
                </div>
              )}
            </div>
            <div className="modal-buttons">
              <Button
                type="button"
                variant="outlined"
                customStyle="cancel-button"
                onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                customStyle="request-button"
                disabled={!isValid}>
                Request
              </Button>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
};
export default SlotRequestForm;
