import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ISelectValue } from 'app/core/shared-components/select';
import { GET_ROLES, GET_TECH_STACK, SHARED_DATA } from 'app/redux/constant';
import {
  fetchRoles,
  fetchTechstack,
  fetchProject,
  fetchLevelOfInterview,
  fetchGeneralNotification,
  fetchActivityNotification
} from 'app/services/common.service';
import {
  IUserInfoDataType,
  IRoleSetType,
  ITechStackType,
  ILevelOfInterviewType,
  IProjectType
} from 'app/services/user-response.types';
import {
  PANELLIST,
  RECRUITER,
  TALENT_ACQUISITION,
  PANEL,
  GETLOI,
  GET_ACTIVITY_NOTIFICATION,
  GET_GENERAL_NOTIFICATION
} from 'helpers/constants';
import { ISelectOptions } from 'app/core/shared-components/customSelect';

export interface INotificationListData {
  notificationId: string;
  title: string;
  description: string;
  id?: string;
  slotId?: string;
  slotDate?: string;
  entity: string;
  createdTime: string;
  isRead: boolean;
}

export interface INotificationData {
  hasNext: boolean;
  notificationList: INotificationListData[];
  pageCount: number;
  totalCount: number;
}

export interface INotification {
  general: INotificationData;
  activity: INotificationData;
}

interface SharedState {
  techStack: ITechStackType[];
  projects: IProjectType[];
  userInfo: IUserInfoDataType | null;
  levelOfInterview: ILevelOfInterviewType[];
  roles: IRoleSetType[];
  recruiter: ISelectOptions | null;
  panel: ISelectOptions;
  isTechLoading: boolean;
  isLevelLoading: boolean;
  isProjectLoading: boolean;
  isSharedError: boolean;
  isCalendarSyncOpen: boolean;
  isManageAdminsOpen: boolean;
  selectedNotificationData: {
    id: string;
    slotDate?: string;
  };
  notificationCount: number;
  isNotificationLoading: boolean;
  isNotificationError: boolean;
  generalNotificationPageNumber: number;
  activityNotificationPageNumber: number;
  notificationData: INotification;
}

const defaultNotificationData = {
  hasNext: false,
  notificationList: [],
  pageCount: 0,
  totalCount: 0
};

const initialState: SharedState = {
  techStack: [],
  projects: [],
  userInfo: null,
  levelOfInterview: [],
  roles: [],
  recruiter: null,
  panel: {
    id: '',
    label: ''
  },
  isTechLoading: false,
  isLevelLoading: false,
  isProjectLoading: false,
  isSharedError: false,
  isCalendarSyncOpen: false,
  isManageAdminsOpen: false,
  selectedNotificationData: {
    id: '',
    slotDate: ''
  },
  notificationCount: 0,
  isNotificationLoading: false,
  isNotificationError: false,
  generalNotificationPageNumber: 0,
  activityNotificationPageNumber: 0,
  notificationData: {
    general: defaultNotificationData,
    activity: defaultNotificationData
  }
};

export const getRoles = createAsyncThunk(GET_ROLES, async function getRoles(_params, thunkAPI) {
  const data = await fetchRoles();
  return data;
});

export const getTechStack = createAsyncThunk(
  GET_TECH_STACK,
  async function getTechStack(_params, thunkAPI) {
    const data = await fetchTechstack();
    if (!data?.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const getProjects = createAsyncThunk(
  'getProjects',
  async function getProjects(_params, thunkAPI) {
    const data = await fetchProject();
    return data;
  }
);

export const getLOI = createAsyncThunk(GETLOI, async function getLOI(_params, thunkAPI) {
  const data = await fetchLevelOfInterview();
  if (!data?.error) {
    return data;
  }
  return thunkAPI.rejectWithValue(data);
});

export const getGeneralNotification = createAsyncThunk(
  GET_GENERAL_NOTIFICATION,
  async function getGeneralNotification(pageNumber: number, thunkAPI) {
    const data = await fetchGeneralNotification(pageNumber);
    if (!data?.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const getActivityNotification = createAsyncThunk(
  GET_ACTIVITY_NOTIFICATION,
  async function getActivityNotification(pageNumber: number, thunkAPI) {
    const data = await fetchActivityNotification(pageNumber);
    if (!data?.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const sharedSlice = createSlice({
  name: SHARED_DATA,
  initialState,
  reducers: {
    toggleCalendarSync: (state, action) => {
      state.isCalendarSyncOpen = action.payload;
    },
    toggleManageAdmins: (state, action) => {
      state.isManageAdminsOpen = action.payload;
    },
    setSelectedNotificationData: (state, action) => {
      const { payload } = action;
      state.selectedNotificationData = { id: payload?.id, slotDate: payload?.slotDate || '' };
    },
    setNotificationCount: (state, { payload }) => {
      state.notificationCount = payload;
    },
    setGeneralPageNumber: (state, { payload }) => {
      state.generalNotificationPageNumber = payload;
    },
    setActivityPageNumber: (state, { payload }) => {
      state.activityNotificationPageNumber = payload;
    },
    setNotificationData: (state, { payload }) => {
      state.notificationData = payload;
    },
    resetNotification: (state) => {
      state.generalNotificationPageNumber = 0;
      state.activityNotificationPageNumber = 0;
      state.notificationData = {
        general: defaultNotificationData,
        activity: defaultNotificationData
      };
      state.isNotificationError = false;
      state.isNotificationLoading = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRoles.fulfilled, (state, { payload }) => {
      const isRecruiter = payload?.data?.find(
        (role: ISelectOptions) => role?.label?.toString()?.toUpperCase() === RECRUITER
      );
      if (isRecruiter) state.recruiter = { id: isRecruiter?.id, label: TALENT_ACQUISITION };
      const isPanel = payload?.data?.find(
        (role: ISelectOptions) => role?.label?.toString()?.toUpperCase() === PANEL
      );
      if (isPanel) state.panel = { id: isPanel?.id, label: PANELLIST };
      state.roles = payload.data;
    });
    builder.addCase(getTechStack.fulfilled, (state, { payload }) => {
      state.techStack = payload.data;
      state.isTechLoading = false;
      state.isSharedError = false;
    });
    builder.addCase(getTechStack.pending, (state, { payload }) => {
      state.isTechLoading = true;
    });
    builder.addCase(getTechStack.rejected, (state, { payload }) => {
      state.isTechLoading = false;
      state.isSharedError = true;
    });
    builder.addCase(getProjects.fulfilled, (state, { payload }) => {
      state.projects = payload.data;
      state.isProjectLoading = false;
      state.isSharedError = false;
    });
    builder.addCase(getProjects.pending, (state, { payload }) => {
      state.isProjectLoading = true;
    });
    builder.addCase(getLOI.fulfilled, (state, { payload }) => {
      state.levelOfInterview = payload.data;
      state.isLevelLoading = false;
      state.isSharedError = false;
    });
    builder.addCase(getLOI.pending, (state, { payload }) => {
      state.isLevelLoading = true;
    });
    builder.addCase(getLOI.rejected, (state, { payload }) => {
      state.isLevelLoading = false;
      state.isSharedError = true;
    });
    builder.addCase(getActivityNotification.pending, (state) => {
      state.isNotificationLoading = true;
    });
    builder.addCase(getActivityNotification.rejected, (state) => {
      state.isNotificationLoading = false;
      state.isNotificationError = true;
    });
    builder.addCase(getActivityNotification.fulfilled, (state, { payload }) => {
      state.isNotificationLoading = false;
      state.isNotificationError = false;
      state.notificationData = {
        activity: {
          ...payload.data,
          notificationList: !!!state.activityNotificationPageNumber
            ? payload.data?.notificationList
            : state.notificationData.activity.notificationList.concat(
                payload?.data?.notificationList
              )
        },
        general: { ...state.notificationData?.general }
      };
    });
    builder.addCase(getGeneralNotification.pending, (state) => {
      state.isNotificationLoading = true;
    });
    builder.addCase(getGeneralNotification.rejected, (state) => {
      state.isNotificationLoading = false;
      state.isNotificationError = true;
    });
    builder.addCase(getGeneralNotification.fulfilled, (state, { payload }) => {
      state.isNotificationLoading = false;
      state.isNotificationError = false;
      state.notificationData = {
        general: {
          ...payload.data,
          notificationList: !!!state.generalNotificationPageNumber
            ? payload.data?.notificationList
            : state.notificationData.general.notificationList.concat(
                payload?.data?.notificationList
              )
        },
        activity: { ...state.notificationData?.activity }
      };
    });
  }
});

export const {
  toggleCalendarSync,
  toggleManageAdmins,
  setSelectedNotificationData,
  setNotificationCount,
  setActivityPageNumber,
  setGeneralPageNumber,
  resetNotification,
  setNotificationData
} = sharedSlice.actions;
export default sharedSlice.reducer;
