import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelectValue } from 'app/core/shared-components/select';
import {
  ADD_SLOT,
  BOOK_REQUESTED_SLOT,
  BOOK_SLOT,
  CANCEL_SLOT,
  DELETE_AVAILABLE_SLOT,
  DELETE_REQUESTED_SLOT,
  FETCH_ALL_SLOTS,
  FETCH_AVAILABLE_SLOTS,
  FETCH_BOOKED_SLOTS,
  FETCH_CANCELLED_SLOTS,
  FETCH_DASHBOARD_BOOKED_SLOTS,
  FETCH_REQUESTED_SLOTS,
  FETCH_SPECIFIC_AVAILABLE_SLOTS,
  FETCH_SPECIFIC_BOOKED_SLOTS,
  FETCH_SPECIFIC_CANCELLED_SLOTS,
  SLOTS,
  SLOT_COUNT,
  SLOT_COUNT_FOR_SPECIFIC_USER,
  VALIDATE_REQUESTED_SLOT
} from 'helpers/constants';
import {
  ISpecificAvailableSlotResponse,
  ISpecificBookedSlotResponse
} from 'app/pages/Slots/components/slot-carousel';
import {
  addSlotForPanel,
  allSlots,
  availableSlot,
  bookedSlots,
  bookRequestedSlotService,
  bookSlotService,
  cancelledSlots,
  cancelSlotService,
  deleteRequestedSlotService,
  IAddSlotBody,
  IBookSlotBody,
  ICancelSlotBody,
  IFetchSlotBody,
  IRequestSlotBody,
  ISlotFilterDTOType,
  IValidateSlotBody,
  requestedSlots,
  requestSlot,
  searchPanelService,
  slotCountRequestService,
  specificAvailableSlot,
  specificBookedCancelledSlot,
  validateSlot
} from 'app/services/slots.service';
import { REQUEST_SLOT, SLOTS_SEARCH } from 'app/redux/constant';
import { getSlotInfo } from './scheduleSlice';
import { IProjectType } from '../../services/user-response.types';

export interface ISLotTimeDTOList {
  fromTime: string;
  toTime: string;
  noOfPanels: number;
  isRequest: boolean;
  slotId: string;
  candidateName: string;
}

export interface ISlotCountRequest {
  interviewStatus: string;
  userId?: string | number;
  techStackId?: string | number;
  dateTime: IDateTime[] | [] | null;
}
import { deleteMultipleSlot } from 'app/services/schedule.service';

export interface IAvailableSlotsResponse {
  data: {
    userDetails: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      designation: string;
      totalExperience: number;
      techStackInterviewLevelSet: [
        {
          techStackId: string;
          techStack: string;
          levelId: string;
          level: string;
          roleId: string;
        }
      ];
      projects: [
        {
          id: string;
          name: string;
        }
      ];
      mobileNo: string;
      profileImageUrl: string;
      addedOn: Date;
      statusEnum: string;
      interviewCountDetail: {
        weeklyInterviewCount: number;
        dailyInterviewCount: number;
      };
    };
    levelId: string;
    level: string;
    interviewSlotDateDTOList: [
      {
        date: string;
        noOfSlots: 0;
        interviewSlotTimeDTOList: ISLotTimeDTOList[];
      }
    ];
  };
}

export interface IRequestedSlotsResponse {
  slotRequestId: string;
  date: string;
  fromTime: string;
  toTime: string;
  techStack: string;
  interviewLevel: string;
  slotRequestUserResponseDtoList: [
    {
      firstName: string;
      lastName: string;
      designation: string;
      profileImageUrl: string;
      level: string[];
    }
  ];
}

export interface ISpecificAvailableSlotBody {
  slotDate: string;
  interviewLevelId?: string | number;
  techStackId: string | number;
  fromTime: string;
  toTime: string;
}

interface IBookSlot {
  slotId: number;
  candidateName: string;
}
interface IBookRequestedSlot {
  slotRequestId: string;
  userId: string;
  candidateName: string;
}

export interface ITechStackInterviewLevelSet {
  techStack: string;
  level: string;
}

interface IHandleSearchParams {
  searchString: string;
  abortSignal: any;
}

export interface ISelectedSearch {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  designation: string;
  totalExperience: string | 0;
  techStackInterviewLevelSet: ITechStackInterviewLevelSet[];
  projects?: IProjectType[];
  mobileNo: string;
  profileImageUrl: string;
  addedOn: string;
  statusEnum: string;
}

interface ILevelWiseCountList {
  levelCount: number;
  levelId: string;
}
interface ISlotCountData {
  levelWiseCountList: ILevelWiseCountList[];
  statusCount: {
    available: number;
    booked: number;
    cancelled: number;
    allSlotCount: number;
  };
}

interface ISlotStates {
  isError: boolean;
  isLoading: boolean;
  isBookedLoading: boolean;
  isAllLoading: boolean;
  isRequestSlotLoading: boolean;
  isFetchSlotLoading: boolean;
  isSlotValidating: boolean;
  isToastVisible: boolean;
  showLoader: boolean;
  toastMessage: string;
  searchOptions: ISelectedSearch[];
  selectedSearch: ISelectedSearch | null;
  clearSearch: boolean;
  currentSlots: IAvailableSlotsResponse;
  bookedSlots: IAvailableSlotsResponse;
  allSlots: IAvailableSlotsResponse;
  availableSlots: IAvailableSlotsResponse;
  cancelledSlots: IAvailableSlotsResponse;
  selectedSlot: ISpecificAvailableSlotResponse;
  selectedBookedSlot: ISpecificBookedSlotResponse;
  selectedCancelledSlot: ISpecificBookedSlotResponse;
  filterValue: IFetchSlotBody;
  filteredDateData: {
    mode: string;
    dates: Date | Date[] | null | any;
    slotTime: { startTime: ISelectValue | null; endTime: ISelectValue | null };
  };
  requestedSlots: IRequestedSlotsResponse[];
  isUndoToastVisible: boolean;
  addSlotError: string;
  deletionId: number;
  pendingRequestDeletion: IRequestedSlotsResponse[];
  showRequestFlag: boolean;
  isSearchError: boolean;
  slotCountData: ISlotCountData;
}

const initialState: ISlotStates = {
  isError: false,
  isLoading: false,
  isBookedLoading: false,
  isAllLoading: false,
  isToastVisible: false,
  isRequestSlotLoading: false,
  isFetchSlotLoading: false,
  isSlotValidating: false,
  showLoader: false,
  toastMessage: '',
  searchOptions: [],
  selectedSearch: null,
  clearSearch: false,
  currentSlots: {} as IAvailableSlotsResponse,
  bookedSlots: {} as IAvailableSlotsResponse,
  allSlots: {} as IAvailableSlotsResponse,
  availableSlots: {} as IAvailableSlotsResponse,
  cancelledSlots: {} as IAvailableSlotsResponse,
  selectedSlot: {} as ISpecificAvailableSlotResponse,
  selectedBookedSlot: {} as ISpecificBookedSlotResponse,
  selectedCancelledSlot: {} as ISpecificBookedSlotResponse,
  filterValue: {} as IFetchSlotBody,
  filteredDateData: {
    mode: 'Custom',
    dates: null,
    slotTime: { startTime: null, endTime: null }
  },
  requestedSlots: [] as IRequestedSlotsResponse[],
  isUndoToastVisible: false,
  addSlotError: '',
  deletionId: -1,
  pendingRequestDeletion: [],
  showRequestFlag: false,
  isSearchError: false,
  slotCountData: {} as ISlotCountData
};

export const handleRequestSlot = createAsyncThunk(
  REQUEST_SLOT,
  async function handleRequestSlot(body: IRequestSlotBody) {
    const data = await requestSlot(body);

    if (data?.error?.length > 0) {
      return data;
    } else {
      return data;
    }
  }
);

export const fetchAvailableSlots = createAsyncThunk(
  FETCH_AVAILABLE_SLOTS,
  async function fetchAvailableSlots(body: IFetchSlotBody, thunkAPI) {
    const data = await availableSlot(body);

    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const fetchBookedSlots = createAsyncThunk(
  FETCH_BOOKED_SLOTS,
  async function fetchBookedSlots(body: IFetchSlotBody, thunkAPI) {
    const data = await bookedSlots(body);

    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);
export const fetchAllSlots = createAsyncThunk(
  FETCH_ALL_SLOTS,
  async function fetchAllSlots(body: IFetchSlotBody, thunkAPI) {
    const data = await allSlots(body);

    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const fetchDashboardBookedSlots = createAsyncThunk(
  FETCH_DASHBOARD_BOOKED_SLOTS,
  async function fetchBookedSlots(body: IFetchSlotBody, thunkAPI) {
    const data = await bookedSlots(body);

    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const fetchCancelledSlots = createAsyncThunk(
  FETCH_CANCELLED_SLOTS,
  async function fetchCancelledSlots(body: IFetchSlotBody, thunkAPI) {
    const data = await cancelledSlots(body);

    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const fetchRequestedSlots = createAsyncThunk(
  FETCH_REQUESTED_SLOTS,
  async function fetchRequestedSlots(undefined, thunkAPI) {
    const data = await requestedSlots();
    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const bookSlot = createAsyncThunk(
  BOOK_SLOT,
  async function bookSlot(body: IBookSlotBody, thunkAPI) {
    const data = await bookSlotService(body);

    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const bookRequestedSlot = createAsyncThunk(
  BOOK_REQUESTED_SLOT,
  async function bookRequestedSlot(
    { slotRequestId, userId, candidateName }: IBookRequestedSlot,
    thunkAPI
  ) {
    const data = await bookRequestedSlotService(slotRequestId, userId, candidateName);

    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const cancelSlot = createAsyncThunk(
  CANCEL_SLOT,
  async function cancelSlot(body: ICancelSlotBody, thunkAPI) {
    const data = await cancelSlotService(body);

    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const deleteRequestedSlot = createAsyncThunk(
  DELETE_REQUESTED_SLOT,
  async function deleteRequestedSlot(requestId: number | string, thunkAPI) {
    const data = await deleteRequestedSlotService(requestId);

    if (!data.error) {
      return requestId;
    }
    return thunkAPI.rejectWithValue(requestId);
  }
);

export const deleteAvailableSlot = createAsyncThunk(
  DELETE_AVAILABLE_SLOT,
  async function deleteAvailableSlot(slotIdList: Array<string> | string, thunkAPI) {
    const data = await deleteMultipleSlot(Array.isArray(slotIdList) ? slotIdList : [slotIdList]);
    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(slotIdList);
  }
);

export const fetchSpecificAvailableSlots = createAsyncThunk(
  FETCH_SPECIFIC_AVAILABLE_SLOTS,
  async function fetchSpecificAvailableSlots(body: ISpecificAvailableSlotBody, thunkAPI) {
    const data = await specificAvailableSlot(body);
    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const addSlot = createAsyncThunk(
  ADD_SLOT,
  async function addSlot(body: IAddSlotBody, thunkAPI) {
    const data = await addSlotForPanel(body);
    if (!data.error) {
      return data;
    } else {
      const { error } = data;
      if (error[0]?.code === 400) return data;
      else thunkAPI.rejectWithValue(data);
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const validateRequestedSlot = createAsyncThunk(
  VALIDATE_REQUESTED_SLOT,
  async function validateRequestedSlot(body: any, thunkAPI) {
    const data = await validateSlot(body);
    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const fetchSpecificBookedSlot = createAsyncThunk(
  FETCH_SPECIFIC_BOOKED_SLOTS,
  async function fetchSpecificBookedSlot(slotId: string, thunkAPI) {
    const data = await specificBookedCancelledSlot(slotId);
    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const fetchSpecificCancelledSlot = createAsyncThunk(
  FETCH_SPECIFIC_CANCELLED_SLOTS,
  async function fetchSpecificBookedSlot(slotId: string, thunkAPI) {
    const data = await specificBookedCancelledSlot(slotId);
    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const handleSearch = createAsyncThunk(SLOTS_SEARCH, async (params: any, thunkAPI) => {
  const { searchString, abortSignal } = params;
  const data = await searchPanelService(searchString, abortSignal?.signal);
  if (!data.error) {
    return data;
  } else if (data.error && data.error[0].code === 1008) {
    return { data: [] };
  }
  return thunkAPI.rejectWithValue(data);
});

export const slotsSlice = createSlice({
  name: SLOTS,
  initialState,
  reducers: {
    toggleToastVisibility: (state, { payload }) => {
      if (payload.length) {
        state.isToastVisible = !state.isToastVisible;
        state.toastMessage = payload;
      } else {
        state.isToastVisible = false;
        state.toastMessage = '';
      }
    },
    toggleUndoToastVisibility: (state, { payload }) => {
      if (payload.length) {
        state.isUndoToastVisible = !state.isUndoToastVisible;
      } else {
        state.isUndoToastVisible = false;
      }
    },
    setFilterValue: (state, { payload }) => {
      state.filterValue = payload;
    },
    setDeletionId: (state, { payload }) => {
      state.deletionId = payload;
    },
    setFilteredDateValue: (state, { payload }) => {
      state.filteredDateData = payload;
    },
    resetSearchResult: (state) => {
      state.selectedSearch = null;
    },
    toggleRequestFlag: (state, { payload }) => {
      state.showRequestFlag = payload;
    },
    removeSlotRequest: (state, action: PayloadAction<string | number>) => {
      const data = state.requestedSlots.findIndex(
        (event) => event.slotRequestId.toString() === action.payload.toString()
      );
      if (data !== -1) {
        const deletedData = state.requestedSlots.splice(data, 1);
        state.pendingRequestDeletion = state.pendingRequestDeletion.concat(deletedData);
        state.requestedSlots = state.requestedSlots;
      }
    },
    readdSlotRequest: (state, action: PayloadAction<string | number>) => {
      const data = state.pendingRequestDeletion.findIndex(
        (slot) => slot.slotRequestId.toString() === action.payload.toString()
      );
      if (data !== -1) {
        state.requestedSlots.push(state.pendingRequestDeletion[data]);
        state.pendingRequestDeletion.splice(data, 1);
        state.requestedSlots = state.requestedSlots;
      }
    },

    slotCountRequest: (state, { payload }) => {
      state.slotCountData = payload;
    },

    resetPage: (state) => {
      state.isError = false;
      state.isToastVisible = false;
      state.isRequestSlotLoading = false;
      state.isFetchSlotLoading = false;
      state.isSlotValidating = false;
      state.showLoader = false;
      state.toastMessage = '';
      state.searchOptions = [];
      state.selectedSearch = null;
      state.clearSearch = false;
      state.bookedSlots = {} as IAvailableSlotsResponse;
      state.availableSlots = {} as IAvailableSlotsResponse;
      state.cancelledSlots = {} as IAvailableSlotsResponse;
      state.selectedSlot = {} as ISpecificAvailableSlotResponse;
      state.selectedBookedSlot = {} as ISpecificBookedSlotResponse;
      state.selectedCancelledSlot = {} as ISpecificBookedSlotResponse;
      state.filterValue = {} as IFetchSlotBody;
      state.deletionId = -1;
      state.filteredDateData = {
        mode: 'Custom',
        dates: null,
        slotTime: { startTime: null, endTime: null }
      };
      state.requestedSlots = [] as IRequestedSlotsResponse[];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(handleRequestSlot.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        showLoader: false
      };
    });
    builder.addCase(handleRequestSlot.pending, (state) => {
      return {
        ...state,
        showLoader: true
      };
    });
    builder.addCase(handleRequestSlot.rejected, (state) => {
      return {
        ...state,
        isError: true,
        showLoader: false
      };
    });
    builder.addCase(fetchAvailableSlots.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isLoading: false,
        currentSlots: payload,
        availableSlots: payload.data,
        showRequestFlag: false,
        selectedSearch: payload?.data?.userDetails ? payload?.data?.userDetails : null
      };
    });
    builder.addCase(fetchAvailableSlots.pending, (state) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(fetchAvailableSlots.rejected, (state) => {
      return {
        ...state,
        isError: true,
        isLoading: false
      };
    });
    builder.addCase(addSlot.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        showLoader: false,
        addSlotError: ''
      };
    });
    builder.addCase(addSlot.pending, (state) => {
      return {
        ...state,
        showLoader: true
      };
    });
    builder.addCase(addSlot.rejected, (state, { payload }) => {
      return {
        ...state,
        isError: true,
        showLoader: false
      };
    });
    builder.addCase(fetchBookedSlots.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isLoading: false,
        isBookedLoading: false,
        currentSlots: payload,
        bookedSlots: payload
      };
    });
    builder.addCase(fetchBookedSlots.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        isBookedLoading: true
      };
    });
    builder.addCase(fetchBookedSlots.rejected, (state) => {
      return {
        ...state,
        isError: true,
        isLoading: false,
        isBookedLoading: false
      };
    });
    builder.addCase(fetchAllSlots.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isLoading: false,
        isAllLoading: false,
        allSlots: payload,
        currentSlots: payload
      };
    });
    builder.addCase(fetchAllSlots.pending, (state) => {
      return {
        ...state,
        isLoading: true,
        isAllLoading: true
      };
    });
    builder.addCase(fetchAllSlots.rejected, (state) => {
      return {
        ...state,
        isError: true,
        isLoading: false,
        isAllLoading: false
      };
    });
    builder.addCase(fetchDashboardBookedSlots.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isBookedLoading: false,
        bookedSlots: payload
      };
    });
    builder.addCase(fetchDashboardBookedSlots.pending, (state) => {
      return {
        ...state,
        isBookedLoading: true
      };
    });
    builder.addCase(fetchDashboardBookedSlots.rejected, (state) => {
      return {
        ...state,
        isError: true,
        isBookedLoading: false
      };
    });
    builder.addCase(validateRequestedSlot.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isSlotValidating: false
      };
    });
    builder.addCase(validateRequestedSlot.pending, (state) => {
      return {
        ...state,
        isSlotValidating: true
      };
    });
    builder.addCase(validateRequestedSlot.rejected, (state) => {
      return {
        ...state,
        isError: true,
        isSlotValidating: false
      };
    });
    builder.addCase(fetchCancelledSlots.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isLoading: false,
        currentSlots: payload,
        cancelledSlots: payload
      };
    });
    builder.addCase(fetchCancelledSlots.pending, (state) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(fetchCancelledSlots.rejected, (state) => {
      return {
        ...state,
        isError: true,
        isLoading: false
      };
    });
    builder.addCase(fetchRequestedSlots.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isRequestSlotLoading: false,
        requestedSlots: payload?.data?.filter(
          ({ slotRequestId: id }: IRequestedSlotsResponse) =>
            !state.pendingRequestDeletion.some(
              ({ slotRequestId: slotId }) => id?.toString() === slotId?.toString()
            )
        )
      };
    });
    builder.addCase(fetchRequestedSlots.pending, (state) => {
      return {
        ...state,
        isRequestSlotLoading: true
      };
    });
    builder.addCase(fetchRequestedSlots.rejected, (state) => {
      return {
        ...state,
        isError: true,
        isRequestSlotLoading: false
      };
    });
    builder.addCase(bookSlot.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isLoading: false
      };
    });
    builder.addCase(bookSlot.pending, (state) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(bookSlot.rejected, (state) => {
      return {
        ...state,
        isError: true,
        isLoading: false
      };
    });
    builder.addCase(bookRequestedSlot.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isRequestSlotLoading: false
      };
    });
    builder.addCase(bookRequestedSlot.pending, (state) => {
      return {
        ...state,
        isRequestSlotLoading: true
      };
    });
    builder.addCase(bookRequestedSlot.rejected, (state) => {
      return {
        ...state,
        isError: true,
        isRequestSlotLoading: false
      };
    });
    builder.addCase(cancelSlot.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isLoading: false
      };
    });
    builder.addCase(cancelSlot.pending, (state) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(cancelSlot.rejected, (state) => {
      return {
        ...state,
        isError: true,
        isLoading: false
      };
    });
    builder.addCase(deleteRequestedSlot.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isRequestSlotLoading: false,
        pendingRequestDeletion: state.pendingRequestDeletion.filter(
          (slot) => slot.slotRequestId.toString() !== payload.toString()
        )
      };
    });
    builder.addCase(deleteRequestedSlot.pending, (state) => {
      return {
        ...state,
        isRequestSlotLoading: true
      };
    });
    builder.addCase(deleteRequestedSlot.rejected, (state) => {
      return {
        ...state,
        isError: true,
        isRequestSlotLoading: false
      };
    });
    builder.addCase(deleteAvailableSlot.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isRequestSlotLoading: false
      };
    });
    builder.addCase(deleteAvailableSlot.pending, (state) => {
      return {
        ...state,
        isRequestSlotLoading: true
      };
    });
    builder.addCase(deleteAvailableSlot.rejected, (state) => {
      return {
        ...state,
        isError: true,
        isRequestSlotLoading: false
      };
    });
    builder.addCase(fetchSpecificAvailableSlots.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isFetchSlotLoading: false,
        selectedSlot: payload.data
      };
    });
    builder.addCase(fetchSpecificAvailableSlots.pending, (state) => {
      return {
        ...state,
        isFetchSlotLoading: true
      };
    });
    builder.addCase(fetchSpecificAvailableSlots.rejected, (state) => {
      return {
        ...state,
        isError: true,
        isFetchSlotLoading: false
      };
    });
    builder.addCase(fetchSpecificBookedSlot.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isFetchSlotLoading: false,
        selectedBookedSlot: payload.data
      };
    });
    builder.addCase(fetchSpecificBookedSlot.pending, (state) => {
      return {
        ...state,
        isFetchSlotLoading: true
      };
    });
    builder.addCase(fetchSpecificBookedSlot.rejected, (state) => {
      return {
        ...state,
        isError: true,
        isFetchSlotLoading: false
      };
    });
    builder.addCase(fetchSpecificCancelledSlot.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isFetchSlotLoading: false,
        selectedBookedSlot: payload.data,
        selectedCancelledSlot: payload.data
      };
    });
    builder.addCase(fetchSpecificCancelledSlot.pending, (state) => {
      return {
        ...state,
        isFetchSlotLoading: true
      };
    });
    builder.addCase(fetchSpecificCancelledSlot.rejected, (state) => {
      return {
        ...state,
        isError: true,
        isFetchSlotLoading: false
      };
    });
    builder.addCase(handleSearch.fulfilled, (state, action) => {
      state.searchOptions = action.payload.data;
      state.isSearchError = false;
    });
    builder.addCase(handleSearch.rejected, (state) => {
      return { ...state, isSearchError: true };
    });

    builder.addCase(slotCount.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isLoading: false,
        slotCountData: payload.data
      };
    });
    builder.addCase(slotCount.pending, (state) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(slotCount.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    });
    builder.addCase(slotCountForSpecificUser.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        isError: false,
        slotCountData: payload.data
      };
    });
    builder.addCase(slotCountForSpecificUser.pending, (state) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(slotCountForSpecificUser.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    });
    builder.addCase(getSlotInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSlotInfo.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSlotInfo.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  }
});

interface IDateTime {
  from: string;
  to: string;
}

export const slotCount = createAsyncThunk(
  SLOT_COUNT,
  async function slotCount({ userId, ...slotCountData }: ISlotCountRequest, thunkAPI) {
    const data = await slotCountRequestService(slotCountData);
    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const slotCountForSpecificUser = createAsyncThunk(
  SLOT_COUNT_FOR_SPECIFIC_USER,
  async function slotCountForSpecificUser(
    { techStackId, ...slotCountDataForSpecificUser }: ISlotCountRequest,
    thunkAPI
  ) {
    const data = await slotCountRequestService(slotCountDataForSpecificUser);
    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const {
  toggleToastVisibility,
  setFilterValue,
  setFilteredDateValue,
  resetSearchResult,
  toggleUndoToastVisibility,
  resetPage,
  setDeletionId,
  readdSlotRequest,
  removeSlotRequest,
  toggleRequestFlag,
  slotCountRequest
} = slotsSlice.actions;
export default slotsSlice.reducer;
