import React, { useState, useEffect, MouseEvent, useCallback } from 'react';
import './index.style.scss';
import Images from '../../../../assets/images';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import ImageComponent from '../image';

interface IDatePickerProps {
  value?: Date | Date[] | null;
  defaultValue?: Date | Date[] | null;
  defaultDate?: Date;
  onChange?: (value: Date | Date[] | null) => void;
  multiple?: 'linear' | 'non-linear' | false;
  width?: number | string;
  height?: number | string;
  disablePast?: boolean;
  disableFuture?: boolean;
  disableHighlightToday?: boolean;
}

interface IDatePickerState {
  today: Moment;
  currentDate: Moment;
  selected: Moment | Moment[] | null;
  rangeSelection: Moment | null;
}

function DatePicker(props: IDatePickerProps) {
  const {
    value,
    defaultValue,
    defaultDate,
    disablePast = false,
    disableFuture = false,
    disableHighlightToday = false,
    multiple = false
  } = props;

  const [pickerState, setPickerState] = useState<IDatePickerState>({
    today: moment(),
    currentDate: moment(defaultDate),
    selected: null,
    rangeSelection: null
  });

  useEffect(() => {
    if (value !== undefined) handleControlled(value);
    else if (defaultValue !== undefined) handleControlled(defaultValue);
    else handleControlled(null);
  }, [props.multiple, props.value]);

  const handleControlled = (param: Date | Date[] | null) => {
    if (multiple === 'linear') handleLinearControlled(param);
    else if (multiple === 'non-linear') handleNonLinearControlled(param);
    else handleDateControlled(param);
  };

  const handleNonLinearControlled = (param: Date | Date[] | null) => {
    if (Array.isArray(param)) {
      const momentDate = param.map((item) => moment(item));
      setPickerState((prevState) => ({ ...prevState, selected: momentDate, rangeSelection: null }));
    } else if (param && !Array.isArray(param))
      setPickerState((prevState) => ({
        ...prevState,
        selected: [moment(param)],
        rangeSelection: null
      }));
    else {
      setPickerState((prevState) => ({
        ...prevState,
        selected: [],
        rangeSelection: null
      }));
    }
  };

  const getMinAndMaxDate = (param: Moment[]) => {
    return {
      min: moment.min(param),
      max: moment.max(param)
    };
  };

  const checkDateEqual = (date1: Moment[], date2: Moment[]) => {
    if (date1.length === date2.length) {
      for (let i = 0; i < date1.length; i++) {
        const equal = date1[i].isSame(date2[i], 'date');
        if (!equal) return false;
      }
      return true;
    } else return false;
  };

  const handleLinearControlled = (param: Date | Date[] | null) => {
    if (Array.isArray(param)) {
      if (param.length > 1) {
        const momentDate = param.map((item) => moment(item));
        const dates = getMinAndMaxDate(momentDate);
        const getAllDays = enumerateDaysBetweenDates(dates.min.clone(), dates.max.clone());
        const equalDates = checkDateEqual(getAllDays, momentDate);
        if (equalDates) {
          setPickerState((prevState) => ({
            ...prevState,
            selected: getAllDays,
            rangeSelection: null
          }));
        } else {
          setPickerState((prevState) => ({
            ...prevState,
            selected: null,
            rangeSelection: null
          }));
          props.onChange && props.onChange(null);
        }
      } else {
        setPickerState((prevState) => ({
          ...prevState,
          selected: moment(param[0]),
          rangeSelection: null
        }));
      }
    } else if (param && !Array.isArray(param))
      setPickerState((prevState) => ({
        ...prevState,
        selected: moment(param),
        rangeSelection: null
      }));
    else {
      setPickerState((prevState) => ({
        ...prevState,
        selected: null,
        rangeSelection: null
      }));
    }
  };

  const handleDateControlled = (param: Date | Date[] | null) => {
    if (!Array.isArray(param))
      setPickerState((prevState) => ({
        ...prevState,
        selected: moment(param),
        rangeSelection: null
      }));
  };

  const getCurrentHeaderDetail = useCallback(() => {
    const { currentDate } = pickerState;
    return currentDate.format('MMMM YYYY');
  }, [pickerState.currentDate]);

  const changeToPreviousMonth = useCallback(() => {
    const { currentDate, today } = pickerState;
    const prevMonth = moment(currentDate).subtract(1, 'month');
    if (disablePast && prevMonth.isBefore(today, 'month')) return;
    else
      setPickerState((prevState) => ({
        ...prevState,
        currentDate: prevMonth
      }));
  }, [pickerState.currentDate, pickerState.today, disablePast]);

  const changeToNextMonth = useCallback(() => {
    const { currentDate, today } = pickerState;
    const nextMonth = moment(currentDate).add(1, 'month');
    if (disableFuture && nextMonth.isSameOrAfter(today, 'month')) return;
    else
      setPickerState((prevState) => ({
        ...prevState,
        currentDate: nextMonth
      }));
  }, [pickerState.currentDate, pickerState.today, disableFuture]);

  const getDaysArrayByMonth = useCallback(() => {
    const { currentDate } = pickerState;
    const firstDateOfMonth = currentDate.clone().startOf('month');
    const lastDateOfMonth = currentDate.clone().endOf('month');
    const arrDays = enumerateDaysBetweenDates(firstDateOfMonth.clone(), lastDateOfMonth.clone());
    const firstDayByNumber = firstDateOfMonth.day();
    const previousDays = moment(firstDateOfMonth).subtract(firstDayByNumber, 'day');
    const diffPreviousDates = enumerateDaysBetweenDates(
      previousDays,
      firstDateOfMonth.clone().subtract(1, 'day')
    );
    const lastDayByNumber = lastDateOfMonth.day();
    const nextDays = moment(lastDateOfMonth).add(6 - lastDayByNumber, 'days');
    const diffNextDates = enumerateDaysBetweenDates(
      lastDateOfMonth.clone().add(1, 'day'),
      nextDays
    );
    return [...diffPreviousDates, ...arrDays, ...diffNextDates];
  }, [pickerState.currentDate]);

  const enumerateDaysBetweenDates = useCallback((startDate: Moment, endDate: Moment) => {
    const date = [];
    while (startDate <= endDate) {
      date.push(startDate.clone());
      startDate.add(1, 'day');
    }
    return date;
  }, []);

  const handleNonLinearSelection = useCallback(
    (date: Moment) => {
      const { onChange } = props;
      const { selected } = pickerState;
      if (Array.isArray(selected)) {
        const selectedFound = selected.findIndex((item) => item.isSame(date, 'date'));
        if (selectedFound !== -1) {
          selected.splice(selectedFound, 1);
          setPickerState((prevState) => ({
            ...prevState,
            selected: selected
          }));
          const dateObjects = selected.map((item) => item.toDate());
          return dateObjects.length
            ? onChange && onChange(dateObjects)
            : onChange && onChange(null);
        } else {
          selected.push(date);
          setPickerState((prevState) => ({
            ...prevState,
            selected: selected
          }));
          const dateObjects = selected.map((item) => item.toDate());
          return dateObjects.length
            ? onChange && onChange(dateObjects)
            : onChange && onChange(null);
        }
      } else {
        const newSelected = [date];
        setPickerState((prevState) => ({
          ...prevState,
          selected: newSelected
        }));
        const dateObjects = newSelected.map((item) => item.toDate());
        return onChange && onChange(dateObjects);
      }
    },
    [pickerState.selected, props.onChange]
  );

  const handleLinearDateSelection = useCallback(
    (date: Moment) => {
      const { onChange } = props;
      const { selected } = pickerState;
      if (selected && !Array.isArray(selected)) {
        const firstSelectedDate = selected.clone();
        if (firstSelectedDate.isSame(date, 'date')) {
          setPickerState((prevState) => ({ ...prevState, selected: null, rangeSelection: null }));
          onChange && onChange(null);
        } else if (firstSelectedDate.isBefore(date, 'date')) {
          const allDates = enumerateDaysBetweenDates(firstSelectedDate, date);
          setPickerState((prevState) => ({
            ...prevState,
            selected: allDates,
            rangeSelection: null
          }));
          const dateObjects = allDates.map((item) => item.toDate());
          onChange && onChange(dateObjects);
        } else {
          const allDates = enumerateDaysBetweenDates(date, firstSelectedDate);
          setPickerState((prevState) => ({
            ...prevState,
            selected: allDates,
            rangeSelection: null
          }));
          const dateObjects = allDates.map((item) => item.toDate());
          onChange && onChange(dateObjects);
        }
      } else {
        setPickerState((prevState) => ({ ...prevState, selected: date, rangeSelection: null }));
        onChange && onChange(null);
      }
    },
    [pickerState.selected, pickerState.rangeSelection, props.onChange]
  );

  const handleDateSelection = useCallback(
    (date: Moment) => {
      const { selected } = pickerState;
      const { onChange } = props;
      const dateSelected = selected && !Array.isArray(selected) && selected.isSame(date, 'date');
      if (dateSelected) {
        setPickerState((prevState) => ({ ...prevState, selected: null }));
        onChange && onChange(null);
      } else {
        setPickerState((prevState) => ({ ...prevState, selected: date }));
        onChange && onChange(date.toDate());
      }
    },
    [pickerState.selected, props.onChange]
  );

  const checkDateDisable = useCallback(
    (date: Moment) => {
      const { today, currentDate } = pickerState;
      const startOfMonth = currentDate.clone().startOf('month');
      const endOfMonth = currentDate.clone().endOf('month');
      if (disablePast && disableFuture) {
        const dateBefore = date.isBefore(today, 'date');
        const dateAfter = date.isAfter(today, 'month');
        return dateBefore || dateAfter;
      } else if (disablePast) {
        const dateBefore = date.isBefore(today, 'date');
        return dateBefore;
      } else if (disableFuture) {
        const dateAfter = date.isAfter(today, 'month');
        return dateAfter;
      } else {
        const dateBefore = date.isBefore(startOfMonth, 'date');
        const dateAfter = date.isAfter(endOfMonth, 'date');
        return dateBefore || dateAfter;
      }
    },
    [pickerState.today, pickerState.currentDate, disablePast, disableFuture]
  );

  const handleButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const { multiple } = props;
      const value = event.currentTarget.getAttribute('data-value');
      if (value) {
        const data = moment(JSON.parse(value));
        const dateSelectable = checkDateDisable(data);
        if (!dateSelectable) {
          if (multiple && multiple.toLowerCase() === 'non-linear') handleNonLinearSelection(data);
          else if (multiple && multiple.toLowerCase() === 'linear') handleLinearDateSelection(data);
          else if (!multiple) handleDateSelection(data);
        }
      }
    },
    [
      multiple,
      handleDateSelection,
      handleLinearDateSelection,
      handleNonLinearSelection,
      checkDateDisable
    ]
  );

  const handleHoverAction = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const { selected } = pickerState;
      if (multiple && multiple.toLowerCase() === 'linear') {
        const value = event.currentTarget.getAttribute('data-value');
        if (value) {
          const date = moment(JSON.parse(value));
          const selectableDate = checkDateDisable(date);
          if (selected && !Array.isArray(selected) && !selectableDate) {
            setPickerState((prevState) => ({ ...prevState, rangeSelection: date }));
          }
        }
      }
    },
    [pickerState.selected, multiple, pickerState.currentDate]
  );

  const renderDateCell = () => {
    const { multiple } = props;
    const { today, selected, currentDate, rangeSelection } = pickerState;
    const daysArray = getDaysArrayByMonth();
    const daysByRow: Moment[][] = [];
    for (let i = 0; i < daysArray.length; i += 7) {
      const data = daysArray.slice(i, i + 7);
      daysByRow.push(data);
    }
    const startOfMonth = currentDate.clone().startOf('month');
    const endOfMonth = currentDate.clone().endOf('month');
    return daysByRow.map((day: Moment[], index) => (
      <div key={index} className="date-picker-days-wrapper">
        {day.map((dayCell: Moment, index) => {
          let checkDateEqualClass = '';
          if (!disableHighlightToday)
            checkDateEqualClass = today.isSame(dayCell, 'date')
              ? 'date-picker-date-button--today'
              : '';

          let selectedDateClass = '';
          let divClass = '';
          if (!multiple && selected && !Array.isArray(selected)) {
            selectedDateClass = selected.isSame(dayCell, 'date')
              ? 'date-picker-date-button--radius'
              : '';
          } else if (
            multiple &&
            multiple.toLowerCase() === 'non-linear' &&
            Array.isArray(selected)
          ) {
            const selectedFound = selected.find((item) => item.isSame(dayCell, 'date'));
            selectedDateClass = selectedFound ? 'date-picker-date-button--radius' : '';
          } else if (multiple && multiple.toLowerCase() === 'linear') {
            if (rangeSelection && selected && !Array.isArray(selected)) {
              const firstSelected = selected.clone();
              const lastSelected = rangeSelection.clone();
              if (firstSelected.isBefore(rangeSelection, 'date')) {
                if (dayCell.isSame(firstSelected, 'date')) {
                  selectedDateClass = 'date-picker-date-button--radius';
                  divClass = dayCell.isSame(firstSelected, 'date')
                    ? 'date-picker-radius-first'
                    : 'date-picker-radius';
                } else if (dayCell.isBetween(firstSelected, lastSelected, 'date')) {
                  divClass = 'date-picker-date-hover';
                } else if (dayCell.isSame(lastSelected, 'date')) {
                  selectedDateClass = 'date-picker-date-button--radius-hover';
                  divClass = dayCell.isSame(firstSelected, 'date')
                    ? 'date-picker-radius-first'
                    : 'date-picker-radius';
                }
              } else if (firstSelected.isAfter(rangeSelection, 'date')) {
                if (dayCell.isSame(firstSelected, 'date')) {
                  selectedDateClass = 'date-picker-date-button--radius';
                  divClass = dayCell.isSame(firstSelected, 'date')
                    ? 'date-picker-radius'
                    : 'date-picker-radius-first';
                } else if (dayCell.isBetween(lastSelected, firstSelected, 'date')) {
                  divClass = 'date-picker-date-hover';
                } else if (dayCell.isSame(lastSelected, 'date')) {
                  selectedDateClass = 'date-picker-date-button--radius-hover';
                  divClass = dayCell.isSame(lastSelected, 'date')
                    ? 'date-picker-radius-first'
                    : 'date-picker-radius';
                }
              } else if (
                firstSelected.isSame(rangeSelection, 'date') &&
                dayCell.isSame(firstSelected, 'date')
              ) {
                selectedDateClass = 'date-picker-date-button--radius';
              }
            } else if (Array.isArray(selected)) {
              const firstSelected = selected[0].clone();
              const lastSelected = selected[selected.length - 1].clone();
              if (dayCell.isSame(firstSelected, 'date') || dayCell.isSame(lastSelected, 'date')) {
                selectedDateClass = 'date-picker-date-button--radius';
                divClass = dayCell.isSame(firstSelected, 'date')
                  ? 'date-picker-radius-first'
                  : 'date-picker-radius';
              } else if (dayCell.isBetween(firstSelected, lastSelected, 'date')) {
                divClass = 'date-picker-date-hover';
              }
            } else if (selected) {
              selectedDateClass = selected.isSame(dayCell, 'date')
                ? 'date-picker-date-button--radius'
                : '';
            }
          }

          let disabledDateClass = '';
          let disablePastDateClass = '';
          if (disablePast) {
            disablePastDateClass = dayCell.isBefore(today, 'date')
              ? 'date-picker-date-cell--disabled'
              : '';
          }
          if (disableFuture) {
            disabledDateClass = dayCell.isAfter(today, 'month')
              ? 'date-picker-date-cell--disabled'
              : '';
          }
          if (!disablePast && !disableFuture) {
            disabledDateClass =
              !startOfMonth.isSameOrBefore(dayCell, 'date') ||
              !endOfMonth.isSameOrAfter(dayCell, 'date')
                ? 'date-picker-date-cell--disabled'
                : '';
          }

          return (
            <div key={index} className={`date-picker-date-cell ${divClass}`}>
              <button
                type="button"
                data-value={JSON.stringify(dayCell)}
                className={`date-picker-date-button ${checkDateEqualClass} ${selectedDateClass}  ${disabledDateClass} ${disablePastDateClass}`}
                onMouseOver={handleHoverAction}
                onClick={handleButtonClick}>
                {dayCell.format('DD')}
              </button>
            </div>
          );
        })}
      </div>
    ));
  };

  return (
    <div
      className="date-picker-wrapper"
      style={{ height: `${props.height}`, width: `${props.width}` }}>
      <div className="date-picker-header">
        <div className="date-picker-icons-wrapper" onClick={changeToPreviousMonth}>
          <ImageComponent
            src={Images.leftChevron}
            alt="LeftChevron"
            customClass={
              disablePast && pickerState.currentDate.isSameOrBefore(pickerState.today)
                ? `date-picker-icon date-picker-icon-disabled`
                : 'date-picker-icon'
            }
          />
        </div>
        <p className="date-picker-header-title-wrapper">{getCurrentHeaderDetail()}</p>
        <div className="date-picker-icons-wrapper" onClick={changeToNextMonth}>
          <ImageComponent
            src={Images.rightChevron}
            alt="RightChevron"
            customClass={
              disableFuture && pickerState.currentDate.isSameOrAfter(pickerState.today)
                ? `date-picker-icon date-picker-icon-disabled`
                : 'date-picker-icon'
            }
          />
        </div>
      </div>
      <div className="date-picker-days-row">
        <div className="date-picker-days-wrapper">
          <div className="date-picker-day">
            <p className="date-picker-day-cell-text">Sun</p>
          </div>
          <div className="date-picker-day">
            <p className="date-picker-day-cell-text">Mon</p>
          </div>
          <div className="date-picker-day">
            <p className="date-picker-day-cell-text">Tue</p>
          </div>
          <div className="date-picker-day">
            <p className="date-picker-day-cell-text">Wed</p>
          </div>
          <div className="date-picker-day">
            <p className="date-picker-day-cell-text">Thu</p>
          </div>
          <div className="date-picker-day">
            <p className="date-picker-day-cell-text">Fri</p>
          </div>
          <div className="date-picker-day">
            <p className="date-picker-day-cell-text">Sat</p>
          </div>
        </div>
        <div className="date-picker-date-wrapper">{renderDateCell()}</div>
      </div>
    </div>
  );
}

export default DatePicker;
