import React, { useState } from 'react';
import { handleTabEventwithoutValue, handleTabKeyPosition } from '../../../../helpers/utils';
import CustomScroll from '../../../pages/Slots/components/custom-horzontal-scroll';
import './index.style.scss';

interface ISingleTabType {
  title: string | JSX.Element;
  tabData: JSX.Element;
}

interface ICustomTabProps {
  tabs: Array<ISingleTabType>;
  onSelect: (index: number) => void;
  selectedIndex: number;
  height?: number;
  showViewAll?: boolean;
  handleViewAll?: () => void;
}

const TabComponent = ({
  tabs,
  selectedIndex = 0,
  onSelect,
  height,
  showViewAll,
  handleViewAll
}: ICustomTabProps) => {
  const [cursor, setCursor] = useState(0);
  const handleSelect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const selectedIndex = Number(event?.currentTarget?.id);
    onSelect(selectedIndex);
  };

  return (
    <div className="tab-container">
      <div className={showViewAll ? 'tab-header no-border' : 'tab-header'}>
        <div className="tabs">
          {tabs?.map((item: ISingleTabType, index: number) => {
            return (
              <div
                tabIndex={0}
                className={selectedIndex === index ? 'tab-title selected' : 'tab-title'}
                id={index.toString()}
                key={index}
                onKeyDown={handleTabKeyPosition(index, onSelect, setCursor)}
                onClick={handleSelect}>
                {item?.title}
              </div>
            );
          })}
        </div>
        <div className="view-all">
          {showViewAll && (
            <div
              onClick={handleViewAll}
              tabIndex={0}
              onKeyDown={handleViewAll && handleTabEventwithoutValue(handleViewAll)}>
              View All{' '}
            </div>
          )}
        </div>
      </div>
      <div className="tab-data">{tabs ? tabs[selectedIndex]?.tabData : null}</div>
    </div>
  );
};
export default TabComponent;
