export const RECRUITER = 'RECRUITER';
export const PANEL = 'PANEL';
export const PANEL_LABEL = 'Panel';
export const TALENT_ACQUISITION = 'Talent Acquisition';
export const PANELLIST = 'Panellist';
export const BOTH = 'Both';
export const LIST = 'List';
export const GRID = 'Grid';
export const DATA_VALUE = 'data-value';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_PANEL = 'ROLE_PANEL';
export const ROLE_RECRUITER = 'ROLE_RECRUITER';
export const LOGIN_ROLE_ERROR_LINK_TEXT = 'contact Slotify support.';
export const CONTACT_ADMIN_EMAIL = 'mailto:slotify.support@coditas.com';
export const DELETE_MODAL_TITLE = 'Delete User';
export const SAVE_MODAL_TITLE = 'Add User';
export const EDIT_MODAL_TITLE = 'Edit User';
export const APPROVE_BTN = 'Approve';
export const DELETE_ACTION = 'delete';
export const EDIT_ACTION = 'edit';
export const NAME = 'name';
export const EMAIL = 'email';
export const NAME_RECRUITER = 'recruiter-name';
export const TECH_STACK = 'tech-stack';
export const PENDING_APPROVAL_TITLE = 'Pending Approvals';
export const PENDING_APPROVAL_LINK = 'Start Approving';
export const RECENTLY_APPROVED_TITLE = 'Recently Approved';
export const SEARCH_RESULT_MESSAGE = 'Search Results For ';
export const CLEAR_RESULT_TEXT = 'Clear Results';
export const SEARCH = 'Search';
export const TODAY = 'Today';
export const SEVEN_DAYS = '7 days';
export const THIRTY_DAYS = '30 days';
export const TWELVE_MONTH = '12 months';
export const TOTAL_TA = 'Total TA';
export const TOTAL_PANEL = 'Total Panel';
export const TOTAL_TECH_STACK = 'Total Tech Stacks';
export const WELCOME = 'Welcome!';
export const BTN_LABEL_ASSIGN_PANELS = 'Assign Panels';
export const ONBOARD_USER_TITLE = 'Onboard User';
export const SIGN_IN_MESSAGE = 'Sign in to Slotify';
export const LOGIN_BUTTON_TEXT = 'Sign In with Google';
export const ROLE_BOTH = { id: 0, label: 'Both' };
export const STATUSENUM_ARCHIVE = 'ARCHIVE';
export const APPROVE_BTN_TEXT = 'Approve';
export const MASS_ONBOARDING_HEADING = 'Mass Onboarding';
export const ONBOARDING_HEADING = 'Onboarding';
export const STATUSENUM_IN_REVIEW = 'IN_REVIEW';
export const STATUSENUM_APPROVED = 'APPROVED';
export const PROJECT_TAB = 'project';
export const APPROVE_TAB = 'approve';
export const STATUS = 'status';
export const BENCH_TAB = 'bench';
export const ALL_TAB = 'all';
export const ONBOARD_BTN = 'onboard';
export const IN_REVIEW_TAB = 'In Review';
export const APPROVED_TAB = 'Approved';
export const ARCHIEVED_TAB_VALUE = 'Archived';
export const ARCHIEVED_TAB = 'archieve';
export const NAME_A_to_Z = 'Name - A to Z';
export const NAME_Z_to_A = 'Name - Z to A';
export const EXP_L_TO_H = 'Experience - Low to High';
export const EXP_H_TO_L = 'Experience - High to Low';
export const RECENTLY_ADDED = 'Recently Added';
export const EXP = 'experience';
export const RESIZE = 'resize';
export const TECH = 'tech';
export const LEVELCASE = 'level';
export const CANCEL = 'Cancel';
export const DELETE = 'Delete';
export const PANEL_SEARCH_PLACEHOLDER = 'Search for Panel';
export const ADMIN_FILTER_RESET_DISABLED = 'admin-filter-reset-disabled';
export const ADMIN_FILTER_RESET_BUTTON = 'admin-filter-reset-button';
export const ADMIN_FILTER_APPLY_DISABLED = 'admin-filter-apply-disabled';
export const ADMIN_FILTER_APPLY_BUTTON = 'admin-filter-apply-button';
export const MEDIUM = 'medium';
export const OUTLINED = 'outlined';
export const CONTAINED = 'contained';
export const CLEAR_RESULTS = 'Clear Results';
export const SEARCH_RESULTS = 'Search Results For';
export const SAVE_PANEL = 'Save Panel';
export const AP_PANEL = 'Panel';
export const NAME_ASC = 'NAME_ASC';
export const NAME_DESC = 'NAME_DESC';
export const EXP_ASC = 'EXP_ASC';
export const EXP_DESC = 'EXP_DESC';
export const ADDED_ON_ASC = 'ADDED_ON_ASC';
export const RECENTY_ADDED_PANEL = 'RECENTLY_ADDED_DESC';
export const NEXT = 'Next';
export const PREVIOUS = 'Previous';
export const DELETE_PANEL = 'Delete Panel';
export const TIME_INTERVAL = 800;
export const DATE_FORMAT = 'en-ZA';
export const MARK_ON_BENCH = 'Mark on Bench';
export const ADD = 'add';
export const EDIT = 'edit';
export const IMAGE_FILE = 'imageFile';
export const GO_BACK_BUTTON = 'Go back';
export const DELETE_LOI_TITLE = 'Delete Level of Panel';
export const EDIT_LOI_TITLE = 'Edit Level of Panel';
export const ADD_LOI_TITLE = 'Add Level of Panel';
export const LEVEL_TITLE = 'Level Of Panel';
export const LEVEL_LABEL = 'Level Of Panel *';
export const NUMBER_OF_PANEL = 'Number of Panel';
export const LEVEL = 'Level Name';
export const EXPERIENCE = 'Experience';
export const SAVE_BUTTON_TEXT = 'Save';
export const CANCEL_BUTTON_TEXT = 'Cancel';
export const DELETE_BUTTON_TEXT = 'Delete';
export const EDIT_BUTTON_TEXT = 'Edit';
export const ADD_LEVEL_BUTTON_TEXT = 'Add Level';
export const DELETE_LEVEL_TEXT = 'Delete Level';
export const LEVEL_NAME = 'levelName';
export const LEVEL_EXPERIENCE = 'levelExperience';
export const LEVEL_NAME_PLACEHOLDER = 'Enter level name';
export const WELCOME_TEXT = 'Welcome';
export const PENDING_APPROVAL = 'Pending Approval';
export const FILTER = 'Filter';
export const RESET = 'Reset';
export const APPLY = 'Apply';
export const ADD_TECH_BUTTON_TEXT = 'Add Tech Stack';
export const DELETE_TECH_TEXT = 'Delete Tech Stack';
export const TECH_NAME = 'techName';
export const TECH_STACK_HEADING = 'Tech Stacks';
export const TECH_STACK_LABEL = 'Tech Stack *';
export const UPLOAD_IMAGE_TEXT = ' Click to upload';
export const TECH_NAME_PLACEHOLDER = 'Enter the Tech Stack (Eg. Java)';
export const MOUSE_DOWN = 'mousedown';
export const ADD_BUTTON_LABEL = 'Add';
export const TECH_STACK_ASC = 'TECH_STACK_ASC';
export const TECH_STACK_DESC = 'TECH_STACK_DESC';
export const ADDED_ON_DESC = 'ADDED_ON_DESC';
export const ADD_PROJECT_BUTTON_TEXT = 'Add Project';
export const ADD_BUTTON_TEXT = 'Add';
export const DELETE_PROJECT = 'Delete Project';
export const PROJECT_NAME = 'techName';
export const PROJECT_HEADING = 'Projects';
export const PROJECT_LABEL = 'Name';
export const NUMBER_OF_PANELS_TEXT = 'Number of Panels:';
export const YEAR_TEXT = 'years';
export const PROJECT_ASC = 'PROJECT_ASC';
export const PROJECT_DESC = 'PROJECT_DESC';
export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export const DEFAULTPAGESIZE = 10;
export const PANEL_FILTER_TOOLTIP_LENGTH = 20;
export const RECRUITER_FILTER_LENGTH = 20;
export const SLOTS = 'Slots';
export const PROJECT_DRAWER_LABEL = 'Project Name *';
export const UNDO = 'Undo';
export const SORT_BY = 'Sort By';
export const CANDIDATE_CANCEL_ENUM = 'CANDIDATE_CANCEL';
export const PANEL_CANCEL_ENUM = 'PANEL_CANCEL';
export const OTHERS_ENUM = 'OTHER';
export const MY_SCHEDULE = 'My Schedule';
export const INTERVIEW_LIMIT = 'Interview Limit';
export const CALENDAR_SYNC = 'Calendar Sync';
export const G_SUITE = 'Gmail, G Suite';
export const CONNECT = 'Connect';
export const DIS_CONNECT = 'Disconnect';
export const MAX_INTERVIEWS = 'Max Interviews:';
export const WEEKLY = 'Weekly';
export const DAILY = 'Daily';
export const TOP_INTERVIEWER = 'Top Interviewer';
export const INTERVIEWS = 'interviews';
export const STREAK = 'Streak';
export const WEEKS_IN_A_ROW = 'weeks in a row';
export const MILESTONE = 'Milestone';
export const SUCESSFUL_INTERVIEWS = 'successful interviews';
export const SLOT_ACHIEVEMENTS = 'Slot Achievements';
export const ISM_SUPPORT_MAIL = 'mailto:slotify.support@coditas.com';
export type CANCELLATION_OPTION_TYPE = 'CANDIDATE_CANCEL' | 'PANEL_CANCEL' | 'OTHER';
export const PANEL_BUSY = 'Panel busy';
export const CANDIDATE_BUSY = 'Candidate busy';
export const OTHER_BUSY = 'Other';
export const SLOT_CANCEL_OPTION = [CANDIDATE_BUSY, PANEL_BUSY, OTHER_BUSY];
export const DEFAULT_WEEKLY_INTERVIEW_LIMIT = 50;
export const DEFAULT_DAILY_INTERVIEW_LIMIT = 50;
export const SELECT_ALL = 'Select All';
export const ORANGE = 'orange';
export const SIXTY_MINUTES = 60;
export const AVAILABLE = 'Available';
export const BOOKED = 'Booked';
export const CANCELLED = 'Cancelled';
export const ALL = 'All';
export const CANCEL_LABEL = 'CANCEL';
export const SLOT_COUNT = 'SLOT_COUNT';
export const FETCH_ALL_SLOTS = 'FETCH_ALL_SLOTS';
export const SLOT_COUNT_FOR_SPECIFIC_USER = 'SLOT_COUNT_FOR_SPECIFIC_USER';
export const REQUESTED_SLOT = 'Requested Slots';
export const TOMORROW = 'Tomorrow';
export const START_TIME = '10:00 am';
export const END_TIME = '11:00 am';
export const FORMAT = 'hh::mm';
export const EIGTH_THIRTY = '8:30 PM';
export const EIGTH_FIFTEEN = '8:15 PM';
export const PAGE_NUMBER_SET = 5;
export const IN_REVIEW = 'IN_REVIEW';
export const APPROVED = 'Approved';
export const RECRUITER_ID = '3';
export const PANEL_ID = '2';
export const ARCHIVE = 'ARCHIVE';
export const APPROVE = 'APPROVED';
export const SELECT_PROJECT = 'Select Project';
export const COPYRIGHT = ' © 2023 Coditas';
export const REQUESTED = 'Requested';
export const GOOGLE = 'Google';
export const GOOGLE_EVENTS = 'Google Events';
export const SELECT = 'Select';
export const RED = 'RED';
export const BLUE = 'BLUE';
export const GREEN = 'GREEN';
export const YELLOW = 'YELLOW';
export const GREY = 'GREY';
export const CANDIDATE_NAME = 'Candidate name*';
export const INTERVIEW_DETAILS = 'Interview Details';
export const REVIEW = 'REVIEW';

export const EXPERIENCEFILTER = [
  {
    experienceFrom: 0,
    experienceTo: 2,
    label: '0 - 2 years'
  },
  {
    experienceFrom: 6,
    experienceTo: 8,
    label: '6 - 8 years'
  },
  {
    experienceFrom: 2,
    experienceTo: 4,
    label: '2 - 4 years'
  },
  {
    experienceFrom: 8,
    experienceTo: 12,
    label: '8 - 12 years'
  },
  {
    experienceFrom: 4,
    experienceTo: 6,
    label: '4 - 6 years'
  },
  {
    experienceFrom: 12,
    experienceTo: 40,
    label: '12+ years'
  }
];
export const PANEL_STATUS = {
  archive: 'ARCHIVE',
  inReview: 'IN_REVIEW',
  approved: 'APPROVED'
};

export const STATUS_OPTIONS = [
  { id: 0, label: 'In Review' },
  { id: 1, label: 'Approved' }
];

export const SCHEDULE_STATUS = {
  AVAILABLE: 1,
  BOOKED: 2,
  CANCELLED: 3
};
export const CANCELLATION_OPTION = {
  panel: 'PANEL_CANCEL' as 'PANEL_CANCEL',
  candidate: 'CANDIDATE_CANCEL' as 'CANDIDATE_CANCEL',
  other: 'OTHER' as 'OTHER'
};

export const SLOT_REQUEST = {
  ACCEPT: 'ACCEPT',
  DECLINE: 'DECLINE'
};

export const statusOptions = [
  {
    id: 1,
    label: IN_REVIEW_TAB,
    showDot: true
  },
  { id: 2, label: APPROVE_BTN, showDot: true }
];

export const userTabs = ['Onboard Users', 'Manage Panellists'];
export const ARCHIVED = 'Archived';
export const DELETE_AVAILABLE_SLOT = 'DELETE_AVAILABLE_SLOT';
export const DELETE_REQUESTED_SLOT = 'DELETE_REQUESTED_SLOT';
export const CANCEL_SLOT = 'CANCEL_SLOT';
export const BOOK_REQUESTED_SLOT = 'BOOK_REQUESTED_SLOT';
export const FETCH_REQUESTED_SLOTS = 'FETCH_REQUESTED_SLOTS';
export const BOOK_SLOT = 'BOOK_SLOT';
export const FETCH_CANCELLED_SLOTS = 'FETCH_CANCELLED_SLOTS';
export const FETCH_DASHBOARD_BOOKED_SLOTS = 'FETCH_DASHBOARD_BOOKED_SLOTS';
export const FETCH_BOOKED_SLOTS = 'FETCH_BOOKED_SLOTS';
export const FETCH_AVAILABLE_SLOTS = 'FETCH_AVAILABLE_SLOTS';
export const FETCH_SPECIFIC_BOOKED_SLOTS = 'FETCH_SPECIFIC_BOOKED_SLOTS';
export const VALIDATE_REQUESTED_SLOT = 'VALIDATE_REQUESTED_SLOT';
export const FETCH_SPECIFIC_CANCELLED_SLOTS = 'FETCH_SPECIFIC_CANCELLED_SLOTS';
export const FETCH_SPECIFIC_AVAILABLE_SLOTS = 'FETCH_SPECIFIC_AVAILABLE_SLOTS';
export const ADD_SLOT = 'ADD_SLOT';
export const DESIGNATION = 'Designation';
export const ON_BENCH = 'On Bench';
export const EMAIL_ADDRESS = 'Email Address';
export const PHONE_NUMBER = 'Phone Number';
export const TECH_STACK_LOP = 'Tech Stack and Level of Panel';
export const TECH_STACKS = 'Tech Stacks';
export const PROJECTS = 'Projects';
export const LOP_LABEL = 'Level of Panel*';
export const NO_LABEL = 'No';
export const YES_LABEL = 'Yes';
export const LOGOUT_TEXT = 'Log out';
export const VIEW_ALL = 'View All';
export const TS_LABEL = 'TALENT_ACQUISITION';
export const ROLE_LABEL = 'Role*';
export const DMY_FORMAT = 'DD MMMM YYYY';
export const YMDH_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const CANCEL_BOOKING = ' Cancel Booking';
export const REMARK = 'Remark';
export const CANCELLATION_REASON = ' Reason for cancellation*';
export const ZOHO_RECRUIT = 'Zoho Recruit';
export const SLOT_COUNT_LIMIT = 300;
export const ADD_SlOTS = 'Add Slot(s)';
export const SELECT_DATES = 'Select Date(s) and slots';
export const USER_STATUS = [
  {
    id: 1,
    label: 'In Review',
    showDot: true
  },
  { id: 2, label: 'Approved', showDot: true }
];
export const PROJECTSTATUSOPTIONS = [
  {
    id: 1,
    label: 'All'
  },
  { id: 2, label: 'On Project' },
  { id: 3, label: 'On Bench' }
];
export const WINDOW_WIDTH = 992;
export const VIEW_DETAILS = 'View details';
export const ALL_LABEL = 'All';
export const ON_PROJECT_DRPDWN = 'On Project';
export const ON_PROJECT = 'ON_PROJECT';
export const ON_BENCH_PROJECT = 'ON_BENCH';
export const PanelTabValues = ['Onboard Users', 'Manage Panellists'];
export const MASS_ONBOARDING = 'Mass Onboarding';
export const ONBOARD_USER = 'Onboard User';
export const ONBOARDING_USERS = 'Onboarding Users';
export const ONBOARD_TEXT = 'Onboard';
export const LOI_LABEL = 'Level of Interview *';

// enum ASCII
export enum ASCII {
  backspace = 'Backspace',
  enter = 'Enter',
  tab = 'Tab',
  arrowDown = 'ArrowDown',
  arrowUP = 'ArrowUp'
}
export const TECH_STACK_FILTER = 'Tech Stack';
export const LEVEL_FILTER = 'Level of Panel';
export const EXPERIENCE_FILTER = 'Experience';
export const PROJECT_FILTER = 'Project';
export const SEARCH_TECH_STACK = 'Search Tech Stack';
export const SEARCH_PROJECT = 'Search Projects';
export const FILTER_BY = 'Filter By';
export const FILTER_EXPERIENCE = [
  '0 to 2 years',
  '2 to 4 years',
  '4 to 8 years',
  '8-12 years',
  '+12 years'
];
export const BOOKED_SLOT = 'Booked Slot';
export const CANCELLED_SLOT = 'Cancelled Slot';
export const TECHSTACK_LEVEL = 'Tech Stack - Level';
export const MODAL_PANEL = 'Panel';
export const CANCELLED_BY = 'Cancelled By';
export const REASON_FOR_CANCELLATION = 'Reason For Cancellation';
export const BOOKED_BY = 'Booked By';
export const CANDIDATE = 'Candidate';
export const MODAL_CANCEL_BOOKING = 'Cancel Booking';
export const GETLOI = 'getLOI';
export const ZERO_EXPERIENCE = '0m';

//Notification
export const GENERAL = 'General';
export const ACTIVITY_LOG = 'Activity log';
export const MARK_ALL_AS_READ = 'Mark all as read';
export enum NOTIFICATION_ENTITY_ENUM {
  SLOT_REQUIREMENT_ADDED = 'SLOT_REQUIREMENT_ADDED',
  SLOT_REQUIREMENT_FULFILLED = 'SLOT_REQUIREMENT_FULFILLED',
  SLOT_CANCELLED = 'SLOT_CANCELLED',
  SLOT_BOOKED = 'SLOT_BOOKED',
  SLOT_REQUEST_ACCEPTED = 'SLOT_REQUEST_ACCEPTED',
  SLOT_ADDED = 'SLOT_ADDED',
  REWARDS = 'REWARDS',
  CALENDAR_SYNC = 'CALENDAR_SYNC',
  TECH_STACK = 'TECH_STACK',
  //LEVEL_OF_PANEL
  INTERVIEW_LEVEL = 'INTERVIEW_LEVEL',
  PROJECT = 'PROJECT',
  PROJECT_ASSIGNED = 'PROJECT_ASSIGNED',
  PROJECT_UNASSIGNED = 'PROJECT_UNASSIGNED',
  ADMIN = 'ADMIN',
  TECH_STACK_ASSIGNED = 'TECH_STACK_ASSIGNED',
  TECH_STACK_UNASSIGNED = 'TECH_STACK_UNASSIGNED',
  TECH_STACK_LEVEL_ASSIGNED = 'TECH_STACK_LEVEL_ASSIGNED',
  TECH_STACK_LEVEL_UNASSIGNED = 'TECH_STACK_LEVEL_UNASSIGNED'
}
export const GET_GENERAL_NOTIFICATION = 'get general notification';
export const GET_ACTIVITY_NOTIFICATION = 'get activity notification';
export const SCROLL_DEBOUCE_TIME = 600;
export const AVAILABLE_SLOT = 'Available Slot';
export const DASHBOARD = 'Dashboard';
export const ENABLE = 'enable';
export const DISABLE = 'disable';
export const NEW_REQUEST = 'New Request';
export const PLUS_NOTIFICATION = '99+';
export const MAX_NOTIFICATION_COUNT = 99;
export const PULSE_ANIMATION_TIMEOUT = 2500;
export const RECRUITER_LABEL = 'Recruiter';
export const NO_NOTIFICATION_GENERAL = 'You have no notifications.';
export const NO_NOTIFICATION_ACTIVITY = 'You have no entries.';
export const NO_NOTIFICATION_MSG = `When they arrive, you'll see them here.`;

// Google Analytics

export const WEEK_VIEW = 'Week View';
export const DAY_VIEW = 'Day View';
export const MONTH_VIEW = 'Month View';
export const ADD_SLOT_BUTTON = 'Add Slot Button Click';
export const ADD_SLOT_THROUGH_CALENDAR = 'Add Slot Through Calendar';
export const SLOT_REQUEST_BUTTON = 'Slot Request Button';
export const SLOT_REQUEST_FORM_DATA = 'Slot Request Form Data';
export const GA_CATEGORY = {
  SCHEDULE: 'Schedule',
  SLOTS: 'Slot'
};
export const GA_LABEL = {
  SCHEDULE: 'schedule_page',
  SLOTS: 'slots_page'
};
export enum GA_EVENT_TYPES {
  BUTTON_CLICK = 'button_click',
  FORM_SUBMIT = 'form_submit'
}
