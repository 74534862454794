import { ISelectOptions } from '../customSelect';
import { CSSObjectWithLabel, StylesConfig } from 'react-select';
import { theme } from '../../../../assets/styles/theme';

export const techStackStyles: StylesConfig<ISelectOptions> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    boxSizing: 'border-box',
    borderRadius: '0.5rem',
    border: state.isFocused ? theme.primaryBorder : theme.checkboxBorder,
    minHeight: 48,
    backgroundColor: state.isDisabled ? theme.backgroundColor : 'inherit',
    justifyContent: 'flex-start',
    gap: '0.625rem',
    minWidth: 120,
    boxShadow: state.isFocused ? theme.inputBoxShadow : theme.tagBoxShadow,
    padding: '0.25rem 0.5rem 0.25rem 1rem',
    color: theme.textColorPrimary,
    cursor: 'pointer',
    '&:hover': {
      border: theme.primaryBorder,
      boxShadow: theme.inputBoxShadow
    },
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0
  })
};

export const techStackStyleError: StylesConfig<ISelectOptions> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    boxSizing: 'border-box',
    borderRadius: '0.5rem',
    border: state.isFocused ? theme.primaryBorder : theme.errorBorder,
    minHeight: 48,
    backgroundColor: state.isDisabled ? theme.backgroundColor : 'inherit',
    justifyContent: 'flex-start',
    gap: '0.625rem',
    minWidth: 120,
    boxShadow: state.isFocused ? theme.inputBoxShadow : theme.tagBoxShadow,
    padding: '0.25rem 0.5rem 0.25rem 1rem',
    color: theme.textColorPrimary,
    cursor: 'pointer',
    '&:hover': {
      border: theme.primaryBorder,
      boxShadow: theme.inputBoxShadow
    },
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  })
};

export const projectStyles: StylesConfig<ISelectOptions> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    boxSizing: 'border-box',
    borderRadius: '0.5rem',
    border: state.isFocused ? theme.primaryBorder : theme.checkboxBorder,
    minHeight: 48,
    backgroundColor: state.isDisabled ? theme.backgroundColor : 'inherit',
    justifyContent: 'flex-start',
    gap: '0.625rem',
    minWidth: 120,
    boxShadow: state.isFocused ? theme.inputBoxShadow : theme.tagBoxShadow,
    padding: '0.25rem 0.5rem 0.25rem 1rem',
    color: theme.textColorPrimary,
    cursor: 'pointer',
    '&:hover': {
      border: theme.primaryBorder,
      boxShadow: theme.inputBoxShadow
    },
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  })
};

export const projectStylesError: StylesConfig<ISelectOptions> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    boxSizing: 'border-box',
    borderRadius: '0.5rem',
    border: state.isFocused ? theme.checkboxBorder : theme.errorBorder,
    minHeight: 48,
    backgroundColor: state.isDisabled ? theme.backgroundColor : 'inherit',
    justifyContent: 'flex-start',
    gap: '0.625rem',
    minWidth: 120,
    boxShadow: state.isFocused ? theme.inputBoxShadow : theme.tagBoxShadow,
    padding: '0.25rem 0.5rem 0.25rem 1rem',
    color: theme.textColorPrimary,
    cursor: 'pointer',
    '&:hover': {
      border: theme.primaryBorder,
      boxShadow: theme.inputBoxShadow
    },
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  })
};
