import React from 'react';
import { components } from 'react-select';
import Images from '../../../../../assets/images';
import ImageComponent from '../../image';

const MultiValueRemove = (props: any) => {
  const { closeIcon } = Images;
  return (
    <components.MultiValueRemove {...props}>
      <ImageComponent src={closeIcon} />
    </components.MultiValueRemove>
  );
};

export default MultiValueRemove;
