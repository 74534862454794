import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Images from 'assets/images';
import ClickAwayListener from 'app/core/shared-components/click-away';
import Tag from 'app/core/shared-components/tag';
import ImageComponent from '../image';
import Typography from '../typography';
import './index.style.scss';
import { theme } from 'assets/styles/theme';
import { NO_RESULT_FOUND_ERROR } from 'helpers/messages.constants';

export interface IOptionType {
  startIcon?: string;
  searchKey: string;
  supportingData?: string;
  tags?: string[];
  id: string;
  singleSearchData: any;
}
interface ISearchProps {
  options: IOptionType[];
  placeholder: string;
  label?: string;
  clearResult?: boolean;
  onChange?: (searchString: string) => void;
  setSelectedId?: (id: number | string, singleSearchData: any) => void;
  tagStyle?: string;
  resultNotFound?: boolean;
  customDropdownIconClass?: string;
}

interface ISearchStates {
  searchOptions: IOptionType[];
  isDropdownOpen: boolean;
  selectedItem: IOptionType;
  searchValue: string;
  isOptionsVisible?: boolean;
  showLoader: boolean;
  isResultNotFound: boolean;
}

const Search = (props: ISearchProps) => {
  const [searchState, setSearchState] = useState<ISearchStates>({
    isDropdownOpen: false,
    selectedItem: {} as IOptionType,
    searchOptions: [],
    searchValue: '',
    isOptionsVisible: false,
    showLoader: true,
    isResultNotFound: false
  });
  const [cursor, setCursor] = useState(-1);
  const [inputValue, setInputValue] = useState('');

  const refOptn = useRef<HTMLDivElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);

  const { isDropdownOpen, searchValue, searchOptions, isResultNotFound } = searchState;
  const {
    customDropdownIconClass,
    resultNotFound,
    tagStyle,
    placeholder,
    options,
    clearResult = false
  } = props;
  const { search, close, noResult, loaderSpinner } = Images;

  function handleKeyUp() {
    setSearchState((prevState) => ({
      ...prevState,
      showLoader: searchValue.trim().length ? true : false
    }));
  }

  function onOptionSelected(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) {
    event?.currentTarget?.id && event?.stopPropagation();
    const id = event?.id ? event?.id : event?.currentTarget?.id;

    const foundIndex = options.findIndex((item) => item?.id.toString() === id);
    setSearchState((prevState) => ({
      ...prevState,
      searchOptions: options,
      selectedItem: {
        ...options[foundIndex]
      },
      searchValue: options[foundIndex]?.searchKey,
      isDropdownOpen: false
    }));
    props.setSelectedId &&
      props.setSelectedId(id, searchState?.searchOptions[foundIndex]?.singleSearchData);
    setCursor(-1);
    setInputValue('');
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.stopPropagation();
    setCursor(-1);
    const { onChange } = props;
    const currentValue = event?.currentTarget?.value;
    setInputValue(currentValue);
    setSearchState((prevState) => ({
      ...prevState,
      showLoader: true,
      isResultNotFound: false,
      isDropdownOpen: options?.length || currentValue ? true : false,
      searchValue: currentValue,
      searchOptions: options?.filter((option) =>
        option?.searchKey?.toLowerCase().includes(currentValue.toLowerCase())
      )
    }));
    onChange && onChange(currentValue);
  }

  function handleFocus() {
    setSearchState((prevState) => ({
      ...prevState,
      isDropdownOpen: options?.length ? true : false
    }));
    if (searchValue.trim().length === 0)
      setSearchState({ ...searchState, isResultNotFound: false });
  }

  function clearInput(event: React.MouseEvent<HTMLImageElement>) {
    event?.stopPropagation();
    setSearchState((prevState) => ({
      ...prevState,
      searchValue: ''
    }));
  }

  const handleCloseDropdown = useCallback(() => {
    setSearchState((prevState) => ({
      ...prevState,
      isDropdownOpen: false
    }));
  }, [isDropdownOpen]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 40) {
      const maxLength = searchOptions?.length < 5 ? searchOptions?.length : 5;
      if (cursor + 1 < maxLength) {
        setCursor(cursor + 1);
      } else {
        if (searchOptions?.length - 1 > cursor && dropRef?.current && refOptn?.current) {
          dropRef.current.scrollTop += refOptn.current.clientHeight;
          setCursor(cursor + 1);
        }
      }
    } else if (event.keyCode === 38) {
      if (cursor - 1 >= 0 && dropRef.current && refOptn.current) {
        dropRef.current.scrollTop -= refOptn.current?.clientHeight;
        setCursor(cursor - 1);
      }
    } else if (event.keyCode === 13 && searchOptions[cursor]) {
      inputValue.length !== 0
        ? (event.preventDefault(),
          event.currentTarget.blur(),
          onOptionSelected(searchOptions[cursor]))
        : event.preventDefault();
    }
  };

  const handleTab = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const index = searchOptions.findIndex((option) => option.id === event.currentTarget.id);
    if (event.keyCode === 13 && searchOptions[index]) {
      inputValue.length !== 0 ? onOptionSelected(searchOptions[index]) : event.preventDefault();
    }
  };

  const setOptions = () => {
    setSearchState((prevState) => ({
      ...prevState,
      searchOptions: options
    }));
  };

  const setResultNotFound = () => {
    if (resultNotFound === true && searchValue?.trim()?.length > 0)
      setSearchState((prevState) => ({
        ...prevState,
        isResultNotFound: resultNotFound
      }));
  };

  const setClearResults = () => {
    if (clearResult) {
      setSearchState((prevState) => ({
        ...prevState,
        searchValue: '',
        isDropdownOpen: false
      }));
    }
  };
  useEffect(() => {
    setOptions();
  }, [options]);

  useEffect(() => {
    setResultNotFound();
  }, [resultNotFound, searchValue]);

  useEffect(() => {
    setClearResults();
  }, [clearResult]);

  return (
    <div className="search" onClick={handleFocus}>
      <ClickAwayListener handleClose={handleCloseDropdown} customClass="search-wrapper">
        <div
          className="search-inputs"
          style={
            isDropdownOpen
              ? {
                  border: theme.primaryBorder,
                  boxShadow: theme.inputBoxShadow
                }
              : undefined
          }>
          <div className="search-icon">
            <ImageComponent src={search} />
          </div>
          <input
            type="text"
            placeholder={placeholder}
            value={searchValue}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            tabIndex={0}
            onKeyDown={handleKeyDown}
          />

          {!!searchValue && (
            <div className="close-icon">
              <ImageComponent src={close} onClick={clearInput} />
            </div>
          )}
        </div>

        {isDropdownOpen && (
          <div className="dropdown-wrapper">
            {!!searchValue?.length && searchState.showLoader && (
              <div className="dropdown-content" ref={dropRef}>
                {!searchOptions?.length && (
                  <div className="no-results">
                    {isResultNotFound ? (
                      <Fragment>
                        <ImageComponent src={noResult} className="no-results-image" />
                        <Typography variant="text" customStyle="name">
                          {NO_RESULT_FOUND_ERROR}
                        </Typography>
                      </Fragment>
                    ) : (
                      <div className="search--loading-wrapper">
                        <ImageComponent src={loaderSpinner} className="search--loading-spinner" />
                      </div>
                    )}
                  </div>
                )}
                {searchOptions?.map((option: IOptionType, index: number) => (
                  <div
                    ref={cursor === index ? refOptn : null}
                    tabIndex={0}
                    onKeyDown={handleTab}
                    className={cursor === index ? 'options selectedOption' : 'options'}
                    key={uuidv4()}
                    id={option.id}
                    onClick={onOptionSelected}>
                    <div className="option-content">
                      <div className="option-image">
                        <ImageComponent
                          src={option?.startIcon || null}
                          fallbackClass={
                            customDropdownIconClass ||
                            (option?.tags ? 'icon-leading-circle alt' : 'icon-leading alt')
                          }
                          fallbackText={`${option?.searchKey
                            ?.split(' ')[0]
                            .charAt(0)
                            .toUpperCase()}`}
                          className={
                            customDropdownIconClass ||
                            (option?.tags ? 'icon-leading-circle' : 'icon-leading')
                          }
                        />
                      </div>
                      <div className="option-data">
                        <Typography variant="text" customStyle="name">
                          {option?.searchKey}
                        </Typography>
                        {option?.supportingData && (
                          <Typography variant="captions" customStyle="supporting-data">
                            {option?.singleSearchData?.designation}
                          </Typography>
                        )}

                        {!!option?.tags && (
                          <div className="tags">
                            {option?.tags?.map((tag: string) => (
                              <Tag
                                label={tag}
                                customClass={tagStyle ? props?.tagStyle : 'single-tag'}
                                key={uuidv4()}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </ClickAwayListener>
    </div>
  );
};

export default React.memo(Search);
