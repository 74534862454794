import * as yup from 'yup';

export const LevelOfPanelValidation = yup.object({
  levelValue: yup
    .string()
    .required('Please enter Level name')
    .max(30, 'Maximum length must be 30')
    .matches(
      /^(?=.*\d)[ a-zA-Z0-9._]+$/,
      'No Special Characters Allowed and Level should contain atleast one digit.'
    )
});

export const LevelOfPanelEditValidation = yup.object({
  levelValue: yup
    .string()
    .required('Please enter Level name')
    .max(30, 'Maximum length must be 30')
    .matches(
      /^(?=.*\d)[ a-zA-Z0-9._]+$/,
      'No Special Characters Allowed and Level should contain atleast one digit.'
    )
});
