import React, { Fragment } from 'react';
import './index.style.scss';
import Typography from 'app/core/shared-components/typography';
import ImageComponent from 'app/core/shared-components/image';
import Button from 'app/core/shared-components/button';
import Tag from 'app/core/shared-components/tag';
import moment from 'moment';
import { timeStamps } from 'helpers/timeStamp.constants';

interface ISlotRequestCardProps {
  name: string;
  imageSource: string | null;
  slotDateStart: Date;
  slotDateEnd: Date;
  techStack: string;
  level: string;
  onDecline?: () => void;
  onAccept?: () => void;
}

function SlotRequestCard(props: ISlotRequestCardProps) {
  const { name, imageSource } = props;
  return (
    <div className="slot-request-card-wrapper">
      <div className="slot-request-card-header">
        <div className="slot-request-card-raised-wrapper">
          <Typography customStyle="slot-request-card--raisedBy">Raised by</Typography>
        </div>
        <div className="slot-request-card-name-avatar-wrapper">
          <div className="slot-request-card-name-wrapper">
            <Typography customStyle="slot-request-card--name">{`${name}`}</Typography>
          </div>
          <div className="slot-request-card-avatar-wrapper">
            <ImageComponent
              src={imageSource}
              alt={`${name}`}
              fallbackText={`${name}`}
              customClass="slot-request-card--avatar"
              fallbackClass="slot-request-card--avatar-fallback"
            />
          </div>
        </div>
      </div>
      <div className="slot-request-card-body">
        <SlotRequestCardDateElement
          slotDateStart={props.slotDateStart}
          slotDateEnd={props.slotDateEnd}
          techStack={props.techStack}
          level={props.level}
        />
      </div>
      <div className="slot-request-card-footer">
        <Button
          variant="outlined"
          customStyle="slot-request-card-buttons slot-request-card--decline-button"
          onClick={props.onDecline}>
          Decline
        </Button>
        <Button
          variant="contained"
          customStyle="slot-request-card-buttons"
          onClick={props.onAccept}>
          Accept
        </Button>
      </div>
    </div>
  );
}

interface ISlotRequestCardDateElement {
  slotDateStart: Date;
  slotDateEnd: Date;
  techStack: string;
  level: string;
}

export function SlotRequestCardDateElement(props: ISlotRequestCardDateElement) {
  const { slotDateStart, slotDateEnd, techStack, level } = props;
  const startDate = moment(slotDateStart);
  const endDate = moment(slotDateEnd);
  const midday = moment(startDate.clone().startOf('day').add(13, 'hours'));
  return (
    <Fragment>
      <div className="slot-card-date-element">
        <Typography customStyle="slot-card-date-label">{`${startDate.format(
          timeStamps.DO_MMMM_YYYY
        )}`}</Typography>
        <Typography customStyle="slot-card-label">{`(${startDate.format(
          timeStamps.DDD
        )})`}</Typography>
      </div>
      <div className="slot-card-detail-element">
        <div className="slot-card-date-element">
          <Typography customStyle="slot-card-time-label">{`${startDate.format(
            timeStamps.HH_MM_A
          )} - ${endDate.format(timeStamps.HH_MM_A)}`}</Typography>
          <Typography customStyle="slot-card-label">
            {startDate.isBefore(midday) ? '(First Half)' : '(Second Half)'}
          </Typography>
        </div>
        <Tag label={`${techStack} - ${level}`} customClass="slot-card-detail-tag" />
      </div>
    </Fragment>
  );
}

export default SlotRequestCard;
