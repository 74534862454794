export const CHECK_ATLEAST_ONE_ALPHABET = /[a-zA-Z]/;
export const CHECK_ONLY_DIGIT = /^[1-9]\d*(\.\d+)?$/;
export const ALPHANUMERIC = /^[A-Za-z0-9 ]*$/;
export const LEVEL_NAME_REGEX = /^[-A-Za-z0-9._ ]*$/;
export const ALPHANUMERIC_WITH_SPECIAL_CHARACTER = /^[ A-Za-z0-9.#+]*$/;
export const TECH_CONTAINS_SPECIAL_CHARACTER = /[`!@$%^*()_\=\[\]{};':"\\|,<>\/?~]/;
export const LEVEL_CONTAINS_SPECIAL_CHARACTER = /[`!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?~]/;
export const PROJECT_CONTAINS_SPECIAL_CHARACTER = /[`!@#$%^*()_+\=\[\]{};':"\\|,<>\/?~]/;
export const AUTOCOMPLETE_SETTINGS = /^[ a-zA-Z\.\-\@]*$/;
export const CANCEL_BOOKING_REGEX = '/^[A-Za-zs]*$/';
export const SPECIAL_CHAR_REGEX = /[-'/`~!#*$@_%+=.,^&(){}[\]|;:"<>?\\]/;
export const SPECIAL_CHAR_REGEX_WITHOUT_SEMICOLON = /[-'/`~!#*$@_%+=.,^&(){}[\]|;"<>?\\]/;
export const NOT_ALPHABET_REGEX = /[^a-zA-Z]/;
