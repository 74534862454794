import { DELETE_ALL_SLOTS } from 'helpers/apiRoutes';
import { CANCELLATION_OPTION_TYPE } from 'helpers/constants';
import service from './index.service';

export async function searchPanel(searchText: string, abortSignal: AbortSignal) {
  try {
    const text = encodeURIComponent(searchText);
    const response = await service.get('/user/panel/search', {
      params: { searchPanel: text },
      signal: abortSignal
    });
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function fetchPanelDetail(userId: string | number) {
  try {
    const response = await service.get('/admin/panel/detail', {
      params: { userId: userId }
    });
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function fetchUserSchedule(userId: string | number) {
  try {
    const response = await service.get('/panel/schedule', {
      params: { userId: userId }
    });
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export interface ISlot {
  id?: number | string;
  from: Date | string;
  to: Date | string;
}

export interface ISlots {
  date: Date | string;
  slot: ISlot[];
}

interface ISlotAdd {
  userId: string | number;
  slots: ISlots[];
}

export async function addSlots(params: ISlotAdd) {
  try {
    const response = await service.post('/interview/slot/add', params);
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function slotRequest() {
  try {
    const response = await service.get('/interview/slot/request/notification');
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

interface ISlotCancelParams {
  userId: string | number;
  slotId: string | number;
  cancelComments: string;
  makeSlotAvailable: boolean;
  cancelOption: CANCELLATION_OPTION_TYPE;
}

export async function slotCancel(params: ISlotCancelParams) {
  try {
    const response = await service.post('/interview/slot/cancel', params);
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function slotRequestAcceptance(requestId: number | string) {
  try {
    const response = await service.post('/interview/slot/request/accept', undefined, {
      params: { slotRequestId: requestId }
    });
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function slotRequestDecline(requestId: number | string) {
  try {
    const response = await service.post('/interview/slot/request/decline', undefined, {
      params: { slotRequestId: requestId }
    });
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function deleteSlot(slotId: number | string) {
  try {
    const response = await service.delete('/interview/slot/delete', {
      params: { slotId: slotId }
    });
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function deleteMultipleSlot(slotIdList: Array<any>) {
  try {
    const response = await service.delete(
      `${DELETE_ALL_SLOTS}?${slotIdList?.map((n) => `slotIdList=${n}`).join('&')}`
    );
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function editSlot(param: ISlotAdd) {
  try {
    const response = await service.post('/interview/slot/edit', param);
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function fetchSlotDetails(slotId: number | string) {
  try {
    const response = await service.get('/interview/slot/detail', { params: { id: slotId } });
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}
