import ReactGA from 'react-ga4';
import moment from 'moment';
import { MomentInput } from 'moment';
import { ITechStackType } from 'helpers/panel';
import { timeStamps } from 'helpers/timeStamp.constants';
import { ZERO_EXPERIENCE } from './constants';

export const handleTabEventWithValue =
  (value?: number | string, onClickFunction?: Function) => (event: React.KeyboardEvent) => {
    if (event?.keyCode === 13) {
      onClickFunction && onClickFunction(value);
    }
  };

export const handleTabEventwithoutValue =
  (onClickFunction: Function) => (event: React.KeyboardEvent) => {
    if (event?.keyCode === 13) {
      event.preventDefault();
      onClickFunction && onClickFunction();
    }
  };
export const handleTabKeyPosition =
  (value: number, onClickFunction?: Function, setFunction?: Function) =>
  (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e?.keyCode === 13) {
      onClickFunction && onClickFunction(value);
    } else if (e?.keyCode === 9) {
      setFunction && setFunction(value + 1);
    }
  };
export const handleTabKeyToggle =
  (value?: boolean, setFunction?: Function) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e?.keyCode === 13) {
      setFunction && setFunction(!value);
    }
  };

export const headerDetailsForSlots = (fromTime: MomentInput, toTime: MomentInput) => {
  return `${moment(fromTime).format(timeStamps.DD_MMMM_YYYY)}, ${moment(fromTime).format(
    timeStamps.DDDD
  )} , ${moment(fromTime).format(timeStamps.SLOT_HH_MM_a)} - ${moment(toTime).format(
    timeStamps.SLOT_HH_MM_a
  )} `;
};

export const timeFormatter = (time: string, format: string) => {
  const formatedDate = moment(time, format).format(timeStamps.HH_MM_A);
  return formatedDate;
};

export const handleMapFunction = (data: ITechStackType[], showChip: boolean, role: number) => {
  return data
    ?.filter((techLevel: ITechStackType) => parseInt(techLevel?.roleId) == role)
    .map((item: ITechStackType) =>
      showChip
        ? {
            firstChipValue: { id: item?.techStackId, label: item?.techStack },
            secondChipValue: { id: item?.levelId, label: item?.level }
          }
        : {
            id: item?.techStackId,
            label: item?.techStack
          }
    );
};

export const getExperience = (experience: number) => {
  const array = experience.toString().split('.');
  const [firstValue, secondValue, ...rest] = array;
  if (experience == 0) {
    return ZERO_EXPERIENCE;
  }
  if (Number(firstValue) && secondValue) {
    return `${firstValue}y ${secondValue == '1' ? '10' : secondValue && Number(secondValue)}m`;
  }
  if (Number(firstValue) && !secondValue) {
    return `${firstValue}y`;
  }
  if (secondValue && !Number(firstValue)) {
    return `${secondValue == '1' ? '10' : secondValue && Number(secondValue)}m`;
  } else return '';
};

export const trackGA = (
  eventCategory: string,
  eventLabel: string,
  eventName: string,
  eventType: string,
  eventData?: string
) => {
  ReactGA.gtag('event', eventName, {
    event_category: eventCategory,
    event_label: eventLabel,
    event_type: eventType,
    event_data: eventData
  });
};
