import React from 'react';
import { ISelectValue } from 'app/core/shared-components/select';
import Form, { ITechStackArray } from 'app/core/shared-components/form';
import { IPanelStates, IPanelType, ISelectedPanelType } from 'helpers/panel';
import { APPROVED, ARCHIVE, IN_REVIEW } from 'helpers/constants';

interface IPanelDrawerProps {
  multiOnboard?: boolean;
  selectedPanel?: ISelectedPanelType;
  handleEditPanel?: (
    role: ISelectValue,
    status: string,
    projects?: ISelectValue[],
    stackLop?: ITechStackArray[]
  ) => void;
  handlCloseDrawer?: () => void;
  panelState?: IPanelStates;
  hideArchive?: boolean;
  panel?: ISelectValue | IPanelType;
  id?: number | string;
}
const PanelDrawer = (props: IPanelDrawerProps) => {
  const {
    multiOnboard,
    selectedPanel,
    hideArchive,
    panel,
    id,
    panelState,
    handleEditPanel,
    handlCloseDrawer
  } = props;
  return (
    <Form
      showProfileField={!multiOnboard}
      userDetail={{
        name: `${selectedPanel?.firstName} ${selectedPanel?.lastName}`,
        designation: `${selectedPanel?.designation}`,
        email: `${selectedPanel?.emailId}`,
        experience: panelState?.formData?.experience && panelState?.formData?.experience,
        mobile: `${selectedPanel?.mobileNo}`,
        profileImageUrl: `${selectedPanel?.profileImageUrl}`
      }}
      panelForm={true}
      showStatusField={true}
      onSave={handleEditPanel}
      roleValue={panelState?.formData?.role ? panelState?.formData?.role : panel}
      statusFieldValue={selectedPanel?.statusEnum}
      disableStatus={selectedPanel?.statusEnum === APPROVED}
      disableUserEditing={id === selectedPanel?.userId}
      disableArchive={hideArchive || selectedPanel?.statusEnum === APPROVED}
      archiveFieldValue={
        multiOnboard
          ? ARCHIVE
          : selectedPanel?.statusEnum !== IN_REVIEW && selectedPanel?.statusEnum !== APPROVED
          ? hideArchive
            ? IN_REVIEW
            : ARCHIVE
          : IN_REVIEW
      }
      projectValue={panelState?.formData?.projects}
      stackLopValue={panelState?.formData?.stackLoi}
      onCancel={handlCloseDrawer}
    />
  );
};
export default PanelDrawer;
