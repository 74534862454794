import React, { Component, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ImageComponent from '../image';
import Typography from '../typography';
import './index.style.scss';
import Acordian from '../accordion';
import Images from '../../../../assets/images/';
import { useLocation, useNavigate } from 'react-router';
import NavLink from './nav-link';
import ClickAwayListener from '../click-away';
import { RECRUITER_LABEL } from 'helpers/constants';
interface ISingleItem {
  icon: string;
  title: string;
  path: string;
}
interface INavigationItemProps {
  items: ISingleItem[];
  customStyles: string;
  toggledState?: boolean;
}
function NavigationItems(props: INavigationItemProps) {
  const { items, customStyles, toggledState } = props;
  const [ismanagePanelsOpen, setIsManagePanelsOpen] = useState(false);
  const rootPath = window.location.pathname === '/';
  const location = useLocation();
  const navigate = useNavigate();
  const [isAccordianOpen, setIsAccordianOpen] = useState<boolean>(false);
  useEffect(() => {
    if (location.pathname === '/panel' || location.pathname === '/talentAcquisition')
      setIsAccordianOpen(true);
    else setIsAccordianOpen(false);
  }, [location]);

  function setIsOpen(arg: any) {
    setIsAccordianOpen(arg);
  }
  const handleOpenManagePanels = () => {
    setIsManagePanelsOpen(!ismanagePanelsOpen);
  };
  const handleCloseMU = () => {
    setIsManagePanelsOpen(false);
  };

  const handleNavigate = (navigationLink: any) => () => {
    navigate(navigationLink);
    setIsManagePanelsOpen(false);
  };
  return (
    <>
      {items.map((item: ISingleItem, index: number) => {
        return item.title !== 'Manage Users' ? (
          <NavLink
            key={index}
            to={item.path}
            id={index.toString()}
            className={({ isActive }) =>
              isActive || (rootPath && item.path === 'home') ? customStyles : 'link'
            }>
            <div className="nav-item">
              <ImageComponent src={item.icon} customClass="nav-image" />
              {!toggledState && (
                <Typography variant="text" customStyle="nav-text ">
                  {item.title}
                </Typography>
              )}
            </div>
          </NavLink>
        ) : toggledState ? (
          <ClickAwayListener handleClose={handleCloseMU}>
            <div className="collapsed-mu-section">
              <div className="nav-accordian-title" onClick={handleOpenManagePanels}>
                <ImageComponent
                  src={
                    ismanagePanelsOpen &&
                    !(location.pathname === '/panel' || location.pathname === '/talentAcquisition')
                      ? Images.collapsedManagePanelSelected
                      : location.pathname === '/panel' || location.pathname === '/talentAcquisition'
                      ? Images.collapsedManagePanelFocus
                      : Images.collapsedManagePanel
                  }
                  className="nav-acc-image"
                />
              </div>
              {ismanagePanelsOpen ? (
                <div className="collapsed-manage-users">
                  <div className="nav-acc-link-wrapper collapsed-item">
                    <div
                      onClick={handleNavigate('/panel')}
                      id={index.toString()}
                      className={
                        location.pathname === '/panel'
                          ? 'active-nav-acordian-link collapsed-selected'
                          : 'nav-acordian-link customLink'
                      }>
                      <div className="nav-item">
                        <Typography variant="text" customStyle="nav-text ">
                          {'Panel'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className="nav-acc-link-wrapper collapsed-item">
                    <div
                      key={uuidv4()}
                      onClick={handleNavigate('/talentAcquisition')}
                      id={index.toString()}
                      className={
                        location.pathname === '/talentAcquisition'
                          ? 'active-nav-acordian-link collapsed-selected'
                          : 'nav-acordian-link customLink'
                      }
                      onSelect={handleCloseMU}>
                      <div className="nav-item">
                        <Typography variant="text" customStyle="nav-text ">
                          {RECRUITER_LABEL}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </ClickAwayListener>
        ) : (
          <Acordian
            key={index}
            title={
              <div className="nav-accordian-title">
                <ImageComponent src={Images.panel} className="nav-acc-image" />
                <div>Manage Users</div>
              </div>
            }
            open={isAccordianOpen}
            onClick={setIsOpen}>
            <div className="nav-acc-link-wrapper">
              <NavLink
                to={'panel'}
                id={index.toString()}
                className={({ isActive }) =>
                  isActive || (rootPath && item.path === 'home')
                    ? 'active-nav-acordian-link'
                    : 'nav-acordian-link'
                }>
                <div className="nav-item">
                  <Typography variant="text" customStyle="nav-text ">
                    {'Panel'}
                  </Typography>
                </div>
              </NavLink>
            </div>
            <div className="nav-acc-link-wrapper">
              <NavLink
                key={uuidv4()}
                to={'talentAcquisition'}
                id={index.toString()}
                className={({ isActive }) =>
                  isActive || (rootPath && item.path === 'home')
                    ? 'active-nav-acordian-link'
                    : 'nav-acordian-link'
                }>
                <div className="nav-item">
                  <Typography variant="text" customStyle="nav-text ">
                    {RECRUITER_LABEL}
                  </Typography>
                </div>
              </NavLink>
            </div>
          </Acordian>
        );
      })}
    </>
  );
}
export default NavigationItems;
