import React, { Fragment, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import Typography from 'app/core/shared-components/typography';
import Button from 'app/core/shared-components/button';
import Modal from 'app/core/shared-components/modal';
import ClickAwayListener from 'app/core/shared-components/click-away';
import {
  clearSearchBarValue,
  editTechStacks,
  handleResetPage,
  setCurrentDeletion,
  setIsToastVisible,
  setIsDeletionInProcess,
  getPanelsUserListTechStacks,
  getRecruiterUserListTechStacks
} from 'app/redux/slices/techStackSlice';
import { getTechStack, setSelectedNotificationData } from 'app/redux/slices/sharedSlice';
import {
  ASCII,
  IMAGE_FILE,
  PULSE_ANIMATION_TIMEOUT,
  TECH_STACK_LABEL,
  UPLOAD_IMAGE_TEXT
} from 'helpers/constants';
import {
  INVALID_TECH_NAME_ERROR,
  TECH_DELETE_TOAST_MESSAGE,
  NO_TECH_NAME_ERROR,
  TECH_NAME_ALREADY_EXIST_ERROR,
  SHOULD_CONTAIN_ALPHABET_ERROR,
  INVALID_FILE_TYPE,
  DELETE_TECHSTACK,
  DELETE_TECHSTACK_MESSAGE,
  UNABLE_TO_DELETE_TECHSTACK
} from 'helpers/messages.constants';
import {
  TECH_CONTAINS_SPECIAL_CHARACTER,
  ALPHANUMERIC_WITH_SPECIAL_CHARACTER,
  CHECK_ATLEAST_ONE_ALPHABET
} from 'helpers/regex.constants';
import './index.style.scss';
import {
  handleCloseSearchResult,
  clearSearchBarValue as clearHomeSearchBar
} from 'app/redux/slices/adminDashboardSlice';
import ImageComponent from 'app/core/shared-components/image';
import Images from 'assets/images';
import { notify } from 'helpers/toastHelper';
import ToastUndo from 'app/core/shared-components/toast-undo';
import UserList from 'app/core/shared-components/users-list';
import { handleTabEventwithoutValue } from 'helpers/utils';
import { timeStamps } from 'helpers/timeStamp.constants';
import { ITechStackType } from 'app/services/user-response.types';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';

interface ITechItemProps {
  techItem: ITechStackType;
  onDelete: (id: string) => void;
  onUndo?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  localDelete: (param: ITechStackType) => void;
}

export interface ITechItemState {
  showEditModal: boolean;
  showDeleteModal: boolean;
  stackValue: string;
  showDropdown: boolean;
  imageFile: File | string;
  techNameError: string;
  errorToast: string;
  disabled: boolean;
  asignee: { recruiterCount: number; panelCount: number };
  fileUploadError: boolean;
}

export function TechItem({ techItem, onDelete, onUndo, localDelete }: ITechItemProps) {
  const [techItemState, setTechItem] = useState<ITechItemState>({
    showEditModal: false,
    showDeleteModal: false,
    stackValue: '',
    showDropdown: false,
    imageFile: '',
    techNameError: '',
    errorToast: '',
    disabled: true,
    asignee: { recruiterCount: 0, panelCount: 0 },
    fileUploadError: false
  });

  const [openUsersList, setOpenUsersList] = useState(false);

  const {
    isPanelListLoading,
    isRecruiterListLoading,
    panelCount,
    panelListData,
    recruiterCount,
    recruiterListData
  } = useAppSelector((state) => state.techStack);
  const { techStack, selectedNotificationData } = useAppSelector((state) => state.shared);
  const { isToastVisible, isDeletionInProcess, deletionErrorToast, deletionAsignee } =
    useAppSelector((state) => state.techStack);

  const dispatch = useAppThunkDispatch();

  const {
    stackValue,
    techNameError,
    errorToast,
    showDropdown,
    disabled,
    showEditModal,
    showDeleteModal
  } = techItemState;
  const { ellipsis, deleteIcon, uploadFile, zohoIcon } = Images;

  const AddedDate = moment(techItem?.addedOn)?.format(timeStamps.DO_MMMM_YYYY);
  const fallBackImageText = techItem?.stack?.charAt(0)?.toUpperCase();

  const handleDropdown = () => {
    setTechItem({ ...techItemState, showDropdown: !techItemState.showDropdown });
  };
  const handleEditModal = () => {
    setTechItem((prevState) => ({
      ...prevState,
      showEditModal: !showEditModal,
      showDropdown: false,
      imageFile: techItem?.imageUrl,
      stackValue: techItem?.stack,
      techNameError: '',
      disabled: true,
      fileUploadError: false
    }));
  };

  const handleDeleteModal = () => {
    techItem.noOfUsers
      ? null
      : setTechItem((prevState) => ({
          ...prevState,
          showDeleteModal: !showDeleteModal,
          showDropdown: false,
          errorToast: ''
        }));
  };
  const handleEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.target?.value;
    setTechItem({
      ...techItemState,
      stackValue: currentValue,
      techNameError: '',
      disabled: false
    });
    if (currentValue?.trim() === '' || currentValue?.trim() === techItem?.stack) {
      setTechItem({
        ...techItemState,
        stackValue: currentValue,
        techNameError: '',
        disabled: true
      });
    } else if (currentValue?.trim()?.length > 30) {
      setTechItem({
        ...techItemState,
        techNameError: ''
      });
    } else if (TECH_CONTAINS_SPECIAL_CHARACTER.test(currentValue)) {
      setTechItem({
        ...techItemState,
        techNameError: INVALID_TECH_NAME_ERROR,
        stackValue: currentValue,
        disabled: true
      });
    }
  };
  const handleFileUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.currentTarget?.files) {
      const [firstFile, ...restFiles] = Array.from(event?.currentTarget?.files);
      const fileUploaded = firstFile;
      const { name } = firstFile;
      if (
        name.substr(name.length - '.png'.length, '.png'.length).toLowerCase() ==
          '.png'.toLowerCase() ||
        name.substr(name.length - '.jpeg'.length, '.jpeg'.length).toLowerCase() ==
          '.jpeg'.toLowerCase() ||
        name.substr(name.length - '.jpg'.length, '.jpg'.length).toLowerCase() ==
          '.jpg'.toLowerCase()
      ) {
        setTechItem({
          ...techItemState,
          imageFile: fileUploaded,
          techNameError: '',
          disabled: false,
          fileUploadError: false
        });
      } else {
        setTechItem({ ...techItemState, fileUploadError: true, showEditModal: true });
      }
    }
  };

  const onDeleteItem = async () => {
    localDelete(techItem);
    dispatch(
      setCurrentDeletion({
        name: techItem.stack,
        imageData: techItemState.imageFile,
        id: techItem.id
      })
    );
    dispatch(setIsToastVisible(true));
    notify(
      false,
      <ToastUndo
        toastMessage={TECH_DELETE_TOAST_MESSAGE}
        handleUndo={onUndo}
        toastId={parseInt(techItem.id)}
      />,
      parseInt(techItem.id),
      handleDeleteTechStack
    );
    await dispatch(setIsDeletionInProcess(true));
    setTechItem({ ...techItemState, showDeleteModal: false });
  };

  function onkeyupEdit(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.code === ASCII.enter && !disabled) {
      onTechStackEdit();
    }
  }

  const handleDeleteTechStack = () => {
    onDelete(techItem.id);
  };

  const onTechStackEdit = async () => {
    const formData = new FormData();
    formData.append(IMAGE_FILE, techItemState?.imageFile);
    const { id, imageUrl } = techItem;
    const { stackValue, imageFile } = techItemState;
    const isTechNameExist = techStack.filter(
      (tech) =>
        tech?.stack?.toUpperCase() ===
        stackValue
          ?.replace(/\s{2,}/g, ' ')
          ?.trim()
          .toUpperCase()
    );
    const isTechStackExist =
      !isTechNameExist?.length ||
      isTechNameExist[0]?.stack?.toUpperCase() === techItem?.stack?.toUpperCase();

    const isTechNameContainsAlphanumeric = ALPHANUMERIC_WITH_SPECIAL_CHARACTER.test(stackValue);
    const isTechNameContainsAlphabets = CHECK_ATLEAST_ONE_ALPHABET.test(stackValue);
    if (isTechNameContainsAlphanumeric) {
      if (isTechNameContainsAlphabets) {
        if (isTechStackExist) {
          if (stackValue?.trim()?.length) {
            const data = await dispatch(
              editTechStacks({
                id: id,
                stackName: stackValue?.replace(/\s{2,}/g, ' ')?.trim(),
                imageFile: imageFile ? formData : null,
                imageUrl
              })
            ).unwrap();
            if (!data?.error) {
              setTechItem({ ...techItemState, showEditModal: false });
              dispatch(handleResetPage());
              dispatch(clearSearchBarValue());
              dispatch(handleCloseSearchResult());
              dispatch(clearHomeSearchBar());
              dispatch(getTechStack());
            } else {
              setTechItem({
                ...techItemState,
                techNameError: data?.error[0]?.message,
                disabled: true
              });
            }
          } else {
            setTechItem({
              ...techItemState,
              techNameError: NO_TECH_NAME_ERROR,
              disabled: true
            });
          }
        } else {
          setTechItem({
            ...techItemState,
            techNameError: TECH_NAME_ALREADY_EXIST_ERROR,
            disabled: true
          });
        }
      } else {
        setTechItem({
          ...techItemState,
          techNameError: SHOULD_CONTAIN_ALPHABET_ERROR,
          disabled: true
        });
      }
    } else {
      setTechItem({
        ...techItemState,
        techNameError: INVALID_TECH_NAME_ERROR,
        disabled: true
      });
    }
  };

  const closeDropdown = useCallback(() => {
    if (showDropdown) {
      setTechItem((prevState) => ({ ...prevState, showDropdown: false }));
    }
  }, [showDropdown, showEditModal]);

  const getTechStackModal = () => {
    return (
      <Modal
        header={'Edit Tech Stack'}
        customStyle="tech-edit-modal"
        onClose={handleEditModal}
        isOpen={techItemState?.showEditModal}>
        <div className="edit-modal-content">
          <div>
            <label htmlFor="techName" className="delete-modal--tech-label">
              {TECH_STACK_LABEL}
            </label>
            <br />
            <input
              autoFocus
              type="text"
              name="techName"
              className="tech-name"
              value={stackValue}
              onChange={handleEdit}
              autoComplete="off"
              required
              onKeyUp={onkeyupEdit}
            />
            <br />
            {techNameError && <Typography variant="error">{techNameError}</Typography>}
          </div>
          <div className="edit-modal-footer">
            <Button customStyle="edit-modal--cancel-btn" onClick={handleEditModal}>
              Cancel
            </Button>
            <Button
              customStyle="edit-modal--save-btn"
              id={techItem.id}
              variant="contained"
              onClick={onTechStackEdit}
              disabled={disabled}>
              Save
            </Button>
          </div>
        </div>
      </Modal>
    );
  };
  const getTechStackDeleteModal = () => {
    const { panelCount, recruiterCount } = techItemState.asignee;
    const { noOfUsers } = techItem;
    const errorMessage = ` has currently been assigned to ${panelCount} Panellists and ${recruiterCount} Talent Acquisitions and cannot be deleted until it is replaced with another Tech Stack.`;
    return (
      <Modal
        header={deletionErrorToast ? UNABLE_TO_DELETE_TECHSTACK : DELETE_TECHSTACK}
        customStyle="edit-modal"
        isOpen={techItemState?.showDeleteModal}
        onClose={handleDeleteModal}>
        <div className="edit-modal-content">
          <p className="delete-modal-para">
            {deletionErrorToast ? (
              <div>
                <span className="delete-ts-black">{`"${stackValue}" `}</span>
                {errorMessage}
              </div>
            ) : (
              DELETE_TECHSTACK_MESSAGE
            )}
          </p>
          {deletionErrorToast ? (
            <div className="edit-modal-footer">
              <Button customStyle="edit-modal--cancel-btn" onClick={handleDeleteModal}>
                Go Back
              </Button>
            </div>
          ) : (
            <div className="edit-modal-footer">
              <Button customStyle="edit-modal--cancel-btn" onClick={handleDeleteModal}>
                Cancel
              </Button>
              <Button
                customStyle="edit-modal--save-btn"
                variant="contained"
                id={techItem.id}
                onClick={onDeleteItem}>
                Delete
              </Button>
            </div>
          )}
        </div>
      </Modal>
    );
  };

  const showDropDown = () => {
    return (
      <div className="dropdown">
        <div
          className="dropdown-option"
          tabIndex={0}
          onClick={handleEditModal}
          onKeyDown={handleTabEventwithoutValue(handleEditModal)}>
          <span>Edit</span>
        </div>
        <div className="divider"></div>
        <div
          tabIndex={techItem?.noOfUsers <= 0 ? 0 : -1}
          onKeyDown={handleTabEventwithoutValue(handleDeleteModal)}
          className={techItem?.noOfUsers ? 'dropdown-option-disabled' : 'dropdown-option'}
          onClick={handleDeleteModal}>
          <span>Delete</span>
        </div>
      </div>
    );
  };

  const getUsersListModal = () => {
    return (
      <Modal customStyle="user-list-modal" onClose={handleUsersList} isOpen={openUsersList}>
        <div className="edit-modal-content">
          <UserList
            title={techItem?.stack?.toString()}
            onClose={handleUsersList}
            id={techItem?.id}
            totalCount={techItem?.noOfUsers}
            listType={'techStack'}
            handleGetList={getList}
            isTabView={true}
            panelCount={panelCount}
            panelData={panelListData}
            recruiterCount={recruiterCount}
            recruiterData={recruiterListData}
            isPanelListLoading={isPanelListLoading}
            isRecruiterListLoading={isRecruiterListLoading}
          />
        </div>
      </Modal>
    );
  };
  const handleUsersList = () => {
    setOpenUsersList(!openUsersList);
  };
  const getList = (id: string) => {
    dispatch(getPanelsUserListTechStacks(id));
    dispatch(getRecruiterUserListTechStacks(id));
  };

  const setTechItemAndImageFile = () => {
    setTechItem({
      ...techItemState,
      stackValue: techItem?.stack,
      imageFile: techItem?.imageUrl,
      asignee: { recruiterCount: 0, panelCount: techItem.noOfUsers }
    });
  };

  const haveNotificationInView = () => {
    if (selectedNotificationData?.id) {
      document
        .getElementById(selectedNotificationData?.id)
        ?.scrollIntoView({ behavior: 'auto', block: 'center' });
      setTimeout(() => {
        dispatch(setSelectedNotificationData({ id: '' }));
      }, PULSE_ANIMATION_TIMEOUT);
    }
  };

  useEffect(() => {
    setTechItemAndImageFile();
    haveNotificationInView();
    return () => {
      dispatch(setSelectedNotificationData({ id: '' }));
    };
  }, []);

  return (
    <Fragment>
      <div className="tech-item-wrapper">
        <div
          className={`tech-item ${
            selectedNotificationData?.id === techItem?.id && 'selected-tech-item'
          }`}
          id={selectedNotificationData?.id === techItem?.id ? techItem?.id : ''}>
          <div className="tech-stack--detail">
            <Typography variant="text" customStyle="tech-stack--name">
              {techItem?.stack}
            </Typography>
            {techItem?.stack?.length > 10 && (
              <div className="ts-tooltip">
                {techItem?.stack}
                <span className="ts-tooltip-arrow" />
              </div>
            )}
            <span onClick={handleUsersList}>
              <Typography variant="text" customStyle="tech-stack--sub-detail">
                Number of Users : {techItem?.noOfUsers}
              </Typography>
            </span>
          </div>
        </div>
        <div className="tech-stack--dropdown">
          <ClickAwayListener handleClose={closeDropdown}>
            <div
              className="dots"
              tabIndex={!techItem?.isZohoRecord ? 0 : -1}
              onKeyDown={handleTabEventwithoutValue(handleDropdown)}>
              {techItem?.isZohoRecord ? (
                <div className="zoho-label">
                  <span className="zoho-tag">Z</span>
                </div>
              ) : (
                <ImageComponent
                  src={ellipsis}
                  alt="3-dots"
                  onClick={handleDropdown}
                  className={`ellipsis ${showDropdown && 'active-btn-ellipsis'}`}
                />
              )}
            </div>
            {techItemState.showDropdown && showDropDown()}
          </ClickAwayListener>
        </div>
      </div>
      {showEditModal && getTechStackModal()}
      {showDeleteModal && getTechStackDeleteModal()}
      {openUsersList && getUsersListModal()}
    </Fragment>
  );
}

export default TechItem;
