import React, { SyntheticEvent } from 'react';
import './index.style.scss';
import Typography from 'app/core/shared-components/typography';
import ImageComponent from 'app/core/shared-components/image';
import Button from 'app/core/shared-components/button';
import Image from 'assets/images';
import { BLUE, GREEN, GREY, RED, YELLOW } from 'helpers/constants';

interface ISlotDetailContainerProps {
  title: string;
  children?: React.ReactNode;
  showEditIcon?: boolean;
  onEditClick?: (event: SyntheticEvent) => void;
}

type borderColour = 'RED' | 'GREEN' | 'BLUE' | 'GREY' | 'YELLOW';
interface ISlotStripProps {
  title: string;
  showCancelButton?: boolean;
  showDeleteIcon?: boolean;
  showEditIcon?: boolean;
  customClass?: string;
  borderColor?: borderColour;
  onCancelClick?: (event: SyntheticEvent) => void;
  onDeleteClick?: (event: SyntheticEvent) => void;
  onEditClick?: (event: SyntheticEvent) => void;
}

function SlotDetailContainer(props: ISlotDetailContainerProps) {
  return (
    <div className="slot-detail-container">
      <div className="slot-detail-header">
        <Typography customStyle="slot-detail-title">{props.title}</Typography>
        {props.showEditIcon && (
          <Button variant="outlined" className="slot-detail-edit" onClick={props.onEditClick}>
            <ImageComponent src={Image.edit} alt="Edit Icon" customClass="slot-edit-icon" />
          </Button>
        )}
      </div>
      <div className="slot-detail-content">{props.children}</div>
    </div>
  );
}

export function SlotStrip(props: ISlotStripProps) {
  const { borderColor } = props;
  let color = 'slot-strip-grey';
  if (borderColor === RED) color = 'slot-strip-red';
  else if (borderColor === GREEN) color = 'slot-strip-green';
  else if (borderColor === BLUE) color = 'slot-strip-blue';
  else if (borderColor === YELLOW) color = 'slot-strip-yellow';
  return (
    <div className={`slot-strip-wrapper ${color} ${props.customClass}`}>
      <div className="slot-strip-title-wrapper">
        <Typography customStyle="slot-strip-title">{props.title}</Typography>
      </div>
      <div className="slot-strip-action-wrapper">
        {props.showCancelButton && (
          <Button
            variant="outlined"
            customStyle="slot-strip-cancel-btn"
            onClick={props.onCancelClick}>
            Cancel Slot
          </Button>
        )}
        {props.showEditIcon && (
          <Button
            variant="outlined"
            customStyle="slot-strip-delete-btn"
            onClick={props.onEditClick}>
            <ImageComponent src={Image.edit} alt="Edit Icon" />
          </Button>
        )}
        {props.showDeleteIcon && (
          <Button
            variant="outlined"
            customStyle="slot-strip-delete-btn"
            onClick={props.onDeleteClick}>
            <ImageComponent src={Image.delete} alt="Delete Icon" />
          </Button>
        )}
      </div>
    </div>
  );
}

SlotStrip.defaultProps = {
  borderColor: GREY,
  showEditIcon: false,
  showCancelButton: false,
  showDeleteIcon: false
} as ISlotStripProps;

SlotDetailContainer.defaultProps = {
  showEditIcon: false
} as ISlotDetailContainerProps;

export default SlotDetailContainer;
