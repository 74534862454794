import React, { Component } from 'react';
import './index.style.scss';
import Typography from '../../core/shared-components/typography';
import ImageComponent from '../../core/shared-components/image/index';
import { ReactComponent as Logo } from '../../../assets/images/coditas-logo.svg';
import loader from '../../../assets/images/transparent_loader.gif';

class Splash extends Component {
  render() {
    return (
      <div className="splash-page">
        <ImageComponent src={loader} />
      </div>
    );
  }
}

export default Splash;
