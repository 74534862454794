import React, { ChangeEvent, Fragment, useCallback, useEffect, useState } from 'react';
import Button from 'app/core/shared-components/button';
import ImageComponent from 'app/core/shared-components/image';
import { toast } from 'react-toastify';
import InputBox from 'app/core/shared-components/input-box';
import Loader from 'app/core/shared-components/loader';
import Modal from 'app/core/shared-components/modal';
import { IOptionType } from 'app/core/shared-components/search';
import SortingOptions from 'app/core/shared-components/sorting-otions';
import Typography from 'app/core/shared-components/typography';
import ErrorPage from 'app/pages/Error';
import TechStackCard from 'app/pages/TechStack/components/techStackCard';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import { getTechStack } from 'app/redux/slices/sharedSlice';
import {
  addTechStacks,
  clearSearchBarValue,
  deleteTechStacks,
  getTechStackData,
  handleResetPage,
  resetSearchBarValue,
  setDeleteionAsignee,
  setDeletionErrToast,
  setIsToastVisible
} from 'app/redux/slices/techStackSlice';
import { ITechStackType } from 'app/services/user-response.types';
import Images from 'assets/images';
import {
  ADDED_ON_DESC,
  ADD_BUTTON_LABEL,
  ADD_TECH_BUTTON_TEXT,
  ASCII,
  CANCEL_BUTTON_TEXT,
  GO_BACK_BUTTON,
  MEDIUM,
  NAME_A_to_Z,
  NAME_Z_to_A,
  OUTLINED,
  RECENTLY_ADDED,
  TECH_NAME,
  TECH_NAME_PLACEHOLDER,
  TECH_STACK_ASC,
  TECH_STACK_DESC,
  TECH_STACK_HEADING,
  TECH_STACK_LABEL,
  UPLOAD_IMAGE_TEXT
} from 'helpers/constants';
import {
  INVALID_FILE_TYPE,
  INVALID_TECH_NAME_ERROR,
  NO_TECH_NAME_ERROR,
  SHOULD_CONTAIN_ALPHABET_ERROR,
  TECH_NAME_ALREADY_EXIST_ERROR
} from 'helpers/messages.constants';
import {
  ALPHANUMERIC_WITH_SPECIAL_CHARACTER,
  CHECK_ATLEAST_ONE_ALPHABET,
  TECH_CONTAINS_SPECIAL_CHARACTER
} from 'helpers/regex.constants';

import routeError from 'router/routeError';
import './index.style.scss';

interface ITechStackState {
  showAddModal: boolean;
  sortStatus: boolean;
  searchSelect: boolean;
  techData: ITechStackType[];
  searchValue: string;
  newTechName: string;
  imageFile: File | string;
  techNameError: string;
  options: IOptionType[];
  resultNotFound: boolean;
  isSearchedOptionSelected: boolean;
  isSortingOn: boolean;
  sortingValue: string;
  disabled: boolean;
  fileUploadError: boolean;
  isSearched: boolean;
  searchData: ITechStackType[];
}
interface ITechStackProps {
  techStackSearchData?: ITechStackType[];
}

const TechStack = ({ techStackSearchData }: ITechStackProps) => {
  const [techStackState, setTechStackState] = useState<ITechStackState>({
    showAddModal: false,
    imageFile: '',
    newTechName: '',
    techData: [],
    searchSelect: false,
    searchValue: '',
    sortStatus: false,
    techNameError: '',
    options: [],
    resultNotFound: false,
    isSearchedOptionSelected: false,
    isSortingOn: false,
    sortingValue: NAME_A_to_Z,
    disabled: true,
    fileUploadError: false,
    searchData: [],
    isSearched: false
  });

  const hiddenFileInput = React.createRef<HTMLInputElement>();

  const dispatch = useAppThunkDispatch();
  const { techStack } = useAppSelector((state) => state.shared);

  const { isLoading, resetPage, isError, currentDeletion, deletionErrorToast, deletionAsignee } =
    useAppSelector((state) => state.techStack);
  const { isTechLoading, techStack: techStackShared } = useAppSelector((state) => state.shared);

  const {
    techData,
    showAddModal,
    imageFile,
    newTechName,
    techNameError,
    isSearchedOptionSelected,
    isSortingOn,
    sortingValue,
    disabled,
    isSearched,
    searchData,
    searchValue
  } = techStackState;

  const { search, close, plus, sort, uploadFile } = Images;

  async function closeToast(id: string) {
    dispatch(setIsToastVisible(false));
    const data = await dispatch(deleteTechStacks(id)).unwrap();
    if (!data?.error) {
      dispatch(handleResetPage());
      dispatch(clearSearchBarValue());
      dispatch(getTechStack());
      setTechStackState((prevState) => ({ ...prevState, techData: techStack }));
    } else {
      dispatch(setDeletionErrToast(data?.error[0]?.message));
      dispatch(
        setDeleteionAsignee({
          recruiterCount: data.error[0].data.recruiterCount,
          panelCount: data.error[0].data.panelCount,
          bookedSlotCount: data.error[0].data.bookedSlotCount
        })
      );
    }
  }

  const handleAddModal = () => {
    setTechStackState({
      ...techStackState,
      showAddModal: true,
      disabled: true
    });
  };

  const handleFileUpload = () => {
    hiddenFileInput?.current?.click();
  };

  const handleFileUploadChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      const [firstFile, ...restFiles] = Array.from(event.target.files);
      const { name } = firstFile;
      if (
        name.substr(name.length - '.png'.length, '.png'.length).toLowerCase() ==
          '.png'.toLowerCase() ||
        name.substr(name.length - '.jpeg'.length, '.jpeg'.length).toLowerCase() ==
          '.jpeg'.toLowerCase() ||
        name.substr(name.length - '.jpg'.length, '.jpg'.length).toLowerCase() ==
          '.jpg'.toLowerCase()
      ) {
        setTechStackState({
          ...techStackState,
          imageFile: firstFile,
          techNameError: '',
          disabled: false,
          fileUploadError: false
        });
      } else {
        setTechStackState({ ...techStackState, fileUploadError: true });
      }
    }
  };

  const handleChangeAdd = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.target?.value;
    setTechStackState({
      ...techStackState,
      newTechName: currentValue,
      techNameError: '',
      disabled: false
    });
    if (currentValue?.trim() === '') {
      setTechStackState({
        ...techStackState,
        newTechName: currentValue,
        techNameError: '',
        disabled: true
      });
    } else if (currentValue?.trim()?.length > 30) {
      setTechStackState({
        ...techStackState
      });
    } else if (TECH_CONTAINS_SPECIAL_CHARACTER.test(currentValue)) {
      setTechStackState({
        ...techStackState,
        techNameError: INVALID_TECH_NAME_ERROR,
        newTechName: currentValue,
        disabled: true
      });
    }
  };

  const toggleSorting = () => {
    setTechStackState({ ...techStackState, isSortingOn: !isSortingOn });
  };

  const closeAddModal = () => {
    setTechStackState({
      ...techStackState,
      showAddModal: false,
      imageFile: '',
      newTechName: '',
      techNameError: '',
      fileUploadError: false
    });
  };

  const addTechStack = async () => {
    const isTechStackExist = techStackShared?.filter(
      (techStack) =>
        techStack?.stack?.toUpperCase().trim() ===
        newTechName
          ?.replace(/\s{2,}/g, ' ')
          ?.trim()
          ?.toUpperCase()
    );

    const isTechNameValid = ALPHANUMERIC_WITH_SPECIAL_CHARACTER.test(newTechName);
    const isTechNameContainsAlphabets = CHECK_ATLEAST_ONE_ALPHABET.test(newTechName);
    if (isTechNameValid && isTechStackExist) {
      if (isTechNameContainsAlphabets) {
        if (!isTechStackExist?.length) {
          if (newTechName?.trim()?.length) {
            const imageData = new FormData();
            imageData.append('imageFile', techStackState.imageFile);
            const { data } = await dispatch(
              addTechStacks({
                newTechName: newTechName?.replace(/\s{2,}/g, ' ')?.trim(),
                imageData
              })
            ).unwrap();
            if (!data?.error) {
              dispatch(getTechStack());
              closeAddModal();
            } else {
              setTechStackState({
                ...techStackState,
                techNameError: data?.error[0]?.message,
                disabled: true
              });
            }
          } else {
            setTechStackState({
              ...techStackState,
              techNameError: NO_TECH_NAME_ERROR,
              disabled: true
            });
          }
        } else {
          setTechStackState({
            ...techStackState,
            techNameError: TECH_NAME_ALREADY_EXIST_ERROR,
            disabled: true
          });
        }
      } else {
        setTechStackState({
          ...techStackState,
          techNameError: SHOULD_CONTAIN_ALPHABET_ERROR,
          disabled: true
        });
      }
    } else {
      setTechStackState({
        ...techStackState,
        techNameError: INVALID_TECH_NAME_ERROR,
        disabled: true
      });
    }
  };

  async function selectSort(value: string) {
    let temp = [...techData];
    switch (value) {
      case NAME_A_to_Z:
        temp = await dispatch(getTechStackData(TECH_STACK_ASC)).unwrap();
        break;
      case NAME_Z_to_A:
        temp = await dispatch(getTechStackData(TECH_STACK_DESC)).unwrap();
        break;
      case RECENTLY_ADDED:
        temp = await dispatch(getTechStackData(ADDED_ON_DESC)).unwrap();
        break;
      default:
        temp = temp;
        break;
    }
    setTechStackState({
      ...techStackState,
      techData: temp,
      sortingValue: value,
      isSortingOn: false
    });
    return temp;
  }

  const getTechStackDeleteErrorModal = () => {
    const { panelCount, recruiterCount, bookedSlotCount } = deletionAsignee;
    const panelAssignedError = ` has currently been assigned to ${panelCount} Panellists and ${recruiterCount} Talent Acquisitions and cannot be deleted until it is replaced with another Tech Stack.`;
    const bookingError = ` has currently ${bookedSlotCount} booked slot${
      bookedSlotCount > 1 ? 's' : ''
    } and cannot be
    deleted until the active bookings are cancelled.`;
    return (
      <Modal
        header={'Unable to delete Tech Stack'}
        customStyle="edit-modal"
        isOpen={!!deletionErrorToast}>
        <div className="edit-modal-content">
          <div className="delete-modal-para">
            <div>
              {panelCount > 0 || recruiterCount > 0 ? (
                <Fragment>
                  <span className="delete-ts-black">{`"${currentDeletion.name}" `}</span>
                  {panelAssignedError}
                </Fragment>
              ) : (
                <Fragment>
                  <span className="delete-ts-black">{`"${currentDeletion?.name}"`}</span>
                  {bookingError}
                </Fragment>
              )}
            </div>
          </div>

          <div className="edit-modal-footer">
            <Button
              customStyle="edit-modal--cancel-btn"
              onClick={() => {
                dispatch(setDeletionErrToast(''));
                dispatch(getTechStack());
              }}>
              {GO_BACK_BUTTON}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const getSearchResults = (searchValue: string) => {
    return techData.filter((techItem) =>
      techItem.stack.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  const handleSearchAction = useCallback(
    (searchValue: string | number) => {
      const searchString = searchValue.toString();

      if (searchString.trim().length) {
        const data = getSearchResults(searchString);
        setTechStackState((prevState) => ({
          ...prevState,
          isSearched: true,
          searchData: data,
          searchValue: searchString
        }));
      } else {
        setTechStackState((prevState) => ({
          ...prevState,
          isSearched: false,
          searchData: [],
          searchValue: ''
        }));
      }
    },
    [techData, searchData]
  );

  const clearInput = () => {
    handleSearchAction('');
  };

  async function localDelete(param: ITechStackType) {
    let temp = techStackState?.techData;
    temp = temp.filter((item) => item?.id !== param?.id);
    setTechStackState((prevState) => ({
      ...prevState,
      techData: temp,
      isSearchedOptionSelected: false
    }));
    await dispatch(clearSearchBarValue());
    dispatch(resetSearchBarValue());
  }

  function undoLocalDelete(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const id = parseInt(event.currentTarget.id);
    toast.dismiss(id);
    toast.update(id, { onClose: () => {}, hideProgressBar: true });
    dispatch(getTechStack());
  }
  const blankFunc = () => {};

  const resetSearch = () => {
    if (resetPage) {
      setTechStackState((prevState) => ({
        ...prevState,
        isSearchedOptionSelected: false
      }));
      dispatch(resetSearchBarValue());
    }
  };
  const setTechStackData = () => {
    if (techStackSearchData) {
      setTechStackState({ ...techStackState, techData: techStackSearchData });
    } else {
      dispatch(getTechStack())
        .unwrap()
        .then((techResponse) => {
          setTechStackState({ ...techStackState, techData: techResponse.data });
        });
      dispatch(handleResetPage());
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === ASCII.enter && newTechName) {
      addTechStack();
    }
  };

  useEffect(() => {
    if (isSearched) {
      setTechStackState((prevState) => ({
        ...prevState,
        searchData: [],
        isSearched: false,
        searchValue: ''
      }));
    }
  }, [techData]);

  useEffect(() => {
    if (!techStackSearchData) {
      setTechStackState({
        ...techStackState,
        techData: techStack,
        sortingValue: NAME_A_to_Z
      });
    }
  }, [techStack]);

  useEffect(() => {
    resetSearch();
  }, [techStackShared]);

  useEffect(() => {
    setTechStackData();
  }, []);

  return (
    <Fragment>
      {navigator.onLine ? (
        !isError ? (
          <div className="tech-stack">
            {!techStackSearchData && (
              <div className="tech-head-wrapper">
                <div className="tech-stack-head">
                  <Typography variant="text" customStyle="head-style">
                    {TECH_STACK_HEADING}
                  </Typography>

                  <div className="btns">
                    {!isSearchedOptionSelected && (
                      <div className="add-sort">
                        <Button startIcon={plus} customStyle="add-btn" onClick={handleAddModal}>
                          {ADD_TECH_BUTTON_TEXT}
                        </Button>

                        <div className="sort-btn-container">
                          <Button
                            variant={OUTLINED}
                            size={MEDIUM}
                            customStyle={`tech-stack-sort-button ${isSortingOn && 'active-btn'}`}
                            onClick={toggleSorting}>
                            <ImageComponent src={sort} />
                          </Button>
                          {isSortingOn && (
                            <div onClick={toggleSorting} className="transparent-backdrop"></div>
                          )}
                          <div className="ts-sort-wrapper">
                            <SortingOptions
                              isOpen={isSortingOn}
                              currentValue={sortingValue}
                              setCurrentValue={selectSort}
                              labelArray={[RECENTLY_ADDED, NAME_A_to_Z, NAME_Z_to_A]}
                              onClose={blankFunc}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="search-bar-stack">
                      <div className="search">
                        <InputBox
                          startIcon={search}
                          endIcon={searchValue ? close : ''}
                          placeholder="Search"
                          value={searchValue}
                          handleChange={handleSearchAction}
                          onEndIconClick={clearInput}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className={`${
                isSearched && !searchData.length ? 'tech-stack-body-flex' : 'tech-stack-body'
              }`}>
              <TechStackCard
                data={isSearched ? searchData : techData}
                localDelete={localDelete}
                undoLocalDelete={undoLocalDelete}
                closeToast={closeToast}
              />
            </div>
            <Modal
              header="Add Tech Stack"
              customStyle="addModal"
              onClose={closeAddModal}
              isOpen={showAddModal}>
              <div className="addModal-content">
                <div>
                  <label htmlFor={TECH_NAME} className="addModal-techLabel">
                    {TECH_STACK_LABEL}
                  </label>
                  <br />
                  <input
                    tabIndex={0}
                    autoFocus
                    type="text"
                    onKeyUp={handleKeyUp}
                    name={TECH_NAME}
                    className="addTechName"
                    placeholder={TECH_NAME_PLACEHOLDER}
                    value={newTechName}
                    autoComplete="off"
                    onChange={handleChangeAdd}
                  />
                  <br />

                  {techNameError && <Typography variant="error">{techNameError}</Typography>}
                </div>
                <div className="addModal-footer">
                  <Button customStyle="addModal-cancelBtn" onClick={closeAddModal}>
                    {CANCEL_BUTTON_TEXT}
                  </Button>
                  <Button
                    customStyle="add-modal--save-btn"
                    variant="contained"
                    onClick={addTechStack}
                    disabled={disabled}>
                    {ADD_BUTTON_LABEL}
                  </Button>
                </div>
              </div>
            </Modal>
            <Loader loading={isLoading || isTechLoading} />
          </div>
        ) : (
          <ErrorPage page="apiFailure" />
        )
      ) : (
        <ErrorPage page="noInternet" />
      )}
      {getTechStackDeleteErrorModal()}
    </Fragment>
  );
};

export default routeError(TechStack);
