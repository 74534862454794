import React, { SyntheticEvent } from 'react';
import './index.style.scss';
import Typography from '../../../../core/shared-components/typography';
import Button from '../../../../core/shared-components/button';

interface IHeaderProps {
  title: any;
  onClear: () => void;
}

function Header(props: IHeaderProps) {
  const { title } = props;

  const handleClick = (event: SyntheticEvent) => {
    const { onClear } = props;
    event.stopPropagation();
    onClear();
  };

  return (
    <div className="slots-search-header">
      <Typography customStyle="slots-search-title">
        Search Results for <span>{`"${title?.firstName} ${title?.lastName}"`}</span>
      </Typography>
      <Button variant="outlined" customStyle="slots-header-button" onClick={handleClick}>
        Clear Results
      </Button>
    </div>
  );
}
export default Header;
