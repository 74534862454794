import { IClippedSelectValue } from '../core/shared-components/clipped-select';
import { ISelectValue } from '../core/shared-components/select';
import service from './index.service';

export interface IOnboardUserExperienceFilter {
  experienceFrom: number;
  experienceTo: number;
}

export async function fetchOnboardList(
  pageNumber = 0,
  pageSize = 10,
  filter: IOnboardUserExperienceFilter[]
) {
  try {
    const response = await service.post(
      `/admin/user/onboard/list/${pageNumber}/${pageSize}`,
      filter
    );
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function fetchUserDetails(userId: number | string) {
  try {
    const response = await service.get('/admin/user', {
      params: { id: userId }
    });
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export interface IOnboardUserData {
  roleId: number | string;
  status: string | 'ARCHIVE' | 'IN_REVIEW' | 'APPROVED';
  projectIds?: number[] | string[];
  onBench?: boolean;
  techStackDetails?: {
    techStackId: number | string;
    interviewLevelId: number | string;
  }[];
}

export async function postOnboardUser(userId: (number | string)[], params: IOnboardUserData) {
  try {
    const response = await service.post('/admin/user/onboard', {
      userIds: userId,
      roleId: params.roleId,
      status: params.status,
      projectIds: params.projectIds,
      onBench: params.onBench,
      techStackDetails: params.techStackDetails
    });
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function onboardSearch(search: string, signal: AbortSignal) {
  const text = encodeURIComponent(search);
  try {
    const response = await service.get('/admin/user/onboard/search', {
      signal: signal,
      params: {
        searchApproval: text
      }
    });
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}
