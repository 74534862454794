import React, { useCallback, useMemo } from 'react';
import { MenuPlacement } from 'react-select';
import CustomSelect, { ISelectOptions } from 'app/core/shared-components/customSelect';
import { ISelectedSlotState } from '../slot-request-card';
import './index.style.scss';

interface ISplitSlot {
  slotOptions: ISelectOptions[];
  onSlotChange: (params: ISelectedSlotState) => void;
  selectedSlot: ISelectedSlotState;
  menuPlacement?: MenuPlacement;
}

const SplitSlot = ({
  slotOptions,
  onSlotChange,
  selectedSlot,
  menuPlacement = 'auto'
}: ISplitSlot) => {
  const startSlotOptions = useMemo(
    () =>
      slotOptions
        ?.filter((option) => {
          if (selectedSlot) return option.id < selectedSlot?.endTime?.id;
        })
        .slice(0, -1),
    [selectedSlot]
  );

  const endSlotOptions = useMemo(
    () =>
      slotOptions
        ?.filter((option) => {
          if (selectedSlot) return option.id > selectedSlot?.startTime?.id;
        })
        .slice(1),
    [selectedSlot]
  );

  const handleSlotChange = useCallback(
    (key: string) => (params: ISelectOptions | ISelectOptions[]) => {
      const date = params as ISelectOptions;
      if (onSlotChange && selectedSlot) {
        onSlotChange({ ...selectedSlot, [`${key}`]: date });
      }
    },
    [selectedSlot]
  );

  return (
    <div className="split-slot-container">
      <div className="slot-duration">Slot Duration:</div>
      <div className="split-slot-option-container">
        <CustomSelect
          dropdownOptions={startSlotOptions || []}
          selectedOptions={selectedSlot?.startTime || null}
          onChange={handleSlotChange('startTime')}
          menuPosition="absolute"
          isSearchable={false}
          menuPlacement={menuPlacement}
          customClass="no-flex"
          maxMenuHeight={129}
        />{' '}
        -{' '}
        <CustomSelect
          dropdownOptions={endSlotOptions || []}
          selectedOptions={selectedSlot?.endTime || null}
          onChange={handleSlotChange('endTime')}
          menuPosition="absolute"
          isSearchable={false}
          menuPlacement={menuPlacement}
          customClass="no-flex"
          maxMenuHeight={129}
        />
      </div>
    </div>
  );
};

export default SplitSlot;
