import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import Button from 'app/core/shared-components/button';
import Drawer from 'app/core/shared-components/drawer';
import Form, { ITechStackArray } from 'app/core/shared-components/form';
import { ISelectValue } from 'app/core/shared-components/select';
import Typography from 'app/core/shared-components/typography';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import { getSpecificUserDetails, onboardUser } from 'app/redux/slices/onboardUserSlice';
import { RECENTY_ADDED_PANEL, STATUSENUM_ARCHIVE } from 'helpers/constants';
import { notify } from 'helpers/toastHelper';
import withRouter from 'router/withRouter';
import { v4 as uuidv4 } from 'uuid';
import './index.style.scss';
import { useNavigate } from 'react-router';
import { getExperience } from 'helpers/utils';
import { PANEL, TALENT_AQUISITION } from 'helpers/appRoutes';

interface ISideModalProps {
  open: boolean;
  onClose?: () => void;
  formHeading?: string;
  getUsersList?: () => void;
}

const SideModal = (props: ISideModalProps) => {
  const { open = false, onClose, formHeading, getUsersList } = props;
  const reduxState = useAppSelector((state) => state);
  const { onboardUser: reduxOnboardState, shared } = reduxState;
  const { userDetails, userId } = reduxOnboardState;
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();

  const handleSave = async (
    role: ISelectValue,
    status: string,
    projects?: ISelectValue[],
    stackLop?: ITechStackArray[]
  ) => {
    if (Array.isArray(userId)) {
      let data = {
        userId: userId,
        data: {
          roleId: role.id,
          status: status
        }
      };
      if (role.id.toString() === shared.panel?.id.toString()) {
        const panelData = {
          projectIds: projects?.map((item) => item.id.toString()) || [],
          onBench: !projects?.length,
          techStackDetails: stackLop ? stackLop : []
        };
        Object.assign(data.data, panelData);
      }
      await dispatch(onboardUser(data));
      const navigateTo = role?.id === '3' ? TALENT_AQUISITION : PANEL;
      const showToast =
        userId.length > 1
          ? `${userId.length} users`
          : `${userDetails?.firstName} ${userDetails?.lastName}`;
      notify(
        true,
        <div className="toast-container-detail">
          <Typography>{`Successfully onboarded ${showToast}`}</Typography>
          <Button
            variant="outlined"
            customStyle="toast-detail-button"
            onClick={handleNavigate(
              navigateTo,
              status === STATUSENUM_ARCHIVE ? 2 : 0,
              userDetails?.userId && userDetails?.userId,
              RECENTY_ADDED_PANEL
            )}>
            View details
          </Button>
        </div>,
        uuidv4().toString(),
        handleToastClose
      );
      getUsersList && getUsersList();
      onClose && onClose();
      return;
    }
  };

  const handleToastClose = () => {};

  const handleNavigate =
    (navigateTo: string, statusNav?: number, id?: number, status?: string) => () => {
      toast.dismiss();
      navigate(navigateTo, {
        state: { tab: statusNav, id: id, status: status }
      });
    };
  useEffect(() => {
    if (Array.isArray(userId) && userId.length === 1) {
      const [firstUserId, ...rest] = userId;
      dispatch(getSpecificUserDetails(firstUserId));
    }
  }, [userId]);

  return (
    <Drawer
      isOpen={open}
      onClose={onClose}
      bodyStyles="modal-drawer-body"
      headerProps={<Typography customStyle="side-modal-label">{formHeading}</Typography>}>
      <Form
        showStatusField={false}
        showProfileField={userId?.length === 1}
        userDetail={{
          name: `${userDetails?.firstName} ${userDetails?.lastName}`,
          designation: userDetails?.designation || '',
          email: userDetails?.emailId || '',
          experience: userDetails?.totalExperience && getExperience(userDetails?.totalExperience),
          mobile: `${userDetails?.mobileNo}`,
          profileImageUrl: `${userDetails?.profileImageUrl}`
        }}
        onSave={handleSave}
        onCancel={onClose}
      />
    </Drawer>
  );
};

export default withRouter(SideModal);
