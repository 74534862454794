import Images from '../../../../../assets/images';
import React from 'react';
import './index.style.scss';
import ImageComponent from '../../../../core/shared-components/image';
import Typography from '../../../../core/shared-components/typography';
import Button from '../../../../core/shared-components/button';
import moment, { Moment } from 'moment';

interface IMonthsToggleProps {
  month: Moment;
  onMonthToggle: (month: Moment) => void;
  hideCurrentMonth?: boolean;
}

interface IMonthsToggleStates {}

const MonthsToggle = (props: IMonthsToggleProps) => {
  const { chevronRightActive, chevronRightDisabled, chevronLeftActive, chevronLeftDisabled } =
    Images;

  const { month, onMonthToggle, hideCurrentMonth } = props;

  const currentMonth = hideCurrentMonth ? moment().subtract(1, 'months') : moment();

  const disabled = month.format('MMMM YYYY') === currentMonth.format('MMMM YYYY');
  const leftDisabled = month.format('MMMM YYYY') === 'September 2022';

  const handleLeftChevronClick = () => {
    const newMonth = moment(month).subtract(1, 'month');
    onMonthToggle(newMonth);
  };

  const handleRightChevronClick = () => {
    const newMonth = moment(month).add(1, 'month');
    onMonthToggle(newMonth);
  };
  return (
    <div className="months-toggle">
      <Button
        startIcon={leftDisabled ? chevronLeftDisabled : chevronLeftActive}
        customStyle={leftDisabled ? 'chevron left disable' : 'chevron left'}
        disabled={leftDisabled}
        onClick={handleLeftChevronClick}></Button>
      <Button
        startIcon={disabled ? chevronRightDisabled : chevronRightActive}
        customStyle="chevron right"
        onClick={handleRightChevronClick}
        disabled={disabled}></Button>
      <Typography variant="headline-16" customStyle="month-name">
        {month.format('MMMM YYYY')}
      </Typography>
    </div>
  );
};
export default MonthsToggle;
