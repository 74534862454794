import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import { ToastContainer } from 'react-toastify';
import './assets/styles/base.scss';

const container = document.getElementById('root')!;
const root = createRoot(container);

Sentry.init({
  environment: process.env.REACT_APP_ENV || '',
  dsn: process.env.REACT_APP_SENTRY_ID || '',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    })
  ],
  tracesSampleRate: 1.0
});

root.render(
  <BrowserRouter>
    <App />
    <ToastContainer newestOnTop />
  </BrowserRouter>
);
