import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from 'app/core/shared-components/button';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import { getUserInterviewLimits, updateInterviewLimits } from 'app/redux/slices/settingSlice';
import {
  DAILY,
  DEFAULT_DAILY_INTERVIEW_LIMIT,
  DEFAULT_WEEKLY_INTERVIEW_LIMIT,
  MAX_INTERVIEWS,
  SAVE_BUTTON_TEXT,
  WEEKLY
} from 'helpers/constants';
import {
  INTERVIEW_CANCELLED_ERR_MSG,
  INTERVIEW_CANCELLED_ERR_MSG_LIMIT
} from 'helpers/messages.constants';
import './index.style.scss';

interface IInterviewLimitStates {
  userId: number;
  weeklyLimit: number;
  dailyLimit: number;
  defaultWeeklyLimit: number;
  defaultdailyLimit: number;
  error: string;
}

function InterviewLimit() {
  const [interviewLimitState, setInterviewLimitState] = useState<IInterviewLimitStates>({
    userId: 0,
    weeklyLimit: DEFAULT_WEEKLY_INTERVIEW_LIMIT,
    dailyLimit: DEFAULT_DAILY_INTERVIEW_LIMIT,
    defaultWeeklyLimit: DEFAULT_WEEKLY_INTERVIEW_LIMIT,
    defaultdailyLimit: DEFAULT_DAILY_INTERVIEW_LIMIT,
    error: ''
  });
  const dispatch = useAppThunkDispatch();
  const userState = useAppSelector((state) => state.user);

  const { weeklyLimit, dailyLimit, defaultWeeklyLimit, defaultdailyLimit, error } =
    interviewLimitState;

  const disableSaveButton =
    (weeklyLimit === defaultWeeklyLimit && dailyLimit === defaultdailyLimit) ||
    error !== '' ||
    weeklyLimit < 0 ||
    dailyLimit < 0;

  const weeklyLimiktChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event?.target?.value) < 0) return;
    setInterviewLimitState((prevState) => ({
      ...prevState,
      weeklyLimit: event.target.value === '' ? 0 : parseInt(event.target.value)
    }));
    if (parseInt(event.target.value) < 0 || dailyLimit! < 0) {
      setInterviewLimitState((prevState) => ({
        ...prevState,
        error: INTERVIEW_CANCELLED_ERR_MSG
      }));
      return;
    }
    if (dailyLimit > parseInt(event.target.value) || isNaN(parseInt(event.target.value))) {
      setInterviewLimitState((prevState) => ({
        ...prevState,
        error: INTERVIEW_CANCELLED_ERR_MSG_LIMIT
      }));
      return;
    } else
      setInterviewLimitState((prevState) => ({
        ...prevState,
        error: ''
      }));
  };

  const dailyLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value) < 0) return;
    setInterviewLimitState((prevState) => ({
      ...prevState,
      dailyLimit: event.target.value === '' ? 0 : parseInt(event.target.value)
    }));
    if (parseInt(event.target.value) < 0 || weeklyLimit < 0) {
      setInterviewLimitState((prevState) => ({
        ...prevState,
        error: INTERVIEW_CANCELLED_ERR_MSG
      }));
      return;
    }
    if (weeklyLimit < parseInt(event.target.value)) {
      setInterviewLimitState((prevState) => ({
        ...prevState,
        error: INTERVIEW_CANCELLED_ERR_MSG_LIMIT
      }));
      return;
    } else
      setInterviewLimitState((prevState) => ({
        ...prevState,
        error: ''
      }));
  };

  const setDefaultInterViewLimits = async () => {
    const data = await dispatch(getUserInterviewLimits());
    const { weeklyInterviewCount, dailyInterviewCount } = data?.payload?.data;

    setInterviewLimitState((prevState) => ({
      ...prevState,
      defaultWeeklyLimit:
        weeklyInterviewCount === null
          ? DEFAULT_WEEKLY_INTERVIEW_LIMIT
          : parseInt(weeklyInterviewCount, 10),
      defaultdailyLimit:
        dailyInterviewCount === null
          ? DEFAULT_DAILY_INTERVIEW_LIMIT
          : parseInt(dailyInterviewCount, 10),
      weeklyLimit:
        weeklyInterviewCount === null
          ? DEFAULT_WEEKLY_INTERVIEW_LIMIT
          : parseInt(weeklyInterviewCount, 10),
      dailyLimit:
        dailyInterviewCount === null
          ? DEFAULT_DAILY_INTERVIEW_LIMIT
          : parseInt(dailyInterviewCount, 10)
    }));
  };

  const setInterviewLimits = async () => {
    const { weeklyLimit, dailyLimit } = interviewLimitState;
    await dispatch(
      updateInterviewLimits({
        userId: userState?.userData?.userId,
        weeklyLimit: weeklyLimit,
        dailyLimit: dailyLimit
      })
    );
    setDefaultInterViewLimits();
  };

  const handleKeyValue = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      event.keyCode === 8 ||
      event.keyCode === 37 ||
      event.keyCode === 39 ||
      (event.keyCode > 47 && event.keyCode < 58)
    ) {
      return true;
    } else if (event.keyCode === 9) {
      return;
    } else {
      return event.preventDefault();
    }
  };

  useEffect(() => {
    setDefaultInterViewLimits();
  }, []);

  return (
    <div className="pa-settings-max-limit-parent-wrapper">
      <div className="pa-settings-max-limit-wrapper">
        <div className="pa-settings-max-limit-left">
          <div className="pa-settings-title">{MAX_INTERVIEWS}: </div>
          <div className="pa-input-wrapper">
            <div className="pa-weekly-input-div">
              <div className="pa-settings-input-label">{WEEKLY}</div>
              <input
                className="pa-input-field"
                type="tel"
                value={weeklyLimit}
                onChange={weeklyLimiktChange}
                onKeyDown={handleKeyValue}
                pattern="^[0-9]*$"
                tabIndex={0}
              />
            </div>
            <div className="separator"></div>
            <div className="pa-daily-input-div">
              <div className="pa-settings-input-label">{DAILY}</div>
              <input
                className="pa-input-field"
                type="tel"
                value={dailyLimit}
                onChange={dailyLimitChange}
                onKeyDown={handleKeyValue}
                pattern="^[0-9]*$"
                tabIndex={0}
              />
            </div>
          </div>
        </div>
        <Button
          variant="contained"
          customStyle="pa-settings-save-button"
          disabled={disableSaveButton}
          onClick={setInterviewLimits}>
          {SAVE_BUTTON_TEXT}
        </Button>
      </div>
      {interviewLimitState?.error && <div className="il-error">{interviewLimitState?.error}</div>}
    </div>
  );
}

export default InterviewLimit;
