import React from 'react';
import { toast } from 'react-toastify';
import { UNDO } from '../../../../helpers/constants';
import './index.style.scss';

interface ToastUndoProps {
  toastId: string | number;
  toastMessage: string;
  handleUndo?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ToastUndo = ({ toastId, toastMessage, handleUndo }: ToastUndoProps) => {
  const closeToastWithoutAction = () => {
    toast.update(toastId, { onClose: () => {}, hideProgressBar: true, autoClose: 1 });
    toast.dismiss(toastId);
  };
  const onUndoClick = handleUndo || closeToastWithoutAction;
  return (
    <div className="undo-toast">
      <div>{toastMessage}</div>
      <div className="toast-undo-btn" id={toastId.toString()} onClick={onUndoClick}>
        {UNDO}
      </div>
    </div>
  );
};
export default ToastUndo;
