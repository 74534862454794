import React, {
  CSSProperties,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import moment from 'moment';
import 'moment-timezone';
import {
  Calendar,
  Components,
  Culture,
  DateLocalizer,
  SlotInfo,
  View,
  Views,
  momentLocalizer
} from 'react-big-calendar';
import { Location, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import Popover from '@mui/material/Popover';
import AddSlotForm from 'app/core/shared-components/add-slot-form';
import Button, { IButtonProps } from 'app/core/shared-components/button';
import ButtonGroup from 'app/core/shared-components/button-group';
import ImageComponent from 'app/core/shared-components/image';
import Loader from 'app/core/shared-components/loader';
import Selector, { ISelectorValue } from 'app/core/shared-components/selector';
import SlotRequestDrawer from 'app/core/shared-components/slot-request-drawer';
import ToastUndo from 'app/core/shared-components/toast-undo';
import CustomMonthHeader from 'app/pages/Schedule/components/custom-month-header';
import CustomToolbarComponent from 'app/pages/Schedule/components/custom-toolbar';
import CustomWeekHeaderComponent from 'app/pages/Schedule/components/custom-week-header';
import GoogleEventPopup from 'app/pages/Schedule/components/google-event-popup';
import SlotAddPopup from 'app/pages/Schedule/components/slot-add-popup';
import SlotAvailablePopup from 'app/pages/Schedule/components/slot-available-popup';
import SlotCancelPopup, { ISlotCancel } from 'app/pages/Schedule/components/slot-cancel-popup';
import SlotRequestedPopup from 'app/pages/Schedule/components/slot-requested-popup';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  ICurrentSelectedUser,
  IEvents,
  cancelBookedSlot,
  changeCurrentDate,
  changeView,
  getPanelSchedule,
  reAddEvent,
  removeEvent,
  resetPage,
  resetSearch,
  searchPanel,
  setUser,
  showErrorScreen,
  slotDelete,
  switchToCurrentUser,
  switchUser
} from 'app/redux/slices/scheduleSlice';
import { setSelectedNotificationData } from 'app/redux/slices/sharedSlice';
import { IAddSlotBody } from 'app/services/slots.service';
import Images from 'assets/images';
import { isAdmin } from 'helpers/authHelper';
import {
  ADD_SLOT_BUTTON,
  ADD_SLOT_THROUGH_CALENDAR,
  DAY_VIEW,
  GA_CATEGORY,
  GA_EVENT_TYPES,
  GA_LABEL,
  MONTH_VIEW,
  MY_SCHEDULE,
  PANEL_STATUS,
  SLOT_REQUEST_BUTTON,
  WEEK_VIEW
} from 'helpers/constants';
import {
  PANEL_ARCHIVED_MESSAGE,
  PANEL_INREVIEW_MESSAGE,
  SLOT_ADDED_TOAST_MESSAGE,
  SLOT_CANCEL_TOAST_MESSAGE,
  SLOT_DELETED_TOAST_MESSAGE,
  SLOT_OVERLAP_TOAST_MESSAGE
} from 'helpers/messages.constants';
import { notify } from 'helpers/toastHelper';
import { EVENT_TYPES, SCHEDULE_EVENTS_TYPE } from 'helpers/types';
import { trackGA } from 'helpers/utils';
import routeError from 'router/routeError';
import { v4 as uuid } from 'uuid';
import Typography from 'app/core/shared-components/typography';
import ErrorPage from '../Error';
import CandidateDetail from './components/candidate-detail-card';
import SlotCancelledPopup from './components/slot-cancelled-popup';
import SlotEditPopup from './components/slot-edit-popup';
import SlotEventsDrawer from './components/slot-events-drawer';
import './index.style.scss';
import { timeStamps } from 'helpers/timeStamp.constants';

moment.tz.setDefault('Asia/Kolkata');
const localizer = momentLocalizer(moment);

const headerStyle: CSSProperties = {
  flexGrow: 1,
  justifyContent: 'space-between',
  flexDirection: 'row-reverse'
};

interface ITechStack {
  techStackId: string;
  techStackName: string;
  levelOfPanel: string;
}
export interface ISlotDetails {
  from: string;
  to: string;
  slotId: string;
  status: number;
  techStack: ITechStack[];
  eventSummary: string;
  isCalendarEvent: boolean;
  isAllDayEvent: boolean | null;
  isRequestedSlot: boolean;
}

interface IScheduleStates {
  buttonTabIndex: 0 | 1 | 2;
  showPopUp: boolean;
  position: {
    left: number;
    top: number;
  };
  start: Date | null;
  end: Date | null;
  type: EVENT_TYPES;
  slotRequestDrawer: boolean;
  slotDrawer: boolean;
  addSlotDrawer: boolean;
  title: string;
  eventDetail: IEvents | null;
  events: IEvents[];
  slotEventDrawerHeaderDate: Date;
  isAdmin: boolean;
  temporaryEvents: IEvents[];
}

interface INavigationParam {
  userId: string;
}

function Schedule() {
  const [scheduleState, setScheduleState] = useState<IScheduleStates>({
    buttonTabIndex: 0,
    showPopUp: false,
    position: { left: 0, top: 0 },
    start: null,
    end: null,
    type: SCHEDULE_EVENTS_TYPE.ADD,
    slotRequestDrawer: false,
    slotDrawer: false,
    title: '',
    eventDetail: null,
    events: [],
    slotEventDrawerHeaderDate: new Date(),
    addSlotDrawer: false,
    isAdmin: false,
    temporaryEvents: []
  });

  const location: Location = useLocation();
  const abortSignal = useRef<any>(null);
  const calendarRef = useRef<any>(null);

  const reduxScheduleState = useAppSelector((state) => state.schedule);
  const { selectedNotificationData } = useAppSelector((state) => state.shared);
  const reduxUserState = useAppSelector((state) => state.user);

  const dispatch = useAppThunkDispatch();
  const {
    searchOptions,
    searchLoading,
    searchError,
    currentSelectedUser,
    currentUser,
    pageLoading,
    eventsLoading,
    pageError,
    slotRequests
  } = reduxScheduleState;

  const { userData } = reduxUserState;

  const changeCurrentSelectedUser = async (userId: string | number) => {
    await dispatch(switchUser(userId));
  };

  const calendarComponents: Components<IEvents, Object> = useMemo(
    () => ({
      week: {
        header: CustomWeekHeaderComponent,
        event: (event) => {
          const duration = moment.duration(
            moment(event?.event?.end).diff(moment(event?.event?.start))
          );

          if (duration.asMinutes() > 46) {
            return <div>{event?.title}</div>;
          } else {
            if (event?.event?.type === SCHEDULE_EVENTS_TYPE.EXISTING)
              return <div>{event?.title}</div>;
            else return null;
          }
        }
      },
      month: {
        header: CustomMonthHeader,
        event: (event) => (
          <div>
            {`${moment(event?.event?.start).format(timeStamps.HH_MM_a)} -
            ${moment(event?.event?.end).format(timeStamps.HH_MM_a)}`}
          </div>
        )
      },
      toolbar: CustomToolbarComponent
    }),
    []
  );

  const memoisedCalendarMessages = useMemo(() => {
    return {
      showMore: (count: number) => `${count} more`
    };
  }, []);

  const memoisedCalendarViews = useMemo(() => [Views.WEEK, Views.DAY, Views.MONTH], []);

  const memoisedCurrentDate = useMemo(() => {
    const { currentDate } = reduxScheduleState;
    return currentDate;
  }, [reduxScheduleState.currentDate]);

  const memoisedCurrentView = useMemo(() => {
    const { currentView } = reduxScheduleState;
    return currentView;
  }, [reduxScheduleState.currentView]);

  const memoisedEvents = useMemo(
    () => reduxScheduleState.calendarEvents,
    [reduxScheduleState.calendarEvents]
  );

  const memoisedScrollToTime = useMemo(() => {
    return moment().set('hour', 9).toDate();
  }, []);

  const memoisedButtonOption: IButtonProps[] = useMemo(() => {
    return [
      {
        variant: 'contained',
        label: 'Week'
      },
      {
        variant: 'contained',
        label: 'Day'
      },
      {
        variant: 'contained',
        label: 'Month'
      }
    ];
  }, []);

  const formats = useMemo(
    () => ({
      formats: {
        timeGutterFormat: (date: Date, culture?: Culture, localizer?: DateLocalizer) =>
          `${localizer?.format(date, 'h a', culture)}`
      }
    }),
    []
  );

  const handleButtonGroupClick = useCallback((position: number) => {
    switch (position) {
      case 0:
        dispatch(changeView(Views.WEEK));
        trackGA(GA_CATEGORY.SCHEDULE, GA_LABEL.SCHEDULE, WEEK_VIEW, GA_EVENT_TYPES.BUTTON_CLICK);
        break;
      case 1:
        dispatch(changeView(Views.DAY));
        trackGA(GA_CATEGORY.SCHEDULE, GA_LABEL.SCHEDULE, DAY_VIEW, GA_EVENT_TYPES.BUTTON_CLICK);
        break;
      case 2:
        dispatch(changeView(Views.MONTH));
        trackGA(GA_CATEGORY.SCHEDULE, GA_LABEL.SCHEDULE, MONTH_VIEW, GA_EVENT_TYPES.BUTTON_CLICK);
        break;
      default:
        dispatch(changeView(Views.WEEK));
        trackGA(GA_CATEGORY.SCHEDULE, GA_LABEL.SCHEDULE, WEEK_VIEW, GA_EVENT_TYPES.BUTTON_CLICK);
        break;
    }
  }, []);

  const eventPropGetter = useCallback((event: IEvents) => {
    return {
      ...(event.type === SCHEDULE_EVENTS_TYPE.EXISTING && {
        className: 'rbc-event-existing'
      }),
      ...(event.type === SCHEDULE_EVENTS_TYPE.BOOKED && {
        className: 'rbc-event-booked'
      }),
      ...(event.type === SCHEDULE_EVENTS_TYPE.AVAILABLE && {
        className: 'rbc-event-available'
      }),
      ...(event.type === SCHEDULE_EVENTS_TYPE.CANCELLED && {
        className: 'rbc-event-cancelled'
      }),
      ...(event.type === SCHEDULE_EVENTS_TYPE.REQUESTED && {
        className: 'rbc-event-requested'
      }),
      ...(event.type === SCHEDULE_EVENTS_TYPE.TEMPORARY && {
        className: 'rbc-event-temporary'
      })
    };
  }, []);

  const onView = useCallback((view: View) => {
    dispatch(changeView(view));
  }, []);

  const handleSlotClick = useCallback(
    (slotInfo: SlotInfo) => {
      trackGA(
        GA_CATEGORY.SCHEDULE,
        GA_LABEL.SCHEDULE,
        ADD_SLOT_THROUGH_CALENDAR,
        GA_EVENT_TYPES.BUTTON_CLICK
      );
      const currentStart = moment(slotInfo.start).set({ hour: 6, minutes: 0 });
      const currentEnd = moment(slotInfo.end).set({ hour: 21, minutes: 0 });
      const slotStart = moment(slotInfo.start);
      const slotEnd = moment(slotInfo.end);
      const presentDay = moment();
      const startCondition = slotStart.isSameOrAfter(currentStart);
      const endCondition = slotEnd.isSameOrBefore(currentEnd);
      if (
        startCondition &&
        endCondition &&
        slotStart.isSameOrAfter(presentDay, 'date') &&
        currentSelectedUser?.status === PANEL_STATUS.approved
      ) {
        const posX = slotInfo.box?.clientX || slotInfo.bounds?.x || 0;
        const posY = slotInfo.box?.clientY || slotInfo.bounds?.y || 0;
        const endTime = slotEnd.isSame(currentEnd, 'hour')
          ? slotEnd.clone().subtract(30, 'minutes').toDate()
          : slotInfo.end;
        setScheduleState((prevState) => ({
          ...prevState,
          showPopUp: true,
          position: {
            left: posX - 50,
            top: posY + 50
          },
          start: slotInfo.start,
          end: endTime,
          type: SCHEDULE_EVENTS_TYPE.ADD,
          temporaryEvents: [
            {
              id: 0,
              start: moment(slotInfo.start),
              end: moment(endTime).add(30, 'minutes'),
              type: SCHEDULE_EVENTS_TYPE.TEMPORARY,
              title: 'Available Slot'
            }
          ]
        }));
      }
    },
    [reduxScheduleState.currentSelectedUser]
  );

  const handleEventClick = useCallback(
    (calEvent: IEvents, event: React.SyntheticEvent<HTMLElement, Event> | any) => {
      setScheduleState((prevState) => ({
        ...prevState,
        showPopUp: true,
        position: { left: event?.clientX! || 0, top: event?.clientY! || 0 },
        start: moment(calEvent.start).toDate()!,
        end: moment(calEvent.end).toDate()!,
        type: calEvent.type,
        title: calEvent.title as string,
        eventDetail: calEvent
      }));
    },
    []
  );

  const handlePopupClose = useCallback(() => {
    const { cancelledPopupLoading } = reduxScheduleState;
    if (!cancelledPopupLoading)
      setScheduleState((prevState) => ({
        ...prevState,
        showPopUp: false,
        position: {
          left: 0,
          top: 0
        },
        type: SCHEDULE_EVENTS_TYPE.ADD,
        title: '',
        eventDetail: null,
        temporaryEvents: []
      }));
  }, [reduxScheduleState.cancelledPopupLoading]);

  const handleSearchChange = useCallback((searchText: string) => {
    if (abortSignal.current) abortSignal.current.abort();
    if (searchText.length) {
      abortSignal.current = new AbortController();
      dispatch(searchPanel({ searchText, abortSignal: abortSignal.current.signal }));
      return;
    }
    dispatch(resetSearch());
  }, []);

  const handleDropdownClose = useCallback(() => {
    dispatch(resetSearch());
  }, []);

  const handleSelectorChange = useCallback(async (param: ISelectorValue | null) => {
    if (param) {
      if (param?.id?.toString().toLowerCase() === userData?.userId?.toString().toLowerCase()) {
        const data = {
          id: param?.id.toString(),
          label: MY_SCHEDULE,
          imageSource: param?.imageSource
        };
        await changeCurrentSelectedUser(data?.id);
        return;
      }
      await changeCurrentSelectedUser(param?.id);
    }
  }, []);

  const showHeader = useCallback(
    (param: ISelectorValue | null) => {
      if (param) return param?.id?.toString() === currentUser?.id?.toString();
      return false;
    },
    [reduxScheduleState.currentUser]
  );

  const handleNavigate = useCallback((newDate: Date) => {
    dispatch(changeCurrentDate(newDate));
  }, []);

  const handleSlotRequestButton = useCallback(() => {
    trackGA(
      GA_CATEGORY.SCHEDULE,
      GA_LABEL.SCHEDULE,
      SLOT_REQUEST_BUTTON,
      GA_EVENT_TYPES.BUTTON_CLICK
    );
    setScheduleState((prevState) => ({
      ...prevState,
      slotRequestDrawer: !prevState.slotRequestDrawer
    }));
  }, []);
  const handleSlotRequestDrawer = useCallback(() => {
    setScheduleState((prevState) => ({
      ...prevState,
      slotRequestDrawer: !prevState.slotRequestDrawer
    }));
  }, []);

  const handleBookCancelSlot = (slotId: number | string) => async (param: ISlotCancel) => {
    handlePopupClose();
    const response = await dispatch(
      cancelBookedSlot({
        cancelComments: param.cancelComments,
        slotId: slotId,
        makeSlotAvailable: param.makeSlotAvailable,
        cancelOption: param.cancelOption
      })
    );
    if (response.payload.data) {
      notify(true, SLOT_CANCEL_TOAST_MESSAGE, slotId, () => {});
    }
  };

  const handleDeleteUndo = useCallback(
    (slotId: string | number) => () => {
      dispatch(reAddEvent(slotId));
      toast.update(slotId, { onClose: () => {}, hideProgressBar: true, autoClose: 1 });
      toast.dismiss(slotId);
    },
    []
  );

  const handleDeleteClose = useCallback(async (toastId: number | string) => {
    await dispatch(slotDelete(toastId));
  }, []);

  const handleDeleteSlotClick = useCallback(
    (event: IEvents | null) => async () => {
      handlePopupClose();
      if (event) {
        dispatch(removeEvent(event?.id));
        notify(
          false,
          <ToastUndo
            toastId={event?.id}
            toastMessage={SLOT_DELETED_TOAST_MESSAGE}
            handleUndo={handleDeleteUndo(event?.id)}
          />,
          event?.id,
          handleDeleteClose
        );
      }
    },
    []
  );

  const handleEditSlot = useCallback(async () => {
    setScheduleState((prevState) => ({ ...prevState, type: SCHEDULE_EVENTS_TYPE.EDIT }));
  }, []);

  const handleStartAccessor = useCallback((event: IEvents) => event?.start?.toDate(), []);
  const handleEndAccessor = useCallback((event: IEvents) => event?.end?.toDate(), []);

  const handleShowMore = useCallback((events: IEvents[], date: Date) => {
    setScheduleState((prevState) => ({
      ...prevState,
      slotDrawer: true,
      events: events,
      slotEventDrawerHeaderDate: date
    }));
  }, []);

  const handleEventDrawerClose = useCallback(() => {
    setScheduleState((prevState) => ({ ...prevState, slotDrawer: false, events: [] }));
  }, []);

  const handleAddSlotDrawer = useCallback(() => {
    setScheduleState((prevState) => ({ ...prevState, addSlotDrawer: !prevState.addSlotDrawer }));
  }, []);

  const handleAddSlotButtonClick = () => {
    !scheduleState.addSlotDrawer &&
      trackGA(
        GA_CATEGORY.SCHEDULE,
        GA_LABEL.SCHEDULE,
        ADD_SLOT_BUTTON,
        GA_EVENT_TYPES.BUTTON_CLICK
      );
    setScheduleState((prevState) => ({ ...prevState, addSlotDrawer: !prevState.addSlotDrawer }));
  };

  const handleAddSlot = useCallback(
    async (_slots: IAddSlotBody, errorMessage: any) => {
      if (!errorMessage?.error) {
        await dispatch(getPanelSchedule());
        const userLabel =
          currentSelectedUser?.id.toString() === currentUser?.id.toString()
            ? ''
            : `for ${currentSelectedUser?.label}`;
        notify(true, `${SLOT_ADDED_TOAST_MESSAGE} ${userLabel}`, 1, () => {});
      } else if (errorMessage?.error[0]?.code === 400)
        notify(true, <Fragment>{SLOT_OVERLAP_TOAST_MESSAGE}</Fragment>, uuid(), () => {}, 'error');
      else dispatch(showErrorScreen());
    },
    [reduxScheduleState.currentSelectedUser, reduxScheduleState.currentUser]
  );

  const handleSlotRequestAccept = useCallback(async () => {
    await dispatch(getPanelSchedule());
  }, []);

  const getSlotDetails = async () => {
    if (reduxScheduleState.currentSelectedUser?.id) {
      await dispatch(getPanelSchedule(reduxScheduleState?.currentSelectedUser?.id));
      if (selectedNotificationData?.id && selectedNotificationData?.slotDate) {
        dispatch(changeView(Views.DAY));
        dispatch(changeCurrentDate(moment(selectedNotificationData?.slotDate).toDate()));
      }
    }
  };

  const updateUser = () => {
    const userRole = reduxUserState?.userData?.role.map((item) => item?.role);
    const hasAdminRole = isAdmin(userRole);
    const data: ICurrentSelectedUser = {
      id: userData?.userId,
      label: MY_SCHEDULE,
      imageSource: userData?.image,
      designation: userData?.designation,
      email: userData?.email,
      techStackInterviewLevelDtoList: [],
      projectNameList: [],
      maxInterviewsDaily: 0,
      maxInterviewsWeekly: 0,
      slots: [],
      status: 'APPROVED'
    };
    setScheduleState((prevState) => ({ ...prevState, isAdmin: hasAdminRole }));
    dispatch(setUser(data));
    if (location.state) {
      const { userId } = location.state as INavigationParam;
      if (userId?.toString() === userData?.userId?.toString()) dispatch(switchToCurrentUser(data));
      else changeCurrentSelectedUser(userId);
    } else dispatch(switchToCurrentUser(data));
  };

  const updateCurrentView = () => {
    const { currentView } = reduxScheduleState;
    if (currentView === Views.WEEK)
      setScheduleState((prevState) => ({ ...prevState, buttonTabIndex: 0 }));
    else if (currentView === Views.DAY)
      setScheduleState((prevState) => ({ ...prevState, buttonTabIndex: 1 }));
    else if (currentView === Views.MONTH)
      setScheduleState((prevState) => ({ ...prevState, buttonTabIndex: 2 }));
  };

  useEffect(() => {
    getSlotDetails();
  }, [reduxScheduleState?.currentSelectedUser]);

  useEffect(() => {
    updateUser();
    return () => {
      dispatch(resetPage());
    };
  }, [reduxUserState?.userData]);

  useEffect(() => {
    updateCurrentView();
  }, [reduxScheduleState.currentView]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedNotificationData({ id: '', slotDate: '' }));
    };
  }, []);

  return (
    <Fragment>
      {!pageError ? (
        <Fragment>
          <div className="schedule-container">
            <header className="schedule-header-wrapper">
              {scheduleState.isAdmin && (
                <Selector
                  value={currentSelectedUser}
                  options={searchOptions}
                  onSearchTextChange={handleSearchChange}
                  placeholder={'Loading...'}
                  customClass="schedule-header-selector"
                  dropDownClass="schedule-header-selector-dropdown"
                  debounceInterval={800}
                  showLoader={searchLoading}
                  showError={searchError}
                  onDropDownClose={handleDropdownClose}
                  onChangeHandler={handleSelectorChange}
                  showDropDownHeader={showHeader(currentSelectedUser) ? null : currentUser}
                />
              )}
              <div
                className="schedule-header-right-container"
                style={scheduleState.isAdmin ? undefined : headerStyle}>
                <div className="mega-phone-container">
                  {currentSelectedUser?.id?.toString() === currentUser?.id?.toString() && (
                    <Button
                      id="notification-icon"
                      disabled={scheduleState.slotRequestDrawer}
                      onClick={handleSlotRequestButton}
                      variant="outlined"
                      data-value={slotRequests.length}
                      customStyle={
                        scheduleState.slotRequestDrawer
                          ? 'schedule-megaphone-active schedule-megaphone-btn'
                          : slotRequests.length
                          ? 'schedule-megaphone-btn-count schedule-megaphone-btn'
                          : 'schedule-megaphone-btn'
                      }>
                      <ImageComponent src={Images.megaphone} />
                    </Button>
                  )}
                  {currentSelectedUser?.status === PANEL_STATUS.approved && (
                    <Button
                      variant="contained"
                      startIcon={Images.plus}
                      customStyle="schedule-add-slot-btn"
                      onClick={handleAddSlotButtonClick}>
                      Add Slot
                    </Button>
                  )}
                </div>
                <ButtonGroup
                  orientation="horizontal"
                  customStyle="schedule-view-btn-group"
                  options={memoisedButtonOption}
                  activeButtonClick={handleButtonGroupClick}
                  tabValue={scheduleState.buttonTabIndex}
                  showSelectedButton
                />
              </div>
            </header>
            {currentSelectedUser && currentSelectedUser?.id !== currentUser?.id && (
              <Fragment>
                <CandidateDetail
                  userImage={currentSelectedUser?.imageSource}
                  userName={currentSelectedUser?.label}
                  userEmail={currentSelectedUser?.email}
                  userDesignation={currentSelectedUser?.designation}
                  userTechStackLevel={currentSelectedUser?.techStackInterviewLevelDtoList}
                  userProject={currentSelectedUser?.projectNameList}
                  dailyInterviewCount={currentSelectedUser?.maxInterviewsDaily}
                  weeklyInterviewCount={currentSelectedUser?.maxInterviewsWeekly}
                  status={currentSelectedUser.status}
                />
                {currentSelectedUser.status === PANEL_STATUS.inReview && (
                  <div className="user-status-badge badge-warning">
                    <ImageComponent
                      src={Images.warningOrange}
                      alt="info-icon"
                      customClass="info-icon"
                    />
                    <Typography customStyle="info-text-warning">
                      {`${PANEL_INREVIEW_MESSAGE}`}
                    </Typography>
                  </div>
                )}
                {currentSelectedUser.status === PANEL_STATUS.archive && (
                  <div className="user-status-badge badge-error">
                    <ImageComponent
                      src={Images.warningIcon}
                      alt="info-icon"
                      customClass="info-icon"
                    />
                    <Typography customStyle="info-text-error">
                      {`${PANEL_ARCHIVED_MESSAGE}`}
                    </Typography>
                  </div>
                )}
              </Fragment>
            )}
            <div className="cal-container">
              <div className="cal-sub-container">
                <Calendar
                  ref={calendarRef}
                  backgroundEvents={scheduleState.temporaryEvents}
                  selectable={true}
                  localizer={localizer}
                  views={memoisedCalendarViews}
                  date={memoisedCurrentDate}
                  events={memoisedEvents}
                  view={memoisedCurrentView}
                  onView={onView}
                  formats={formats.formats}
                  components={calendarComponents}
                  onSelectSlot={handleSlotClick}
                  eventPropGetter={eventPropGetter}
                  onSelectEvent={handleEventClick}
                  onNavigate={handleNavigate}
                  startAccessor={handleStartAccessor}
                  endAccessor={handleEndAccessor}
                  onShowMore={handleShowMore}
                  scrollToTime={memoisedScrollToTime}
                  popup={false}
                  doShowMoreDrillDown={false}
                  className={
                    memoisedCurrentView === Views.MONTH
                      ? 'month-classes'
                      : memoisedCurrentView === Views.DAY
                      ? 'day-classes'
                      : ''
                  }
                  messages={memoisedCalendarMessages}
                  enableAutoScroll
                />
                <Loader loading={eventsLoading} customClass="events-loading-wrapper" />
              </div>
            </div>
            {scheduleState.showPopUp && (
              <Popover
                open={scheduleState.showPopUp}
                anchorReference="anchorPosition"
                anchorPosition={scheduleState.position}
                PaperProps={{
                  variant: 'elevation',
                  className: 'popup'
                }}
                onClose={handlePopupClose}>
                {scheduleState.type === SCHEDULE_EVENTS_TYPE.AVAILABLE && (
                  <SlotAvailablePopup
                    start={scheduleState.start!}
                    end={scheduleState.end!}
                    onCloseClick={handlePopupClose}
                    onDeleteClick={handleDeleteSlotClick(scheduleState.eventDetail)}
                    onEditClick={handleEditSlot}
                  />
                )}
                {scheduleState.type === SCHEDULE_EVENTS_TYPE.BOOKED &&
                  scheduleState.eventDetail && (
                    <SlotCancelPopup
                      slotId={scheduleState.eventDetail.id}
                      end={scheduleState.end}
                      onCloseClick={handlePopupClose}
                      onCancelHandler={handleBookCancelSlot(scheduleState.eventDetail.id)}
                      showMakeSlotAvailableCheckbox={scheduleState.isAdmin}
                      showCancelledBy={scheduleState.isAdmin}
                    />
                  )}
                {scheduleState.type === SCHEDULE_EVENTS_TYPE.EXISTING &&
                  scheduleState.eventDetail && (
                    <GoogleEventPopup
                      eventTitle={scheduleState?.title}
                      start={scheduleState?.start}
                      end={scheduleState?.end}
                      onClose={handlePopupClose}
                    />
                  )}
                {scheduleState.type === SCHEDULE_EVENTS_TYPE.ADD && (
                  <SlotAddPopup
                    start={scheduleState.start!}
                    end={scheduleState.end!}
                    onCancel={handlePopupClose}
                    onClose={handlePopupClose}
                  />
                )}
                {scheduleState.type === SCHEDULE_EVENTS_TYPE.EDIT && scheduleState.eventDetail && (
                  <SlotEditPopup
                    slotId={scheduleState.eventDetail.id}
                    start={scheduleState.start!}
                    end={scheduleState.end!}
                    onCancel={handlePopupClose}
                    onClose={handlePopupClose}
                  />
                )}
                {scheduleState.type === SCHEDULE_EVENTS_TYPE.CANCELLED &&
                  scheduleState.eventDetail && (
                    <SlotCancelledPopup
                      slotId={scheduleState.eventDetail.id}
                      onClose={handlePopupClose}
                    />
                  )}
                {scheduleState.type === SCHEDULE_EVENTS_TYPE.REQUESTED &&
                  scheduleState.eventDetail && (
                    <SlotRequestedPopup
                      slotId={scheduleState.eventDetail.id}
                      onCloseClick={handlePopupClose}
                    />
                  )}
              </Popover>
            )}
            <AddSlotForm
              isDrawerOpen={scheduleState.addSlotDrawer}
              userId={currentSelectedUser?.id.toString()!}
              toggleDrawer={handleAddSlotDrawer}
              onAddSlot={handleAddSlot}
            />
            <SlotRequestDrawer
              open={scheduleState.slotRequestDrawer}
              onClose={handleSlotRequestDrawer}
              onAccept={handleSlotRequestAccept}
            />
            <SlotEventsDrawer
              open={scheduleState.slotDrawer}
              headerDate={scheduleState.slotEventDrawerHeaderDate}
              events={scheduleState.events}
              onClose={handleEventDrawerClose}
              showMakeSlotAvailableCheckbox={scheduleState.isAdmin}
              showCancelledBy={scheduleState.isAdmin}
            />
          </div>
          <Loader loading={pageLoading} />
        </Fragment>
      ) : (
        <ErrorPage page="apiFailure" />
      )}
    </Fragment>
  );
}

export default routeError(Schedule);
