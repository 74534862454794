import React, { useState, useEffect } from 'react';
import './index.style.scss';
import Typography from '../typography';
import ImageComponent from '../image';
import Images from '../../../../assets/images';
interface IAccordionProps {
  title: string | JSX.Element;
  children: React.ReactNode;
  defaultOpen?: boolean;
  open?: boolean;
  onClick?: any;
}
function Accordion(props: IAccordionProps) {
  const { title, children, defaultOpen = false, open = false, onClick } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { arrowUp, arrowDown } = Images;

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const toggleAccordionState = () => {
    setIsOpen(!isOpen);
    onClick && onClick(!isOpen);
  };
  useEffect(() => {
    if (defaultOpen || open) {
      setIsOpen(true);
    }
  }, []);

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      toggleAccordionState();
    }
  };
  const accordionWrapperStyle = isOpen
    ? 'accordion-handler-wrapper accordion-handler--radius'
    : 'accordion-handler-wrapper';
  return (
    <div className="accordion-container">
      <div
        className={accordionWrapperStyle}
        onClick={toggleAccordionState}
        tabIndex={0}
        onKeyDown={handleKeyDown}>
        <Typography customStyle="accordion-title">{title}</Typography>
        <ImageComponent src={isOpen ? arrowUp : arrowDown} customClass="accordion-arrow-image" />
      </div>
      {isOpen && <div className="accordion-details-wrapper">{children}</div>}
    </div>
  );
}
export default Accordion;
