import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import Radio from 'app/core/shared-components/radio-group';
import './index.style.scss';
import { SORT_BY } from 'helpers/constants';

type Props = {
  setCurrentValue: (param: string) => void;
  currentValue: string;
  labelArray: Array<string>;
  onClose: () => void;
  isOpen?: boolean;
};

function SortingOptions({
  setCurrentValue,
  currentValue,
  labelArray,
  onClose,
  isOpen = false
}: Props) {
  const [value, setValue] = useState('');
  function setSortingOption(param: string) {
    setCurrentValue(param);
    setValue(param);
  }

  useEffect(() => {
    if (value !== '') onClose();
  }, [value]);

  return (
    <CSSTransition timeout={300} classNames="alert" unmountOnExit in={isOpen}>
      <div className="sort-container">
        <div className="sort-header">{SORT_BY}</div>
        <div className="options-container">
          <Radio
            labelArray={labelArray}
            setCurrentValue={setSortingOption}
            currentValue={currentValue}
          />
        </div>
      </div>
    </CSSTransition>
  );
}

export default SortingOptions;
