import * as yup from 'yup';
import _, { uniq } from 'lodash';
import { ISelectOptions } from 'app/core/shared-components/customSelect';

const schemaOfFieldRequired = yup
  .object()
  .shape({
    id: yup.string().required(),
    label: yup.string().required()
  })
  .required();

const slotTimeSchema = yup.object().shape({
  startTime: schemaOfFieldRequired,
  endTime: schemaOfFieldRequired
});

export const SlotRequestFormValidation = yup.object({
  slotTime: yup
    .array()
    .of(slotTimeSchema)
    .min(1, 'MIN_ONE_VALUE')
    .nonNullable()
    .test('slotTime', (values) => {
      if (values) {
        for (let i = 0; i < values?.length - 1; i++) {
          if (
            values[i]?.startTime === ('' as unknown as ISelectOptions) &&
            values[i]?.endTime === ('' as unknown as ISelectOptions)
          ) {
            return false;
          }
        }
        for (let i = 0; i < values?.length - 1; i++) {
          for (let j = i + 1; j < values?.length; j++) {
            const startTimeEqual =
              values[i].startTime !== null &&
              values[i].startTime !== ('' as unknown as ISelectOptions) &&
              values[i]?.startTime?.id === values[j]?.startTime?.id;
            const endTimeEqual =
              values[i]?.endTime !== null &&
              values[i].endTime !== ('' as unknown as ISelectOptions) &&
              values[i]?.endTime?.id === values[j]?.endTime?.id;
            if (startTimeEqual && endTimeEqual) {
              return false;
            }
          }
        }
        return true;
      } else return false;
    })
});
