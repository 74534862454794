import React from 'react';
import './index.style.scss';
import Typography from '../../../../core/shared-components/typography';
import ImageComponent from '../../../../core/shared-components/image';

interface ITableNameProps {
  userImage: string | null;
  userName: string;
  userDesignation: string;
}

function TableName(props: ITableNameProps) {
  const { userImage, userName, userDesignation } = props;
  return (
    <div className="table-name-wrapper">
      <ImageComponent
        src={userImage}
        alt={userName}
        fallbackText={userName}
        customClass="table-name-image"
        fallbackClass="table-name-image"
        loading="eager"
      />
      <div className="table-name-content">
        <Typography customStyle="table-user-name">{userName}</Typography>
        <Typography customStyle="table-user-designation">{userDesignation}</Typography>
      </div>
    </div>
  );
}

export default TableName;
