import React, { Fragment, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import './index.style.scss';
import Typography from 'app/core/shared-components/typography';
import Modal from 'app/core/shared-components/modal';
import ProjectCard from 'app/pages/Project/components/projects-card';
import Plus from 'assets/images/plus.svg';
import Sort from 'assets/images/sort-icon.svg';
import Images from 'assets/images';
import ProjectsSearchIcon from 'assets/images/projects-search.svg';
import Search, { IOptionType } from 'app/core/shared-components/search';
import Loader from 'app/core/shared-components/loader';
import ErrorPage from 'app/pages/Error';
import Button from 'app/core/shared-components/button';
import { useAppThunkDispatch, useAppSelector } from 'app/redux/hooks';
import {
  addProjects,
  getProjectData,
  searchSelected,
  clearSearchBarValue,
  resetSearchBarValue,
  handleProjectSearch,
  setIsToastVisible,
  editProjects,
  deleteProjects,
  setCurrentDeletion
} from 'app/redux/slices/projectSlice';
import {
  NAME_A_to_Z,
  NAME_Z_to_A,
  SEARCH,
  MEDIUM,
  OUTLINED,
  PROJECT_HEADING,
  ADD_PROJECT_BUTTON_TEXT,
  PROJECT_LABEL,
  CANCEL_BUTTON_TEXT,
  ADD_BUTTON_TEXT,
  PROJECT_ASC,
  PROJECT_DESC,
  DELETE_BUTTON_TEXT,
  ASCII
} from 'helpers/constants';
import {
  PROJECT_NAME_CONTAINS_SPECIAL_CHAR_ERROR,
  INVALID_PROJECT_NAME_ERROR,
  PROJECT_UNDO_TAG,
  PROJECT_DELETE_MODAL_TEXT,
  PROJECT_DELETE_CONFIRMATION_TEXT,
  PROJECT_NAME_ALREADY_EXIST,
  PROJECT_NAME_PLACEHOLDER
} from 'helpers/messages.constants';
import { PROJECT_CONTAINS_SPECIAL_CHARACTER } from 'helpers/regex.constants';
import { notify } from 'helpers/toastHelper';
import { getProjects } from 'app/redux/slices/sharedSlice';
import SortingOptions from 'app/core/shared-components/sorting-otions';
import { IProjectType } from 'app/services/user-response.types';
import ImageComponent from 'app/core/shared-components/image';
import routeError from 'router/routeError';
import ProjectDrawer from 'app/pages/Project/components/projectDrawer';
import ToastUndo from 'app/core/shared-components/toast-undo';

interface IProject {
  showAddModal: boolean;
  sortStatus: boolean;
  searchSelect: boolean;
  projectData: IProjectType[];
  searchText: string;
  newProjectName: string;
  projectNameError: string;
  isSortingOn: boolean;
  sortingValue: string;
  options: IOptionType[];
  resultNotFound: boolean;
  isSearchedOptionSelected: boolean;
  clearResult: boolean;
  disabled: boolean;
  currentEditable: IProjectType;
  currentName: string;
  isDeleteOn: boolean;
  isToastVisible: boolean;
  isLoading: boolean;
  isDeletionInProcess: boolean;
}

const Project = () => {
  const [projectState, setProjectState] = useState<IProject>({
    showAddModal: false,
    newProjectName: '',
    projectData: [],
    searchSelect: false,
    searchText: '',
    sortStatus: false,
    options: [],
    resultNotFound: false,
    isSearchedOptionSelected: false,
    clearResult: false,
    projectNameError: '',
    isSortingOn: false,
    sortingValue: NAME_A_to_Z,
    disabled: true,
    currentEditable: {} as IProjectType,
    currentName: '',
    isDeleteOn: false,
    isToastVisible: false,
    isLoading: false,
    isDeletionInProcess: false
  });
  const timeOut = useRef<any>(null);
  const abortSignal = useRef<any>(null);

  const dispatch = useAppThunkDispatch();

  const { projects, isProjectLoading } = useAppSelector((state) => state?.shared);
  const { clearResult, isLoading, resetPage, isError } = useAppSelector((state) => state?.project);

  const {
    projectData,
    showAddModal,
    newProjectName,
    isSortingOn,
    sortingValue,
    disabled,
    projectNameError,
    options,
    resultNotFound,
    isSearchedOptionSelected
  } = projectState;

  const handleAddModal = () => {
    setProjectState({
      ...projectState,
      showAddModal: true,
      projectNameError: '',
      newProjectName: ''
    });
  };

  const closeAddModal = () => {
    setProjectState({
      ...projectState,
      showAddModal: false,
      newProjectName: '',
      disabled: true
    });
  };

  const handleChangeAdd = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.target.value;
    setProjectState({
      ...projectState,
      newProjectName: currentValue,
      projectNameError: '',
      disabled: currentValue?.trim() ? false : true
    });
    if (currentValue?.length > 30) {
      setProjectState({
        ...projectState,
        projectNameError: ''
      });
    } else if (PROJECT_CONTAINS_SPECIAL_CHARACTER.test(currentValue)) {
      setProjectState({
        ...projectState,
        projectNameError: PROJECT_NAME_CONTAINS_SPECIAL_CHAR_ERROR,
        newProjectName: currentValue,
        disabled: true
      });
    }
  };

  const addProject = async () => {
    const isProjectExists = projects?.filter(
      (project) =>
        project?.name?.toUpperCase() ===
        newProjectName
          ?.replace(/\s{2,}/g, ' ')
          ?.trim()
          ?.toUpperCase()
    );
    if (!isProjectExists?.length) {
      const data = await dispatch(
        addProjects(newProjectName?.replace(/\s{2,}/g, ' ')?.trim())
      ).unwrap();
      if (!data?.error) {
        dispatch(getProjects());
        closeAddModal();
      } else {
        setProjectState({
          ...projectState,
          projectNameError: data?.error[0]?.message,
          disabled: true
        });
      }
      setProjectState({
        ...projectState,
        disabled: true,
        showAddModal: false
      });
    } else {
      setProjectState({
        ...projectState,
        projectNameError: INVALID_PROJECT_NAME_ERROR,
        disabled: true
      });
    }
  };

  async function handleSearchAction(searchString: string) {
    setProjectState((prevState) => ({
      ...prevState,
      options: []
    }));
    if (resultNotFound) {
      setProjectState((prevState) => ({
        ...prevState,
        resultNotFound: false
      }));
    }
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
    if (!!searchString?.trim()?.length) {
      timeOut.current = setTimeout(async () => {
        if (abortSignal.current) abortSignal.current.abort();
        abortSignal.current = new AbortController();
        const signal = abortSignal.current;
        const { data } = await dispatch(
          handleProjectSearch({ searchString: searchString?.trim(), abortSignal: signal })
        ).unwrap();
        if (!data?.error) {
          const filteredProjects = data?.map((project: IProjectType) => {
            return {
              searchKey: project?.name,
              id: project?.id,
              startIcon: ProjectsSearchIcon,
              singleSearchData: project
            };
          });
          setProjectState((prevState) => ({
            ...prevState,
            options: filteredProjects,
            resultNotFound: false
          }));
        } else {
          setProjectState((prevState) => ({
            ...prevState,
            resultNotFound: true
          }));
        }
      }, 1000);
    } else {
      setProjectState((prevState) => ({ ...prevState, options: [] }));
    }
  }

  async function handleDrawerEdit() {
    setProjectState({ ...projectState, isLoading: true });
    await dispatch(
      editProjects({
        id: projectState?.currentEditable?.id,
        projectName: projectState?.currentEditable?.name?.replace(/\s{2,}/g, ' ')?.trim()
      })
    ).unwrap();
    dispatch(getProjects());
    setProjectState({ ...projectState, isSearchedOptionSelected: false, isLoading: true });
    clearSearch();
  }

  function searchAction(_id: string | number, searchSingleData: IProjectType) {
    setProjectState((prevState) => ({
      ...prevState,
      isSearchedOptionSelected: true,
      clearResult: false,
      currentEditable: searchSingleData,
      currentName: searchSingleData.name
    }));
    dispatch(searchSelected(searchSingleData));
  }

  async function clearSearch() {
    setProjectState((prevState) => ({
      ...prevState,
      isSearchedOptionSelected: false,
      projectData: projects
    }));
    await dispatch(clearSearchBarValue());
    dispatch(resetSearchBarValue());
  }

  function localDelete(project: IProjectType) {
    let temp = projectState.projectData;
    temp = temp.filter((item) => item.id !== project.id);

    setProjectState((prevState) => ({
      ...prevState,
      projectData: temp,
      isDeletionInProcess: true,
      isDeleteOn: false,
      isSearchedOptionSelected: false,
      clearResult: true,
      searchText: '',
      projectNameError: ''
    }));
    dispatch(setCurrentDeletion(project));
    notify(
      false,
      <ToastUndo toastMessage={PROJECT_UNDO_TAG} handleUndo={onUndoDelete} toastId={project.id} />,
      project.id,
      onCloseDialogForToast(project.id)
    );
    dispatch(clearSearchBarValue());
  }

  function onUndoDelete(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const id = event.currentTarget.id;
    toast.dismiss(id);
    toast.update(id, { onClose: () => {}, hideProgressBar: true });
    dispatch(resetSearchBarValue());
    dispatch(getProjects());
  }

  function deleteConfirmationClick() {
    localDelete(projectState.currentEditable);
  }

  function toggleDeleteModal() {
    setProjectState({
      ...projectState,
      isDeleteOn: !projectState.isDeleteOn
    });
  }

  const selectSort = async (val: string) => {
    let temp = [...projectState?.projectData];
    switch (val) {
      case NAME_A_to_Z:
        temp = await dispatch(getProjectData(PROJECT_ASC)).unwrap();
        break;
      case NAME_Z_to_A:
        temp = await dispatch(getProjectData(PROJECT_DESC)).unwrap();
        break;
    }
    setProjectState({ ...projectState, projectData: temp, sortingValue: val, isSortingOn: false });
  };

  const toggleSorting = () => {
    setProjectState({ ...projectState, isSortingOn: !isSortingOn });
  };

  const getProjectDeleteModal = () => {
    return (
      <Modal
        header="Delete Project"
        customStyle="edit-modal"
        isOpen={projectState?.isDeleteOn}
        onClose={toggleDeleteModal}>
        <div className="edit-modal-content">
          <Typography customStyle="delete-modal-para">{PROJECT_DELETE_MODAL_TEXT}</Typography>
          <Typography customStyle="delete-modal-confirm-text">
            {PROJECT_DELETE_CONFIRMATION_TEXT}
          </Typography>
          <div className="edit-modal-footer">
            <Button customStyle="edit-modal--cancel-btn" onClick={toggleDeleteModal}>
              {CANCEL_BUTTON_TEXT}
            </Button>
            <Button
              customStyle="edit-modal--save-btn"
              variant="contained"
              onClick={deleteConfirmationClick}>
              {DELETE_BUTTON_TEXT}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const onCloseDialogForToast = (id: string) => () => {
    dispatch(setIsToastVisible(false));
    dispatch(deleteProjects(id))
      .unwrap()
      .then(() => {
        dispatch(getProjects());
        setProjectState((prevState) => ({ ...prevState, projectData: projects }));
      });
  };

  function onDrawerClose() {
    setProjectState((prevState) => ({
      ...prevState,
      isSearchedOptionSelected: false,
      clearResult: true,
      searchText: '',
      projectNameError: ''
    }));
    clearSearch();
  }

  const handleEdit = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const currentValue = event?.target?.value;

    const isProjectExists = projects?.filter(
      (project) =>
        project?.name?.toUpperCase() ===
        currentValue
          ?.replace(/\s{2,}/g, ' ')
          ?.trim()
          ?.toUpperCase()
    );
    if (isProjectExists?.length) {
      setProjectState({
        ...projectState,
        currentEditable: { ...projectState.currentEditable, name: currentValue },
        projectNameError: PROJECT_NAME_ALREADY_EXIST
      });
      return;
    }

    setProjectState({
      ...projectState,
      currentEditable: { ...projectState.currentEditable, name: currentValue },
      projectNameError: ''
    });
    if (currentValue?.length > 30) {
      setProjectState({
        ...projectState,
        projectNameError: ''
      });
    } else if (PROJECT_CONTAINS_SPECIAL_CHARACTER.test(currentValue)) {
      setProjectState({
        ...projectState,
        projectNameError: PROJECT_NAME_CONTAINS_SPECIAL_CHAR_ERROR,
        currentEditable: { ...projectState.currentEditable, name: currentValue }
      });
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === ASCII.enter) {
      if (!disabled) addProject();
    }
  };
  const blankFunc = () => {};

  const resetSearch = () => {
    if (resetPage) {
      setProjectState((prevState) => ({
        ...prevState,
        isSearchedOptionSelected: false
      }));
      dispatch(resetSearchBarValue());
    }
    setProjectState((prevState) => ({ ...prevState, projectData: projects }));
  };

  useEffect(() => {
    dispatch(getProjects());
  }, []);

  useEffect(() => {
    resetSearch();
  }, [projects]);

  return (
    <Fragment>
      {!!navigator.onLine ? (
        !isError ? (
          <div className="project">
            <div className="project-head-wrapper">
              <div className="project-stack-head">
                <Typography variant="text" customStyle="head-style">
                  {PROJECT_HEADING}
                </Typography>

                <div className="btns">
                  <div className="add-sort">
                    <Button startIcon={Plus} customStyle="add-btn" onClick={handleAddModal}>
                      {ADD_PROJECT_BUTTON_TEXT}
                    </Button>

                    <div className="sort-btn-container">
                      <Button
                        variant={OUTLINED}
                        size={MEDIUM}
                        customStyle={`project-stack-sort-button ${isSortingOn && 'active-btn'}`}
                        onClick={toggleSorting}>
                        <ImageComponent src={Sort} />
                      </Button>
                      {isSortingOn && (
                        <div onClick={toggleSorting} className="transparent-backdrop"></div>
                      )}
                      <div className="project-sort-wrapper">
                        <SortingOptions
                          isOpen={isSortingOn}
                          currentValue={sortingValue}
                          setCurrentValue={selectSort}
                          labelArray={[NAME_A_to_Z, NAME_Z_to_A]}
                          onClose={blankFunc}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-bar">
                <Search
                  onChange={handleSearchAction}
                  setSelectedId={searchAction}
                  options={options}
                  customDropdownIconClass="projects-icon"
                  clearResult={clearResult}
                  resultNotFound={resultNotFound}
                  placeholder={SEARCH}
                />
              </div>
            </div>
            <div className="project-body">
              {projectData?.map((item) => (
                <ProjectCard key={item?.id} projectCard={item} onDeleteThroughCard={localDelete} />
              ))}
            </div>
            <Modal
              header="Add Project"
              customStyle="addModal"
              isOpen={showAddModal}
              onClose={closeAddModal}>
              <div className="addModal-content">
                <div>
                  <label htmlFor="projectName" className="addModal-projectLabel">
                    {PROJECT_LABEL}
                  </label>
                  <br />
                  <input
                    autoFocus
                    type="text"
                    name="projectName"
                    className="addProjectName"
                    placeholder={PROJECT_NAME_PLACEHOLDER}
                    value={newProjectName}
                    autoComplete="off"
                    onChange={handleChangeAdd}
                    onKeyUp={handleKeyUp}
                  />
                  <br />
                  {projectNameError && (
                    <Typography variant="error" customStyle="project-error">
                      {projectNameError}
                    </Typography>
                  )}
                </div>
                <div className="addModal-footer">
                  <Button customStyle="addModal-cancelBtn" onClick={closeAddModal}>
                    {CANCEL_BUTTON_TEXT}
                  </Button>
                  <Button
                    customStyle="add-modal--save-btn"
                    variant="contained"
                    disabled={disabled}
                    onClick={addProject}>
                    {ADD_BUTTON_TEXT}
                  </Button>
                </div>
              </div>
            </Modal>
            <ProjectDrawer
              currentEditable={projectState.currentEditable}
              currentName={projectState.currentName}
              handleDrawerEdit={handleDrawerEdit}
              handleEdit={handleEdit}
              isLoading={isLoading}
              isOpen={isSearchedOptionSelected}
              onDrawerClose={onDrawerClose}
              projectNameError={projectNameError}
              toggleDeleteModal={toggleDeleteModal}
            />
            <Loader loading={isLoading || isProjectLoading} />

            {getProjectDeleteModal()}
          </div>
        ) : (
          <ErrorPage page="apiFailure" />
        )
      ) : (
        <ErrorPage page="noInternet" />
      )}
    </Fragment>
  );
};

export default routeError(Project);
