import React, { Fragment, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'app/core/shared-components/infinite-scroll';
import Error from 'app/pages/Error';
import { useAppDispatch, useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  INotificationData,
  getActivityNotification,
  getGeneralNotification,
  resetNotification,
  setActivityPageNumber,
  setGeneralPageNumber,
  setNotificationData
} from 'app/redux/slices/sharedSlice';
import {
  ACTIVITY_LOG,
  GENERAL,
  MARK_ALL_AS_READ,
  NO_NOTIFICATION_ACTIVITY,
  NO_NOTIFICATION_GENERAL,
  NO_NOTIFICATION_MSG
} from 'helpers/constants';
import NotificationCard from './notification-card';
import { readAllNotification } from 'app/services/common.service';
import { ITab } from 'helpers/types';
import Loader from 'app/core/shared-components/loader';
import './index.styles.scss';
import Images from 'assets/images';
import ImageComponent from 'app/core/shared-components/image';

interface INotificationDrawerState {
  activeTab: ITab;
}

const NotificationDrawer = () => {
  const [notificationState, setNotificationState] = useState<INotificationDrawerState>({
    activeTab: 'general'
  });
  const [noNotificationScreen, setNoNotificationScreen] = useState(true);

  const thunkDispatch = useAppThunkDispatch();
  const dispatch = useAppDispatch();

  const rootRef = useRef<HTMLDivElement>(null);

  const {
    activityNotificationPageNumber,
    generalNotificationPageNumber,
    notificationData,
    isNotificationError,
    isNotificationLoading
  } = useAppSelector((state) => state?.shared);

  const { general, activity } = notificationData;
  const { activeTab } = notificationState;

  const isGeneralTabActive = activeTab === 'general';

  const activeNoticationsData = isGeneralTabActive ? general : activity;

  const activeHasNext = isGeneralTabActive ? general?.hasNext : activity?.hasNext;
  const { noNotifications } = Images;

  const showLoader =
    isNotificationLoading &&
    (isGeneralTabActive
      ? generalNotificationPageNumber === 0
      : activityNotificationPageNumber === 0);

  const getNotificationData = async (value: boolean) => {
    setNoNotificationScreen(value);
    const res = await thunkDispatch(
      isGeneralTabActive
        ? getGeneralNotification(generalNotificationPageNumber)
        : getActivityNotification(activityNotificationPageNumber)
    );
    if (res?.payload?.data?.notificationList?.length > 0) {
      setNoNotificationScreen(false);
    }
  };

  const handleTabClick = (tab: ITab) => () => {
    setNotificationState((prevState) => ({ ...prevState, activeTab: tab }));
  };

  const updateNotificationData = (notificationType: ITab, notificationId?: string) => {
    const updatedData = {
      ...notificationData,
      [notificationType]: {
        ...activeNoticationsData,
        notificationList: activeNoticationsData?.notificationList.map((notification) =>
          (!!notificationId?.length && notification?.notificationId === notificationId) ||
          !!!notificationId?.length
            ? { ...notification, isRead: true }
            : notification
        )
      }
    };
    dispatch(setNotificationData(updatedData));
  };

  const handleMarkAllAsRead = async () => {
    const data = await readAllNotification(
      isGeneralTabActive ? 'GENERAL_NOTIFICATION' : 'ACTIVITY_LOG'
    );

    if (!data?.error) {
      updateNotificationData(isGeneralTabActive ? 'general' : 'activity');
    }
  };

  const onPageChange = () => {
    dispatch(
      isGeneralTabActive
        ? setGeneralPageNumber(generalNotificationPageNumber + 1)
        : setActivityPageNumber(activityNotificationPageNumber + 1)
    );
  };

  useEffect(() => {
    if ((isGeneralTabActive ? generalNotificationPageNumber : activityNotificationPageNumber) > 0) {
      getNotificationData(false);
    }
  }, [activityNotificationPageNumber, generalNotificationPageNumber]);

  useEffect(() => {
    if (
      (isGeneralTabActive ? generalNotificationPageNumber : activityNotificationPageNumber) === 0
    ) {
      getNotificationData(true);
    }
  }, [activeTab]);

  useEffect(() => {
    return () => {
      dispatch(resetNotification());
    };
  }, []);

  return (
    <Fragment>
      <div className="notifications-tabs-container">
        <div className="tabs">
          <div
            className={`general ${isGeneralTabActive ? 'active-tab' : ''}`}
            onClick={handleTabClick('general')}>
            <span>{GENERAL}</span>
          </div>
          <div
            className={`activity ${isGeneralTabActive ? '' : 'active-tab'}`}
            onClick={handleTabClick('activity')}>
            <span>{ACTIVITY_LOG}</span>
          </div>
        </div>
        <div className="mark-as-read" onClick={handleMarkAllAsRead}>
          <span>{MARK_ALL_AS_READ}</span>
        </div>
      </div>
      <div className="divider"></div>
      {isNotificationError ? (
        <Error page="apiFailure" />
      ) : showLoader ? (
        <Loader loading={isNotificationLoading} customClass="notification-loader" />
      ) : noNotificationScreen ? (
        <div className="no-notifications">
          <span>
            <ImageComponent src={noNotifications} />
          </span>
          <span>
            {isGeneralTabActive ? `${NO_NOTIFICATION_GENERAL}` : `${NO_NOTIFICATION_ACTIVITY}`}
          </span>
          <span>{NO_NOTIFICATION_MSG}</span>
        </div>
      ) : (
        <Fragment>
          <div ref={rootRef} className="notifications-card-container">
            <InfiniteScroll
              handlePageChange={onPageChange}
              nextPage={activeHasNext}
              rootRef={rootRef?.current!}>
              {activeNoticationsData?.notificationList?.length &&
                activeNoticationsData?.notificationList?.map((notificationData, index) => {
                  return (
                    <NotificationCard
                      data={notificationData}
                      key={index}
                      activeTab={activeTab}
                      updateNotificationData={updateNotificationData}
                    />
                  );
                })}
            </InfiniteScroll>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default NotificationDrawer;
