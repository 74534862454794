import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import panelSlice from '../slices/panelSlice';
import thunk from 'redux-thunk';
import loginSlice from '../slices/loginSlice';
import userSlice from '../slices/userSlice';
import talentSlice from '../slices/talentSlice';
import adminDashboardSlice from '../slices/adminDashboardSlice';
import techStackSlice from '../slices/techStackSlice';
import settingsSlice from '../slices/settingSlice';
import sharedSlice from '../slices/sharedSlice';
import levelOfInterviewSlice from '../slices/levelOfInterviewSlice';
import projectSlice from '../slices/projectSlice';
import onboardUserSlice from '../slices/onboardUserSlice';
import slotsSlice from '../slices/slotsSlice';
import scheduleSlice from '../slices/scheduleSlice';

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ['user', 'shared']
};

const rootReducer = combineReducers({
  panel: panelSlice,
  login: loginSlice,
  user: userSlice,
  shared: sharedSlice,
  settings: settingsSlice,
  adminDashboard: adminDashboardSlice,
  techStack: techStackSlice,
  project: projectSlice,
  talentAcquisition: talentSlice,
  onboardUser: onboardUserSlice,
  levelOfInterview: levelOfInterviewSlice,
  slots: slotsSlice,
  schedule: scheduleSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

export const persistedStore = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
