import React, { Fragment, useEffect, useState } from 'react';
import './index.style.scss';
import Typography from 'app/core/shared-components/typography';
import Images from 'assets/images';
import ImageComponent from 'app/core/shared-components/image';
import Button from 'app/core/shared-components/button';
import Select, { ISelectValue } from 'app/core/shared-components/select';
import moment, { Moment } from 'moment';
import { useAppThunkDispatch } from 'app/redux/hooks';
import { addPanelSlot } from 'app/redux/slices/scheduleSlice';
import { formatTime, generateTimeSlots } from 'helpers/dateHelper';
import { notify } from 'helpers/toastHelper';
import { v4 as uuid } from 'uuid';
import {
  PAST_START_TIME,
  SLOT_ADDED_TOAST_MESSAGE,
  SLOT_OVERLAP_TOAST_MESSAGE
} from 'helpers/messages.constants';
import CustomSelect from 'app/core/shared-components/customSelect';
import { ISlot, ISlots } from 'app/services/schedule.service';
import { timeStamps } from 'helpers/timeStamp.constants';

interface ISlotEditPopupProps {
  start: Date;
  end: Date;
  onAccept?: (start: Date, end: Date) => void;
  onCancel?: () => void;
  onClose?: () => void;
}
interface ISlotAddStates {
  date: Moment;
  selectedStartDate: ISelectValue;
  selectedEndDate: ISelectValue;
  startTimeOption: ISelectValue[];
  endTimeOption: ISelectValue[];
  startMoment: Moment[];
  endMoment: Moment[];
  showWarning: boolean;
}

function SlotAddPopup(props: ISlotEditPopupProps) {
  const { onCancel, start, end } = props;
  const [slotState, setSlotState] = useState<ISlotAddStates>(() => ({
    date: moment(start),
    selectedStartDate: {
      id: 0,
      label: moment(start).format(timeStamps.HH_MM_A)
    },
    selectedEndDate: { id: 2, label: moment(end).add(30, 'minute').format(timeStamps.HH_MM_A) },
    startTimeOption: [],
    endTimeOption: [],
    startMoment: [],
    endMoment: [],
    showWarning: moment(start).isSameOrBefore(moment())
  }));
  const dispatch = useAppThunkDispatch();
  const { selectedStartDate, selectedEndDate, startTimeOption, endTimeOption, date, showWarning } =
    slotState;

  const handleSave = async () => {
    const { selectedStartDate, selectedEndDate, startMoment, endMoment } = slotState;
    const currentTime = moment();
    const startDateTime = startMoment.find(
      (_undefined, index) => index.toString() === selectedStartDate.id.toString()
    );
    const endDateTime = endMoment.find(
      (_undefined, index) => index.toString() === selectedEndDate.id.toString()
    );
    if (
      startDateTime &&
      endDateTime &&
      endDateTime.clone().diff(startDateTime, 'minutes', true) >= 30 &&
      startDateTime.isAfter(currentTime)
    ) {
      const slot: ISlot[] = [];
      slot.push({
        from: formatTime(startDateTime.format(timeStamps.HH_MM_A), startDateTime.toDate()),
        to: formatTime(endDateTime.format(timeStamps.HH_MM_A), endDateTime.toDate())
      });
      const data = {
        date: startDateTime?.format(timeStamps.YYYY_MM_DD),
        slot: slot
      };
      props.onClose && props.onClose();
      const response = await dispatch(addPanelSlot([data]));
      if (response.payload.data) {
        notify(true, <Fragment>{SLOT_ADDED_TOAST_MESSAGE}</Fragment>, uuid());
      } else if (response.payload.error) {
        const { error } = response.payload;
        if (error[0]?.error[0]?.code === 400)
          notify(
            true,
            <Fragment>{SLOT_OVERLAP_TOAST_MESSAGE}</Fragment>,
            uuid(),
            () => {},
            'error'
          );
      }
    } else {
      setSlotState((prevState) => ({ ...prevState, showWarning: true }));
    }
  };

  const handleStartChange = (param: ISelectValue | ISelectValue[] | null) => {
    const { selectedEndDate, startMoment, endMoment } = slotState;
    if (param && !Array.isArray(param)) {
      const startTime = startMoment.find(
        (_undefined, index) => index.toString() === param.id.toString()
      );
      const endTime = endMoment.find(
        (_undefined, index) => index.toString() === selectedEndDate.id.toString()
      );
      if (startTime && endTime && endTime.diff(startTime, 'minutes', true) >= 30) {
        return setSlotState((prevState) => ({
          ...prevState,
          showWarning: false,
          selectedStartDate: param
        }));
      } else if (startTime) {
        const ISMEndTime = moment(startTime).set({ hour: 21, minute: 0 });
        const data = startTime.clone().add(1, 'hours');
        if (data.isSameOrBefore(ISMEndTime)) {
          const endIndex = endMoment.findIndex((slot) => slot.isSame(data, 'minutes'));
          setSlotState((prevState) => ({
            ...prevState,
            showWarning: false,
            selectedStartDate: param,
            selectedEndDate: { id: endIndex, label: data.format(timeStamps.HH_MM_A) }
          }));
          return;
        } else if (data.subtract(30, 'minutes').isSameOrBefore(ISMEndTime)) {
          const endIndex = endMoment.findIndex((slot) => slot.isSame(data, 'minutes'));
          setSlotState((prevState) => ({
            ...prevState,
            showWarning: false,
            selectedStartDate: param,
            selectedEndDate: { id: endIndex, label: data.format(timeStamps.HH_MM_A) }
          }));
          return;
        } else {
          data.subtract(15, 'minutes');
          const endIndex = endMoment.findIndex((slot) => slot.isSame(data, 'minutes'));
          setSlotState((prevState) => ({
            ...prevState,
            showWarning: false,
            selectedStartDate: param,
            selectedEndDate: { id: endIndex, label: data.format(timeStamps.HH_MM_A) }
          }));
        }
      }
    }
  };

  const handleEndChange = (param: ISelectValue | ISelectValue[] | null) => {
    const { selectedStartDate, startMoment, endMoment } = slotState;
    if (param && !Array.isArray(param)) {
      const startTime = startMoment.find(
        (_undefined, index) => index.toString() === selectedStartDate.id.toString()
      );
      const endTime = endMoment.find(
        (_undefined, index) => index.toString() === param.id.toString()
      );
      if (startTime && endTime && endTime.diff(startTime, 'minutes', true) >= 30) {
        return setSlotState((prevState) => ({
          ...prevState,
          showWarning: false,
          selectedEndDate: param
        }));
      } else if (endTime) {
        const data = endTime.clone().subtract(1, 'hours');
        const ISMStartTime = moment(startTime).set({ hour: 6, minute: 0 });
        if (data.isSameOrAfter(ISMStartTime)) {
          const endIndex = startMoment.findIndex((slot) => slot.isSame(data, 'minutes'));
          setSlotState((prevState) => ({
            ...prevState,
            showWarning: false,
            selectedEndDate: param,
            selectedStartDate: { id: endIndex, label: data.format(timeStamps.HH_MM_A) }
          }));
          return;
        } else if (data.add(30, 'minutes').isSameOrAfter(ISMStartTime)) {
          const endIndex = startMoment.findIndex((slot) => slot.isSame(data, 'minutes'));
          setSlotState((prevState) => ({
            ...prevState,
            showWarning: false,
            selectedEndDate: param,
            selectedStartDate: { id: endIndex, label: data.format(timeStamps.HH_MM_A) }
          }));
          return;
        } else {
          data.add(15, 'minutes').isSameOrAfter(ISMStartTime);
          const endIndex = startMoment.findIndex((slot) => slot.isSame(data, 'minutes'));
          setSlotState((prevState) => ({
            ...prevState,
            showWarning: false,
            selectedEndDate: param,
            selectedStartDate: { id: endIndex, label: data.format(timeStamps.HH_MM_A) }
          }));
          return;
        }
      }
    }
  };

  const updateSlotTime = () => {
    const startOption = generateTimeSlots(start, true);
    startOption.pop();
    const endOption = generateTimeSlots(end);
    const startTimeOption = startOption.map((slot, index) => ({
      id: index,
      label: slot.format(timeStamps.HH_MM_A)
    }));
    const endTimeOption = endOption.map((slot, index) => ({
      id: index,
      label: slot.format(timeStamps.HH_MM_A)
    }));
    setSlotState((prevState) => ({
      ...prevState,
      startTimeOption,
      endTimeOption,
      startMoment: startOption,
      endMoment: endOption
    }));
  };

  useEffect(() => {
    updateSlotTime();
  }, []);

  return (
    <div className="slot-add-popup-wrapper">
      <div className="slot-edit-header">
        <Typography customStyle="slot-edit-label">Add Slot</Typography>
        <div className="slot-edit-icon-wrapper" onClick={props.onClose}>
          <ImageComponent src={Images.close} />
        </div>
      </div>
      <div className="slot-edit-content-wrapper">
        <div className="slot-edit-select-content">
          <CustomSelect
            selectedOptions={selectedStartDate}
            dropdownOptions={startTimeOption}
            onChange={handleStartChange}
            restrictAlphabetOnSearch
            showFullWidth
          />
          <Typography>-</Typography>
          <CustomSelect
            selectedOptions={selectedEndDate}
            dropdownOptions={endTimeOption}
            onChange={handleEndChange}
            restrictAlphabetOnSearch
            showFullWidth
          />
        </div>
        <Typography customStyle="slot-date-label">
          {date.format(timeStamps.DAY_AND_DATE)}
        </Typography>
        {showWarning && (
          <div className="slot-warning-message-wrapper">
            <ImageComponent src={Images.warningOrange} alt="Warning" />
            <Typography customStyle="slot-warning-message">{PAST_START_TIME}</Typography>
          </div>
        )}
      </div>
      <div className="slot-action-wrapper">
        <Button variant="outlined" customStyle="slot-cancel-btn" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          customStyle="slot-save-btn"
          onClick={handleSave}
          disabled={showWarning}>
          Add
        </Button>
      </div>
    </div>
  );
}

export default SlotAddPopup;
