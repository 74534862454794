import Images from '../../../../../assets/images';
import React, { useEffect, useState } from 'react';
import ImageComponent from '../../../../core/shared-components/image';
import './index.style.scss';
import Typography from '../../../../core/shared-components/typography';
import { IRewardsUser } from '../../../../redux/slices/adminDashboardSlice';
import { slice } from 'lodash';

interface IRewardsDataProps {
  userData: IRewardsUser[];
  position: boolean;
}

interface IRewardsDataStates {
  names: string[];
}

const RewardsData = (props: any) => {
  const { userData, position } = props;

  const [rewardsDataStates, setRewardsDataStates] = useState<IRewardsDataStates>({
    names: []
  });

  useEffect(() => {
    const names = userData
      .map((data: IRewardsUser) => {
        return `${data.firstName} ${data.lastName}`;
      })
      .join(', ');
    setRewardsDataStates((prevState) => ({ ...prevState, names: names }));
  }, [userData]);

  const { crownIcon, firstBadge, secondBadge, thirdBadge, avatar } = Images;

  const badge = position === 1 ? firstBadge : position === 2 ? secondBadge : thirdBadge;
  const gradient = position === 1 ? 'first' : position === 2 ? 'second' : 'third';
  const imagesToShow = position === 1 ? 3 : 2;

  return (
    <div className={`rewards-data  ${gradient}`}>
      <div className="user-images">
        {position === 1 && <ImageComponent className="crown" src={crownIcon} />}
        <div className="stacked-images">
          {userData?.slice(0, imagesToShow)?.map((user: IRewardsUser, index: number) => (
            <ImageComponent
              customClass={
                index === imagesToShow - 1 && userData?.length > imagesToShow
                  ? `profile-image ${gradient} overlay`
                  : `profile-image ${gradient}`
              }
              src={user?.profileImage}
              fallbackText={user?.firstName}
              key={index}
              fallbackClass={
                index === imagesToShow - 1 && userData?.length > imagesToShow
                  ? `profile-image ${gradient} overlay`
                  : `profile-image ${gradient}`
              }
            />
          ))}
          {userData?.length > imagesToShow && (
            <div className="count">{`+${userData.length - imagesToShow + 1}`}</div>
          )}
        </div>
        <ImageComponent src={badge} className="position-badge" />
      </div>
      <div className={`user-description ${gradient}`}>
        <Typography variant="headline-16" customStyle="user-name">
          {rewardsDataStates?.names}
          <div className="tooltip-wrapper">
            {userData?.map((data: IRewardsUser, index: number) => {
              return <div key={index}>{`${data.firstName} ${data.lastName}`}</div>;
            })}
            <div className="tooltip-arrow"></div>
          </div>
        </Typography>
        <Typography variant="supporting-text" customStyle="points">
          {`${userData[0]?.points} points`}
        </Typography>
        {userData?.length === 1 && (
          <Typography variant="supporting-text">{`${userData[0]?.designation}`}</Typography>
        )}
      </div>
    </div>
  );
};
export default React.memo(RewardsData);
