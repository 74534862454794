import React, { Component, ReactNode, useEffect, useState } from 'react';
import DrawerAnimationWrapper from 'app/core/shared-components/drawer-animation-wrapper';
import ProfileDetails from 'app/core/shared-components/profile-details';
import ImageComponent from 'app/core/shared-components/image';
import Images from 'assets/images';
import { handleTabEventwithoutValue } from 'helpers/utils';
import './index.style.scss';

interface IDrawerProps {
  children: React.ReactNode;
  onClose: React.MouseEventHandler<HTMLElement>;
  customStyle?: string;
  isOpen: boolean;
  headerProps?: JSX.Element;
  headerStyles?: string;
  bodyStyles?: string;
  backdropNotRequired?: boolean;
}

function Drawer(props: IDrawerProps) {
  function handleDrawerClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation();
  }
  const {
    children,
    onClose,
    customStyle,
    headerProps,
    headerStyles,
    bodyStyles,
    backdropNotRequired
  } = props;
  const drawerBackdropStyle = 'drawer-backdrop active';
  const showDivider = headerProps?.type == ProfileDetails;
  const { close } = Images;

  return (
    <div className={drawerBackdropStyle} onClick={backdropNotRequired ? undefined : onClose}>
      <div className="drawer" onClick={handleDrawerClick}>
        <div className="drawer-header">
          <div className="drawer-header-content">
            <div className={headerStyles}>{headerProps}</div>
          </div>
          <div
            className="close-icon"
            onClick={onClose}
            tabIndex={0}
            onKeyDown={handleTabEventwithoutValue(onClose)}>
            <ImageComponent src={close} />
          </div>
        </div>
        {showDivider && <div className="divider"></div>}
        <div className="drawer-body">
          <div className={bodyStyles}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default DrawerAnimationWrapper(Drawer);
