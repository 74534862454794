import React, { Children, SyntheticEvent } from 'react';
import Modal from 'app/core/shared-components/modal';
import Button from 'app/core/shared-components/button';
import './index.style.scss';
import { PRIMARY, SECONDARY } from 'helpers/constants';
interface props {
  title: string;
  description?: string;
  onConfirm?: () => void;
  confirmText?: string;
  cancelText?: string;
  onClose: () => void;
  buttonGroupVariant?: 'primary' | 'secondary';
  children?: React.ReactNode;
  isOpen?: boolean;
  customStyle?: string;
  customBackDropStyle?: string;
}

function ConfirmationModal({
  title,
  description,
  onConfirm,
  confirmText = 'Save',
  cancelText = 'Cancel',
  buttonGroupVariant = PRIMARY,
  onClose,
  children,
  isOpen,
  customStyle,
  customBackDropStyle
}: props) {
  const handleModalClose = (event: SyntheticEvent) => {
    event.stopPropagation();
    onClose();
  };
  return (
    <Modal
      customStyle={customStyle ? `${customStyle} cm-delete-modal` : `cm-delete-modal`}
      customBackdrop={`cm-delete-modal-backdrop ${customBackDropStyle ? customBackDropStyle : ''}`}
      onClose={handleModalClose}
      header={title}
      isOpen={isOpen}>
      {description && <div className="cm-delete-notice-text">{description}</div>}
      {children}
      <div className="cm-delete-btn-container">
        <div className="cm-button">
          <Button
            variant="outlined"
            size="medium"
            onClick={onClose}
            customStyle="cm-delete-cancel-button">
            {cancelText}
          </Button>
        </div>
        {buttonGroupVariant === PRIMARY && (
          <div onClick={onConfirm} className="cm-button">
            <Button size="medium" customStyle="cm-delete-button" variant="contained">
              {confirmText}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
