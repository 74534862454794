import React, { Fragment, useEffect, useState } from 'react';
import './index.style.scss';
import Loader from 'app/core/shared-components/loader';
import { useNavigate } from 'react-router';
import Table from 'app/core/shared-components/table';
import { debounce } from 'lodash';
import ImageComponent from 'app/core/shared-components/image';
import InputBox from 'app/core/shared-components/input-box';
import Button from 'app/core/shared-components/button';
import TabComponent from 'app/core/shared-components/tab';
import {
  NO_PANELLISTS_FOUND,
  NO_PANELLISTS_FOUND_DESC,
  NO_RECRUITERS_FOUND,
  NO_RECRUITERS_FOUND_DESC
} from 'helpers/messages.constants';
import { BTN_LABEL_ASSIGN_PANELS, TIME_INTERVAL } from 'helpers/constants';
import { useAppThunkDispatch } from 'app/redux/hooks';
import {
  getPanelsSearchUserListTechStacks,
  getPanelsUserListTechStacks,
  getRecruiterSearchUserListTechStacks,
  getRecruiterUserListTechStacks
} from 'app/redux/slices/techStackSlice';
import Images from 'assets/images';
import { PANEL_ROUTE } from 'helpers/apiRoutes';
import { NOT_ALPHABET_REGEX } from 'helpers/regex.constants';
import {
  columns,
  columnsRecruiter,
  levelColumns,
  LEVELTYPE,
  PANELTABNAME,
  projectColumns,
  PROJECTTYPE,
  RECRUITERTABNAME
} from 'app/core/shared-components/users-list/utils/columns';
import { getUsersSearchListProjects } from 'app/redux/slices/projectSlice';
import { userListLevelSearch } from 'app/redux/slices/levelOfInterviewSlice';

interface IUsersTabState {
  selectedTabIndex?: number;
  searchText?: string;
}

interface UserListProps {
  id?: string;
  title?: string;
  totalCount?: number;
  onClose?: () => void;
  listData?: [];
  isLoading?: boolean;
  isTabView?: boolean;
  panelData?: [];
  panelCount?: number;
  recruiterData?: [];
  recruiterCount?: number;
  listType?: string;
  handleGetList?: Function;
  isPanelListLoading?: boolean;
  isRecruiterListLoading?: boolean;
}

const UserList = (props: UserListProps) => {
  const [userTabData, setUserTabData] = useState<IUsersTabState>({
    selectedTabIndex: 0,
    searchText: ''
  });
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const { selectedTabIndex } = userTabData;
  const { close, noResult } = Images;
  const {
    id,
    title,
    totalCount,
    onClose,
    listData,
    isLoading = false,
    isTabView = false,
    panelCount = 0,
    panelData,
    recruiterCount = 0,
    recruiterData,
    listType,
    handleGetList,
    isPanelListLoading = false,
    isRecruiterListLoading = false
  } = props;
  const [loadingState, setLoadingState] = useState<boolean>();
  const userTabs = [
    {
      name: PANELTABNAME,
      count: panelCount
    },
    { name: RECRUITERTABNAME, count: recruiterCount }
  ];

  const debounceCondition = () =>
    debounce(async (text: string, tabIndex: number) => {
      const searchString = text?.toString().trim().toLowerCase();
      if (searchString?.length) {
        const searchUser =
          id &&
          (listType === PROJECTTYPE.toLowerCase()
            ? getUsersSearchListProjects({
                id: id,
                searchText: searchString?.trim()
              })
            : listType === LEVELTYPE.toLowerCase()
            ? userListLevelSearch({
                id: id,
                searchText: searchString?.trim()
              })
            : tabIndex === 0
            ? getPanelsSearchUserListTechStacks({
                id: id,
                searchText: searchString?.trim()
              })
            : getRecruiterSearchUserListTechStacks({
                id: id,
                searchText: searchString?.trim()
              }));
        searchUser && (await dispatch(searchUser).unwrap());
      } else {
        handleClearText();
      }
    }, TIME_INTERVAL);

  const debounceSearch = React.useRef(debounceCondition()).current;

  const handleSearch = (text: string | number) => {
    if (!NOT_ALPHABET_REGEX.test(text.toString())) {
      setUserTabData((prevState) => ({
        ...prevState,
        searchText: text.toString()
      }));
      debounceSearch(text.toString().toLowerCase(), Number(selectedTabIndex) || 0);
    }
  };

  const handleClearText = () => {
    setUserTabData((prev) => ({
      ...prev,
      searchText: ''
    }));
    handleGetList && handleGetList(id);
  };
  const handleNavigate = (navigationLink: string, statusTab?: number) => () => {
    navigate(navigationLink, {
      state: { tab: statusTab }
    });
  };

  const handleTabSelect = (index: number) => {
    index === 0
      ? id && dispatch(getPanelsUserListTechStacks(id))
      : id && dispatch(getRecruiterUserListTechStacks(id));
    setUserTabData((prevState) => ({
      ...prevState,
      selectedTabIndex: index,
      searchText: ''
    }));
  };

  const getTabBody = () => {
    const data =
      selectedTabIndex === 0 ? (
        panelData?.length ? (
          <Table
            columnsDefinition={columns}
            rowsDefinition={panelData}
            customEmptyScreen={true}
            isCheckEnabled={false}
          />
        ) : (
          <div className="noUsersScreen">
            <div className="imageNoUsers">
              <ImageComponent src={noResult} />
            </div>
            <div className="noUsersTitle">{NO_PANELLISTS_FOUND}</div>
            <div className="noUsersSubHeading">{`${NO_PANELLISTS_FOUND_DESC} Tech Stack`}</div>
            <div>
              <Button
                size="medium"
                customStyle="add-btn panellist-btn"
                variant="contained"
                onClick={handleNavigate(PANEL_ROUTE, 1)}>
                {BTN_LABEL_ASSIGN_PANELS}
              </Button>
            </div>
          </div>
        )
      ) : !!recruiterData?.length ? (
        <Table
          columnsDefinition={columnsRecruiter}
          rowsDefinition={recruiterData}
          customEmptyScreen={true}
          isCheckEnabled={false}
        />
      ) : (
        <div className="noUsersScreen">
          <div className="imageNoUsers">
            <ImageComponent src={noResult} />
          </div>
          <div className="noUsersTitle">{NO_RECRUITERS_FOUND}</div>
          <div className="noUsersSubHeading">{`${NO_RECRUITERS_FOUND_DESC} Tech Stack`}</div>
        </div>
      );
    return data;
  };

  useEffect(() => {
    handleGetList && handleGetList(id);
  }, [id]);

  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, []);

  useEffect(() => {
    setLoadingState(!isLoading && !isPanelListLoading && !isRecruiterListLoading);
  }, [isLoading, isRecruiterListLoading, isPanelListLoading]);

  return (
    <div className="outerWrapper">
      <Loader
        loading={
          !isTabView
            ? isLoading
            : selectedTabIndex === 0
            ? isPanelListLoading
            : isRecruiterListLoading
        }
        customClass="modalLoader"
      />
      {loadingState && (
        <Fragment>
          <div className="heading-section">
            <div className="list-heading">
              {title}
              <span className="total-count">{`(${totalCount})`}</span>
            </div>
            <div className="search-box">
              <InputBox
                value={userTabData?.searchText}
                startIcon={Images?.search}
                customClass="user-team-header-search"
                endIcon={userTabData?.searchText?.length ? Images?.close : ''}
                placeholder="Search"
                handleChange={handleSearch}
                onEndIconClick={handleClearText}
              />
              <ImageComponent src={close} onClick={onClose} className="crossIcon" />
            </div>
          </div>
          {isTabView && userTabs.length ? (
            <TabComponent
              tabs={userTabs?.map((levels) => {
                return {
                  title: (
                    <span>
                      {levels?.name} {`(${levels?.count})`}
                    </span>
                  ),
                  tabData: (
                    <div className="user-details">
                      <div className="slot-loading-wrapper">
                        <Loader
                          loading={
                            selectedTabIndex === 0 ? isPanelListLoading : isRecruiterListLoading
                          }
                          customClass="dashboard-loader"
                        />
                      </div>
                      <Fragment>
                        {selectedTabIndex === 0
                          ? !isPanelListLoading && getTabBody()
                          : !isRecruiterListLoading && getTabBody()}
                      </Fragment>
                    </div>
                  )
                };
              })}
              height={200}
              onSelect={handleTabSelect}
              selectedIndex={selectedTabIndex as number}
              showViewAll={false}></TabComponent>
          ) : !!listData?.length ? (
            <div>
              <div>{!!listData?.length && <div className="table-separator"></div>}</div>
              <div className="tableSection">
                <Table
                  columnsDefinition={
                    listType === PROJECTTYPE.toLowerCase() ? projectColumns : levelColumns
                  }
                  rowsDefinition={listData}
                  customEmptyScreen={true}
                  isCheckEnabled={false}
                />
              </div>
            </div>
          ) : (
            <div className="noUsersScreen">
              <div className="imageNoUsers">
                <ImageComponent src={noResult} />
              </div>
              <div className="noUsersTitle">{NO_PANELLISTS_FOUND}</div>
              <div className="noUsersSubHeading">{`${NO_PANELLISTS_FOUND_DESC} ${
                listType === PROJECTTYPE.toLowerCase() ? PROJECTTYPE : LEVELTYPE
              }`}</div>
              <div>
                <Button
                  size="medium"
                  customStyle="add-btn panellist-btn"
                  variant="contained"
                  onClick={handleNavigate(PANEL_ROUTE, 1)}>
                  {BTN_LABEL_ASSIGN_PANELS}
                </Button>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};
export default UserList;
