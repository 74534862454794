import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import Button from 'app/core/shared-components/button';
import Modal from 'app/core/shared-components/modal';
import ToastUndo from 'app/core/shared-components/toast-undo';
import ImageComponent from 'app/core/shared-components/image';
import ComponentLoader from 'app/pages/Dashboard/components/loader-component';
import ProfileDetails from 'app/core/shared-components/profile-details';
import TATechStacks from 'app/pages/Dashboard/components/home-drawer/techStacks';
import ConfirmationModal from 'app/core/shared-components/confirmation-modal';
import Drawer from 'app/core/shared-components/drawer';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  clearSearchBarValue,
  getEmployeeCount,
  toggleDrawerVisibility
} from 'app/redux/slices/adminDashboardSlice';
import { deleteRecruiterService } from 'app/services/talent-acquisition.service';
import Images from 'assets/images';
import {
  CANCEL_BUTTON_TEXT,
  DELETE_ACTION,
  ROLE_ADMIN,
  ROLE_LABEL,
  TALENT_ACQUISITION,
  TS_LABEL,
  ZERO_EXPERIENCE
} from 'helpers/constants';
import { notify } from 'helpers/toastHelper';
import {
  CANNOT_DELETE_ACTIVE_BOOKINGS,
  DELETE_TA,
  DELETE_TA_DESCRIPTION,
  TA_UNABLE_DELETE_TITLE
} from 'helpers/messages.constants';
import { handleTabEventwithoutValue } from 'helpers/utils';
interface IHomeDrawerStates {
  isModalOpen: boolean;
  modalAction: string;
  modalTitle: string;
  modalDescription: string;
  isUndoToastVisible: boolean;
}

interface IHomeDrawerProps {
  isDrawerOpen?: boolean;
}

function HomeDrawer(props: IHomeDrawerProps) {
  const dispatch = useAppThunkDispatch();
  const [homeDrawerStates, setHomeDrawerStates] = useState<IHomeDrawerStates>({
    isModalOpen: false,
    modalAction: '',
    modalTitle: '',
    modalDescription: '',
    isUndoToastVisible: false
  });
  const { isModalOpen, modalTitle, modalDescription } = homeDrawerStates;

  const [noDeleteModal, showNoDeleteModal] = useState<boolean>(false);
  const [deleteUserName, setDeleteUserName] = useState<string>('');

  const { userDataById, isDrawerOpen, isUserDataLoading } = useAppSelector(
    (state) => state.adminDashboard
  );
  const { userId: id, role } = useAppSelector((state) => state.user.userData);
  const {
    profileImageUrl,
    firstName,
    lastName,
    designation,
    emailId,
    mobileNo,
    techStackInterviewLevelDtos,
    roles,
    totalExperience,
    userId
  } = userDataById;
  const { delete: Delete } = Images;

  function toggleDrawer() {
    dispatch(toggleDrawerVisibility());
    dispatch(clearSearchBarValue());
  }

  function handleCloseModal() {
    setHomeDrawerStates((prevStates) => ({ ...prevStates, isModalOpen: !prevStates.isModalOpen }));
  }

  function handleDelete() {
    setHomeDrawerStates((prevStates) => ({
      ...prevStates,
      isModalOpen: true,
      modalAction: DELETE_ACTION,
      modalDescription: DELETE_TA_DESCRIPTION,
      modalTitle: DELETE_TA
    }));
  }

  async function deleteUser() {
    const deleteModalState = await deleteRecruiterService(userId.toString());
    setHomeDrawerStates((prevStates) => ({
      ...prevStates,
      isModalOpen: false,
      isUndoToastVisible: false
    }));
    if (!deleteModalState.data) {
      showNoDeleteModal(true);
      setDeleteUserName(deleteModalState?.error[0]?.data);
    } else {
      const userRole = role.map((item) => item.role).includes(ROLE_ADMIN);
      userRole && dispatch(getEmployeeCount());
      showNoDeleteModal(false);
      toggleDrawer();
    }
  }

  const onDeleteClick = () => {
    setHomeDrawerStates((prevStates) => ({
      ...prevStates,
      isUndoToastVisible: true,
      isModalOpen: false
    }));
    notify(
      false,
      <ToastUndo
        toastMessage={`Successfully deleted ${firstName} ${lastName}`}
        handleUndo={onUndoDelete}
        toastId={parseInt(userDataById?.userId.toString())}
      />,
      parseInt(userDataById?.userId.toString()),
      deleteUser
    );
  };

  const onUndoDelete = () => {
    setHomeDrawerStates((prevStates) => ({
      ...prevStates,
      isUndoToastVisible: false
    }));
    toast.dismiss(parseInt(userDataById?.userId.toString()));
    toast.update(parseInt(userDataById?.userId.toString()), {
      onClose: () => {},
      hideProgressBar: true
    });
  };

  const handleCancelDeleteModal = () => {
    showNoDeleteModal(false);
  };

  const getLDeleteModalEdgeCase = () => {
    return (
      <Modal
        header={TA_UNABLE_DELETE_TITLE}
        customStyle="edit-modal"
        onClose={handleCloseModal}
        isOpen={noDeleteModal}>
        <div className="edit-modal-content">
          <div className="delete-modal-para">
            <span className="modalLevelName">
              <q>{deleteUserName}</q>
            </span>
            <span>{CANNOT_DELETE_ACTIVE_BOOKINGS}</span>
          </div>
          <div className="edit-modal-footer">
            <Button customStyle="edit-modal--cancel-btn" onClick={handleCancelDeleteModal}>
              {CANCEL_BUTTON_TEXT}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <Fragment>
      {getLDeleteModalEdgeCase()}
      <Drawer
        onClose={toggleDrawer}
        isOpen={isDrawerOpen}
        bodyStyles="drawer-body-ta"
        headerStyles="drawer-header-custom-style"
        headerProps={
          id === userId ? (
            ''
          ) : (
            <div
              className="delete-icon"
              onClick={handleDelete}
              tabIndex={0}
              onKeyDown={handleTabEventwithoutValue(handleDelete)}>
              <ImageComponent src={Delete} />
            </div>
          )
        }>
        {isUserDataLoading ? (
          <ComponentLoader />
        ) : (
          <Fragment>
            <ProfileDetails
              profileImageUrl={profileImageUrl}
              name={`${firstName} ${lastName}`}
              designation={designation}
              email={emailId}
              mobile={mobileNo}
              showExperience={totalExperience ? totalExperience : ZERO_EXPERIENCE}
            />
            <div className="separatorLine"></div>
            <div className="techDetails">
              <div className="techLabel">{ROLE_LABEL}</div>
              <div className="techName">{TALENT_ACQUISITION}</div>
              {techStackInterviewLevelDtos?.length > 0 && (
                <Fragment>
                  <div className="techStackLabel">{TS_LABEL}</div>
                  <div className="techStakName">
                    <TATechStacks techStackInterviewLevelDtos={techStackInterviewLevelDtos} />
                  </div>
                </Fragment>
              )}
            </div>
          </Fragment>
        )}
      </Drawer>
      <ConfirmationModal
        title={modalTitle}
        description={modalDescription}
        onConfirm={onDeleteClick}
        onClose={handleCloseModal}
        isOpen={isModalOpen}
        confirmText="Delete"
      />
    </Fragment>
  );
}
export default HomeDrawer;
