import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PANEL, PANEL_ARCHIVE, PANEL_STATUS_CHANGE } from '../constant';
import { RootState } from '../store';
import {
  getInReviewPanelService,
  getArchivedPanelService,
  getApprovedPanelService,
  deletePanelService,
  searchPanelService,
  getUserInfoService,
  editUserInfoService,
  editStatusService,
  archiveUsers
} from '../../services/panel.service';
import {
  PANEL_GET_SELECTEDUSERDATA,
  PANEL_SEARCH,
  PANEL_DELETE,
  PANEL_EDITPANEL_INFO,
  PANEL_GET_PANEL_ALL,
  PANEL_GET_PANEL_ONBENCH,
  PANEL_GET_PANEL_ONPROJECT
} from '../constant';
interface IPanelStates {
  panelData: any;
}

const initialState: IPanelStates = {
  panelData: {
    panelList: [],
    count: 0,
    allCount: 0,
    onProjectCount: 0,
    onBenchCount: 0,
    interviewLevelList: [],
    techStacks: [],
    projects: [],
    searchOptions: [],
    selectedUserData: {},
    isToastVisible: false,
    saveModaltitle: '',
    saveModalDescription: '',
    formData: {},
    loading: false,
    isError: false,
    currentDeletion: {},
    loader: false,
    isDrawerLoading: false
  }
};

export const getSelectedUserData = createAsyncThunk(
  PANEL_GET_SELECTEDUSERDATA,
  async (id: string, thunkAPI) => {
    const value = await getUserInfoService(id);
    if (!value?.error) {
      return value;
    }
    return thunkAPI.rejectWithValue(value);
  }
);

export const getSearchedData = createAsyncThunk(PANEL_SEARCH, async (params: any, thunkAPI) => {
  const { searchString, abortSignal } = params;
  const data = await searchPanelService(searchString, abortSignal?.signal);
  if (!data.error) {
    return data;
  } else {
    if (data.error[0].code === 1008) {
      return { data: null, error: [{ message: data.error.message, code: data.error.code }] };
    } else if (data.error[0].code === 400) {
      return { data: null, error: [{ message: data.error.message, code: data.error.code }] };
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  }
});

export const deletePanels = createAsyncThunk(PANEL_DELETE, async (idArray: any) => {
  await deletePanelService(idArray);
});

export const editUserInfo = createAsyncThunk(PANEL_EDITPANEL_INFO, async (body: any, thunkAPI) => {
  const state: any = await thunkAPI.getState();
  const value = await editUserInfoService(body);
  if (!value?.error) {
    return value;
  }
  return thunkAPI.rejectWithValue(value);
});

export const getInReviewPanel = createAsyncThunk(
  PANEL_GET_PANEL_ALL,
  async (props: any, thunkAPI) => {
    const value = await getInReviewPanelService(props.pgNo, props.filters);
    if (!value?.error) {
      return value;
    }
    return thunkAPI.rejectWithValue(value);
  }
);

export const getArchivedPanel = createAsyncThunk(
  PANEL_GET_PANEL_ONBENCH,
  async (props: any, thunkAPI) => {
    const value = await getArchivedPanelService(props.pgNo, props.filters);
    if (!value?.error) {
      return value;
    }
    return thunkAPI.rejectWithValue(value);
  }
);

export const getApprovedPanel = createAsyncThunk(
  PANEL_GET_PANEL_ONPROJECT,
  async (props: any, thunkAPI) => {
    const value = await getApprovedPanelService(props.pgNo, props.filters);
    if (!value?.error) {
      return value;
    }
    return thunkAPI.rejectWithValue(value);
  }
);
export const approvePanel = createAsyncThunk(PANEL_STATUS_CHANGE, async (props: any, thunkAPI) => {
  const value = await editStatusService(props);
  if (!value?.error) {
    return value;
  }
  return thunkAPI.rejectWithValue(value);
});
export const archievePanel = createAsyncThunk(PANEL_ARCHIVE, async (props: any, thunkAPI) => {
  const value = await archiveUsers(props);
  if (!value?.error) {
    return value;
  }
  return thunkAPI.rejectWithValue(value);
});

export const panelSlice = createSlice({
  name: PANEL,
  initialState,
  reducers: {
    setCurrentDeletion: (state, action) => {
      state.panelData.currentDeletion = action.payload;
    },
    sort: (state, action) => {
      state.panelData.panelList = action.payload;
    },
    setLoading: (state, action) => {
      state.panelData.loader = action.payload;
    },
    setToast: (state, action) => {
      state.panelData.isToastVisible = action.payload;
    },
    setPanelList: (state, action) => {
      state.panelData.panelList = action.payload;
      (state.panelData.count = 1),
        (state.panelData.allCount = 1),
        (state.panelData.onProjectCount = action.payload[0]?.projectNameList?.length ? 1 : 0),
        (state.panelData.onBenchCount = action.payload[0]?.projectNameList?.length ? 0 : 1);
    },
    generateFormDetails: (state, action) => {
      const { updatedData, initialRole } = action.payload;
      const techStackId = updatedData?.techStack?.map((techStack: any) => techStack?.id);
      const projectId = updatedData?.panelValues?.projects?.map((project: any) => project?.id);
      const isOnBench = !updatedData?.panelValues?.projects?.length;
      const techStackLevel = updatedData?.panelValues?.stackLoi?.map((stack: any) => ({
        techStackId: [stack?.firstValue?.id],
        interviewLevelId: stack?.secondValue?.id
      }));
      const objectToSend = {
        userId: action.payload.userId,
        roleDetails: [
          {
            roleId: 0,
            projectId: [],
            techStackLevel: [
              {
                techStackId: [],
                interviewLevelId: 0
              }
            ]
          }
        ],
        onBench: false
      };
      const recruiterRoleDetails = [
        {
          roleId: 3,
          techStackLevel: [{ techStackId: techStackId, interviewLevelId: 0 }]
        }
      ];
      const panelRoleDetails = [
        {
          roleId: 2,
          projectId: projectId,
          techStackLevel: techStackLevel
        }
      ];
      let dataToSend = {};
      if (updatedData?.techStack && updatedData?.panelValues) {
        const bothData = {
          ...objectToSend,
          roleDetails: [recruiterRoleDetails[0], panelRoleDetails[0]],
          onBench: isOnBench
        };
        dataToSend = bothData;
      } else if (updatedData?.techStack?.length) {
        const recruiterData = { ...objectToSend, roleDetails: recruiterRoleDetails };
        dataToSend = recruiterData;
      } else {
        const updatedData = { ...objectToSend, roleDetails: panelRoleDetails, onBench: isOnBench };
        dataToSend = updatedData;
      }

      state.panelData.formData = dataToSend;
    },
    generateModalDetails: (state, actions) => {
      const { title, description } = actions.payload;
      state.panelData.saveModaltitle = title;
      state.panelData.saveModalDescription = description;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getInReviewPanel.fulfilled, (state, { payload }) => {
      (state.panelData.panelList = payload?.data?.panelList?.length ? payload.data.panelList : []),
        (state.panelData.count = payload.data.pageCount),
        (state.panelData.allCount = payload.data.totalCount),
        (state.panelData.onProjectCount = payload.data.onProjectCount),
        (state.panelData.onBenchCount = payload.data.onBenchCount);
      state.panelData.loading = false;
    });
    builder.addCase(getInReviewPanel.rejected, (state, { payload }) => {
      state.panelData.isError = true;
      state.panelData.loading = false;
    });
    builder.addCase(getInReviewPanel.pending, (state, { payload }) => {
      state.panelData.isError = false;
      state.panelData.loading = true;
    });
    builder.addCase(getArchivedPanel.fulfilled, (state, { payload }) => {
      (state.panelData.panelList = payload?.data?.panelList?.length ? payload.data.panelList : []),
        (state.panelData.count = payload.data.pageCount),
        (state.panelData.allCount = payload.data.totalCount),
        (state.panelData.onProjectCount = payload.data.onProjectCount),
        (state.panelData.onBenchCount = payload.data.onBenchCount);
      state.panelData.loading = false;
    });
    builder.addCase(getArchivedPanel.rejected, (state, { payload }) => {
      state.panelData.isError = true;
      state.panelData.loading = false;
    });
    builder.addCase(getArchivedPanel.pending, (state, { payload }) => {
      state.panelData.isError = false;
      state.panelData.loading = true;
    });
    builder.addCase(getApprovedPanel.fulfilled, (state, { payload }) => {
      (state.panelData.panelList = payload?.data?.panelList?.length ? payload.data.panelList : []),
        (state.panelData.count = payload.data.pageCount),
        (state.panelData.allCount = payload.data.totalCount),
        (state.panelData.onProjectCount = payload.data.onProjectCount),
        (state.panelData.onBenchCount = payload.data.onBenchCount);
      state.panelData.loading = false;
    });
    builder.addCase(getApprovedPanel.rejected, (state, { payload }) => {
      state.panelData.isError = true;
      state.panelData.loading = false;
    });
    builder.addCase(getApprovedPanel.pending, (state, { payload }) => {
      state.panelData.isError = false;
      state.panelData.loading = true;
    });
    builder.addCase(getSearchedData.pending, (state, { payload }: any) => {
      state.panelData.searchOptions = [];
    });
    builder.addCase(getSearchedData.fulfilled, (state, { payload }: any) => {
      state.panelData.searchOptions = payload.data;
      state.panelData.isError = false;
    });
    builder.addCase(getSearchedData.rejected, (state, { payload }: any) => {
      state.panelData.isError = true;
    });
    builder.addCase(getSelectedUserData.pending, (state, { payload }: any) => {
      state.panelData.selectedUserData = {};
    });
    builder.addCase(getSelectedUserData.fulfilled, (state, { payload }: any) => {
      state.panelData.selectedUserData = payload.data;
    });
    builder.addCase(getSelectedUserData.rejected, (state, { payload }) => {
      state.panelData.isError = true;
    });
    builder.addCase(editUserInfo.rejected, (state, { payload }) => {
      state.panelData.isError = true;
      state.panelData.isDrawerLoading = false;
    });
    builder.addCase(editUserInfo.fulfilled, (state, { payload }) => {
      state.panelData.isDrawerLoading = false;
    });
    builder.addCase(editUserInfo.pending, (state, { payload }) => {
      state.panelData.isDrawerLoading = true;
    });
  }
});

export const {
  sort,
  setPanelList,
  generateFormDetails,
  generateModalDetails,
  setLoading,
  setToast,
  setCurrentDeletion
} = panelSlice.actions;
export const panel = (state: RootState) => state.panel.panelData;
export default panelSlice.reducer;
