import React, { useCallback, useEffect, useState } from 'react';
import './index.style.scss';
import { Moment } from 'moment';
import SlotDetailContainer, { SlotStrip } from '.';
import CancelCard, { ICancel } from './cancel-card';
import moment from 'moment';
import { GREEN } from 'helpers/constants';
import { timeStamps } from 'helpers/timeStamp.constants';

interface IEvents {
  id: string | number;
  start: Moment;
  end: Moment;
  showCancelCard?: boolean;
}

export interface ISlotBooked extends ICancel {
  id: string | number;
}

interface ISlotBookedContainer {
  events: IEvents[];
  onCardCancelClick?: (param: ISlotBooked) => void;
  showMakeSlotAvailableCheckbox?: boolean;
  showCancelledBy?: boolean;
}

function SlotBookedContainer(props: ISlotBookedContainer) {
  const [slots, setSlot] = useState(props.events);

  useEffect(() => {
    const { events } = props;
    setSlot(events);
  }, [props.events]);

  const handleStripCancelClick = useCallback(
    (param: IEvents) => () => {
      const data = slots.map((slot) => {
        if (slot.id.toString() === param.id.toString()) {
          slot.showCancelCard = !slot.showCancelCard;
          return slot;
        }
        return slot;
      });
      setSlot(data);
    },
    [slots]
  );

  const handleCardCancelClick = useCallback(
    (param: IEvents) => (params: ICancel) => {
      const { onCardCancelClick } = props;
      const data = Object.assign(params, { id: param.id });
      onCardCancelClick && onCardCancelClick(data);
    },
    [props.onCardCancelClick]
  );

  return (
    <SlotDetailContainer title="Booked Slots">
      {slots.map((slot) => {
        if (!slot.showCancelCard)
          return (
            <SlotStrip
              key={slot.id}
              showCancelButton={slot.end.isAfter(moment())}
              title={`${slot.start.format(timeStamps.HH_MM_A)} - ${slot.end.format(
                timeStamps.HH_MM_A
              )}`}
              borderColor={GREEN}
              onCancelClick={handleStripCancelClick(slot)}
            />
          );
        else
          return (
            <div key={slot.id} className="cancel-card-border-wrapper">
              <div className="cancel-card-border"></div>
              <CancelCard
                start={slot.start.toDate()}
                end={slot.end.toDate()}
                onCloseClick={handleStripCancelClick(slot)}
                onCancelClick={handleCardCancelClick(slot)}
                showCancelledBy={props.showCancelledBy}
                showMakeSlotAvailableCheckbox={props.showMakeSlotAvailableCheckbox}
              />
            </div>
          );
      })}
    </SlotDetailContainer>
  );
}

SlotBookedContainer.defaultProps = {
  showMakeSlotAvailableCheckbox: false,
  showCancelledBy: false
};

export default SlotBookedContainer;
