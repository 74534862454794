import React, { Fragment, useCallback, useEffect, useState } from 'react';
import './index.style.scss';
import Button from 'app/core/shared-components/button';
import Typography from 'app/core/shared-components/typography';
import ClickAwayListener from 'app/core/shared-components/click-away';
import Images from 'assets/images';
import NoRequestedSlot from 'assets/images/no-result-found.svg';
import ImageComponent from 'app/core/shared-components/image';
import DatePicker from 'app/core/shared-components/date-picker';
import moment from 'moment';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  getSlotSummaryData,
  editPanelSlot,
  ISlotSummaryType,
  slotDelete,
  setCurrentSelectedDate,
  readdEvent,
  removeEvent
} from 'app/redux/slices/adminDashboardSlice';
import SlotAvailableContainer, {
  IEvents
} from 'app/core/shared-components/slot-detail-container/slot-available';
import SlotCancelledContainer from 'app/core/shared-components/slot-detail-container/slot-cancelled';
import GoogleEventsContainer from 'app/core/shared-components/slot-detail-container/google-events';
import ComponentLoader from 'app/pages/Dashboard/components/loader-component';
import {
  CHECK_FOR_ANOTHER_DATE,
  NO_EVENTS_FOUND,
  SLOT_DELETED_TOAST_MESSAGE,
  SLOT_EDIT_TOAST_MESSAGE,
  SLOT_OVERLAP_TOAST_MESSAGE
} from 'helpers/messages.constants';
import ToastUndo from 'app/core/shared-components/toast-undo';
import { notify } from 'helpers/toastHelper';
import { toast } from 'react-toastify';
import { formatTime } from 'helpers/dateHelper';
import { v4 as uuidv4 } from 'uuid';
import ErrorPage from 'app/pages/Error';
import SlotRequestedContainer from 'app/core/shared-components/slot-detail-container/slot-requested';
import { timeStamps } from 'helpers/timeStamp.constants';

interface ICategoryData {
  eventSummary: string | null;
  from: string;
  isAllDayEvent: boolean | null;
  isCalendarEvent: boolean;
  isRequestedSlot: boolean;
  slotId: string;
  status: number;
  techStack: string[] | null;
  to: string;
}

interface ISummaryData {
  AVAILABLE?: ICategoryData[];
  BOOKED?: ICategoryData[];
  CANCELLED?: ICategoryData[];
}
interface ISlotSummary {
  showDatePicker: boolean;
  selectedDate: Date | null;
  disabled: boolean;
  currentDate: Date | null;
  slotSummary: ISummaryData;
  pendingDeletionQueue: string[];
  availableSlots: IEvents[];
}

const SlotSummary = () => {
  const [slotSummaryStates, setSlotSummaryStates] = useState<ISlotSummary>({
    showDatePicker: false,
    selectedDate: new Date(),
    disabled: true,
    currentDate: new Date(),
    slotSummary: {},
    pendingDeletionQueue: [],
    availableSlots: []
  });
  const dispatch = useAppThunkDispatch();
  const {
    slotSummaryData,
    isSlotSummaryLoading,
    availableSlotSummary,
    editSlotError,
    requestedSlotSummary,
    isSlotSummaryError,
    currentSelectedDate
  } = useAppSelector((state) => state.adminDashboard);
  const { userId } = useAppSelector((state) => state.user.userData);
  const {
    showDatePicker,
    selectedDate,
    disabled,
    currentDate,
    availableSlots,
    pendingDeletionQueue
  } = slotSummaryStates;
  const { date, close } = Images;

  const handleDeleteUndo = useCallback(
    (slotId: string | number) => async () => {
      dispatch(readdEvent(slotId));
      toast.dismiss(slotId);
      toast.update(slotId, { onClose: () => {}, hideProgressBar: true, autoClose: 10 });
    },
    []
  );

  const handleDeleteClose = useCallback(async (toastId: number | string) => {
    await dispatch(slotDelete(toastId));
  }, []);

  const handleAvailableSlotsDelete = async (param: IEvents) => {
    const clickableTime = moment();
    if (param.end.isAfter(clickableTime)) {
      dispatch(removeEvent(param.id));
      notify(
        false,
        <ToastUndo
          toastId={param.id}
          toastMessage={SLOT_DELETED_TOAST_MESSAGE}
          handleUndo={handleDeleteUndo(param.id)}
        />,
        param.id,
        handleDeleteClose
      );
    }
  };

  const closeDatePicker = () => {
    setSlotSummaryStates((prevState) => ({ ...prevState, showDatePicker: false }));
  };

  const handleResetDate = async () => {
    setSlotSummaryStates((prevState) => ({ ...prevState, showDatePicker: false }));
    await setSlotSummaryStates((prevState) => ({
      ...prevState,
      selectedDate: new Date(),
      showDatePicker: false
    }));
    dispatch(setCurrentSelectedDate(new Date()));
    dispatch(getSlotSummaryData(moment().format(timeStamps.DATE_TIME)));
  };

  const toggleDatePicker = () => {
    if (!showDatePicker) {
      setSlotSummaryStates((prevState) => ({ ...prevState, disabled: true }));
    }
    setSlotSummaryStates((prevState) => ({ ...prevState, showDatePicker: !showDatePicker }));
  };

  const handleDateChange = (date: Date | Date[] | null) => {
    if (!Array.isArray(date)) {
      setSlotSummaryStates((prevState) => ({ ...prevState, currentDate: date }));
      if (
        moment(date).format(timeStamps.DD_MM_YY) ==
          moment(selectedDate).format(timeStamps.DD_MM_YY) ||
        date === null
      ) {
        setSlotSummaryStates((prevState) => ({ ...prevState, disabled: true }));
      } else {
        setSlotSummaryStates((prevState) => ({ ...prevState, disabled: false }));
      }
    }
  };

  const handleApply = async () => {
    await setSlotSummaryStates((prevState) => ({
      ...prevState,
      selectedDate: currentDate,
      showDatePicker: false
    }));
    dispatch(setCurrentSelectedDate(currentDate));
    dispatch(getSlotSummaryData(moment(currentDate).format(timeStamps.DATE_TIME)));
  };

  const handleSaveClick = (param: IEvents) => {
    const data = {
      date: moment(selectedDate).format(timeStamps.YYYY_MM_DD),
      slot: [param].map((slot) => {
        return {
          id: slot.id.toString(),
          from: formatTime(slot.start.format(timeStamps.HH_MM_A), slot.start.toDate()),
          to: formatTime(slot.end.format(timeStamps.HH_MM_A), slot.end.toDate())
        };
      })
    };
    dispatch(editPanelSlot({ userId: userId, slots: [data] })).then(({ payload }) => {
      if (!payload.error) {
        dispatch(getSlotSummaryData(moment(selectedDate).format(timeStamps.DATE_TIME)));
        notify(true, SLOT_EDIT_TOAST_MESSAGE, uuidv4().toString());
      }
    });
  };

  const updateSlotSummaryData = () => {
    dispatch(getSlotSummaryData(moment(selectedDate).format(timeStamps.DATE_TIME)));
    dispatch(setCurrentSelectedDate(selectedDate));
    setSlotSummaryStates((prevState) => ({
      ...prevState,
      slotSummary: slotSummaryData
    }));
  };
  useEffect(() => {
    updateSlotSummaryData();
  }, []);

  useEffect(() => {
    setSlotSummaryStates((prevState) => ({ ...prevState, availableSlots: availableSlotSummary }));
  }, [availableSlotSummary]);

  return (
    <div className="slot-summary-wrapper">
      {editSlotError || isSlotSummaryError ? (
        <ErrorPage page="apiFailure" />
      ) : (
        <>
          <div className="header">
            <div className="title">
              <Typography variant="headline-16" customStyle="slot-summary-header">
                Slot Summary
              </Typography>
              <div className="date-picker">
                <ClickAwayListener handleClose={closeDatePicker}>
                  <Button
                    startIcon={date}
                    onClick={toggleDatePicker}
                    customStyle="date-btn"></Button>
                  {showDatePicker && (
                    <div className="slot-summary-date-picker-popover">
                      <div className="year">
                        <Typography variant="headline-16" customStyle="title">
                          Filter
                        </Typography>
                        <ImageComponent
                          src={close}
                          customClass="modal-close-icon"
                          onClick={closeDatePicker}
                        />
                      </div>
                      <div className="slot-date-picker-wrapper">
                        <DatePicker
                          onChange={handleDateChange}
                          disablePast
                          defaultValue={selectedDate}
                        />
                      </div>
                      <div className="modal-buttons">
                        <Button
                          variant="outlined"
                          customStyle="cancel-button"
                          onClick={handleResetDate}
                          disabled={
                            moment().format(timeStamps.DD_MM_YY) ===
                            moment(selectedDate).format(timeStamps.DD_MM_YY)
                          }>
                          Reset
                        </Button>
                        <Button
                          variant="contained"
                          customStyle="request-button"
                          disabled={disabled}
                          onClick={handleApply}>
                          Apply
                        </Button>
                      </div>
                    </div>
                  )}
                  {moment().format(timeStamps.DD_MM_YY) !==
                    moment(selectedDate).format(timeStamps.DD_MM_YY) && (
                    <div className="slot-summary-date-picker-badge"></div>
                  )}
                </ClickAwayListener>
              </div>
            </div>
            <Typography variant="headline-16" customStyle="date">
              {moment(selectedDate || '')?.format(timeStamps.DD_MMMM_YYYY)}
            </Typography>
          </div>

          {isSlotSummaryLoading ? (
            <ComponentLoader />
          ) : (
            <div className="slot-summary-body">
              {slotSummaryData?.summaryMap &&
                !!(Object.keys(slotSummaryData?.summaryMap)?.length === 0) && (
                  <div className="no-events">
                    <ImageComponent src={NoRequestedSlot} />
                    <Typography variant="headline-24">{NO_EVENTS_FOUND}</Typography>
                    <Typography variant="headline-16" customStyle="no-slot-message">
                      {CHECK_FOR_ANOTHER_DATE}
                    </Typography>
                  </div>
                )}
              {!!availableSlotSummary?.length && (
                <SlotAvailableContainer
                  events={availableSlotSummary}
                  onDeleteClick={handleAvailableSlotsDelete}
                  onSaveClick={handleSaveClick}
                />
              )}
              {!!requestedSlotSummary?.length && (
                <SlotRequestedContainer events={requestedSlotSummary} />
              )}
              {!!slotSummaryData?.summaryMap?.calendarEvents?.length && (
                <GoogleEventsContainer
                  events={slotSummaryData?.summaryMap?.calendarEvents?.map(
                    (events: ICategoryData, index: number) => ({
                      title: events.eventSummary,
                      start: events.isAllDayEvent
                        ? moment(events.from).set({ hour: 0, minute: 0 })
                        : moment(events.from),
                      end: events.isAllDayEvent
                        ? moment(events.to).set({ hour: 23, minute: 59 })
                        : moment(events.to)
                    })
                  )}
                />
              )}
              {!!slotSummaryData?.summaryMap?.CANCEL?.length && (
                <SlotCancelledContainer
                  events={slotSummaryData?.summaryMap?.CANCEL?.map((events: ICategoryData) => ({
                    start: moment(events.from),
                    end: moment(events.to)
                  }))}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default SlotSummary;
