import React, { ButtonHTMLAttributes, HtmlHTMLAttributes } from 'react';
import ImageComponent from 'app/core/shared-components/image';
import './index.style.scss';
import { ISelectedSearchProps } from 'app/core/shared-components/button-group';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: any;
  startIcon?: string;
  endIcon?: string;
  variant?: 'text' | 'outlined' | 'contained';
  size?: '2xl' | 'xl' | 'large' | 'medium' | 'small';
  disabled?: boolean;
  customStyle?: string;
  label?: string;
  count?: number;
  selectedSearch?: ISelectedSearchProps;
}
function Button(props: IButtonProps) {
  const { variant, startIcon, label, endIcon, size, children, customStyle, count, ...otherProps } =
    props;

  return (
    <button
      className={`custom-button button--${size} button--${
        variant ? variant : 'outlined'
      } ${customStyle}`}
      {...otherProps}>
      {startIcon && <ImageComponent customClass="icon--leading" src={startIcon} />}
      {children && (
        <span className="button--label">
          {children}
          {label}
        </span>
      )}
      {endIcon && <ImageComponent customClass="icon--trailing " src={endIcon} />}
    </button>
  );
}

export default Button;
