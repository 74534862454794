import React, { useCallback, useMemo } from 'react';
import { components } from 'react-select';
import CheckBox from '../../checkbox';
import './index.style.scss';
import { SELECT_ALL } from '../../../../../helpers/constants';

const MenuList = ({ children, selectProps, ...props }: any) => {
  const { showMasterCheck = false, onChange, selectedOptions, options } = selectProps;

  const handleSelectOption = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      selectedOptions?.length < options?.length ? onChange(options) : onChange([]);
    },
    [options, selectedOptions]
  );

  const checkMaster = useMemo(
    () => selectedOptions?.length === options?.length,
    [selectedOptions, options]
  );

  const checkIdeal = useMemo(
    () => !checkMaster && selectedOptions?.length && selectedOptions?.length < options?.length,
    [selectedOptions, options]
  );

  const displayMasterCheck = useMemo(
    () => showMasterCheck && Array.isArray(children) && children?.length === options?.length,
    [children, options]
  );

  return (
    <components.MenuList {...props}>
      {displayMasterCheck && (
        <div className="select-master-check" onClick={handleSelectOption}>
          <CheckBox
            handleClick={handleSelectOption}
            checked={checkMaster}
            ideal={checkIdeal}
            tabIndex
          />
          <span>{SELECT_ALL}</span>
        </div>
      )}
      {children}
    </components.MenuList>
  );
};

export default MenuList;
