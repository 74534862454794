import React, { Fragment, useMemo } from 'react';
import { components } from 'react-select';
import Images from '../../../../../assets/images';
import { ORANGE } from '../../../../../helpers/constants';
import ImageComponent from '../../image';

const DropdownIndicator = (props: any) => {
  const {
    showDropdownIndicator = false,
    menuIsOpen,
    dropDownIndicatorColor = ''
  } = props.selectProps;
  const { arrowDown, arrowUp, orangeArrow, orangeArrowUp } = Images;

  const downImageSrc = useMemo(
    () => (dropDownIndicatorColor === ORANGE ? orangeArrow : arrowDown),
    [dropDownIndicatorColor]
  );
  const upImageSrc = useMemo(
    () => (dropDownIndicatorColor === ORANGE ? orangeArrowUp : arrowUp),
    [dropDownIndicatorColor]
  );

  return (
    <Fragment>
      {showDropdownIndicator ? (
        <components.DropdownIndicator {...props}>
          <ImageComponent src={menuIsOpen ? upImageSrc : downImageSrc} />
        </components.DropdownIndicator>
      ) : null}
    </Fragment>
  );
};

export default DropdownIndicator;
