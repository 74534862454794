import React from 'react';
import { IClippedSelectValue } from 'app/core/shared-components/clipped-select';
import Form, { ITechStackArray } from 'app/core/shared-components/form';
import { ISelectValue } from 'app/core/shared-components/select';
import PanelDrawer from 'app/pages/Panel/components/utils/PanelDrawer';
import { IPanelStates, IPanelType, ISelectedPanelType } from 'helpers/panel';
import { getExperience } from 'helpers/utils';

interface IFormBody {
  panelForm: boolean;
  selectedUser: ISelectedPanelType;
  panelState: IPanelStates;
  id: string | number;
  panel: IPanelType | ISelectValue;
  handlCloseDrawer: () => void;
  handleEditPanel: (
    role: ISelectValue,
    status: string,
    projects?: ISelectValue[],
    stackLop?: ITechStackArray[]
  ) => void;
  handleSave: (
    role: ISelectValue,
    status: string,
    projects?: ISelectValue[],
    stackLop?: ITechStackArray[]
  ) => void;
}
const FormBody = (props: IFormBody) => {
  const {
    panelForm,
    selectedUser,
    panelState,
    id,
    panel,
    handlCloseDrawer,
    handleEditPanel,
    handleSave
  } = props;

  return panelForm ? (
    <PanelDrawer
      multiOnboard={false}
      selectedPanel={selectedUser}
      panelState={panelState}
      hideArchive={false}
      id={id}
      panel={panel}
      handlCloseDrawer={handlCloseDrawer}
      handleEditPanel={handleEditPanel}
    />
  ) : (
    <Form
      showProfileField={true}
      userDetail={{
        name: `${selectedUser?.firstName} ${selectedUser?.lastName}`,
        designation: selectedUser?.designation || '',
        email: selectedUser?.emailId || '',
        experience: (selectedUser?.experience && getExperience(selectedUser?.experience)) || '',
        mobile: `${selectedUser?.mobileNo}`,
        profileImageUrl: `${selectedUser?.profileImageUrl}`
      }}
      showStatusField={false}
      onSave={handleSave}
      onCancel={handlCloseDrawer}
    />
  );
};
export default FormBody;
