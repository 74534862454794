import React from 'react';
import LayoutRoute from './layoutRoute';
import Onboard from '../app/pages/Onboard';
import LevelOfInterview from '../app/pages/LevelOfInterview';
import Project from '../app/pages/Project';
import Panel from '../app/pages/Panel';
import TechStack from '../app/pages/TechStack';
import Settings from '../app/pages/Settings';
import TalentAcquisition from '../app/pages/Recruiter';
import Slots from '../app/pages/Slots';
import Schedule from '../app/pages/Schedule';
import Dashboard from '../app/pages/Dashboard';
import { ROLE_ADMIN, ROLE_PANEL, ROLE_RECRUITER } from '../helpers/constants';

const adminRoute = [
  {
    name: '/',
    path: '',
    element: <Dashboard />
  },
  {
    name: 'home',
    path: 'home',
    element: <Dashboard />
  },
  {
    name: 'onboard',
    path: 'onboard',
    element: <Onboard />
  },
  {
    name: 'techStacks',
    path: 'techStacks',
    element: <TechStack />
  },
  {
    name: 'levels',
    path: 'levels',
    element: <LevelOfInterview />
  },
  {
    name: 'projects',
    path: 'projects',
    element: <Project />
  },
  {
    name: 'settings',
    path: 'settings',
    element: <Settings />
  },
  {
    name: 'talentAcquisition',
    path: 'talentAcquisition',
    element: <TalentAcquisition />
  },
  {
    name: 'panel',
    path: 'panel',
    element: <Panel />
  }
];

const panelRoutes = [
  {
    name: '/',
    path: '',
    element: <Dashboard />
  },
  {
    name: 'home',
    path: 'home',
    element: <Dashboard />
  },
  {
    name: 'Schedule',
    path: 'schedule',
    element: <Schedule />
  },
  {
    name: 'settings',
    path: 'settings',
    element: <Settings />
  }
];

const taRoutes = [
  {
    name: '/',
    path: '',
    element: <Dashboard />
  },
  {
    name: 'home',
    path: 'home',
    element: <Dashboard />
  },
  {
    name: 'Slots',
    path: 'slots',
    element: <Slots />
  },
  {
    name: 'settings',
    path: 'settings',
    element: <Settings />
  }
];

function routes(roles: Array<string>) {
  let filteredRoutes: any = [];
  if (roles.includes(ROLE_ADMIN)) filteredRoutes = [...filteredRoutes, ...adminRoute];
  if (roles.includes(ROLE_PANEL)) filteredRoutes = [...filteredRoutes, ...panelRoutes];
  if (roles.includes(ROLE_RECRUITER)) filteredRoutes = [...filteredRoutes, ...taRoutes];

  return [
    {
      name: 'layout',
      path: '/',
      element: <LayoutRoute />,
      children: [...filteredRoutes]
    }
  ];
}

export default routes;
