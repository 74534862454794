import React, { SyntheticEvent, useEffect, useState } from 'react';
import './index.style.scss';
import ImageComponent from 'app/core/shared-components/image';
import Typography from 'app/core/shared-components/typography';
import Button from 'app/core/shared-components/button';
import Images from 'assets/images';
import { Moment } from 'moment';
import moment from 'moment';
import { generateTimeSlots, nearestMinutes } from 'helpers/dateHelper';
import CustomSelect, { ISelectOptions } from 'app/core/shared-components/customSelect';
import { PAST_START_TIME } from 'helpers/messages.constants';
import { timeStamps } from 'helpers/timeStamp.constants';

interface ISlotEditProps {
  start: Moment;
  end: Moment;
  onSaveClick?: (start: Moment, end: Moment) => void;
  onCancelClick?: (event: SyntheticEvent) => void;
}

interface ISlotEditStates {
  firstSelected: ISelectOptions | null;
  secondSelected: ISelectOptions | null;
  startTimeOption: ISelectOptions[];
  endTimeOption: ISelectOptions[];
  timeSlots: Moment[];
  showWarning: boolean;
}

function SlotEdit(props: ISlotEditProps) {
  const { start, end } = props;
  const [slotState, setSlotState] = useState<ISlotEditStates>({
    showWarning: start.isSameOrBefore(moment()),
    startTimeOption: [],
    endTimeOption: [],
    timeSlots: [],
    firstSelected: null,
    secondSelected: null
  });
  const { showWarning } = slotState;

  const setFormData = () => {
    const momentStart = moment(start).set({ hour: 6, minutes: 0 });
    const momentEnd = moment(end).set({ hour: 6, minutes: 0 });
    const currentDateTime = moment();
    const startMoment = moment(start);
    if (startMoment.isSame(currentDateTime, 'date')) {
      const nearestIntervalTime = nearestMinutes(15, currentDateTime);
      const setTime = {
        hour: nearestIntervalTime.isSameOrAfter(startMoment)
          ? startMoment.hour()
          : nearestIntervalTime.hour(),
        minutes: nearestIntervalTime.isSameOrAfter(startMoment)
          ? startMoment.minute()
          : nearestIntervalTime.minute()
      };
      momentStart.set(setTime);
      momentEnd.set(setTime);
    }
    const startOption = generateTimeSlots(momentStart.toDate(), true);
    startOption.pop();
    const endOption = generateTimeSlots(momentEnd.toDate());
    const startTimeOption = startOption.map((slot, index) => ({
      id: index,
      label: slot.format(timeStamps.HH_MM_A)
    }));
    const endTimeOption = endOption.map((slot, index) => ({
      id: index,
      label: slot.format(timeStamps.HH_MM_A)
    }));
    const firstSelected = startOption.findIndex((item) => moment(item).isSame(moment(start)));
    const lastSelected = endOption.findIndex((item) => moment(item).isSame(moment(end)));
    if (firstSelected !== -1 && lastSelected !== -1) {
      const startSelected = {
        id: firstSelected,
        label: startOption[firstSelected].format(timeStamps.HH_MM_A)
      };
      const endSelected = {
        id: lastSelected,
        label: endOption[lastSelected].format(timeStamps.HH_MM_A)
      };
      setSlotState((prevState) => ({
        ...prevState,
        startTimeOption: [...startTimeOption],
        endTimeOption: [...endTimeOption],
        firstSelected: startSelected,
        secondSelected: endSelected,
        timeSlots: endOption
      }));
    }
  };

  const handleFirstSelectChange = (param: ISelectOptions | ISelectOptions[] | null) => {
    const { timeSlots, secondSelected } = slotState;
    if (param && !Array.isArray(param)) {
      const slotIndex = timeSlots.findIndex(
        (_item, index) => index.toString() === param.id.toString()
      );
      if (slotIndex !== -1) {
        const firstSlot = timeSlots[slotIndex];
        if (secondSelected) {
          const secondSlotIndex = timeSlots.findIndex(
            (_item, index) => index.toString() === secondSelected.id.toString()
          );
          const secondSlot = timeSlots[secondSlotIndex];
          if (secondSlotIndex !== -1 && secondSlot.diff(firstSlot, 'minutes') >= 30) {
            setSlotState((prevState) => ({
              ...prevState,
              firstSelected: {
                id: slotIndex,
                label: timeSlots[slotIndex].format(timeStamps.HH_MM_A)
              },
              showWarning: false
            }));
          } else {
            setSlotState((prevState) => ({
              ...prevState,
              firstSelected: {
                id: slotIndex,
                label: timeSlots[slotIndex].format(timeStamps.HH_MM_A)
              },
              secondSelected: null,
              showWarning: false
            }));
          }
        }
      } else {
        setSlotState((prevState) => ({
          ...prevState,
          firstSelected: { id: slotIndex, label: timeSlots[slotIndex].format(timeStamps.HH_MM_A) },
          showWarning: false
        }));
      }
    }
  };

  const handleSecondSelectChange = (param: ISelectOptions | ISelectOptions[] | null) => {
    const { timeSlots, firstSelected } = slotState;
    if (param && !Array.isArray(param)) {
      const slotIndex = timeSlots.findIndex(
        (_item, index) => index.toString() === param.id.toString()
      );
      if (slotIndex !== -1) {
        const secondSlot = timeSlots[slotIndex];
        if (firstSelected) {
          const firstSlotIndex = timeSlots.findIndex(
            (_item, index) => index.toString() === firstSelected.id.toString()
          );
          const firstSlot = timeSlots[firstSlotIndex];
          if (firstSlotIndex !== -1 && secondSlot.diff(firstSlot, 'minutes') >= 30) {
            setSlotState((prevState) => ({
              ...prevState,
              secondSelected: {
                id: slotIndex,
                label: timeSlots[slotIndex].format(timeStamps.HH_MM_A)
              },
              showWarning: false
            }));
          } else {
            setSlotState((prevState) => ({
              ...prevState,
              secondSelected: {
                id: slotIndex,
                label: timeSlots[slotIndex].format(timeStamps.HH_MM_A)
              },
              firstSelected: null,
              showWarning: false
            }));
          }
        } else {
          setSlotState((prevState) => ({
            ...prevState,
            secondSelected: {
              id: slotIndex,
              label: timeSlots[slotIndex].format(timeStamps.HH_MM_A)
            },
            showWarning: false
          }));
        }
      }
    }
  };

  const checkValidation = () => {
    const { firstSelected, secondSelected, timeSlots } = slotState;
    if (firstSelected && secondSelected && !showWarning) {
      const firstSlot = timeSlots.findIndex(
        (_item, index) => index.toString() === firstSelected?.id.toString()
      );
      const secondSlot = timeSlots.findIndex(
        (_item, index) => index.toString() === secondSelected?.id.toString()
      );
      if (firstSlot !== -1 && secondSlot !== -1) {
        const checkStart = timeSlots[firstSlot].isSame(props.start);
        const checkEnd = timeSlots[secondSlot].isSame(props.end);
        return checkStart && checkEnd;
      } else return true;
    } else return true;
  };

  const handleSaveClick = () => {
    const { onSaveClick } = props;
    const { timeSlots, firstSelected, secondSelected } = slotState;
    const currentTime = moment();
    const firstSlot = timeSlots.findIndex(
      (_item, index) => index.toString() === firstSelected?.id.toString()
    );
    const secondSlot = timeSlots.findIndex(
      (_item, index) => index.toString() === secondSelected?.id.toString()
    );
    if (firstSlot !== -1 && secondSlot !== -1) {
      const slotA = timeSlots[firstSlot];
      const slotB = timeSlots[secondSlot];
      if (
        slotB.diff(slotA, 'minutes') >= 30 &&
        slotA.isSameOrAfter(currentTime) &&
        slotB.isAfter(currentTime)
      ) {
        onSaveClick && onSaveClick(slotA, slotB);
      } else setSlotState((prevState) => ({ ...prevState, showWarning: true }));
    } else setSlotState((prevState) => ({ ...prevState, showWarning: true }));
  };

  const handleCancelClick = (event: SyntheticEvent) => {
    const { onCancelClick } = props;
    onCancelClick && onCancelClick(event);
  };
  useEffect(() => {
    setFormData();
  }, []);

  return (
    <div className="slot-edit-container">
      <div className="slot-edit-container-inner">
        <div className="slot-edit-select-wrapper">
          <CustomSelect
            dropdownOptions={slotState.startTimeOption}
            selectedOptions={slotState.firstSelected}
            onChange={handleFirstSelectChange}
            placeholder="10:00 am"
            showFullWidth
          />
          <Typography>-</Typography>
          <CustomSelect
            dropdownOptions={slotState.endTimeOption}
            selectedOptions={slotState.secondSelected}
            onChange={handleSecondSelectChange}
            placeholder="11:00 pm"
            showFullWidth
          />
        </div>
      </div>
      {showWarning && (
        <div className="slot-warning-message-wrapper">
          <ImageComponent src={Images.warningOrange} alt="Warning" />
          <Typography customStyle="slot-warning-message">{PAST_START_TIME}</Typography>
        </div>
      )}
      <div className="slot-edit-action-btn-wrapper">
        <Button
          variant="outlined"
          customStyle="slot-edit-action-btn slot-edit-cancel-btn"
          onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          variant="contained"
          customStyle="slot-edit-action-btn"
          onClick={handleSaveClick}
          disabled={checkValidation()}>
          Save
        </Button>
      </div>
    </div>
  );
}

export default SlotEdit;
