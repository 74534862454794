import * as momentExtend from 'moment';
import { Moment } from 'moment';
import { extendMoment } from 'moment-range';
import Person from '../assets/images/person.svg';

const moment = extendMoment(momentExtend);

function dateOrdinal(date: number) {
  if (date == 31 || date == 21 || date == 1) return date + 'st';
  else if (date == 22 || date == 2) return date + 'nd';
  else if (date == 23 || date == 3) return date + 'rd';
  else return date + 'th';
}

export function getMonth(date: number) {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec'
  ];
  return monthNames[date];
}

function formatDate(date: string) {
  const currentDate = new Date();
  const yesterdayDate = new Date();
  yesterdayDate.setDate(currentDate.getUTCDate() - 1);
  const userDate = new Date(date);
  if (currentDate.toDateString() === userDate.toDateString()) return 'Today';
  else if (yesterdayDate.toDateString() === userDate.toDateString()) return 'Yesterday';
  return `${dateOrdinal(userDate.getUTCDate())} ${getMonth(userDate.getUTCMonth())} '${userDate
    .getUTCFullYear()
    .toString()
    .substring(-2)}`;
}
export default formatDate;

export function getImageInbase64(image: string, imageExt: string) {
  let base64Image = '';
  if (!image) {
    return Person;
  } else {
    base64Image = `data:image/${imageExt};base64,${image}`;
    return base64Image;
  }
}

export const getTime = (date: Date | null, filterNode: boolean = false) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const isCurrentDate = date?.toDateString() == currentDate.toDateString();
  const locale = 'en';
  const hours = [];
  let index = 0;

  const currentTime = moment().format('HH:mm:ss');

  moment.locale(locale);

  for (let hour = 6; hour <= 20; hour++) {
    for (let step = 0; step <= 45; step += 15) {
      if (
        isCurrentDate &&
        moment({ hour, minute: step }).format('HH:mm:ss') <= currentTime &&
        !filterNode
      ) {
        continue;
      }
      hours.push({ id: index, label: moment({ hour, minute: step }).format('h:mm A') });
      index++;
    }
  }
  hours.push({ id: index, label: moment('9:00 PM', 'h:mm A').format('h:mm A') });
  return hours;
};

export const formatTime = (time: string, date: Date) => {
  const dateObj = moment(moment(date).format('YYYY/MM/DD') + time, 'YYYY-MM-DDLT');
  const formattedDate = dateObj.utcOffset('+05:30').format();
  return formattedDate;
};

export function generateTimeSlots(
  date: Date,
  excludeEnd = false,
  step = 15,
  endTime = { hour: 21, minute: 0 }
) {
  const start = moment(date);
  const lastISMTime = start.clone().set(endTime);
  const allDays = moment.range(start, lastISMTime);
  const timeSlots = Array.from(allDays.by('minutes', { excludeEnd, step: step }));
  return timeSlots;
}

export function nearestMinutes(interval: number, someMoment: Moment) {
  const roundedMinutes = Math.round(someMoment.clone().minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}
