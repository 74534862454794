import React, { Fragment, useCallback, useEffect, useState } from 'react';
import './index.style.scss';
import Drawer from 'app/core/shared-components/drawer';
import moment, { Moment } from 'moment';
import Typography from 'app/core/shared-components/typography';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  cancelBookedSlot,
  editPanelSlot,
  reAddEvent,
  removeEvent,
  slotDelete,
  IEvents as ICalEvents
} from 'app/redux/slices/scheduleSlice';
import { SCHEDULE_EVENTS_TYPE } from 'helpers/types';
import { formatTime } from 'helpers/dateHelper';
import {
  SLOT_DELETED_TOAST_MESSAGE,
  SLOT_EDIT_TOAST_MESSAGE,
  SLOT_OVERLAP_TOAST_MESSAGE,
  SLOT_CANCEL_TOAST_MESSAGE
} from 'helpers/messages.constants';
import Loader from 'app/core/shared-components/loader';
import SlotBookedContainer, {
  ISlotBooked
} from 'app/core/shared-components/slot-detail-container/slot-booked';
import SlotCancelledContainer from 'app/core/shared-components/slot-detail-container/slot-cancelled';
import GoogleEventsContainer from 'app/core/shared-components/slot-detail-container/google-events';
import SlotAvailableContainer, {
  IEvents
} from 'app/core/shared-components/slot-detail-container/slot-available';
import { notify } from 'helpers/toastHelper';
import { toast } from 'react-toastify';
import ToastUndo from 'app/core/shared-components/toast-undo';
import { v4 as uuid } from 'uuid';
import ImageComponent from 'app/core/shared-components/image';
import Images from 'assets/images';
import SlotRequestedContainer from 'app/core/shared-components/slot-detail-container/slot-requested';
import { timeStamps } from 'helpers/timeStamp.constants';
import DrawerHeader from './drawer-header';

interface ISlotEventsDrawerStates {
  header: Moment;
  available: ICalEvents[];
  existing: ICalEvents[];
  cancelled: ICalEvents[];
  booked: ICalEvents[];
  requested: ICalEvents[];
}

interface ISlotEventsDrawerProps {
  open: boolean;
  events: ICalEvents[];
  headerDate: Date;
  onClose?: () => void;
  showMakeSlotAvailableCheckbox?: boolean;
  showCancelledBy?: boolean;
}

function SlotEventsDrawer(props: ISlotEventsDrawerProps) {
  const { open, headerDate, showMakeSlotAvailableCheckbox = false } = props;
  const [slotsState, setSlotStates] = useState<ISlotEventsDrawerStates>({
    header: moment(headerDate),
    available: [],
    existing: [],
    cancelled: [],
    booked: [],
    requested: []
  });
  const reduxScheduleState = useAppSelector((state) => state.schedule);
  const dispatch = useAppThunkDispatch();
  const { header, available, existing, cancelled, booked, requested } = slotsState;

  const getBookedFromRedux = (params: ICalEvents[]) => {
    const { bookedEvents } = reduxScheduleState;
    const data = params.filter(
      (item) => !!bookedEvents.find((slot) => slot.id.toString() === item.id.toString())
    );
    const bookedSlots = data.map((item) => ({ ...item, showCancelCard: false }));
    return bookedSlots;
  };

  const getAvailableFromRedux = (params: ICalEvents[]) => {
    const { availableEvents } = reduxScheduleState;
    const availableSlots = params.filter(
      (item) => !!availableEvents.find((slot) => slot.id.toString() === item.id.toString())
    );
    return availableSlots;
  };

  const handleDrawerClose = () => {
    setSlotStates((prevState) => ({
      ...prevState,
      available: [],
      cancelled: [],
      booked: [],
      existing: []
    }));
    props.onClose && props.onClose();
  };

  const handleDeleteUndo = useCallback(
    (slotId: string | number) => async () => {
      dispatch(reAddEvent(slotId));
      toast.update(slotId, { onClose: () => {}, hideProgressBar: true, autoClose: 1 });
      toast.dismiss(slotId);
    },
    []
  );

  const handleDeleteClose = useCallback(async (toastId: number | string) => {
    await dispatch(slotDelete(toastId));
  }, []);

  const handleAvailableSlotsDelete = async (param: IEvents) => {
    const clickableTime = moment();
    if (param.end.isAfter(clickableTime)) {
      dispatch(removeEvent(param.id));
      notify(
        false,
        <ToastUndo
          toastId={param.id}
          toastMessage={SLOT_DELETED_TOAST_MESSAGE}
          handleUndo={handleDeleteUndo(param.id)}
        />,
        param.id,
        handleDeleteClose
      );
    }
  };

  const handleSaveClick = async (param: IEvents) => {
    const data = {
      date: moment(props.headerDate).format(timeStamps.YYYY_MM_DD),
      slot: [param].map((slot) => {
        return {
          id: slot.id.toString(),
          from: formatTime(slot.start.format(timeStamps.HH_MM_A), slot.start.toDate()),
          to: formatTime(slot.end.format(timeStamps.HH_MM_A), slot.end.toDate())
        };
      })
    };
    const response = await dispatch(editPanelSlot([data]));
    const { payload } = response;
    if (payload.data) {
      notify(true, <Fragment>{SLOT_EDIT_TOAST_MESSAGE}</Fragment>, 0);
    } else if (payload.error && payload.error[0]?.error[0]?.code === 400) {
      notify(true, <Fragment>{SLOT_OVERLAP_TOAST_MESSAGE}</Fragment>, uuid(), () => {}, 'error');
    }
    handleDrawerClose();
  };

  const handleCancelClick = async (param: ISlotBooked) => {
    const data = slotsState.booked.find((slot) => slot.id.toString() === param.id.toString());
    if (data) {
      const clickableTime = moment();
      const endTime = moment(data.end);
      if (endTime.isAfter(clickableTime)) {
        const response = await dispatch(
          cancelBookedSlot({
            slotId: param.id,
            cancelComments: param.cancelComments,
            makeSlotAvailable: param.makeSlotAvailable,
            cancelOption: param.cancelOption
          })
        );
        if (response.payload.data) {
          notify(true, <Fragment>{SLOT_CANCEL_TOAST_MESSAGE}</Fragment>, uuid());
          setSlotStates((prevState) => ({
            ...prevState,
            cancelled: [...prevState.cancelled, data]
          }));
        }
      }
    }
  };

  const updateBookedAndAvailableSlots = (eventType: string) => {
    const { events } = props;
    const slotEventType = events.filter((event) => event.type === eventType);
    const eventCondition = eventType === SCHEDULE_EVENTS_TYPE.AVAILABLE;
    const slots = eventCondition
      ? getAvailableFromRedux(slotEventType)
      : getBookedFromRedux(slotEventType);
    setSlotStates((prevState) => ({
      ...prevState,
      [eventCondition ? 'available' : 'booked']: slots
    }));
  };

  const updateSlots = () => {
    const { events } = props;
    const requested = events.filter((event) => event.type === SCHEDULE_EVENTS_TYPE.REQUESTED);
    const existing = events.filter((event) => event.type === SCHEDULE_EVENTS_TYPE.EXISTING);
    const cancelled = events.filter((event) => event.type === SCHEDULE_EVENTS_TYPE.CANCELLED);

    setSlotStates((prevState) => ({
      ...prevState,
      existing,
      cancelled,
      requested
    }));
  };
  useEffect(() => {
    updateBookedAndAvailableSlots(SCHEDULE_EVENTS_TYPE.BOOKED);
  }, [reduxScheduleState.bookedEvents, props.events]);

  useEffect(() => {
    updateBookedAndAvailableSlots(SCHEDULE_EVENTS_TYPE.AVAILABLE);
  }, [reduxScheduleState.availableEvents, props.events]);

  useEffect(() => {
    updateSlots();
  }, [props.events]);

  useEffect(() => {
    setSlotStates((prevState) => ({ ...prevState, header: moment(headerDate) }));
  }, [props.headerDate]);

  return (
    <Drawer
      bodyStyles="slot-events-drawer-body"
      isOpen={open}
      headerStyles="drawer-header-wrapper"
      headerProps={<DrawerHeader title={header.format(timeStamps.DAY_AND_DATE)} />}
      onClose={handleDrawerClose}>
      {!!booked.length && (
        <SlotBookedContainer
          events={slotsState.booked}
          onCardCancelClick={handleCancelClick}
          showMakeSlotAvailableCheckbox={showMakeSlotAvailableCheckbox}
          showCancelledBy={props.showCancelledBy}
        />
      )}
      {!!available.length && (
        <SlotAvailableContainer
          events={available}
          onDeleteClick={handleAvailableSlotsDelete}
          onSaveClick={handleSaveClick}
        />
      )}
      {!!requested.length && <SlotRequestedContainer events={requested} />}
      {!!existing.length && <GoogleEventsContainer events={existing} />}
      {!!cancelled.length && <SlotCancelledContainer events={cancelled} />}
      {!available.length && !booked.length && !existing.length && !cancelled.length && (
        <div className="no-events-container">
          <ImageComponent src={Images.nothingHere} />
          <Typography customStyle="no-events-text">Nothing Here!</Typography>
        </div>
      )}
      <Loader loading={reduxScheduleState.eventsLoading} customClass="loading-wrapper" />
    </Drawer>
  );
}

SlotEventsDrawer.defaultProps = {
  showMakeSlotAvailableCheckbox: false,
  showCancelledBy: false
};

export default SlotEventsDrawer;
