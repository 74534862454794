import React, { useEffect, useState } from 'react';
import Typography from '../../../../core/shared-components/typography';
import Images from '../../../../../assets/images';
import Button from '../../../../core/shared-components/button';
import {
  IUpcomingInterviewData,
  toggleCancelModal
} from '../../../../redux/slices/adminDashboardSlice';
import ImageComponent from '../../../../core/shared-components/image';
import { useAppSelector, useAppThunkDispatch } from '../../../../redux/hooks';
import InterviewCancelCard, { ICancel } from '../interview-cancel-card';
import UpcomingInterviews from '.';
import moment from 'moment';
interface IUpcomingInterviewDetails {
  upcomingInterview: IUpcomingInterviewData;
  onCancel?: (interview: ICancel) => void;
}

const UpcomingInterviewDetails = (props: IUpcomingInterviewDetails) => {
  const { avatarGray, clockGray, techStackGray, upcomingEmptyCheck } = Images;
  const { upcomingInterview } = props;
  const dispatch = useAppThunkDispatch();

  const handleCancelBooking = () => {
    dispatch(toggleCancelModal(upcomingInterview));
  };
  return (
    <>
      <Typography variant="headline-16" customStyle="interview-date">
        {moment(upcomingInterview.date).format('DD MMMM YYYY')}
      </Typography>
      <div className="interview-detail-card">
        <div className="candidate-details">
          <div className="name">
            <ImageComponent src={avatarGray} />
            <Typography variant="headline-16">{upcomingInterview.candidateName}</Typography>
          </div>
          <div className="timings">
            <ImageComponent src={clockGray} />
            <Typography customStyle="times">{`${moment(upcomingInterview.fromTime).format(
              'hh:mm A'
            )} - ${moment(upcomingInterview.toTime).format('hh:mm A')}`}</Typography>
            <ImageComponent src={techStackGray} />
            <Typography customStyle="times">
              {upcomingInterview.techStack} {upcomingInterview.level}
            </Typography>
          </div>
        </div>
        <Button variant="text" customStyle="cancel-button" onClick={handleCancelBooking}>
          Cancel Booking
        </Button>
      </div>
    </>
  );
};
export default UpcomingInterviewDetails;
