import React from 'react';
import Typography from 'app/core/shared-components/typography';
import SlotEfficiencyGraph from 'app/pages/Dashboard/components/slot-efficiency-graph';
import './index.style.scss';

const SlotEfficiency = () => (
  <div className="slot-efficiency-widget-wrapper">
    <div className="slot-efficiency-header">
      <Typography customStyle="slot-efficiency-title" variant="headline-16">
        Slot Efficiency
      </Typography>
    </div>
    <SlotEfficiencyGraph />
  </div>
);

export default SlotEfficiency;
