import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import { getLOI } from 'app/redux/slices/sharedSlice';
import { fetchDashboardBookedSlots } from 'app/redux/slices/slotsSlice';
import { SETTINGS } from 'helpers/appRoutes';
import { formatTime } from 'helpers/dateHelper';
import { ReactComponent as LoaderSpinner } from 'assets/images/loader-spinner.svg';
import NoRequestedSlot from 'assets/images/no-result-found.svg';
import Button from 'app/core/shared-components/button';
import CustomSelect from 'app/core/shared-components/customSelect';
import ImageComponent from 'app/core/shared-components/image';
import { ISelectValue } from 'app/core/shared-components/select';
import TabComponent from 'app/core/shared-components/tab';
import Typography from 'app/core/shared-components/typography';
import { ILevelOfInterviewType } from 'app/services/user-response.types';
import BookedSlot from '../booked-slot';
import { techLevelStyles } from '../slot-efficiency-graph/techLevel.style';
import './index.style.scss';

const BookedSlots = () => {
  const [bookedSlotsState, setBookedSlotsState] = useState({
    techStackFilter: {} as ISelectValue,
    levelFilter: {} as ILevelOfInterviewType,
    selectedTabIndex: 0
  });

  const { techStackLevelSet } = useAppSelector((state) => state.user.userData);
  const { levelOfInterview, isSharedError, isLevelLoading, isTechLoading } = useAppSelector(
    (state) => state.shared
  );

  const { isBookedLoading, bookedSlots } = useAppSelector((state) => state.slots);

  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();

  const { techStackFilter, levelFilter, selectedTabIndex } = bookedSlotsState;

  const techStackList = techStackLevelSet?.map((techStack) => ({
    id: techStack.techStackId,
    label: techStack.techStackName
  }));

  const handleTechStackChange = (params: ISelectValue | ISelectValue[] | null) => {
    if (!Array.isArray(params) && params !== null) {
      setBookedSlotsState((prevState) => ({
        ...prevState,
        techStackFilter: params
      }));
    }
  };

  const handleTabSelect = (index: number) => {
    setBookedSlotsState((prevState) => ({
      ...prevState,
      selectedTabIndex: index,
      levelFilter: levelOfInterview[index]
    }));
  };

  const handleAddTechStack = () => {
    navigate(SETTINGS);
  };

  useEffect(() => {
    if (!levelOfInterview.length) {
      dispatch(getLOI());
    }
  }, []);

  useEffect(() => {
    techStackLevelSet.length &&
      setBookedSlotsState((prevState) => ({
        ...prevState,
        techStackFilter: {
          id: techStackLevelSet[0]?.techStackId,
          label: techStackLevelSet[0]?.techStackName
        }
      }));
  }, [techStackLevelSet.length]);

  useEffect(() => {
    levelOfInterview.length &&
      setBookedSlotsState((prevState) => ({
        ...prevState,
        levelFilter: levelOfInterview[0]
      }));
  }, [levelOfInterview.length]);

  useEffect(() => {
    dispatch(
      fetchDashboardBookedSlots({
        levelId: levelFilter?.id?.toString(),
        techStackId: techStackFilter?.id?.toString(),
        dateTime: [
          {
            from: formatTime('12:00 AM', new Date()),
            to: formatTime('11:59 PM', new Date())
          }
        ]
      })
    );
  }, [techStackFilter?.id, levelFilter?.id]);

  return (
    <div className="booked-slots">
      <div className="booked-slots-header">
        <Typography customStyle="booked-slots-title" variant="headline-16">
          Interviews Scheduled - Today
        </Typography>
        <CustomSelect
          dropdownOptions={techStackList}
          selectedOptions={techStackFilter}
          onChange={handleTechStackChange}
          customStyles={techLevelStyles}
          customClass="no-flex"
          showDropdownIndicator
        />
      </div>
      <div className="booked-slots-container">
        <TabComponent
          tabs={levelOfInterview?.map((levels) => {
            return {
              title: levels?.level,
              tabData: (
                <>
                  {techStackLevelSet?.length < 1 ? (
                    <div className="no-slots-found">
                      <ImageComponent src={NoRequestedSlot} />
                      <Typography variant="headline-24">No slots found</Typography>
                      <Typography variant="headline-16" customStyle="no-slot-message">
                        Add a Tech Stack to view the slots
                      </Typography>
                      <Button
                        customStyle="slot-btn"
                        variant="contained"
                        onClick={handleAddTechStack}>
                        Add Tech Stack
                      </Button>
                    </div>
                  ) : (
                    <div className="slot-details">
                      {isBookedLoading ? (
                        <LoaderSpinner className="loading-spinner" />
                      ) : (
                        <>
                          {bookedSlots?.data?.interviewSlotDateDTOList?.map((slotList, index) => (
                            <BookedSlot key={index} slots={slotList?.interviewSlotTimeDTOList} />
                          ))}
                          {(bookedSlots?.data?.interviewSlotDateDTOList === null ||
                            bookedSlots?.data?.interviewSlotDateDTOList.length <= 0) && (
                            <div className="no-slots-available">
                              <ImageComponent src={NoRequestedSlot} />
                              <Typography variant="headline-24">No Slots Found</Typography>
                              <Typography variant="headline-16" customStyle="no-slot-message">
                                No slots have been booked
                              </Typography>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </>
              )
            };
          })}
          onSelect={handleTabSelect}
          selectedIndex={selectedTabIndex}></TabComponent>
      </div>
    </div>
  );
};

export default BookedSlots;
