import React, { Fragment, useCallback } from 'react';
import { HeaderGroup } from 'react-table';
import ImageComponent from '../../../image';
import Images from '../../../../../../assets/images';

interface IHeaders {
  headers: HeaderGroup<object>[];
  sortColumns: boolean;
  noHeaderBorder: boolean;
}

const Headers = (props: IHeaders) => {
  const { headers, sortColumns, noHeaderBorder } = props;

  const { arrowDown, arrowUp } = Images;

  const generateSortingIndicator = useCallback(
    (column: any) => {
      return column.isSorted ? (
        <ImageComponent
          src={column.isSortedDesc ? arrowDown : arrowUp}
          customClass="sort-indicator"
        />
      ) : null;
    },
    [headers]
  );

  return (
    <Fragment>
      {headers.map((column: any, index: number) => {
        const columnProps = sortColumns
          ? column?.getHeaderProps(column.getSortByToggleProps())
          : column?.getHeaderProps();
        return (
          <Fragment key={index}>
            {column.render('Header') && (
              <th
                {...columnProps}
                key={index}
                className={`table-th-cell ${noHeaderBorder ? 'no-border' : ''}`}>
                {column.render('Header')}
                {sortColumns && generateSortingIndicator(column)}
              </th>
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default Headers;
