import { debounce, DebouncedFunc } from 'lodash';

export default class ServerSentEvent {
  private _source: EventSource | undefined;
  private _url: string | URL;
  private _options: EventSourceInit | undefined;
  private _autoRetry: boolean;
  private _retryDelay: number;
  private _debounceCall?: DebouncedFunc<() => void>;
  onConnect?: (event: Event) => void;
  onMessage?: (event: MessageEvent) => void;
  onError?: (error: any) => void;

  constructor(url: string | URL, autoRetry = true, options?: EventSourceInit) {
    this._url = url;
    this._options = options;
    this._autoRetry = autoRetry;
    this._retryDelay = 1000;
  }

  private _disconnect() {
    try {
      this._source?.close();
      this._source = undefined;
    } catch (error) {
      console.error('Connection closed abruptly', error);
    }
  }

  openConnection() {
    if (this._source && typeof this._source !== undefined) {
      this._disconnect();
    }

    this._source = new EventSource(`${this._url}`, this._options);

    this._source.onopen = (event: Event) => {
      this._retryDelay = 1000;
      this.onConnect && this.onConnect(event);
    };

    this._source.onmessage = (event: MessageEvent) => {
      this.onMessage && this.onMessage(event);
    };

    this._source.onerror = (event: any) => {
      switch (event.target.readyState) {
        case EventSource.CONNECTING:
          console.warn('Retry connection', event);
          break;
        case EventSource.CLOSED:
          if (this._source && this._autoRetry && this._retryDelay <= 60 * 3000) {
            this._retryDelay = this._retryDelay * 2;
            this._debounceCall = debounce(() => {
              this.openConnection();
            }, this._retryDelay);
            this._debounceCall();
          } else {
            this.closeConnection();
            this.onError && this.onError(event);
          }
          break;
        default:
          this.closeConnection();
          this.onError && this.onError(event);
      }
    };
  }

  closeConnection() {
    this._disconnect();
    this._debounceCall?.cancel();
  }
}
