import Typography from '../../../../core/shared-components/typography';
import React, { useEffect, useState } from 'react';
import './index.style.scss';
import userIcon from '../../../../../assets/images/bold-user.svg';
import grayTickIcon from '../../../../../assets/images/gray-tick.svg';
import ImageComponent from '../../../../core/shared-components/image';
import { useAppSelector, useAppThunkDispatch } from '../../../../redux/hooks';
import { getEmployeeCount } from '../../../../redux/slices/adminDashboardSlice';

const EmployeeSummary = () => {
  const [userCount, setUserCount] = useState<any>();
  const dispatch = useAppThunkDispatch();
  const { employeeCount } = useAppSelector((state) => state.adminDashboard);
  useEffect(() => {
    dispatch(getEmployeeCount());
  }, []);
  useEffect(() => {
    setUserCount((prev: any) => ({
      ...prev,
      usersCount: employeeCount
    }));
  }, [employeeCount]);

  return (
    <div className="card-wrapper">
      <div>
        <Typography variant="headline-16" customStyle="summary-text">
          Employee Summary
        </Typography>
      </div>
      <div className="total-users-div">
        <div className="user-box">
          <div className="totalUsers-heading">
            Total Active TA
            <div className="greenbar"></div>
          </div>
          <div className="countSection">
            <div className="count">{userCount?.usersCount?.recruiterCount}</div>
            <div>
              <ImageComponent src={userIcon} />
            </div>
          </div>
        </div>
        <div className="user-box">
          <div className="totalUsers-heading-blue">
            Total Active Panellists
            <div className="bluebar"></div>
          </div>
          <div className="countSection">
            <div className="count">{userCount?.usersCount?.panelCount}</div>
            <div>
              <ImageComponent src={grayTickIcon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmployeeSummary;
