import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ILevelsData } from '../../pages/LevelOfInterview';
import {
  addLevelService,
  deleteLevelService,
  editLevelService,
  userListLevelSearchService,
  userListLevelService
} from '../../services/levelOfInterview.service';
import {
  ADD_LEVEL,
  DELETE_LEVEL,
  EDIT_LEVEL,
  LEVEL_OF_PANNEL,
  LEVEL_OF_USERS_LIST,
  USER_SEARCH_LIST
} from '../constant';

interface LevelOfInterviewState {
  isLoading: boolean;
  isError: boolean;
  currentDeletion: ILevelsData;
  isToastVisible: boolean;
  userList?: [];
  count?: number;
  userListLoading?: boolean;
}

interface LevelOfPanelUserListType {
  id: string;
  searchText: string;
}

const initialState: LevelOfInterviewState = {
  isLoading: false,
  isError: false,
  currentDeletion: {} as ILevelsData,
  isToastVisible: false,
  userList: [],
  count: 0,
  userListLoading: false
};

interface IEditLevel {
  id: string;
  level: string;
}

export const editLevel = createAsyncThunk(
  EDIT_LEVEL,
  async function editLevel(editedData: IEditLevel, thunkAPI) {
    const data = await editLevelService(editedData?.id, editedData?.level?.trim());
    if (!data?.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);
export const userListLevel = createAsyncThunk(
  LEVEL_OF_USERS_LIST,
  async function userListLevel(levelId: number, thunkAPI) {
    const data = await userListLevelService(levelId);
    return !data?.error ? data : thunkAPI.rejectWithValue(data);
  }
);
export const userListLevelSearch = createAsyncThunk(
  USER_SEARCH_LIST,
  async function userListLevelSearch(levelData: LevelOfPanelUserListType, thunkAPI) {
    const data = await userListLevelSearchService(levelData?.id, levelData?.searchText);
    return !data?.error ? data : thunkAPI.rejectWithValue(data);
  }
);

export const addLevel = createAsyncThunk(
  ADD_LEVEL,
  async function addLevel(levelData: { level: string }, thunkAPI) {
    const data = await addLevelService(levelData?.level?.trim());
    if (!data?.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const deleteLevel = createAsyncThunk(
  DELETE_LEVEL,
  async function deleteLevel(id: string, thunkAPI) {
    const data = await deleteLevelService(id);
    if (data?.data !== null) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

const builderFunc = (
  state: LevelOfInterviewState,
  loading: boolean,
  userList: [] | undefined,
  count: number | undefined,
  error: boolean
) => {
  state.userListLoading = loading;
  state.userList = userList;
  state.count = count;
  state.isError = error;
};

export const levelOfInterviewSlice = createSlice({
  name: LEVEL_OF_PANNEL,
  initialState,
  reducers: {
    setCurrentDeletion: (state, action) => {
      state.currentDeletion = action.payload;
    },
    setIsToastVisible: (state, action) => {
      state.isToastVisible = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(editLevel.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(editLevel.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(editLevel.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addLevel.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(addLevel.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(addLevel.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(deleteLevel.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(deleteLevel.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(deleteLevel.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(userListLevel.fulfilled, (state, { payload }) =>
      builderFunc(state, false, payload?.data?.panelList, payload?.data?.count, false)
    );
    builder.addCase(userListLevel.pending, (state, { payload }) =>
      builderFunc(state, true, undefined, undefined, false)
    );
    builder.addCase(userListLevel.rejected, (state, { payload }) =>
      builderFunc(state, false, undefined, undefined, true)
    );
    builder.addCase(userListLevelSearch.fulfilled, (state, { payload }) =>
      builderFunc(state, false, payload?.data?.panelList, payload?.data?.count, false)
    );
    builder.addCase(userListLevelSearch.pending, (state, { payload }) =>
      builderFunc(state, true, undefined, undefined, false)
    );
    builder.addCase(userListLevelSearch.rejected, (state, { payload }) =>
      builderFunc(state, false, undefined, undefined, true)
    );
  }
});

export const { setIsToastVisible, setCurrentDeletion } = levelOfInterviewSlice.actions;
export default levelOfInterviewSlice.reducer;
