import React, { Component, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ImageComponent from '../image';
import Typography from '../typography';
import { v4 as uuidv4 } from 'uuid';
import './index.style.scss';

interface ISingleItem {
  icon: string;
  title: string;
  path: string;
}

interface INavPopupProps {
  items: ISingleItem[];
  customStyles?: string;
  sendSelectedNavData: (value: boolean) => void;
}

interface INavPopupStates {
  isPopupOpen: boolean;
}

function NavPopup(props: INavPopupProps) {
  const [navPopupState, setNavPopUpStates] = useState<INavPopupStates>({ isPopupOpen: true });

  function onPopupClose() {
    setNavPopUpStates({
      isPopupOpen: false
    });
    props.sendSelectedNavData(false);
  }

  const { items, customStyles } = props;
  const { isPopupOpen } = navPopupState;

  return (
    <>
      {isPopupOpen && (
        <div className="bottom-navigation-popup">
          {items.slice(2).map((item: ISingleItem, index: number) => (
            <NavLink
              key={uuidv4()}
              to={item.path}
              id={index.toString()}
              onClick={onPopupClose}
              className={({ isActive }) =>
                isActive ? 'bottom-nav-item selected' : 'bottom-nav-item link'
              }>
              <div className="bottom-navigation-item">
                <ImageComponent src={item.icon} customClass="nav-image" />
                <Typography variant="text" customStyle="nav-text ">
                  {item.title}
                </Typography>
              </div>
            </NavLink>
          ))}
        </div>
      )}
    </>
  );
}

export default NavPopup;
