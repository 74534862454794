import React, { useCallback, useEffect, useMemo, useState } from 'react';
import '../../index.style.scss';
import { Navigate, ToolbarProps, Views } from 'react-big-calendar';
import { SCHEDULE_EVENTS_TYPE } from 'helpers/types';
import { useAppSelector } from 'app/redux/hooks';
import moment from 'moment';
import ImageComponent from 'app/core/shared-components/image';
import Images from 'assets/images';
import ColorIndicator from 'app/pages/Schedule/components/color-indicator';
import { timeStamps } from 'helpers/timeStamp.constants';

function CustomToolbarComponent(props: ToolbarProps) {
  const reduxScheduleState = useAppSelector((state) => state.schedule);
  const state = useMemo(() => {
    const { currentSelectedUser, currentUser } = reduxScheduleState;
    if (currentUser?.id?.toString() === currentSelectedUser?.id.toString())
      return [
        SCHEDULE_EVENTS_TYPE.AVAILABLE,
        SCHEDULE_EVENTS_TYPE.BOOKED,
        SCHEDULE_EVENTS_TYPE.CANCELLED,
        SCHEDULE_EVENTS_TYPE.REQUESTED,
        SCHEDULE_EVENTS_TYPE.EXISTING
      ];
    else
      return [
        SCHEDULE_EVENTS_TYPE.AVAILABLE,
        SCHEDULE_EVENTS_TYPE.BOOKED,
        SCHEDULE_EVENTS_TYPE.CANCELLED,
        SCHEDULE_EVENTS_TYPE.REQUESTED
      ];
  }, [reduxScheduleState.currentSelectedUser, reduxScheduleState.currentUser]);

  const handleNext = useCallback(() => {
    props.onNavigate(Navigate.NEXT);
  }, []);

  const handlePrevious = useCallback(() => {
    props.onNavigate(Navigate.PREVIOUS);
  }, []);

  const getCurrentHeader = useCallback(() => {
    const momentCurrentDate = moment(props.date);
    if (props.view === Views.WEEK) {
      const startOfWeek = moment(momentCurrentDate).startOf('week');
      const endOfWeek = moment(momentCurrentDate).endOf('week');
      return `${startOfWeek.format(timeStamps.D_MMM)} - ${endOfWeek.format(timeStamps.D_MMM_YY)}`;
    } else if (props.view === Views.DAY) {
      return `${momentCurrentDate.format(timeStamps.D_MMMM_YYYY)}`;
    } else if (props.view === Views.MONTH) {
      return `${momentCurrentDate.format(timeStamps.MMMM_YYYY)}`;
    }
    return `${props.date}`;
  }, [props.date, props.view]);

  return (
    <div className="calendar-header">
      <div className="cal-header-upper">
        <div className="calendar-header-left">
          <div className="calendar-navigation-action">
            <div className="chevron-wrapper" onClick={handlePrevious}>
              <ImageComponent className="range-changer-btn" src={Images.leftChevron} />
            </div>
            <div className="chevron-wrapper" onClick={handleNext}>
              <ImageComponent className="range-changer-btn" src={Images.rightChevron} />
            </div>
          </div>
          <div className="current-header">{getCurrentHeader()}</div>
        </div>
        <ColorIndicator optionsArray={state} />
      </div>
      {props.view === Views.DAY && (
        <div className="day-view-highlight">
          <div className="day-view-highlight-date">{moment(props.date).format(timeStamps.D)}</div>
          <div className="day-view-highlight-day">
            {moment(props.date).format(timeStamps.DDDD).toUpperCase()}
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(CustomToolbarComponent);
