import React, { ChangeEvent, Fragment, SyntheticEvent } from 'react';
import Button from 'app/core/shared-components/button';
import CheckBox from 'app/core/shared-components/checkbox';
import ImageComponent from 'app/core/shared-components/image';
import Input from 'app/core/shared-components/input-box/inputComponent/inputComponent';
import Radio from 'app/core/shared-components/radio-group';
import Typography from 'app/core/shared-components/typography';
import { ISlotUserDetail } from 'app/pages/Slots/components/slot-carousel';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  cancelSlot,
  fetchAllSlots,
  fetchBookedSlots,
  toggleToastVisibility
} from 'app/redux/slices/slotsSlice';
import { ReactComponent as LoaderSpinner } from 'assets/images/loader-spinner.svg';
import CloseIcon from 'assets/images/modal-close-icon.svg';
import {
  ALL,
  BOOKED,
  BOOKED_BY,
  BOOKED_SLOT,
  CANCELLED_BY,
  CANCELLED_SLOT,
  CANCEL_BOOKING,
  CANDIDATE,
  CANDIDATE_BUSY,
  CANDIDATE_CANCEL_ENUM,
  INTERVIEW_DETAILS,
  MODAL_CANCEL_BOOKING,
  MODAL_PANEL,
  OTHERS_ENUM,
  OTHER_BUSY,
  PANEL,
  PANEL_BUSY,
  PANEL_CANCEL_ENUM,
  REASON_FOR_CANCELLATION,
  TECHSTACK_LEVEL
} from 'helpers/constants';
import { MAKE_SLOT_AVAILABLE, SLOT_CANCEL_TOAST_MESSAGE } from 'helpers/messages.constants';
import { notify } from 'helpers/toastHelper';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import './index.style.scss';
import Images from 'assets/images';

interface ISlotRequestCardProps {
  date?: string;
  panelDetails: ISlotUserDetail[] | ISlotUserDetail;
  candidateName: string;
  recruiterName?: string;
  handleClose?: () => void;
  showTag?: boolean;
  cancelReason?: string;
  cancelledBy?: string;
  cancelComments?: string;
  readOnly?: boolean;
  tag?: string;
  zohoRecruitUrl?: string;
  isSearch?: boolean;
  dateFilter?: {
    from: string;
    to: string;
  }[];
  techStackInterviewLevel: {
    techStackId: string;
    techStack: string;
    levelId: string;
    level: string;
  };
  slotType?: string;
}

const SlotCancellationModal = ({
  date,
  handleClose,
  panelDetails,
  cancelReason,
  candidateName,
  cancelledBy,
  readOnly,
  showTag,
  isSearch,
  tag,
  dateFilter,
  cancelComments,
  zohoRecruitUrl,
  techStackInterviewLevel,
  recruiterName,
  slotType
}: ISlotRequestCardProps) => {
  const dispatch = useAppThunkDispatch();

  const hookForm = useForm({
    defaultValues: {
      markAsAvailable: false,
      remark: '',
      reason: CANDIDATE_BUSY
    },
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const { setValue, control, setError, register, handleSubmit, reset, watch } = hookForm;

  let panelDetail: ISlotUserDetail;
  if (Array.isArray(panelDetails)) {
    const [firstPanelDetail, ...rest] = panelDetails;
    panelDetail = firstPanelDetail;
  } else {
    panelDetail = panelDetails;
  }
  const { filterValue, isFetchSlotLoading, currentSlots } = useAppSelector((state) => state.slots);

  const { bookedSlot, cancelledSlot, availableSlot } = Images;

  const handleCandidateNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('remark', `${event.target.value}`);
  };

  const handleCheckboxClick = (event: SyntheticEvent, check: boolean) => {
    event.stopPropagation();
    setValue('markAsAvailable', check);
  };

  const handleToastClose = () => {
    dispatch(toggleToastVisibility(''));
  };

  const handleSlotCancellation = () => {
    const cancelSlotBody = {
      userId: panelDetail?.userId || currentSlots?.data?.userDetails?.id,
      slotId: panelDetail?.slotId,
      cancelComments: watch('remark')?.trim(),
      cancelOption:
        watch('reason') === CANDIDATE_BUSY
          ? CANDIDATE_CANCEL_ENUM
          : watch('reason') === PANEL_BUSY
          ? PANEL_CANCEL_ENUM
          : OTHERS_ENUM,
      makeSlotAvailable: watch('markAsAvailable')
    };
    dispatch(cancelSlot(cancelSlotBody)).then(({ payload }) => {
      if (payload?.data) {
        if (!isSearch && slotType === BOOKED) {
          dispatch(fetchBookedSlots(filterValue));
        } else if (isSearch && slotType === BOOKED) {
          dispatch(
            fetchBookedSlots({
              userId: currentSlots?.data?.userDetails?.id?.toString(),
              dateTime: dateFilter
            })
          );
        } else if (slotType === ALL && isSearch) {
          dispatch(
            fetchAllSlots({
              userId: currentSlots?.data?.userDetails?.id?.toString(),
              dateTime: dateFilter
            })
          );
        } else if (slotType === ALL && !isSearch) {
          dispatch(fetchAllSlots(filterValue));
        }
        notify(true, SLOT_CANCEL_TOAST_MESSAGE, uuidv4().toString(), handleToastClose);
        handleClose && handleClose();
      }
    });
  };

  const handleReasonChange = (value: string) => {
    setValue('reason', `${value}`);
  };

  return (
    <div className="slot-cancellation-card">
      {isFetchSlotLoading ? (
        <div className={readOnly ? 'slot-loading-wrapper cancelled' : 'slot-loading-wrapper'}>
          <LoaderSpinner className="loading-spinner" />
        </div>
      ) : (
        <Fragment>
          <div className="slot-cancel-header">
            <div className="slot-details">
              <div
                className={!readOnly ? 'slot-header-image bg-green' : 'slot-header-image bg-red'}>
                <ImageComponent src={!readOnly ? bookedSlot : cancelledSlot} />
              </div>
              <div className="slot-header-details">
                <div className="slot-header-name">{!readOnly ? BOOKED_SLOT : CANCELLED_SLOT}</div>
                <Typography variant="headline-16" customStyle="date-time">
                  {date}
                </Typography>
              </div>
            </div>
            <ImageComponent src={CloseIcon} customClass="slot-delete-btn" onClick={handleClose} />
          </div>
          <div className="interview-details">
            <Typography variant="headline-16" customStyle="title">
              {INTERVIEW_DETAILS}
            </Typography>
            <div className="candidate-details">
              <div className="common-details heading">
                {TECHSTACK_LEVEL}
                <div className="detail-section">{`${techStackInterviewLevel?.techStack} - ${techStackInterviewLevel?.level}`}</div>
              </div>
              <div className="common-details heading">
                {CANDIDATE}
                <div className="detail-section">{`${candidateName}`}</div>
              </div>
            </div>
            <div className="candidate-details">
              <div className="common-details heading">
                {MODAL_PANEL}
                <div className="detail-section panel-name">
                  {`${panelDetail.firstName} ${panelDetail.lastName}`}
                </div>
              </div>
              {!readOnly ? (
                <div className="common-details heading">
                  {BOOKED_BY}
                  <div className="detail-section">{`${recruiterName}`}</div>
                </div>
              ) : (
                <div className="common-details heading">
                  {CANCELLED_BY}
                  <div className="detail-section">{`${cancelledBy}`}</div>
                </div>
              )}
            </div>
          </div>
          <div className="separator-div"></div>
          <form onSubmit={handleSubmit(handleSlotCancellation)}>
            <div className="form-field">
              <Typography variant="input-label" customStyle="input-label">
                {REASON_FOR_CANCELLATION}
                {!readOnly && <span>*</span>}
              </Typography>
              {readOnly && (
                <Typography variant="headline-16" customStyle="text">
                  {cancelReason === CANDIDATE_CANCEL_ENUM
                    ? CANDIDATE_BUSY
                    : cancelReason === PANEL_CANCEL_ENUM
                    ? PANEL_BUSY
                    : OTHER_BUSY}
                </Typography>
              )}
              {!readOnly && (
                <div className="radio-input-field">
                  <Controller
                    control={control}
                    name="reason"
                    render={({}) => (
                      <Radio
                        {...register('reason', {
                          required: true,
                          onChange: handleReasonChange
                        })}
                        labelArray={[CANDIDATE_BUSY, PANEL_BUSY, OTHER_BUSY]}
                        setCurrentValue={handleReasonChange}
                        currentValue={watch('reason')}
                      />
                    )}
                  />
                </div>
              )}
            </div>

            {(!readOnly || (readOnly && cancelComments)) && (
              <div className="form-field">
                <Typography
                  variant="input-label"
                  customStyle={!readOnly ? 'input-label remark' : 'input-label'}>
                  Remark{!readOnly && watch('reason') === OTHER_BUSY && <span>*</span>}
                </Typography>

                {readOnly && (
                  <Typography variant="headline-16" customStyle="text">
                    {cancelComments}
                  </Typography>
                )}
                {!readOnly && (
                  <div className="name-input-field">
                    <Controller
                      control={control}
                      name="remark"
                      render={({}) => (
                        <Input
                          {...register('remark', {
                            required: false,
                            onChange: handleCandidateNameChange
                          })}
                          autoFocus
                          type="text"
                          autoComplete="off"
                        />
                      )}
                    />
                  </div>
                )}
              </div>
            )}

            <div className="modal-footer">
              {!readOnly && watch('reason') !== PANEL_BUSY && (
                <Controller
                  control={control}
                  name="markAsAvailable"
                  render={({}) => (
                    <CheckBox
                      label={MAKE_SLOT_AVAILABLE}
                      customClass="label"
                      checked={watch('markAsAvailable')}
                      handleClick={handleCheckboxClick}
                    />
                  )}
                />
              )}
              {!readOnly && (
                <div className="book-btn">
                  <Button
                    variant="contained"
                    customStyle="mark-booked-button"
                    disabled={
                      (watch('reason') === OTHER_BUSY && !watch('remark').trim()) ||
                      !watch('reason')
                    }>
                    {MODAL_CANCEL_BOOKING}
                  </Button>
                </div>
              )}
            </div>
          </form>
        </Fragment>
      )}
    </div>
  );
};
export default SlotCancellationModal;
