export const INTERVIEW_SLOT_COUNT = '/interview/slot/count';
export const PROJECT_USERS_LIST = '/admin/project/panel/list';
export const PROJECT_USERS_SEARCH_LIST = '/admin/project/panel/search';
export const TS_USERS_PANEL_LIST = '/admin/tech-stack/panel/list';
export const TS_USERS_RECRUITER_LIST = '/admin/tech-stack/recruiter/list';
export const TS_USERS_SEARCH_PANEL_LIST = '/admin/tech-stack/panel/search';
export const TS_USERS_SEARCH_RECRUITER_LIST = '/admin/tech-stack/recruiter/search';
export const PANEL_ROUTE = '/panel';
export const ONBOARD_ROUTE = '/onboard';
export const HOME_ROUTE = '/home';
export const LOGIN_ROUTE = '/login';
export const RECRUITER_ROUTE = '/talentAcquisition';
export const SETTINGS_ROUTE = '/settings';
export const LOP_USERS_LIST = '/admin/interview-level/panel/list';
export const LOP_USERS_SEARCH_LIST = '/admin/interview-level/panel/search';
export const DELETE_ALL_SLOTS = '/interview/slot/deleteAll';
export const NOTIFICATION_COUNT = '/user/push/notification/count';
export const GENERAL_NOTIFICATION = '/user/general/notification/list/';
export const ACTIVITY_NOTIFICATION = '/user/activity-log/list/';
export const READ_NOTIFICATION = '/user/notification/read';
export const READ_ALL_NOTIFICATION = '/user/notification/read/all';
export const INTERVIEW_SLOT_REQUEST = '/interview/slot/request';
export const INTERVIEW_SLOT_REQUEST_VALIDATE = '/interview/slot/request/validate';
export const AVAILABLE_SLOTS = '/interview/slot/available';
export const BOOKED_SLOTS = '/interview/slot/booked';
export const ALL_SLOTS = '/interview/slot/all';
export const CANCELLED_SLOTS = '/interview/slot/cancelled';
export const CANCEL_SINGLE_SLOT = '/interview/slot/cancel';
export const AVAILABLE_SLOT_DETAIL = '/interview/slot/detail/available';
export const ADD_SLOT = '/interview/slot/add';
export const SLOT_DETAIL = '/interview/slot/detail';
export const REQUESTED_SLOT_LIST = '/interview/slot/request/list';
export const SLOTS_PANEL_SEARCH = '/user/panel/search';
export const BOOK_SLOT = '/interview/slot/book';
export const SLOT_REQUEST_BOOK = '/interview/slot/request/book';
export const SLOT_REQUEEST_DELETE = '/interview/slot/request/delete';
