export const timeStamps = {
  DDD: 'ddd',
  DD_MM_YY: 'DD MM YY',
  D: 'D',
  D_MMM: 'D MMM',
  D_MMM_YY: "D MMM 'YY",
  DD_MMMM_YYYY: 'DD MMMM YYYY',
  DD_MMM_YYYY: 'DD MMM YYYY',
  D_MMMM_YYYY: "D MMMM, YYYY'",
  MMMM_YYYY: 'MMMM YYYY',
  DDDD: 'dddd',
  HH_MM_a: 'hh:mm a',
  SLOT_HH_MM_a: 'hh:mma',
  HH_MM_A: 'hh:mm A',
  SLOT_HH_MM_A: 'hh:mmA',
  DAY_AND_DATE: 'dddd, D MMMM YYYY',
  DD_MMM: 'DD MMM',
  DO_MMMM_YYYY: 'Do MMMM YYYY',
  YYYY_MM_DD: 'YYYY-MM-DD',
  DATE_TIME: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  DD_MM_YYYY: 'DD/MM/YYYY'
};
