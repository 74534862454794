import { LOP_USERS_LIST, LOP_USERS_SEARCH_LIST } from '../../helpers/apiRoutes';
import service from './index.service';

export async function editLevelService(id: string, level: string) {
  try {
    const response = await service.put(`/admin/interview-level/edit?id=${id}&level=${level}`);
    return response.data;
  } catch (error: any) {
    return { data: null, error: [error] };
  }
}
export async function addLevelService(level: string) {
  try {
    const response = await service.post(`/admin/interview-level/add?level=${level}`);
    return response.data;
  } catch (error: any) {
    return { data: null, error: [error] };
  }
}
export async function deleteLevelService(id: number | string) {
  try {
    const response = await service.delete(`/admin/interview-level/delete?id=${id}`);
    return response.data;
  } catch (error: any) {
    return { data: null, error: [error] };
  }
}
export async function userListLevelService(id: number | string) {
  try {
    const response = await service.get(`${LOP_USERS_LIST}?levelId=${id}`);
    return response.data;
  } catch (error: any) {
    return { data: null, error: [error] };
  }
}
export async function userListLevelSearchService(id: string, searchText: string) {
  try {
    const response = await service.get(
      `${LOP_USERS_SEARCH_LIST}?levelId=${id}&searchText=${searchText}`
    );
    return response.data;
  } catch (error: any) {
    return { data: null, error: [error] };
  }
}
