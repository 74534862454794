import React from 'react';
import ErrorPage from 'app/pages/Error';
import ImageComponent from 'app/core/shared-components/image';
import Table from 'app/core/shared-components/table';
import tickIcon from 'assets/images/nothing-here.svg';
import { IOnboardFormData, IPanelData } from 'helpers/panel';
import {
  NO_ONBOARD_USERS_DESC,
  NO_ONBOARD_USERS_TITLE,
  NO_PANELLIST_DESC,
  NO_PANELLIST_TITLE
} from 'helpers/messages.constants';
import { Column } from 'react-table';

interface ITabBody {
  selectedTabIndex: number;
  isOnboardUserTabError: boolean;
  onboardUserTable?: IOnboardFormData[];
  onBoardUsersTableColumn?: Column[];
  onBoardUsersRowData?: IOnboardFormData[];
  isPanelUserTabError: boolean;
  panelUserData?: IPanelData[];
  panelUsersTableColumn?: Column[];
  panelUsersRowData?: IPanelData[];
}
const TabBody = (props: ITabBody) => {
  const {
    selectedTabIndex,
    isOnboardUserTabError = false,
    onboardUserTable,
    onBoardUsersTableColumn = [],
    onBoardUsersRowData = [],
    isPanelUserTabError = false,
    panelUserData,
    panelUsersTableColumn = [],
    panelUsersRowData = []
  } = props;

  const data =
    selectedTabIndex === 0 ? (
      isOnboardUserTabError ? (
        <div className="errorPage">
          <ErrorPage page="apiFailure" />
        </div>
      ) : onboardUserTable?.length ? (
        <Table
          columnsDefinition={onBoardUsersTableColumn}
          rowsDefinition={onBoardUsersRowData}
          isCheckEnabled={false}
        />
      ) : (
        <div className="no-results-container">
          <div className="nr-main-container">
            <div>
              <ImageComponent src={tickIcon} />
            </div>
            <div className="desc">
              <div className="desc-heading">{NO_ONBOARD_USERS_TITLE}</div>
              <div className="desc-subtext">{NO_ONBOARD_USERS_DESC}</div>
            </div>
          </div>
        </div>
      )
    ) : isPanelUserTabError ? (
      <div className="errorPage">
        <ErrorPage page="apiFailure" />
      </div>
    ) : panelUserData?.length ? (
      <Table
        columnsDefinition={panelUsersTableColumn}
        rowsDefinition={panelUsersRowData}
        isCheckEnabled={false}
      />
    ) : (
      <div className="no-results-container">
        <div className="nr-main-container">
          <div>
            <ImageComponent src={tickIcon} />
          </div>
          <div className="desc">
            <div className="desc-heading">{NO_PANELLIST_TITLE}</div>
            <div className="desc-subtext">{NO_PANELLIST_DESC}</div>
          </div>
        </div>
      </div>
    );
  return data;
};
export default TabBody;
