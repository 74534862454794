import React, { Fragment, PropsWithChildren, useCallback, useLayoutEffect, useRef } from 'react';
import './index.style.scss';
import { ReactComponent as LoaderSpinner } from 'assets/images/loader-spinner.svg';

interface IInfiniteScrollProps extends PropsWithChildren {
  handlePageChange: () => void;
  nextPage: boolean;
  customClass?: string;
  rootRef?: Element;
  showEndDot?: boolean;
}

const InfiniteScroll = ({
  handlePageChange,
  nextPage,
  children,
  rootRef,
  showEndDot = true
}: IInfiniteScrollProps) => {
  const targetRef = useRef<HTMLDivElement>(null);

  const intersectionObserverCallback: IntersectionObserverCallback = useCallback(
    (entries, observer) => {
      const [entry] = entries;
      if (entry.isIntersecting && nextPage) {
        observer.disconnect();
        handlePageChange();
      }
    },
    [handlePageChange, nextPage]
  );

  useLayoutEffect(() => {
    const options: IntersectionObserverInit = {
      root: rootRef ?? document
    };

    const observer = new IntersectionObserver(intersectionObserverCallback, options);
    if (targetRef.current) observer.observe(targetRef.current);

    return () => {
      observer.disconnect();
    };
  }, [rootRef, intersectionObserverCallback]);

  return (
    <Fragment>
      {children}
      <div className="infinite-scroll-loader" ref={targetRef}>
        {nextPage ? <LoaderSpinner className={` loading-spinner`} /> : showEndDot && '•'}
      </div>
    </Fragment>
  );
};

export default InfiniteScroll;
