import React from 'react';
import Button from 'app/core/shared-components/button';
import { MEDIUM, OUTLINED } from 'helpers/constants';
import '../.././index.style.scss';

interface IButtonGroupsProps {
  selectedRows: string[];
  tabButtonValue: number;
  handleApproveModalOpen: (val: any) => void;
  handleArchiveUsers: () => {};
  handleMultiOnboard: () => void;
  handleMultiDelete: () => void;
  markOnBench: () => {};
  projectStatusValue: number | string;
}
const ButtonGroups = (props: IButtonGroupsProps) => {
  const {
    selectedRows,
    tabButtonValue,
    handleApproveModalOpen,
    handleArchiveUsers,
    handleMultiOnboard,
    handleMultiDelete,
    markOnBench,
    projectStatusValue
  } = props;
  return (
    <div className="ap-mark-buttons">
      {tabButtonValue === 0 && (
        <>
          <Button
            variant={OUTLINED}
            size={MEDIUM}
            onClick={handleApproveModalOpen}
            customStyle="ap-delete-top-button">
            Approve
          </Button>
          <Button
            variant={OUTLINED}
            size={MEDIUM}
            onClick={handleArchiveUsers}
            customStyle="ap-delete-top-button">
            Archive
          </Button>
        </>
      )}
      {tabButtonValue === 2 && (
        <Button
          variant={OUTLINED}
          size={MEDIUM}
          onClick={handleMultiOnboard}
          customStyle="ap-delete-top-button">
          Onboard
        </Button>
      )}
      <Button
        variant={OUTLINED}
        size={MEDIUM}
        onClick={handleMultiDelete}
        customStyle="ap-delete-top-button">
        Delete
      </Button>
      {projectStatusValue !== 3 && (
        <Button
          variant={OUTLINED}
          size={MEDIUM}
          onClick={markOnBench}
          customStyle="ap-mark-top-button">
          Mark on Bench
        </Button>
      )}
    </div>
  );
};
export default ButtonGroups;
