import { IReadNotificationType } from 'helpers/types';
import service from './index.service';
import {
  ACTIVITY_NOTIFICATION,
  GENERAL_NOTIFICATION,
  READ_ALL_NOTIFICATION,
  READ_NOTIFICATION
} from 'helpers/apiRoutes';

export async function fetchProject() {
  try {
    const response = await service.get('/user/project/list');
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function fetchTechstack() {
  try {
    const response = await service.get('/user/tech-stack/list');
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function fetchLevelOfInterview() {
  try {
    const response = await service.get('/user/interview-levels');
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function fetchRoles() {
  try {
    const response = await service.get('/user/role/list');
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function userInfoService() {
  try {
    const response = await service.get('/user/info');
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}
export async function editPaneUserService(body: any) {
  try {
    const response = await service.post(`admin/user/edit`, body);
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function fetchGeneralNotification(pageNumber: number) {
  try {
    const response = await service.get(`${GENERAL_NOTIFICATION}${pageNumber}/10`);
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function fetchActivityNotification(pageNumber: number) {
  try {
    const response = await service.get(`${ACTIVITY_NOTIFICATION}${pageNumber}/10`);
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function readNotification(
  notificationId: string,
  notificationType: IReadNotificationType
) {
  try {
    const response = await service.get(
      `${READ_NOTIFICATION}?notificationId=${notificationId}&notificationType=${notificationType}`
    );
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}

export async function readAllNotification(notificationType: IReadNotificationType) {
  try {
    const response = await service.get(
      `${READ_ALL_NOTIFICATION}?notificationType=${notificationType}`
    );
    return response.data;
  } catch (error: any) {
    const { data } = error.response;
    return { data: null, error: [data] };
  }
}
