import Select, { ISelectValue } from '../../../../core/shared-components/select';
import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppThunkDispatch } from '../../../../redux/hooks';
import './index.style.scss';
import MonthsToggle from '../months-toggle';
import moment, { Moment } from 'moment';
import SlotChart from '../slot-chart';
import CircleRequired from '../../../../../assets/images/circle-required.svg';
import CircleProvided from '../../../../../assets/images/circle-provided.svg';
import CircleBooked from '../../../../../assets/images/circle-booked.svg';
import CircleCancelled from '../../../../../assets/images/circle-cancelled.svg';
import ImageComponent from '../../../../core/shared-components/image';
import NoRequestedSlot from '../../../../../assets/images/no-result-found.svg';
import { subtract } from 'lodash';
import {
  getSlotEfficiencyData,
  ISlotEfficiencyData
} from '../../../../redux/slices/adminDashboardSlice';
import { ReactComponent as LoaderSpinner } from '../../../../../assets/images/loader-spinner.svg';
import Typography from '../../../../core/shared-components/typography';
import Button from '../../../../core/shared-components/button';
import { useNavigate } from 'react-router';
import ErrorPage from '../../../Error';
import SlotRequirementForm from '../slot-requirement-form';
import { v4 as uuid } from 'uuid';
import CustomSelect, { ISelectOptions } from '../../../../core/shared-components/customSelect';
import { techLevelStyles } from './techLevel.style';

interface ISlotEfficiencyGraph {
  techStackFilter: ISelectOptions;
  levelFilter: ISelectValue;
  month: Moment;
  chartData: ISlotEfficiencyData[];
  isAdmin: boolean;
  techStackList: ISelectValue[];
  isRequirementModalOpen: boolean;
  updateGraph: boolean;
}

const SlotEfficiencyGraph = () => {
  const { techStackLevelSet, role } = useAppSelector((state) => state.user.userData);
  const { techStack } = useAppSelector((state) => state.shared);

  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();

  const { levelOfInterview, isLevelLoading, isTechLoading } = useAppSelector(
    (state) => state.shared
  );

  const { slotEfficiencyData, isSlotEfficiencyLoading, isSlotEfficiencyError } = useAppSelector(
    (state) => state.adminDashboard
  );

  const levelOfInterviewList = levelOfInterview?.map((levelData) => ({
    id: levelData?.id,
    label: levelData?.level
  }));

  const [slotEfficiencyStates, setSlotEfficiencyStates] = useState<ISlotEfficiencyGraph>({
    techStackFilter: {} as ISelectOptions,
    levelFilter: {} as ISelectValue,
    month: moment(),
    chartData: [] as ISlotEfficiencyData[],
    isAdmin: true,
    techStackList: [],
    isRequirementModalOpen: false,
    updateGraph: false
  });

  const {
    techStackFilter,
    levelFilter,
    month,
    chartData,
    isAdmin,
    techStackList,
    isRequirementModalOpen,
    updateGraph
  } = slotEfficiencyStates;
  const graphData = slotEfficiencyData?.map((slotData) => ({
    month: moment(slotData.month.toString(), 'M').format('MMMM'),
    required: slotData.required,
    provided: slotData.provided,
    cancelled: slotData.cancelled,
    booked: slotData.booked
  }));

  useEffect(() => {
    const isAdminRole = role.length === 1 && !!role.filter((item) => item.id === 1).length;
    let techStackListData: ISelectValue[] = [];
    if (isAdminRole) {
      techStackListData = techStack?.map((techStack) => ({
        id: techStack.id,
        label: techStack.stack
      }));
    } else {
      techStackListData = techStackLevelSet?.map((techStack) => ({
        id: techStack.techStackId,
        label: techStack.techStackName
      }));
    }

    setSlotEfficiencyStates((prevState) => ({
      ...prevState,
      isAdmin: isAdminRole,
      techStackList: techStackListData
    }));
  }, []);

  useEffect(() => {
    if (levelOfInterview?.length && techStackList?.length) {
      setSlotEfficiencyStates((prevState) => ({
        ...prevState,
        levelFilter: levelOfInterview?.map((levelData) => ({
          id: levelData?.id,
          label: levelData?.level
        }))[0],
        techStackFilter: techStackList?.map((techStack) => ({
          id: techStack?.id,
          label: techStack?.label
        }))[0]
      }));
    }
  }, [levelOfInterview?.length, techStackList?.length]);

  useEffect(() => {
    const monthNumber = parseInt(month.format('M'));
    const year = parseInt(month.format('YYYY'));
    const currentMonth = {
      month: monthNumber,
      techStack: techStackFilter?.id?.toString(),
      interviewLevel: levelFilter?.id?.toString(),
      year: year
    };
    if (techStackFilter?.id && levelFilter?.id) {
      dispatch(getSlotEfficiencyData(currentMonth)).then(({ payload }) => {
        setSlotEfficiencyStates((prevState) => ({
          ...prevState,
          chartData: slotEfficiencyData
        }));
      });
    }
  }, [levelFilter?.id, techStackFilter?.id, month, updateGraph]);

  const handleTechStackChange = (params: ISelectOptions | ISelectOptions[] | null) => {
    if (!Array.isArray(params) && params !== null) {
      setSlotEfficiencyStates((prevState) => ({
        ...prevState,
        techStackFilter: params
      }));
    }
  };

  const handleLevelChange = (params: ISelectValue | ISelectValue[] | null) => {
    if (!Array.isArray(params) && params !== null) {
      setSlotEfficiencyStates((prevState) => ({
        ...prevState,
        levelFilter: params
      }));
    }
  };

  const handleMonthChange = (month: Moment) => {
    setSlotEfficiencyStates((prevState) => ({ ...prevState, month: month }));
  };

  const handleModalClose = () => {
    setSlotEfficiencyStates((prevState) => ({ ...prevState, isRequirementModalOpen: false }));
  };

  const handleAddTechStack = () => {
    navigate('/settings');
  };

  const handleSlotRequirementClick = () => {
    setSlotEfficiencyStates((prevState) => ({ ...prevState, isRequirementModalOpen: true }));
  };

  const handleUpdateGraph = () => {
    setSlotEfficiencyStates((prevState) => ({ ...prevState, updateGraph: !updateGraph }));
  };

  return (
    <div className="slot-efficiency-widget">
      {isSlotEfficiencyError ? (
        <ErrorPage page="apiFailure" />
      ) : (
        <>
          {isLevelLoading || isTechLoading ? (
            <div className={'slot-efficiency-loading-wrapper'}>
              <LoaderSpinner className="loading-spinner" />
            </div>
          ) : (
            <>
              {!!role.filter((item) => item.id === 3).length &&
                role.length > 1 &&
                moment().format('MMMM YYYY') === month.format('MMMM YYYY') && (
                  <Button
                    customStyle="slot-efficiency-link"
                    variant="text"
                    onClick={handleSlotRequirementClick}>
                    Slot Requirement
                  </Button>
                )}
              <div className="slot-efficiency-filters">
                <div className="month-filter">
                  <MonthsToggle month={month} onMonthToggle={handleMonthChange} />
                </div>
                <div className="tech-level-filter">
                  {(isAdmin || techStackList?.length > 1) && (
                    <div className="slot-overview-dropdown">
                      <CustomSelect
                        dropdownOptions={techStackList}
                        selectedOptions={techStackFilter}
                        onChange={handleTechStackChange}
                        showDropdownIndicator
                        customStyles={techLevelStyles}
                      />
                    </div>
                  )}
                  {levelOfInterview?.length > 1 && techStackList?.length > 0 && (
                    <div className="slot-overview-dropdown">
                      <CustomSelect
                        dropdownOptions={levelOfInterviewList}
                        selectedOptions={levelFilter}
                        onChange={handleLevelChange}
                        showDropdownIndicator
                        customStyles={techLevelStyles}
                      />
                    </div>
                  )}
                </div>
              </div>
              {techStackList?.length < 1 && !isAdmin ? (
                <div className="no-stats-found">
                  <ImageComponent src={NoRequestedSlot} />
                  <Typography variant="headline-24">No stats found</Typography>
                  <Typography variant="headline-16" customStyle="no-slot-message">
                    Add a Tech Stack to view the statistics
                  </Typography>
                  <Button customStyle="slot-btn" variant="contained" onClick={handleAddTechStack}>
                    Add Tech Stack
                  </Button>
                </div>
              ) : (
                <>
                  <SlotChart chartData={graphData?.reverse()} />
                  <div className="graph-legends">
                    <div className="label">
                      <ImageComponent src={CircleRequired} />
                      <div>Required</div>
                    </div>
                    <div className="label">
                      <ImageComponent src={CircleProvided} />
                      Provided
                    </div>
                    <div className="label">
                      <ImageComponent src={CircleBooked} />
                      Booked
                    </div>
                    <div className="label">
                      <ImageComponent src={CircleCancelled} />
                      Cancelled
                    </div>
                  </div>
                </>
              )}
              {isRequirementModalOpen && (
                <SlotRequirementForm
                  onClose={handleModalClose}
                  techStack={techStackFilter}
                  level={levelFilter}
                  monthData={month}
                  onAdd={handleUpdateGraph}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
export default SlotEfficiencyGraph;
