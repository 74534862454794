import React, { useCallback, useMemo, useEffect, useState, Fragment } from 'react';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import { useNavigate } from 'react-router';
import { Column } from 'react-table';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import TabComponent from 'app/core/shared-components/tab';
import { ISelectValue } from 'app/core/shared-components/select';
import ConfirmationModal from 'app/core/shared-components/confirmation-modal';
import Drawer from 'app/core/shared-components/drawer';
import Typography from 'app/core/shared-components/typography';
import FormBody from 'app/core/shared-components/users-section-tab/components/FormBody';
import TabBody from 'app/core/shared-components/users-section-tab/components/TabBody';
import { ITechStackArray } from 'app/core/shared-components/form';
import { onboardUser } from 'app/redux/slices/onboardUserSlice';
import RowCreator from 'app/pages/Panel/components/rowCreator';
import UserDetail from 'app/pages/Onboard/components/user-detail';
import Button from 'app/core/shared-components/button';
import ImageComponent from 'app/core/shared-components/image';
import deleteIcon from 'assets/images/delete.svg';
import { deletePanelService } from 'app/services/panel.service';
import GetLDeleteModalEdgeCase from 'app/core/shared-components/users-section-tab/utils';
import Loader from 'app/core/shared-components/loader';
import { notify } from 'helpers/toastHelper';
import ToastUndo from 'app/core/shared-components/toast-undo';
import { ReactComponent as LoaderSpinner } from 'assets/images/loader-spinner.svg';
import {
  approvePanel,
  editUserInfo,
  getSelectedUserData,
  setLoading
} from 'app/redux/slices/panelSlice';
import {
  APPROVE_BTN,
  CANCEL,
  DELETE,
  DELETE_PANEL,
  EXP,
  NAME,
  ONBOARD_USER_TITLE,
  PanelTabValues,
  PROJECT_TAB,
  RECENTY_ADDED_PANEL,
  ROLE_ADMIN,
  STATUS,
  STATUSENUM_APPROVED,
  STATUSENUM_ARCHIVE,
  TECH,
  VIEW_DETAILS
} from 'helpers/constants';
import {
  getEmployeeCount,
  onboardUserData,
  panelUsersData
} from 'app/redux/slices/adminDashboardSlice';
import {
  IOnboardFormData,
  IPanelData,
  IPanelStates,
  IPanelStatesData,
  IPanelType,
  IProjectStatusType,
  IProjectType,
  ISelectedPanelType,
  statusOptions
} from 'helpers/panel';
import { getExperience, handleMapFunction } from 'helpers/utils';
import { ONBOARD_ROUTE, PANEL_ROUTE, RECRUITER_ROUTE } from 'helpers/apiRoutes';
import {
  APPROVE_PANEL_DASHBOARD,
  APPROVE_USER_MODAL_TITLE,
  DELETE_MODAL_DESCRIPTION,
  SUCCESSFULLY_APPROVED,
  SUCCESSFULLY_ARCHIEVED,
  SUCCESSFULLY_ONBOARDED,
  SUCCESSFULLY_DELETED
} from 'helpers/messages.constants';
import './index.style.scss';

interface IUsersTabState {
  selectedTabIndex?: number;
  isDrawerOpen?: boolean;
  panelUserData?: IPanelData[];
  onboardUserTable?: IOnboardFormData[];
}
const UsersTab = () => {
  const [userTabData, setUserTabData] = useState<IUsersTabState>({
    selectedTabIndex: 0,
    isDrawerOpen: false,
    panelUserData: [],
    onboardUserTable: []
  });
  const [panelState, setPanelState] = useState<IPanelStates>(IPanelStatesData);
  const [approveUser, setApproveUser] = useState({ id: '', firstName: '', lastName: '' });
  const { selectedUser, isEditingOn, isSingleDeleteOn } = panelState;
  const { selectedTabIndex = 0, panelUserData, onboardUserTable } = userTabData;
  const navigate = useNavigate();
  const userTabs = PanelTabValues;
  const [statusFieldValue, setStausFieldValue] = useState<number>();
  const dispatch = useAppThunkDispatch();
  const { userId: id } = useAppSelector((state) => state.user.userData);

  const {
    panelUserList,
    onboardUserList,
    isOnboardUserListLoading,
    isPanelUserListLoading,
    isOnboardUserTabError,
    isPanelUserTabError
  } = useAppSelector((state) => state.adminDashboard);

  const { recruiter, panel } = useAppSelector((state) => state.shared);
  const [firstStatusOption, ...rest] = statusOptions;
  const [statusValue, setStatusValue] = useState(firstStatusOption);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [panelForm, setPanelForm] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [nonDeletedUserModal, setNonDeletedUserModal] = useState(false);
  const [nonDeletedUserArray, setNonDeletedUserArray] = useState();
  const reduxState = useAppSelector((state) => state);
  const reduxOnboardState = useAppSelector((state) => state.onboardUser);
  const rdxUserInfo = useAppSelector((state) => state.user);
  const { isDrawerLoading } = useAppSelector((state) => state?.panel.panelData);
  const { isOnboardingDrawerLoading } = reduxOnboardState;
  const { shared } = reduxState;
  const onBoardUsersRowData = useMemo(() => onboardUserTable?.slice(0, 4), [onboardUserTable]);
  const panelUsersRowData = useMemo(() => panelUserData?.slice(0, 4), [panelUserData]);

  const generateRole = (array: IPanelType[]) => {
    const roles = array?.filter((role) => role?.id == recruiter?.id || role?.id == panel?.id);
    const [panelRole, ...rest] = roles;
    const currentRole = panelRole?.id === panel?.id ? panel : recruiter;
    return currentRole;
  };
  const handleApproveStatus = () => {
    dispatch(setLoading(true));
    setStausFieldValue(1);
    dispatch(approvePanel([approveUser?.id])).then((response) => {
      notify(
        true,
        <div className="toast-container-detail">
          <Typography customStyle="toast-title">{`${SUCCESSFULLY_APPROVED} ${approveUser?.firstName} ${approveUser?.lastName}`}</Typography>
          <Button
            variant="outlined"
            customStyle="toast-detail-button"
            onClick={handleNavigate(PANEL_ROUTE, 1, approveUser?.id, RECENTY_ADDED_PANEL)}>
            {VIEW_DETAILS}
          </Button>
        </div>,
        uuidv4().toString(),
        handleCloseToast
      );
      dispatch(panelUsersData()).then(({ payload }) => {
        setUserTabData((prev) => ({
          ...prev,
          panelUserData: payload?.data
        }));
      });
    });

    setStausFieldValue(1);
    setPanelState((prevState) => ({
      ...prevState,
      isDeleteOn: false,
      selectedRows: [],
      isEditingOn: false,
      isSingleDeleteOn: false,
      isToastVisible: true
    }));
    setOpenApproveModal(false);
    setNonDeletedUserModal(false);
    dispatch(setLoading(false));
  };
  const handleEditPanel = async (
    role: ISelectValue,
    status: string,
    projects?: ISelectValue[],
    stackLop?: ITechStackArray[]
  ) => {
    if (selectedUser?.userId) {
      let data = {
        userId: [selectedUser?.userId],
        roleId: role.id,
        status: status
      };
      if (role.id.toString() === shared.panel?.id.toString()) {
        const panelData = {
          projectIds: projects?.map((item) => item.id.toString()) || [],
          onBench: !projects?.length,
          techStackDetails: stackLop ? stackLop : []
        };
        setStausFieldValue(status == STATUSENUM_APPROVED ? 1 : 2);
        Object.assign(data, panelData);
      }
      await dispatch(editUserInfo(data));
      dispatch(panelUsersData()).then(({ payload }) => {
        setUserTabData((prev) => ({
          ...prev,
          panelUserData: payload?.data
        }));
      });
      const statusText =
        status === STATUSENUM_APPROVED
          ? `${SUCCESSFULLY_APPROVED} ${selectedUser?.firstName} ${selectedUser?.lastName}`
          : status === STATUSENUM_ARCHIVE
          ? `${SUCCESSFULLY_ARCHIEVED} ${selectedUser?.firstName} ${selectedUser?.lastName}`
          : '';
      if (status !== selectedUser.statusEnum) {
        notify(
          true,
          <div className="toast-container-detail">
            <Typography customStyle="toast-title">{statusText}</Typography>
            <Button
              variant="outlined"
              customStyle="toast-detail-button"
              onClick={handleNavigate(
                PANEL_ROUTE,
                status == STATUSENUM_APPROVED ? 1 : 2,
                selectedUser && selectedUser.userId,
                RECENTY_ADDED_PANEL
              )}>
              {VIEW_DETAILS}
            </Button>
          </div>,
          uuidv4().toString(),
          handleCloseToast
        );
      }
      setPanelState((prevState) => ({
        ...prevState,
        isEditingOn: false
      }));
      setPanelForm(false);
      handlCloseDrawer && handlCloseDrawer();
      return;
    }
  };
  const handleSave = async (
    role: ISelectValue,
    status: string,
    projects?: ISelectValue[],
    stackLop?: ITechStackArray[]
  ) => {
    if (!Array.isArray(selectedUser?.userId)) {
      let data = {
        userId: [selectedUser?.userId],
        data: {
          roleId: role.id,
          status: status
        }
      };
      if (role.id.toString() === shared.panel?.id.toString()) {
        const panelData = {
          projectIds: projects?.map((item) => item.id.toString()) || [],
          onBench: !projects?.length,
          techStackDetails: stackLop ? stackLop : []
        };
        setStausFieldValue(status === STATUSENUM_ARCHIVE ? 2 : 0);
        Object.assign(data.data, panelData);
      }
      const statusText =
        status === STATUSENUM_ARCHIVE
          ? `${SUCCESSFULLY_ARCHIEVED} ${selectedUser?.firstName} ${selectedUser?.lastName}`
          : `${SUCCESSFULLY_ONBOARDED} ${selectedUser?.firstName} ${selectedUser?.lastName}`;
      await dispatch(onboardUser(data));
      const userRole = rdxUserInfo?.userData?.role.map((item) => item?.role).includes(ROLE_ADMIN);
      userRole && dispatch(getEmployeeCount());
      dispatch(onboardUserData());
      setUserTabData((prevState) => ({
        ...prevState,
        onboardUserTable: onboardUserList
      }));
      notify(
        true,
        <div className="toast-container-detail">
          <Typography customStyle="toast-title">{statusText}</Typography>
          <Button
            variant="outlined"
            customStyle="toast-detail-button"
            onClick={handleNavigate(
              role.id === '2' ? PANEL_ROUTE : RECRUITER_ROUTE,
              status === STATUSENUM_ARCHIVE ? 2 : 0,
              selectedUser?.userId && selectedUser?.userId,
              RECENTY_ADDED_PANEL
            )}>
            {VIEW_DETAILS}
          </Button>
        </div>,
        uuidv4().toString(),
        handleCloseToast
      );
      handlCloseDrawer && handlCloseDrawer();
      return;
    }
  };
  const commonTdClick = async (id: string) => {
    setPanelForm(true);
    dispatch(setLoading(true));
    const user = await dispatch(getSelectedUserData(id.toString())).unwrap();
    const tempRole = generateRole(user.data.roles || []);
    const tempProject = user.data.projects?.map((item: IProjectType) => ({
      id: item?.id,
      label: item?.name
    }));

    const tempStackLoi = handleMapFunction(user?.data?.techStackInterviewLevelDtos, true, 2);
    const temptechStack = handleMapFunction(user?.data?.techStackInterviewLevelDtos, false, 3);
    setPanelState((prevState) => ({
      ...prevState,
      formData: {
        role: tempRole,
        experience: user?.data?.totalExperience && getExperience(user?.data?.totalExperience),
        projects: [...tempProject],
        stackLoi: [...tempStackLoi],
        techStack: [...temptechStack]
      },
      selectedUser: user?.data,
      isEditingOn: true
    }));
    dispatch(setLoading(false));
  };

  const rowClickOnboard = (item: ISelectedPanelType) => () => {
    setPanelState((prevState) => ({ ...prevState, isEditingOn: true, selectedUser: item }));
  };

  const rowClick = (item: string) => () => {
    commonTdClick(item);
  };

  const handleStatusChange = (userData: IPanelData, params: IProjectStatusType) => {
    setApproveUser(userData);
    setOpenApproveModal(params?.id === 2 ? true : false);
  };

  const handlCloseDrawer = () => {
    setPanelState((prev) => ({
      ...prev,
      isEditingOn: false
    }));
    setPanelForm(false);
  };

  const handleTabSelect = (index: number) => {
    index === 0 ? dispatch(onboardUserData()) : dispatch(panelUsersData());
    setUserTabData((prevState) => ({
      ...prevState,
      selectedTabIndex: index,
      panelUserData: panelUserList,
      onboardUserTable: onboardUserList
    }));
  };

  const handleCloseApproveModal = () => {
    setOpenApproveModal(false);
    setStatusValue(firstStatusOption);
  };

  const handleNavigate =
    (navigationLink: string, statusTab?: number, id?: string, status?: string) => () => {
      navigate(navigationLink, {
        state: { tab: statusTab, id: id, status: status }
      });
    };
  const handleDelete = () => {
    setDeleteUser(true);
    setPanelState((prevState) => ({
      ...prevState,
      isSingleDeleteOn: true
    }));
  };

  const localDelete = (panel: ISelectedPanelType) => {
    let panelUserDataCopy = panelUserData;
    panelUserDataCopy = panelUserDataCopy?.filter((item: IPanelData) => item?.id !== panel?.userId);
    setPanelState((prevState) => ({
      ...prevState,
      isDeletionInProcess: true,
      isDeleteOn: false,
      isSingleDeleteOn: false,
      isToastVisible: true
    }));
    setUserTabData((prev) => ({
      ...prev,
      panelUserData: panelUserDataCopy
    }));
    notify(
      false,
      <ToastUndo
        toastMessage={`${SUCCESSFULLY_DELETED} ${panelState?.selectedUser?.firstName} ${panelState?.selectedUser?.lastName}`}
        handleUndo={onUndoDelete}
        toastId={panel?.userId}
      />,
      panel?.userId,
      deletePanelData
    );
  };
  const handleCloseModalDelete = () => {
    setNonDeletedUserModal(false);
  };

  const deletePanelData = () => {
    const ids = [selectedUser?.userId];
    dispatch(setLoading(true));
    deletePanelService(ids).then((response) => {
      if (response?.data) {
        dispatch(panelUsersData());
        setPanelState((prevState) => ({
          ...prevState,
          isDeleteOn: false,
          selectedRows: [],
          isEditingOn: false,
          isSingleDeleteOn: false,
          clearResult: false
        }));
        const userRole = rdxUserInfo?.userData?.role.map((item) => item?.role).includes(ROLE_ADMIN);
        userRole && dispatch(getEmployeeCount());
        setDeleteUser(false);
      } else {
        setPanelState((prevState) => ({
          ...prevState,
          isDeleteOn: false,
          selectedRows: [],
          isEditingOn: false,
          isSingleDeleteOn: false,
          isMultiDeleteOn: false
        }));
        dispatch(panelUsersData());
        setUserTabData((prevState) => ({
          ...prevState,
          panelUserData: panelUserList
        }));
        setNonDeletedUserModal(true);
        const [firstData, ...rest] = response?.error;
        setNonDeletedUserArray(firstData?.data);
      }
    });
    dispatch(setLoading(false));
  };

  const deleteConfirmationClick = () => {
    const ids = panelState?.selectedUser;
    localDelete(ids);
    setPanelState((prevState) => ({
      ...prevState,
      isEditingOn: false
    }));
  };
  const handleViewAll = () => {
    selectedTabIndex === 0 ? navigate(ONBOARD_ROUTE) : navigate(PANEL_ROUTE);
  };

  const handleCloseModal = () => {
    setPanelState((prevState) => ({
      ...prevState,
      isSingleDeleteOn: false
    }));
  };
  const onUndoDelete = (event: React.SyntheticEvent) => {
    const id = event?.currentTarget?.id;
    dispatch(panelUsersData()).then((res) => {
      setUserTabData((prev) => ({
        ...prev,
        panelUserData: res?.payload?.data
      }));
    });
    toast.update(id, { onClose: () => {}, hideProgressBar: true });
    toast.dismiss(id);
  };
  const handlePanelUserList = () => {
    setUserTabData((prevState) => ({
      ...prevState,
      panelUserData: panelUserList
    }));
  };
  const handleOnboardUserList = () => {
    setUserTabData((prevState) => ({
      ...prevState,
      onboardUserTable: onboardUserList
    }));
  };

  const handleCloseToast = () => {};

  const onBoardUsersTableColumn: Column<any>[] = [
    {
      Header: '',
      accessor: 'firstName',
      Cell: (item) => {
        return (
          <div
            className="td-decoration"
            key={item?.row?.original?.firstName}
            onClick={rowClickOnboard(item?.row?.original)}>
            <UserDetail
              userName={`${item?.row?.original?.firstName} ${item?.row?.original?.lastName}`}
              userImage={item?.row?.original?.profileImageUrl}
            />
          </div>
        );
      }
    },
    {
      Header: '',
      accessor: 'designation',
      Cell: (item) => {
        return (
          <div
            className="td-decoration"
            key={item?.row?.original?.designation}
            onClick={rowClickOnboard(item?.row?.original)}>
            {item?.row?.original?.designation}
          </div>
        );
      }
    },
    {
      Header: '',
      accessor: 'emailId',
      Cell: (item) => {
        return (
          <div
            className="td-decoration"
            key={item?.row?.original?.emailId}
            onClick={rowClickOnboard(item?.row?.original)}>
            {item?.row?.original?.emailId}
          </div>
        );
      }
    },
    {
      Header: '',
      accessor: 'experience',
      Cell: (item) => {
        return (
          <div
            className="td-decoration"
            key={item?.row?.original?.experience}
            onClick={rowClickOnboard(item?.row?.original)}>
            {getExperience(item?.row?.original?.experience)}
          </div>
        );
      }
    }
  ];

  const panelUsersTableColumn: Column<any>[] = [
    {
      Header: '',
      accessor: 'firstName',
      Cell: (item) => {
        return (
          <div key={NAME} className="panel-row-cell" onClick={rowClick(item?.row?.original?.id)}>
            <RowCreator component={NAME} panel={item?.row?.original} />
          </div>
        );
      }
    },
    {
      Header: '',
      accessor: 'techStackInterviewLevelSet',
      Cell: (item) => {
        return (
          <div
            key={TECH}
            className="panel-row-cell panel-cell-tech-stack"
            onClick={rowClick(item?.row?.original?.id)}>
            <RowCreator component={TECH} panel={item?.row?.original} showLOP={true} />
          </div>
        );
      }
    },
    {
      Header: '',
      accessor: 'totalExperience',
      Cell: (item) => {
        return (
          <div
            key={EXP}
            className="panel-row-cell align-center panel-panel-gray-text"
            onClick={rowClick(item?.row?.original?.id)}>
            <RowCreator component={EXP} panel={item?.row?.original} />
          </div>
        );
      }
    },
    {
      Header: '',
      accessor: 'projects',
      Cell: (item) => {
        return (
          <div
            key={PROJECT_TAB}
            className="panel-row-cell"
            onClick={rowClick(item?.row?.original?.id)}>
            <RowCreator component={PROJECT_TAB} panel={item?.row?.original} />
          </div>
        );
      }
    },
    {
      Header: '',
      accessor: 'statusEnum',
      Cell: useCallback(
        (item: { row: { original: { id: string } } }) => {
          return (
            <div key={STATUS} className="panel-row-cell">
              {id === item?.row?.original?.id ? (
                ''
              ) : (
                <RowCreator
                  component={STATUS}
                  panel={item?.row?.original}
                  handleChange={handleStatusChange}
                  value={statusValue}
                  options={statusOptions}
                />
              )}
            </div>
          );
        },
        [panelUserData]
      )
    }
  ];

  const getTabBody = () => {
    return (
      <TabBody
        selectedTabIndex={selectedTabIndex}
        isOnboardUserTabError={isOnboardUserTabError}
        onboardUserTable={onboardUserTable}
        onBoardUsersTableColumn={onBoardUsersTableColumn}
        onBoardUsersRowData={onBoardUsersRowData}
        isPanelUserTabError={isPanelUserTabError}
        panelUserData={panelUserData}
        panelUsersTableColumn={panelUsersTableColumn}
        panelUsersRowData={panelUsersRowData}
      />
    );
  };

  useEffect(() => {
    dispatch(onboardUserData());
  }, []);
  useEffect(() => {
    handleOnboardUserList();
  }, [onboardUserList]);

  useEffect(() => {
    handlePanelUserList();
  }, [panelUserList]);

  useEffect(() => {
    dispatch(panelUsersData());
  }, []);
  return (
    <div className="tab-view">
      <TabComponent
        tabs={userTabs?.map((levels) => {
          return {
            title: levels,
            tabData: (
              <div className="user-details">
                <div className="slot-loading-wrapper">
                  <Loader
                    loading={
                      selectedTabIndex === 0 ? isOnboardUserListLoading : isPanelUserListLoading
                    }
                    customClass="dashboard-loader"
                  />
                </div>
                <Fragment>
                  {selectedTabIndex === 0
                    ? !isOnboardUserListLoading && getTabBody()
                    : !isPanelUserListLoading && getTabBody()}
                </Fragment>
              </div>
            )
          };
        })}
        height={200}
        onSelect={handleTabSelect}
        selectedIndex={selectedTabIndex}
        showViewAll={true}
        handleViewAll={handleViewAll}></TabComponent>
      <ConfirmationModal
        isOpen={openApproveModal}
        title={APPROVE_USER_MODAL_TITLE}
        description={APPROVE_PANEL_DASHBOARD}
        onConfirm={handleApproveStatus}
        onClose={handleCloseApproveModal}
        cancelText={CANCEL}
        confirmText={APPROVE_BTN}
      />
      <ConfirmationModal
        title={DELETE_PANEL}
        isOpen={isSingleDeleteOn}
        description={DELETE_MODAL_DESCRIPTION}
        onConfirm={deleteConfirmationClick}
        onClose={handleCloseModal}
        cancelText={CANCEL}
        confirmText={DELETE}
      />
      <GetLDeleteModalEdgeCase
        handleCloseModalDelete={handleCloseModalDelete}
        nonDeletedUserArray={nonDeletedUserArray}
        isOpen={nonDeletedUserModal}
      />
      <Drawer
        isOpen={isEditingOn}
        onClose={handlCloseDrawer}
        bodyStyles="modal-drawer-body"
        headerProps={
          panelForm ? (
            id === panelState?.selectedUser?.userId ? (
              ''
            ) : (
              <div className="delete-icon">
                <ImageComponent src={deleteIcon} onClick={handleDelete} />
              </div>
            )
          ) : (
            <Typography customStyle="side-modal-label">{ONBOARD_USER_TITLE}</Typography>
          )
        }>
        {(selectedTabIndex === 0 ? isOnboardingDrawerLoading : isDrawerLoading) ? (
          <div className="drawer-loader">
            <LoaderSpinner className="loading-spinner" />
          </div>
        ) : (
          <FormBody
            panelForm={panelForm}
            selectedUser={selectedUser}
            panelState={panelState}
            id={id}
            panel={panel}
            handlCloseDrawer={handlCloseDrawer}
            handleEditPanel={handleEditPanel}
            handleSave={handleSave}
          />
        )}
      </Drawer>
    </div>
  );
};
export default UsersTab;
