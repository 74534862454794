import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import SlotCancellationModal from '../../../Slots/components/slot-cancellation-modal';
import moment from 'moment';
import './index.style.scss';
import { useAppSelector, useAppThunkDispatch } from '../../../../redux/hooks';
import { fetchSpecificBookedSlot } from '../../../../redux/slices/slotsSlice';
import Button from '../../../../core/shared-components/button';
import UserIcon from '../../../../../assets/images/user-icon.svg';
import TimeIcon from '../../../../../assets/images/time-icon.svg';
import Typography from '../../../../core/shared-components/typography';
import ImageComponent from '../../../../core/shared-components/image';
import { headerDetailsForSlots } from '../../../../../helpers/utils';

interface IBookedSlotsProps {
  slots: any;
}

const BookedSlots = (props: IBookedSlotsProps) => {
  const { slots } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedSlotId, setSelectedSlotId] = useState<number>(-1);
  const dispatch = useAppThunkDispatch();
  const { selectedBookedSlot } = useAppSelector((state) => state.slots);

  const handleSlotClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const id = parseInt(event.currentTarget.id);
    setSelectedSlotId(id);
    dispatch(fetchSpecificBookedSlot(slots[id].slotId));
    setShowModal(!showModal);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="booked-slot">
      {slots.map((slot: any, index: number) => (
        <div key={index}>
          <Popover
            isOpen={showModal && selectedSlotId == index}
            padding={8}
            reposition
            containerClassName="slot-booking-popover"
            onClickOutside={handleCloseModal}
            align={'center'}
            positions={['right', 'left', 'bottom', 'top']}
            content={
              <SlotCancellationModal
                handleClose={handleCloseModal}
                cancelReason={selectedBookedSlot?.cancelReason}
                candidateName={selectedBookedSlot?.candidateName}
                panelDetails={selectedBookedSlot?.slotUserDetail}
                techStackInterviewLevel={selectedBookedSlot?.techStackInterviewLevelDto}
                date={headerDetailsForSlots(
                  slots[selectedSlotId]?.fromTime,
                  slots[selectedSlotId]?.toTime
                )}
              />
            }>
            <div className="slot-card">
              <div className="slot-card-userdetails">
                <Typography className="user-labels-container user-label" variant="headline-16">
                  <ImageComponent src={UserIcon} />
                  {slot?.candidateName}
                </Typography>
                <Typography className="user-labels-container time-label" variant="captions">
                  <ImageComponent src={TimeIcon} />
                  <span>{`${moment(slot?.fromTime).format('hh:mm a')} - ${moment(
                    slot?.toTime
                  ).format('hh:mm a')}`}</span>
                </Typography>
              </div>
              <Button variant="text" id={index.toString()} onClick={handleSlotClick}>
                Cancel Booking
              </Button>
            </div>
          </Popover>
        </div>
      ))}
    </div>
  );
};
export default BookedSlots;
