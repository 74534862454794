import Slider from 'react-slick';
import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.style.scss';
import LeftArrow from '../../../../assets/images/chevron.svg';
import RightArrow from '../../../../assets/images/group.svg';

function CustomNextArrow({ onClick, className, height }: any) {
  const isDisabled = className.toString().includes('slick-disabled');
  return !isDisabled ? (
    <button className="right-arrow" onClick={onClick} style={{ height: height }}>
      <img src={RightArrow} alt="img" />
    </button>
  ) : null;
}
function CustomPrevArrow({ onClick, className, height }: any) {
  const isDisabled = className.toString().includes('slick-disabled');
  return !isDisabled ? (
    <button className="left-arrow" onClick={onClick} style={{ height: height }}>
      <img src={LeftArrow} alt="img" />
    </button>
  ) : null;
}

const HorizontalSlider = ({ children, customClass, arrowHeight, slidesToShow = 1 }: any) => {
  var settings = {
    dots: false,
    speed: 500,
    variableWidth: false,
    infinite: false,
    adaptiveHeight: true,
    nextArrow: <CustomNextArrow height={arrowHeight} />,
    prevArrow: <CustomPrevArrow height={arrowHeight} />,
    draggable: true,
    swipeToSlide: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    accessibility: true,
    centerMode: false
  };

  return (
    <>
      <Slider {...settings} className={customClass}>
        {children}
      </Slider>
    </>
  );
};
export default HorizontalSlider;
