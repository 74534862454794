import React, { useEffect, useRef, useState } from 'react';
import ImageComponent from '../../../core/shared-components/image';
import CustomSelect from '../../../core/shared-components/customSelect';
import dot_green from '../../../../assets/images/dot_green.svg';
import arrowRight from '../../../../assets/images/arrow-right.svg';
import {
  NAME,
  EXP,
  EMAIL,
  PROJECT_TAB,
  TECH,
  STATUS,
  LEVELCASE,
  STATUSENUM_IN_REVIEW,
  STATUSENUM_APPROVED,
  APPROVED_TAB,
  ONBOARD_TEXT,
  ON_BENCH
} from '../../../../helpers/constants';
import { IPanelData, IProjectStatusType } from 'helpers/panel';
import { statusStyles } from './status.style';
import { getExperience } from 'helpers/utils';
import { Popover } from 'react-tiny-popover';

interface ITSStates {
  techStack: string;
  level: string;
}
interface IProjState {
  id: string;
  name: string;
}
interface IRowsCreatorProps {
  panel: any;
  component: string;
  handleChange?: (data: IPanelData, e: IProjectStatusType) => void;
  value?: IProjectStatusType;
  options?: IProjectStatusType[];
  handleOnboard?: (id: string) => void;
  showLOP?: boolean;
  showOption?: boolean;
  tooltip?: boolean;
}

const RowCreator = (props: IRowsCreatorProps) => {
  const {
    panel,
    showLOP = true,
    showOption,
    handleChange,
    handleOnboard,
    component,
    options = [],
    tooltip = false
  } = props;
  const [first, second, ...rest] = panel?.techStackInterviewLevelSet;
  const [firstProject, ...restProj] = panel?.projects || [];
  const [showTooltip, setShowTooltip] = useState(false);
  const [showProjTooltip, setShowProjTooltip] = useState(false);
  const [firstLetter, ...restLetters] = panel?.firstName;
  const handleStatusChange = (e: IProjectStatusType) => {
    handleChange && handleChange(panel, e);
  };
  const handleMouseOut = () => setShowTooltip(false);
  const handleMouseIn = () => setShowTooltip(true);
  const handleProjMouseOut = () => setShowProjTooltip(false);
  const handleProjMouseIn = () => setShowProjTooltip(true);
  const handleOnboardUser = () => {
    handleOnboard && handleOnboard(panel.id);
  };
  switch (component) {
    case NAME:
      return (
        <div className="panel-name-designation">
          <div className="panel-img-name-container">
            <ImageComponent
              src={panel?.profileImageUrl || null}
              className="admin-panel-img"
              fallbackClass="panel-panel-img-alt"
              fallbackText={firstLetter}
            />
            <div>
              <div className="panel-panel-name">{`${panel?.firstName} ${panel?.lastName}`}</div>
              <div className="panel-panel-designation panel-panel-gray-text">
                {panel?.designation}
              </div>
              <div className="panel-panel-designation panel-panel-gray-text">{panel?.email}</div>
            </div>
          </div>
        </div>
      );
      break;
    case EMAIL:
      return <div>{panel?.email}</div>;
      break;
    case TECH:
      return (
        <div className="panel-cell-tech-stack-div">
          {!!panel?.techStackInterviewLevelSet.length ? (
            <div className="ap-tech-container">
              <div className="tech-bg tech-ellipsis">
                {first?.techStack}
                {!!showLOP && `- ${first?.level}`}
              </div>
              {panel?.techStackInterviewLevelSet.length > 1 && (
                <div className="tech-bg tech-ellipsis secondTechStack">
                  {second?.techStack}
                  {!!showLOP && `-${second?.level}`}
                </div>
              )}
            </div>
          ) : null}
          {panel?.techStackInterviewLevelSet.length > 2 ? (
            <Popover
              isOpen={showTooltip && tooltip}
              positions={['top', 'bottom']}
              containerClassName="select-popover"
              reposition
              clickOutsideCapture
              content={
                <div>
                  <div className="tooltipCountTS">
                    {rest?.map((item: ITSStates, i: number) => (
                      <span key={i}>
                        {item?.techStack} - {item?.level}
                      </span>
                    ))}
                  </div>
                </div>
              }
              onClickOutside={handleMouseOut}>
              <div className="tooltip-container">
                <div
                  className="tech-plus"
                  onMouseEnter={handleMouseIn}
                  onMouseLeave={handleMouseOut}>
                  +{panel?.techStackInterviewLevelSet.length - 2}
                </div>
              </div>
            </Popover>
          ) : null}
        </div>
      );
      break;
    case LEVELCASE:
      return (
        <div className="panel-cell-tech-stack-div">
          {!!panel?.techStackInterviewLevelSet?.length ? (
            <div className="ap-tech-container">
              <div className="tech-bg tech-ellipsis">{first?.level}</div>
              {panel?.techStackInterviewLevelSet?.length > 1 && (
                <div className="tech-bg tech-ellipsis secondTechStack">{second?.level}</div>
              )}
            </div>
          ) : null}
          {panel?.techStackInterviewLevelSet.length > 2 ? (
            <div className="tech-plus">+{panel?.techStackInterviewLevelSet.length - 2}</div>
          ) : null}
        </div>
      );

    case EXP:
      return <div>{getExperience(panel?.totalExperience)}</div>;

    case PROJECT_TAB:
      return (
        <div className="panel-cell-project-stack-div">
          <div
            className={
              panel?.projects?.length ? 'project-bg project-padding' : 'ap-on-bench-common'
            }>
            {panel?.projects?.length ? (
              <Popover
                isOpen={showProjTooltip && tooltip}
                positions={['top', 'bottom']}
                containerClassName="select-popover"
                reposition
                clickOutsideCapture
                content={
                  <div>
                    <div className="tooltipCountTS">{firstProject?.name}</div>
                  </div>
                }
                onClickOutside={handleProjMouseOut}>
                <div className="tooltip-container-proj">
                  <div
                    className="project-bg"
                    onMouseEnter={handleProjMouseIn}
                    onMouseLeave={handleProjMouseOut}>
                    <span className="text">{firstProject?.name}</span>
                  </div>
                </div>
              </Popover>
            ) : (
              ON_BENCH
            )}
          </div>
          {panel?.projects?.length > 1 ? (
            <Popover
              isOpen={showTooltip && tooltip}
              positions={['top', 'bottom']}
              containerClassName="select-popover"
              reposition
              clickOutsideCapture
              content={
                <div>
                  <div className="tooltipCountTS">
                    {restProj?.map((item: IProjState, i: number) => (
                      <span key={i}>{item?.name}</span>
                    ))}
                  </div>
                </div>
              }
              onClickOutside={handleMouseOut}>
              <div className="tooltip-container">
                <div
                  className="project-bg project-plus"
                  onMouseEnter={handleMouseIn}
                  onMouseLeave={handleMouseOut}>
                  +{panel?.projects?.length - 1}
                </div>
              </div>
            </Popover>
          ) : null}
        </div>
      );
    case STATUS:
      return (
        <div className="statusCol">
          {panel?.statusEnum === STATUSENUM_IN_REVIEW ? (
            <div className="dropdownStatus">
              <CustomSelect
                dropdownOptions={options}
                selectedOptions={props.value}
                onChange={handleStatusChange}
                customStyles={statusStyles}
                dropDownIndicatorColor="orange"
                showDropdownIndicator
                isSearchable={false}
                searchOption={showOption}
              />
            </div>
          ) : panel?.statusEnum === STATUSENUM_APPROVED ? (
            <div className="approve">
              <img src={dot_green} /> {APPROVED_TAB}
            </div>
          ) : (
            <div className="onboard" onClick={handleOnboardUser}>
              {ONBOARD_TEXT}
            </div>
          )}
        </div>
      );

    default:
      return <img className="profile-arrow panel-panel-gray-text" src={arrowRight} />;
  }
};

export default RowCreator;
