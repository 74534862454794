import React, { Fragment } from 'react';

interface IProjectsDetailsProps {
  projects: { id: string; name: string }[];
}

const ProjectsDetails = (props: IProjectsDetailsProps) => {
  const { projects } = props;

  return (
    <Fragment>
      {projects?.slice(0, 2)?.map((item, index: number) => (
        <div className="single-tag project" key={index}>
          {item.name}
        </div>
      ))}
    </Fragment>
  );
};

export default ProjectsDetails;
