import React, { Component } from 'react';
import ImageComponent from '../image';
import Typography from '../typography';
import './index.style.scss';
import Images from '../../../../assets/images';

interface IProfileDetailsProps {
  profileImageUrl?: string;
  name: string;
  designation: string;
  mobile: string;
  email: string;
  experience?: string;
  onDelete?: () => void;
  showExperience?: any;
}

function ProfileDetails(props: IProfileDetailsProps) {
  const { profileImageUrl, name, designation, mobile, email, onDelete, showExperience } = props;

  const { delete: Delete } = Images;
  const fallBackImageText = name.charAt(0).toUpperCase();

  return (
    <div className="drawer-header-style">
      <div className="drawer-top-item">
        <ImageComponent
          src={profileImageUrl || null}
          className="drawer-profile-avatar"
          fallbackClass="drawer-profile-avatar no-image"
          fallbackText={fallBackImageText}
        />

        <Typography customStyle="drawer-profile-name">{name}</Typography>
        <Typography customStyle="drawer-profile-text">{designation}</Typography>
        <Typography customStyle="drawer-profile-text">{email}</Typography>
        {showExperience && (
          <Typography customStyle="drawer-profile-text">{showExperience}</Typography>
        )}
        <Typography customStyle="drawer-profile-text">{mobile}</Typography>
      </div>
    </div>
  );
}

export default ProfileDetails;
