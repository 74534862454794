import React, { HTMLAttributes, useCallback } from 'react';
import Typography from '../typography';
import Images from '../../../../assets/images';
import ImageComponent from '../image';
import './index.style.scss';

interface props extends HTMLAttributes<HTMLDivElement> {
  label: string;
  leftIcon?: string;
  rightIcon?: string;
  removable?: boolean;
  disabled?: boolean;
  onRemove?: (label: string, dataValue: string | null) => void;
  customClass?: string;
  dataValue?: string;
}

function Tag(props: props) {
  const {
    label,
    removable,
    onRemove,
    leftIcon,
    rightIcon,
    disabled,
    customClass,
    dataValue,
    ...otherProps
  } = props;

  const { close } = Images;

  const tagClass = disabled
    ? `tag-disabled tag-container ${customClass}`
    : `tag-container ${customClass}`;

  const handleRemove = useCallback(
    function handleRemove(event: any) {
      const { disabled, onRemove, label, dataValue = null } = props;
      event.stopPropagation();
      if (!disabled) {
        onRemove && onRemove(label, dataValue);
      }
    },
    [disabled, onRemove, label, dataValue]
  );

  return (
    <div className={tagClass} {...otherProps}>
      {leftIcon && <ImageComponent src={leftIcon} draggable={false} customClass="tag-icon" />}
      <Typography customStyle="tag-label">{label}</Typography>
      {rightIcon && <ImageComponent src={rightIcon} draggable={false} customClass="tag-icon" />}
      {removable && (
        <div className="tag-icon-wrapper" onClick={handleRemove}>
          <ImageComponent src={close} customClass="tag-icon" draggable={false} />
        </div>
      )}
    </div>
  );
}

Tag.defaultProps = {
  variant: 'primary',
  removable: false,
  disabled: false
};

export default Tag;
