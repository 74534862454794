import React, {
  MouseEvent,
  ChangeEvent,
  InputHTMLAttributes,
  useRef,
  forwardRef,
  useImperativeHandle,
  RefObject,
  TextareaHTMLAttributes,
  useCallback,
  useState,
  FocusEvent
} from 'react';
import '../index.style.scss';
import ImageComponent from 'app/core/shared-components/image';
import { INPUT_PLACEHOLDER } from 'helpers/messages.constants';
import { HTMLInputTextareaProps, HTMLInputProps, HTMLTextareaProps } from 'helpers/types';

interface inputProps extends HTMLInputTextareaProps {
  customClass?: string;
  startIcon?: string;
  endIcon?: string;
  multiline?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onStartIconClick?: (event: MouseEvent<HTMLDivElement>) => void;
  onEndIconClick?: (event: MouseEvent<HTMLDivElement>) => void;
  rows?: number;
  wrap?: 'hard' | 'soft';
  inputProps?: HTMLInputProps;
  textAreaProps?: HTMLTextareaProps;
  onFocus?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const Input = forwardRef((props: inputProps, ref) => {
  const inputRef: RefObject<HTMLInputElement> | RefObject<HTMLTextAreaElement> = useRef(null);

  useImperativeHandle(ref, () => inputRef.current, []);

  const [customInputWrapperClass, setCustomInputWrapperClass] = useState<string>(
    props.disabled ? 'input-wrapper disabled' : 'input-wrapper '
  );

  const {
    customClass,
    startIcon,
    onStartIconClick,
    multiline,
    endIcon,
    onEndIconClick,
    rows,
    wrap,
    onChange,
    inputProps,
    textAreaProps,
    ...otherProps
  } = props;

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { onChange, disabled } = props;
      !disabled && onChange && onChange(event);
    },
    [props.onChange]
  );

  const handleTextAreaChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const { onChange, disabled } = props;
      !disabled && onChange && onChange(event);
    },
    [props.onChange]
  );

  const handleInputFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const { onFocus } = props;
      setCustomInputWrapperClass('input-wrapper focus');
      onFocus && onFocus(event);
    },
    [props.onFocus]
  );

  const handleTextAreaFocus = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      const { onFocus } = props;
      setCustomInputWrapperClass('input-wrapper focus');
      onFocus && onFocus(event);
    },
    [props.onFocus]
  );

  const handleTextAreaBlur = useCallback(
    (event: FocusEvent<HTMLTextAreaElement>) => {
      const { onBlur } = props;
      setCustomInputWrapperClass('input-wrapper');
      onBlur && onBlur(event);
    },
    [props.onBlur]
  );

  const handleInputBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const { onBlur } = props;
      setCustomInputWrapperClass('input-wrapper');
      onBlur && onBlur(event);
    },
    [props.onBlur]
  );

  return (
    <div className={`${customClass} ${customInputWrapperClass}`}>
      {startIcon && (
        <div className="start-icon" onClick={onStartIconClick}>
          <ImageComponent src={startIcon} className="icon" />
        </div>
      )}
      {multiline ? (
        <textarea
          ref={inputRef as RefObject<HTMLTextAreaElement>}
          className="input-field"
          rows={rows}
          wrap={wrap}
          onChange={handleTextAreaChange}
          onFocus={handleTextAreaFocus}
          onBlur={handleTextAreaBlur}
          {...otherProps}
          {...textAreaProps}
        />
      ) : (
        <input
          ref={inputRef as RefObject<HTMLInputElement>}
          className="input-field"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          {...otherProps}
          {...inputProps}
        />
      )}
      {endIcon && (
        <div className="end-icon" onClick={onEndIconClick}>
          <ImageComponent src={endIcon} />
        </div>
      )}
    </div>
  );
});

Input.defaultProps = {
  row: 2,
  placeholder: INPUT_PLACEHOLDER
} as inputProps;

export default Input;
