import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import moment from 'moment';
import { toast } from 'react-toastify';
import Typography from 'app/core/shared-components/typography';
import ImageComponent from 'app/core/shared-components/image';
import Images from 'assets/images';
import InputBox from 'app/core/shared-components/input-box';
import CheckBox from 'app/core/shared-components/checkbox';
import Button from 'app/core/shared-components/button';
import ComponentLoader from '../loader-component';
import ToastUndo from 'app/core/shared-components/toast-undo';
import Radio from 'app/core/shared-components/radio-group';
import Modal from 'app/core/shared-components/modal';
import {
  getSlotSummaryData,
  getUpcomingInterviews,
  IUpcomingInterviewData
} from 'app/redux/slices/adminDashboardSlice';
import { cancelSlot, fetchSpecificBookedSlot } from 'app/redux/slices/slotsSlice';
import { notify } from 'helpers/toastHelper';
import {
  CANCELLATION_REASON,
  CANCEL_BOOKING,
  CANDIDATE_BUSY,
  CANDIDATE_CANCEL_ENUM,
  DMY_FORMAT,
  OTHERS_ENUM,
  OTHER_BUSY,
  PANEL_BUSY,
  PANEL_CANCEL_ENUM,
  REMARK,
  YMDH_FORMAT,
  ZOHO_RECRUIT
} from 'helpers/constants';
import './index.style.scss';
import { INTERVIEW_CANCELLED, MAKE_SLOT_AVAILABLE } from 'helpers/messages.constants';

interface ICancelCardProps {
  slotId: string;
  onCloseClick?: () => void;
  onCancelClick?: (id: string) => void;
}

export interface ICancel {
  slotDetails: IUpcomingInterviewData;
  cancelRemark: string;
  cancelReason: string;
  makeSlotAvailable: boolean;
}

interface IInterviewCancelCardStates extends ICancel {
  label: string;
}

function InterviewCancelCard(props: ICancelCardProps) {
  const [cancelCardState, setCancelCardState] = useState<IInterviewCancelCardStates>({
    label: ``,
    cancelRemark: '',
    cancelReason: PANEL_BUSY,
    makeSlotAvailable: false,
    slotDetails: {} as IUpcomingInterviewData
  });
  const { avatarGray, clockGray, techStackGray } = Images;
  const { userId, role } = useAppSelector((state) => state.user.userData);
  const { isFetchSlotLoading, selectedBookedSlot } = useAppSelector((state) => state.slots);
  const { currentSelectedDate } = useAppSelector((state) => state.adminDashboard);
  const { slotDetails } = cancelCardState;
  const dispatch = useAppThunkDispatch();

  const handleChange = (param: string | number) => {
    setCancelCardState((prevState) => ({ ...prevState, cancelRemark: param.toString() }));
  };

  const handleCheckBoxClick = () => {
    setCancelCardState((prevState) => ({
      ...prevState,
      makeSlotAvailable: !prevState.makeSlotAvailable
    }));
  };

  const handleCancelClick = () => {
    props.onCloseClick && props.onCloseClick();
    props.onCancelClick && props.onCancelClick(slotDetails.slotId);
    notify(
      false,
      <ToastUndo
        toastMessage={INTERVIEW_CANCELLED}
        handleUndo={onUndoDelete}
        toastId={parseInt(userId.toString())}
      />,
      parseInt(userId.toString()),
      cancelInterview
    );
  };

  const onUndoDelete = () => {
    dispatch(getUpcomingInterviews(userId.toString()));
    toast.dismiss(parseInt(userId.toString()));
    toast.update(parseInt(userId.toString()), { onClose: () => {}, hideProgressBar: true });
  };

  const cancelInterview = () => {
    if (
      cancelCardState.cancelReason !== OTHER_BUSY ||
      (cancelCardState.cancelReason === OTHER_BUSY && cancelCardState.cancelRemark.trim().length)
    ) {
      dispatch(
        cancelSlot({
          userId: userId.toString(),
          slotId: slotDetails.slotId,
          cancelComments: cancelCardState.cancelRemark,
          cancelOption:
            cancelCardState.cancelReason === CANDIDATE_BUSY
              ? CANDIDATE_CANCEL_ENUM
              : cancelCardState.cancelReason === PANEL_BUSY
              ? PANEL_CANCEL_ENUM
              : OTHERS_ENUM,
          makeSlotAvailable: cancelCardState.makeSlotAvailable
        })
      ).then(() => {
        if (
          moment(slotDetails.date).format(DMY_FORMAT) ===
          moment(currentSelectedDate).format(DMY_FORMAT)
        ) {
          dispatch(getSlotSummaryData(moment(currentSelectedDate).format(YMDH_FORMAT)));
        }

        dispatch(getUpcomingInterviews(userId.toString()));
      });
    }
  };

  const handleReasonChange = (val: string) => {
    setCancelCardState((prevState) => ({
      ...prevState,
      cancelReason: val
    }));
  };
  const handleSelectedSlot = () => {
    const selectedSlotData = {
      slotId: props?.slotId,
      date: selectedBookedSlot?.date,
      fromTime: selectedBookedSlot?.fromTime,
      toTime: selectedBookedSlot?.toTime,
      candidateName: selectedBookedSlot?.candidateName,
      techStack: selectedBookedSlot?.techStackInterviewLevelDto?.techStack,
      level: selectedBookedSlot?.techStackInterviewLevelDto?.level,
      zohoRecruitUrl: selectedBookedSlot?.zohoRecruitUrl
    };
    setCancelCardState((prevState) => ({ ...prevState, slotDetails: selectedSlotData }));
  };

  useEffect(() => {
    dispatch(fetchSpecificBookedSlot(props.slotId));
  }, [props.slotId]);

  useEffect(() => {
    handleSelectedSlot();
  }, [selectedBookedSlot]);

  return (
    <Modal customStyle="slot-cancel-card" isOpen={true} onClose={props.onCloseClick}>
      {isFetchSlotLoading ? (
        <ComponentLoader />
      ) : (
        <>
          <div className="slot-cancel-card-header">
            <div className="close-icon-wrapper" onClick={props.onCloseClick}>
              <ImageComponent src={Images.close} alt="Close Icon" />
            </div>
            <div className="slot-cancel-title">
              <div>Cancel Interview</div>
            </div>
            <div className="interview-detail-card">
              <Typography variant="headline-16" customStyle="date-text">
                {moment(slotDetails.date).format('DD MMMM YYYY')}
              </Typography>
              <div className="candidate-details">
                <div className="name">
                  <div className="candidate-name">
                    <ImageComponent src={avatarGray} />
                    <Typography variant="headline-16">{slotDetails.candidateName}</Typography>
                  </div>
                  {slotDetails?.zohoRecruitUrl && (
                    <a
                      href={`${slotDetails?.zohoRecruitUrl}`}
                      className="slot-cancel-icon-wrapper"
                      target="_blank"
                      rel="noreferrer">
                      <div className="link">{ZOHO_RECRUIT}</div>
                    </a>
                  )}
                </div>
                <div className="timings">
                  <ImageComponent src={clockGray} />
                  <Typography customStyle="times">{`${moment(slotDetails.fromTime).format(
                    'hh:mm A'
                  )} - ${moment(slotDetails.toTime).format('hh:mm A')}`}</Typography>
                  <ImageComponent src={techStackGray} />
                  <Typography customStyle="times">
                    {slotDetails.techStack} {slotDetails.level}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="slot-cancel-card-main">
            <div className="slot-cancel-card-content">
              {!!role.filter((item) => item.role === 'ROLE_ADMIN').length && (
                <>
                  <Typography customStyle="cancel-reason-label">{CANCELLATION_REASON}</Typography>
                  <div className="radio-input-field">
                    <Radio
                      labelArray={[CANDIDATE_BUSY, PANEL_BUSY, OTHER_BUSY]}
                      setCurrentValue={handleReasonChange}
                      currentValue={cancelCardState.cancelReason}
                    />
                  </div>
                </>
              )}

              <Typography customStyle="cancel-reason-label">
                {REMARK + cancelCardState.cancelReason === OTHER_BUSY && `*`}
              </Typography>

              <InputBox
                multiline
                placeholder="Please enter a reason for slot cancellation"
                customClass="cancel-input-box"
                value={cancelCardState.cancelRemark}
                handleChange={handleChange}
              />

              {!!role.filter((item) => item.role === 'ROLE_ADMIN').length &&
                cancelCardState.cancelReason !== PANEL_BUSY && (
                  <div className="slot-cancel-checkbox-wrapper">
                    <CheckBox handleClick={handleCheckBoxClick} tabIndex />
                    <Typography>{MAKE_SLOT_AVAILABLE}</Typography>
                  </div>
                )}
            </div>
            <Button
              variant="outlined"
              customStyle="slot-cancel-card-action-btn"
              disabled={
                cancelCardState.cancelReason === 'Other' &&
                !cancelCardState.cancelRemark.trim().length
              }
              onClick={handleCancelClick}>
              {CANCEL_BOOKING}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}

export default InterviewCancelCard;
