import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import './index.style.scss';
import { v4 as uuidv4 } from 'uuid';
import moment, { Moment } from 'moment';
import SlotRadioGroup, { IPanelOptions } from '../slot-radio-group';
import Typography from 'app/core/shared-components/typography';
import ImageComponent from 'app/core/shared-components/image';
import DeleteIcon from 'assets/images/delete.svg';
import NoRequestedSlot from 'assets/images/no-result-found.svg';
import CloseIcon from 'assets/images/modal-close-icon.svg';
import Images from 'assets/images';
import { ReactComponent as LoaderSpinner } from 'assets/images/loader-spinner.svg';
import InputBox from 'app/core/shared-components/input-box';
import Button from 'app/core/shared-components/button';
import ConfirmationModal from 'app/core/shared-components/confirmation-modal';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  bookRequestedSlot,
  bookSlot,
  deleteAvailableSlot,
  fetchAllSlots,
  fetchAvailableSlots,
  fetchBookedSlots,
  fetchRequestedSlots,
  toggleToastVisibility
} from 'app/redux/slices/slotsSlice';
import './index.style.scss';
import {
  ALL,
  ASCII,
  AVAILABLE,
  AVAILABLE_SLOT,
  BOOKED,
  CANDIDATE_NAME,
  INTERVIEW_DETAILS
} from 'helpers/constants';
import { headerDetailsForSlots } from 'helpers/utils';
import {
  NO_SPECIAL_CHARACTERS_ALLOWED,
  SLOT_MARKED_AS_BOOKED,
  MARK_AS_BOOKED,
  NO_RESPONSES_RECEIVED,
  SLOT_DELETED_SUCCESSFULLY,
  SLOT_DELETE_CONFIRMATION
} from 'helpers/messages.constants';
import { ISelectedSearchProps } from 'app/core/shared-components/button-group';
import { ISlots } from '../slot-carousel';
import { ISelectOptions } from 'app/core/shared-components/customSelect';
import { formatTime, generateTimeSlots } from 'helpers/dateHelper';
import { notify } from 'helpers/toastHelper';
import TechLevelRadioGroup from '../tech-level-radio-group';
import { timeStamps } from 'helpers/timeStamp.constants';

interface IPanelistProps {
  designation?: string;
  emailId?: string;
  experience?: number;
  firstName?: string;
  imageUrl?: string;
  lastName?: string;
  levelOfInterview?: string;
  mobileNo?: string;
  slotId?: string;
  userId?: string;
  level?: string[];
  profileImageUrl?: string;
}

interface ISlotRequestCardProps {
  date?: string;
  panelList: IPanelistProps[];
  hideDelete?: boolean;
  handleClose?: () => void;
  deleteSlotRequest?: (requestId: string) => void;
  showTag?: boolean;
  tag?: string;
  requestId?: string;
  isSearch?: boolean;
  slotId?: string[] | string;
  dateFilter?: {
    from: string;
    to: string;
  }[];
  slotCategory?: string;
  fromTime: string;
  toTime: string;
  currentSlot?: ISlots;
  selectedUser?: ISelectedSearchProps;
  showModal?: boolean;
  handleShowModal?: () => void;
  isRequest?: boolean;
}

export interface ISelectedSlotState {
  startTime: ISelectOptions;
  endTime: ISelectOptions;
}

const SlotRequestCard = ({
  date,
  panelList = [],
  hideDelete,
  handleClose,
  showTag,
  tag,
  requestId,
  deleteSlotRequest,
  isSearch,
  slotId,
  dateFilter,
  slotCategory,
  fromTime,
  toTime,
  currentSlot,
  selectedUser,
  showModal,
  handleShowModal,
  isRequest = false
}: ISlotRequestCardProps) => {
  const [currentSelectedPanel, setCurrentSelectedPanel] = useState<number>(0);
  const [currentSelectedTechLevel, setCurrentSelectedTechLevel] = useState<number>(0);
  const [candidateName, setCandidateName] = useState<string>('');
  const [candidateNameError, setCandidateNameError] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedSlot, setSelectedSlot] = useState<ISelectedSlotState>({
    startTime: {} as ISelectOptions,
    endTime: {} as ISelectOptions
  });
  const dispatch = useAppThunkDispatch();
  const { filterValue, isFetchSlotLoading, currentSlots, isUndoToastVisible } = useAppSelector(
    (state) => state.slots
  );

  const { availableSlot } = Images;

  const panelData = panelList?.map((panel) => {
    return {
      id: panel?.userId?.toString(),
      image: panel?.imageUrl || panel?.profileImageUrl,
      name: `${panel?.firstName} ${panel?.lastName}`,
      designation: panel?.designation,
      level: panel?.levelOfInterview || panel?.level,
      experience: panel?.experience
    };
  });

  const techLevelData = currentSlots?.data?.userDetails?.techStackInterviewLevelSet?.map(
    (techLevel) => {
      return {
        techStack: { id: techLevel?.techStackId, label: techLevel?.techStack },
        level: { id: techLevel?.levelId, label: techLevel?.level }
      };
    }
  );

  const techStackLOP = techLevelData && techLevelData[currentSelectedTechLevel];

  const { startTime, endTime } = selectedSlot;

  const handleCandidateNameChange = (val: string | number) => {
    if (!/^[A-Za-z ]+$/.test(val.toString()) && val.toString()?.length) {
      setCandidateNameError(NO_SPECIAL_CHARACTERS_ALLOWED);
    } else {
      setCandidateNameError('');
    }
    setCandidateName(val.toString());
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const deleteAvailableSlots = () => {
    if (isSearch) {
      const response =
        currentSlot &&
        dispatch(deleteAvailableSlot([currentSlot?.slotId])).then((res) => {
          slotCategory === ALL
            ? dispatch(fetchAllSlots({ userId: selectedUser?.id, dateTime: dateFilter }))
            : dispatch(fetchAvailableSlots({ userId: selectedUser?.id, dateTime: dateFilter }));
        });
      if (response) {
        setIsModalOpen(false);
        notify(true, SLOT_DELETED_SUCCESSFULLY, uuidv4().toString(), handleToastClose);
        handleClose && handleClose();
      }
      return;
    }
    const response =
      slotId &&
      dispatch(deleteAvailableSlot(slotId)).then((res) => {
        slotCategory === ALL
          ? dispatch(fetchAllSlots(filterValue))
          : dispatch(fetchAvailableSlots(filterValue));
      });
    if (response) {
      setIsModalOpen(false);
      notify(true, SLOT_DELETED_SUCCESSFULLY, uuidv4().toString(), handleToastClose);
      handleClose && handleClose();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ASCII.enter && !(!candidateName.trim().length || !!candidateNameError)) {
      handleSlotBooking();
    }
  };

  const deleteSlot = () => {
    if (requestId) {
      setIsModalOpen(false);
      deleteSlotRequest && deleteSlotRequest(requestId);
    }
  };

  const handleToastClose = () => {
    dispatch(toggleToastVisibility(''));
  };

  const handleSlotBooking = () => {
    if (requestId) {
      const userId = panelList[currentSelectedPanel]?.userId;
      dispatch(
        bookRequestedSlot({
          slotRequestId: requestId,
          userId: userId as string,
          candidateName: candidateName
        })
      ).then(({ payload }) => {
        if (payload?.data) {
          dispatch(fetchRequestedSlots());
          if (slotCategory === BOOKED) {
            dispatch(fetchBookedSlots(filterValue));
          } else if (slotCategory === AVAILABLE) {
            dispatch(fetchAvailableSlots(filterValue));
          }
          notify(true, SLOT_MARKED_AS_BOOKED, uuidv4().toString(), handleToastClose);
          handleClose && handleClose();
        }
      });
    } else {
      if (isSearch) {
        const selectedSlotId = slotId;
        if (selectedSlotId) {
          dispatch(
            bookSlot({
              slotId: selectedSlotId?.toString(),
              candidateName: candidateName,
              techStackId: techStackLOP?.techStack?.id?.toString(),
              interviewLevelId: techStackLOP?.level?.id?.toString(),
              baseSlotDTO: {
                from: formatTime(startTime?.label, moment(fromTime).toDate()),
                to: formatTime(endTime?.label, moment(toTime).toDate())
              }
            })
          ).then(({ payload }) => {
            if (payload?.data) {
              slotCategory === ALL
                ? dispatch(
                    fetchAllSlots({
                      userId: currentSlots?.data?.userDetails?.id?.toString(),
                      dateTime: dateFilter
                    })
                  )
                : dispatch(
                    fetchAvailableSlots({
                      userId: currentSlots?.data?.userDetails?.id?.toString(),
                      dateTime: dateFilter
                    })
                  );
              notify(true, SLOT_MARKED_AS_BOOKED, uuidv4().toString(), handleToastClose);
              handleClose && handleClose();
            }
          });
        }
      } else {
        const slotId = panelList[currentSelectedPanel]?.slotId;
        if (filterValue?.levelId && filterValue?.techStackId) {
          dispatch(
            bookSlot({
              slotId: slotId as string,
              candidateName: candidateName,
              techStackId: filterValue?.techStackId,
              interviewLevelId: filterValue?.levelId,
              baseSlotDTO: {
                from: formatTime(startTime?.label, moment(fromTime).toDate()),
                to: formatTime(endTime?.label, moment(toTime).toDate())
              }
            })
          ).then(({ payload }) => {
            if (payload?.data) {
              slotCategory === ALL
                ? dispatch(fetchAllSlots(filterValue))
                : dispatch(fetchAvailableSlots(filterValue));
              notify(true, SLOT_MARKED_AS_BOOKED, uuidv4().toString(), handleToastClose);
              handleClose && handleClose();
            }
          });
        }
      }
    }
  };

  const handleRadioGroupChange = (val: number) => {
    setCurrentSelectedPanel(val);
  };

  const handleTechStackLevelRadioGroup = (val: number) => {
    setCurrentSelectedTechLevel(val);
  };

  const handleSelectedSlot = (params: ISelectedSlotState) => {
    setSelectedSlot(params);
  };

  const dropDownOptions = useMemo(() => {
    const toTimeMoment = moment(toTime);
    const toTimeObj = {
      hour: toTimeMoment.hour(),
      minute: toTimeMoment.minute()
    };
    return generateTimeSlots(moment(fromTime).toDate(), false, 15, toTimeObj).map(
      (date: Moment, index: number) => ({
        id: index,
        label: moment(date).format(timeStamps.HH_MM_a)
      })
    );
  }, [fromTime, toTime]);

  const updateSelectedSlot = useCallback(() => {
    if (dropDownOptions) {
      const [
        startTime,
        secondDropDownOption,
        thirdDropDownOption,
        fourthDropDownOption,
        endTime,
        ...rest
      ] = dropDownOptions;
      const selectedEndTime =
        endTime ?? fourthDropDownOption ?? thirdDropDownOption ?? secondDropDownOption;
      setSelectedSlot({
        startTime: startTime,
        endTime: selectedEndTime
      });
    }
  }, [dropDownOptions]);

  const confirmationModal = (
    <ConfirmationModal
      customStyle="slot-delete-modal"
      customBackDropStyle="slot-delete-modal-backdrop"
      isOpen={isModalOpen}
      title="Delete Slot"
      confirmText="Delete"
      description={SLOT_DELETE_CONFIRMATION}
      onConfirm={showModal ? deleteAvailableSlots : deleteSlot}
      onClose={toggleModal}
    />
  );

  const modalContainer = document.getElementById('confirmation-container');

  useEffect(() => updateSelectedSlot(), []);

  return (
    <div className={requestId ? 'slot-request-card slot-request-drawer' : 'slot-request-card'}>
      {isFetchSlotLoading ? (
        <div className="slot-loading-wrapper">
          <LoaderSpinner className="loading-spinner" />
        </div>
      ) : (
        <Fragment>
          <div className={`slot-request-header ${!!requestId ? 'slot-request-card-header' : ''}`}>
            {!!!requestId && (
              <div className="available-slot-icon">
                <ImageComponent src={availableSlot} alt="available-slot" />
              </div>
            )}
            <div className="slot-details">
              {!!!requestId && <div className="slot-details-header">{AVAILABLE_SLOT}</div>}
              <Typography
                variant="headline-16"
                customStyle={!!!requestId ? 'slot-details-headline' : ''}>
                {headerDetailsForSlots(fromTime, toTime)}
              </Typography>
              {showTag && <div className="single-tag">{tag}</div>}
            </div>
            <div className="actions-container">
              <ImageComponent
                src={DeleteIcon}
                customClass="slot-delete-btn"
                onClick={toggleModal}
              />
              {!requestId && (
                <ImageComponent
                  src={CloseIcon}
                  customClass="slot-delete-btn"
                  onClick={handleClose}
                />
              )}
            </div>
          </div>

          {isSearch && currentSlots?.data?.userDetails?.techStackInterviewLevelSet ? (
            <Fragment>
              <Typography variant="headline-16" customStyle="title">
                {INTERVIEW_DETAILS}
              </Typography>

              <TechLevelRadioGroup
                options={techLevelData}
                currentValue={currentSelectedTechLevel}
                setCurrentValue={handleTechStackLevelRadioGroup}
                fromTime={fromTime}
                toTime={toTime}
                onSlotChange={handleSelectedSlot}
                selectOptions={dropDownOptions}
                selectedSlot={selectedSlot}
              />
              <div className="divider"></div>
              <div className="form-field">
                <Typography variant="input-label">Candidate name*</Typography>
                <div className="name-input-field">
                  <InputBox
                    value={candidateName}
                    handleChange={handleCandidateNameChange}
                    onKeyUp={handleKeyPress}
                    autoFocus={true}
                    placeholder="Enter candidate name"
                  />
                </div>
                {candidateNameError && (
                  <Typography variant="error">{candidateNameError}</Typography>
                )}
              </div>
              <div className="slot-btn">
                <Button
                  variant="contained"
                  customStyle="mark-booked-button"
                  onClick={handleSlotBooking}
                  disabled={!candidateName.trim().length || !!candidateNameError}>
                  {MARK_AS_BOOKED}
                </Button>
              </div>
            </Fragment>
          ) : !!panelData?.length ? (
            <Fragment>
              <SlotRadioGroup
                options={panelData}
                currentValue={currentSelectedPanel}
                setCurrentValue={handleRadioGroupChange}
                fromTime={fromTime}
                toTime={toTime}
                onSlotChange={handleSelectedSlot}
                selectOptions={dropDownOptions}
                selectedSlot={selectedSlot}
                showExperience
                displaySplitSlot={isRequest ? !isRequest : !!!requestId}
              />
              {!!!requestId && <div className="divider"></div>}
              <div className="form-field">
                <Typography variant="input-label">{CANDIDATE_NAME}</Typography>
                <div className="name-input-field">
                  <InputBox
                    value={candidateName}
                    handleChange={handleCandidateNameChange}
                    onKeyUp={handleKeyPress}
                    autoFocus={!requestId}
                    placeholder="Enter candidate name"
                  />
                </div>
                {candidateNameError && (
                  <Typography variant="error">{candidateNameError}</Typography>
                )}
              </div>
              <div className="slot-btn">
                <Button
                  variant="contained"
                  customStyle="mark-booked-button"
                  onClick={handleSlotBooking}
                  disabled={!candidateName.trim().length || !!candidateNameError}>
                  {MARK_AS_BOOKED}
                </Button>
              </div>
            </Fragment>
          ) : (
            <div className="no-request-response">
              <ImageComponent src={NoRequestedSlot} />
              <div className="no-result-text">{NO_RESPONSES_RECEIVED}</div>
            </div>
          )}
          {modalContainer ? (
            createPortal(<>{confirmationModal}</>, modalContainer)
          ) : (
            <ConfirmationModal
              customStyle="slot-delete-modal"
              customBackDropStyle="slot-delete-modal-backdrop"
              isOpen={isModalOpen}
              title="Delete Slot"
              confirmText="Delete"
              description={SLOT_DELETE_CONFIRMATION}
              onConfirm={deleteSlot}
              onClose={toggleModal}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};
export default SlotRequestCard;
