import { StylesConfig } from 'react-select';
import { ISelectOptions } from './index';
import { theme } from 'assets/styles/theme';

export const styles: StylesConfig<ISelectOptions> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    boxSizing: 'border-box',
    borderRadius: '0.5rem',
    border: state.isFocused ? theme.primaryBorder : theme.checkboxBorder,
    minHeight: '3rem',
    backgroundColor: state.isDisabled ? theme.bgColor : 'inherit',
    justifyContent: 'flex-start',
    gap: '0.625rem',
    minWidth: '7.5rem',
    boxShadow: state.isFocused ? theme.inputBoxShadow : theme.boxShadow,
    padding: '0.25rem 0.5rem 0.25rem 1rem',
    color: theme.textColorPrimary,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'none'
    }
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    backgroundColor: state.isDisabled ? theme.bgColor : theme.white
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    padding: 0,
    backgroundColor: theme.white,
    cursor: 'pointer',
    color: state.isSelected ? theme.primary : theme.textColorPrimary,
    fontWeight: 400
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: theme.textColorPrimary
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    border: theme.menuBorder,
    boxShadow: theme.menuBoxShadow,
    zIndex: 999,
    borderRadius: '0.5rem'
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    msOverflowStyle: 'none',
    scrollbarwidth: 'none',
    borderRadius: '0.5rem',
    padding: 0,
    '::-webkit-scrollbar': {
      display: 'none'
    }
  }),
  valueContainer: (baseStyles, state: any) => ({
    ...baseStyles,
    backgroundColor: state.isDisabled ? theme.bgColor : 'inherit',
    borderRadius: '0.5rem',
    padding: 0,
    gap: '0.625rem',
    flexWrap: 'nowrap'
  }),
  input: (baseStyles, state) => ({
    ...baseStyles
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: theme.textColor2
  }),
  indicatorsContainer: (baseStyles, state) => ({
    ...baseStyles,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0 0.125rem 0 0'
  }),
  indicatorSeparator: (baseStyles, state) => ({
    display: 'none'
  }),
  multiValue: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isDisabled ? theme.bgColor : theme.white,
    border: theme.checkboxBorder,
    borderRadius: '0.5rem',
    padding: '0.5rem 0.5rem 0.5rem 1rem',
    maxHeight: '2rem',
    minHeight: '2rem',
    alignItems: 'center',
    boxShadow: theme.boxShadow,
    margin: 0,
    gap: '0.5rem'
  }),
  multiValueLabel: (baseStyles, state) => ({
    ...baseStyles,
    padding: 0,
    paddingLeft: 0,
    cursor: 'pointer'
  }),
  multiValueRemove: (baseStyles, state) => ({
    ...baseStyles,
    cursor: 'pointer',
    backgroundColor: state.isDisabled ? theme.bgColor : theme.white,
    alignItems: 'center',
    padding: 0,
    '&:hover': {
      backgroundColor: state.isDisabled ? theme.bgColor : theme.white
    }
  }),
  menuPortal: (baseStyles, state) => ({
    ...baseStyles,
    zIndex: 999
  })
};
export const selectStyles: StylesConfig<ISelectOptions> = {
  option: (styles) => {
    return {
      ...styles,
      padding: 0,
      ':active': {
        ...styles[':active'],
        color: theme.white,
        fontWeight: theme.weight500,
        backgroundColor: theme.activeSelectOption
      }
    };
  }
};
