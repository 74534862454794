import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DATE_FORMAT, SEARCH } from 'helpers/constants';
import formatDate from 'helpers/dateHelper';
import { notify } from 'helpers/toastHelper';
import { editPaneUserService } from 'app/services/common.service';
import {
  deletePanelService,
  deleteRecruiterService,
  employeeCount,
  employeeSummaryService,
  fetchOnboardUsersList,
  fetchPanelUsersList,
  fetchUserRoleList,
  getRewardsService,
  getSlotSummaryService,
  getUpcomingInterviewsData,
  ISlotEfficiencyBody,
  searchFilter,
  slotEfficiencyService,
  userDataFromIdService,
  getSlotAchievementsDataService,
  ISlotRequirementBody,
  postSlotRequirementService
} from 'app/services/home.service';
import { deleteSlot, editSlot, ISlots } from 'app/services/schedule.service';
import {
  IUserListDataType,
  ITechStackType,
  IUserDataByIdType
} from 'app/services/user-response.types';
import {
  ADMIN_DASHBOARD,
  CHANGE_SUMMARY,
  DELETE_USER,
  EDIT_USER,
  GET_REWARDS_DATA,
  GET_SLOT_SUMMARY_DATA,
  GET_SUMMARY,
  GET_UPCOMING_INTERVIEWS,
  GET_USER,
  GET_USER_LIST
} from 'app/redux/constant';
import { SLOT_OVERLAP_TOAST_MESSAGE } from 'helpers/messages.constants';
import { RootState } from 'app/redux/store';
import { IEvents } from 'app/redux/slices/scheduleSlice';

export interface IOptionType {
  startIcon?: string;
  searchKey: string;
  supportingData?: string;
  tags?: string[];
  id: string;
  singleSearchData: IUserListDataType;
}

export interface ISlotEfficiencyData {
  required: number;
  provided: number;
  month: number;
  year: number;
  booked: number;
  cancelled: number;
}

export interface IListType {
  firstName: string;
  lastName: string;
  designation: string;
  addedOn: string;
  userId: string | number;
}

export interface IRewardsUser {
  firstName: string;
  lastName: string;
  profileImage: string | null;
  designation: string;
  points: number;
  rank: number;
}
export interface IRewardsWidgetData {
  rank: number;
  userPoints: number;
  rewardsRankingDtoList: IRewardsUser[];
}

export interface IUpcomingInterviewData {
  slotId: string;
  date: string;
  fromTime: string;
  toTime: string;
  candidateName: string;
  techStack: string;
  level: string;
  zohoRecruitUrl: string;
}

export interface ISlotSummaryType {
  from: string;
  to: string;
  slotId: string;
  status: number;
  techStack: {
    techStackId: 0;
    techStackName: string;
    levelOfPanel: string;
  }[];
  eventSummary: string;
  isCalendarEvent: boolean;
  isAllDayEvent: boolean;
}

interface IAdminDashboardStates {
  panelCount: string;
  recruiterCount: string;
  techStackCount: string;
  pendingList: IListType[];
  approvedList: IListType[];
  userDataById: IUserDataByIdType;
  clearResult: boolean;
  searchOptions: IOptionType[];
  selectedSearch: any;
  selectedPendingApproval: IUserDataByIdType;
  isPendingApprovalClicked: boolean;
  mountHome: boolean;
  isDrawerOpen: boolean;
  isHomeLoading: boolean;
  isError: boolean;
  isTechDrawerOpen: boolean;
  slotEfficiencyData: ISlotEfficiencyData[];
  isSlotEfficiencyError: boolean;
  isSlotEfficiencyLoading: boolean;
  panelUserList?: any;
  onboardUserList?: any;
  isOnboardUserListLoading?: any;
  isUserDataLoading?: boolean;
  isPanelUserListLoading: boolean;
  pendingDeletionData: any[];
  rewardsWidgetData: IRewardsWidgetData;
  isRewardsDataLoading: boolean;
  upcomingInterviewData: IUpcomingInterviewData[];
  isUpcomingInterviewLoading: boolean;
  currentSelectedInterview: IUpcomingInterviewData;
  isCancelModalOpen: boolean;
  showNotification: boolean;
  employeeCount: any;
  slotSummaryData: any;
  isSlotSummaryLoading: boolean;
  availableSlotSummary: IEvents[];
  requestedSlotSummary: IEvents[];
  currentSelectedDate: string;
  pendingSlotDeletion: IEvents[];
  editSlotError: boolean;
  isSlotAcheiveMentError: boolean;
  isSlotAcheiveMentLoading: boolean;
  isSearchError: boolean;
  isSlotSummaryError: boolean;
  isOnboardUserTabError: boolean;
  isPanelUserTabError: boolean;
}

const initialState: IAdminDashboardStates = {
  panelCount: '0',
  recruiterCount: '0',
  techStackCount: '0',
  pendingList: [],
  approvedList: [],
  clearResult: false,
  userDataById: {} as IUserDataByIdType,
  searchOptions: [],
  selectedSearch: null,
  selectedPendingApproval: {} as IUserDataByIdType,
  isPendingApprovalClicked: false,
  mountHome: false,
  isDrawerOpen: false,
  isHomeLoading: false,
  isError: false,
  isTechDrawerOpen: false,
  slotEfficiencyData: [] as ISlotEfficiencyData[],
  isSlotEfficiencyError: false,
  panelUserList: [],
  onboardUserList: [],
  isSlotEfficiencyLoading: false,
  isOnboardUserListLoading: false,
  isPanelUserListLoading: false,
  isUserDataLoading: false,
  pendingDeletionData: [],
  rewardsWidgetData: {} as IRewardsWidgetData,
  isRewardsDataLoading: false,
  upcomingInterviewData: [] as IUpcomingInterviewData[],
  isUpcomingInterviewLoading: false,
  currentSelectedInterview: {} as IUpcomingInterviewData,
  isCancelModalOpen: false,
  showNotification: true,
  employeeCount: {},
  slotSummaryData: {},
  isSlotSummaryLoading: false,
  availableSlotSummary: [] as IEvents[],
  currentSelectedDate: '',
  pendingSlotDeletion: [],
  editSlotError: false,
  isSlotAcheiveMentError: false,
  isSlotAcheiveMentLoading: false,
  isSearchError: false,
  requestedSlotSummary: [],
  isSlotSummaryError: false,
  isOnboardUserTabError: false,
  isPanelUserTabError: false
};

export const getEmployeeSummary = createAsyncThunk(
  GET_SUMMARY,
  async function employeeSummary(fromDate: string, thunkAPI) {
    const toDate = new Date().toLocaleDateString(DATE_FORMAT);
    const data = await employeeSummaryService(fromDate, toDate);
    if (!data?.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const handleSearch = createAsyncThunk(
  SEARCH,
  async function handleSearch(params: any, thunkAPI) {
    const { searchString, abortSignal } = params;
    const data = await searchFilter(searchString, abortSignal?.signal);
    if (!data.error) {
      return data;
    } else if (data.error && data.error[0].code === 1008) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  }
);

export const getUserData = createAsyncThunk(
  GET_USER,
  async function getUserDataById(id: string | number, thunkAPI) {
    const data = await userDataFromIdService(id.toString());
    if (!data?.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);
export const getEmployeeCount = createAsyncThunk(
  'GET_EMPLOYEE_COUNT',
  async function getEmployeeCounts(undefined, thunkAPI) {
    const data = await employeeCount();
    if (!data?.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const getSlotAchievementsData = createAsyncThunk(
  'GET_SLOT_ACHIEVEMENTS',
  async (undefined, thunkAPI) => {
    const data = await getSlotAchievementsDataService();
    if (!data?.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const editUserData = createAsyncThunk(
  EDIT_USER,
  async function editUserData(body: any, thunkAPI) {
    const data = await editPaneUserService(body);
    if (!data?.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);
export const panelUsersData = createAsyncThunk(
  'GET USERS',
  async function panelUsersData(undefined, thunkAPI) {
    const data = await fetchPanelUsersList();
    if (!data?.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);
export const postSlotRequirement = createAsyncThunk(
  'POST REQUIREMENT',
  async function postSlotRequirement(body: ISlotRequirementBody, thunkAPI) {
    const data = await postSlotRequirementService(body);
    if (!data?.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);
export const onboardUserData = createAsyncThunk(
  'GET USERS ONBOARD',
  async function onboardUserData(undefined, thunkAPI) {
    const data = await fetchOnboardUsersList();
    if (!data?.error) {
      return data;
    } else {
      if (data.error.code === 'ERR_NETWORK') {
        return { data: null, error: [{ message: data.error.message, code: data.error.code }] };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    }
  }
);
export const getList = createAsyncThunk(
  GET_USER_LIST,
  async function getUserList(undefined, thunkAPI) {
    const windowHeight = window.innerHeight;

    const data = await fetchUserRoleList(0, 8);
    if (data?.error) {
      return thunkAPI.rejectWithValue(data);
    }
    const pendingListData = data.data.pendingApprovalUsersList.map((user: any) => {
      const data = {
        userName: `${user.firstName} ${user.lastName}`,
        designation: user.designation,
        createdAt: user.addedOn,
        userId: user.userId,
        profileImageUrl: user.profileImageUrl
      };
      return data;
    });
    const approvedListData = data.data.recentlyAddedUsersList.map((user: any) => {
      const data = {
        userName: `${user.firstName} ${user.lastName}`,
        designation: user.designation,
        createdAt: formatDate(user.addedOn),
        userId: user.userId,
        profileImageUrl: user.profileImageUrl
      };
      return data;
    });
    return [pendingListData, approvedListData];
  }
);

export const changeSummary = createAsyncThunk(
  CHANGE_SUMMARY,
  async function changeEmployeeSummary(fromDate: string, { dispatch }: any) {
    dispatch(getEmployeeSummary(fromDate));
  }
);

export const getSlotEfficiencyData = createAsyncThunk(
  CHANGE_SUMMARY,
  async function getSlotEfficiencyData(body: ISlotEfficiencyBody, thunkAPI) {
    const data = await slotEfficiencyService(body);

    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const getRewardsData = createAsyncThunk(
  GET_REWARDS_DATA,
  async function getRewardsData(params: any, thunkAPI) {
    const data = await getRewardsService(params?.month, params?.year);

    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const getSlotSummaryData = createAsyncThunk(
  GET_SLOT_SUMMARY_DATA,
  async function getSlotSummaryData(date: string, thunkAPI) {
    const data = await getSlotSummaryService(date);
    if ((data.error && data.error[0]?.code === 1005) || !data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const editPanelSlot = createAsyncThunk(`edit-panel-slot`, async (param: any, thunkAPI) => {
  const response = await editSlot({ userId: param.userId, slots: param.slots });
  if (!response.error) {
    const state: any = thunkAPI.getState();
    return response;
  } else {
    const { error } = response;
    return thunkAPI.rejectWithValue(error[0]);
  }
});

export const getUpcomingInterviews = createAsyncThunk(
  GET_UPCOMING_INTERVIEWS,
  async function getUpcomingInterviews(userId: string, thunkAPI) {
    const data = await getUpcomingInterviewsData(userId);

    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const slotDelete = createAsyncThunk(
  `delete-available-slot`,
  async (slotId: string | number, thunkAPI) => {
    const response = await deleteSlot(slotId);

    if (!response.error) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const deleteUserData = createAsyncThunk(
  DELETE_USER,
  async function deleteUser(selectedListData: IUserDataByIdType, thunkAPI) {
    let responseData;
    const { roles, userId } = selectedListData;
    const isRecruiter = roles?.filter((role: any) => role?.id === 3).length;
    const isPanel = roles?.filter((role: any) => role?.id === 2).length;
    const isBoth = isPanel && isRecruiter;
    if (isBoth) {
      responseData = await deleteRecruiterService(userId);
      responseData = await deletePanelService([userId]);
    } else if (isPanel) {
      responseData = await deletePanelService([userId]);
    } else if (isRecruiter) {
      responseData = await deleteRecruiterService(userId);
    }
    if (!responseData?.error) {
      return responseData;
    }
    return thunkAPI.rejectWithValue(responseData);
  }
);

export const adminDashboardSlice = createSlice({
  name: ADMIN_DASHBOARD,
  initialState,
  reducers: {
    searchSelected: (state, action) => {
      state.selectedSearch = action.payload;
    },
    clearSearchBarValue: (state) => {
      state.clearResult = true;
    },
    resetSearchBarValue: (state) => {
      state.clearResult = false;
    },
    handlePendingApprovalSelected: (state, action) => {
      state.isPendingApprovalClicked = true;
      state.selectedPendingApproval = action.payload;
    },
    closePendingApprovalDialog: (state) => {
      state.isPendingApprovalClicked = false;
    },
    handleCloseSearchResult: (state) => {
      state.mountHome = true;
      state.isError = false;
    },
    toggleDrawerVisibility: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
    setPendingDeletionData: (state, { payload }) => {
      state.pendingDeletionData = payload;
    },
    toggleTechDrawerVisibility: (state) => {
      state.isTechDrawerOpen = !state.isTechDrawerOpen;
    },
    toggleCancelModal: (state, { payload }) => {
      state.currentSelectedInterview = payload;
      state.isCancelModalOpen = !state.isCancelModalOpen;
    },
    closeNotification: (state) => {
      state.showNotification = false;
    },
    setCurrentSelectedDate: (state, { payload }) => {
      state.currentSelectedDate = payload;
    },
    removeEvent: (state, action: PayloadAction<string | number>) => {
      const data = state.availableSlotSummary.findIndex(
        (event) => event.id.toString() === action.payload.toString()
      );
      if (data !== -1) {
        const deletedData = state.availableSlotSummary.splice(data, 1);
        state.pendingSlotDeletion = state.pendingSlotDeletion.concat(deletedData);
        state.availableSlotSummary = state.availableSlotSummary;
      }
    },
    readdEvent: (state, action: PayloadAction<string | number>) => {
      const data = state.pendingSlotDeletion.findIndex(
        (slot) => slot.id.toString() === action.payload.toString()
      );
      if (data !== -1) {
        state.availableSlotSummary.push(state.pendingSlotDeletion[data]);
        state.pendingSlotDeletion.splice(data, 1);
        state.availableSlotSummary = state.availableSlotSummary;
      }
    },
    resetPage: (state) => {
      state.panelCount = '0';
      state.recruiterCount = '0';
      state.techStackCount = '0';
      state.pendingList = [];
      state.approvedList = [];
      state.clearResult = false;
      state.userDataById = {} as IUserDataByIdType;
      state.searchOptions = [];
      state.selectedSearch = null;
      state.selectedPendingApproval = {} as IUserDataByIdType;
      state.isPendingApprovalClicked = false;
      state.mountHome = false;
      state.isDrawerOpen = false;
      state.isHomeLoading = false;
      state.isError = false;
      state.isTechDrawerOpen = false;
      state.slotEfficiencyData = [] as ISlotEfficiencyData[];
      state.isSlotEfficiencyError = false;
      state.panelUserList = [];
      state.onboardUserList = [];
      state.isSlotEfficiencyLoading = false;
      state.isUserDataLoading = false;
      state.pendingDeletionData = [];
      state.rewardsWidgetData = {} as IRewardsWidgetData;
      state.isRewardsDataLoading = false;
      state.upcomingInterviewData = [] as IUpcomingInterviewData[];
      state.isUpcomingInterviewLoading = false;
      state.availableSlotSummary = [];
      state.currentSelectedDate = '';
      state.editSlotError = false;
      state.isSearchError = false;
      state.isSlotSummaryError = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployeeSummary.fulfilled, (state, { payload }) => {
      return {
        ...state
      };
    });
    builder.addCase(getEmployeeSummary.pending, (state, { payload }) => {
      return {
        ...state,
        isHomeLoading: true
      };
    });
    builder.addCase(getEmployeeSummary.rejected, (state, { payload }) => {
      return {
        ...state,
        isError: true,
        isHomeLoading: false
      };
    });
    builder.addCase(getEmployeeCount.fulfilled, (state, { payload }) => {
      return {
        ...state,
        employeeCount: payload?.data
      };
    });
    builder.addCase(getEmployeeCount.pending, (state, { payload }) => {
      return {
        ...state,
        isHomeLoading: true
      };
    });
    builder.addCase(getEmployeeCount.rejected, (state, { payload }) => {
      return {
        ...state,
        isError: true,
        isHomeLoading: false
      };
    });
    builder.addCase(editPanelSlot.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isSlotSummaryLoading: false,
        editSlotError: false
      };
    });
    builder.addCase(editPanelSlot.pending, (state, { payload }) => {
      return {
        ...state,
        isSlotSummaryLoading: true
      };
    });
    builder.addCase(editPanelSlot.rejected, (state, { payload }: any) => {
      if (payload?.error[0]?.code === 400) {
        notify(true, SLOT_OVERLAP_TOAST_MESSAGE, uuidv4().toString(), () => {}, 'error');
        return {
          ...state,
          isSlotSummaryLoading: false
        };
      } else {
        return {
          ...state,
          editSlotError: true,
          isSlotSummaryLoading: false
        };
      }
    });
    builder.addCase(slotDelete.fulfilled, (state, action) => {
      state.isSlotSummaryLoading = false;
      state.isError = false;
    });
    builder.addCase(slotDelete.rejected, (state) => {
      state.isSlotSummaryLoading = false;
      state.isError = true;
    });
    builder.addCase(slotDelete.pending, (state) => {
      state.isSlotSummaryLoading = true;
    });
    builder.addCase(getRewardsData.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: true,
        isRewardsDataLoading: false,
        rewardsWidgetData: payload.data
      };
    });
    builder.addCase(getRewardsData.pending, (state, { payload }) => {
      return {
        ...state,
        isRewardsDataLoading: true
      };
    });
    builder.addCase(getRewardsData.rejected, (state, { payload }) => {
      return {
        ...state,
        isError: true,
        isRewardsDataLoading: false
      };
    });
    builder.addCase(getSlotSummaryData.fulfilled, (state, { payload }) => {
      const available = payload.data.summaryMap?.AVAILABLE?.filter(
        (item: any) => item.isRequestedSlot === false
      );
      const request = payload.data.summaryMap?.AVAILABLE?.filter(
        (item: any) => item.isRequestedSlot === true
      );
      return {
        ...state,
        isSlotSummaryLoading: false,
        slotSummaryData: payload.data,
        availableSlotSummary: available?.map((item: any) => ({
          id: item?.slotId,
          start: moment(item?.from),
          end: moment(item?.to)
        })),
        requestedSlotSummary: request?.map((item: any) => ({
          id: item?.slotId,
          start: moment(item?.from),
          end: moment(item?.to)
        }))
      };
    });
    builder.addCase(getSlotSummaryData.pending, (state, { payload }) => {
      return {
        ...state,
        isSlotSummaryLoading: true
      };
    });
    builder.addCase(getSlotSummaryData.rejected, (state, { payload }) => {
      return {
        ...state,
        isSlotSummaryError: true,
        isSlotSummaryLoading: false
      };
    });
    builder.addCase(getUpcomingInterviews.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isError: false,
        isUpcomingInterviewLoading: false,
        upcomingInterviewData: payload.data
      };
    });
    builder.addCase(getUpcomingInterviews.pending, (state, { payload }) => {
      return {
        ...state,
        isUpcomingInterviewLoading: true
      };
    });
    builder.addCase(getUpcomingInterviews.rejected, (state, { payload }) => {
      return {
        ...state,
        isError: true,
        isUpcomingInterviewLoading: false
      };
    });
    builder.addCase(getSlotEfficiencyData.fulfilled, (state, { payload }) => {
      return {
        ...state,
        slotEfficiencyData: payload?.data,
        isSlotEfficiencyLoading: false
      };
    });
    builder.addCase(getSlotEfficiencyData.pending, (state, { payload }) => {
      return {
        ...state,
        isSlotEfficiencyLoading: true
      };
    });
    builder.addCase(getSlotEfficiencyData.rejected, (state, { payload }) => {
      return {
        ...state,
        isSlotEfficiencyError: true,
        isSlotEfficiencyLoading: false
      };
    });
    builder.addCase(postSlotRequirement.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isSlotEfficiencyError: false,
        isSlotEfficiencyLoading: false
      };
    });
    builder.addCase(postSlotRequirement.pending, (state, { payload }) => {
      return {
        ...state,
        isSlotEfficiencyLoading: true
      };
    });
    builder.addCase(postSlotRequirement.rejected, (state, { payload }) => {
      return {
        ...state,
        isSlotEfficiencyError: true,
        isSlotEfficiencyLoading: false
      };
    });
    builder.addCase(getList.fulfilled, (state, action) => {
      return {
        ...state,
        pendingList: action.payload ? action.payload[0] : [],
        approvedList: action.payload ? action.payload[1] : []
      };
    });
    builder.addCase(getList.rejected, (state, action) => {
      return {
        ...state,
        isError: true,
        isHomeLoading: false
      };
    });
    builder.addCase(getUserData.fulfilled, (state, { payload }) => {
      return {
        ...state,
        userDataById: payload.data,
        isUserDataLoading: false
      };
    });
    builder.addCase(getUserData.pending, (state, { payload }) => {
      return {
        ...state,
        isUserDataLoading: true
      };
    });
    builder.addCase(panelUsersData.fulfilled, (state, { payload }) => {
      return {
        ...state,
        panelUserList: payload.data,
        isPanelUserListLoading: false,
        isPanelUserTabError: false
      };
    });
    builder.addCase(panelUsersData.rejected, (state, action) => {
      return {
        ...state,
        isError: true,
        isPanelUserListLoading: false,
        isPanelUserTabError: true
      };
    });
    builder.addCase(panelUsersData.pending, (state, { payload }) => {
      return {
        ...state,
        isPanelUserListLoading: true,
        isPanelUserTabError: false
      };
    });
    builder.addCase(onboardUserData.fulfilled, (state, { payload }) => {
      return {
        ...state,
        onboardUserList: payload.data,
        isOnboardUserListLoading: false,
        isOnboardUserTabError: false
      };
    });
    builder.addCase(onboardUserData.rejected, (state, action) => {
      return {
        ...state,
        isError: true,
        isOnboardUserListLoading: false,
        isOnboardUserTabError: true
      };
    });
    builder.addCase(onboardUserData.pending, (state, { payload }) => {
      return {
        ...state,
        isOnboardUserListLoading: true,
        isOnboardUserTabError: false
      };
    });
    builder.addCase(getUserData.rejected, (state, { payload }) => {
      return {
        ...state,
        isError: true
      };
    });
    builder.addCase(editUserData.pending, (state) => {
      return {
        ...state,
        isHomeLoading: true
      };
    });
    builder.addCase(editUserData.fulfilled, (state) => {
      return {
        ...state,
        isHomeLoading: false
      };
    });
    builder.addCase(editUserData.rejected, (state, { payload }) => {
      return {
        ...state,
        isHomeLoading: false,
        isError: true
      };
    });
    builder.addCase(deleteUserData.pending, (state) => {
      return {
        ...state,
        isHomeLoading: true
      };
    });
    builder.addCase(deleteUserData.fulfilled, (state) => {
      return {
        ...state,
        isHomeLoading: false
      };
    });
    builder.addCase(deleteUserData.rejected, (state, { payload }) => {
      return {
        ...state,
        isError: true,
        isHomeLoading: false
      };
    });
    builder.addCase(handleSearch.fulfilled, (state, { payload }: any) => {
      return {
        ...state,
        searchOptions: payload,
        isSearchError: false
      };
    });
    builder.addCase(handleSearch.rejected, (state, { payload }: any) => {
      return {
        ...state,
        isSearchError: true
      };
    });
    builder.addCase(getSlotAchievementsData.rejected, (state, { payload }) => {
      return {
        ...state,
        isSlotAcheiveMentError: false,
        isSlotAcheiveMentLoading: false
      };
    });
    builder.addCase(getSlotAchievementsData.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isSlotAcheiveMentLoading: false
      };
    });
    builder.addCase(getSlotAchievementsData.pending, (state, { payload }) => {
      return {
        ...state,
        isSlotAcheiveMentLoading: true
      };
    });
  }
});

export const {
  searchSelected,
  clearSearchBarValue,
  handlePendingApprovalSelected,
  closePendingApprovalDialog,
  handleCloseSearchResult,
  resetSearchBarValue,
  toggleDrawerVisibility,
  toggleTechDrawerVisibility,
  resetPage,
  setPendingDeletionData,
  toggleCancelModal,
  closeNotification,
  setCurrentSelectedDate,
  readdEvent,
  removeEvent
} = adminDashboardSlice.actions;
export const dashboard = (state: RootState) => state.adminDashboard;
export default adminDashboardSlice.reducer;
