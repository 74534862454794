import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import './index.style.scss';
import {
  getRecruiters,
  getSearchedData,
  getSelectedUserData,
  setLoading,
  setTotalCount
} from 'app/redux/slices/talentSlice';
import { useAppSelector, useAppDispatch, useAppThunkDispatch } from 'app/redux/hooks';
import filterIcon from 'assets/images/filter.svg';
import sortIcon from 'assets/images/sort.svg';
import deleteIcon from 'assets/images/delete.svg';
import {
  NAME,
  EMAIL,
  TECH_STACK,
  TECH,
  OUTLINED,
  MEDIUM,
  RECENTLY_ADDED,
  NAME_A_to_Z,
  NAME_Z_to_A,
  DELETE,
  NAME_ASC,
  ADDED_ON_DESC,
  NAME_DESC,
  BOTH,
  CANCEL_BUTTON_TEXT,
  ROLE_ADMIN,
  ROLE_RECRUITER,
  TALENT_ACQUISITION,
  RECRUITER,
  RECRUITER_LABEL
} from 'helpers/constants';
import {
  TA_UNABLE_DELETE_TITLE,
  TA_SEARCH_PLACEHOLDER,
  DELETE_TA,
  DELETE_TA_DESCRIPTION,
  CANNOT_DELETE_ACTIVE_BOOKINGS
} from 'helpers/messages.constants';
import RowCreator from './components/rowCreator';
import { useNavigate, useLocation } from 'react-router-dom';
import Typography from 'app/core/shared-components/typography';
import Button from 'app/core/shared-components/button';
import ImageComponent from 'app/core/shared-components/image';
import Search from 'app/core/shared-components/search';
import Filters from './components/filters';
import ClickAwayListener from 'app/core/shared-components/click-away';
import SortingOptions from 'app/core/shared-components/sorting-otions';
import Loader from 'app/core/shared-components/loader';
import Drawer from 'app/core/shared-components/drawer';
import ErrorPage from '../Error';
import ProfileDetails from 'app/core/shared-components/profile-details';
import ConfirmationModal from 'app/core/shared-components/confirmation-modal';
import { deleteRecruiterService } from 'app/services/talent-acquisition.service';
import routeError from 'router/routeError';
import Modal from 'app/core/shared-components/modal';
import { CSSTransition } from 'react-transition-group';
import ToastUndo from 'app/core/shared-components/toast-undo';
import { notify } from 'helpers/toastHelper';
import { toast } from 'react-toastify';
import { getEmployeeCount } from 'app/redux/slices/adminDashboardSlice';
import Table from 'app/core/shared-components/table/';
import { getExperience, handleTabEventwithoutValue } from 'helpers/utils';

interface IFilterState {
  techStackList: string[];
  sortBy: string;
}

interface ISingleSearchedData {
  designation: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  profileImageUrl: string;
}

interface ISearchedOptionProps {
  id: string;
  searchKey: string;
  startIcon: string;
  supportingData: string;
  techStackList: string[];
  singleSearchData: ISingleSearchedData[];
}

interface ISelectedRecruiter {
  designation?: string;
  email?: string;
  firstName?: string;
  id?: string;
  lastName?: string;
  mobileNo?: string;
  profileImageUrl?: string;
  role?: string;
  techStackList?: string[];
  totalExperience?: number;
}
interface ITalentStates {
  pgNo: number;
  isFilteringOn: boolean;
  filters: IFilterState;
  isSortingOn: boolean;
  sortingValue: string;
  clearResult: boolean;
  searchedOptions: ISearchedOptionProps[];
  isSearchOptionSelected: boolean;
  noResultFound: boolean;
  isEditingOn: boolean;
  isLoading: boolean;
  selectedRecruiter: ISelectedRecruiter;
  isDeleteOn: boolean;
  isSaveOn: boolean;
  isToastVisible: boolean;
  filterCount: number;
  roleChanged: boolean;
  formData: any;
  currentPg: number;
  talentData?: ISelectedRecruiter[];
  isDeletionInProcess?: boolean;
}

function TalentAcquisition() {
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [talentState, setTalentState] = useState<ITalentStates>({
    pgNo: 1,
    isFilteringOn: false,
    filters: { techStackList: [], sortBy: NAME_ASC },
    isSortingOn: false,
    sortingValue: '',
    clearResult: false,
    searchedOptions: [],
    isSearchOptionSelected: false,
    noResultFound: false,
    isEditingOn: false,
    isLoading: false,
    selectedRecruiter: {},
    isDeleteOn: false,
    isSaveOn: false,
    isToastVisible: false,
    filterCount: 0,
    roleChanged: false,
    formData: {},
    currentPg: 1,
    talentData: [],
    isDeletionInProcess: false
  });
  const userState = useAppSelector((state) => state.user);
  const [deleteModal, setDeleteModal] = useState(false);
  const [noDeleteModal, setNoDeleteModal] = useState(false);
  const [deleteUserName, setDeleteUserName] = useState<ReactNode>();
  const [isFilterPage, setIsFilterPage] = useState(false);
  const [showNoFilter, setShownoFilter] = useState(false);
  const [dataDrawerOpen, setDataDrawerOpen] = useState<any>(location);
  const recruiterData = useAppSelector((state) => state.talentAcquisition.recruiterData);
  const { userId: id } = useAppSelector((state) => state.user.userData);
  const timeOut = useRef<any>(null);
  const abortSignal = useRef<any>(null);

  async function getRecruiterList(pgNo: number, filters: IFilterState) {
    dispatch(setLoading(true));
    await dispatch(
      getRecruiters({
        pgNo: pgNo - 1,
        filters: filters
      })
    );
    dispatch(setLoading(false));
  }

  function onCloseDialogForToast() {
    setTalentState((prevState) => ({
      ...prevState,
      isToastVisible: false,
      isEditingOn: false
    }));
    deleteRecruiter();
  }
  function localDelete() {
    const taUser = talentState.selectedRecruiter.id;
    let temp = talentState?.talentData?.filter((item) => item?.id !== taUser);
    setTalentState((prevState) => ({
      ...prevState,
      isDeletionInProcess: true,
      talentData: temp,
      isToastVisible: true,
      isEditingOn: false,
      clearResult: true
    }));
    setDeleteModal(false);
    talentState?.selectedRecruiter?.id &&
      notify(
        false,
        <ToastUndo
          toastMessage={`${talentState?.selectedRecruiter?.firstName} ${talentState?.selectedRecruiter?.lastName} is being deleted`}
          handleUndo={onUndoDelete}
          toastId={talentState?.selectedRecruiter?.id as string}
        />,
        talentState?.selectedRecruiter?.id,
        onCloseDialogForToast
      );
  }
  function onUndoDelete(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const id = event.currentTarget.id;
    toast.dismiss(id);
    toast.update(id, { onClose: () => {}, hideProgressBar: true });
    getRecruiterList(1, {
      techStackList: talentState?.filters?.techStackList,
      sortBy: talentState.filters.sortBy
    });
  }
  async function deleteRecruiter() {
    dispatch(setLoading(true));
    const deleteModalState =
      talentState?.selectedRecruiter?.id &&
      (await deleteRecruiterService(talentState?.selectedRecruiter?.id));
    if (deleteModalState.data === false) {
      setNoDeleteModal(true);
      const [firstError, ...rest] = deleteModalState?.error;
      setDeleteUserName(firstError?.data);
    } else {
      const userRole = userState.userData.role.map((item) => item.role).includes(ROLE_ADMIN);
      userRole && dispatch(getEmployeeCount());
    }
    setDeleteModal(false);
    await getRecruiterList(talentState.pgNo, {
      techStackList: talentState.filters.techStackList,
      sortBy: talentState.filters.sortBy
    });
    setTalentState((prevState) => ({
      ...prevState,
      isDeleteOn: false,
      isEditingOn: false
    }));
    dispatch(setLoading(false));
  }

  async function setSearchedOption(id: string | number, singleSearchData: any) {
    commonTdClick(id as string);
    setTalentState((prevState) => ({
      ...prevState,
      clearResult: false,
      isSearchOptionSelected: true,
      selecteRecruiter: { ...singleSearchData }
    }));
    dispatch(setTotalCount(1));
    await getRecruiterList(talentState.pgNo, {
      techStackList: talentState.filters.techStackList,
      sortBy: talentState.filters.sortBy
    });
  }

  function setSortingValue(val: string) {
    setTalentState((prevState) => ({ ...prevState, sortingValue: val }));
    selectSort(val);
  }

  async function commonTdClick(id: string) {
    dispatch(setLoading(true));
    const user = await dispatch(getSelectedUserData(id)).unwrap();
    const tempRole = user.data.role === ROLE_RECRUITER ? TALENT_ACQUISITION : '';
    const temptechStack = user.data.techStackList;

    setTalentState((prevState) => ({
      ...prevState,
      formData: {
        role: tempRole,
        experience: user.data.totalExperience && getExperience(user.data.totalExperience),
        projects: [],
        stackLoi: [],
        techStack: [...temptechStack]
      }
    }));
    dispatch(setLoading(false));
    setTalentState((prevState) => ({
      ...prevState,
      selectedRecruiter: user.data,
      isEditingOn: true,
      isSortingOn: false,
      isFilteringOn: false
    }));
  }

  function selectSort(val: string) {
    let temp = talentState?.talentData && [...talentState?.talentData];

    switch (val) {
      case NAME_A_to_Z:
        setTalentState((prevState) => ({
          ...prevState,
          filters: { ...prevState.filters, sortBy: NAME_ASC }
        }));
        break;
      case NAME_Z_to_A:
        setTalentState((prevState) => ({
          ...prevState,
          filters: { ...prevState.filters, sortBy: NAME_DESC }
        }));
        break;
      case RECENTLY_ADDED:
        setTalentState((prevState) => ({
          ...prevState,
          filters: { ...prevState.filters, sortBy: ADDED_ON_DESC }
        }));
        break;
      default:
        temp = temp;
        break;
    }
  }

  const columns = [
    {
      Header: (
        <div key={NAME} className="recruiter-header-cell recruiter-first-header">
          Name
        </div>
      ),
      accessor: 'firstName',
      Cell: (item: any) => {
        return (
          <div
            key={NAME}
            className="recruiter-row-cell recruiter-first-row-cell"
            id={item.id}
            onClick={() => {
              commonTdClick(item?.row?.original?.id);
            }}>
            <RowCreator componentType={NAME} recruiter={item?.row?.original} />
          </div>
        );
      }
    },
    {
      Header: (
        <div key={EMAIL} className="recruiter-header-cell">
          Email Address
        </div>
      ),
      accessor: 'email',
      Cell: (item: any) => {
        return (
          <div
            key={EMAIL}
            className="recruiter-row-cell recruiter-gray-text"
            id={item?.row?.original?.id}
            onClick={() => {
              commonTdClick(item?.row?.original?.id);
            }}>
            <RowCreator componentType={EMAIL} recruiter={item?.row?.original} />
          </div>
        );
      }
    },
    {
      Header: (
        <div key={TECH} className="recruiter-header-cell">
          Tech Stack
        </div>
      ),
      accessor: 'techStackList',
      Cell: (item: any) => {
        return (
          <div
            key={TECH}
            className="recruiter-row-cell recruiter-cell-tech-stack"
            id={item?.row?.original?.id}
            onClick={() => {
              commonTdClick(item?.row?.original?.id);
            }}>
            <RowCreator componentType={TECH} recruiter={item?.row?.original} />
          </div>
        );
      }
    }
  ];

  const handleFilterClose = React.useCallback(() => {
    if (talentState.isFilteringOn) {
      setTalentState((prevState) => ({
        ...prevState,
        isFilteringOn: false
      }));
    }
  }, [talentState.filters, talentState.isFilteringOn, talentState.filterCount]);

  const handleDelete = () => {
    setDeleteModal(true);
  };
  const handleCloseModal = () => {
    setNoDeleteModal(false);
  };
  const handleCancelDeleteModal = () => {
    setNoDeleteModal(false);
  };
  const getLDeleteModalEdgeCase = () => {
    return (
      <Modal
        header={TA_UNABLE_DELETE_TITLE}
        customStyle="edit-modal"
        onClose={handleCloseModal}
        isOpen={noDeleteModal}>
        <div className="edit-modal-content">
          <div className="delete-modal-para">
            <span className="modalLevelName">
              <q>{deleteUserName}</q>
            </span>
            <span>{CANNOT_DELETE_ACTIVE_BOOKINGS}</span>
          </div>
          <div className="edit-modal-footer">
            <Button customStyle="edit-modal--cancel-btn" onClick={handleCancelDeleteModal}>
              {CANCEL_BUTTON_TEXT}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const handleOnFilterClose = () => {
    setTalentState((prevState) => ({ ...prevState, isFilteringOn: false }));
  };
  const handlegetFilterCount = (param: number) => {
    setTalentState((prevState) => ({ ...prevState, filterCount: param }));
  };
  const handleSortingClose = () => {
    setTalentState((prevState) => ({
      ...prevState,
      isSortingOn: false
    }));
  };
  const handleGetFilters = (param: string[]) => {
    setIsFilterPage(param.length > 0);
    setTalentState((prevState) => ({
      ...prevState,
      filters: { ...prevState.filters, techStackList: [...param] }
    }));
  };
  const handleSetFilterOpen = () => {
    setTalentState((prevState) => ({
      ...prevState,
      isFilteringOn: !talentState.isFilteringOn
    }));
  };
  const handleSortClickAway = () => {
    if (talentState.isSortingOn) {
      setTalentState((prevState) => ({
        ...prevState,
        isSortingOn: false
      }));
    }
  };
  const handleSetSortingOpen = () => {
    setTalentState((prevState) => ({
      ...prevState,
      isSortingOn: !talentState.isSortingOn,
      isFilteringOn: false
    }));
  };

  const handleDeleteProfile = () => {
    setTalentState((prevState) => ({
      ...prevState,
      isDeleteOn: true
    }));
  };
  const handleSetPageNo = (param: number) => {
    setTalentState((prevState) => ({
      ...prevState,
      pgNo: param,
      currentPg: param,
      isSortingOn: false,
      isFilteringOn: false
    }));
  };
  const handleDrawerClose = () => {
    setTalentState((prevState) => ({ ...prevState, isEditingOn: false, clearResult: true }));
  };
  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };

  const handledataDrawer = () => {
    const locationState = dataDrawerOpen?.state as unknown as any;
    if (locationState?.id) {
      setTalentState((prevState) => ({
        ...prevState,
        filters: { ...prevState.filters, sortBy: ADDED_ON_DESC }
      }));
      commonTdClick(locationState.id.toString());
    } else if (locationState?.status) {
      setTalentState((prevState) => ({
        ...prevState,
        filters: { ...prevState.filters, sortBy: ADDED_ON_DESC }
      }));
    }
    navigate(location.pathname, { replace: true });
  };

  async function onSearchChange(searchString: string) {
    setTalentState((prevState) => ({
      ...prevState,
      searchedOptions: []
    }));
    if (talentState.noResultFound) {
      setTalentState((prevState) => ({
        ...prevState,
        noResultFound: false
      }));
    }
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
    if (!!searchString.trim().length) {
      timeOut.current = setTimeout(async () => {
        if (abortSignal.current) abortSignal.current.abort();
        abortSignal.current = new AbortController();
        const signal = abortSignal.current.signal;
        const data = await dispatch(getSearchedData({ searchString, signal })).unwrap();
        if (!data?.error) {
          const { data: optionsData } = data;
          const options = optionsData?.map((ta: ISelectedRecruiter) => {
            return {
              startIcon: ta.profileImageUrl,
              searchKey: `${ta.firstName} ${ta.lastName}`,
              id: ta.id,
              supportingData: `${ta.firstName} ${ta.lastName}`,
              singleSearchData: ta
            };
          });
          setTalentState((prevState) => ({ ...prevState, searchedOptions: options }));
        } else {
          setTalentState((prevState) => ({
            ...prevState,
            searchedOptions: [],
            noResultFound: true
          }));
        }
      }, 1000);
    } else {
      setTalentState((prevState) => ({ ...prevState, searchedOptions: [] }));
    }
  }

  const getRecruitersData = () => {
    setShownoFilter(isFilterPage);
    getRecruiterList(talentState.pgNo, {
      techStackList: talentState.filters.techStackList,
      sortBy: talentState.filters.sortBy
    });
  };

  useEffect(() => {
    setTalentState((prev) => ({
      ...prev,
      talentData: recruiterData?.recruiterDTOList
    }));
  }, [recruiterData?.recruiterDTOList]);

  useEffect(
    () => () => {
      window.history.replaceState({}, document.title);
    },
    []
  );

  useEffect(() => {
    handledataDrawer();
  }, [dataDrawerOpen]);

  useEffect(() => {
    getRecruitersData();
  }, [talentState.pgNo, talentState.filters]);

  useEffect(() => {
    setTalentState((prevState) => ({ ...prevState, currentPg: 1, pgNo: 1 }));
  }, [talentState.filters]);
  return !recruiterData.isError ? (
    <div className="admin-recruiter-outer-container">
      <Loader loading={recruiterData.loading} />
      <div className="ap-top-bar-wrapper">
        <div>
          <Typography customStyle="admin-recruiter-heading">{RECRUITER_LABEL}</Typography>
        </div>
        <div className="filters-search-section">
          <div className="admin-recruiter-top-bar">
            <div className="admin-recruiter-filters-container">
              <div>
                <div className="filter-modal-btn-container">
                  <ClickAwayListener handleClose={handleFilterClose}>
                    <Button
                      variant={OUTLINED}
                      size={MEDIUM}
                      customStyle={`admin-recruiter-filter-buttons ${
                        talentState?.isFilteringOn && 'active-btn'
                      }`}
                      onClick={handleSetFilterOpen}>
                      <ImageComponent src={filterIcon} />
                    </Button>
                    <CSSTransition
                      in={talentState?.isFilteringOn}
                      timeout={300}
                      unmountOnExit
                      classNames="alert">
                      <Filters
                        isOpen={talentState?.isFilteringOn}
                        appliedFilters={talentState?.filters?.techStackList}
                        getFilters={handleGetFilters}
                        onClose={handleOnFilterClose}
                        getFilterCount={handlegetFilterCount}
                      />
                    </CSSTransition>
                  </ClickAwayListener>
                  {talentState.filterCount ? (
                    <div className="ta-filter-count">{talentState.filterCount}</div>
                  ) : null}
                </div>
              </div>
              <div>
                <div className="sort-btn-container">
                  <ClickAwayListener handleClose={handleSortClickAway}>
                    <Button
                      variant={OUTLINED}
                      size={MEDIUM}
                      customStyle={`admin-recruiter-filter-buttons ${
                        talentState.isSortingOn && 'active-btn'
                      }`}
                      onClick={handleSetSortingOpen}>
                      <ImageComponent src={sortIcon} />
                    </Button>
                    <div className="recruiter-sorting-parent">
                      <SortingOptions
                        isOpen={talentState?.isSortingOn}
                        currentValue={talentState.sortingValue}
                        labelArray={[RECENTLY_ADDED, NAME_A_to_Z, NAME_Z_to_A]}
                        setCurrentValue={setSortingValue}
                        onClose={handleSortingClose}
                      />
                    </div>
                  </ClickAwayListener>
                </div>
              </div>
            </div>
          </div>
          <div className="ap-search">
            <Search
              options={talentState.searchedOptions}
              placeholder={TA_SEARCH_PLACEHOLDER}
              onChange={onSearchChange}
              setSelectedId={setSearchedOption}
              customDropdownIconClass="serached-options-image"
              clearResult={talentState.clearResult}
              resultNotFound={talentState.noResultFound}
            />
          </div>
        </div>
      </div>
      <Table
        columnsDefinition={columns}
        rowsDefinition={talentState?.talentData as ISelectedRecruiter[]}
        numberOfPages={Math.ceil(recruiterData.totalCount / 10)}
        onPageChange={handleSetPageNo}
        isCheckEnabled={false}
        currentPg={talentState.currentPg}
        isFilterScreen={showNoFilter}
        totalCount={recruiterData.totalCount}
      />
      <ConfirmationModal
        title={DELETE_TA}
        description={DELETE_TA_DESCRIPTION}
        confirmText={DELETE}
        onConfirm={localDelete}
        onClose={handleDeleteModalClose}
        isOpen={deleteModal}
      />
      {getLDeleteModalEdgeCase()}
      <Drawer
        onClose={handleDrawerClose}
        isOpen={talentState.isEditingOn}
        bodyStyles="drawer-body-ta"
        headerStyles="drawer-header-custom-style"
        headerProps={
          <Fragment>
            {id === talentState?.selectedRecruiter?.id ? (
              ''
            ) : (
              <div
                className="delete-icon"
                tabIndex={0}
                onKeyDown={handleTabEventwithoutValue(handleDelete)}>
                <ImageComponent src={deleteIcon} onClick={handleDelete} />
              </div>
            )}
          </Fragment>
        }>
        <Fragment>
          <ProfileDetails
            profileImageUrl={talentState.selectedRecruiter.profileImageUrl}
            name={`${talentState.selectedRecruiter.firstName} ${talentState.selectedRecruiter.lastName}`}
            designation={talentState?.selectedRecruiter?.designation as string}
            email={talentState?.selectedRecruiter?.email as string}
            mobile={talentState?.selectedRecruiter?.mobileNo as string}
            showExperience={talentState?.formData?.experience && talentState?.formData?.experience}
            onDelete={handleDeleteProfile}
          />
          <div className="separatorLine"></div>
          <div className="techDetails">
            <div className="techLabel">Role*</div>
            <div className="techName">{talentState?.formData?.role}</div>
            {talentState?.formData?.techStack?.length > 0 && (
              <Fragment>
                <div className="techStackLabel">Tech Stack</div>
                <div className="techStakName">
                  {talentState?.formData?.techStack?.map(
                    (techStackLabel: string, index: number) => {
                      return (
                        <div className="techStackNames" key={index}>
                          {techStackLabel}
                        </div>
                      );
                    }
                  )}
                </div>
              </Fragment>
            )}
          </div>
        </Fragment>
      </Drawer>
    </div>
  ) : (
    <ErrorPage page="apiFailure" />
  );
}

export default routeError(TalentAcquisition);
