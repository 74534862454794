import React, { useState, useEffect } from 'react';
import '../../index.style.scss';
import moment from 'moment';
import { HeaderProps } from 'react-big-calendar';

function CustomWeekHeaderComponent(props: HeaderProps) {
  const [headerState, setHeaderState] = useState(moment(props.date));

  useEffect(() => {
    setHeaderState(moment(props.date));
  }, [props.date]);

  return (
    <div className="custom-week-header">
      <p>{headerState.format('D')}</p>
      <p>
        {window.innerWidth > 480
          ? headerState.format('dddd').toUpperCase()
          : headerState.format('ddd').toUpperCase()}
      </p>
    </div>
  );
}

export default React.memo(CustomWeekHeaderComponent);
