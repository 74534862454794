import service from './index.service';
export async function authService(tokenId: string) {
  try {
    const response = await service.get('/auth/client/login/?token_id=' + tokenId);

    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function adminInfoService() {
  try {
    const response = await service.get('/user/info');
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function logoutService() {
  try {
    const response = await service.post('/auth/logout');
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}
