import React, { Fragment, useEffect, useState } from 'react';
import './index.style.scss';
import Typography from 'app/core/shared-components/typography';
import Images from 'assets/images';
import ImageComponent from 'app/core/shared-components/image';
import Button from 'app/core/shared-components/button';
import Select, { ISelectValue } from 'app/core/shared-components/select';
import moment, { Moment } from 'moment';
import { useAppThunkDispatch } from 'app/redux/hooks';
import { editPanelSlot } from 'app/redux/slices/scheduleSlice';
import { formatTime, generateTimeSlots, nearestMinutes } from 'helpers/dateHelper';
import { isNull } from 'lodash';
import { notify } from 'helpers/toastHelper';
import {
  PAST_START_TIME,
  SLOT_EDIT_TOAST_MESSAGE,
  SLOT_OVERLAP_TOAST_MESSAGE
} from 'helpers/messages.constants';
import { v4 as uuid } from 'uuid';
import CustomSelect from 'app/core/shared-components/customSelect';
import { ISlot } from 'app/services/schedule.service';
import { timeStamps } from 'helpers/timeStamp.constants';

interface ISlotEditPopupProps {
  slotId: string | number;
  start: Date;
  end: Date;
  onCancel?: () => void;
  onClose?: () => void;
}

interface ISlotEditStates {
  startDate: Moment;
  endDate: Moment;
  startTimeOption: ISelectValue[];
  endTimeOption: ISelectValue[];
  startMoment: Moment[];
  endMoment: Moment[];
  selectedStartDate: ISelectValue | null;
  selectedEndDate: ISelectValue | null;
  showWarning: boolean;
}

function SlotEditPopup(props: ISlotEditPopupProps) {
  const { onCancel, start, end } = props;
  const [slotState, setSlotState] = useState<ISlotEditStates>({
    startDate: moment(start),
    endDate: moment(end),
    startTimeOption: [],
    endTimeOption: [],
    startMoment: [],
    endMoment: [],
    selectedStartDate: null,
    selectedEndDate: null,
    showWarning: moment(start).isSameOrBefore(moment())
  });
  const dispatch = useAppThunkDispatch();
  const {
    startDate,
    endDate,
    startTimeOption,
    endTimeOption,
    selectedStartDate,
    selectedEndDate,
    showWarning
  } = slotState;

  const setFormData = () => {
    const momentStart = moment(start).set({ hour: 6, minutes: 0 });
    const momentEnd = moment(end).set({ hour: 6, minutes: 0 });
    const currentDateTime = moment();
    const startMoment = moment(start);
    if (startMoment.isSame(currentDateTime, 'date')) {
      const nearestIntervalTime = nearestMinutes(15, currentDateTime);
      const setTime = {
        hour: nearestIntervalTime.isSameOrAfter(startMoment)
          ? startMoment.hour()
          : nearestIntervalTime.hour(),
        minutes: nearestIntervalTime.isSameOrAfter(startMoment)
          ? startMoment.minute()
          : nearestIntervalTime.minute()
      };
      momentStart.set(setTime);
      momentEnd.set(setTime);
    }
    const startOption = generateTimeSlots(momentStart.toDate(), true);
    startOption.pop();
    const endOption = generateTimeSlots(momentEnd.toDate());
    const startTimeOption = startOption.map((slot, index) => ({
      id: index,
      label: slot.format(timeStamps.HH_MM_A)
    }));
    const endTimeOption = endOption.map((slot, index) => ({
      id: index,
      label: slot.format(timeStamps.HH_MM_A)
    }));
    const firstSelected = startOption.findIndex((item) => moment(item).isSame(moment(start)));
    const lastSelected = endOption.findIndex((item) => moment(item).isSame(moment(end)));
    if (firstSelected !== -1 && lastSelected !== -1) {
      const startSelected = {
        id: firstSelected,
        label: startOption[firstSelected].format(timeStamps.HH_MM_A)
      };
      const endSelected = {
        id: lastSelected,
        label: endOption[lastSelected].format(timeStamps.HH_MM_A)
      };
      setSlotState((prevState) => ({
        ...prevState,
        startTimeOption,
        endTimeOption,
        startMoment: startOption,
        endMoment: endOption,
        selectedStartDate: startSelected,
        selectedEndDate: endSelected
      }));
    }
  };
  const handleSave = async () => {
    const { selectedStartDate, selectedEndDate, startMoment, endMoment } = slotState;
    if (selectedStartDate && selectedEndDate) {
      const startTime = startMoment.find(
        (_item, index) => index.toString() === selectedStartDate.id.toString()
      )!;
      const endTime = endMoment.find(
        (_item, index) => index.toString() === selectedEndDate.id.toString()
      )!;
      const presentDay = moment();
      const duration = moment.duration(startTime.clone().diff(presentDay), 'minutes');
      if (duration.asMinutes() >= 30 && startTime && endTime) {
        const slot: ISlot[] = [];
        slot.push({
          from: formatTime(startTime.format(timeStamps.HH_MM_A), startTime.toDate()),
          to: formatTime(endTime.format(timeStamps.HH_MM_A), endTime.toDate()),
          id: props.slotId
        });
        const data = {
          date: startDate.format(timeStamps.YYYY_MM_DD),
          slot: slot
        };
        props.onClose && props.onClose();
        const response = await dispatch(editPanelSlot([data]));
        const { payload } = response;
        if (payload.data) {
          notify(true, SLOT_EDIT_TOAST_MESSAGE, props.slotId);
        } else if (payload.error && payload.error[0]?.error[0]?.code === 400) {
          notify(true, SLOT_OVERLAP_TOAST_MESSAGE, uuid(), () => {}, 'error');
        }
      } else {
        setSlotState((prevState) => ({ ...prevState, showWarning: true }));
      }
    }
  };

  const handleStartSelection = (param: ISelectValue | ISelectValue[] | null) => {
    const { startMoment, endMoment, selectedEndDate } = slotState;
    if (param && !Array.isArray(param)) {
      const startTime = startMoment.find(
        (_item, index) => index.toString() === param.id.toString()
      );
      const endTime = endMoment.find(
        (_item, index) => index.toString() === selectedEndDate?.id.toString()
      );
      const duration = moment.duration(endTime?.clone().diff(startTime, 'minutes'), 'minutes');
      if (startTime && duration.asMinutes() >= 30) {
        return setSlotState((prevState) => ({
          ...prevState,
          showWarning: false,
          selectedStartDate: param
        }));
      } else {
        return setSlotState((prevState) => ({
          ...prevState,
          showWarning: false,
          selectedStartDate: param,
          selectedEndDate: null
        }));
      }
    }
  };

  const handleEndSelection = (param: ISelectValue | ISelectValue[] | null) => {
    const { startMoment, endMoment, selectedStartDate } = slotState;
    if (param && !Array.isArray(param)) {
      const startTime = startMoment.find(
        (_item, index) => index.toString() === selectedStartDate?.id.toString()
      );
      const endTime = endMoment.find((_item, index) => index.toString() === param.id.toString());
      const duration = moment.duration(endTime?.clone().diff(startTime, 'minutes'), 'minutes');
      if (startTime && duration.asMinutes() >= 30) {
        return setSlotState((prevState) => ({
          ...prevState,
          showWarning: false,
          selectedEndDate: param
        }));
      } else {
        return setSlotState((prevState) => ({
          ...prevState,
          showWarning: false,
          selectedEndDate: param,
          selectedStartDate: null
        }));
      }
    }
  };

  const checkValidation = () => {
    const { selectedStartDate, selectedEndDate, showWarning, startMoment, endMoment } = slotState;
    const firstSelected = startMoment.find(
      (_item, index) => index.toString() === selectedStartDate?.id.toString()
    );
    const secondSelected = endMoment.find(
      (_item, index) => index.toString() === selectedEndDate?.id.toString()
    );
    const samePropAndValueStart = moment(props.start).isSame(firstSelected);
    const samePropAndValueEnd = moment(props.end).isSame(secondSelected);
    if (showWarning) return true;
    else if (isNull(selectedStartDate) || isNull(selectedEndDate)) return true;
    else if (samePropAndValueStart && samePropAndValueEnd) return true;
    else return false;
  };

  useEffect(() => {
    setFormData();
  }, []);

  return (
    <div className="slot-edit-popup-wrapper">
      <div className="slot-edit-header">
        <Typography customStyle="slot-edit-label">Edit Slot</Typography>
        <div className="slot-edit-icon-wrapper" onClick={props.onClose}>
          <ImageComponent src={Images.close} />
        </div>
      </div>
      <div className="slot-edit-content-wrapper">
        <div className="slot-edit-select-content">
          <CustomSelect
            selectedOptions={selectedStartDate}
            dropdownOptions={startTimeOption}
            onChange={handleStartSelection}
            placeholder="10:00 AM"
            showFullWidth
          />
          <Typography>-</Typography>
          <CustomSelect
            selectedOptions={selectedEndDate}
            dropdownOptions={endTimeOption}
            onChange={handleEndSelection}
            placeholder="11:00 AM"
            showFullWidth
          />
        </div>
        <Typography customStyle="slot-date-label">
          {startDate.format(timeStamps.DAY_AND_DATE)}
        </Typography>
        {showWarning && (
          <div className="slot-warning-message-wrapper">
            <ImageComponent src={Images.warningOrange} alt="Warning" />
            <Typography customStyle="slot-warning-message">{PAST_START_TIME}</Typography>
          </div>
        )}
      </div>
      <div className="slot-action-wrapper">
        <Button variant="outlined" customStyle="slot-cancel-btn" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          customStyle="slot-save-btn"
          onClick={handleSave}
          disabled={checkValidation()}>
          Save
        </Button>
      </div>
    </div>
  );
}

SlotEditPopup.defaultProps = {
  title: 'Add Slot'
};

export default SlotEditPopup;
