import React, { Fragment, useEffect, useState } from 'react';
import './index.style.scss';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import _ from 'lodash';
import Images from 'assets/images';
import Button from 'app/core/shared-components/button';
import CheckBox from 'app/core/shared-components/checkbox';
import { getOnboardUserList } from 'app/redux/slices/onboardUserSlice';
import {
  ADMIN_FILTER_APPLY_BUTTON,
  ADMIN_FILTER_APPLY_DISABLED,
  ADMIN_FILTER_RESET_BUTTON,
  ADMIN_FILTER_RESET_DISABLED,
  APPLY,
  EXPERIENCEFILTER,
  EXPERIENCE_FILTER,
  FILTER_BY
} from 'helpers/constants';

interface IFiltersProps {
  onClose?: () => void;
}
interface IExperienceType {
  experienceFrom: number;
  experienceTo: number;
}
function ExperienceFilter(props: IFiltersProps) {
  const reduxOnboardState = useAppSelector((state) => state.onboardUser);
  const { filtersApplied, showDrawer } = reduxOnboardState;
  const [filterState, setFilterState] = useState(filtersApplied);
  const [isApplyEnabled, setIsApplyEnabled] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState('');
  const dispatch = useAppThunkDispatch();
  const { onClose } = props;
  const { cancelIcon, close } = Images;
  const filterOptions = [EXPERIENCE_FILTER];

  const handleSelection = (experience: IExperienceType) => () => {
    const data = {
      experienceFrom: experience.experienceFrom,
      experienceTo: experience.experienceTo
    };
    const checkExist = filterState.findIndex(
      (value) =>
        value.experienceFrom === data.experienceFrom && value.experienceTo === data.experienceTo
    );
    if (checkExist !== -1) {
      const data = [...filterState];
      data.splice(checkExist, 1);
      setFilterState(data);
      return;
    }
    setFilterState([...filterState, data]);
  };

  const handleApplyFilter = () => {
    const { onClose } = props;
    setIsApplyEnabled(true);
    dispatch(
      getOnboardUserList({
        pageNumber: 0,
        filter: filterState
      })
    );
    onClose && onClose();
  };

  const checkFilterChange = () => {
    const sortFilterState = _.sortBy(filterState);
    const sortApplyFilterState = _.sortBy(filtersApplied);
    return _.isEqual(sortFilterState, sortApplyFilterState);
  };

  const filterTickCheck = (params: IExperienceType) => {
    return filterState.some(
      (value) =>
        value.experienceFrom === params.experienceFrom && value.experienceTo === params.experienceTo
    );
  };

  const resetFilters = () => {
    setFilterState([]);
  };

  const filterData = () => {
    return (
      <Fragment>
        {EXPERIENCEFILTER?.map((experience, index: number) => {
          return (
            <CheckBox
              tabIndex
              key={index}
              label={experience?.label}
              checked={filterTickCheck(experience)}
              customClass="filter-options-checkbox"
              handleClick={handleSelection(experience)}
            />
          );
        })}
      </Fragment>
    );
  };

  const handleFilterByChange = (item: string) => {
    setIsItemSelected(item);
  };

  useEffect(() => {
    setIsItemSelected(EXPERIENCE_FILTER);
  }, []);

  return (
    <div className="admin-panel-list-filters-modal admin-panel-filter-backdrop">
      <div className="filter-modal-outer onboard-filter-modal-outer">
        <div className="ap-filter-header">
          <div className="admin-modal-filter-header">{FILTER_BY}</div>
          <div onClick={onClose} className="ap-filter-close">
            <img src={close} alt="" />
          </div>
        </div>
        <div className="onboard-parent-filter-container">
          <div className="side-bar-title">
            {filterOptions.map((item: string, index: number) => {
              return (
                <div
                  key={index}
                  className={isItemSelected === item ? 'item-name item-name-active' : 'item-name'}
                  onClick={() => handleFilterByChange(item)}>
                  {item}
                </div>
              );
            })}
          </div>
          <div className="right-side">{filterData()}</div>
        </div>
        <div className="btnContainer">
          <Button
            onClick={resetFilters}
            variant="outlined"
            size="medium"
            customStyle={isApplyEnabled ? ADMIN_FILTER_RESET_DISABLED : ADMIN_FILTER_RESET_BUTTON}>
            Reset
          </Button>
          <Button
            size="medium"
            customStyle={!isApplyEnabled ? ADMIN_FILTER_APPLY_BUTTON : ADMIN_FILTER_APPLY_DISABLED}
            disabled={checkFilterChange()}
            variant="contained"
            onClick={handleApplyFilter}>
            {APPLY}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ExperienceFilter;
