import React from 'react';
import './index.style.scss';
import ImageComponent from '../../../../core/shared-components/image';
import Typography from '../../../../core/shared-components/typography';

interface IUserInfoDataType {
  userName: string;
  userImage?: string;
}

const UserDetail = (props: IUserInfoDataType) => {
  const { userName, userImage = '' } = props;
  return (
    <div className="ta-user-detail-wrapper">
      <ImageComponent
        src={userImage}
        alt={userName}
        fallbackText={userName}
        customClass="ta-user-image"
        fallbackClass="ta-user-image-fallback"
      />
      <Typography customStyle="ta-user-detail-name">{userName}</Typography>
    </div>
  );
};

export default UserDetail;
