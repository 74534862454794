import React, { BaseSyntheticEvent, Fragment, SyntheticEvent, useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray, FieldArray, SubmitHandler } from 'react-hook-form';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomSelect, { ISelectOptions } from 'app/core/shared-components/customSelect';
import Button from 'app/core/shared-components/button';
import CheckBox from 'app/core/shared-components/checkbox';
import ProfileDetails from 'app/core/shared-components/profile-details';
import { ISelectValue } from 'app/core/shared-components/select';
import Typography from 'app/core/shared-components/typography';
import ImageComponent from 'app/core/shared-components/image';
import {
  APPROVE,
  APPROVED,
  ARCHIVE,
  IN_REVIEW,
  MARK_ON_BENCH,
  PANELLIST,
  PANEL_ID,
  PANEL_STATUS,
  RECRUITER_ID,
  SELECT_PROJECT,
  STATUSENUM_IN_REVIEW,
  STATUS_OPTIONS,
  TALENT_ACQUISITION,
  ZERO_EXPERIENCE
} from 'helpers/constants';
import { yupOnboard } from 'helpers/onboard.validation';
import { useAppSelector } from 'app/redux/hooks';
import { IPanelType } from 'helpers/panel';
import { DUPLICATE_VALUE } from 'helpers/messages.constants';
import Images from 'assets/images';
import { IARCHIVETYPES, ISTATUSFIELD, ISTATUSTYPES } from 'helpers/types';
import {
  projectStyles,
  projectStylesError,
  techStackStyleError,
  techStackStyles
} from './index.styles';
import './index.style.scss';

interface IUserDetail {
  name: string;
  designation: string;
  email: string;
  mobile: string;
  experience: string;
  profileImageUrl: string;
}
interface IClippedSelectValue {
  firstChipValue: ISelectValue | null;
  secondChipValue: ISelectValue | null;
}

export interface ITechStackArray {
  techStackId: string;
  interviewLevelId: string;
}

interface ITechStackDetails {
  techStackId: ISelectOptions | null;
  interviewLevelId: ISelectOptions | null;
}
interface IOnboardFormProps {
  selectedRole?: ISelectOptions | null;
  archive: boolean;
  project: ISelectOptions[] | null;
  techStackDetails: ITechStackDetails[] | null;
  status: ISelectOptions | null;
}
export interface IRoleFormProps {
  userDetail?: IUserDetail | null | false;
  hideRoleField?: boolean;
  hideArchiveField?: boolean;
  showProfileField?: boolean;
  onCancel?: () => void;
  panelForm?: boolean;
  onSave?: (
    role: ISelectValue,
    status: ISTATUSTYPES | string,
    projects?: ISelectValue[],
    stackLop?: ITechStackArray[]
  ) => void;
  showStatusField?: boolean;
  roleValue?: ISelectValue | IPanelType;
  statusFieldValue?: ISTATUSFIELD | undefined;
  archiveFieldValue?: IARCHIVETYPES;
  projectValue?: ISelectValue[];
  stackLopValue?: IClippedSelectValue[];
  disableStatus?: boolean;
  disableUserEditing?: boolean;
  disableArchive?: boolean;
}

interface IFormStates {
  disableAll: boolean;
  statusValue: ISelectValue | null;
  projectOptions: ISelectValue[];
  roleOptions: ISelectValue[];
  recruiter: ISelectValue | null;
  panel: ISelectValue | null;
}
interface IOptions {
  roleOptions: ISelectOptions[];
  projectOptions: ISelectOptions[];
  techStackOptions: ISelectOptions[];
  levelOfPanelOptions: ISelectOptions[];
}
function Form(props: IRoleFormProps) {
  const {
    showStatusField = false,
    userDetail = false,
    showProfileField = true,
    hideRoleField = false,
    panelForm = false,
    onCancel,
    onSave,
    roleValue = null,
    statusFieldValue = IN_REVIEW,
    archiveFieldValue = IN_REVIEW,
    projectValue = [],
    stackLopValue = [
      {
        firstChipValue: null,
        secondChipValue: null
      }
    ]
  } = props;

  const [disable, setDisable] = useState(true);
  const [isEqualCount, setIsEqualCount] = useState(0);
  const [formState, setFormState] = useState<IFormStates>({
    disableAll: false,
    statusValue: null,
    projectOptions: [],
    roleOptions: [],
    recruiter: null,
    panel: null
  });
  const [options, setOptions] = useState<IOptions>({
    roleOptions: [],
    projectOptions: [],
    techStackOptions: [],
    levelOfPanelOptions: []
  });
  const hookForm = useForm<IOnboardFormProps>({
    defaultValues: {
      archive: false,
      project: [],
      techStackDetails: [
        {
          techStackId: null,
          interviewLevelId: null
        }
      ],
      status: IN_REVIEW as unknown as ISelectOptions
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(yupOnboard)
  });

  const { roleOptions, projectOptions, levelOfPanelOptions, techStackOptions } = options;
  const reduxSharedState = useAppSelector((state) => state.shared);
  const { projects, recruiter: rdxRecruiter, panel: rdxPanel } = reduxSharedState;
  const reduxState = useAppSelector((state) => state.shared);
  const { panel } = formState;
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    clearErrors,
    resetField,
    formState: { isValid, errors }
  } = hookForm;
  const watchProject = watch('project');
  const watchArchive = watch('archive');
  const buttonDisableCondition = !isValid || disable;
  const selectedRoleCheck =
    (watch('selectedRole') && watch('selectedRole')?.id === panel?.id) ||
    (watch('selectedRole') && watch('selectedRole')?.id === panel?.id);
  const { append, remove, fields, replace, update } = useFieldArray({
    name: 'techStackDetails',
    control: control
  });
  const { cancelIcon } = Images;

  const getStatusValue = (status: ISTATUSFIELD | null) => {
    const [inReviewStatus, approvedStatus, ...rest] = STATUS_OPTIONS;
    if (status === PANEL_STATUS.inReview) return inReviewStatus;
    else if (status === PANEL_STATUS.approved) return approvedStatus;
    return null;
  };

  const onArchiveFieldClick = (_event: SyntheticEvent, checked: boolean) => {
    if (
      _.isEqual(checked, archiveFieldValue === ARCHIVE ? true : false) ||
      !getStatusValue(statusFieldValue)
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
    setValue('archive', checked, {
      shouldValidate: true
    });
    const [firstStackLOP, ...rest] = stackLopValue;
    if (stackLopValue?.length && firstStackLOP?.firstChipValue === null) {
      resetField('techStackDetails');
    }
    clearErrors();
  };

  const onMarkOnBenchClick = (_event: SyntheticEvent, checked: boolean) => {
    if (getStatusValue(statusFieldValue)) {
      setDisable(false);
    }
    if (checked) {
      setValue('project', [], {
        shouldValidate: true
      });
      return;
    }
  };

  const handleProjectUpdate = (params: ISelectOptions | ISelectOptions[]) => {
    if (_.isEqual(params, projectValue) && isEqualCount < 1) {
      setDisable(true);
    } else if (watch('status')) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    setValue('project', params as ISelectOptions[], {
      shouldValidate: true
    });
  };

  const handleRoleChange = (params: ISelectOptions | ISelectOptions[]) => {
    setValue('selectedRole', params as ISelectOptions, {
      shouldValidate: true
    });
    if (!Array.isArray(params) && params?.id === RECRUITER_ID) {
      setDisable(false);
    }
    replace({ techStackId: null, interviewLevelId: null });
    resetField('project');
    resetField('archive');
    clearErrors();
  };

  const handleAddMoreClick = (event: BaseSyntheticEvent) => {
    event.preventDefault();
    append({ techStackId: null, interviewLevelId: null });
  };

  const checkTechStackDetails = () => {
    const techStackDetails = watch('techStackDetails');

    const techStackArray: ITechStackArray[] = [];

    techStackDetails?.forEach((techStackdetail: ITechStackDetails) => {
      techStackArray.push({
        techStackId: techStackdetail?.techStackId?.id.toString() || '',
        interviewLevelId: techStackdetail?.interviewLevelId?.id.toString() || ''
      });
    });

    const uniqueArray = techStackArray.reduce<ITechStackArray[]>((accumulator, current) => {
      const stackLOP = accumulator.find(
        (item: ITechStackArray) =>
          item.techStackId === current.techStackId &&
          item.interviewLevelId === current.interviewLevelId
      );
      if (!stackLOP) {
        return accumulator.concat([current]);
      } else {
        return accumulator;
      }
    }, []);

    const fieldArrayLengthCheck = uniqueArray.length !== techStackArray.length;

    if (fieldArrayLengthCheck) {
      setError('techStackDetails', { message: DUPLICATE_VALUE });
    } else clearErrors('techStackDetails');
  };

  const handleTechStackUpdate = (params: ISelectOptions | ISelectOptions[], index: number) => {
    setValue(`techStackDetails.${index}.techStackId`, params as ISelectOptions, {
      shouldValidate: true
    });
    checkTechStackDetails();
    if (getStatusValue(statusFieldValue)) {
      setDisable(false);
      setIsEqualCount(isEqualCount + 1);
    }
  };

  const handleLOIUpdate = (params: ISelectOptions | ISelectOptions[], index: number) => {
    setValue(`techStackDetails.${index}.interviewLevelId`, params as ISelectOptions, {
      shouldValidate: true
    });
    checkTechStackDetails();
    if (getStatusValue(statusFieldValue)) {
      setDisable(false);
      setIsEqualCount(isEqualCount + 1);
    }
  };

  const handleStatusUpdate = (params: string | ISelectValue | null | ISelectValue[]) => {
    if (_.isEqual(params, getStatusValue(statusFieldValue))) {
      setDisable(true);
    } else {
      setDisable(false);
      setIsEqualCount(isEqualCount + 1);
    }
    setValue('status', params as ISelectValue, {
      shouldValidate: true
    });
  };

  const handleSubmission: SubmitHandler<IOnboardFormProps> = (data) => {
    const { onSave } = props;
    if (data.techStackDetails) {
      const [firstTechStack, ...rest] = data.techStackDetails;
      const techStackData =
        data.techStackDetails.length && firstTechStack.techStackId != undefined
          ? data.techStackDetails.map((item) => ({
              techStackId: item?.techStackId?.id as string,
              interviewLevelId: item?.interviewLevelId?.id as string
            }))
          : [];
      if (isValid) {
        const statusValue = watchArchive
          ? ARCHIVE
          : watch('status')?.id === 0
          ? STATUSENUM_IN_REVIEW
          : APPROVE;

        onSave &&
          onSave(
            watch('selectedRole') as ISelectOptions,
            statusValue as string,
            watch('project') as ISelectOptions[],
            techStackData
          );
        reset();
      }
    }
  };

  const removeFieldSet = (index: number) => {
    remove(index);
    checkTechStackDetails();
    if (getStatusValue(statusFieldValue)) {
      setDisable(false);
    }
  };

  const updateRoleState = () => {
    const data = [
      {
        id: rdxRecruiter?.id ? rdxRecruiter.id.toString() : RECRUITER_ID,
        label: TALENT_ACQUISITION
      },
      {
        id: rdxPanel?.id ? rdxPanel.id.toString() : PANEL_ID,
        label: PANELLIST
      }
    ];
    setOptions((prev) => ({
      ...prev,
      roleOptions: data
    }));
    return data;
  };

  const updateFormState = () => {
    const data = [
      {
        id: rdxRecruiter?.id ? rdxRecruiter.id : RECRUITER_ID,
        label: TALENT_ACQUISITION
      },
      {
        id: rdxPanel?.id ? rdxPanel.id : PANEL_ID,
        label: PANELLIST
      }
    ];
    const [recruiterData, panelData, ...rest] = data;
    setFormState((prevState) => ({
      ...prevState,
      recruiter: recruiterData,
      panel: panelData,
      roleOptions: data
    }));
    return data;
  };

  const setFormValues = () => {
    setValue('status', getStatusValue(statusFieldValue));
    setValue('project', projectValue);

    setValue('selectedRole', roleValue);
    setValue('archive', archiveFieldValue === ARCHIVE ? true : false);

    const data = stackLopValue.length
      ? stackLopValue.map((value) => {
          return {
            techStackId: {
              id:
                typeof value.firstChipValue?.id === undefined
                  ? (null as unknown as string)
                  : (value.firstChipValue?.id as string),
              label: (value.firstChipValue?.label as string) || (null as unknown as string)
            },
            interviewLevelId: {
              id:
                typeof value.secondChipValue?.id === undefined
                  ? (null as unknown as string)
                  : (value.secondChipValue?.id as string),
              label: (value.secondChipValue?.label as string) || (null as unknown as string)
            }
          };
        })
      : [
          {
            techStackId: null,
            interviewLevelId: null
          }
        ];
    replace(data);
    setError('project', {
      message: ''
    });
    if (statusFieldValue === APPROVE) {
      setValue('archive', false);
    }
  };

  const updateProjectOptions = () => {
    const data = projects.map((item) => ({ id: item.id, label: item.name }));
    setFormState((prevState) => ({ ...prevState, projectOptions: data }));
  };
  const updateLevelOfPanelOptions = () => {
    const { levelOfInterview } = reduxSharedState;
    const data = levelOfInterview.map((item) => ({ id: item.id, label: item.level }));
    setOptions((prev) => ({
      ...prev,
      levelOfPanelOptions: data
    }));
  };
  const updateTechStackOptions = () => {
    const { techStack } = reduxSharedState;
    const data = techStack.map((item) => ({ id: item.id, label: item.stack }));
    setOptions((prev) => ({
      ...prev,
      techStackOptions: data
    }));
  };
  const handleProjectList = () => {
    const data = projects.map((item) => ({ id: item.id, label: item.name }));
    setOptions((prev) => ({
      ...prev,
      projectOptions: data
    }));
  };
  useEffect(() => {
    handleProjectList();
  }, [reduxSharedState?.projects]);

  useEffect(() => {
    updateTechStackOptions();
  }, [reduxSharedState.techStack]);

  useEffect(() => {
    updateLevelOfPanelOptions();
  }, [reduxSharedState.levelOfInterview]);

  useEffect(() => {
    updateRoleState();
  }, [reduxSharedState?.roles]);

  useEffect(() => {
    updateProjectOptions();
  }, [reduxSharedState?.projects]);

  useEffect(() => {
    updateFormState();
  }, [reduxSharedState?.roles]);

  useEffect(() => {
    setFormValues();
  }, []);
  return (
    <div className="drawer-form-container">
      <form onSubmit={handleSubmit(handleSubmission)} className="drawer-detail-wrapper">
        <div className="onboard-form">
          {userDetail && showProfileField && (
            <ProfileDetails
              name={userDetail.name}
              designation={userDetail.designation}
              email={userDetail.email}
              mobile={userDetail.mobile}
              profileImageUrl={userDetail.profileImageUrl}
              showExperience={userDetail?.experience ? userDetail?.experience : ZERO_EXPERIENCE}
            />
          )}
          <div className="role-form-container">
            <div className="role-form-wrapper">
              {!hideRoleField && (
                <div className="role-selection-container">
                  <Typography customStyle="form-label form-label-primary">Role*</Typography>
                  {watch('selectedRole') && watch('selectedRole')?.id === panel?.id && panelForm ? (
                    <div className="panel-label" tabIndex={0}>
                      {watch('selectedRole')?.label}
                    </div>
                  ) : (
                    <Controller
                      name="selectedRole"
                      control={control}
                      render={({ field }) => (
                        <CustomSelect
                          {...field}
                          showDropdownIndicator
                          dropdownOptions={roleOptions}
                          selectedOptions={watch('selectedRole') as ISelectOptions}
                          placeholder="Select Role *"
                          onChange={handleRoleChange}
                          disabled={roleValue?.id === PANEL_ID}
                          blurInputOnSelect={true}
                        />
                      )}
                    />
                  )}
                </div>
              )}
              {!!selectedRoleCheck && (
                <Fragment>
                  <Controller
                    name="archive"
                    control={control}
                    render={({ field }) => (
                      <CheckBox
                        {...field}
                        tabIndex={true}
                        checked={watch('archive') as boolean}
                        label="Archive"
                        customClass="form-label form-label-secondary checkboxfield"
                        handleClick={onArchiveFieldClick}
                        disabled={getStatusValue(statusFieldValue)?.label === APPROVED}
                      />
                    )}
                  />
                  {showStatusField && (
                    <div className="panel-form-status-container">
                      <Typography customStyle="form-label form-label-primary status-label">
                        Status*
                      </Typography>
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <CustomSelect
                            selectedOptions={field.value}
                            dropdownOptions={STATUS_OPTIONS}
                            disabled={
                              (watchArchive ||
                                getStatusValue(statusFieldValue)?.label === APPROVED) as boolean
                            }
                            onChange={handleStatusUpdate}
                            showDropdownIndicator
                            blurInputOnSelect={true}
                          />
                        )}
                      />
                    </div>
                  )}

                  <Typography customStyle="form-label form-label-primary">Project*</Typography>
                  <Controller
                    name="project"
                    control={control}
                    render={({ field }) => (
                      <CustomSelect
                        {...field}
                        isMulti
                        showDropdownIndicator
                        dropdownOptions={projectOptions}
                        selectedOptions={watch('project')}
                        placeholder={SELECT_PROJECT}
                        disabled={watchArchive as boolean}
                        onChange={handleProjectUpdate}
                        blurInputOnSelect={true}
                      />
                    )}
                  />
                  <Controller
                    name="project"
                    control={control}
                    render={({ field }) => (
                      <CheckBox
                        {...field}
                        tabIndex={true}
                        checked={!watchProject?.length}
                        label={MARK_ON_BENCH}
                        customClass="form-label form-label-secondary checkboxfield"
                        handleClick={onMarkOnBenchClick}
                        disabled={(!watchProject?.length || watchArchive) as boolean}
                      />
                    )}
                  />
                  <div className="stackLoi-row-container">
                    <Typography customStyle="stackLoi-text-primary">Tech Stack *</Typography>
                    <Typography customStyle="stackLoi-text-primary">Level of Panel *</Typography>
                  </div>

                  {!!fields.length &&
                    fields.map((field, index: number) => {
                      return (
                        <div key={field?.id} className="techStack-fieldArray">
                          <Controller
                            name={`techStackDetails.${index}.techStackId`}
                            control={control}
                            render={({ field: fieldController }) => (
                              <CustomSelect
                                {...fieldController}
                                showDropdownIndicator
                                dropdownOptions={techStackOptions}
                                selectedOptions={fieldController.value}
                                disabled={watchArchive as boolean}
                                customStyles={
                                  errors.techStackDetails?.message
                                    ? techStackStyleError
                                    : techStackStyles
                                }
                                onChange={(event) => handleTechStackUpdate(event, index)}
                                blurInputOnSelect={true}
                              />
                            )}
                          />
                          <Controller
                            key={field.id}
                            name={`techStackDetails.${index}.interviewLevelId`}
                            control={control}
                            render={({ field: fieldController }) => (
                              <CustomSelect
                                {...fieldController}
                                showDropdownIndicator
                                dropdownOptions={levelOfPanelOptions}
                                selectedOptions={fieldController.value}
                                disabled={watchArchive as boolean}
                                customStyles={
                                  errors.techStackDetails?.message
                                    ? projectStylesError
                                    : projectStyles
                                }
                                onChange={(event) => handleLOIUpdate(event, index)}
                                blurInputOnSelect={true}
                              />
                            )}
                          />

                          <Button
                            onClick={() => {
                              removeFieldSet(index);
                            }}
                            disabled={
                              watch('techStackDetails')?.length === 1 || (watchArchive as boolean)
                            }
                            className="onboard-remove-button">
                            <div className="clipped-close-Icon">
                              <ImageComponent
                                src={cancelIcon}
                                alt={'Close Icon'}
                                className={
                                  watch('techStackDetails')?.length === 1 ? 'stackLoi-icon' : ''
                                }
                              />
                            </div>
                          </Button>
                        </div>
                      );
                    })}
                  <div>
                    {!!errors.techStackDetails && (
                      <p className="error-message">{errors?.techStackDetails?.message}</p>
                    )}
                  </div>
                  <Button
                    onClick={(event) => handleAddMoreClick(event)}
                    disabled={watchArchive as boolean}
                    className={
                      !watchArchive
                        ? 'onboard-add-button'
                        : 'onboard-add-button onboard-add-button-disabled'
                    }>
                    Add more
                  </Button>
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <div className="role-form-action-button-wrapper">
          <Button
            variant="outlined"
            type="button"
            customStyle="form-action-buttons form-action-buttons--cancel"
            onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            customStyle="form-action-buttons"
            disabled={buttonDisableCondition}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}

export default Form;
