import React from 'react';
import Typography from '../../../../core/shared-components/typography';
import Slots from '../../../Slots';
import './index.style.scss';

interface ISlotOverviewProps {
  title: string;
  showOnlyAvailableSlots?: boolean;
}

const SlotOverview = ({ title, showOnlyAvailableSlots }: ISlotOverviewProps) => {
  return (
    <div
      className={
        showOnlyAvailableSlots
          ? 'slot-overview-wrapper available-slots-wrapper'
          : 'slot-overview-wrapper'
      }>
      <Typography customStyle="slot-overview-title" variant="headline-16">
        {title}
      </Typography>
      <Slots isSlotPage={false} hideTabs={showOnlyAvailableSlots} />
    </div>
  );
};

export default SlotOverview;
