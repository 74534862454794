import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { uniqueId } from 'lodash';
import moment from 'moment';
import Images from 'assets/images';
import { isAdmin } from 'helpers/authHelper';
import { DASHBOARD, MAX_NOTIFICATION_COUNT, PLUS_NOTIFICATION, SEARCH } from 'helpers/constants';
import Button from 'app/core/shared-components/button';
import Drawer from 'app/core/shared-components/drawer';
import ImageComponent from 'app/core/shared-components/image';
import Loader from 'app/core/shared-components/loader';
import Search from 'app/core/shared-components/search';
import SlotRequestDrawer from 'app/core/shared-components/slot-request-drawer';
import Typography from 'app/core/shared-components/typography';
import UsersTab from 'app/core/shared-components/users-section-tab';
import { ADMIN, PANEL } from 'app/redux/constant';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  closeNotification,
  getSlotSummaryData,
  IOptionType
} from 'app/redux/slices/adminDashboardSlice';
import { handleSearch as handlePanelSearch } from 'app/redux/slices/slotsSlice';
import ErrorPage from '../../Error';
import DashboardLayout from '../components/dashboard-layout';
import NotificationDrawer from '../components/notification-drawer';
import RewardsWidget from '../components/rewards-widget';
import SlotEfficiency from '../components/slot-efficiency';
import SlotSummary from '../components/slot-summary';
import UpcomingInterviews from '../components/upcoming-interviews';
import './index.style.scss';
import { IPanelSearch } from 'app/services/user-response.types';
import { SCHEDULE } from 'helpers/appRoutes';
import { timeStamps } from 'helpers/timeStamp.constants';
import { SLOT_REQUEST_RAISED } from 'helpers/messages.constants';

interface IPanelDashboardStates {
  showPanelSearch: boolean;
  options: IOptionType[];
  resultNotFound: boolean;
  isSlotNotificationOpen: boolean;
  isNotificationOpen: boolean;
}
const WIDGETS: any = {
  admin: [
    {
      column: 1,
      components: [<SlotEfficiency key={uniqueId()} />]
    },
    {
      column: 2,
      components: [
        <div key={uniqueId()} className="rewards-interview">
          <UpcomingInterviews userRole="admin" />
          <RewardsWidget />
        </div>
      ]
    },
    {
      column: 3,
      components: [<UsersTab key={uniqueId()} />]
    }
  ],
  panel: [
    {
      column: 1,
      components: [<SlotSummary key={uniqueId()} />]
    },
    {
      column: 2,
      components: [
        <div key={uniqueId()} className="rewards-interview">
          <UpcomingInterviews userRole="panel" />
          <RewardsWidget />
        </div>
      ]
    }
    // {
    //   column: 3,
    //   components: [<SlotAchievements key={uniqueId()} />]
    // }
  ]
};
const PanelDashboard = () => {
  const [panelDashboardStates, setPanelDashboardStates] = useState<IPanelDashboardStates>({
    showPanelSearch: false,
    options: [],
    resultNotFound: false,
    isSlotNotificationOpen: false,
    isNotificationOpen: false
  });

  const [userRole, setUserRole] = useState(PANEL);
  const [widget, setWidget] = useState<any>([]);

  const timeOut = useRef<any>(null);
  const abortSignal = useRef<any>(null);
  const navigate = useNavigate();

  const userState = useAppSelector((state) => state.user);
  const { firstName, userId, isLoading } = useAppSelector((state) => state.user.userData);
  const { showNotification, currentSelectedDate } = useAppSelector((state) => state.adminDashboard);
  const { slotRequests } = useAppSelector((state) => state.schedule);
  const slotsReduxState = useAppSelector((state) => state.slots);
  const { notificationCount } = useAppSelector((state) => state?.shared);
  const { isFirstLogin } = useAppSelector((state) => state.login);

  const dispatch = useAppThunkDispatch();

  const { showPanelSearch, resultNotFound, options, isSlotNotificationOpen, isNotificationOpen } =
    panelDashboardStates;

  const { close, notificationIcon } = Images;

  const [firstSlotRequest, ...rest] = slotRequests;

  const handlePanelSearchAction = async (searchString: string) => {
    setPanelDashboardStates((prevState) => ({
      ...prevState,
      options: []
    }));
    if (resultNotFound) {
      setPanelDashboardStates((prevState) => ({
        ...prevState,
        resultNotFound: false
      }));
    }
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
    if (!!searchString?.trim()?.length) {
      timeOut.current = setTimeout(async () => {
        if (abortSignal.current) abortSignal.current.abort();
        abortSignal.current = new AbortController();
        const signal = abortSignal.current;
        const data = await dispatch(handlePanelSearch({ searchString, signal }));
        if (data.payload?.data?.length) {
          const options = data.payload?.data?.map((user: IPanelSearch) => {
            return {
              id: user?.id,
              startIcon: user?.profileImageUrl,
              searchKey: `${user?.firstName} ${user?.lastName}`,
              supportingData: user?.designation,
              singleSearchData: user
            };
          });
          setPanelDashboardStates((prevState) => ({
            ...prevState,
            options: options,
            resultNotFound: false
          }));
        } else {
          setPanelDashboardStates((prevState) => ({
            ...prevState,
            resultNotFound: true
          }));
        }
      }, 1000);
    } else {
      setPanelDashboardStates((prevState) => ({ ...prevState, options: [] }));
    }
  };

  const handlePanelSearchSelection = (id: string | number, _searchSingleData: any) => {
    navigate(SCHEDULE, { state: { userId: id } });
  };

  const handleSlotNotification = () => {
    setPanelDashboardStates((prevState) => ({
      ...prevState,
      isSlotNotificationOpen: !isSlotNotificationOpen
    }));
  };

  const handleCloseNotification = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    event.stopPropagation();
    dispatch(closeNotification());
  };

  const handleOnSlotAccept = () => {
    dispatch(getSlotSummaryData(moment(currentSelectedDate).format(timeStamps.DATE_TIME)));
  };

  const closeNotificationDrawer = () => {
    setPanelDashboardStates((prevState: IPanelDashboardStates) => ({
      ...prevState,
      isNotificationOpen: false
    }));
  };

  const openNotificationDrawer = () => {
    setPanelDashboardStates((prevState: IPanelDashboardStates) => ({
      ...prevState,
      isNotificationOpen: true
    }));
  };

  const getFormattedTime = (time: string | Date) => moment(time).format(timeStamps.HH_MM_A);

  const updateUserRole = () => {
    const userRole = userState?.userData?.role?.map((item) => item.role);
    if (isAdmin(userRole)) {
      setUserRole(ADMIN);
    } else {
      setUserRole(PANEL);
    }
  };

  useEffect(() => {
    updateUserRole();
  }, [userState?.userData?.role]);

  useEffect(() => {
    setWidget(WIDGETS[userRole]);
  }, [userRole]);

  return slotsReduxState.isSearchError ? (
    <ErrorPage page="apiFailure" />
  ) : isLoading ? (
    <Loader loading={isLoading} />
  ) : (
    <div className="panel-dashboard-wrapper">
      <div className="panel-dashboard-header">
        <div className="header-container">
          <div className="header-title">{isFirstLogin ? `Welcome ${firstName}` : DASHBOARD}</div>
        </div>

        <div className="actions-container">
          <div
            className={`notifications ${isNotificationOpen ? 'notifications-active' : ''}`}
            onClick={openNotificationDrawer}>
            {!!Number(notificationCount) && (
              <div
                className={
                  notificationCount > MAX_NOTIFICATION_COUNT
                    ? 'count-increased notification-count'
                    : 'notification-count'
                }>
                {notificationCount > MAX_NOTIFICATION_COUNT ? PLUS_NOTIFICATION : notificationCount}
              </div>
            )}
            <ImageComponent src={notificationIcon} customClass="notification-icon" />
          </div>

          {userRole === ADMIN && (
            <div className="search-bar">
              <Search
                placeholder={SEARCH}
                onChange={handlePanelSearchAction}
                setSelectedId={handlePanelSearchSelection}
                options={options}
                customDropdownIconClass="search-circle"
                clearResult={true}
                resultNotFound={resultNotFound}
              />
            </div>
          )}
        </div>
      </div>
      {!!(showNotification && slotRequests?.length) &&
        (slotRequests?.length === 1 ? (
          <div className="slot-notification" onClick={handleSlotNotification}>
            <div className="slot-notification-details">
              <Typography variant="headline-16">Slot Request:</Typography>
              <div className="tag blue">{`${firstSlotRequest.techStack} - ${firstSlotRequest.level}`}</div>
              <div className="tag green">{`${getFormattedTime(
                firstSlotRequest.fromTime
              )} - ${getFormattedTime(firstSlotRequest.toTime)}`}</div>
              <div className="tag yellow">
                {moment(firstSlotRequest.date).format(timeStamps.DD_MMMM_YYYY)}
              </div>
            </div>
            <div className="ta-detail">
              <Typography variant="headline-16" customStyle="ta-name">
                <span>Raised by</span>{' '}
                {`${firstSlotRequest.firstName} ${firstSlotRequest.lastName}`}
              </Typography>
            </div>
            <Button variant="text" customStyle="view-details-btn" onClick={handleSlotNotification}>
              View Details
            </Button>
            <ImageComponent
              src={close}
              customClass="cross-icon"
              onClick={handleCloseNotification}
            />
          </div>
        ) : (
          <div className="slot-notification">
            <div className="slot-notification-details">
              <Typography variant="headline-16">{`${slotRequests.length} ${SLOT_REQUEST_RAISED}`}</Typography>
            </div>
            <div className="ta-detail">
              <Button
                variant="text"
                customStyle="view-details-btn"
                onClick={handleSlotNotification}>
                View Details
              </Button>
            </div>
            <ImageComponent
              src={close}
              customClass="cross-icon"
              onClick={handleCloseNotification}
            />
          </div>
        ))}
      <SlotRequestDrawer
        open={isSlotNotificationOpen}
        onClose={handleSlotNotification}
        onAccept={handleOnSlotAccept}
      />
      <div className="notification-drawer-container">
        <Drawer
          isOpen={isNotificationOpen}
          onClose={closeNotificationDrawer}
          headerProps="Notifications"
          headerStyles="notification-header"
          bodyStyles="notification-body"
          hideDivider>
          <NotificationDrawer />
        </Drawer>
      </div>

      <DashboardLayout layout={widget} />
    </div>
  );
};

export default PanelDashboard;
