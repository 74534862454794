import React, { Fragment } from 'react';
import './index.style.scss';
import TechItem from 'app/pages/TechStack/components/TechItem';
import ImageComponent from 'app/core/shared-components/image';
import Typography from 'app/core/shared-components/typography';
import Images from 'assets/images';
import { NO_RESULT_FOUND_ERROR } from 'helpers/messages.constants';
import { ITechStackType } from '../../../../services/user-response.types';

interface ITechStackCardProps {
  data: ITechStackType[];
  localDelete: (params: ITechStackType) => void;
  undoLocalDelete: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  closeToast: (id: string) => void;
}

const TechStackCard = (props: ITechStackCardProps) => {
  const { data, localDelete, undoLocalDelete, closeToast } = props;
  const { noResult } = Images;

  return (
    <Fragment>
      {!!data.length ? (
        data?.map((item) => (
          <TechItem
            key={item?.id}
            techItem={item}
            localDelete={localDelete}
            onUndo={undoLocalDelete}
            onDelete={closeToast}
          />
        ))
      ) : (
        <div className="no-result-tech-stack">
          <ImageComponent src={noResult} className="no-results-image" />
          <Typography variant="text" customStyle="name">
            {NO_RESULT_FOUND_ERROR}
          </Typography>
        </div>
      )}
    </Fragment>
  );
};

export default TechStackCard;
