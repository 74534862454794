import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SEARCH } from '../../../helpers/constants';
import {
  techStackEdit,
  addTechStack,
  deleteTechStack,
  techStackService,
  searchTechStack,
  userListPanelTechStack,
  userListSearchPanelTechStack,
  userListRecruiterTechStack,
  userListSearchRecruiterTechStack
} from '../../services/techStack.service';
import {
  ADD_TECH_STACK,
  DELETE_TECH_STACK,
  EDIT_TECH_STACK,
  PANEL_LIST_TS,
  PANEL_SEARCH_LIST_TS,
  RECRUITER_LIST_TS,
  RECRUITER_SEARCH_LIST_TS,
  TECH_STACK
} from '../constant';
import { ISelectedSearch } from './slotsSlice';
import { ITechStackType } from './userSlice';

interface IAddTechStackData {
  imageData: FormData;
  newTechName: string;
}

interface IEditTechStackData {
  imageFile: FormData | null;
  imageUrl: string;
  id: string;
  stackName: string;
}

export interface ITechStackState {
  techData: ITechStackType[];
  selectedSearch: ISelectedSearch;
  clearResult: boolean;
  isLoading: boolean;
  resetPage: boolean;
  isError: boolean;
  editResponse: { data: boolean; error: null | string };
  currentDeletion: { name: string; imageData: any; id: number };
  isToastVisible: boolean;
  isDeletionInProcess: boolean;
  deletionErrorToast: string;
  deletionAsignee: {
    recruiterCount: number;
    panelCount: number;
    bookedSlotCount: number;
  };
  isPanelListLoading: boolean;
  isRecruiterListLoading: boolean;
  panelListData?: [];
  recruiterListData?: [];
  panelCount?: number;
  recruiterCount?: number;
}
const initialState: ITechStackState = {
  techData: [],
  selectedSearch: {} as ISelectedSearch,
  clearResult: false,
  isLoading: false,
  resetPage: false,
  isError: false,
  editResponse: { data: true, error: null },
  currentDeletion: { name: '', imageData: {}, id: -1 },
  isToastVisible: false,
  isDeletionInProcess: false,
  deletionErrorToast: '',
  deletionAsignee: {
    recruiterCount: 0,
    panelCount: 0,
    bookedSlotCount: 0
  },
  isPanelListLoading: false,
  isRecruiterListLoading: false,
  panelListData: [],
  recruiterListData: [],
  panelCount: 0,
  recruiterCount: 0
};
interface TechStackUserListType {
  id: string;
  searchText: string;
}

export const getPanelsUserListTechStacks = createAsyncThunk(
  PANEL_LIST_TS,
  async function getPanelsUserListTechStacks(id: string, thunkAPI) {
    const resp = await userListPanelTechStack(id);
    if (resp?.data !== null) {
      return resp;
    }
    return thunkAPI.rejectWithValue(resp);
  }
);
export const getRecruiterUserListTechStacks = createAsyncThunk(
  RECRUITER_LIST_TS,
  async function getRecruiterUserListTechStacks(id: string, thunkAPI) {
    const resp = await userListRecruiterTechStack(id);
    if (resp?.data !== null) {
      return resp;
    }
    return thunkAPI.rejectWithValue(resp);
  }
);
export const getPanelsSearchUserListTechStacks = createAsyncThunk(
  PANEL_SEARCH_LIST_TS,
  async function getPanelsSearchUserListTechStacks(data: TechStackUserListType, thunkAPI) {
    const resp = await userListSearchPanelTechStack(data?.id, data?.searchText);
    if (resp?.data !== null) {
      return resp;
    }
    return thunkAPI.rejectWithValue(resp);
  }
);
export const getRecruiterSearchUserListTechStacks = createAsyncThunk(
  RECRUITER_SEARCH_LIST_TS,
  async function getRecruiterSearchUserListTechStacks(data: TechStackUserListType, thunkAPI) {
    const resp = await userListSearchRecruiterTechStack(data?.id, data?.searchText);
    if (resp?.data !== null) {
      return resp;
    }
    return thunkAPI.rejectWithValue(resp);
  }
);

export const getTechStackData = createAsyncThunk(
  TECH_STACK,
  async function techStackData(sortingOrder: string, thunkAPI) {
    const resp = await techStackService(sortingOrder);
    if (!resp?.error) {
      return resp.data;
    }
    return thunkAPI.rejectWithValue(resp);
  }
);

export const deleteTechStacks = createAsyncThunk(
  DELETE_TECH_STACK,
  async function deleteTechStacks(id: string, thunkAPI) {
    const resp = await deleteTechStack(id);
    if (resp?.data !== null) {
      return resp;
    }
    return thunkAPI.rejectWithValue(resp);
  }
);

export const addTechStacks = createAsyncThunk(
  ADD_TECH_STACK,
  async function addTechStacks(data: IAddTechStackData, thunkAPI) {
    const resp = await addTechStack(data?.newTechName?.trim(), data?.imageData);
    if (resp?.data !== null) {
      return resp;
    }
    return thunkAPI.rejectWithValue(resp);
  }
);
export const editTechStacks = createAsyncThunk(
  EDIT_TECH_STACK,
  async function editTechStacks(data: IEditTechStackData, thunkAPI) {
    const resp = await techStackEdit(
      data?.id,
      data?.stackName?.trim(),
      data?.imageFile,
      data?.imageUrl
    );
    if (resp?.data !== null) {
      return resp;
    }
    return thunkAPI.rejectWithValue(resp);
  }
);
export const handleTechSearch = createAsyncThunk(SEARCH, async function handleSearch(params: any) {
  const { searchString, abortSignal } = params;
  const data = await searchTechStack(searchString, abortSignal?.signal);
  if (!data.error) {
    return data;
  } else {
    return { data: data };
  }
});

const builderFuncPanel = (
  state: ITechStackState,
  loading: boolean,
  userList: [] | undefined,
  count: number | undefined,
  error: boolean
) => {
  (state.isPanelListLoading = loading),
    (state.panelListData = userList),
    (state.panelCount = count);
  state.isError = error;
};

const builderFuncRecruiter = (
  state: ITechStackState,
  loading: boolean,
  userList: [] | undefined,
  count: number | undefined,
  error: boolean
) => {
  (state.isRecruiterListLoading = loading),
    (state.recruiterListData = userList),
    (state.recruiterCount = count);
  state.isError = error;
};

export const techStackSlice = createSlice({
  name: TECH_STACK,
  initialState,
  reducers: {
    searchSelected: (state, action) => {
      state.selectedSearch = action.payload;
    },
    clearSearchBarValue: (state) => {
      state.clearResult = true;
    },
    resetSearchBarValue: (state) => {
      state.clearResult = false;
    },
    handleResetPage: (state) => {
      state.resetPage = true;
      state.isError = false;
    },
    setDeletionErrToast: (state, action) => {
      state.deletionErrorToast = action.payload;
    },
    setDeleteionAsignee: (state, action) => {
      state.deletionAsignee = {
        recruiterCount: action.payload?.recruiterCount,
        panelCount: action.payload?.panelCount,
        bookedSlotCount: action.payload?.bookedSlotCount
      };
    },

    setIsDeletionInProcess: (state, action) => {
      state.isDeletionInProcess = action.payload;
    },
    setCurrentDeletion: (state, action) => {
      state.currentDeletion = {
        name: action.payload?.name,
        imageData: action.payload?.imageData,
        id: action.payload?.id
      };
    },
    setIsToastVisible: (state, action) => {
      state.isToastVisible = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPanelsUserListTechStacks.fulfilled, (state, { payload }) =>
      builderFuncPanel(state, false, payload?.data?.panelList, payload?.data?.count, false)
    );
    builder.addCase(getPanelsUserListTechStacks.pending, (state, { payload }) =>
      builderFuncPanel(state, true, undefined, undefined, false)
    );
    builder.addCase(getPanelsUserListTechStacks.rejected, (state, { payload }) =>
      builderFuncPanel(state, false, undefined, undefined, true)
    );

    builder.addCase(getPanelsSearchUserListTechStacks.fulfilled, (state, { payload }) =>
      builderFuncPanel(state, false, payload?.data?.panelList, payload?.data?.count, false)
    );
    builder.addCase(getPanelsSearchUserListTechStacks.pending, (state, { payload }) =>
      builderFuncPanel(state, true, undefined, undefined, false)
    );
    builder.addCase(getPanelsSearchUserListTechStacks.rejected, (state, { payload }) =>
      builderFuncPanel(state, false, undefined, undefined, true)
    );

    builder.addCase(getRecruiterUserListTechStacks.fulfilled, (state, { payload }) =>
      builderFuncRecruiter(state, false, payload?.data?.recruiterList, payload?.data?.count, false)
    );
    builder.addCase(getRecruiterUserListTechStacks.pending, (state, { payload }) =>
      builderFuncRecruiter(state, true, undefined, undefined, false)
    );
    builder.addCase(getRecruiterUserListTechStacks.rejected, (state, { payload }) =>
      builderFuncRecruiter(state, false, undefined, undefined, true)
    );

    builder.addCase(getRecruiterSearchUserListTechStacks.fulfilled, (state, { payload }) =>
      builderFuncRecruiter(state, false, payload?.data?.recruiterList, payload?.data?.count, false)
    );
    builder.addCase(getRecruiterSearchUserListTechStacks.pending, (state, { payload }) =>
      builderFuncRecruiter(state, true, undefined, undefined, false)
    );
    builder.addCase(getRecruiterSearchUserListTechStacks.rejected, (state, { payload }) =>
      builderFuncRecruiter(state, false, undefined, undefined, true)
    );

    builder.addCase(getTechStackData.fulfilled, (state, { payload }) => {
      state.techData = payload?.data;
      state.isLoading = false;
    });
    builder.addCase(getTechStackData.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(getTechStackData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(editTechStacks.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.editResponse = { data: payload?.data, error: payload?.error };
    });
    builder.addCase(editTechStacks.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(editTechStacks.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addTechStacks.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(addTechStacks.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(addTechStacks.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(deleteTechStacks.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(deleteTechStacks.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(deleteTechStacks.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });
  }
});

export const {
  searchSelected,
  clearSearchBarValue,
  resetSearchBarValue,
  handleResetPage,
  setCurrentDeletion,
  setIsToastVisible,
  setIsDeletionInProcess,
  setDeletionErrToast,
  setDeleteionAsignee
} = techStackSlice.actions;
export default techStackSlice.reducer;
