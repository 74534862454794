import './index.style.scss';
import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  TooltipProps,
  ResponsiveContainer,
  Rectangle,
  ReferenceArea
} from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import CircleRequired from '../../../../../assets/images/circle-required.svg';
import CircleProvided from '../../../../../assets/images/circle-provided.svg';
import CircleBooked from '../../../../../assets/images/circle-booked.svg';
import CircleCancelled from '../../../../../assets/images/circle-cancelled.svg';
import ImageComponent from '../../../../core/shared-components/image';
import { useAppSelector } from '../../../../redux/hooks';
import { ISlotEfficiencyData } from '../../../../redux/slices/adminDashboardSlice';

interface IChartData {
  month: string;
  required: number;
  provided: number;
  booked: number;
  cancelled: number;
}

interface ISlotChartProps {
  chartData: IChartData[];
}

function SlotChart({ chartData }: ISlotChartProps) {
  const { isSlotEfficiencyLoading: loading } = useAppSelector((state) => state.adminDashboard);

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <div className="label">
            <ImageComponent src={CircleRequired} />
            <div>{`Required: ${payload?.[0]?.payload?.required || 0}`}</div>
          </div>
          <div className="label">
            <ImageComponent src={CircleProvided} />
            {`Provided: ${payload?.[0]?.payload?.provided}`}
          </div>
          <div className="label">
            <ImageComponent src={CircleBooked} />
            {`Booked: ${payload?.[0]?.payload?.booked}`}
          </div>
          <div className="label">
            <ImageComponent src={CircleCancelled} />
            {`Cancelled: ${payload?.[0]?.payload?.cancelled}`}
          </div>
          <div className="tooltip-arrow"></div>
        </div>
      );
    }
    return null;
  };
  const [barGraphData, setBarGraphData] = useState<any>({});

  const showDashedRequirement = ({ payload, height, width, ...rest }: any) => {
    // eslint-disable-next-line react/prop-types
    if (payload?.required === null) {
      return (
        <Rectangle
          {...rest}
          width={width}
          height={279}
          fill="#ffffff"
          strokeDasharray="5"
          strokeWidth={1}
          stroke="#98A2B3"
          alwaysShow
        />
      );
    } else return <Rectangle {...rest} width={width} height={height} />;
  };

  const handleBarGraphData = (data: any) => setBarGraphData(data);

  return (
    <div className="chart-body">
      <ResponsiveContainer width="99%" height={310}>
        {!loading ? (
          <BarChart
            data={chartData}
            height={310}
            barGap={8}
            margin={{
              right: 8
            }}>
            <Bar
              dataKey="required"
              fill="#CFEAFF"
              isAnimationActive={true}
              style={{
                boxShadow: '-4px 0px 4px rgba(17, 0, 17, 0.25)'
              }}
              shape={showDashedRequirement}
              radius={[8, 8, 0, 0]}
              onMouseOver={handleBarGraphData}
            />
            <Bar
              dataKey="provided"
              fill=" #90CFFF"
              isAnimationActive={true}
              radius={[8, 8, 0, 0]}
              onMouseOver={handleBarGraphData}
            />
            <Bar
              dataKey="booked"
              fill="#2C74D6"
              isAnimationActive={true}
              radius={[8, 8, 0, 0]}
              onMouseOver={handleBarGraphData}
            />
            <Bar
              dataKey="cancelled"
              fill="#2444A4"
              isAnimationActive={true}
              radius={[8, 8, 0, 0]}
              onMouseOver={handleBarGraphData}
            />
            <XAxis
              dataKey="month"
              style={{
                fontSize: '16px',
                fontFamily: 'Roboto',
                color: '#667085',
                zIndex: 4
              }}
              stroke="#667085"
              strokeWidth={2}
              tickLine={false}
              axisLine={true}
            />
            <Tooltip
              cursor={{ fill: 'transparent' }}
              content={<CustomTooltip />}
              shared={false}
              position={{ x: barGraphData.x, y: barGraphData.y - 150 }}
            />
          </BarChart>
        ) : (
          <div className="graph-shimmer">
            <div className="grid">
              <div className="box first shimmerBG"></div>
              <div className="box second shimmerBG"></div>
              <div className="box third shimmerBG"></div>
              <div className="box fourth shimmerBG"></div>
            </div>
            <div className="grid">
              <div className="box first shimmerBG"></div>
              <div className="box second shimmerBG"></div>
              <div className="box third shimmerBG"></div>
              <div className="box fourth shimmerBG"></div>
            </div>
            <div className="grid">
              <div className="box first shimmerBG"></div>
              <div className="box second shimmerBG"></div>
              <div className="box third shimmerBG"></div>
              <div className="box fourth shimmerBG"></div>
            </div>
          </div>
        )}
      </ResponsiveContainer>
    </div>
  );
}
export default React.memo(SlotChart);
