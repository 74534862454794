import service from './index.service';

export async function searchUser(query: string, abortSignal: any) {
  try {
    const response = await service.get('/admin/settings/user/list', {
      params: { text: query },
      signal: abortSignal
    });
    return response.data;
  } catch (e: any) {
    return { data: null, error: [e] };
  }
}

export async function fetchAdminList(pageNumber = 0, pageSize: number | string = 10) {
  try {
    const response = await service.get(`/admin/list/${pageNumber}/${pageSize}`);
    return response.data;
  } catch (e: any) {
    return { data: null, error: [e] };
  }
}

export async function isGoogleSynced() {
  try {
    const response = await service.get('/preference/get?preferenceTypeEnum=CALENDAR_SYNC');
    return response.data;
  } catch (e: any) {
    return { data: null, error: [e] };
  }
}

export async function googleSyncServcie(param: any) {
  try {
    const response = await service.get('/user/calendar/sync', {
      params: { code: param.code, enabled: param.enabled }
    });
    return response.data;
  } catch (e: any) {
    return { data: null, error: [e] };
  }
}

export async function deleteAdmin(userId: string | number) {
  try {
    const response = await service.delete('/admin/delete', {
      params: { userId: userId }
    });
    return response.data;
  } catch (e: any) {
    return { data: null, error: [e] };
  }
}

export async function postAddAdmin(userId: string[] | number[]) {
  try {
    const response = await service.post('/admin/add', { userIds: userId });
    return response.data;
  } catch (e: any) {
    return { data: null, error: [e] };
  }
}

export async function postUpdateInterviewLimits(
  userId: string | number,
  maxInterviewsWeekly: number | null,
  maxInterviewsDaily: number | null
) {
  try {
    const response = await service.post('/panel/settings/interview-count', {
      userId: userId,
      maxInterviewsWeekly: maxInterviewsWeekly,
      maxInterviewsDaily: maxInterviewsDaily
    });
    return response.data;
  } catch (e: any) {
    return { data: null, error: [e] };
  }
}

export async function postUpdateAdminStack(techStackId: string[] | number[]) {
  try {
    const response = await service.post(
      `/user/update/tech-stack?${techStackId.map((value) => `techStackId=${value}`).join('&')}`
    );
    return response.data;
  } catch (e: any) {
    return { data: null, error: [e] };
  }
}
export async function searchAdminService(searchString: string, signal: any) {
  try {
    const text = encodeURIComponent(searchString);
    const response = await service.get(`/admin/search`, {
      params: {
        searchText: text
      },
      signal
    });
    return response.data;
  } catch (error: any) {
    if (error.code === 'ERR_BAD_REQUEST') {
      return { data: null, error: [{ message: error.message, code: error.code }] };
    } else if (error?.code === 'ERR_NETWORK') {
      return { data: null, error: [{ message: error.message, code: error.code }] };
    }
  }
}

export async function getInterviewLimitsService() {
  try {
    const response = await service.get('/panel/settings/interview-limit');
    return response.data;
  } catch (e: any) {
    return { data: null, error: [e] };
  }
}
