import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ISelectValue } from 'app/core/shared-components/select';
import {
  deleteAdmin,
  fetchAdminList,
  postAddAdmin,
  postUpdateAdminStack,
  searchAdminService,
  searchUser,
  postUpdateInterviewLimits,
  isGoogleSynced,
  googleSyncServcie,
  getInterviewLimitsService
} from 'app/services/setting.service';
import { SETTINGS } from 'app/redux/constant';
import { getAdminInfo } from 'app/redux/slices/userSlice';
import { DEFAULTPAGESIZE } from 'helpers/constants';

export interface IUserList {
  userId: number;
  firstName: string;
  lastName: string;
  emailId: string;
  designation: string;
  employeeId?: string;
  roleSet?: {
    id: number;
    role: string;
    label: string;
  }[];
  mobileNo?: string;
  profileImageUrl: string;
}

export interface IAdminDTO {
  userId: number;
  firstName: string;
  lastName: string;
  emailId: string;
  designation: string;
  experience: number;
  profileImageUrl: string;
}

interface IAdminList {
  pageCount: number;
  totalCount: number;
  userApprovalDTOList: IAdminDTO[];
}

interface ISettingState {
  options: IUserList[];
  adminList: IAdminDTO[];
  isLoading: boolean;
  showToast: number;
  error: boolean;
  deleteToast: boolean;
  searchOptions?: IUserList[];
}

interface ISearchParams {
  searchString: string;
  abortSignal: any;
}

interface IListParams {
  pageNumber?: number;
  pageSize?: number;
}

interface IUpdateInterviewLimits {
  userId: string;
  weeklyLimit: number;
  dailyLimit: number;
}

interface IHandleGoogleSync {
  code: string;
  enabled: boolean;
}

export const search = createAsyncThunk(
  `${SETTINGS}/search`,
  async (params: ISearchParams, thunkAPI) => {
    const { searchString, abortSignal } = params;
    const data = await searchUser(searchString, abortSignal.signal);
    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);
export const getSearchedData = createAsyncThunk('search', async (params: any, thunkAPI) => {
  const { searchString, abortSignal } = params;
  const data = await searchAdminService(searchString, abortSignal?.signal);
  if (!data.error) {
    return data;
  } else {
    if (data.error[0].code === 'ERR_BAD_REQUEST') {
      return { data: null, error: [{ message: data.error.message, code: data.error.code }] };
    } else if (data?.error[0]?.code === 'ERR_NETWORK') {
      return thunkAPI.rejectWithValue(data);
    } else {
      return data;
    }
  }
});

export const getAdminList = createAsyncThunk(
  `${SETTINGS}/adminList`,
  async (params: IListParams, thunkAPI) => {
    const { pageNumber = 0, pageSize = DEFAULTPAGESIZE * 50 } = params;
    const data = await fetchAdminList(pageNumber, pageSize);

    if (!data.error) {
      return data.data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const removeAdmin = createAsyncThunk(
  `${SETTINGS}/removeAdmin`,
  async (params: string | number, thunkAPI) => {
    thunkAPI.dispatch(toggleLoading());
    const data = await deleteAdmin(params);
    thunkAPI.dispatch(toggleLoading());
    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const addAdmin = createAsyncThunk(
  `${SETTINGS}/addAdmin`,
  async (params: string[] | number[], thunkAPI) => {
    thunkAPI.dispatch(toggleLoading());
    const data = await postAddAdmin(params);
    thunkAPI.dispatch(toggleLoading());
    if (!data.error) {
      return params;
    }
    return thunkAPI.rejectWithValue(params);
  }
);

export const updateInterviewLimits = createAsyncThunk(
  `${SETTINGS}/updateInterviewLimits`,
  async (params: IUpdateInterviewLimits, thunkAPI) => {
    thunkAPI.dispatch(toggleLoading());
    const data = await postUpdateInterviewLimits(
      params.userId,
      params.weeklyLimit,
      params.dailyLimit
    );
    thunkAPI.dispatch(toggleLoading());
    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const checkIsGoogleSynced = createAsyncThunk(
  `${SETTINGS}/checkIsGoogleSynced`,
  async (undefined, thunkAPI) => {
    const data = await isGoogleSynced();
    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const getUserInterviewLimits = createAsyncThunk(
  `${SETTINGS}/userInterviewLimits`,
  async (undefined, thunkAPI) => {
    const data = await getInterviewLimitsService();
    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const handleGoogleSync = createAsyncThunk(
  `${SETTINGS}/googleSync`,
  async (param: IHandleGoogleSync, thunkAPI) => {
    const data = await googleSyncServcie(param);
    if (!data.error) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  }
);

export const updateAdminStack = createAsyncThunk(
  `${SETTINGS}/editAdminStack`,
  async (params: ISelectValue[], thunkAPI) => {
    thunkAPI.dispatch(toggleLoading());
    const data = await postUpdateAdminStack(params.map((value) => value.id.toString()));
    if (!data.error) {
      await thunkAPI.dispatch(getAdminInfo());
      thunkAPI.dispatch(toggleLoading());
      return params;
    }
    thunkAPI.dispatch(toggleLoading());
    return thunkAPI.rejectWithValue(params);
  }
);

const initialState: ISettingState = {
  options: [],
  adminList: [],
  isLoading: false,
  showToast: 0,
  error: false,
  deleteToast: false,
  searchOptions: []
};

export const settingSlice = createSlice({
  name: SETTINGS,
  initialState,
  reducers: {
    toggleLoading: (state) => {
      state.isLoading = !state.isLoading;
    },
    resetToastCount: (state) => {
      state.showToast = 0;
      state.deleteToast = false;
    },
    resetPageState: (state) => {
      state.showToast = 0;
      state.error = false;
      state.options = [];
    }
  },
  extraReducers(builder) {
    builder.addCase(getSearchedData.pending, (state, { payload }) => {
      state.searchOptions = [];
    });
    builder.addCase(getSearchedData.fulfilled, (state, { payload }) => {
      state.searchOptions = payload.data;
    });
    builder.addCase(getSearchedData.rejected, (state, { payload }) => {
      state.error = true;
    });
    builder.addCase(search.fulfilled, (state, action) => {
      state.options = action.payload.data;
    });
    builder.addCase(search.rejected, (state) => {
      state.options = [];
    });
    builder.addCase(getAdminList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAdminList.fulfilled, (state, action) => {
      state.adminList = action.payload.userApprovalDTOList;
      state.isLoading = false;
    });
    builder.addCase(getAdminList.rejected, (state) => {
      state.error = true;
    });
    builder.addCase(addAdmin.rejected, (state) => {
      state.error = true;
    });
    builder.addCase(removeAdmin.fulfilled, (state) => {
      state.deleteToast = true;
    });
    builder.addCase(removeAdmin.rejected, (state) => {
      state.error = true;
    });
    builder.addCase(addAdmin.fulfilled, (state, action) => {
      state.showToast = action.payload.length;
    });
    builder.addCase(updateAdminStack.rejected, (state, action) => {
      state.error = true;
    });
    builder.addCase(updateInterviewLimits.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateInterviewLimits.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateInterviewLimits.rejected, (state) => {
      state.error = true;
    });
    builder.addCase(checkIsGoogleSynced.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(checkIsGoogleSynced.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(checkIsGoogleSynced.rejected, (state) => {
      state.error = true;
    });
    builder.addCase(handleGoogleSync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(handleGoogleSync.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(handleGoogleSync.rejected, (state) => {
      state.error = true;
    });
    builder.addCase(getUserInterviewLimits.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserInterviewLimits.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getUserInterviewLimits.rejected, (state) => {
      state.error = true;
    });
  }
});

export const { toggleLoading, resetToastCount, resetPageState } = settingSlice.actions;

export default settingSlice.reducer;
