import Images from '../../../../../assets/images';

const {
  tickIcon,
  projectBlueIcon,
  techStackBlueIcon,
  levelBlueIcon,
  calendarSyncIcon,
  slotBookedIcon,
  slotAddedIcon,
  slotCancelledIcon,
  topEarnerIcon,
  adminIcon,
  plusPrimary,
  slotRequestAcceptedIcon,
  techStackLevelIcon
} = Images;

export const NOTIFICATION_ICON_MAP: { [key: string]: string } = {
  PROJECT: projectBlueIcon,
  PROJECT_UNASSIGNED: projectBlueIcon,
  PROJECT_ASSIGNED: projectBlueIcon,
  TECH_STACK: techStackBlueIcon,
  TECH_STACK_ASSIGNED: techStackBlueIcon,
  TECH_STACK_UNASSIGNED: techStackBlueIcon,
  INTERVIEW_LEVEL: levelBlueIcon,
  ADMIN: adminIcon,
  SLOT_ADDED: slotAddedIcon,
  SLOT_BOOKED: slotBookedIcon,
  SLOT_CANCELLED: slotCancelledIcon,
  CALENDAR_SYNC: calendarSyncIcon,
  REWARDS: topEarnerIcon,
  SLOT_REQUIREMENT_ADDED: plusPrimary,
  SLOT_REQUIREMENT_FULFILLED: tickIcon,
  SLOT_REQUEST_ACCEPTED: slotRequestAcceptedIcon,
  TECH_STACK_LEVEL_ASSIGNED: techStackLevelIcon,
  TECH_STACK_LEVEL_UNASSIGNED: techStackLevelIcon
};
