import React, { Component } from 'react';
import './index.style.scss';
import { ReactComponent as LoaderSpinner } from '../../../../assets/images/loader-spinner.svg';

interface ILoadingProps {
  loading: boolean;
  customClass?: string;
  customClassIcon?: string;
}

class Loader extends Component<ILoadingProps, {}> {
  render() {
    const { loading, customClass, customClassIcon = null } = this.props;
    if (!loading) return null;
    return (
      <div className={`${customClass} loading-wrapper`}>
        <LoaderSpinner className={` loading-spinner ${customClassIcon}`} />
      </div>
    );
  }
}

export default Loader;
