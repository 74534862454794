import React, { Fragment, useEffect, useState } from 'react';
import { Column } from 'react-table';
import { v4 as uuidv4 } from 'uuid';
import {
  NO_SLOTS_AVAILABLE,
  NO_SLOTS_BOOKED,
  NO_SLOTS_CANCELLED,
  NO_SLOTS_FOUND,
  NO_SLOTS_PROVIDED,
  PANEL_APPROVAL,
  SLOTS_OVERAPPING_MESSAGE,
  SLOT_ADDED_TOAST_MESSAGE
} from 'helpers/messages.constants';
import Images from 'assets/images';
import Date from 'assets/images/date.svg';
import { ReactComponent as LoaderSpinner } from 'assets/images/loader-spinner.svg';
import NoRequestedSlot from 'assets/images/no-result-found.svg';
import Plus from 'assets/images/plus.svg';
import { theme } from 'assets/styles/theme';
import {
  ALL,
  ARCHIVED,
  AVAILABLE,
  BOOKED,
  CANCEL,
  CANCELLED,
  DAILY,
  DEFAULT_DAILY_INTERVIEW_LIMIT,
  DEFAULT_WEEKLY_INTERVIEW_LIMIT,
  EMAIL,
  MAX_INTERVIEWS,
  PROJECT_LABEL,
  REVIEW,
  STATUSENUM_APPROVED,
  STATUSENUM_IN_REVIEW,
  TECH_STACK,
  WEEKLY
} from 'helpers/constants';
import { notify } from 'helpers/toastHelper';
import SlotCarousel from 'app/pages/Slots/components/slot-carousel';
import SlotPicker from 'app/pages/Slots/components/slot-picker';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  fetchAllSlots,
  fetchAvailableSlots,
  fetchBookedSlots,
  fetchCancelledSlots,
  setFilterValue,
  setFilteredDateValue,
  slotCountForSpecificUser,
  toggleRequestFlag,
  toggleToastVisibility
} from 'app/redux/slices/slotsSlice';
import { IAddSlotBody, ISlotFilterDTOType } from 'app/services/slots.service';
import AddSlotForm from '../add-slot-form';
import Button from '../button';
import ButtonGroup, { ISelectedSearchProps } from '../button-group';
import ClickAwayListener from '../click-away';
import { ISelectOptions } from '../customSelect';
import ImageComponent from '../image';
import Loader from '../loader';
import Table from '../table';
import Typography from '../typography';
import './index.style.scss';
import ProjectsDetails from './projectsDetails';
import TechStackLOI from './textStackLOI';
import { ISlotInfoType, ISlotsStatusTypes } from 'helpers/types';

interface ISlotSearchResultStates {
  dateFilter: ISlotFilterDTOType[];
  showDateFilter: boolean;
  techStackFilter: ISelectOptions;
  slotCategory: ISlotsStatusTypes;
  borderColor: string;
  isAddSlotDrawerOpen: boolean;
  isAddSlotDropdownOpen: boolean;
  showAddSlotCards: boolean;
  activeIndex: number;
  noResultMessage: string;
  toastIcon: string;
  isDashboard: boolean;
}

export interface ISlotsSearchResultProps {
  selectedSearch: ISelectedSearchProps;
  isDashboard?: boolean;
}

function SlotsSearchResult(props: ISlotsSearchResultProps) {
  const [slotSearchPageState, setSlotSearchPageState] = useState<ISlotSearchResultStates>({
    dateFilter: [],
    showDateFilter: false,
    techStackFilter: {} as ISelectOptions,
    slotCategory: AVAILABLE,
    borderColor: theme.primary,
    isAddSlotDrawerOpen: false,
    isAddSlotDropdownOpen: false,
    showAddSlotCards: false,
    activeIndex: 0,
    noResultMessage: NO_SLOTS_PROVIDED,
    toastIcon: Images?.rightTick,
    isDashboard: false
  });

  const dispatch = useAppThunkDispatch();

  const {
    filteredDateData,
    currentSlots,
    isLoading,
    filterValue,
    selectedSearch: selectedSearchResult,
    showRequestFlag,
    slotCountData
  } = useAppSelector((state) => state?.slots);

  const {
    dateFilter,
    showDateFilter,
    techStackFilter,
    slotCategory,
    borderColor,
    isAddSlotDrawerOpen,
    activeIndex,
    noResultMessage
  } = slotSearchPageState;

  const { selectedSearch } = props;

  const isApproved = selectedSearch?.statusEnum === STATUSENUM_APPROVED;

  const techStackInterviewLevelSetTagLimit = 2;
  const projectTagLimit = 2;
  const interviewSlotList = currentSlots?.data?.interviewSlotDateDTOList;

  const slotInfo: ISlotInfoType[] = [
    { type: AVAILABLE, color: theme.primary },
    { type: BOOKED, color: theme.success },
    { type: CANCELLED, color: theme.error },
    { type: ALL, color: '' }
  ];

  const columns: Column<any>[] = [
    {
      Header: (
        <div className="panel-header-cell" key="name">
          Name
        </div>
      ),
      accessor: 'firstName',
      Cell: (item) => {
        return (
          <div className="row-cell" key={PROJECT_LABEL}>
            <div className="cell-profile">
              <div className="image">
                <ImageComponent
                  src={item?.row?.original?.profileImageUrl}
                  customClass="cell-profile-image"
                  fallbackClass="cell-profile-image"
                  fallbackText={`${item?.row?.original?.firstName}`}
                />
              </div>
              <div className="cell-profile-data">
                <Typography variant="text" customStyle="cell-name">
                  {`${item?.row?.original?.firstName} ${item?.row?.original?.lastName}`}
                  {!isApproved && (
                    <div
                      className={
                        selectedSearch['statusEnum'] &&
                        selectedSearch['statusEnum'].toString().toLowerCase()
                      }>
                      <div className="circle"></div>
                      {selectedSearch?.statusEnum === STATUSENUM_IN_REVIEW ? REVIEW : ARCHIVED}
                    </div>
                  )}
                </Typography>
                <Typography variant="text" customStyle="cell-designation">
                  {currentSlots?.data?.userDetails?.designation}
                </Typography>
                <Typography variant="text" customStyle="cell-designation">
                  {currentSlots?.data?.userDetails?.email}
                </Typography>
              </div>
            </div>
          </div>
        );
      }
    },
    {
      Header: (
        <div className="panel-header-cell" key="tech-level">
          Tech Stack - Level of Interview
        </div>
      ),
      accessor: 'techStackInterviewLevelSet',
      Cell: (item) => {
        return (
          <div className="row-cell-tech-level" key={EMAIL}>
            <div className="tech-level">
              <TechStackLOI
                techStackInterviewLevelSet={item?.row?.original?.techStackInterviewLevelSet}
              />
              {item?.row?.original?.techStackInterviewLevelSet?.length >
                techStackInterviewLevelSetTagLimit && (
                <div
                  className="single-tag "
                  key={item?.row?.original?.techStackInterviewLevelSet?.length}>
                  {`+${
                    item?.row?.original?.techStackInterviewLevelSet?.length -
                    techStackInterviewLevelSetTagLimit
                  }`}
                </div>
              )}
            </div>
          </div>
        );
      }
    },
    {
      Header: (
        <div className="panel-header-cell" key="project">
          Project
        </div>
      ),
      accessor: 'projects',
      Cell: (item) => {
        return (
          <div className="row-cell-tech-level" key={TECH_STACK}>
            <div className="tech-level">
              <ProjectsDetails projects={item?.row?.original?.projects} />
              {item?.row?.original?.projects?.length > projectTagLimit && (
                <div className="single-tag project" key={item?.row?.original?.projects?.length}>
                  {`+${currentSlots?.data?.userDetails?.projects?.length - projectTagLimit}`}
                </div>
              )}
              {!item?.row?.original?.projects?.length && (
                <div
                  className="single-tag bench"
                  key={currentSlots?.data?.userDetails?.projects?.length}>
                  On Bench
                </div>
              )}
            </div>
          </div>
        );
      }
    },
    {
      Header: (
        <div className="panel-header-cell" key="max-interviews">
          {MAX_INTERVIEWS}
        </div>
      ),
      accessor: 'interviewCountDetail',
      Cell: (item) => {
        return (
          <div className="row-cell-tech-level" key={DEFAULT_WEEKLY_INTERVIEW_LIMIT}>
            <div className="interviews" key={DEFAULT_DAILY_INTERVIEW_LIMIT}>
              <div className="label">
                {WEEKLY}:
                <span>
                  {item?.row?.original?.interviewCountDetail?.weeklyInterviewCount ||
                    DEFAULT_WEEKLY_INTERVIEW_LIMIT}
                </span>
              </div>
              <div className="label">
                {DAILY}:
                <span>
                  {item?.row?.original?.interviewCountDetail?.dailyInterviewCount ||
                    DEFAULT_DAILY_INTERVIEW_LIMIT}
                </span>
              </div>
            </div>
          </div>
        );
      }
    }
  ];

  const techStackLevel = currentSlots?.data?.userDetails?.techStackInterviewLevelSet?.map(
    (techStack) => ({
      id: techStack?.techStackId,
      label: techStack?.techStack
    })
  );

  const techStackLevelSet = techStackLevel?.filter(
    (techStack, index) =>
      techStackLevel?.findIndex((techData) => techStack?.id === techData?.id) === index
  );

  const handleDateFilter = (filterValue: ISlotFilterDTOType[]) => {
    setSlotSearchPageState((prevState) => ({
      ...prevState,
      dateFilter: filterValue
    }));
    dispatch(
      slotCountForSpecificUser({
        userId: selectedSearch.id,
        interviewStatus:
          slotCategory === AVAILABLE || slotCategory === BOOKED || slotCategory === ALL
            ? slotCategory.toUpperCase()
            : CANCEL.toUpperCase(),
        dateTime: filterValue ? filterValue : []
      })
    );
  };

  const closeDateFilter = () => {
    if (showDateFilter) {
      setSlotSearchPageState((prevState) => ({
        ...prevState,
        showDateFilter: false
      }));
    }
  };

  const toggleDateFilter = () => {
    setSlotSearchPageState((prevState) => ({
      ...prevState,
      showDateFilter: !showDateFilter
    }));
  };

  function handleButtonGroupClick(position: number) {
    setSlotSearchPageState((prevState) => ({
      ...prevState,
      activeIndex: position
    }));
    switch (position) {
      case 0:
        dispatch(
          fetchAvailableSlots({
            userId: selectedSearch.id,
            dateTime: dateFilter
          })
        );
        setSlotSearchPageState((prevState) => ({
          ...prevState,
          noResultMessage: NO_SLOTS_PROVIDED
        }));
        break;
      case 1:
        dispatch(
          fetchBookedSlots({
            userId: selectedSearch.id,
            dateTime: dateFilter
          })
        );
        setSlotSearchPageState((prevState) => ({
          ...prevState,
          noResultMessage: NO_SLOTS_BOOKED
        }));
        break;
      case 2:
        dispatch(
          fetchCancelledSlots({
            userId: selectedSearch.id,
            dateTime: dateFilter
          })
        );
        setSlotSearchPageState((prevState) => ({
          ...prevState,
          noResultMessage: NO_SLOTS_CANCELLED
        }));
        break;
      case 3:
        dispatch(
          fetchAllSlots({
            userId: selectedSearch.id,
            dateTime: dateFilter
          })
        );
        setSlotSearchPageState((prevState) => ({
          ...prevState,
          noResultMessage: NO_SLOTS_AVAILABLE
        }));
        break;
    }
    setSlotSearchPageState((prevState) => ({
      ...prevState,
      borderColor: slotInfo[position]?.color,
      slotCategory: slotInfo[position]?.type
    }));
  }

  const handleAddSlotDrawer = () => {
    setSlotSearchPageState((prevState) => ({
      ...prevState,
      isAddSlotDrawerOpen: !isAddSlotDrawerOpen,
      isAddSlotDropdownOpen: false,
      showAddSlotCards: false
    }));
  };

  const handleCloseToast = () => {
    dispatch(toggleToastVisibility(''));
  };

  const handleAddSlot = (_val: IAddSlotBody, errorMessage: any) => {
    if (errorMessage?.error) {
      notify(true, SLOTS_OVERAPPING_MESSAGE, uuidv4().toString(), handleCloseToast, 'error');
    } else {
      notify(true, SLOT_ADDED_TOAST_MESSAGE, uuidv4().toString(), handleCloseToast);
    }
  };

  const setDateFilter = () => {
    if (techStackFilter?.id) {
      switch (slotCategory) {
        case AVAILABLE:
          dispatch(
            fetchAvailableSlots({
              userId: selectedSearch.id,
              dateTime: dateFilter
            })
          );
          setSlotSearchPageState((prevState) => ({
            ...prevState,
            noResultMessage: NO_SLOTS_PROVIDED
          }));
          break;
        case BOOKED:
          dispatch(
            fetchBookedSlots({
              userId: selectedSearch.id,
              dateTime: dateFilter
            })
          );
          setSlotSearchPageState((prevState) => ({
            ...prevState,
            noResultMessage: NO_SLOTS_BOOKED
          }));
          break;
        case CANCELLED:
          dispatch(
            fetchCancelledSlots({
              userId: selectedSearch.id,
              dateTime: dateFilter
            })
          );
          setSlotSearchPageState((prevState) => ({
            ...prevState,
            noResultMessage: NO_SLOTS_CANCELLED
          }));
          break;
        case ALL:
          dispatch(
            fetchAllSlots({
              userId: selectedSearch.id,
              dateTime: dateFilter
            })
          );
          setSlotSearchPageState((prevState) => ({
            ...prevState,
            noResultMessage: NO_SLOTS_AVAILABLE
          }));
          break;
      }
      dispatch(
        setFilterValue({
          userId: selectedSearch.id,
          techStackId: parseInt(techStackFilter?.id.toString()),
          dateTime: dateFilter
        })
      );
    }
  };

  const setCurrentSlots = () => {
    dispatch(
      slotCountForSpecificUser({
        userId: selectedSearch.id,
        interviewStatus:
          slotCategory === AVAILABLE || slotCategory === BOOKED
            ? slotCategory.toUpperCase()
            : CANCEL.toUpperCase(),
        dateTime: dateFilter ? dateFilter : []
      })
    );
  };

  const setPageStateAccordingToUser = () => {
    if (currentSlots?.data?.userDetails && techStackLevel?.length) {
      const [firstTechStackLevelSet, ...rest] = techStackLevelSet;
      setSlotSearchPageState((prevState) => ({
        ...prevState,
        techStackFilter: firstTechStackLevelSet,
        activeIndex: 0,
        slotCategory: AVAILABLE,
        borderColor: theme.primary
      }));
    }
    dispatch(
      setFilteredDateValue({
        mode: 'Custom',
        dates: null,
        slotTime: { startTime: null, endTime: null }
      })
    );
    dispatch(
      setFilterValue({
        ...filterValue,
        dateTime: []
      })
    );
  };

  useEffect(() => {
    dispatch(toggleRequestFlag(false));
  }, []);

  useEffect(() => {
    setDateFilter();
  }, [dateFilter]);

  useEffect(() => {
    setCurrentSlots();
  }, [currentSlots]);

  useEffect(() => {
    setPageStateAccordingToUser();
  }, [currentSlots?.data?.userDetails?.id]);

  return (
    <div className="slots--search-result">
      {!!currentSlots?.data?.userDetails && (
        <Table
          columnsDefinition={columns}
          rowsDefinition={[currentSlots?.data?.userDetails] || []}
          isCheckEnabled={false}
        />
      )}
      <div className="slots-body">
        {isApproved && (
          <div className="slots-body-header">
            <ButtonGroup
              selectedSearch={selectedSearch}
              orientation="horizontal"
              tabValue={activeIndex}
              customStyle="slots-button-group"
              activeButtonClick={handleButtonGroupClick}
              showSelectedButton={true}
              options={[
                {
                  size: 'medium',
                  variant: 'contained',
                  label: AVAILABLE,
                  count: slotCountData?.statusCount?.available
                },
                {
                  size: 'medium',
                  variant: 'contained',
                  label: BOOKED,
                  count: slotCountData?.statusCount?.booked
                },
                {
                  size: 'medium',
                  variant: 'contained',
                  label: CANCELLED,
                  count: slotCountData?.statusCount?.cancelled
                },
                {
                  size: 'small',
                  variant: 'contained',
                  label: ALL,
                  count: slotCountData?.statusCount?.allSlotCount
                }
              ]}></ButtonGroup>
            <div className="slot-filters">
              <div className="date-filter">
                <ClickAwayListener handleClose={closeDateFilter}>
                  <Button
                    startIcon={Date}
                    onClick={toggleDateFilter}
                    customStyle="date-btn"></Button>
                  {showDateFilter && (
                    <div className="slot-date-filter-popover">
                      <SlotPicker
                        isSearched={true}
                        onClose={toggleDateFilter}
                        onSubmit={handleDateFilter}
                        slotCategory={slotCategory}
                      />
                    </div>
                  )}
                  {filteredDateData?.dates && <div className="slots-filter-badge"></div>}
                </ClickAwayListener>
              </div>

              <Button
                customStyle="add-slot-btn"
                variant="contained"
                startIcon={Plus}
                onClick={handleAddSlotDrawer}>
                Add Slot
              </Button>
            </div>
          </div>
        )}

        <div
          className={interviewSlotList === null || !isApproved ? 'slots-tabs empty' : 'slots-tabs'}>
          <div className="slot-details" id="carousel">
            {isLoading ? (
              <div className="slot-loading-wrapper">
                <LoaderSpinner className="loading-spinner" />
              </div>
            ) : (
              <Fragment>
                {slotCategory === AVAILABLE &&
                  interviewSlotList !== null &&
                  !isLoading &&
                  isApproved &&
                  showRequestFlag && (
                    <div className="request-flag">
                      <div className="yellow-circle"></div>
                      <Typography variant="subtitle-16">Requested Slots</Typography>
                    </div>
                  )}

                {!isApproved ? (
                  <div className="no-slots-available">
                    <ImageComponent src={NoRequestedSlot} />
                    <Typography variant="subtitle-18">{PANEL_APPROVAL}</Typography>
                  </div>
                ) : interviewSlotList !== null ? (
                  interviewSlotList?.map((slotList, index) => (
                    <SlotCarousel
                      key={index}
                      dateFilter={dateFilter}
                      isSearch={true}
                      techStackId={techStackFilter?.id?.toString()}
                      noOfSlots={slotList?.noOfSlots}
                      day={slotList?.date}
                      slots={slotList?.interviewSlotTimeDTOList}
                      slotType={slotCategory}
                      borderColor={borderColor}
                      selectedUser={selectedSearch}
                    />
                  ))
                ) : (
                  <div className="no-slots-available">
                    <ImageComponent src={NoRequestedSlot} />
                    <Typography variant="headline-24">{NO_SLOTS_FOUND}</Typography>
                    <Typography variant="headline-16" customStyle="no-slot-message">
                      {noResultMessage}
                    </Typography>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
      <AddSlotForm
        isDrawerOpen={isAddSlotDrawerOpen}
        toggleDrawer={handleAddSlotDrawer}
        userId={selectedSearch.id}
        dateFilter={dateFilter}
        slotCategory={slotCategory}
        onAddSlot={handleAddSlot}
        techStackFilter={techStackFilter}
      />
      <Loader loading={selectedSearchResult === null && isLoading} />
    </div>
  );
}

export default SlotsSearchResult;
