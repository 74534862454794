import React, { useCallback, useState } from 'react';
import './index.style.scss';
import Images from '../../../../../assets/images';
import ImageComponent from '../../../../core/shared-components/image';
import Typography from '../../../../core/shared-components/typography';
import moment, { Moment } from 'moment';

interface ISlotAvailablePopupProps {
  start: Date | null;
  end: Date | null;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  onCloseClick?: () => void;
}

interface ISlotAvailablePopupStates {
  startDate: Moment;
  endDate: Moment;
}

function SlotAvailablePopup(props: ISlotAvailablePopupProps) {
  const { start, end, onEditClick, onDeleteClick, onCloseClick } = props;
  const [slotState, setSlotState] = useState<ISlotAvailablePopupStates>({
    startDate: moment(start),
    endDate: moment(end)
  });
  const { startDate, endDate } = slotState;

  const handleEditClick = useCallback(() => {
    const { endDate } = slotState;
    if (endDate.isSameOrAfter(moment())) {
      onEditClick && onEditClick();
    }
  }, [slotState.endDate]);

  const handleDeleteClick = useCallback(() => {
    if (endDate.isSameOrAfter(moment())) {
      onDeleteClick && onDeleteClick();
    }
  }, [slotState.endDate]);

  return (
    <div className="slot-available-wrapper">
      <div className="slot-available-header">
        <Typography customStyle="slot-available-title">Available Slot</Typography>
        <div className="slot-available-icon-wrapper">
          {endDate.isSameOrAfter(moment()) && (
            <div className="icon-wrapper" onClick={handleEditClick}>
              <ImageComponent src={Images.edit} />
            </div>
          )}
          {endDate.isSameOrAfter(moment()) && (
            <div className="icon-wrapper" onClick={handleDeleteClick}>
              <ImageComponent src={Images.delete} />
            </div>
          )}
          <div className="icon-wrapper" onClick={onCloseClick}>
            <ImageComponent src={Images.close} />
          </div>
        </div>
      </div>
      <div className="slot-available-content">
        <Typography customStyle="slot-timing">{`${startDate.format('hh:mm A')} - ${endDate.format(
          'hh:mm A'
        )}`}</Typography>
        <Typography customStyle="slot-dates">{`${startDate.format(
          'dddd, DD MMMM YYYY'
        )}`}</Typography>
      </div>
    </div>
  );
}

export default SlotAvailablePopup;
