import React from 'react';
import { ReactComponent as LoaderSpinner } from '../../../../../assets/images/loader-spinner.svg';
import './index.style.scss';

interface IComponentLoader {
  customStyle?: string;
}

const ComponentLoader = ({ customStyle }: IComponentLoader) => {
  return (
    <div className={customStyle || 'component-loading-wrapper'}>
      <LoaderSpinner className="loading-spinner" />
    </div>
  );
};
export default ComponentLoader;
