import { ISelectOptions } from 'app/core/shared-components/customSelect';
import { StylesConfig } from 'react-select';
import { theme } from 'assets/styles/theme';

export const techLevelStyles: StylesConfig<ISelectOptions> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    backgroundColor: state.menuIsOpen ? theme.primaryLight : 'inherit',
    borderBottom: state.menuIsOpen ? theme.primaryBorder : 'none',
    borderRadius: '0.5rem 0.5rem 0 0',
    alignItems: 'center',
    boxShadow: 'none',
    cursor: 'pointer',
    minWidth: 120,
    maxWidth: 150,
    '&:hover': {
      border: 'none',
      borderBottom: state.menuIsOpen ? theme.primaryBorder : 'none'
    }
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    backgroundColor: 'inherit',
    borderRadius: '0.5rem 0.5rem 0 0',
    alignItems: 'center',
    padding: '0.75rem 0.25rem 0.75rem 1rem',
    width: 114.2
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    backgroundColor: 'inherit'
  }),
  indicatorsContainer: (baseStyles, state) => ({
    ...baseStyles,
    padding: '0 0.125rem 0 0'
  }),
  input: (baseStyles, state) => ({
    ...baseStyles
  })
};

export const normalStyle: StylesConfig<ISelectOptions> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    boxSizing: 'border-box',
    border: theme.checkboxBorder,
    borderRadius: '0.5rem',
    alignItems: 'center',
    boxShadow: 'none',
    cursor: 'pointer',
    minWidth: 120,
    maxWidth: 250,
    '&:hover': {
      border: theme.checkboxBorder
    }
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    borderRadius: '0.5rem 0.5rem 0 0',
    alignItems: 'center',
    padding: '0.6rem 0 0.6rem 0.75rem',
    maxWidth: '9rem',
    minWidth: '9rem'
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    backgroundColor: 'inherit'
  }),
  indicatorsContainer: (baseStyles, state) => ({
    ...baseStyles,
    padding: '0 0.125rem 0 0'
  }),
  input: (baseStyles, state) => ({
    ...baseStyles
  })
};
