import React, { SyntheticEvent, useCallback, useEffect, useState, useRef, Fragment } from 'react';
import { Column } from 'react-table';
import './index.style.scss';
import Typography from 'app/core/shared-components/typography';
import TableName from '../table-name';
import TableEmail from '../table-email';
import Button from 'app/core/shared-components/button';
import Autocomplete from '../auto-complete';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import { ISelectValue } from 'app/core/shared-components/select';
import { IAdminDTO, addAdmin, getAdminList, getSearchedData } from 'app/redux/slices/settingSlice';
import Images from 'assets/images';
import Input from 'app/core/shared-components/input-box';
import Tag from 'app/core/shared-components/tag';
import ImageComponent from 'app/core/shared-components/image';
import { notify } from 'helpers/toastHelper';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';
import Loader from 'app/core/shared-components/loader';
import Table from 'app/core/shared-components/table';
import { setSelectedNotificationData } from 'app/redux/slices/sharedSlice';
import { NO_USERS_FOUND, USER_SEARCHED_DOES_NOT_EXIST } from 'helpers/messages.constants';
import { PULSE_ANIMATION_TIMEOUT } from 'helpers/constants';

interface IUserTeamState {
  rowDefinition: IAdminDTO[];
  selectedUsers: ISelectValue[];
  showModal: boolean;
  searchText: string;
  searchLoading: boolean;
}

function UserTeam() {
  const [teamState, setTeamState] = useState<IUserTeamState>({
    rowDefinition: [],
    selectedUsers: [],
    showModal: false,
    searchText: '',
    searchLoading: false
  });
  const reduxState = useAppSelector((state) => state.settings);
  const { selectedNotificationData } = useAppSelector((state) => state.shared);
  const abortSignal = useRef<any>(null);
  const debounceRef = useRef<any>(null);
  const dispatch = useAppThunkDispatch();

  const haveAdminInView = () => {
    if (selectedNotificationData?.id) {
      const element = document.getElementById(selectedNotificationData?.id);
      element?.scrollIntoView({ behavior: 'auto', block: 'center' });
      setTimeout(() => {
        dispatch(setSelectedNotificationData({ id: '' }));
      }, PULSE_ANIMATION_TIMEOUT);
    }
  };

  const columnDefinitions: Column<any>[] = [
    {
      Header: (
        <div key="entries" className="table-header-cell">
          Name
        </div>
      ),
      accessor: 'firstName',
      Cell: (item) => {
        return (
          <div key={item?.row?.original?.userId} className={`td-decoration-admin`}>
            <TableName
              userImage={item?.row?.original?.profileImageUrl}
              userName={`${item?.row?.original?.firstName} ${item?.row?.original?.lastName}`}
              userDesignation={item?.row?.original?.designation}
            />
          </div>
        );
      }
    },
    {
      Header: (
        <div key="details" className="table-header-cell">
          Email Address
        </div>
      ),
      accessor: 'emailId',
      Cell: (item) => {
        return (
          <div key={item?.row?.original?.emailId} className="td-decoration-admin">
            <TableEmail
              userId={item?.row?.original?.userId}
              userEmail={item?.row?.original?.emailId}
            />
          </div>
        );
      }
    }
  ];

  const handleChange = async (params: ISelectValue[]) => {
    setTeamState((prevState) => ({ ...prevState, selectedUsers: [...params] }));
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.stopPropagation();
    const { selectedUsers } = teamState;
    if (selectedUsers.length) {
      const data = selectedUsers.map((item) => item.id.toString());
      await dispatch(addAdmin(data));
      notify(
        true,
        `Successfully sent invites to ${selectedUsers.length} people`,
        uuidv4().toString(),
        handleToastClose
      );
      setTeamState((prevState) => ({ ...prevState, selectedUsers: [] }));
      await dispatch(getAdminList({}));
    }
  };

  const handleToastClose = () => {};

  const debounceSearch = async (searchString: string) => {
    if (abortSignal.current) abortSignal.current.abort();
    abortSignal.current = new AbortController();
    const signal = abortSignal.current.signal;
    setTeamState((prevState) => ({ ...prevState }));
    const list = await dispatch(getSearchedData({ searchString, signal })).unwrap();

    setTeamState((prevState) => ({
      ...prevState,
      rowDefinition: list.data,
      searchLoading: false
    }));
  };

  const handleSearch = async (text: string | number) => {
    const { adminList } = reduxState;
    const searchString = text.toString().trim();
    setTeamState((prevState) => ({
      ...prevState,
      searchText: text.toString(),
      searchLoading: true
    }));
    if (searchString.length > 0) {
      if (debounceRef.current) debounceRef.current.cancel();
      debounceRef.current = debounce(debounceSearch, 2000);
      debounceRef.current(text.toString());
      return;
    } else {
      setTeamState((prevState) => ({
        ...prevState,
        rowDefinition: adminList,
        searchText: '',
        searchLoading: false
      }));
    }
  };

  const handleClearText = () => {
    const { adminList } = reduxState;

    setTeamState((prevState) => ({ ...prevState, rowDefinition: adminList, searchText: '' }));
  };

  useEffect(() => {
    haveAdminInView();
  }, [teamState.rowDefinition]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedNotificationData({ id: '' }));
    };
  }, []);

  useEffect(() => {
    const { adminList } = reduxState;
    setTeamState((prevState) => ({ ...prevState, rowDefinition: adminList }));
  }, [reduxState.adminList]);

  return (
    <div className="user-team-wrapper">
      <div className="user-team-header">
        <div className="user-team-header-admin-wrapper">
          <div className="user-team-header-admin-label">
            <Typography customStyle="user-team-text">Admins</Typography>
          </div>
          <Tag label={`${reduxState.adminList.length}`} customClass="user-team-admin-count-label" />
        </div>
        <Input
          value={teamState.searchText}
          startIcon={Images.search}
          customClass="user-team-header-search"
          endIcon={teamState.searchText.length ? Images.close : ''}
          placeholder="Search for Admin"
          handleChange={handleSearch}
          onEndIconClick={handleClearText}
        />
      </div>
      {teamState.rowDefinition.length ? (
        <div className="user-team-table-wrapper">
          <Table
            isCheckEnabled={false}
            columnsDefinition={columnDefinitions}
            rowsDefinition={teamState.rowDefinition}
            noHeaderBorder
            idKey="userId"
            selectedId={selectedNotificationData?.id}
          />
          <Loader loading={teamState.searchLoading} customClass="user-team-loading" />
        </div>
      ) : (
        <div className="user-team-search-no-result">
          <ImageComponent
            src={Images.noResult}
            alt="No Results"
            customClass="user-team-no-result-image"
          />
          <div className="user-team-no-user-text-wrapper">
            <Typography customStyle="user-team-no-user">{NO_USERS_FOUND}</Typography>
            <Typography customStyle="user-team-not-found">
              {USER_SEARCHED_DOES_NOT_EXIST}
            </Typography>
          </div>
        </div>
      )}

      <div className="user-team-autocomplete-wrapper">
        <Typography customStyle="user-team-text">Invite Admins</Typography>
        <Autocomplete value={teamState.selectedUsers} onChange={handleChange} />
      </div>
      <Button
        variant="contained"
        startIcon={Images.email}
        customStyle="user-team-button"
        disabled={!teamState.selectedUsers.length}
        onClick={handleSubmit}>
        Send Invite
      </Button>
    </div>
  );
}

export default UserTeam;
