import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import ClockIcon from 'assets/images/clock-icons.svg';
import CloseIcon from 'assets/images/modal-close-icon.svg';
import { AVAILABLE, BOOKED } from 'helpers/constants';
import { formatTime, getTime } from 'helpers/dateHelper';
import Button from 'app/core/shared-components/button';
import CustomSelect from 'app/core/shared-components/customSelect';
import DatePicker from 'app/core/shared-components/date-picker';
import ImageComponent from 'app/core/shared-components/image';
import Radio from 'app/core/shared-components/radio-group';
import { ISelectValue } from 'app/core/shared-components/select';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  fetchAvailableSlots,
  fetchBookedSlots,
  fetchCancelledSlots,
  setFilterValue,
  setFilteredDateValue
} from 'app/redux/slices/slotsSlice';
import { ISlotFilterDTOType } from 'app/services/slots.service';
import MultipleSlots, { ISlotTime } from './MultipleSlots';
import './index.style.scss';
interface ISlotDateFilterProps {
  onClose: () => void;
  onSubmit?: (filterValue: ISlotFilterDTOType[]) => void;
  showClose?: boolean;
  onSlotAdd?: (slots: IAddSlotTimings[]) => void;
  addMultipleSlots?: boolean;
  slotCategory?: string;
  isSearched?: boolean;
}
export interface IAddSlotTimings {
  date: Date;
  times: ISlotTime[];
}
interface ISlotPickerStates {
  currentValue: string;
}
function SlotPicker(props: ISlotDateFilterProps) {
  const {
    onClose,
    onSubmit,
    showClose = true,
    addMultipleSlots = false,
    onSlotAdd,
    slotCategory,
    isSearched = false
  } = props;
  const { filteredDateData, filterValue } = useAppSelector((state) => state.slots);
  const dispatch = useAppThunkDispatch();
  const [datePickerMode, setDatePickerMode] = useState<'linear' | 'non-linear' | false>(
    'non-linear'
  );
  const [formData, setFormData] = useState<{
    date: Date | Date[] | null;
    slotTime: { startTime: ISelectValue | null; endTime: ISelectValue | null };
    disabled: boolean;
    addSlotTimes: ISlotTime[];
    addSlotDisabled: boolean;
  }>({
    date: !addMultipleSlots ? filteredDateData.dates : null,
    slotTime: {
      startTime: !addMultipleSlots ? filteredDateData.slotTime?.startTime : null,
      endTime: !addMultipleSlots ? filteredDateData.slotTime?.endTime : null
    },
    disabled: true,
    addSlotTimes: [] as ISlotTime[],
    addSlotDisabled: true
  });
  const [isDatePickerOpen, toggleDatePicker] = useState<boolean>(false);
  const [currentState, setCurrentState] = useState<string>(filteredDateData.mode);
  const { date, slotTime, disabled, addSlotDisabled, addSlotTimes } = formData;
  const handleReset = () => {
    setFormData({
      date: null,
      slotTime: { startTime: null, endTime: null },
      disabled: true,
      addSlotDisabled: true,
      addSlotTimes: []
    });
    dispatch(
      setFilteredDateValue({
        mode: 'Custom',
        dates: null,
        slotTime: { startTime: null, endTime: null }
      })
    );
    dispatch(
      setFilterValue({
        ...filterValue,
        dateTime: []
      })
    );
    const userId = filterValue.userId;
    onSubmit && onSubmit([]);
    if (!isSearched) {
      if (slotCategory === AVAILABLE) {
        dispatch(fetchAvailableSlots({ ...filterValue, dateTime: [] }));
      } else if (slotCategory === BOOKED) {
        dispatch(fetchBookedSlots({ ...filterValue, dateTime: [] }));
      } else {
        dispatch(fetchCancelledSlots({ ...filterValue, dateTime: [] }));
      }
    } else {
      if (slotCategory === AVAILABLE) {
        dispatch(fetchAvailableSlots({ userId, dateTime: [] }));
      } else if (slotCategory === BOOKED) {
        dispatch(fetchBookedSlots({ userId, dateTime: [] }));
      } else {
        dispatch(fetchCancelledSlots({ userId, dateTime: [] }));
      }
    }
    onClose();
  };
  const handleDateChange = (date: Date | Date[] | null) => {
    setFormData((prevState) => ({ ...prevState, date: date }));
    if (Array.isArray(date)) {
      const sortedDate = date.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      if (sortedDate[0].toDateString() === currentDate?.toDateString() && date.length < 2) {
        setFormData((prevState) => ({
          ...prevState,
          slotTime: { startTime: null, endTime: null }
        }));
      }
    }
    toggleDatePicker(!isDatePickerOpen);
  };
  const handleStartTimeChange = (val: ISelectValue | ISelectValue[] | null) => {
    if (!Array.isArray(val))
      setFormData((prevState) => ({
        ...prevState,
        slotTime: { startTime: val, endTime: null }
      }));
  };
  const handleEndTimeChange = (val: ISelectValue | ISelectValue[] | null) => {
    if (!Array.isArray(val))
      setFormData((prevState) => ({
        ...prevState,
        slotTime: { startTime: prevState.slotTime.startTime, endTime: val }
      }));
  };
  const handleSubmit = () => {
    let times: { from: string; to: string }[] = [];
    const { startTime, endTime } = slotTime;
    if (Array.isArray(date)) {
      times = date.map((value) => {
        return {
          from: formatTime(startTime!.label, value),
          to: formatTime(endTime!.label, value)
        };
      });
    } else if (date !== null) {
      times = [
        {
          from: formatTime(startTime!.label, date),
          to: formatTime(endTime!.label, date)
        }
      ];
    }
    onSubmit && onSubmit(times);
    dispatch(
      setFilteredDateValue({
        mode: currentState,
        dates: date,
        slotTime: { startTime: slotTime.startTime, endTime: slotTime.endTime }
      })
    );
    onClose();
  };
  function handleRadio(val: string) {
    if (val === 'Date Range') {
      setDatePickerMode('linear');
    } else {
      setDatePickerMode('non-linear');
    }
    setCurrentState(val);
  }
  useEffect(() => {
    if (
      date != null &&
      slotTime.startTime != null &&
      slotTime.endTime != null &&
      (!_.isEqual(date, filteredDateData.dates) || !_.isEqual(slotTime, filteredDateData.slotTime))
    ) {
      setFormData((prevState) => ({ ...prevState, disabled: false }));
    } else {
      setFormData((prevState) => ({ ...prevState, disabled: true }));
    }
  }, [date, slotTime]);
  const handleAddSlot = () => {
    if (Array.isArray(date) && date !== null) {
      const slots = date?.map((singleDate) => {
        return {
          date: singleDate,
          times: addSlotTimes
        };
      });
      onSlotAdd && onSlotAdd(slots);
      onClose();
    }
  };
  const handleMultipleTimeChange = (times: ISlotTime[]) => {
    setFormData((prevState) => ({ ...prevState, addSlotTimes: times }));
  };
  const handleAddSlotDisable = (value: boolean) => {
    setFormData((prevState) => ({
      ...prevState,
      addSlotDisabled: date !== null ? value : true
    }));
  };
  const startTime = getTime(Array.isArray(date) ? date[0] : date);
  const startTimeOptions =
    Array.isArray(date) && date.length < 2 ? startTime : getTime(new Date(), true);
  const endTimeOptions =
    slotTime.startTime !== null
      ? startTimeOptions.filter((time) => time?.id > Number(slotTime.startTime!.id)).slice(1)
      : getTime(Array.isArray(date) ? date[0] : date);

  return (
    <div>
      <div className="slot-modal">
        <div className="heading">
          <Radio
            labelArray={['Custom', 'Date Range']}
            currentValue={currentState}
            setCurrentValue={handleRadio}
          />
          {showClose === true && (
            <ImageComponent src={CloseIcon} customClass="modal-close-icon" onClick={onClose} />
          )}
        </div>
        <div className="slot-date-picker-wrapper">
          <DatePicker
            onChange={handleDateChange}
            disablePast
            key={`${currentState}`}
            defaultValue={formData.date}
            multiple={currentState === 'Date Range' ? 'linear' : 'non-linear'}
          />
        </div>
        <div className="form-field">
          {addMultipleSlots && (
            <div>
              <div className="add-slots-subText">
                <ImageComponent src={ClockIcon} customClass="image" />
                <span className="slotsText">Available Slots</span>
              </div>
              <div className="addSlotsSection">
                <MultipleSlots
                  onChangeTime={handleMultipleTimeChange}
                  setDisabled={handleAddSlotDisable}
                  date={date}
                />
              </div>
              <div></div>
            </div>
          )}
          {!addMultipleSlots && (
            <div className="slot-timing">
              <CustomSelect
                dropdownOptions={startTimeOptions?.slice(0, -2)}
                selectedOptions={slotTime?.startTime}
                placeholder="10:00 am"
                onChange={handleStartTimeChange}
                restrictAlphabetOnSearch
              />
              <div className="separator"></div>
              <CustomSelect
                dropdownOptions={endTimeOptions}
                selectedOptions={slotTime.endTime}
                placeholder="11:00 am"
                onChange={handleEndTimeChange}
                restrictAlphabetOnSearch
              />
            </div>
          )}
        </div>
        {!addMultipleSlots ? (
          <div className="modal-buttons">
            <Button
              variant="outlined"
              customStyle="cancel-button"
              onClick={handleReset}
              disabled={filteredDateData.dates ? false : true}>
              Reset
            </Button>
            <Button
              variant="contained"
              customStyle="request-button"
              disabled={disabled}
              onClick={handleSubmit}>
              Apply
            </Button>
          </div>
        ) : (
          <div className="modal-buttons">
            <Button variant="outlined" customStyle="cancel-button" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              customStyle="request-button"
              disabled={addSlotDisabled}
              onClick={handleAddSlot}>
              Add Slot
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
export default SlotPicker;
