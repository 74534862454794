import React, { Component, useEffect, useState, useRef, Fragment, BaseSyntheticEvent } from 'react';
import { toast } from 'react-toastify';
import ImageComponent from 'app/core/shared-components/image';
import Drawer from 'app/core/shared-components/drawer';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  clearSearchBarValue,
  handleCloseSearchResult,
  resetSearchBarValue,
  toggleDrawerVisibility,
  toggleTechDrawerVisibility
} from 'app/redux/slices/adminDashboardSlice';
import './index.style.scss';
import Images from 'assets/images';
import DeleteDisabled from 'assets/images/delete-disabled.svg';
import { v4 as uuidv4 } from 'uuid';
import {
  CANCEL_BUTTON_TEXT,
  DELETE_ACTION,
  DELETE_MODAL_TITLE,
  EDIT_ACTION,
  IMAGE_FILE,
  SAVE_BUTTON_TEXT,
  TECH_STACK_LABEL,
  UPLOAD_IMAGE_TEXT,
  DELETE_TECH_TEXT,
  UNDO
} from 'helpers/constants';
import {
  INVALID_TECH_NAME_ERROR,
  NO_TECH_NAME_ERROR,
  TECH_NAME_ALREADY_EXIST_ERROR,
  SHOULD_CONTAIN_ALPHABET_ERROR,
  DELETE_MODAL_DESCRIPTION,
  INVALID_FILE_TYPE,
  DELETE_TECH_MODAL_DESCRIPTION,
  TECH_DELETE_TOAST_MESSAGE,
  TECH_STACK_EDITED
} from 'helpers/messages.constants';
import {
  TECH_CONTAINS_SPECIAL_CHARACTER,
  ALPHANUMERIC_WITH_SPECIAL_CHARACTER,
  CHECK_ATLEAST_ONE_ALPHABET
} from 'helpers/regex.constants';
import { notify } from 'helpers/toastHelper';
import Button from 'app/core/shared-components/button';
import Typography from 'app/core/shared-components/typography';
import { getTechStack } from 'app/redux/slices/sharedSlice';
import {
  handleResetPage,
  editTechStacks,
  setCurrentDeletion
} from 'app/redux/slices/techStackSlice';
import ConfirmationModal from 'app/core/shared-components/confirmation-modal';
import { deleteTechStack } from 'app/services/techStack.service';
import ToastUndo from 'app/core/shared-components/toast-undo';

interface ITechDrawerState {
  isModalOpen: boolean;
  modalAction: string;
  modalDescription: string;
  modalTitle: string;
  showEditModal: boolean;
  showDeleteModal: boolean;
  stackValue: string;
  showDropdown: boolean;
  imageFile: File | string;
  techNameError: string;
  errorToast: string;
  disabled: boolean;
  isImageChanged: boolean;
  asignee: {
    recruiterCount: number;
    panelCount: number;
  };
  fileUploadError: boolean;
  isUndoToastVisible: boolean;
}

function TechDrawer(props: any) {
  const [techDrawerState, setTechDrawerState] = useState<ITechDrawerState>({
    showEditModal: false,
    showDeleteModal: false,
    stackValue: '',
    showDropdown: false,
    imageFile: '',
    techNameError: '',
    errorToast: '',
    disabled: true,
    isModalOpen: false,
    modalAction: EDIT_ACTION,
    modalDescription: '',
    modalTitle: '',
    isImageChanged: false,
    asignee: { recruiterCount: 0, panelCount: 0 },
    fileUploadError: false,
    isUndoToastVisible: false
  });
  const dispatch = useAppThunkDispatch();
  const { selectedSearch, isTechDrawerOpen } = useAppSelector((state) => state.adminDashboard);
  const { isLoading } = useAppSelector((state) => state.techStack);
  const { techStack } = useAppSelector((state) => state.shared);
  const hiddenFileInput = React.createRef<HTMLInputElement>();
  const { delete: Delete, deleteIcon, uploadFile, zohoIcon } = Images;

  const {
    stackValue,
    techNameError,
    errorToast,
    disabled,
    showEditModal,
    imageFile,
    isModalOpen,
    modalTitle,
    modalDescription,
    modalAction,
    isImageChanged,
    asignee,
    fileUploadError,
    isUndoToastVisible
  } = techDrawerState;

  function toggleDrawer() {
    setTechDrawerState((prevState) => ({
      ...prevState,
      showEditModal: !showEditModal,
      showDropdown: false,
      imageFile: '',
      stackValue: '',
      techNameError: '',
      errorToast: '',
      disabled: true
    }));

    dispatch(clearSearchBarValue());
    dispatch(toggleTechDrawerVisibility());
    setTechDrawerState((prevState) => ({ ...prevState, isImageChanged: false }));
  }

  const onDeleteImageClick = () => {
    setTechDrawerState({ ...techDrawerState, imageFile: '', disabled: false });
  };

  const handleEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.target?.value;
    setTechDrawerState({
      ...techDrawerState,
      stackValue: currentValue,
      techNameError: '',
      disabled: false
    });
    if (currentValue?.trim() === '' || currentValue?.trim() === selectedSearch?.stack) {
      setTechDrawerState({
        ...techDrawerState,
        stackValue: currentValue,
        techNameError: '',
        disabled: true
      });
    } else if (currentValue?.trim()?.length > 30) {
      setTechDrawerState({
        ...techDrawerState,
        techNameError: ''
      });
    } else if (TECH_CONTAINS_SPECIAL_CHARACTER.test(currentValue)) {
      setTechDrawerState({
        ...techDrawerState,
        techNameError: INVALID_TECH_NAME_ERROR,
        stackValue: currentValue,
        disabled: true
      });
    }
  };
  const handleFileUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [firstFile, ...rest] = Array.from(event?.currentTarget?.files as FileList);
    const fileUploaded = firstFile;

    const { name } = firstFile;
    if (
      name.substr(name.length - '.png'.length, '.png'.length).toLowerCase() ==
        '.png'.toLowerCase() ||
      name.substr(name.length - '.jpeg'.length, '.jpeg'.length).toLowerCase() ==
        '.jpeg'.toLowerCase() ||
      name.substr(name.length - '.jpg'.length, '.jpg'.length).toLowerCase() == '.jpg'.toLowerCase()
    ) {
      setTechDrawerState({
        ...techDrawerState,
        imageFile: fileUploaded,
        techNameError: '',
        disabled: false,
        isImageChanged: true,
        fileUploadError: false
      });
    } else {
      setTechDrawerState({ ...techDrawerState, fileUploadError: true, disabled: true });
    }
  };
  const handleFileUpload = () => {
    hiddenFileInput?.current?.click();
  };

  const onDeleteClick = () => {
    setTechDrawerState((prevStates) => ({
      ...prevStates,
      isUndoToastVisible: true,
      isModalOpen: false
    }));
    notify(
      false,
      <ToastUndo
        toastMessage={TECH_DELETE_TOAST_MESSAGE}
        handleUndo={onUndoDelete}
        toastId={parseInt(selectedSearch?.id?.toString())}
      />,
      parseInt(selectedSearch?.id?.toString()),
      onDeleteItem
    );
  };

  const onUndoDelete = () => {
    setTechDrawerState((prevStates) => ({
      ...prevStates,
      isUndoToastVisible: false
    }));
    toast.dismiss(parseInt(selectedSearch?.id?.toString()));
    toast.update(parseInt(selectedSearch?.id?.toString()), {
      onClose: () => {},
      hideProgressBar: true
    });
  };

  const onDeleteItem = async () => {
    setTechDrawerState({ ...techDrawerState, isModalOpen: false, isUndoToastVisible: false });
    const data = await deleteTechStack(selectedSearch?.id);
    if (!data?.error) {
      toggleDrawer();
      dispatch(getTechStack());
    } else {
      setTechDrawerState({
        ...techDrawerState,
        errorToast: data?.error[0]?.message,
        isUndoToastVisible: false,
        asignee: {
          recruiterCount: data.error[0].data.recruiterCount,
          panelCount: data.error[0].data.panelCount
        }
      });
    }
  };

  const handleToastClose = () => {};

  const onTechStackEdit = async () => {
    const formData = new FormData();
    formData.append(IMAGE_FILE, techDrawerState?.imageFile);
    const { id, imageUrl } = selectedSearch;
    const { stackValue, imageFile } = techDrawerState;
    const isTechNameExist = techStack.filter(
      (tech) =>
        tech?.stack?.toUpperCase() ===
        stackValue
          ?.replace(/\s{2,}/g, ' ')
          ?.trim()
          .toUpperCase()
    );
    const isTechStackExist =
      !isTechNameExist?.length ||
      isTechNameExist[0]?.stack?.toUpperCase() === selectedSearch?.stack?.toUpperCase();

    const isTechNameContainsAlphanumeric = ALPHANUMERIC_WITH_SPECIAL_CHARACTER.test(stackValue);
    const isTechNameContainsAlphabets = CHECK_ATLEAST_ONE_ALPHABET.test(stackValue);
    if (isTechNameContainsAlphanumeric) {
      if (isTechNameContainsAlphabets) {
        if (isTechStackExist) {
          if (stackValue?.trim()?.length) {
            const data = await dispatch(
              editTechStacks({
                id: id,
                stackName: stackValue?.replace(/\s{2,}/g, ' ')?.trim(),
                imageFile: imageFile ? formData : null,
                imageUrl
              })
            ).unwrap();
            if (!data?.error && !isLoading) {
              notify(true, TECH_STACK_EDITED, uuidv4().toString(), handleToastClose);
              dispatch(getTechStack());
              toggleDrawer();
            } else {
              setTechDrawerState({
                ...techDrawerState,
                techNameError: data?.error[0]?.message,
                disabled: true
              });
            }
          } else {
            setTechDrawerState({
              ...techDrawerState,
              techNameError: NO_TECH_NAME_ERROR,
              disabled: true
            });
          }
        } else {
          setTechDrawerState({
            ...techDrawerState,
            techNameError: TECH_NAME_ALREADY_EXIST_ERROR,
            disabled: true
          });
        }
      } else {
        setTechDrawerState({
          ...techDrawerState,
          techNameError: SHOULD_CONTAIN_ALPHABET_ERROR,
          disabled: true
        });
      }
    } else {
      setTechDrawerState({
        ...techDrawerState,
        techNameError: INVALID_TECH_NAME_ERROR,
        disabled: true
      });
    }
  };

  function handleDelete() {
    setTechDrawerState((prevStates) => ({
      ...prevStates,
      isModalOpen: true,
      modalAction: DELETE_ACTION,
      modalDescription: DELETE_MODAL_DESCRIPTION,
      modalTitle: DELETE_MODAL_TITLE
    }));
  }

  function handleCloseModal() {
    setTechDrawerState((prevStates) => ({
      ...prevStates,
      isModalOpen: false,
      errorToast: ''
    }));
  }

  useEffect(() => {
    if (selectedSearch && isTechDrawerOpen) {
      setTechDrawerState((prevState) => ({
        ...prevState,
        showEditModal: !showEditModal,
        showDropdown: false,
        imageFile: selectedSearch?.imageUrl,
        stackValue: selectedSearch?.stack,
        techNameError: '',
        disabled: true,
        isImageChanged: false
      }));
    }
  }, [isTechDrawerOpen]);

  return (
    <Fragment>
      <Drawer
        onClose={toggleDrawer}
        isOpen={isTechDrawerOpen}
        bodyStyles="drawer-body-style"
        headerStyles="drawer-header-custom-style"
        headerProps={
          selectedSearch?.noOfUsers > 0 ? (
            <ImageComponent src={DeleteDisabled} customClass="delete-icon" />
          ) : (
            <ImageComponent src={Delete} onClick={handleDelete} customClass="delete-icon" />
          )
        }>
        <div className="edit-drawer-content">
          {selectedSearch?.isZohoRecord && (
            <div className="zoho-label">
              <span className="zoho-tag">Z</span>
            </div>
          )}
          <div className="edit-tech-drawer-img">
            {selectedSearch?.isZohoRecord ? (
              <ImageComponent
                src={selectedSearch?.imageUrl === null ? undefined : selectedSearch?.imageUrl}
                fallbackText={stackValue}
                fallbackClass="uploaded-image"
                className="uploaded-image"
              />
            ) : (
              <Fragment>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  ref={hiddenFileInput}
                  key={uuidv4()}
                  className="hidden-input"
                  onChange={handleFileUploadChange}
                />
                {imageFile && (
                  <div className="editable-tech-image">
                    <img
                      src={
                        selectedSearch?.imageUrl && !isImageChanged
                          ? selectedSearch?.imageUrl
                          : URL.createObjectURL(imageFile as unknown as Blob)
                      }
                      className="uploaded-image"
                    />
                    <div className="delete-tech-image" onClick={onDeleteImageClick}>
                      <ImageComponent className="" src={deleteIcon} />
                    </div>
                  </div>
                )}
                {!imageFile && (
                  <div className="image-upload-wrapper">
                    <ImageComponent src={uploadFile} />
                    <div className="add-image" onClick={handleFileUpload}>
                      {UPLOAD_IMAGE_TEXT}
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </div>
          {fileUploadError && (
            <div className="invalid-error-ts-drawer-home">
              <Typography variant="error">{INVALID_FILE_TYPE}</Typography>
            </div>
          )}
          <Typography customStyle="tech-drawer-text">
            Number of Users: {selectedSearch?.noOfUsers}
          </Typography>
          <form className="tech-drawer-form">
            <label htmlFor="techName" className="delete-drawer--tech-label">
              {TECH_STACK_LABEL}
            </label>
            <input
              type="text"
              name="techName"
              className="tech-name"
              value={stackValue}
              onChange={handleEdit}
              disabled={selectedSearch?.isZohoRecord}
              autoComplete="off"
              required
            />
            {techNameError && <Typography variant="error">{techNameError}</Typography>}
          </form>
          {!selectedSearch?.isZohoRecord && (
            <div className="edit-drawer-footer">
              <Button customStyle="edit-drawer--cancel-btn" onClick={toggleDrawer}>
                {CANCEL_BUTTON_TEXT}
              </Button>

              <Button
                customStyle="edit-drawer--save-btn"
                variant="contained"
                onClick={onTechStackEdit}
                disabled={disabled}>
                {SAVE_BUTTON_TEXT}
              </Button>
            </div>
          )}
        </div>
      </Drawer>
      <Fragment>
        <ConfirmationModal
          isOpen={isModalOpen}
          title={DELETE_TECH_TEXT}
          description={DELETE_TECH_MODAL_DESCRIPTION}
          onConfirm={onDeleteClick}
          onClose={handleCloseModal}
          confirmText={'Delete'}
        />
      </Fragment>
      <ConfirmationModal
        isOpen={!!errorToast}
        title="Unable to delete Tech Stack"
        buttonGroupVariant="secondary"
        onClose={handleCloseModal}
        cancelText={'Go Back'}>
        <div className="cm-delete-notice-text">
          <span className="delete-ts-black">{`"${selectedSearch?.stack}"`}</span>
          {` has currently
            been assigned to ${asignee.panelCount} Panellists and 
            ${asignee.recruiterCount} Talent Acquisitions and cannot be deleted until it is replaced
            with another Tech Stack.`}
        </div>
      </ConfirmationModal>
    </Fragment>
  );
}
export default TechDrawer;
