import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  SyntheticEvent,
  ChangeEvent,
  Fragment
} from 'react';
import './index.style.scss';
import Images from 'assets/images';
import ImageComponent from '../image';
import Button from '../button';
import Typography from '../typography';
import ClickAwayListener from '../click-away';
import _ from 'lodash';
import Loader from '../loader';
import { COULDNT_FIND_RESULTS, LOADING, START_TYPING } from 'helpers/messages.constants';

interface ISelectorBase {
  label: string;
  imageSource: string | null;
}

export interface ISelectorValue extends ISelectorBase {
  id: string | number;
}

export interface ISelectorOption extends ISelectorValue {
  secondaryLabel?: string;
}

export interface ISelectorProps {
  open?: boolean;
  options: ISelectorOption[] | null;
  value?: ISelectorValue | null;
  onSearchTextChange?: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
  onChangeHandler?: (value: ISelectorValue | null) => void;
  debounceInterval?: number;
  showLoader?: boolean;
  showError?: boolean;
  customClass?: string;
  dropDownClass?: string;
  onDropDownClose?: () => void;
  showDropDownHeader?: ISelectorValue | null;
  placeholder?: string;
}

export interface ISelectorStates {
  showDropdown: boolean;
  selected: ISelectorValue | null;
}

interface ISelectorDropdownStates {
  inputFocusStyle: string | null;
  inputValue: string;
}

interface ISelectorDropdownProps {
  options: ISelectorOption[] | null;
  onSearchTextChange?: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
  onClickHandler?: (option: ISelectorOption) => void;
  debounceInterval?: number;
  showLoader: boolean;
  showError: boolean;
  dropDownClass?: string;
  showDropDownHeader?: ISelectorValue | null;
}

function Selector(props: ISelectorProps) {
  const {
    showLoader = false,
    showError = false,
    customClass,
    dropDownClass,
    onDropDownClose,
    placeholder
  } = props;
  const [selectorState, setSelectorState] = useState<ISelectorStates>({
    showDropdown: false,
    selected: null
  });
  const { showDropdown } = selectorState;

  const toggleDropdown = useCallback(() => {
    setSelectorState((prevState) => ({ ...prevState, showDropdown: !prevState.showDropdown }));
    if (showDropdown) {
      onDropDownClose && onDropDownClose();
    }
  }, [selectorState.showDropdown]);

  const closeDropdown = useCallback(() => {
    if (showDropdown) {
      setSelectorState((prevState) => ({ ...prevState, showDropdown: false }));
      onDropDownClose && onDropDownClose();
    }
  }, [selectorState.showDropdown]);

  const selectorViewBox = () => {
    const { selected = null } = selectorState;
    if (selected) {
      return (
        <div className="selector-main-viewbox" onClick={toggleDropdown}>
          <div className="selector-selected-icon-wrapper">
            <ImageComponent
              src={selected?.imageSource}
              alt={selected?.label}
              fallbackText={selected?.label}
              customClass="selector-user-icon"
              fallbackClass="selector-user-icon--fallback"
            />
          </div>
          <div className="selector-text-container">
            <Typography customStyle="selector-text">{selected?.label}</Typography>
          </div>
        </div>
      );
    }
    return (
      <div className="selector-main-viewbox" onClick={toggleDropdown}>
        <div className="selector-text-placeholder">{placeholder}</div>
      </div>
    );
  };

  const handleSelection = useCallback(
    (option: ISelectorOption) => {
      const { onChangeHandler } = props;
      const data = {
        id: option.id,
        label: option.label,
        imageSource: option.imageSource
      };
      setSelectorState((prevState) => ({ ...prevState, selected: data, showDropdown: false }));
      onDropDownClose && onDropDownClose();
      onChangeHandler && onChangeHandler(data);
    },
    [props.onChangeHandler]
  );

  useEffect(() => {
    const { value = null } = props;
    setSelectorState((prevState) => ({ ...prevState, selected: value }));
  }, [props.value]);

  useEffect(() => {
    const { open = false } = props;
    setSelectorState((prevState) => ({ ...prevState, showDropdown: open }));
  }, [props.open]);

  return (
    <ClickAwayListener
      customClass={
        showDropdown
          ? `selector-container selector-container-focus ${customClass}`
          : `selector-container ${customClass}`
      }
      handleClose={closeDropdown}>
      <div
        className={showDropdown ? 'selector-viewbox selector-viewbox-active' : 'selector-viewbox'}>
        {selectorViewBox()}
        <Button variant="outlined" className="selector-icon-wrapper" onClick={toggleDropdown}>
          <ImageComponent
            src={showDropdown ? Images.arrowUp : Images.arrowDown}
            alt="arrow-icon"
            customClass="selector-icon"
          />
        </Button>
      </div>
      {showDropdown && (
        <SelectorDropdown
          options={props.options}
          onSearchTextChange={props.onSearchTextChange}
          onClickHandler={handleSelection}
          showLoader={showLoader}
          showError={showError}
          dropDownClass={dropDownClass}
          debounceInterval={props.debounceInterval}
          showDropDownHeader={props.showDropDownHeader}
        />
      )}
    </ClickAwayListener>
  );
}

function SelectorDropdown(props: ISelectorDropdownProps) {
  const [dropdownState, setDropdownState] = useState<ISelectorDropdownStates>({
    inputFocusStyle: null,
    inputValue: ''
  });

  const inputRef = useRef<HTMLInputElement | null>(null);
  const debounceRef = useRef<any>(null);

  const {
    showLoader = false,
    showError = false,
    dropDownClass,
    showDropDownHeader = false
  } = props;
  const { inputValue, inputFocusStyle } = dropdownState;

  const handleStylingChange = useCallback(() => {
    const { inputFocusStyle = null } = dropdownState;
    if (inputFocusStyle) {
      setDropdownState((prevState) => ({ ...prevState, inputFocusStyle: null }));
      return;
    }
    setDropdownState((prevState) => ({
      ...prevState,
      inputFocusStyle: 'selector-input-field-focus'
    }));
  }, [dropdownState.inputFocusStyle]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { onSearchTextChange, debounceInterval } = props;
      const value = event.currentTarget.value;
      setDropdownState((prevState) => ({ ...prevState, inputValue: value }));
      if (onSearchTextChange) {
        if (debounceRef.current) {
          debounceRef.current.cancel();
        }
        debounceRef.current = _.debounce(onSearchTextChange, debounceInterval);
        debounceRef.current(value.trim(), event);
      }
    },
    [props.onSearchTextChange, props.debounceInterval]
  );

  const handleClick = useCallback(
    (param: ISelectorOption) => {
      const { onClickHandler } = props;
      onClickHandler && onClickHandler(param);
    },
    [props.onClickHandler]
  );

  const handleSelectorHeaderClick = useCallback(
    (param: ISelectorOption) => () => {
      const { onClickHandler } = props;
      onClickHandler && onClickHandler(param);
    },
    [props.onClickHandler]
  );

  const handleClearText = useCallback(() => {
    const { onSearchTextChange } = props;
    setDropdownState((prevState) => ({ ...prevState, inputValue: '' }));
    inputRef?.current?.focus();
    onSearchTextChange && onSearchTextChange('');
  }, [props.onSearchTextChange]);

  return (
    <Fragment>
      <div className={`selector-dropdown-container ${dropDownClass}`}>
        {showDropDownHeader && (
          <div>
            <div
              className="selector-dropdown-my-schedule-container"
              onClick={handleSelectorHeaderClick(showDropDownHeader)}>
              <div className={'selector-image-container'}>
                <ImageComponent
                  src={showDropDownHeader.imageSource}
                  alt="profile-icon"
                  fallbackText="My Schedule"
                  customClass="selector-image-container"
                />
              </div>
              <Typography customStyle="my-schedule-label">My Schedule</Typography>
            </div>
            <hr className="schedule-label-line" />
          </div>
        )}
        <div className={`selector-input-field-wrapper ${inputFocusStyle}`}>
          <div className="selector-input-field-icons">
            <ImageComponent src={Images.search} alt="search" />
          </div>
          <input
            autoFocus
            type="text"
            className="selector-input-field"
            placeholder="Search"
            ref={inputRef}
            value={inputValue}
            onFocus={handleStylingChange}
            onBlur={handleStylingChange}
            onChange={handleInputChange}
          />
          {!!inputValue.length && (
            <Button className="selector-input-field-icons" onClick={handleClearText}>
              <ImageComponent src={Images.close} alt="close" />
            </Button>
          )}
        </div>
        <div className="selector-dropdown-list">
          {!showError ? (
            <Fragment>
              {!showLoader ? (
                <Fragment>
                  <SelectorOptions options={props.options || []} onClickHandler={handleClick} />

                  {!props.options?.length && <SelectorTypingPlaceholder />}
                </Fragment>
              ) : (
                <SelectorLoading open={showLoader} />
              )}
            </Fragment>
          ) : (
            <SelectorNoResult />
          )}
        </div>
      </div>
    </Fragment>
  );
}

interface ISelectorOptions {
  options: ISelectorOption[];
  onClickHandler?: (param: ISelectorOption) => void;
}

function SelectorOptions(props: ISelectorOptions) {
  const handleClick = (param: ISelectorOption) => () => {
    const { onClickHandler } = props;
    onClickHandler && onClickHandler(param);
  };

  return (
    <Fragment>
      {props.options.map((item, index) => (
        <SelectorOptionDetail
          key={index}
          id={item.id}
          label={item.label}
          imageSource={item.imageSource}
          secondaryLabel={item?.secondaryLabel}
          onClick={handleClick(item)}
        />
      ))}
    </Fragment>
  );
}

interface ISelectorOptionDetail extends ISelectorOption {
  onClick?: (event: SyntheticEvent) => void;
}

function SelectorOptionDetail(props: ISelectorOptionDetail) {
  const { secondaryLabel, onClick } = props;
  const handleTabClick = (event: React.KeyboardEvent) => {
    if (event?.keyCode === 13) {
      onClick && onClick(event);
    }
  };
  return (
    <div
      className="selector-user-wrapper"
      onClick={onClick}
      tabIndex={0}
      onKeyDown={handleTabClick}>
      <div className="selector-user-icon-wrapper">
        <ImageComponent
          src={props.imageSource}
          fallbackText={props.label}
          customClass="selector-user-icon"
          fallbackClass="selector-user-icon-wrapper"
        />
      </div>
      <div className="selector-user-detail-wrapper">
        <Typography customStyle="selector-user-label">{props.label}</Typography>
        {secondaryLabel && (
          <Typography customStyle="selector-user-label--secondary">{secondaryLabel}</Typography>
        )}
      </div>
    </div>
  );
}

interface ISelectorLoader {
  open: boolean;
}

function SelectorLoading(props: ISelectorLoader) {
  return (
    <Fragment>
      <Loader
        loading={props.open}
        customClass="selector-loading-spinner"
        customClassIcon="selector-loading-icon"
      />
      <Typography customStyle="loading-text">{LOADING}</Typography>
    </Fragment>
  );
}

function SelectorNoResult() {
  return (
    <Fragment>
      <ImageComponent src={Images.noResult} alt="No result" customClass="no-result-container" />
      <div className="no-result-text-container">
        <Typography customStyle="no-user-label">{COULDNT_FIND_RESULTS}</Typography>
      </div>
    </Fragment>
  );
}

function SelectorTypingPlaceholder() {
  return (
    <div className="selector-typing-container">
      <ImageComponent src={Images.searchIcon} alt="search" customClass="search-icon" />
      <Typography customStyle="search-text">{START_TYPING}</Typography>
    </div>
  );
}

Selector.defaultProps = {
  options: [],
  open: false,
  debounceInterval: 2000,
  showLoader: false,
  showError: false,
  showDropDownHeader: null,
  placeholder: 'Select'
};

SelectorDropdown.defaultProps = {
  debounceInterval: 2000,
  showDropDownHeader: null
};

export default Selector;
