import React, { Component, Fragment } from 'react';
import Button from '../../button';
import {
  RESIZE,
  NEXT,
  PREVIOUS,
  PAGE_NUMBER_SET,
  DEFAULTPAGESIZE
} from '../../../../../helpers/constants';
import rightArrowGray from '../../../../../assets/images/rightArrowGray.svg';
import './index.style.scss';
import ImageComponent from '../../../shared-components/image';
import PaginationNext from '../../../../../assets/images/paginationNext.svg';
import PaginationPrevious from '../../../../../assets/images/paginationPrev.svg';
import { handleTabEventWithValue } from '../../../../../helpers/utils';
interface IPaginationProps {
  pageShift: (offset: number) => void;
  pageNo: number;
  numberOfPages: number;
  pageNoArray: Array<number>;
  setPageNo: (param: number) => void;
  totalCount?: number;
}

interface IPaginationStates {
  pages: number;
  isMobile: boolean;
}

export default class Pagination extends Component<IPaginationProps, IPaginationStates> {
  constructor(props: IPaginationProps) {
    super(props);
    this.state = {
      pages: 0,
      isMobile: window.innerWidth < 600
    };
    this.updateDeviceStatus = this.updateDeviceStatus.bind(this);
  }

  componentDidMount() {
    window.addEventListener(RESIZE, this.updateDeviceStatus);
  }
  updateDeviceStatus() {
    if (window.innerWidth < 600) this.setState({ isMobile: true });
    else this.setState({ isMobile: false });
  }

  static getDerivedStateFromProps(props: IPaginationProps) {
    return { pages: props.numberOfPages };
  }

  getPageNumberArray = () => {
    const { pageNo, pageNoArray } = this.props;
    const startIndex =
      (pageNo - 1) % PAGE_NUMBER_SET === 0
        ? pageNo - 1
        : Math.floor((pageNo - 1) / PAGE_NUMBER_SET) * PAGE_NUMBER_SET;
    const endIndex = PAGE_NUMBER_SET * (startIndex / PAGE_NUMBER_SET + 1);

    return pageNoArray.slice(startIndex, endIndex).map((pageNumber) => {
      return (
        <div
          className={`page-number-div ${
            this.props.pageNo === pageNumber ? 'page-number-selected' : ''
          }`}
          onClick={() => {
            this.props.setPageNo(pageNumber);
          }}
          key={pageNumber}>
          {pageNumber}
        </div>
      );
    });
  };

  render() {
    const { pageShift, pageNo, pageNoArray, totalCount } = this.props;
    const result = `Results ${(pageNo - 1) * DEFAULTPAGESIZE + 1}-${
      pageNo === pageNoArray[pageNoArray?.length - 1] ? totalCount : pageNo * DEFAULTPAGESIZE
    } of ${totalCount}`;
    return (
      <Fragment>
        <div className="pagination-main-container">
          <div className="page-container">
            <Button
              id="pagination-prev"
              variant="outlined"
              size="medium"
              customStyle={'pagination-button-styles pg-prev-btn'}
              onClick={() => pageShift(-1)}
              disabled={pageNo === 1}>
              <ImageComponent src={PaginationPrevious} />
            </Button>
            <div className="pagination-page-numbers">
              {pageNoArray?.length && this.getPageNumberArray()}
            </div>
            <Button
              id="pagination-next"
              variant="outlined"
              size="medium"
              onClick={() => pageShift(1)}
              disabled={pageNo === pageNoArray?.length}
              customStyle={
                pageNo === pageNoArray?.length
                  ? 'pagination-disabled-button pg-next-btn'
                  : 'pagination-button-styles pg-next-btn'
              }>
              <ImageComponent src={PaginationNext} />
            </Button>
          </div>
          <div className="results-count">{result}</div>
        </div>
      </Fragment>
    );
  }
}
