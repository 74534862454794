import React, { Fragment } from 'react';
import { HeaderGroup } from 'react-table';
import CheckBox from '../../checkbox';
import Headers from './headers';

interface IHeaderGroupsProps {
  headerGroups: HeaderGroup<object>[];
  isCheckEnabled: boolean;
  noHeaderBorder: boolean;
  sortColumns: boolean;
  checkIdeal: () => boolean;
  checkMaster: () => boolean;
  handleAllRowSelect: () => void;
}

const HeaderGroups = (props: IHeaderGroupsProps) => {
  const {
    headerGroups,
    isCheckEnabled,
    noHeaderBorder,
    checkIdeal,
    checkMaster,
    sortColumns,
    handleAllRowSelect
  } = props;

  return (
    <Fragment>
      {headerGroups.map((headerGroup, index) => {
        return (
          <tr {...headerGroup.getHeaderGroupProps()} key={index} className="table-header-row">
            {isCheckEnabled && (
              <th
                className={`table-header-cell table-checkbox-cell ${
                  noHeaderBorder ? 'no-border' : ''
                }`}>
                <CheckBox
                  ideal={checkIdeal()}
                  checked={checkMaster()}
                  handleClick={handleAllRowSelect}
                />
              </th>
            )}
            <Headers
              headers={headerGroup.headers}
              noHeaderBorder={noHeaderBorder}
              sortColumns={sortColumns}
            />
          </tr>
        );
      })}
    </Fragment>
  );
};

export default HeaderGroups;
