import React from 'react';
import './index.style.scss';
import Typography from '../../../../core/shared-components/typography';
import ImageComponent from '../../../../core/shared-components/image';
import Images from '../../../../../assets/images';
import moment from 'moment';

interface IGoogleEventPopupProps {
  eventTitle: string;
  start: Date | null;
  end: Date | null;
  onClose?: () => void;
}

function GoogleEventPopup(props: IGoogleEventPopupProps) {
  const { eventTitle, start, end, onClose } = props;
  const startDate = moment(start);
  const endDate = moment(end);
  return (
    <div className="g-event-popup-wrapper">
      <div className="g-event-header">
        <Typography customStyle="g-event-label">Google Event</Typography>
        <div className="g-event-icon-wrapper" onClick={onClose}>
          <ImageComponent src={Images.close} />
        </div>
      </div>
      <div className="g-event-content">
        <Typography customStyle="g-event-title">{eventTitle}</Typography>
        <Typography customStyle="g-event-timming-label">{`${startDate.format(
          'dddd, DD MMMM YYYY'
        )}, ${startDate.format('hh:mm A')} - ${endDate.format('hh:mm A')}`}</Typography>
      </div>
    </div>
  );
}

export default GoogleEventPopup;
