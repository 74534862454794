import React, { useState, useEffect, Fragment, useCallback, BaseSyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import './index.style.scss';
import CheckBox from 'app/core/shared-components/checkbox';
import Button from 'app/core/shared-components/button';
import { useAppSelector } from 'app/redux/hooks';

import {
  ADMIN_FILTER_RESET_DISABLED,
  ADMIN_FILTER_RESET_BUTTON,
  ADMIN_FILTER_APPLY_DISABLED,
  ADMIN_FILTER_APPLY_BUTTON,
  PANEL_FILTER_TOOLTIP_LENGTH,
  SEARCH,
  TECH_STACK_FILTER,
  LEVEL_FILTER,
  EXPERIENCE_FILTER,
  PROJECT_FILTER,
  SEARCH_TECH_STACK,
  SEARCH_PROJECT,
  FILTER_BY,
  EXPERIENCEFILTER,
  FILTER_EXPERIENCE
} from 'helpers/constants';
import _ from 'lodash';
import { handleTabEventWithValue } from 'helpers/utils';
import Search from 'app/core/shared-components/search';
import InputBox from 'app/core/shared-components/input-box';
import Images from 'assets/images';
import ImageComponent from 'app/core/shared-components/image';
import { NO_RESULT_FOUND_ERROR } from 'helpers/messages.constants';
import Typography from 'app/core/shared-components/typography';
import { IFilters } from 'helpers/panel';

interface IFiltersProps {
  onFilterClose: () => void;
  setFilterCount: (count: number) => void;
  applyFilters: (param: IFilters) => void;
  appliedFilters: IFilters;
  tab?: number;
  isOpen?: boolean;
}
interface ITechStackState {
  searchValue: string;
  isSearchedOptionSelected: boolean;
  isSearched: boolean;
  searchData: any;
}

export interface ILevelOfInterviewType {
  id: string;
  level: string;
  experience: string;
  numberOfPanel: number;
  isZohoRecord: boolean;
  levelCount?: number | string;
}

export interface ITechStackType {
  id: string;
  stack: string;
  addedOn: string;
  image: string[];
  isZohoRecord: boolean;
}

export interface IProjectType {
  id: string;
  name: string;
  numberOfPanel: number;
}

function Filters({
  onFilterClose,
  setFilterCount,
  applyFilters,
  appliedFilters,
  tab,
  isOpen = false
}: IFiltersProps) {
  const [interviewLevelList, setInterviewLevelListState] = useState<Array<string>>([]);
  const [techStacks, setTechStacksState] = useState<Array<string>>([]);
  const [experience, setExperienceState] = useState<Array<number>>([]);
  const [projects, setProjectsState] = useState<Array<string>>([]);
  const [singleCheckCount, setSingleCheckCount] = useState(0);
  const [isApplyEnabled, setIsApplyEnabled] = useState(false);
  const [eligiblePanel, setEligiblePanels] = useState(false);
  const [filterBy, setFilterBy] = useState('');
  const [showSearchField, setShowSearchField] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState('');
  const [techStackState, setTechStackState] = useState<ITechStackState>({
    isSearchedOptionSelected: false,
    searchData: [],
    isSearched: false,
    searchValue: ''
  });

  const {
    projects: sharedProjects,
    techStack,
    levelOfInterview
  } = useAppSelector((state) => state.shared);

  const { search, close, noResult } = Images;

  const filterOptions = [TECH_STACK_FILTER, LEVEL_FILTER, EXPERIENCE_FILTER, PROJECT_FILTER];

  let { searchData, searchValue, isSearched } = techStackState;

  function resetFilters() {
    setInterviewLevelListState([]);
    setExperienceState([]);
    setProjectsState([]);
    setTechStacksState([]);
    setSingleCheckCount(0);
    setEligiblePanels(false);
  }

  function setInterViewLevels(e?: React.MouseEvent<HTMLDivElement, MouseEvent> | number | any) {
    e.stopPropagation();
    let temp = interviewLevelList;
    let value = e?.currentTarget?.id || e;
    let foundIndex = temp.findIndex((item) => {
      return item === value;
    });
    if (foundIndex !== -1) {
      temp.splice(foundIndex, 1);
      setSingleCheckCount(singleCheckCount - 1);
    } else {
      temp.push(value);
      setSingleCheckCount(singleCheckCount + 1);
    }
    setInterviewLevelListState([...temp]);
  }

  function setProjects(e: React.MouseEvent<HTMLDivElement, MouseEvent> | number | any) {
    e.stopPropagation();
    let temp = projects;
    const value = e?.currentTarget?.id || e;
    let foundIndex = temp.findIndex((item) => {
      return item === value;
    });
    if (foundIndex !== -1) {
      temp.splice(foundIndex, 1);
      setSingleCheckCount(singleCheckCount - 1);
    } else {
      temp.push(value);
      setSingleCheckCount(singleCheckCount + 1);
    }
    setProjectsState([...temp]);
  }

  function setTechStack(e: React.MouseEvent<HTMLDivElement, MouseEvent> | number | any) {
    e.stopPropagation();
    let temp = techStacks;
    const value = e?.currentTarget?.id || e;
    let foundIndex = temp.findIndex((item) => {
      return item === value;
    });
    if (foundIndex !== -1) {
      temp.splice(foundIndex, 1);
      setSingleCheckCount(singleCheckCount - 1);
    } else {
      temp.push(value);
      setSingleCheckCount(singleCheckCount + 1);
    }
    setTechStacksState([...temp]);
  }

  function setExperiences(e: React.MouseEvent<HTMLDivElement, MouseEvent> | number | any) {
    e.stopPropagation();
    let temp = experience;
    const value = parseInt(e?.currentTarget?.id) > -1 ? parseInt(e?.currentTarget?.id) : e;
    let foundIndex = temp.findIndex((item) => {
      return item == value;
    });
    if (foundIndex !== -1) {
      temp.splice(foundIndex, 1);
      setSingleCheckCount(singleCheckCount - 1);
    } else {
      temp.push(value.toString());
      setSingleCheckCount(singleCheckCount + 1);
    }
    setExperienceState([...temp]);
  }

  function applyFiltersLocal() {
    let count = 0;
    count = techStacks.length + interviewLevelList.length + projects.length + experience.length;
    if (eligiblePanel) {
      count++;
    }
    setFilterCount(count);
    onFilterClose();
    applyFilters({
      interviewLevelList: interviewLevelList,
      techStacks: techStacks,
      experience: experience,
      projects: projects,
      count: count,
      eligiblePanels: eligiblePanel
    });
  }

  const handleResetBtn = () => {
    singleCheckCount ? resetFilters() : () => {};
  };
  const handleApplyBtn = () => {
    !isApplyEnabled ? applyFiltersLocal() : () => {};
  };

  const handleFilterByChange = (item: string) => {
    setFilterBy(item);
    setIsItemSelected(item);
    handleSearchAction('');
    switch (item) {
      case TECH_STACK_FILTER:
        return setShowSearchField(true);
      case EXPERIENCE_FILTER:
        return setShowSearchField(false);
      case LEVEL_FILTER:
        return setShowSearchField(false);
      case PROJECT_FILTER:
        return setShowSearchField(true);
    }
  };

  const getSearchResults = (searchValue: string) => {
    if (filterBy === TECH_STACK_FILTER) {
      return techStack.filter(
        (techItem) => techStack && techItem.stack.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else {
      return sharedProjects.filter(
        (techItem) =>
          sharedProjects && techItem.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
  };

  const handleSearchAction = useCallback(
    (searchValue: string | number) => {
      const searchString = searchValue.toString();

      if (searchString.trim().length) {
        const data = getSearchResults(searchString);
        setTechStackState((prevState) => ({
          ...prevState,
          isSearched: true,
          searchData: data,
          searchValue: searchString
        }));
      } else {
        setTechStackState((prevState) => ({
          ...prevState,
          isSearched: false,
          searchData: [],
          searchValue: ''
        }));
      }
    },
    [searchData]
  );

  const clearInput = () => {
    handleSearchAction('');
  };

  const noResultFound = () => {
    return (
      <div className="no-result-tech-stack">
        <ImageComponent src={noResult} className="no-results-image" />
        <Typography variant="text" customStyle="name">
          {NO_RESULT_FOUND_ERROR}
        </Typography>
      </div>
    );
  };
  const checkBoxItem = (
    id: string,
    setFunction: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | number | any) => void,
    item: string,
    name: (string | number)[]
  ) => {
    return (
      <div
        className="ap-filter-modal-list-item"
        id={id}
        key={id}
        tabIndex={0}
        onClick={setFunction}
        onKeyDown={handleTabEventWithValue(id, setFunction)}>
        <CheckBox checked={name?.includes(id)} />
        <div className="ap-filter-modal-list-item-name">{item}</div>
        {item?.length > PANEL_FILTER_TOOLTIP_LENGTH && (
          <div className="ts-tooltip">
            {item}
            <span className="ts-tooltip-arrow" />
          </div>
        )}
      </div>
    );
  };
  const filterData = (item: string) => {
    switch (item) {
      case TECH_STACK_FILTER:
        const techData: ITechStackType[] = isSearched ? searchData : techStack;

        return (
          <Fragment>
            {isSearched && !techData.length ? (
              noResultFound()
            ) : (
              <div className={'ap-filter-modal-list-grid-container-panel'}>
                {techData?.map((item) => {
                  return checkBoxItem(item.id, setTechStack, item?.stack, techStacks);
                })}
              </div>
            )}
          </Fragment>
        );

      case PROJECT_FILTER:
        const projectData = isSearched ? searchData : sharedProjects;
        return (
          <Fragment>
            {isSearched && !projectData.length ? (
              noResultFound()
            ) : (
              <div className={'ap-filter-modal-list-grid-container-panel'}>
                {projectData?.map((item: IProjectType) => {
                  return checkBoxItem(item.id, setProjects, item?.name, projects);
                })}
              </div>
            )}
          </Fragment>
        );

      case LEVEL_FILTER:
        return (
          <div className={'ap-filter-modal-list-grid-container-panel'}>
            {levelOfInterview?.map((item) => {
              return checkBoxItem(
                item.id.toString(),
                setInterViewLevels,
                item.level,
                interviewLevelList
              );
            })}
          </div>
        );

      case EXPERIENCE_FILTER:
        return (
          <div className="experience">
            {FILTER_EXPERIENCE.length &&
              FILTER_EXPERIENCE.map((experienceLevel, index: number) => {
                return checkBoxItem(index.toString(), setExperiences, experienceLevel, experience);
              })}
          </div>
        );

      default:
        return item;
    }
  };

  const setInitialData = () => {
    const { projects, interviewLevelList, techStacks, experience, count, eligiblePanels } =
      appliedFilters;
    setProjectsState([...projects]);
    setInterviewLevelListState([...interviewLevelList]);
    setTechStacksState([...techStacks]);
    setExperienceState([...experience]);
    setEligiblePanels(eligiblePanels);
    setSingleCheckCount(
      projects.length +
        interviewLevelList.length +
        techStacks.length +
        experience.length +
        (eligiblePanels ? 1 : 0)
    );
    setFilterBy(TECH_STACK_FILTER);
  };

  const handleIsEnabled = () => {
    const isEnabled = _.isEqual(appliedFilters, {
      interviewLevelList,
      techStacks,
      experience,
      projects,
      count: techStacks.length + interviewLevelList.length + projects.length + experience.length,
      eligiblePanels: eligiblePanel
    });
    setIsApplyEnabled(isEnabled);
  };

  useEffect(() => {
    setFilterBy(TECH_STACK_FILTER);
    setShowSearchField(true);
    setIsItemSelected(TECH_STACK_FILTER);
  }, []);

  useEffect(() => {
    setInitialData();
  }, []);

  useEffect(() => {
    handleIsEnabled();
  }, [interviewLevelList, techStacks, experience, projects, eligiblePanel]);

  return (
    <div className="admin-panel-list-filters-modal admin-panel-filter-backdrop">
      <div className="filter-modal-outer">
        <div className="ap-filter-header">
          <div className="admin-modal-filter-header">{FILTER_BY}</div>
          <div onClick={onFilterClose} className="ap-filter-close">
            <img src={close} alt="" />
          </div>
        </div>
        <div className="parent-filter-container">
          <div className="side-bar-title">
            {filterOptions.map((item: string, index: number) => {
              return (
                <div
                  key={index}
                  className={isItemSelected === item ? 'item-name item-name-active' : 'item-name'}
                  onClick={() => handleFilterByChange(item)}>
                  {item}
                </div>
              );
            })}
          </div>
          <div className="right-side">
            {showSearchField && (
              <div className="search-bar">
                <InputBox
                  startIcon={search}
                  endIcon={searchValue ? close : ''}
                  placeholder={filterBy === TECH_STACK_FILTER ? SEARCH_TECH_STACK : SEARCH_PROJECT}
                  value={searchValue}
                  handleChange={handleSearchAction}
                  onEndIconClick={clearInput}
                  customClass="input-bar"
                />
              </div>
            )}
            {filterData(filterBy)}
          </div>
        </div>
        <div className="btnContainer">
          <Button
            onClick={handleResetBtn}
            variant="outlined"
            size="medium"
            customStyle={
              singleCheckCount === 0 ? ADMIN_FILTER_RESET_DISABLED : ADMIN_FILTER_RESET_BUTTON
            }>
            Reset
          </Button>
          <Button
            size="medium"
            customStyle={!isApplyEnabled ? ADMIN_FILTER_APPLY_BUTTON : ADMIN_FILTER_APPLY_DISABLED}
            onClick={handleApplyBtn}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Filters;
