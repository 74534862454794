import React, { useCallback, useEffect, useRef, useState } from 'react';
import Select, { MenuPlacement, MultiValue, SingleValue, StylesConfig } from 'react-select';
import DropdownIndicator from 'app/core/shared-components/customSelect/dropdownIndicator';
import ValueContainer from 'app/core/shared-components/customSelect/valueContainer';
import Option from 'app/core/shared-components/customSelect/option';
import MultiValueRemove from 'app/core/shared-components/customSelect/multiValueRemove';
import MenuList from 'app/core/shared-components/customSelect/menuList';
import ClickAwayListener from 'app/core/shared-components/click-away';
import { menuPosition, dropDownIndicatorColor } from 'helpers/types';
import {
  CHECK_ATLEAST_ONE_ALPHABET,
  SPECIAL_CHAR_REGEX,
  SPECIAL_CHAR_REGEX_WITHOUT_SEMICOLON
} from 'helpers/regex.constants';
import { selectStyles, styles } from './index.style';
import './index.style.scss';
import { ASCII } from 'helpers/constants';

export interface ISelectOptions {
  id: string | number;
  label: string;
}

interface ICustomSelectProps {
  dropdownOptions: ISelectOptions[];
  selectedOptions: ISelectOptions[] | ISelectOptions | null | any;
  onChange: (params: ISelectOptions) => void;
  name?: string;
  placeholder?: string;
  isMulti?: boolean;
  customStyles?: StylesConfig<ISelectOptions>;
  showDropdownIndicator?: boolean;
  autoScrollToId?: string;
  disabled?: boolean;
  tagLimit?: number;
  autoFocus?: boolean;
  isSearchable?: boolean;
  dropDownIndicatorColor?: dropDownIndicatorColor;
  showFullWidth?: boolean;
  showMasterCheck?: boolean;
  menuPosition?: menuPosition;
  blurInputOnSelect?: boolean;
  searchOption?: boolean;
  restrictAlphabetOnSearch?: boolean;
  menuPlacement?: MenuPlacement;
  maxMenuHeight?: number;
  customClass?: string;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
}
const CustomSelect = (props: ICustomSelectProps) => {
  const {
    dropdownOptions,
    onChange,
    selectedOptions,
    customStyles,
    isMulti = false,
    name,
    showDropdownIndicator = false,
    placeholder = 'Select',
    autoScrollToId,
    disabled = false,
    tagLimit = 2,
    autoFocus = false,
    isSearchable = true,
    dropDownIndicatorColor,
    showFullWidth = true,
    showMasterCheck = false,
    menuPosition = 'fixed',
    searchOption = false,
    menuPlacement = 'auto',
    maxMenuHeight = 200,
    restrictAlphabetOnSearch = false,
    customClass = 'select-clickAway-listener',
    onMenuOpen,
    onMenuClose
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const ref = useRef<any>(null);

  const handleSelectChange = (
    value:
      | MultiValue<ISelectOptions | ISelectOptions[]>
      | SingleValue<ISelectOptions | ISelectOptions[]>
  ) => {
    onChange(value as ISelectOptions);
    setIsMenuOpen(isMulti && true);
  };

  const getOptionValue = useCallback(
    (option: ISelectOptions) => {
      return option.id ? option.id.toString() : '';
    },
    [selectedOptions]
  );
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event?.key) {
      case ASCII.enter:
        if (searchOption) {
          setIsMenuOpen(false);
        } else {
          setIsMenuOpen(!isMenuOpen);
        }
        break;
    }
  };

  const onSelectKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const specialCharRegex = restrictAlphabetOnSearch
      ? SPECIAL_CHAR_REGEX_WITHOUT_SEMICOLON
      : SPECIAL_CHAR_REGEX;

    const isAlphabet = CHECK_ATLEAST_ONE_ALPHABET.test(event.key);
    const isSpecial = specialCharRegex.test(event.key);

    if (
      event.key === ASCII.backspace ||
      event.key === ASCII.tab ||
      event.key === ASCII.arrowDown ||
      event.key === ASCII.arrowUP ||
      event.key === ASCII.enter
    )
      return;
    if ((isAlphabet && restrictAlphabetOnSearch) || isSpecial) {
      event.preventDefault();
    }
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };
  const toggleDropdown = () => {
    if (!disabled) setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent | KeyboardEvent) => {
      if (ref?.current && !ref?.current?.contains(event?.target as any)) {
        setIsMenuOpen(false);
      }
      return;
    };
    window.addEventListener('click', handleClickOutside, true);
    window.addEventListener('keydown', handleClickOutside, true);
    return () => {
      window.removeEventListener('click', handleClickOutside, true);
      window.removeEventListener('keydown', handleClickOutside, true);
    };
  }, [ref]);

  return (
    <ClickAwayListener handleClose={handleClose} customClass={customClass}>
      <div
        ref={ref}
        className={`react-select ${showFullWidth ? 'full-width' : null}`}
        tabIndex={0}
        onClick={toggleDropdown}
        onKeyDown={onKeyDown}>
        <Select
          {...props}
          isMulti={isMulti}
          options={dropdownOptions}
          value={selectedOptions}
          placeholder={placeholder}
          styles={
            customStyles
              ? { ...styles, ...customStyles, ...selectStyles }
              : { ...styles, ...selectStyles }
          }
          hideSelectedOptions={false}
          closeMenuOnSelect={!isMulti}
          menuShouldScrollIntoView={true}
          isDisabled={disabled}
          menuPlacement={menuPlacement}
          maxMenuHeight={maxMenuHeight}
          menuPosition={menuPosition}
          autoFocus={autoFocus}
          onChange={handleSelectChange}
          isClearable={false}
          menuIsOpen={isMenuOpen}
          getOptionValue={getOptionValue}
          isSearchable={isSearchable}
          backspaceRemovesValue={false}
          tabSelectsValue={false}
          tabIndex={0}
          onKeyDown={onSelectKeyDown}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          components={{
            DropdownIndicator,
            ValueContainer,
            Option,
            MultiValueRemove,
            MenuList
          }}
        />
      </div>
    </ClickAwayListener>
  );
};

CustomSelect.defaultProps = {
  isMulti: false,
  showDropdownIndicator: false,
  placeholder: 'Select',
  disabled: false,
  tagLimit: 2,
  autoFocus: false,
  isSearchable: true,
  showFullWidth: true,
  showMasterCheck: false,
  menuPosition: 'fixed'
};
export default CustomSelect;
