import {
  ADD_SLOT,
  ALL_SLOTS,
  AVAILABLE_SLOTS,
  AVAILABLE_SLOT_DETAIL,
  BOOKED_SLOTS,
  BOOK_SLOT,
  CANCELLED_SLOTS,
  CANCEL_SINGLE_SLOT,
  INTERVIEW_SLOT_COUNT,
  INTERVIEW_SLOT_REQUEST,
  INTERVIEW_SLOT_REQUEST_VALIDATE,
  REQUESTED_SLOT_LIST,
  SLOTS_PANEL_SEARCH,
  SLOT_DETAIL,
  SLOT_REQUEEST_DELETE,
  SLOT_REQUEST_BOOK
} from 'helpers/apiRoutes';
import { ISlotTimeData } from 'app/pages/Slots/components/slot-request-form';
import { ISlotCountRequest, ISpecificAvailableSlotBody } from 'app/redux/slices/slotsSlice';
import service from './index.service';

export interface IRequestSlotBody {
  date: string;
  slots: ISlotTimeData[];
  techStackId: string;
  interviewLevelId: string;
}

export interface IBookSlotBody {
  slotId: string;
  techStackId: string;
  interviewLevelId: string;
  candidateName: string;
  baseSlotDTO: {
    from: string;
    to: string;
  };
}

export interface ISlotFilterDTOType {
  from: string;
  to: string;
}

export interface IFetchSlotBody {
  userId?: string;
  levelId?: string;
  techStackId?: string | null;
  dateTime?: {
    from: string;
    to: string;
  }[];
}

interface IAddSlotType {
  date: string;
  slot: {
    from: string;
    to: string;
  }[];
}

export interface IAddSlotBody {
  userId: number | string;
  slots: IAddSlotType[];
}

export interface ICancelSlotBody {
  userId: string;
  slotId: string;
  cancelComments: string;
  cancelOption: string;
  makeSlotAvailable: boolean;
}

export interface IValidateSlotBody {
  date: string;
  slots: ISlotTimeData[];
  techStackId: string;
  interviewLevelId: string;
}

export async function requestSlot(body: IRequestSlotBody) {
  try {
    const response = await service.post(INTERVIEW_SLOT_REQUEST, body);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function validateSlot(body: IRequestSlotBody) {
  try {
    const response = await service.post(INTERVIEW_SLOT_REQUEST_VALIDATE, body);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function availableSlot(body: IFetchSlotBody) {
  try {
    const response = await service.post(AVAILABLE_SLOTS, body);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function bookedSlots(body: IFetchSlotBody) {
  try {
    const response = await service.post(BOOKED_SLOTS, body);
    return response.data;
  } catch (e: any) {
    const { data } = e.response;
    return { data: null, error: [data] };
  }
}

export async function allSlots(body: IFetchSlotBody) {
  try {
    const response = await service.post(ALL_SLOTS, body);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function cancelledSlots(body: IFetchSlotBody) {
  try {
    const response = await service.post(CANCELLED_SLOTS, body);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function cancelSlotService(body: ICancelSlotBody) {
  try {
    const response = await service.post(CANCEL_SINGLE_SLOT, body);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function specificAvailableSlot(body: ISpecificAvailableSlotBody) {
  try {
    const response = await service.post(AVAILABLE_SLOT_DETAIL, body);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function addSlotForPanel(body: IAddSlotBody) {
  try {
    const response = await service.post(ADD_SLOT, body);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return data;
  }
}

export async function specificBookedCancelledSlot(slotId: string) {
  try {
    const response = await service.get(SLOT_DETAIL, { params: { id: slotId } });
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function requestedSlots() {
  try {
    const response = await service.get(REQUESTED_SLOT_LIST);
    return response?.data;
  } catch (error: any) {
    const { data } = error?.response;
    return { data: null, error: [data] };
  }
}

export async function searchPanelService(searchString: string, signal: any) {
  try {
    const response = await service.get(SLOTS_PANEL_SEARCH, {
      params: { searchPanel: searchString },
      signal
    });
    return response?.data;
  } catch (error: any) {
    if (error.code === 'ERR_CANCELED') {
      return { data: null, error: [{ message: error.message, code: error.code }] };
    }
    return error?.response?.data;
  }
}

export async function bookSlotService(body: IBookSlotBody) {
  try {
    const response = await service.post(BOOK_SLOT, body);
    return response?.data;
  } catch (error: any) {
    return { data: null, error: [error] };
  }
}

export async function bookRequestedSlotService(
  slotRequestId: string,
  userId: string,
  candidateName: string
) {
  try {
    const response = await service.post(SLOT_REQUEST_BOOK, null, {
      params: {
        slotRequestId: slotRequestId?.toString(),
        userId: userId?.toString(),
        candidateName: candidateName
      }
    });
    return response?.data;
  } catch (error: any) {
    return { data: null, error: [error] };
  }
}

export async function deleteRequestedSlotService(requestId: number | string) {
  try {
    const response = await service.delete(SLOT_REQUEEST_DELETE, {
      params: { requestId: requestId?.toString() }
    });
    return response?.data;
  } catch (error: any) {
    return { data: null, error: [error] };
  }
}

export async function slotCountRequestService(body: ISlotCountRequest) {
  try {
    const response = await service.post(INTERVIEW_SLOT_COUNT, body);
    return response?.data;
  } catch (error: any) {
    return { data: null, error: [error] };
  }
}
