import React from 'react';
import './index.style.scss';
import SlotDetailContainer, { SlotStrip } from '.';
import { Moment } from 'moment';
import { GOOGLE_EVENTS } from 'helpers/constants';
import { timeStamps } from 'helpers/timeStamp.constants';

interface IGEvents {
  title?: string | React.ReactNode;
  start: Moment;
  end: Moment;
}

interface IGoogleEvents {
  events: IGEvents[];
}

function GoogleEventsContainer(props: IGoogleEvents) {
  const { events } = props;
  return (
    <SlotDetailContainer title={GOOGLE_EVENTS}>
      {events.map((item, index) => (
        <SlotStrip
          key={index}
          title={`${item.title}, ${item.start.format(timeStamps.HH_MM_A)} - ${item.end.format(
            timeStamps.HH_MM_A
          )}`}
        />
      ))}
    </SlotDetailContainer>
  );
}

export default GoogleEventsContainer;
